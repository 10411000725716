/* MenuItemDetails.css */
.menu-item-detail-wizard {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /*overflow-y: auto;*/
  -webkit-overflow-scrolling: touch; /* enables iOS "momentum" style scrolling */
  z-index: 1000;
  padding-bottom: 300%;
}

.menu-item-detail-wizard p {
  margin: 0;
}

.menu-item-detail {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 93%;
  /*overflow-y: auto;*/
  -webkit-overflow-scrolling: touch; /* enables iOS "momentum" style scrolling */
  z-index: 1000;
}

.menu-item-detail--animate-in {
  opacity: 0;
  transform: scale(0);
  animation: scaleUp 0.3s forwards;
}

.menu-item-detail--skip-animation {
  opacity: 1;
  transform: scale(1);
  animation: none;
}

.item-details__cart-indicator {
  position: fixed;
  bottom: calc(57px + 16px);
  right: 16px;
  z-index: 1100;
  background: linear-gradient(180deg, #fff, #fff 35%, #f6f6f6 71%, #ededed 94%, #ededed);
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
}

@keyframes scaleUp {
  100% {
    opacity: 1;
    transform: none;
  }
}

.menu-item-detail--closing {
  animation: scaleDown 0.3s forwards;
}

@keyframes scaleDown {
  0% {
    opacity: 1;
    transform: none;
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes hideOrderButton {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -100px;
  }
}

@keyframes showOrderButton {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 0;
  }
}

.hide-add-to-order {
  animation: hideOrderButton 0.3s forwards;
}

.show-add-to-order {
  animation: showOrderButton 0.3s forwards;
}

.item-detail__button--close {
  padding-top: 30px;
}
.combo-item-detail__button--close__wrapper {
  padding: 0 24px;
}

.item-details-main__Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

.item-detail__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0; /* Fixes iOS cropping */
  padding: 30px;
  background-color: #fff;
  transition: all 0.5s;
}

.item-detail__top-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
}

.item-detail__transition-container {
  background-color: transparent;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 0px;
  left: 0;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  z-index: 2;
}

.item-detail__transition-container.wizard {
  top: 73px;
  max-height: 50%;
}

.item-detail__transition-container > .item-detail__heading {
  margin: 0;
  opacity: 0;
}

.item-detail__transition-container.show {
  -webkit-box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.77);
  box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.77);
  background-color: #fff;
  opacity: 1;
  transition: background-color 0.5s, box-shadow 0.5s, opacity 0.5s;
}

.item-detail__transition-container.hide {
  box-shadow: none;
  background-color: transparent;
  transition: background-color 0.5s, box-shadow 0.5s, opacity 0.5s;
}

.item-detail__transition-container.hide.wizard {
  opacity: 0;
}

.item-detail__transition-container.show > .item-detail__heading {
  opacity: 1;
  transition: opacity 0.5s;
}

.item-detail__transition-container.hide > .item-detail__heading {
  opacity: 0;
  transition: opacity 0.5s;
}

.item-detail__transition-container > .item-detail__button--close {
  padding-top: 0;
  width: 40px;
  height: 40px;
}

.item-details__top-content__fav-button {
  padding-top: 30px;
  width: unset;
  height: unset;
}

.item-detail__image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 225px;
  height: 219px;
  margin-bottom: 25px;
  border-radius: 4px;
  overflow: hidden;
}

.item-detail__image-wrapper.wizard {
  width: 225px;
  border-radius: 4px;
  overflow: hidden;
  width: 40%;
  height: unset;
  margin-bottom: 0;
  max-width: 225px;
}

.item-detail__transition-container .item-detail__image-wrapper {
  width: 20%;
  margin: 0 15px 0 0;
}

.item-detail__transition-container .item_detail__secondary-info_wrapper {
  width: 100%;
}

.item-detail__transition-container .item-detail__description {
  height: calc(13px * 1 * 1.5);
  -webkit-line-clamp: 1;
  max-width: 180px;
}

.item-detail__transition-container .item__description--read-more {
  top: 0;
  right: 0;
  position: absolute;
  margin-top: 0;
}

.item-detail__transition-container
  .item-detail__description--expanded
  + .item__description--read-more {
  position: relative;
}

.item_detail__secondary-info_wrapper {
  width: 58%;
}

.item_detail__secondary-info_wrapper.expanded {
  width: 100%;
}

.item-detail__image {
  width: 100px;
  height: 100px !important;
  border-radius: inherit;
}

.item-detail__heading.wizard {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 700;
  padding-bottom: 8px;
}

.item-detail__heading {
  margin: 0 auto 20px;
  font-size: 1.25rem;
  font-weight: 500;
}

.item-detail__options-subheading {
  font-weight: 600;
  margin: 0 0 5px;
}

.item-detail__options + .item-detail__options-subheading {
  margin-top: 15px;
}

.item_detail__top-content.wizard {
  width: 100%;
}

.item-detail__secondary-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.item-detail__secondary-info.wizard {
  position: relative;
}

.item-detail__description {
  position: relative;
  max-height: 20em;
  overflow: hidden;
  margin: 0 15px 0 0;
  font-size: 13px;
  line-height: 1.5;
  text-align: left;
  transition: max-height 0.2s;
  white-space: pre-line;
  width: 100%;
  word-wrap: break-word;
}

.item-detail__description.wizard {
  max-width: 400px;
  height: calc(13px * 3 * 1.5);
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin: 0 15px 0 0;
  font-size: 13px;
  line-height: 1.5;
  width: 100%;
}

.item-detail__price-calories {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.item-detail__price-calories > p {
  margin: 0;
}

.item-detail__description-wrapper {
  width: 100%;
}

.item-detail__description--expanded {
  max-height: 100%;
  min-height: 5.5em;
  transition: max-height 0.5s;
  transition: min-height 0.5s;
  display: unset;
}

.item-detail__description.wizard.item-detail__description--expanded {
  overflow: visible;
}

.item-detail__description--expanded.wizard {
  height: unset;
  display: block;
}

.item__description--read-more {
  color: var(--color-secondary);
  text-align: right;
  float: right;
}

.item-details__description-button {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 10;
}

.item__description--read-more-container {
  width: 100%;
}

.item_description__gradient {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 18px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 18%, rgba(255, 255, 255, 0) 100%);
}

.item-detail__calories.wizard,
.item-detail__price.wizard {
  color: #757575;
  font-size: 1rem;
  font-weight: 600;
}

.item-detail__calories,
.item-detail__price {
  color: #757575;
  font-size: 1.125rem;
  font-weight: 700;
  margin-left: 5px;
}

.item-detail__price-width {
  display: flex;
  justify-content: flex-end;
  width: inherit;
}

.item-detail__calories:before {
  content: "|";
  margin-right: 5px;
}

.item-detail__calories.wizard:before {
  content: "";
  display: none;
}

.item-detail__calories.wizard {
  margin-left: 0;
}

.item-detail__calories-no-price {
  margin-left: 0;
}
.item-detail__calories-no-price::before {
  content: none;
  margin: 0;
}

.item-detail__options-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  flex-shrink: 0; /* Fixes iOS cropping */
  width: 100%;
  padding: 0 0 300%;
  background: #fff;
}

.item-detail__options-heading {
  margin: 0 0 10px;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  text-align: center;
}

.item-detail__options-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* enables iOS "momentum" style scrolling */
  scroll-behavior: smooth;
}

.item-detail__options-group + .item-detail__options-subheading {
  margin-top: 12px;
}

.item-detail__options-group + .special-instructions-wrapper {
  margin-top: 36px;
}

.item-detail__options {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  flex-direction: column;
}

.item-detail__options:empty {
  display: none; /* when option groups don't have names */
}

.item-detail__options + .item-detail__options {
  margin-top: 18px;
}

.item-detail__option + .item-detail__option {
  margin-top: 0px;
}

.item-detail__option:last-child:not(:first-child) > .item-detail__option-button {
  margin-right: 30px;
}

.item-detail__option-button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  min-height: 53px;
  padding: 10px 20px;
  background: linear-gradient(180deg, #fff 0, #fff 35%, #f6f6f6 71%, #ededed 94%, #ededed);
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.12);
}

.item-detail__option-text-container {
  text-align: left;
}

.item-detail__option-button--instructions {
  margin: 18px 0 0;
}

.item-detail__option-button-name {
  font-size: 0.8125rem;
  font-weight: 500;
  text-align: left;
  margin-bottom: 5px;
}

.item-detail__option-note {
  color: #4a5568;
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
}

.item-detail__option-note.icon--plus,
.item-detail__option-note.icon--minus {
  width: 15px;
  height: 15px;
  stroke-width: 3px;
  margin-right: 3px;
}

.item-detail__form__fieldset-legend-button {
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.item-detail__form-expanded {
  /* Set our transitions up. */
  max-height: 100%;
}

.item-detail__form-collapsed {
  max-height: 0;
  overflow: hidden;
}

.item-detail__option-note--mandatory {
  color: #ff2020;
}

button:disabled .item-detail__option-note--mandatory {
  color: #dadae0;
}

.item-detail__option-button:disabled > .icon--pencil {
  color: #dadae0;
}

.item-detail__option-button > .icon--pencil {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 20px;
}

.modal__form {
  width: 100%;
  margin-bottom: 20px;
}

.modal-wrapper--item-options {
  z-index: 1200;
}

.item-options__modal-hint {
  margin-bottom: 10px;
  display: block;
  color: #4a5568;
}

.item-options__modal-hint--mandatory {
  color: #ff2020;
}

.modal__form-field-row {
  position: relative;
}

.modal__form-field-row + .modal__form-field-row {
  margin-top: 15px;
}

.modal__form-subfield {
  margin-left: 30px;
  margin-top: 15px;
}

.modal__legend {
  margin-bottom: 25px;
  font-size: 1.0625rem;
  font-weight: 600;
  text-align: center;
}

.modal__legend--subfield {
  margin-bottom: 15px;
}

.modal__option-label::before {
  content: "";
  position: absolute;
  top: 5px;
  margin: auto 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  background-color: #eee;
}

.modal__form-subfield-group {
  margin-top: 15px;
  transition: max-height 0.25s;
  max-height: 0;
  overflow: hidden;
}

.modal__option-checkbox:checked ~ .modal__form-subfield-group {
  max-height: 500px;
}

.modal__form-subfield-row {
  margin-left: 40px;
  position: relative;
}

.modal__form-subfield-row + .modal__form-subfield-row {
  margin-top: 15px;
}

.modal__sub-option-label {
  padding-left: 40px;
}

.modal__sub-option-label::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  background-color: #eee;
}

.modal__option-checkbox:checked + .modal__option-label::before {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='icon icon--check'><polyline points='20 6 9 17 4 12'></polyline></svg>");
  background-repeat: no-repeat;
  background-position: center;
}

.modal__option-checkbox:disabled + .modal__option-label {
  color: #cdcdcd;
}

.modal__option-checkbox:disabled + .modal__option-label:before {
  background-color: #f5f5f5;
}

.modal__option-checkbox:focus + .modal__option-label::before {
  outline: 1px solid #4299e1;
}

.modal__option-name {
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: capitalize;
}

.modal__option-price {
  color: #4299e1;
  font-size: 0.8125rem;
  display: block;
}

.modal__option-checkmark {
  background-color: #4caf52;
  stroke: #fff;
  stroke-width: 3px;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  padding: 3px;
}

.modal__options-hint {
  margin-bottom: 25px;
  font-size: 1rem;
}

.special-instructions-wrapper {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.special-instructions-wrapper > .order-item__option-header {
  background-color: #eee;
}

.special-instructions__field {
  width: 80%;
  padding: 10px 18px;
  font-size: 0.875rem;
  font-weight: 400;
  border: none;
  border-radius: 4px;
  border: 1px solid var(--color-gray-light);
  margin: 15px auto;
  margin-bottom: 20px;
}
.suggested-item-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 95%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
}
.itemLevelSuggestedItemsHeading{
  margin-bottom: 5px;
}
.comboSuggestedItemWrapper{
  height: 100% !important;
}
.suggested-items-list{
  padding: 10px 24px;
}
.suggested-item-card{
  width: calc(75% / 3 - 1em) !important;
  max-width: 170px;
}

.suggested-item-order-level-card{
  width: calc(100% / 2 - 1em) !important;
  max-width: auto;
  margin: 0 !important;
}
/* Add to Favorites button */
.heart-icon--flicker {
  animation: flickerFavoritesButton 0.35s forwards;
}

@keyframes flickerFavoritesButton {
  50% {
    transform: scale(2);
    opacity: 0.5;
  }
  100% {
    transform: initial;
    opacity: 1;
  }
}

.item-detail__image-overlap-cotnainer,
.item-detail__image-underlay {
  position: relative;
  left: 0;
  top: 0;
  max-height: 100%;
}

.item-detail__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.desktop-order-menu__card-image.item-detail__image-overlay {
  object-fit: cover;
}

.item-detail__option {
  width: 100%;
}

.menu-item-detail #special-instructions,
.desktop-order-item__options-container #special-instructions {
  background-color: #fff !important;
}

.menu-item__favourite-dialog-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu-item__favourite-dialog-modal p {
  margin-bottom: 36px;
}

.menu-item__favourite-dialog-modal-buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.menu-item__favourite-dialog-modal + .dialog-modal__buttons {
  display: none;
}

@keyframes slideUp {
  0% {
    transform: scale(2);
  }
  100% {
    transform: initial;
  }
}

.menu-item__top-content-animation {
  top: 73px;
  max-height: 15%;
  background-color: transparent;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  left: 0;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  z-index: 2;

  transition: all 3s;
}

.menu-item__top-content-animation.show {
  box-shadow: 0px 2px 14px -2px rgb(0 0 0 / 77%);
  background-color: #fff;
  opacity: 1;
  transition: background-color 0.5s, box-shadow 0.5s, opacity 0.5s;
}

.menu-item__top-content-animation.show .testClass {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu-item__top-content-animation.show .item-detail__image-wrapper {
  float: unset;
  width: 10%;
}

.item-detail__content.isMinimized {
  position: sticky;
  z-index: 999999;
  -webkit-box-shadow: 0px 2px 10px -2px rgba(182, 182, 182, 0.77);
  box-shadow: 0px 2px 10px -2px rgba(182, 182, 182, 0.77);
  background-color: #fff;
  opacity: 1;
  max-height: 115px;
  top: 86px;
  transition: all 0.5s;
}

.item-detail__content .item-detail__image-wrapper {
  max-width: 100px;
  max-height: 100px;
  margin-right: 10px;
  width: unset;
}

.item-detail__content.isMinimized .item-detail__description--expanded {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: unset;
}

.item-detail__content.isMinimized .item__description--read-more {
  font-size: 0.9rem;
}

.item-detail__content.isMinimized.isExpanded .item-detail__price-calories {
  margin-top: 10px;
  margin-bottom: 0;
}

.item-detail__content.isMinimized.isExpanded {
  max-height: unset;
}

.item-detail__content.isMinimized.isExpanded .item__description--read-more {
  position: unset;
  margin-bottom: 10px;
}

.item-detail__content.isMinimized.isCollapsed {
  min-height: 107px;
  max-height: 210px;
  overflow: hidden;
  /*transition: max-height 0.5s;*/
}

.item-detail__content.isMinimized + .item-detail__options-container {
  padding-top: 100px;
}

.item-detail__content.isMaximized {
  position: sticky;
  max-height: 2000px;
  /*top: 0;
  z-index: 99999;*/
  box-shadow: 0px 2px 10px -2px rgb(182 182 182 / 77%);
  /*transition: max-height 0.5s;*/
}

.quantity-special-instruction__container {
  width: 100%;
}

.animate-scale-down {
  width: 0 !important;
  margin: 0 !important;
  transition: width 0.5s, margin 0.5s;
}

.item__description--read-less {
  margin-top: 10px;
  margin-bottom: 10px;
}

.item_details__name-price-wrapper {
  flex-grow: 1;
}

.item-detail__description__wrapper {
  margin-top: 10px;
  align-items: flex-end;
}

.item_details__name-price-container {
  flex-grow: 1;
}

.item-detail__image-wrapper.wizard {
  height: 0;
  padding-top: 30%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.item-detail__image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

/** Mobile Specific Style **/
@media screen and (max-width: 660px) {
  .item-detail__option-text-container {
    text-align: right;
  }

  .item-detail__content.isMinimized {
    top: 0;
    width: 100%;
  }

  .addons-modifiers-form .form__field-label--checkbox-radio.no-thankyou-option::after {
    right: 8px;
    top: 8px;
  }
  .suggested-item-card{
    width: calc(100% / 2 - 1em) !important;
    max-width: unset !important;
  }
  .suggested-item-order-level-card{
    width: 98%  !important;
    max-width: auto;
  }
}

/** Desktop Design **/
@media screen and (min-width: 660px) {
  .item-details-main__Wrapper {
    max-width: 1200px;
    border-radius: 0.5em;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .menu-item-detail-wizard {
    padding-bottom: 0;
  }
  .item_detail__top-content.wizard {
    padding-top: 0;
  }
  .menu-item_main-container {
    position: fixed;
    z-index: 1005;
    width: 100%;
    height: 100%;
    background-color: #0000007a;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-bottom: 0;
  }

  .menu-combo-item_main-container {
    z-index: 1004;
  }

  .item-details-main__Wrapper {
    height: 99%;
    border-radius: 12px;
    max-width: 1200px;
    background-color: #fff;
    border: 2px solid #fff;
    margin-left: 24px;
    margin-right: 24px;
    width: 100%;
  }

  .item-detail__content.isMinimized {
    top: 40px;
  }

  .item-detail__content.isMaximized {
    top: unset;
    box-shadow: 0px 2px 4px -2px rgb(182 182 182 / 77%);
  }

  .order-item__options-form {
    padding: 0px 24px 24px;
  }

  .form__fieldset-addonModifier {
    padding: 30px 0;
  }

  .item-details-main__Wrapper::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .item-detail__description.wizard {
    max-width: unset;
  }

  .item-detail__content.isMinimized {
    box-shadow: 0px 7px 10px -6px rgb(182 182 182 / 77%);
  }
  .item-detail__price-calories .item-detail__content.isMinimized.isCollapsed {
    width: 100%;
    max-height: 100px;
    position: sticky;
    top: 40px;
  }

  .item-detail__content.isMinimized.isExpanded .item-detail__price-calories {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .item-detail__content.isMinimized .item-detail__image-wrapper,
  .item-detail__content.isMaximized .item-detail__image-wrapper {
    margin-bottom: 12px;
  }

  .item-detail__options-container {
    background-color: #fff;
  }
  .order-item__option-header {
    background-color: #eee;
  }

  .desktop-next-group-button__container {
    position: sticky;
    bottom: 0;
  }

  .desktop-next-group-button {
    padding: 1em 0;
    background-color: var(--color-online-order-settings-background);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .desktop-next-group-button__container .FAB-wrapper {
    position: unset;
  }

  .desktop-next-group-button__container .save-changes-FAB > .desktop-container {
    justify-content: center;
  }

  .item-detail__content.isMinimized.isExpanded {
    width: 100%;
    max-height: unset;
    position: sticky;
    top: 40px;
  }

  /*.item-detail__content.isMinimized + .item-detail__options-container {
    margin-top: 100px;
  }*/

  .item-details__desktop-close-button-container {
    min-height: 40px;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 999999;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background-color: #fff;
  }

  .form__fieldset.form__fieldset--top-level.no-image-group
    div.form__field-wrapper--checkbox-radio__background
    > div
    > label.regular-option,
  .form__fieldset.form__fieldset--top-level.no-image-group
    div.form__field-wrapper--checkbox-radio__background
    > div
    > label.no-thankyou-option {
    align-items: center;
  }
  .item-detail__options-container {
    padding-bottom: 100%;
  }

  .item-detail__content.isMinimized.isCollapsed {
    max-height: 210px;
  }

  .item-detail__content .item-detail__image-wrapper {
    max-width: 100px;
    max-height: 100%;
    width: 40%;
    margin-bottom: 0 !important;
  }

  .item-detail__image-container {
    align-items: flex-start;
  }

  .item-detail__image-wrapper.wizard {
    height: 100px !important;
    padding-top: 10vh !important;
    width: 100% !important;
    position: relative !important;
    overflow: hidden !important;
  }

  .item-detail__image {
    top: 0;
    left: 0;
    object-fit: cover;
  }
}
