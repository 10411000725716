.blink {
  animation-name: blink;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes blink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes blink-star {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    transform: scale(1.75);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.delay-1 {
  animation-delay: 0.35s;
}

.delay-2 {
  animation-delay: 0.7s;
}

/* OrderConfirmation styles */
.main-content--center-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.order-confirmation-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
  padding: 0 5% 50px;
  overflow-y: scroll;
}

.order-confirmation__checkmark {
  background: #bfd72f;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  animation: appear 1s forwards, blink 0.5s 0.75s forwards;
}

.order-confirmation__checkmark > .icon {
  width: 12px;
  height: 12px;
  stroke: #fff;
}

.order-confirmation__heading {
  opacity: 0;
  transform: translateY(100px);
  font-size: 1.25rem;
  font-weight: 600;
  animation: slide-in 0.5s forwards;
  animation-delay: 0.1s;
}

.order-confirmation__order-summary {
  opacity: 0;
  margin-bottom: 15px;
  transform: translateY(10px);
  animation: slide-in 0.5s forwards;
  animation-delay: 0.35s;
  text-align: left;
  line-height: 1.5;
}

.order-confirmation__total {
  opacity: 0;
  margin-bottom: 30px;
  font-weight: 600;
  transform: translateY(10px);
  animation: slide-in 0.5s forwards;
  animation-delay: 0.7s;
}

@keyframes slide-in {
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.reward__container {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #d64123;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
  margin-bottom: 25px;
  opacity: 0;
  transform: translateY(10px);
  animation-delay: 0.4s;
  animation: appear 1s forwards;
}

@keyframes appear {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.earned-reward {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
}

.reward-message {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
}

.earned-reward__value {
  font-size: 20px;
  font-weight: 600;
}

.earned-reward > .icon--star {
  width: 18px;
  color: #d64123;
  fill: #d64123;
  animation: blink-star 0.5s forwards;
}

.earned-reward > .icon--star:first-child {
  animation-delay: 1s;
}

.earned-reward > .icon--star:last-child {
  animation-delay: 1.15s;
}

.rewards-link {
  opacity: 0;
  font-size: 13px;
  transform: translateY(10px);
  animation: appear 1s forwards;
  animation-delay: 0.15s;
  margin-bottom: 10px;
}

.order-confirmation__points-diclaimer {
  line-height: 1.5;
}

.order-confirmation__order-id {
  margin-bottom: 15px;
  line-height: 1.5;
}

.order-confirmation__order-id > span {
  font-weight: 600;
}

.order-confirmation__image {
  margin: 35px 20px;
}

.order-confirmation__thankyou-note {
  display: flex;
  justify-content: left;
  align-items: center;
}

.order-confirmation__rewards-bottom {
  margin-top: 36px;
}

.order-confirmation__rewards-bottom > a {
  white-space: normal;
}

.add-new-loyalty-card-button {
  padding: 10px 18px;
  font-size: 13px;
  line-height: 100%;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  font-weight: 550;
}

.order-confirmation__points-diclaimer > span > svg {
  width: 20px;
  height: 20px;
  vertical-align: top;
  left: 0;
}

.order-confirmation__points-issued {
  font-weight: bold;
}

.order-confirmation__ratings-main-container {
  background-color: var(--color-gray-light);
  padding: 1em;
  margin-top: 1.3em;
}
.order-confirmation__ratings-header {
  font-weight: 700;
  text-align: center;
  margin-top: 0;
}

.order-confirmation__ratings-button-container {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 0 auto;
}

.order-confirmation__ratings-button-container > a {
  margin-right: 10px;
  background-color: var(--color-secondary);
  padding: 0.6em;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.order-confirmation__ratings-button-container > a > svg {
  color: white;
  width: 30px;
  height: 27px;
}
/** Desktop Layout **/

@media screen and (min-width: 660px) and (max-width: 1600px) {
  .order-confirmation__image {
    text-align: center;
  }

  .order-confirmation__image > img {
    max-width: 40%;
  }
  .order-confirmation__points-diclaimer {
    margin-bottom: 2em;
  }

  .order-confirmation-container {
    max-width: 80%;
    overflow-y: auto;
    margin: 0 auto;
    text-align: center;
  }

  .order-confirmation__thankyou-note,
  .order-confirmation__order-id,
  .order-confirmation__order-summary {
    justify-content: center;
    text-align: center;
  }

  .order-confirmation__rewards-bottom,
  .order-confirmation__check-my-reward-link-wrapper {
    padding-right: 25%;
    padding-left: 25%;
    bottom: 0;
    margin-top: 0;
    padding-top: 0;
  }

  .order-confirmation__rewards-bottom.order-confirmation__rewards-bottom__bottom-margin {
    margin-bottom: 3em;
  }

  .order-confirmation__rewards-bottom {
    max-width: 98vw;
  }
}

@media screen and (min-width: 1601px) {
  .order-confirmation__image {
    text-align: center;
  }
  .order-confirmation__image > img {
    max-width: 50%;
  }
  .order-confirmation-container {
    max-width: 70%;
    overflow-y: auto;
    margin: 0 auto;
    text-align: center;
  }
  .order-confirmation__points-diclaimer {
    margin-bottom: 2em;
  }

  .order-confirmation__thankyou-note,
  .order-confirmation__order-id,
  .order-confirmation__order-summary {
    justify-content: center;
    text-align: center;
  }

  .order-confirmation__rewards-bottom {
    padding-right: 25%;
    padding-left: 25%;
  }
}

.order-confirmation__check-my-reward-link {
  color: var(--color-secondary);
  font-weight: 600;
}

.order-confirmation__check-my-reward-link-wrapper {
  margin-top: 15px;
}

/** End of desktop Layout **/
