.payment-method__credit-card-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
}

.payment-method__credit-card-icons > .icon {
  width: 40px;
  height: 40px;
  fill: #2a2a2a;
}

.payment-method__credit-card-icons > .icon.cc-type--inactive {
  fill: #94a3bc;
}

.payment-method__credit-card-icons > .icon.cc-type--inactive.icon--alelo > path:nth-child(3) {
  fill: #94a3bc !important;
}

.payment-method__credit-card-icons > .icon + .icon {
  margin-left: 10px;
}
