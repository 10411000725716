/* App Footer styles */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  /*padding-left: 3%;*/
  background-color: var(--color-footer-background-colour);
  /*box-shadow: 0 -2px 20px 3px rgba(0, 0, 0, 0.2);*/
  z-index: 1100;
  border-top: 1px solid #eee;
}

.main-content--pickup ~ .footer,
.main-content--delivery ~ .footer {
  z-index: 400;
}

.footer--open-subnav {
  box-shadow: 0px -10px 20px 3px rgba(0, 0, 0, 0.2);
}

.footer__nav-list {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.footer-subnav__list {
  opacity: 1;
  transform: translateY(0);
  position: absolute;
  bottom: 55px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 89.4%;
  padding: 12px 24px 32px;
  list-style-type: none;
  /*(animation: slideUpSubnav 0.2s forwards;*/
}

.footer__subnav-background.mobile-hamburger-menu {
  top: 70px;
  border-radius: 5px;
  opacity: 0;
}

.footer__subnav-background.mobile-hamburger-menu + .footer__subnav-overlay {
  opacity: 0;
}

.footer__more-options {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 100%;
}

.footer__subnav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  z-index: 1000;
  animation: fadeIn 300ms;
}

.button--pay-in-store {
  position: fixed;
  bottom: calc(57px + 11px); /* footer height + distance from footer */
  right: 18px;
  z-index: 100;
  min-width: 40%;
  width: auto;
  display: flex;
  justify-content: center;
  color: var(--color-pay-in-store-button-text);
  background-color: var(--color-pay-in-store-button);
  box-shadow: 0 5px 10px rgba(2, 10, 6, 0.33);
}

.footer__subnav-background {
  opacity: 0;
  transform: translateY(100%);
  position: fixed;
  z-index: 1001;
  bottom: 57px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 89.4%;
  background-color: var(--color-footer-background-colour);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.21);
  animation: slideUpSubnav 0.2s forwards;
}

/** Prevents double tab zoom on footer buttons **/
.body--iOS .footer__nav-item {
  touch-action: manipulation;
}

.button--pay-in-store.button--pay-in-store-left-aligned {
  left: 18px;
  right: unset;
}
@keyframes slideUpSubnav {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media screen and (min-width: 660px) {
  .button--pay-in-store {
    box-shadow: 0 5px 10px rgba(2, 10, 6, 0.33);
    bottom: 5em;
    right: 2em;
    min-width: 0;
    padding: 1em 5em;
  }
}
/* END OF App Footer styles */
