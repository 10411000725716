/* Containers */
.desktop-header {
  position: relative;
  z-index: 1005;
  background-color: var(--color-header-background-colour);
}

.desktop-header-container {
  display: flex;
  align-items: center;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
}
/* END OF: Containers */

/* Client Logo */
.desktop-app-header__logo-wrapper {
  display: inline-block;
  height: 60px;
  margin-right: 2em;
  transition: opacity 0.3s;
}

.desktop-app-header__logo-link {
  transition: opacity 0.3s;
}

.desktop-app-header__logo-link:hover {
  opacity: 0.7;
}

.app-header__logo {
  max-height: 100%;
  width: auto;
  max-width: 250px;
}
/* END OF: Client Logo */

/* Navigation */
#desktop-header__menu-toggle {
  display: none;
}

#desktop-header__menu-toggle > svg {
  color: var(--color-header-text-and-icon-colour);
  position: absolute;
  right: 0;
}

.desktop-header__nav-menu {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  animation: fadeIn 0.1s;
}

.desktop-header__nav-list {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.desktop-header__nav-item {
  position: relative;
  display: flex;
  align-items: center;
}

.desktop-header__nav-item + li {
  margin-left: 2em;
}

.desktop-header__nav-item[data-item="language"] {
  margin-left: auto;
}

.desktop-header__nav-item[data-item="sign-in"] {
  text-align: right;
}

.desktop-header__nav-item[data-item="sign-in"]:after {
  content: "/";
  color: var(--color-header-text-and-icon-colour);
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.desktop-header__nav-item[data-item="register"] {
  margin-left: 0;
  text-align: left;
}

.desktop-header__nav-item--active > .desktop-header__nav-link,
.desktop-header__nav-item--active > button {
  color: var(--color-header-active-text-colour);
}

.desktop-header__nav-link,
.desktop-header__nav-button {
  font-size: 1em;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  transition: opacity 0.3s;
  cursor: pointer;
  color: var(--color-header-text-and-icon-colour);
  text-wrap: wrap;
}

.desktop-header__nav-link:hover,
.desktop-header__nav-button:hover {
  opacity: 0.7;
}

/* Submenu dropdowns */
.desktop-header__subnav {
  display: none;
  position: absolute;
  z-index: 550;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 50px;
  /* offset for submenu toggle button */
  margin-left: calc(-17px / 2);
}

.desktop-header-subnav__content {
  position: relative;
  display: flex;
  padding-left: 2em;
  padding-right: 2em;
  background-color: var(--color-header-background-colour);
  border-radius: 5px;
  box-shadow: 0 2px 15px 0px rgb(0 0 0 / 20%);
}

.desktop-header-subnav__content:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 15px solid var(--color-header-background-colour);
}

.desktop-header-subnav__list {
  list-style-type: none;
  flex-direction: column;
  padding-top: 1em;
  padding-bottom: 1em;
}

.desktop-header-subnav__list-item {
  white-space: nowrap;
}

.desktop-header-nav__submenu-link,
.desktop-header-nav__submenu-button {
  margin: 0.5em 0;
  white-space: nowrap;
  transition: opacity 0.2s;
  color: var(--color-header-text-and-icon-colour);
}

.desktop-header-nav__submenu-link:hover,
.desktop-header-nav__submenu-button:hover {
  opacity: 0.7;
}

.desktop-header-nav__submenu-link {
  display: block;
}

.desktop-header-nav__submenu-button {
  text-align: left;
}

.desktop-header-nav-item__show-submenu-button {
  display: flex;
  align-items: center;
}

.desktop-header-nav-item__show-submenu-button svg {
  fill: var(--color-header-text-and-icon-colour);
}

.desktop-header-nav-item__show-submenu-button[aria-expanded="true"] + .desktop-header__subnav {
  display: block;
}

.desktop-header__nav-item--active > button + button .icon--arrow-down,
.desktop-header__nav-item--active > .desktop-header__nav-link + button .icon--arrow-down {
  fill: var(--color-header-active-text-colour);
}

/* END OF: Submenu dropdowns */

@media screen and (max-width: 1125px) {
  #desktop-header__menu-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    margin-left: auto;
    position: relative;
  }

  .desktop-header__nav-menu {
    display: none;
    transform-origin: top right;
    transform: scale(0);
    position: absolute;
    top: calc(1.6em + 57px);
    right: 50px;
    width: 310px;
    max-width: 90vw;
    text-align: right;
    background-color: var(--color-header-background-colour);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25em;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    overflow: hidden;
  }

  #desktop-header__menu-toggle[aria-expanded="true"] + .desktop-header__nav-menu {
    display: block;
    animation: scaleUpFromCorner 0.2s forwards;
  }

  @keyframes scaleUpFromCorner {
    to {
      transform: scale(1);
    }
  }

  .desktop-header__nav-list {
    display: block;
  }

  .desktop-header__nav-item {
    width: 100%;
    flex-wrap: wrap;
  }

  .desktop-header__nav-item + li {
    margin-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .desktop-header__nav-item[data-item="sign-in"],
  .desktop-header__nav-item[data-item="register"] {
    display: inline-flex;
    vertical-align: middle;
    width: 50%;
  }

  .desktop-header__nav-item[data-item="sign-in"]:after {
    display: none;
  }

  .desktop-header__nav-item[data-item="register"] {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  .desktop-header__nav-link,
  .desktop-header__nav-button {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 44px;
    padding-left: 2em;
    padding-right: 1em;
  }

  .desktop-header-nav-item__show-submenu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  .desktop-header-nav-item__show-submenu-button[aria-expanded="true"] + .desktop-header__subnav {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    padding-top: 0;
    margin-left: 0;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .desktop-header-subnav__content:before {
    display: none;
  }

  .desktop-header-subnav__content {
    padding: 0.5em 1em 0.5em 2.5em;
    background-color: var(--color-header-background-colour);
    border-radius: 0;
    box-shadow: none;
  }

  .desktop-header-subnav__list-item {
    text-align: left;
  }

  .desktop-header-nav__submenu-link {
    display: inline-block;
  }
  .desktop-header {
    z-index: 1005;
  }
}
