.dateTimeInputComboContainer {
  max-width: 300px;
}

.dateTimeInputComboContainer label {
  margin: 1.5px;
}

.formDatepickerInput {
  resize: none;
  border: 1px solid #dedede !important;
  border-radius: 0.5em !important;
  width: 300px !important;
  height: 42px !important;
}

.formTimepickerInput {
  resize: none;
  border: 1px solid #dedede !important;
  border-radius: 0.5em !important;
  max-width: 150px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none !important;
  height: 35px !important;
}

.dateTimeInputComboContainer .rmdp-container {
  width: 300px !important;
}

.dateTimeInputComboContainer .rmdp-day.rmdp-today span {
  background-color: transparent !important;
  color: #0086ff !important;
}

.dateTimeInputComboContainer .rmdp-day.rmdp-today.rmdp-selected span {
  background-color: transparent !important;
  color: white !important;
}

.dateTimeInputComboContainer .rmdp-day span:hover {
  border-radius: none !important;
  color: var(--color-secondary);
}

.dateTimeInputComboContainer .rmdp-day span.sd {
  border-radius: 50% !important;
}

.dateTimeInputComboContainer .form-visuaally-hidden-button {
  visibility: hidden !important;
}
.dateTimeInputComboContainer
input[name="hour"],
.dateTimeInputComboContainer input[name="minute"] {
  padding: 0.15rem 0 !important;
}

.dateTimeInputComboContainer .rmdp-day.rmdp-today.rmdp-day.rmdp-selected span {
  color: #fff !important;
  background-color: var(--color-primary) !important;
}

.dateTimeInputComboContainer .rmdp-day.rmdp-today.rmdp-selected span:hover {
  color: #0086ff !important;
  background-color: #fff !important;
}

.dateTimeInputComboContainer .rmdp-time-picker.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateTimeInputComboContainer .rmdp-time-picker.bottom div {
  max-width: 40px;
}

.dateTimeInputComboContainer .rmdp-time-picker.bottom div:nth-child(3) button {
  display: none;
}

.dateTimeInputComboContainer .rmdp-time-picker.bottom div:nth-child(3) input {
  pointer-events: none;
  color: #757575
}

.dateTimeInputComboContainer .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--color-primary) !important;
  color: white !important;
}
.dateTimeInputComboContainer div.rmdp-header-values span:last-child {
  margin-left: 4px;
}
.disabledCalendarInput{
  pointer-events: none;
  border: none !important;
}
.dateTimeInputComboContainer .rmdp-selected span {
  box-shadow: none !important;
}

/* mobile view */
@media screen and (max-width:660px) {
 .dateTimeInputComboContainer .rmdp-header-values>span {
    font-size: 0.85rem;
  }

  .dateTimeInputComboContainer .rmdp-week-day {
    font-size: 0.85em !important;
  }

  .dateTimeInputComboContainer .rmdp-day>span {
    font-size: 0.85em !important;
  }

  .dateTimeInputComboContainer .rmdp-mobile {
    min-width: 300px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .dateTimeInputComboContainer .rmdp-mobile .rmdp-calendar {
    width: 100%;
    margin-bottom: 1em !important;
  }

  .dateTimeInputComboContainer .rmdp-mobile .rmdp-calendar .rmdp-day-picker>div {
    width: 100% !important;
    font-size: 1em !important;
  }

  .dateTimeInputComboContainer .rmdp-calendar-container-mobile div.rmdp-action-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .dateTimeInputComboContainer .rmdp-calendar-container-mobile div.rmdp-action-buttons button {
    color: var(--color-primary);
    font-size: 1em !important;
  }

}