.store-info__details-modal {
  opacity: 0;
  transform: translateX(100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 57px);
  padding: 16px 25px;
  background-color: #fff;
  animation: slideInStoreInfoDetails 0.3s forwards;
  z-index: 300;
}

.store-info__details-modal--closing {
  animation: slideOutStoreInfoDetails 0.3s forwards;
}
.store-info-details__hours-table {
  margin-left: 10px;
}
.store-info-details__hours-table tr {
line-height: 1.5;
}
.button--close-info-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 44px;
  min-height: 44px;
  margin: 0 auto 15px;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

.button--close-info-dialog > svg {
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

.google-maps__store-info-details {
  margin-bottom: 19px;
  padding: 0;
  border-top: none;
  overflow-y: visible;
}

.store-info-details__name {
  line-height: 1.3;
  white-space: normal;
  text-overflow: clip;
}

.store-info__address {
  margin: 0;
  color: #555;
  font-size: 14px;
  line-height: 1.7;
}

.store-info-details__phone-wrapper {
  display: inline-flex;
  align-items: center;
  margin-bottom: 19px;
  font-size: 14px;
}

.store-info-details__phone-wrapper > svg {
  margin-right: 8px;
}
.store-info-details__table-wrapper {
  position: relative;
}

.store-info-details__table-wrapper > svg {
  position: absolute;
  top: 8px;
  left: 0;
  width: 20px;
  height: 20px;
}

.store-info-details__table {
  position: relative;
  width: 100%;
  padding-left: 30px;
}

.store-info-details__hours--today {
  font-weight: 500;
}

.store-info-details__table td {
  padding: 3px 0;
  font-size: 14px;
}

.store-info-details__table td[align="left"] {
  width: 40%;
  text-transform: capitalize;
}

.store-info-details__table td[align="right"] {
  width: 60%;
}

.store-info-details__table-row--active {
  font-weight: 600;
}

@keyframes slideInStoreInfoDetails {
  to {
    opacity: 1;
    transform: initial;
  }
}

@keyframes slideOutStoreInfoDetails {
  from {
    opacity: 1;
    transform: initial;
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}
