.availableGiftCardsContainer {
  margin: 0;
  padding: 20px 50px;
}

.carouselHeadingContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.1rem;
  padding-left: 0.15rem;
}

.carouselHeadingContainer > h1 {
  margin: 0;
  font-size: 1.5em;
  font-weight: 500;
}

.giftCardCarouselSection {
  margin-bottom: 2.5rem;
  opacity: 1;
  transform: none;
}
.giftCardsListPaddingTop {
  padding-top: 1.2em;
}
.giftCardDetailsConfirmedMessageListContainer {
  display: flex;
  justify-content: center;
}
.giftCardDetailsConfirmedMessageList {
  list-style: disc !important;
  max-width: 600px;
  white-space: pre-wrap;
  text-align: left;
  margin: 0 !important;
}
.giftCardDetailsConfirmedMessageList > li > p {
  margin-top: 0;
}
.gcOrderConfirmPadding {
  padding-top: 3rem;
}
.gcMenuBreadcrumbs {
  margin-bottom: 1.9rem;
}
.gcOrderConfirmationCheckmark svg {
  stroke: #fff;
}

.gift-card-tabs span.MuiTab-wrapper {
  text-wrap: wrap;
}

@media screen and (max-width: 560px) {
  .availableGiftCardsContainer {
    margin: 0;
    padding: 20px 0 20px 0;
  }

  .carouselHeadingContainer {
    padding-right: 1.5em;
    padding-left: 20px;
  }

  .categorizedCardContainer {
    margin: 1.5em;
    margin-left: 20px;
    margin-top: 0;
  }

  .categorizedCardContainer ul {
    padding-left: 0 !important;
  }
  .giftCardDetailsConfirmedMessageList {
    padding-left: 1em;
    padding-right: 1em;
    max-width: 100%;
  }
}
