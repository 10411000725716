.menu-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.2em;
}

.menu-breadcrumbs__item {
  font-weight: 500;
  line-height: 100%;
}

.menu-breadcrumbs__link {
  color: var(--color-secondary);
}

.menu-breadcrumbs > .icon {
  width: 10px;
  height: 10px;
  margin: 0 0.5em;
  fill: #2a2a2a;
}
