.dashboard__module {
  transform: translateY(40%);
}

.dashboard__order-module {
  /* fix for expanded custom dropdowns cut-off by the module below */
  position: relative;
  z-index: 1;
}

.dashboard__module--horizontal-slider {
  /* for horizontal slider to go off-screen on the right */
  padding-right: 0;
}

.dashboard--animate .dashboard__module {
  animation: slideUpDashboardSection 0.25s forwards;
  animation-timing-function: ease-out;
}

.dashboard__module-subheading {
  margin: 0 0 4px;
  color: #757575;
  font-size: 0.8em;
  font-weight: 400;
}

.dashboard__notification {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  color: #fff;
  background-color: #2a2a2a;
  z-index: 800;
}

.dashboard__notification--alert {
  background-color: #d34146;
}

.dashboard__notification-text {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 0.9rem;
}

.dashboard__notification .icon {
  width: 18px;
  height: 18px;
  color: #fff;
}

.dashboard__notification .icon--triangle-alert {
  margin-right: 4px;
}

.dashboard__close-notification {
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.dashboard__notification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #989898b8;
  animation: fadeIn 300ms;
  z-index: 900;
}

.dashboard__confirm-notification {
  display: block;
  min-width: 153px;
  height: 36px;
  margin-top: 30px;
  margin-left: auto;
  padding: 0 24px;
  color: #fff;
  text-transform: uppercase;
  border: 1px solid #fff;
  border-radius: 4px;
}

@keyframes slideUpDashboardSection {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDownTimeStampContainer {
  from {
    transform: translateY(-6em);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideUpTimeStampContainer {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-15em);
  }
}

.last-login-timestamp__container {
  z-index: 9999;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  padding: 0.5em 1.5em;
  background-color: #fbfbfb;
  color: black;
  box-shadow: 0px 2px 3px 0px rgb(148 148 148 / 20%);
}

.last-login-timestamp__container_slidedown {
  animation: slideDownTimeStampContainer 1s forwards;
}

.last-login-timestamp__container_slideup {
  animation: slideUpTimeStampContainer 1s forwards;
}

.dashboard-header {
  position: sticky;
  top: 0;
}

.dashboard-main-content {
  background-color: transparent;
}

#main-content-dashboard {
  background-color: #f5f5f5;
}

.dashboard-sections-container {
  margin-top: -20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #f5f5f5;
  position: relative;
  padding: 20px 0px 5em 20px;
}

.dashboard-main-content .additional-disclaimer {
  padding-left: 24px;
  padding-right: 24px;
}

.xp-subscription-container > h2 {
  margin: 0 0 0.4em 0;
}

.dashboard-scroll-up-button {
  position: fixed;
  right: 16px;
  bottom: 135px;
  background-color: #ffffff;
  opacity: 0.8;
  border-radius: 50%;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px 0px #00000026;
  z-index: 1100;
}

.dashboard-scroll-up-button > .icon {
  stroke: black;
}

.dashboard-scroll-up-button.hidden {
  opacity: 0.8;
  animation: scrollButtonFadeOutMobile 0.5s forwards;
}

.dashboard-scroll-up-button.not-visible {
  display: none;
}

@media screen and (min-width: 660px) {
  .dashboard__module:first-child {
    margin-bottom: 2%;
  }

  .dashboard-main-content {
    font-size: 21px;
    overflow-x: visible;
    overflow-y: visible;
  }

  .dashboard__module-subheading {
    margin-bottom: 12px;
  }

  .last-login-timestamp__container {
    z-index: 9999;
    position: fixed;
    right: 0;
    top: unset;
    width: 100%;
    padding: 1em;
    background-color: #fbfbfb;
    color: black;
    text-align: center;
    box-shadow: 0px -2px 3px 0px rgb(148 148 148 / 39%);
  }

  @keyframes slideDownTimeStampContainerDesktop {
    from {
      bottom: 0;
    }

    to {
      bottom: -8em;
    }
  }

  @keyframes slideUpTimeStampContainerDesktop {
    from {
      bottom: -8em;
    }

    to {
      bottom: 0;
    }
  }

  .last-login-timestamp__container_slidedown {
    animation: slideUpTimeStampContainerDesktop 1s forwards;
  }

  .last-login-timestamp__container_slideup {
    animation: slideDownTimeStampContainerDesktop 1s forwards;
  }

  .dashboard-sections-container {
    border-radius: 0px;
    margin-top: 0;
  }

  .dashboard-scroll-up-button {
    position: fixed;
    right: 50px;
    bottom: 120px;
    background-color: #ffffff;
    border-radius: 50%;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 2px 0px #00000026;
    z-index: 1100;
    opacity: 0;
    animation: scrollButtonFadeInDesktop 0.5s forwards;
  }

  .dashboard-scroll-up-button.hidden {
    opacity: 1;
    animation: scrollButtonFadeOutDesktop 0.5s forwards;
  }

  .dashboard-action-buttons-container {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
    margin-bottom: 15px;
  }
  .dashboard-action-buttons-container > .dashboard-action-button {
    margin-bottom: 10px !important;
    margin-right: 1em !important;
    flex: calc(25% - 1em) 1;
  }

  .dashboard-action-buttons-container > .dashboard-action-button:nth-child(4) {
    margin-right: 0 !important;
  }

  .dashboard-action-buttons-container > .dashboard-action-button:last-child {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 660px) {
  .dashboard-main-content .additional-disclaimer {
    margin-bottom: 6em;
  }

  .dashboard-action-buttons-container {
    padding-right: 20px;
  }
  .dashboard-action-buttons-container > .dashboard-action-button {
    margin-bottom: 10px !important;
  }
}
/* Ipad */
@media screen and (min-width: 661px) and (max-width: 1024px) {
  .dashboard-action-buttons-container {
    justify-content: space-between;
  }
  .dashboard-action-buttons-container
    > .dashboard-action-button#dashboard-rewards-button:not(:first-child) {
    margin-right: 0 !important;
  }
  .dashboard-action-buttons-container > .dashboard-action-button {
    flex: calc(45% - 1em) 1;
    max-width: 49%;
  }

  .dashboard-action-buttons-container > .dashboard-action-button:nth-child(2) {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 1248px) {
  .dashboard-sections-container .desktop-container {
    /*padding-left: 24px;
    padding-right: 24px;*/
  }
}

@keyframes scrollButtonFadeInMobile {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.8;
  }
}

@keyframes scrollButtonFadeInDesktop {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scrollButtonFadeOutMobile {
  from {
    opacity: 0.8;
  }

  to {
    opacity: 0;
  }
}

@keyframes scrollButtonFadeOutDesktop {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
