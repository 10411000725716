.register-form-wrapper {
  width: 85%;
  margin: 3em auto;
  background-color: #fff;
  animation: fadeIn 0.3s forwards;
  max-width: 1200px;
}

.form-register {
  min-height: 130px;
  margin-bottom: 23px;
}

.form-register__rewards-step {
  min-height: 190px;
  margin-bottom: 0;
}

.form-register__request-rewards-card {
  min-height: 99px;
}

.form-register__password-step {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 218px; /* with "Keep me logged-in" checkbox */
  margin-bottom: 0;
}

.form-register__field-wrapper {
  opacity: 0;
  transform: translateX(100vw);
  top: 0;
  width: 100%;
  text-align: center;
}

.form-register__checkbox-field-wrapper {
  top: auto;
  bottom: 68px;
}

.form-register__field-wrapper--active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 2s, transform 1s;
}

.form-register__field-wrapper--inactive {
  height: 0;
}

.button--form-next,
.button--form-previous {
  display: block;
  width: 50%;
  margin: 0 auto;
  font-weight: 600;
  text-transform: uppercase;
}

.button--form-previous {
  margin-right: 15px;
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1);
}

.button--form-previous-inactive {
  transform: translateX(-100vw);
}

.button--form-previous-active {
  transform: translateX(0);
  transition: transform 1s;
}

.button--form-slide-right {
  transform: translateX(5px);
  transition: transform 1s;
}

.button-form-slide-middle {
  transform: translateX(-50%);
  transition: transform 1s;
}

.form-register__stepper {
  opacity: 0;
  color: #757575;
  font-size: 0.8rem;
  text-align: center;
  animation: fadeIn 0.2s forwards;
}

.register-form-wrapper select {
  background-color: white;
}

.button-wrapper--register-account {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.register-form-buttons-wrapper {
  display: flex;
  justify-content: space-between;
}

.button--form-next-inactive {
  transform: translateX(100vw);
}

.form--registration > .form__fieldset > .form-heading {
  margin: 0;
}

.form--registration > .form-submit__wrapper {
  text-align: center;
  margin-top: 3em;
}

.registration-form__custom-label-styling {
  align-items: baseline;
}

@media screen and (min-width: 660px) {
  .form__field-wrapper--register-checkbox {
    flex-direction: unset !important;
  }
}
