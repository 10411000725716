.login-register__form-wrapper {
  bottom: 85px;
  left: 0;
  right: 0;
  padding: 0 35px;
  width: 100%;
  margin: 35px auto;
}

.forgot-password__confirm-email-message {
  width: 100%;
  margin: 0.5em auto 0.5em auto;
}

.forgot-password__form-wrapper,
.login-temporary-password__form-wrapper,
.new-password__form-wrapper {
  transform: translateX(100vw);
  animation: slideInLoginRegisterForm 0.5s forwards;
}

.login-temporary-password__form-wrapper {
  padding: 0px;
}

.forgot-password__main-wrapper {
  padding: 4em 2em 2em 2em;
}

.login-register__bottom-link-wrapper {
  opacity: 0;
  bottom: 10px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  animation: fadeIn 0.2s forwards;
}

.link--forgot-password-wrapper {
  position: relative;
  animation-delay: 1s;
}

.user-is-greeted .link--continue-as-guest-wrapper {
  opacity: 1;
  animation-delay: 0s;
}

.login-register__bottom-link {
  display: inline-block;
  height: 44px;
  padding: 0px 18px;
  color: var(--color-secondary);
  font-size: 1.1rem;
  line-height: 44px;
  font-weight: 500;
}

.link--continue-as-guest-fade-out,
.greeting-message-fade-out {
  animation: fadeOut 0.3s forwards;
}

@keyframes slideInLoginRegisterForm {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.login__form-wrapper .form__field {
  border-radius: 0;
  width: 100%;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #cdcdcd;
}

.login__form-wrapper .form__field-label {
  display: block;
  text-align: left;
  position: initial;
}

/* .login__form-wrapper .form__field-wrapper--in-focus > .form__field-label {
  transform: none;
  transition: none;
} */

@media screen and (max-width: 660px) {
  .login-register__main-container {
    padding-bottom: 0;
  }
}

.login__form-wrapper .form-submit__wrapper {
  /*padding: 0 20%;*/
}

@media screen and (min-width: 660px) {
  .forgot-password__confirm-email-message {
    margin: 2.5em auto 2.5em auto;
    display: flex;
    justify-content: center;
  }

  .button--submit-form-wrapper {
    margin-top: 3em;
  }

  .login-register__form-wrapper {
    padding: 60px 60px 60px;
    margin: unset;
  }

  .login-register__main-container {
    display: flex;
    justify-content: center;
  }

  .login-register__main-wrapper {
    max-width: 60%;
    min-width: 60%;
    border-radius: 10px;
    border: 1px solid var(--color-gray-light);
    margin-top: 2em;
    height: auto;
    background-color: #fff;
  }

  .login-or-register-buttons {
    position: relative;
    padding-top: 70px;
  }

  .login-register__main-wrapper {
    margin-top: 1em;
  }

  .login-temporary-password__form-wrapper {
    margin-top: 2.5em;
    padding: 0px;
  }

  .new-password__form-wrapper {
    margin-top: 2.5em;
    padding: 0px;
  }

  .forgot-password__main-wrapper {
    padding: 4em;
  }
}

@media screen and (min-width: 1028px) {
  .login-register__main-wrapper {
    max-width: 45%;
    min-width: 45%;
  }

  .login-temporary-password__form-wrapper {
    margin-top: 2.5em;
    padding: 0px;
  }

  .new-password__form-wrapper {
    margin-top: 2.5em;
    padding: 0px;
  }

  .forgot-password__main-wrapper {
    padding: 4em;
  }
}
