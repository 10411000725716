/* App Footer's Item styles */
.footer__nav-item {
  flex: 1;
  width: 10%;
}

.footer__nav-item--settings {
  flex-grow: 0;
}

.footer__nav-item + .footer__nav-item--settings {
  margin-left: 12px;
}

.footer__nav-item--settings .footer__nav-button {
  justify-content: center;
  width: 38px;
  height: 100%;
}

.footer__nav-button,
.footer__nav-link {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 0 12px;
  line-height: 1.15;
}

.footer__nav-item--active-module .footer__nav-button,
.footer__nav-item--active-module .footer__nav-link {
  color: var(--color-secondary);
}
.giftCardFooterIcon {
  fill: var(--color-footer-text-and-icon-colour);
}
.footer__nav-item--active-module .footer__nav-button > svg.giftCardFooterIcon,
.footer__nav-item--active-module .footer__nav-link > svg.giftCardFooterIcon {
  fill: var(--color-header-footer-active-text-colour);
}

.footer__nav-item--active-module .footer__nav-link svg {
  fill: var(--color-secondary);
}

.footer__nav-button > svg,
.footer__nav-link > svg {
  height: 20px;
  margin-bottom: 3px;
  stroke: var(--color-footer-text-and-icon-colour);
}

.footer__nav-link > svg.icon--qr,
.footer__nav-button > svg.icon--utensils {
  stroke: none;
  fill: var(--color-footer-text-and-icon-colour);
}

.footer__nav-item--settings .footer__nav-button > svg {
  margin-bottom: 0;
}

.footer__nav-item--active-module .footer__nav-button > svg,
.footer__nav-item--active-module .footer__nav-link > svg {
  stroke: var(--color-header-footer-active-text-colour);
  fill: var(--color-header-footer-active-text-colour);
}

.footer__nav-item--active-module .footer__nav-link > svg.icon--star {
  fill: transparent;
}

.footer__nav-item--active-module .footer__nav-button > .icon--utensils,
.footer__nav-item--active-module .footer__nav-link > .icon--utensils {
  fill: var(--color-header-footer-active-text-colour);
}

.footer__nav-item--active-module .footer__nav-button > .icon--qr,
.footer__nav-item--active-module .footer__nav-link > .icon--qr {
  stroke: none;
  fill: var(--color-header-footer-active-text-colour);
}

.footer__nav-item-title {
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  pointer-events: none;
}
div.giftCardMobileLinkDotIndicator{
  position: relative;
}
div.giftCardMobileLinkDotIndicator span{
  position: absolute;
  right: -2px;
  top: 3px;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: var(--color-gift-nav-link-indicator-colour);
}
/* END OF App Footer's Item styles */

/** For small mobile devices such as iphone SE */
@media screen and (max-width: 390px) {
  .footer__nav-item span {
    font-size: 10px;
  }
}
