/* TODO: organize this better and fix class names to follow BEM */

.scan-in-store-main-content {
  padding: 1em 0 calc(57px + 45px);
  background-color: #f5f5f5;
}

.scan-in-store__active-card-info-and-qr-code-info {
  padding-left: 24px;
  padding-right: 24px;
}

.scan-in-store__active-card-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5em;
}

.scan-in-store-active-card-info__points-and-balance {
  margin-right: 1em;
}

.scan-in-store-active-card-info__points-and-star {
  display: flex;
  align-items: center;
}

.scan-in-store-active-card-info__points {
  margin-right: 4px;
  font-size: 1.5rem;
  font-weight: 500;
}

.scan-in-store-active-card-info__points-and-star > .icon--star {
  stroke: none;
  fill: #f49c00;
  width: 28px;
  height: 28px;
}

.scan-in-store-active-card-info__balance {
  font-size: 14px;
  font-weight: 500;
}

.pay-in-store__select-giftcard-wrapper {
  position: relative;
}

.pay-in-store__select-giftcard {
  height: 40px;
  padding-right: 21px;
  appearance: none;
  border: none;
  background-color: transparent;
}

.pay-in-store__select-giftcard:disabled {
  opacity: 1;
}

.pay-in-store__select-giftcard-wrapper > .icon--arrow-down {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.icon--gold-star {
  position: relative;
  top: 2px;
  left: 5px;
  width: 20px;
  height: 20px;
  fill: #333;
}

.payInStore__selectedCard-card-container {
  margin-bottom: 2em;
  background-color: #fff;
}

.payInStore__selectedCard-card {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 15px 1px rgb(0 0 0 / 26%);
  overflow: hidden;
}

.scan-in-store__qr-code-content {
  padding: 1.5em;
}

.payInStore__selectedCard-barcode-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
}

.payInStore__selectedCard-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -0.3em;
  margin-right: -0.3em;
}

.payInStore__selectedCard-buttons > .button--primary-small {
  flex: 1;
  width: auto;
  margin: 0.2em 0.3em;
  margin-right: 0.3em !important;
  padding-left: 1em;
  padding-right: 1em;
}

.payInStore__selectedCard-buttons > .payment-method-section__details-wallet-link {
  margin-bottom: 1em;
}

.payInStore__selectedCard-barcode-number {
  text-align: center;
  letter-spacing: 7px;
  margin-bottom: 0.8em;
}

.payInStore__selectedCard-barcode {
  min-height: 70px;
}

.payInStore__selectedCard-barcode > svg {
  display: block;
  margin: 0 auto;
  width: 200px;
  height: 200px;
}

.payInStore__additional-cards-container h3 {
  margin-bottom: 0;
}

.scan-in-store-qr-card__refresh-code-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0 auto 1.2em;
  padding-left: 2em;
  padding-right: 2em;
}

.scan-in-store-qr-card__refresh-code-button > .icon--refresh {
  width: 0.8em;
  height: 0.8em;
  margin-right: 4px;
  color: var(--color-button-text);
}

.scan-in-store__add-money-and-autoreload {
  padding: 1em 1.5em;
}

.scan-in-store__autoreload-status {
  margin-top: 0;
  margin-bottom: 1.5em;
}

.add-money {
  padding: 10px 18px;
  font-size: 13px;
  line-height: 100%;
  background-color: var(--color-primary);
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  color: var(--color-button-text);
  font-weight: 500;
}

.add-money > .icon--plus {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  color: var(--color-button-text);
}

.barcode-type {
  height: 40px;
  color: var(--color-secondary);
  font-size: 14px;
  font-weight: 500;
}

.add-new-card-button {
  background-color: #e2e2e2;
  border: dashed 1px #c7c7c7;
  border-radius: 5px;
  margin-right: 2%;
  margin-bottom: 2%;
  display: inline-block;
  position: relative;
  max-height: 25vw;
}

.payInStore__wallet-cards {
  display: flex;
  flex-wrap: wrap;
}

.payInStore__wallet-cards > div,
.payInStore__wallet-cards > button {
  flex: 0 48%;
  font-size: 14px;
}

.payInStore__add-new-card {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1.2;
}

.additional-card > img {
  border-radius: 5px;
  min-height: 25vw;
  max-height: 25vw;
}

.additional-card {
  margin-right: 2%;
  margin-bottom: 2%;
}

.additional-card-details {
  color: #8e8d8d;
  font-size: 12px;
}

.additional-card-selected {
  position: relative;
}
.additional-card-selected > .additional-card-details {
  color: black;
}

.additional-card-selected > .icon--check {
  stroke: #fff;
  stroke-width: 3px;
  width: 20px;
  height: 20px;
  padding: 3px;
  background-color: #4caf52;
  border-radius: 100%;
  position: absolute;
  right: -3px;
  top: -3px;
}

.scan-in-store-active-card-info__expiry {
  max-width: 150px;
  word-wrap: break-word;
  font-size: 13px;
  color: #8e8d8d;
  font-weight: 500;
  margin-top: 5px;
  margin-left: 5px;
}

.payInStore__how-to-container {
  padding: 5%;
}

.payInStore__how-to-container > h2 {
  margin: 0 0 12px;
  font-size: 1.25rem;
}

.payInStore__how-to-container > p {
  margin: 0 0 12px;
}

.payInStore__how-to--add-card {
  margin-top: 40px;
  text-align: right;
}

.scan-in-store__gift-cards-header-and-transfer-funds {
  padding-left: 24px;
  padding-right: 24px;
}

.scan-in-store-gift-cards-section__header {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.scan-in-store-gift-cards-section__header > .icon--gift {
  width: 21px;
  height: 21px;
  margin-right: 5px;
}

.scan-in-store-gift-cards-section__link {
  display: flex;
  align-items: center;
}

.scan-in-store-gift-cards-section__link + a {
  margin-top: 0.5em;
}

.scan-in-store-gift-cards-section__link > .icon--plus-circle {
  fill: #fff;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.scan-in-store-gift-cards-section__transfer-funds:not(:empty) {
  margin-bottom: 2em;
}

.scan-in-store-gift-cards-section__transfer-funds-text {
  margin-top: 0;
}

.scan-in-store__gift-cards-list {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.scan-in-store-gift-cards-list__item {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 1em 24px 1em calc(24px + 20px + 5px);
  background-color: #fff;
}

.scan-in-store-gift-cards-list__item + li {
  margin-top: 5px;
}

.scan-in-store-gift-cards-list-item-info__icon-and-masked-number {
  display: flex;
  align-items: center;
}

.scan-in-store-gift-cards-list-item-info__icon-and-masked-number {
  margin-bottom: 0.5em;
}

.scan-in-store-gift-cards-list-item-info__icon-wrapper {
  position: absolute;
  top: 1em;
  left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background-color: #4caf52;
  border-radius: 50%;
}

.scan-in-store-gift-cards-list-item-info__icon-wrapper > .icon--check {
  width: 12px;
  height: 12px;
  stroke: #fff;
  stroke-width: 3px;
}

.scan-in-store-gift-cards-list-item-info__masked-number {
  color: #2a2a2a;
  font-size: 15px;
}

.scan-in-store-gift-cards-list-item-info__name-and-expiry {
  color: #757575;
  font-size: 12px;
}
