/* LoginRegisterUserAlreadyExists.css */
.register-user-exists {
  width: 100%;
  margin-top: 3em;
  z-index: 1000;
  animation: fadeIn 1s forwards;
  height: 100%;
}

.register-user-exists__content {
  position: relative;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -40%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
}

.register-user-exists__header {
  position: relative;
  width: 210px;
  height: 210px;
  margin-bottom: 36px;
}

.register-user-exists__questionMarks {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.questionMark {
  position: absolute;
}

.questionMark:nth-child(even) {
  animation: floatQuestionMark 3s infinite;
}

.questionMark:nth-child(odd) {
  animation: floatQuestionMark 6s infinite;
}

@keyframes floatQuestionMark {
  0% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0.5;
  }
}

.register-user-exists__heading {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -65%);
  margin: 0;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.75rem;
}

.register-user-exists__text {
  margin: 0 0 30px;
  font-size: 0.9rem;
  font-weight: 600;
  text-align: left;
}

.button--submit-form {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 50px;
  font-weight: 600;
  text-transform: uppercase;
}

.button--submit-form + .button--submit-form {
  margin-top: 20px;
}

.modal--fullscreen.register-user-exists__modal > h1 {
  margin: 30px 0 0;
  text-align: center;
  font-size: 1.8rem;
}

.register-user-exists__singin-button {
  position: relative;
  display: block;
  margin: 40px auto;
  height: 50px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 50px;
}
/* END OF Dashboard Welcome Overlay */
