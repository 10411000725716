.combo-item-detail__option-button {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.combo-item-detail__option-button > .icon--check {
  stroke: #fff;
  stroke-width: 3px;
  width: 18px;
  height: 18px;
  padding: 3px;
  background-color: #4caf52;
  border-radius: 100%;
}

.menu__category-card.chosen-combo {
  border: 2px solid #4caf52;
}
