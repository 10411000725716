select.form__field {
  appearance: none;
}
.selectIconStart {
  position: absolute;
  z-index: 2;
  margin: 0;
  padding: 0;
  left: 20px;
  bottom: 12px;
  font-size: 1.1rem;
  font-weight: 500
}
/* .form__field-wrapper--in-focus > .form__field-label {
  font-size: 12px;
  transform: translateY(-9px);
} */

.gcAmountSelectDisabled{
  opacity: 1 !important;
  border: none !important;
  pointer-events: none;
}