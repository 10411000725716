.formSelectInputCombo {
  position: relative;
  display: flex;
  width: 100%;
}
.formSelectInputComboSelect{
  min-width: 100%;
  position: relative;
}
.comboSelectField{
  min-width: 100%;
}
.formSelectInputComboSelect.gcSelectInputShrink{
  min-width: 50%;
  position: relative;
}
.gcSelectInputShrink .comboSelectField{
  min-width: 50%;
}
.formSelectInputComboInput{
  position: relative;
  margin-left: 1em;
  display: flex;
  width: 100%;
}
.formSelectInputCombo>input {
  padding-left: 1em;
}
.formSelectInputComboInput label {
  position: absolute;
  top: -71px;
  left: 0;
}
@media screen and (max-width: 660px) {
  .formSelectInputCombo {
    flex-direction: column;
  }
  .formSelectInputComboSelect{
    min-width: 100%;
  }
  .formSelectInputComboInput{
    margin-top: 5em;
    margin-left: 0;
  }
}