.account-form-modal-container {
  width: 100%;
}

.account-form-modal-container .modal__heading {
  margin-bottom: 44px;
  font-size: 1.7rem;
  text-align: center;
}

.account-main-content {
  padding-bottom: 85px;
}

.account-section {
  padding: 0;
}

.account-section--email-and-logout {
  margin-bottom: 18px;
  padding-top: 18px;
  padding-bottom: 18px;
  background-color: #f9f9f9;
}

.account-section:nth-child(2) + .account-section,
.account-section:nth-child(3) + .account-section {
  margin-top: 18px;
}

.account-section__email {
  margin-bottom: 0;
  font-weight: 600;
}

.account-section__logout-button {
  height: 36px;
}

.account-section__heading {
  margin: 0 0 18px;
  font-size: 1.25em;
}

.account-section__editable-items-list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.account-section__editable-items-list > .navListItemDivider {
  margin-top: 8px;
}

.account-section__editable-item + .account-section__editable-item {
  margin-top: 8px;
}

.account-section__edit-item-button,
.account-section__edit-item-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 36px;
}

.account-section__payment-methods-counter {
  margin-left: 4px;
}

.account-section__edit-item-button > .icon--arrow-right,
.account-section__edit-item-link > .icon--arrow-right {
  width: 12px;
  height: 12px;
}

.modal-wrapper--account-update {
  background-color: #fff;
  z-index: 1151;
}

.modal-wrapper--account-update > .modal--fullscreen {
  padding-bottom: 125px;
}

.account-form-modal-container
  .form__field-wrapper--checkbox-radio
  + .form__field-wrapper--checkbox-radio {
  margin-top: 10px;
}

/* .account-form-modal-container .form__field-wrapper--in-focus > .form__field-label {
  transform: none;
} */

.account-form-modal-container .form__field {
  height: 42px;
  font-size: 1rem;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #dedede;
}

.account-form-modal-container .form__field:focus {
  border-color: var(--color-secondary);
}

.account-form-modal-container .form__field::placeholder {
  color: #aaa;
}

.account-form-modal-container .form__field:focus::placeholder {
  color: #aaa;
  transition: none;
}

.account-form-modal-container .form__field + .icon--arrow-down {
  top: auto;
  bottom: 6px;
  right: -6px;
  width: 25px;
  height: 25px;
}

.account-form-modal-container .current-field-value {
  border-bottom: none;
}

/* TEMP: delete once link destinations are set up */
.account-section__recent-loyalty-transactions-link,
.account-section__recent-card-transactions-link {
  pointer-events: none;
}

/** Mobile only **/
@media screen and (max-width: 660px) {
  .account-form-modal-container {
    padding-bottom: 5em;
  }

  .account-deletion-submit-button-wrapper {
    margin-top: 4em;
  }

  .account-deletion-back-to-home > a {
    width: unset;
    min-width: unset;
    padding: 1em 5em;
  }

  .account-deletion-back-to-home {
    margin-top: 6em;
    display: flex;
    justify-content: center;
  }

  .account-deletion-main-content,
  .account-deletion-confirmation-main-content {
    margin-top: 1em;
  }
}

.request_account_deletion {
  color: var(--color-error);
}

.giftCardRecentOrdersNavListItem {
  border-top: 1px solid #e0e0e0;
  width: 100%;
  padding-top: 0.25rem;
}

@media screen and (max-width: 660px) {
  .giftCardRecentOrdersNavListItem {
    border: none;
    padding-top: 0;
  }
}

.navListItemDivider {
  border-top: 1px solid #e0e0e0;
  width: 100%;
  padding-top: 0.25rem;
  margin-top: 0.25rem;
}
/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .account-info__page-title,
  .account-info__change-password-page-title {
    background-color: #f7f7f7;
  }

  .account-info__page-title,
  .account-info__change-password-page-title {
    padding-top: 0.1em;
    padding-bottom: 0.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .account-info__change-password-page-title > div {
    margin: 1em 0;
  }

  .account-info__change-password-title {
    font-size: 1.2em;
  }

  /*.account-info__change-password-main-container {
    margin-right: 10%;
    margin-left: 10%;
    padding-bottom: 10em;
  }*/

  .form.form--account-update,
  .form--account-change-password {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 5em;
  }

  .form--account-update .form__field-wrapper,
  .form--account-change-password .form__field-wrapper {
    flex: 0 50%;
  }

  .form--account-update .form__field-wrapper:not(.form__field-wrapper--checkbox-radio),
  .form--account-change-password .form__field-wrapper:not(.form__field-wrapper--checkbox-radio) {
    margin-top: 3em !important;
  }

  .form--account-update
    .form__field-wrapper:nth-child(odd):not(.form__field-wrapper--checkbox-radio),
  .form--account-change-password
    .form__field-wrapper:nth-child(odd):not(.form__field-wrapper--checkbox-radio) {
    padding-right: 5em;
  }

  .form--account-update .form__field-wrapper:nth-child(odd) .icon--arrow-down {
    right: 72px;
  }

  .form--account-update
    .form__field-wrapper:nth-child(even):not(.form__field-wrapper--checkbox-radio),
  .form--account-change-password
    .form__field-wrapper:nth-child(even):not(.form__field-wrapper--checkbox-radio) {
    padding-left: 5em;
  }

  .form--account-update .form-submit__wrapper,
  .form--account-change-password .form-submit__wrapper {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 25% 3em 25%;
    background: rgb(255, 255, 255);
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(50%, rgba(255, 255, 255, 1)),
      to(rgba(255, 255, 255, 0.23573179271708689))
    );
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0.23573179271708689) 100%
    );
  }

  .delete-account-desktop-title {
    padding-top: 0.1em;
    padding-bottom: 0.5em;
    background-color: #f7f7f7;
    justify-content: center;
    display: flex;
    flex-direction: row;
  }

  .account-deletion-submit-button-wrapper {
    display: flex;
    margin-top: 4em;
  }

  .account-deletion-submit-button-wrapper > .form__error {
    display: flex;
    margin-left: 5em;
  }

  .account-deletion-back-to-home > a {
    width: unset;
    min-width: unset;
    padding: 1em 5em;
  }

  .account-deletion-back-to-home {
    margin-top: 6em;
    display: flex;
    justify-content: flex-start;
  }

  .account-deletion-title {
    font-size: 1.3em;
  }
  .account-deletion-subtitle {
    font-size: 1.5em;
  }

  .account-deletion-confirmation-main-content > .desktop-container,
  .account-deletion-main-content > .desktop-container {
    margin-top: 2em;
  }
  .navListItemDivider li {
    text-align: left;
  }
}

.change-password__floating-button,
.update_account_info__floating-button {
  bottom: 57px;
  padding-bottom: 17px;
}

.account__delivery-address-header {
  font-size: 1.5rem;
  padding: 0px 24px;
  margin-bottom: 0;
}

@media screen and (max-width: 660px) {
  .account-info__page-title,
  .account-info__change-password-page-title {
    background-color: #f7f7f7;
    margin-bottom: 1em;
  }

  .account-info__page-title > div,
  .account-info__change-password-page-title > div {
    margin: 2em 10%;
  }

  .account-info__main-container,
  .account-info__change-password-main-container {
    margin-right: 10%;
    margin-left: 10%;
    padding-bottom: 10em;
  }

  .desktop-header-container {
    padding-right: 5%;
    padding-left: 5%;
  }

  .account-info__form-wrapper {
    padding: 0 2em;
  }

  .form--account-update .form-submit__wrapper,
  .form--account-change-password .form-submit__wrapper {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 25% 5em 25%;
    background: rgb(255, 255, 255);
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(50%, rgba(255, 255, 255, 1)),
      to(rgba(255, 255, 255, 0.23573179271708689))
    );
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0.23573179271708689) 100%
    );
    display: none;
  }

  #form--change-password .form-submit__wrapper {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }

  #form--update-account-details .form-submit__wrapper {
    display: block;
    padding-left: 0;
    padding-right: 0;
    position: sticky;
    padding-bottom: 0;
    z-index: 999999;
  }

  .account-form-update-info-modal-container {
    position: relative;
    padding-bottom: 7em;
  }

  #form--change-password .button--submit-form-wrapper > button,
  #form--update-account-details .button--submit-form-wrapper > button {
    position: relative;
    display: block;
    width: 70%;
    height: 52px;
    margin: 0 auto;
    padding: 0 15px;
    color: var(--color-button-text);
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    background-color: var(--color-primary);
    border-radius: 9999px;
    z-index: 1;
  }
}

@media screen and (min-width: 660px) {
  .account__delivery-address-header {
    max-width: 1200px;
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
  }
  .giftCardRecentOrdersNavListItem {
    padding-top: 0;
  }
}

/** End of Desktop Layout **/
