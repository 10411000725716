.addMoney__container {
  width: 100%;
  overflow: auto;
  background-color: white;
  position: absolute;
  height: 100%;
  z-index: 1100;
}

.addMoney__label {
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.addMoney__cvv-label {
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: -25px;
}

.addMoney__auto-reload-container > span {
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
}

.addMoney__from-wrapper {
  padding: 7% 7%;
}

.addMoney__from-wrapper > .form__field-wrapper:first-child {
  margin-top: 0;
}

.addMoney__input-field {
  width: 100%;
  padding: 18px 18px 7px;
  color: #2a2a2a;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0 5px 5px 0;
  background-clip: padding-box;
}

#method-of-payment__select,
.addMoney__input-field,
#reload-frequency__select,
#gift-card__select {
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;
}

.money-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  height: 40px;
  text-align: center;
  background: #d0d0d0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.input-container {
  display: -ms-flexbox;
  /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.addMoney__auto-reload-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.add-money__credit-card-form {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1100;
  overflow: scroll;
}

.addMoney__stored-cc-details-container {
  display: flex;
}

.addMoney__cvv-container {
  margin-left: 10%;
}

.addMoney__cvv-input {
  margin-top: 25px;
  width: 100%;
  color: #2a2a2a;
  line-height: normal;
  background-color: #fff;
  background-clip: padding-box; /* removes iOS shadow */
  height: 35px;
  padding: 10px 12px;
  font-size: 1rem;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #dedede;
}

.addMoney__cvv-input-wrapper {
  display: inline-block;
  margin-top: -1.6em;
}

.addMoney__cvv-input-label {
  top: 0;
  bottom: 0;
  left: 18px;
  display: flex;
  align-items: center;
  line-height: normal;
  pointer-events: none;
  transition: font-size 0.3s, transform 0.3s;
  position: static;
  margin: 0 0 7%;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
}

.addMoney__stored-cc-container {
  margin-right: 10%;
}

.addMoney__title {
  margin: 1em 0;
}

.addMoney__stored-cc,
.addMoney__add-new-cc {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.addMoney__stored-cc > span,
.addMoney__add-new-cc > span {
  margin-left: 10px;
  font-weight: 500;
  font-size: 15px;
}

.addMoney__stored-cc > .icon--check {
  stroke: #fff;
  stroke-width: 3px;
  width: 18px;
  height: 18px;
  padding: 3px;
  background-color: #4caf52;
  border-radius: 100%;
}

.addMoney__stored-cc-expiry {
  padding-left: 30px;
  color: #676767;
  font-size: 12px;
}

.addMoney__add-new-cc > .icon--plus-circle {
  width: 25px;
  height: 25px;
  padding: 3px;
  stroke-width: 3px;
}

.addMoney__auto-reload-elements {
  position: relative;
  animation-name: moveupReloadElements;
  animation-duration: 0.5s;
}

@-webkit-keyframes moveupReloadElements {
  from {
    bottom: -200px;
  }

  to {
    bottom: 0px;
  }
}

@-moz-keyframes moveupReloadElements {
  from {
    bottom: -200px;
  }

  to {
    bottom: 0px;
  }
}

@-o-keyframes moveupReloadElements {
  from {
    bottom: -200px;
  }

  to {
    bottom: 0px;
  }
}

@keyframes moveupReloadElements {
  from {
    bottom: -200px;
  }

  to {
    bottom: 0px;
  }
}

.fadeOutContainer {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 500ms, visibility 500ms;
  transition: opacity 500ms, visibility 500ms;
}

.fadeInContainer {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 500ms, visibility 500ms;
  transition: opacity 500ms, visibility 500ms;
}

.slideUpContainer {
  transform: translateY(-70px);
  -webkit-transform: translateY(-70px);
  transition: all 600ms;
}

.slideDownContainer {
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  transition: all 600ms;
}

/** Slider Style **/
.switch {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #bfd72f;
}

input:focus + .slider {
  box-shadow: 0 0 1px #bfd72f;
}

input:checked + .slider:before {
  -webkit-transform: translateX(66px);
  -ms-transform: translateX(66px);
  transform: translateX(66px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/** End Of Slider Style **/

.addMoney__manual-reload-disbaled {
  visibility: hidden;
}

.addMoney__manual-reload-disbaled > * {
  display: none;
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .addMoney__container {
    z-index: 0;
  }

  .addMoney__from-wrapper {
    padding: 0% 7% 7%;
  }
}
/** End of Desktop Layout **/

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .addMoney__from-wrapper {
    padding: 0% 7% 7%;
    max-width: 100vw;
    margin: 0;
  }

  .FAB-wrapper.FAB-wrapper__addMoney {
    max-width: 100vw;
  }

  .FAB-wrapper.FAB-wrapper__addMoney .desktop-container {
    display: flex;
    justify-content: flex-end;
  }

  .FAB-wrapper.FAB-wrapper__addMoney .desktop-container > button {
    margin: 0;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .addMoney__from-wrapper {
    padding: 0% 7% 7%;
    max-width: 80vw;
    margin: 0;
  }

  .FAB-wrapper.FAB-wrapper__addMoney {
    max-width: 80vw;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .addMoney__from-wrapper {
    padding: 0% 7% 7%;
    max-width: 50vw;
    margin: 0;
  }

  .FAB-wrapper.FAB-wrapper__addMoney {
    max-width: 50vw;
  }
}

/** Mobile Devices Layout **/
@media screen and (max-width: 439px) {
  .addMoney__stored-cc,
  .addMoney__add-new-cc {
    margin-top: 0;
  }
}

/** End of Mobile Devices Layout **/
