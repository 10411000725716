.dashboard-welcome-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d64123;
  z-index: 1000;
}

.dashboard-welcome-overlay__content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-welcome-overlay__circle-mask {
  transform: scale(0);
  width: 210px;
  height: 210px;
  clip-path: circle(50%);
  margin-bottom: 36px;
  background-color: #fff;
  animation: scaleUpDashboardWelcomeCircleMask 0.3s forwards cubic-bezier(0.74, 1.03, 1, 1.13);
}

@keyframes scaleUpDashboardWelcomeCircleMask {
  100% {
    transform: scale(1);
  }
}

.dashboard-welcome-overlay__heading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%);
  margin: 0;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.75rem;
  text-align: center;
  white-space: nowrap;
}

.dashboard-welcome-overlay__loading-text {
  margin: 0 0 18px;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
}

.dashboard-welcome-overlay__loading-dots {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto;
}

.dashboard-welcome-overlay__loading-dot {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  animation: growWelcomeLoadingDot 1.5s infinite;
  animation-delay: 0.2s;
}

@keyframes growWelcomeLoadingDot {
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.dashboard-welcome-overlay__loading-dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dashboard-welcome-overlay__loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}

.dashboard-welcome-overlay__loading-dot:nth-child(4) {
  animation-delay: 0.5s;
}
