.order-status-content {
  background: linear-gradient(180deg, #eee, #eee 80%, #fff);
}

.order-status__header {
  padding-top: 10px;
  padding-bottom: 10px;
}

.order-status__header-wrapper {
  padding-left: 30px;
  padding-right: 30px;
}

.order-status__content-wrapper {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  margin-bottom: 0.2em;
}

.order-status__order-id {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
  font-size: 15px;
}

.order-status__order-info {
  border-bottom: 1px dashed #eee;
}

.order-status__order-id > span {
  display: flex;
  align-items: center;
}

.order-status__order-id > span > .icon--clock {
  margin-right: 5px;
  width: 13px;
  height: 13px;
  margin-bottom: 1px;
}

/** Order Details Section **/

.order-status__order-details-status-header {
  font-size: 1.25em;
  font-weight: bold;
  display: flex;
  margin-top: 10px;
  margin-bottom: 0px;
}

.order-status__order-details-confirm-label {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1.2rem;
}

.order-status__order-details-header {
  font-size: 1.25em;
  font-weight: bold;
}

.order-status__text-decoration {
  display: inline-flex;
  text-decoration: none;
}

.order-status__order-details-container {
  margin-top: 1em;
}

.order-status__order-details-button-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.order-status__order-details-button-wrapper > button {
  margin-right: 5px;
  color: var(--color-secondary);
  font-weight: 600;
  font-size: 17px;
}

.order-status__order-details-button-wrapper > .icon--arrow-right,
.order-status__order-id.order-status__collapsable-order-time-id > button > .icon--arrow-right {
  fill: var(--color-secondary);
  height: 13px;
  width: 13px;
  transform: rotate(0);
  transition: transform 0.25s ease-in;
}

.order-status__order-details-button-wrapper > .icon--arrow-right.is-down,
.order-status__order-id.order-status__collapsable-order-time-id
  > button
  > .icon--arrow-right.is-down {
  transform: rotate(90deg);
  transition: transform 0.25s ease-in;
}

.order-status__order-details-disclaimer {
  font-size: 0.8rem;
  border-bottom: 2px solid #e8e8e8;
  padding-bottom: 1.2em;
  display: block;
}

.order-status__order-details-phone-number {
  font-weight: bold;
}

.order-status__order-details {
  margin-bottom: 1em;
  overflow: hidden;
  height: 100%;
  transform: scaleY(1);
  transition: transform 5s ease-in;
}

.order-status__order-details-date-address {
  font-size: 16px;
  line-height: 1.5;
}

.order-status__order-details-date-address__header {
  font-weight: 600;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 0px;
}

.order-status__order-details-items-header,
.order-status__order-details-total {
  font-size: 16px;
  font-weight: 600;
}

.order-status__order-details-items-list {
  list-style-type: none;
  padding-left: 0;
}

.order-status__completed-order-reorder-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-status__completed-order-reorder-desktop-child {
  width: 100%;
  margin-top: auto;
}

.order-status__completed-order-reorder-desktop-child:nth-child(2) {
  width: 100%;
  margin-top: auto;
  display: inline-block;
  justify-content: right;
  text-align: -webkit-right;
}

.order-status__order-details-items-list-current-order {
  list-style-type: none;
  padding-top: 1.2em;
  padding-left: 0;
  border-top: 2px solid #e8e8e8;
}

.order-status__collapsable-container {
  padding-bottom: 30px;
}
.order-status__collapsable-order-time-id {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.order-status__content-wrapper.collapsable {
  padding-bottom: 0;
}

.order-status__collapsable-order-details {
  padding-bottom: 30px;
}

.order-status__collapsable-order-details .order-status__order-details-container,
.order-status__collapsable-order-details .order-status__order-details-items-list {
  margin-top: 0;
}

.order-status__collapsed-steps-wrapper {
  padding-top: 1em;
}

.order-status__last-update-timestamp {
  font-size: 12px;
}

/** END of Order Details Section **/

/** Mobile Only **/
@media screen and (max-width: 660px) {
  .order-status__content-wrapper {
    padding-right: 0;
    padding-left: 0;
  }

  .order-status__collapsable-order-time-status-wrapper,
  .order-status__order-details,
  .order-status__steps-wrapper {
    padding-left: 20px;
    padding-right: 30px;
  }
}

/** Desktop Layout **/

@media screen and (min-width: 660px) {
  .order-status__header-wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .order-status__content-main-wrapper {
    width: 100%;
    background-color: #fff;
  }

  .order-status__order-id {
    padding-top: 30px;
    margin: 0 auto;
  }

  .order-status__order-details-confirm-label {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 1.2rem;
  }

  .order-status__desktop-title {
    padding-top: 0.1em;
    padding-bottom: 0.5em;
    background-color: #f7f7f7;
    justify-content: center;
    display: flex;
    flex-direction: row;
  }
  .order-status__progress-bar-item:not(:last-child) {
    margin-right: 10%;
  }

  .order-status__progress-bar-item:first-child {
    min-width: unset;
  }

  .order-status__order-details-container {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .order-status__order-details-disclaimer {
    font-size: 14px;
  }
}

/** ENd of Desktop layout **/
