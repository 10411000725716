.transfer-funds__container {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #fff;
}

.transfer-funds__form_wrapper {
  padding: 3em 30px 1em 30px;
  height: 90%;
  overflow: auto;
}

.transfer-funds__success-message-reward-detail {
  margin: 0;
}

.transfer-funds__success-message-header-wrapper {
  display: flex;
  align-content: baseline;
  margin-bottom: 1em;
}

.transfer-funds__success-message-header-wrapper > div:first-child {
  display: flex;
  align-items: center;
}

.transfer-funds__success-message-header > span > .icon--check {
  background-color: #4caf52;
  border-radius: 50%;
  stroke: white;
  stroke-width: 4px;
  padding: 3px;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.transfer-funds__success-message-header-wrapper + .transfer-funds__success-message-reward-detail {
  margin-bottom: 8px;
}
.transfer-funds__success-message-reward-transaction {
  font-size: 14px;
}

.transfer-funds__success-message-header {
  font-weight: 700;
  font-size: 1.1em;
  margin: 0;
}

.transfer-funds__check-balance-button-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 2em 0;
  align-items: baseline;
}

.transfer-funds__check-balance-button-wrapper > div {
  width: 50%;
  flex-direction: row;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.transfer-funds__check-balance-button-wrapper > div > span > svg {
  top: 3px;
}

.transfer-funds__check-balance-button-wrapper > button {
  min-width: 45%;
  width: unset;
}

.form--transfer-funds {
  height: 100%;
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .transfer-funds__success-message-header > span > .icon--check {
    width: 24px;
    height: 24px;
  }

  .transfer-funds__success-message-reward-detail {
    font-size: 0.95em;
  }

  .transfer-funds__form_wrapper {
    padding-right: 5%;
    padding-left: 5%;
    display: flex;
    width: 65%;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }

  .transfer-funds__check-balance-button-wrapper > div {
    width: 28%;
  }
}

@media screen and (min-width: 1280px) {
  .transfer-funds__form_wrapper {
    padding-right: 10%;
    padding-left: 10%;
  }
}

/** End of Desktop Layout **/

/** Mobile Layout **/
@media screen and (max-width: 660px) {
  .transfer-funds__form_wrapper > form > .form-submit__wrapper {
    padding-bottom: 8em;
  }
}
/** End of Mobile Layout **/
