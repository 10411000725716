.forgot-password__heading {
  position: relative;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 1.5em;
}

.forgot-password__form-wrapper > form > .form__field-wrapper > input,
.new-password__form-wrapper > form > .form__field-wrapper > input {
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid #cdcdcd;
}

.forgot-password__form-wrapper > form > .form__field-wrapper > label,
.new-password__form-wrapper > form > .form__field-wrapper > label {
  left: 0;
}

.new-password__form-wrapper {
  padding: 0px;
}

#form--forgot-password > .form-submit__wrapper > .button--submit-form-wrapper > button {
  width: 75%;
}

@media screen and (min-width: 660px) {
  .forgot-password__main-wrapper {
    justify-content: center;
    max-width: 60%;
    min-width: 60%;
    border-radius: 10px;
    border: 1px solid var(--color-gray-light);
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    background-color: #fff;
  }

}

@media screen and (min-width: 1028px) {
  .forgot-password__main-wrapper {
    max-width: 45%;
    min-width: 45%;
  }
}
