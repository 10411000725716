.cart-items-collapsable__content-buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 0 30px 10px 30px;
  width: 100%;
  background-color: var(--color-gray-light);
}

.cart-items-collapsable__content-buttons-container > button,
.cart-items-collapsable__content-buttons-container > a {
  color: var(--color-secondary);
  font-size: 0.8em;
  font-weight: 600;
}

@media screen and (min-width: 850px) {
  .cart-items-desktop {
    width: 100%;
  }
}
