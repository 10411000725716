/* Modal.css */
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal-wrapper--select-order-store {
  z-index: 1100;
}

.modal-wrapper--fullscreen {
  background-color: transparent;
}

.modal-wrapper--transparent {
  background-color: rgba(244, 244, 244, 0.97);
}

.modal {
  opacity: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding: 25px;
  border-radius: 8px;
}

.modal--default {
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* enables iOS "momentum" style scrolling */
  transform: translateY(100%) scale(0);
  transform-origin: center bottom;
  animation: slideInFromBottomAndGrow 0.3s forwards;
  background-color: #fff;
}

@keyframes slideInFromBottomAndGrow {
  100% {
    transform: initial;
    opacity: 1;
  }
}

@keyframes slideOutToBottomAndShrink {
  0% {
    transform: initial;
    opacity: 1;
  }
  100% {
    transform: translateY(100%) scale(0);
    opacity: 0;
  }
}

.modal--fullscreen {
  transform: translateX(100%);
  width: 100%;
  height: 100%;
  padding: 63px 25px 85px;
  border-radius: 0;
  animation: slideInFromRight 0.3s forwards;
  overflow-y: auto;
}

.modal--vertical-animation > .modal--fullscreen {
  transform: translateY(100%);
  animation: slideInFromBottom 0.3s forwards;
}

.modal--fullscreen.modal--static {
  opacity: 1;
  transform: initial;
  width: 100%;
  height: 100%;
  padding: 85px 25px;
  border-radius: 0;
  animation: none;
}

@keyframes slideInFromRight {
  100% {
    transform: initial;
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  100% {
    transform: initial;
    opacity: 1;
  }
}

.modal--slideOutToRight {
  animation: slideOutToRight 0.2s forwards;
}

@keyframes slideOutToRight {
  0% {
    transform: initial;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.modal--iframe {
  width: 90%;
  height: 90%;
  transform: translateY(100%) scale(0);
  transform-origin: center bottom;
  animation: slideInFromBottomAndGrow 0.3s forwards;
}

.modal--slideOutToBottomAndShrink {
  animation: slideOutToBottomAndShrink 0.2s forwards;
}

.modal__button--close,
.modal__link--back {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

.modal--fullscreen .modal__button--close,
.modal--fullscreen .modal__link--back {
  top: 9px;
  left: 9px;
}

.modal--default .modal__button--close,
.modal--default .modal__link--back {
  top: 0;
  right: 0;
}

.modal__heading {
  margin: 0 0 20px;
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.2;
}

.modal--fullscreen .modal__heading {
  font-size: 1.5rem;
}

.modal__buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modal--default .form-submit__wrapper .button,
.modal__buttons > button {
  /* min-width: 80px; /* to avoid pill-shaped button to turn into a circle 
  width: auto;
  height: 40px;
  font-size: 0.8rem;
  text-transform: uppercase;*/
}

.modal--transparent {
  background-color: transparent;
}

.modal-wrapper--recent-locations-closes-soon {
  z-index: 1100;
}

.modal-wrapper--bottom-aligned {
  align-items: flex-end;
}

.modal--bottom-aligned {
  align-items: flex-start;
  width: 100%;
  max-height: 90%;
  padding-top: 48px;
  padding-bottom: 48px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* enables iOS "momentum" style scrolling */
  transform: translateY(100%) scale(0);
  transform-origin: center bottom;
  animation: slideInFromBottomAndGrow 0.3s forwards;
  background-color: #fff;
  border-radius: 0;
}

.modal--bottom-aligned > .modal__button--close {
  top: 0;
  right: 0;
}

@media screen and (min-width: 660px) {
  .modal {
    width: initial;
    padding: 3em 2em;
    max-width: 90%;
  }

  .modal > .modal__buttons {
    margin-top: 2em;
    justify-content: center;
  }

  .modal.modal--fullscreen {
    width: 90%;
  }
}
