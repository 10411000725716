.dashboard-news-feed__list-container {
  list-style-type: none;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.dashboard-news-feed__card {
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.dashboard-news-feed__image-container,
.dashboard-news-feed__image-container > img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dashboard-news-feed__title {
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
}

.dashboard-news-feed__blurb {
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-height: 1.3;
}
.dashboard-news-feed__blurb-expanded {
  max-height: unset !important;
  -webkit-line-clamp: unset !important;
  -webkit-box-orient: unset !important;
  display: block !important;
}

.dashboard-news-feed__disclaimer-container {
  margin-bottom: 10px;
}
.dashboard-news-feed__disclaimer {
  font-size: 14px;
  font-weight: 400;
}
.dashboard-news-feed__disclaimer::before {
  content: "*";
  margin-right: 3px;
}

.dashboard-news-feed-blurb-container {
  height: 100%;
}

.dashboard-news-feed__button-conatainer {
  display: flex;
  flex-direction: row;
  text-align: right;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  position: relative;
  width: 100%;
}

.dashboard-news-feed__button-conatainer.expanded {
  position: relative;
  margin-top: 2em;
  padding-left: 0;
  padding-right: 0;
}

.dashboard-news-feed__button-conatainer.no-image:not(.with-read-more) {
  flex-direction: row-reverse;
  padding-right: 0;
}

.dashboard-news-feed__button-conatainer.no-image.with-read-more {
  padding-left: 0;
  padding-right: 0;
}

.dashboard-news-feed__button-conatainer.with-image.without-read-more {
  flex-direction: row-reverse;
}

.dashboard-news-feed__button {
  width: unset;
  max-width: 100%;
  padding-left: 2em;
  padding-right: 2em;
  white-space: normal;
  word-wrap: break-word;
  margin: 0 !important;
}

.dashboard-news-feed-section {
  padding-right: 20px !important;
}

.dashboard-news-feed__card
  > .dashboard-news-feed__column:only-child
  > .dashboard-news-feed__button-conatainer {
  position: relative;
  right: 0;
  bottom: 0;
}

.dashboard-news-feed__blurb.dashboard-news-feed__blurb-expanded
  ~ .dashboard-news-feed__button-conatainer {
  position: relative;
  right: 0;
  bottom: 0;
}

/* Mobile Only */
@media screen and (max-width: 660px) {
  .dashboard-news-feed__card {
    position: relative;
  }

  .dashboard-news-feed__title {
    padding-top: 1em;
  }

  .dashboard-news-feed__title,
  .dashboard-news-feed__blurb {
    padding-left: 1em;
    padding-right: 1em;
  }

  .dashboard-news-feed__card ~ *:only-child {
    padding-left: 1em;
    padding-right: 1em;
  }

  .no-content-button-container {
    position: relative;
    right: 0;
    padding-right: 1em;
    padding-left: 1em;
    margin-top: 30px;
  }

  .with-content-button-container {
    position: relative;
  }

  .dashboard-news-feed__blurb {
    -webkit-line-clamp: 1;
  }

  .dashboard-news-feed__button-conatainer {
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 20px;
  }

  .dashboard-news-feed__button-conatainer.expanded {
    position: relative;
    margin-top: 2em;
    padding-left: 1em;
    padding-right: 1em;
  }

  .dashboard-news-feed__button-conatainer.no-image:not(.with-read-more) {
    flex-direction: row-reverse;
    padding-right: 1em;
  }

  .dashboard-news-feed__button-conatainer.no-image.with-read-more {
    padding-left: 1em;
    padding-right: 1em;
  }

  .dashboard-news-feed__button-conatainer.with-image.without-read-more {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 660px) {
  .dashboard-news-feed__card {
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
  }
  .dashboard-news-feed__image-container {
    width: 100%;
    display: flex;
  }

  .dashboard-news-feed__image-container > img {
    width: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .dashboard-news-feed__column {
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;
  }

  .dashboard-news-feed__read-more-button {
    text-align: left;
  }

  .dashboard-news-feed-section {
    padding-right: 0 !important;
  }
}

/** Ipad Mini **/
@media screen and (min-width: 768px) and (max-width: 820px) {
  .dashboard-news-feed__blurb {
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: 400;
    text-overflow: ellipsis;
    max-height: 400px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
}

/** Ipad Air **/
@media screen and (min-width: 820px) {
  .dashboard-news-feed__blurb {
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: 400;
    text-overflow: ellipsis;
    max-height: 400px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
}

@media screen and (min-width: 1025px) {
  .dashboard-news-feed__blurb {
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: 400;
    text-overflow: ellipsis;
    max-height: 400px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .dashboard-news-feed__blurb.dashboard-news-feed__blurb-expanded
    ~ .dashboard-news-feed__button-conatainer {
    position: absolute;
    right: 1em;
    bottom: 1em;
  }

  .dashboard-news-feed__blurb-expanded ~ .dashboard-news-feed__button-conatainer {
    position: relative !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  .dashboard-news-feed__column.news-feed-content {
    padding: 40px !important;
  }

  .dashboard-news-feed__card
    > .dashboard-news-feed__column:only-child
    > .dashboard-news-feed__button-conatainer {
    right: 0 !important;
    bottom: 0 !important;
  }

  .dashboard-news-feed__blurb.dashboard-news-feed__blurb-expanded
    ~ .dashboard-news-feed__button-conatainer {
    position: relative !important;
    right: 0 !important;
    bottom: 0 !important;
  }
}
