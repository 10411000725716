/*FormInput.css*/
.form__field-wrapper {
  position: relative;
}

.form__field-wrapper + * {
  margin-top: 2.5em;
}

.form__field-wrapper--small {
  display: inline-block;
  width: 48%;
  margin-top: 3em;
}

.form__field-wrapper--small + .form__field-wrapper--small:nth-child(odd) {
  margin-left: 4%;
}

.form__field-wrapper--small + .form__field-wrapper--small:nth-child(2) {
  margin-left: 4%;
}

.form__field-label {
  top: 0;
  bottom: 0;
  left: 18px;
  display: flex;
  align-items: center;
  line-height: normal;
  pointer-events: none;
  transition: font-size 0.3s, transform 0.3s;
  position: static;
  margin: 0 0 12px;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
}

.form__field {
  width: 100%;
  color: #2a2a2a;
  line-height: normal;
  background-color: #fff;
  background-clip: padding-box; /* removes iOS shadow */
  height: 42px;
  padding: 10px 12px;
  font-size: 1rem;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #dedede;
}

.form__field--with-right-button {
  /* button width + padding */
  padding-right: calc(42px + 8px);
}

.form__field-wrapper--invalid .form__field {
  border-bottom: 1px solid #ff2020;
}

.form__field--select {
  background-color: #fff;
  appearance: none; /* removes native browser arrow down icon */
}

.form__field + .icon--arrow-down {
  position: absolute;
  top: auto;
  bottom: 6px;
  right: 7px;
  width: 25px;
  height: 25px;
  margin: auto;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:focus::placeholder {
  color: #ccc;
  transition: color 0.2s;
}

.form__field:focus {
  border-color: var(--color-secondary);
}

.button--show-password {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 43px;
  height: 100%;
}

.button--show-password > .icon {
  margin-right: 12px;
  color: #757575;
}

.form__field-error {
  opacity: 0;
  transform: translate(-10px, 40px);
  position: absolute;
  top: 100%;
  right: 0;
  /* max-width: 65%; */
  padding: 2px 6px;
  color: #ff2020;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  animation: showFieldError 0.25s forwards;
  pointer-events: none;
  z-index: 600;
}

.inputInfoText {
  position: absolute;
  font-size: 0.9em;
    bottom: -25px;
}

@keyframes showFieldError {
  100% {
    opacity: 1;
    transform: translate(-10px, -12px);
  }
}

.form__field-wrapper--small > .form__field-error {
  max-width: none; /* reset for longer errors in short fields */
}
.currencySymbolPadding {
  padding-left: 2.5em;
}
@media screen and (max-width:660px) {
  .inputInfoTextMargin{
    margin-bottom:  4.2em;
  }
}