.menu-container {
  padding-top: 1.5em;
  padding-bottom: 12em;
}

.mobile-container {
  padding-left: 24px;
  padding-right: 24px;
}

.menu__category-cards-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  margin: -0.5em;
  padding: 0;
  list-style-type: none;
  animation: fadeIn 0.2s forwards;
}

.menu__back-to-previous-level-link {
  display: inline-flex;
  align-items: center;
  min-height: 30px;
  margin-bottom: 1em;
}

.menu__back-to-previous-level-link > .icon {
  width: 12px;
  height: 12px;
  margin-right: 0.6em;
}

.menu-back-to-previous-level-link__text {
  font-size: 1.2rem;
  font-weight: 600;
}

.menu__empty-message {
  margin: 2em 0 0;
  font-size: 1.2rem;
  text-align: center;
}
