.dashboard__module--horizontal-slider .dashboard-section__body.desktop-container {
  padding-right: 0;
}

.dashboard__horizontal-slider-wrapper {
  position: relative;
  overflow: hidden;
  padding: 10px 0 18px 0px;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.dashboard-horizontal-slider__list.featured-slider {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin: -0.5em;
  padding: 0;
  list-style-type: none;
  -webkit-animation: fadeIn 0.2s forwards;
  animation: fadeIn 0.2s forwards;
}

.dashboard-horizontal-slider__list.featured-slider > .menu__category-card {
  border: none;
  box-shadow: none;
}
.dashboard-horizontal-slider__list > .dashboard-horizontal-slider__card:first-child {
  margin-left: 2px;
}

@media screen and (max-width: 660px) {
  .dashboard__module--horizontal-slider .dashboard-section__body.desktop-container {
    padding-left: 0;
  }

  .dashboard-horizontal-slider__list .menu-category-card___text-wrapper {
    text-wrap: wrap;
  }

  .dashboard-horizontal-slider__list.featured-slider > .menu__category-card {
    min-width: 50%;
  }
}

@media screen and (min-width: 1248px) {
  .dashboard-section__body.desktop-container .dashboard__horizontal-slider-wrapper {
    margin-left: -24px;
  }
}

.dashboard__horizontal-slider-wrapper::-webkit-scrollbar {
  display: none;
}

body.body--touch-device .dashboard__horizontal-slider-wrapper {
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

body.body--iOS .dashboard__horizontal-slider-wrapper {
  -webkit-overflow-scrolling: touch; /* enables iOS "momentum" style scrolling */
}

.dashboard-horizontal-slider__list {
  white-space: nowrap;
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  transition: transform 0.3s;
}

.dashboard-slide__image-and-text-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  flex-direction: column;
}

body:not(.body--touch-device) .dashboard__featured-item-img {
  transition: transform 0.3s;
}

body:not(.body--touch-device)
  .dashboard-slide__image-and-text-wrapper:hover
  .dashboard__featured-item-img {
  transform: scale(1.1);
}

.dashboard-horizontal-slider__list > li {
  display: inline-block;
}

.dashboard-horizontal-slider__card {
  --card-ratio: 205 / 400;
  position: relative;
  width: 90%;
  height: 0;
  overflow: hidden;
  border-radius: 9px;
  box-shadow: 0 0.5px 3px 0.5px rgb(0 0 0 / 15%);
}

.dashboard-horizontal-slider__list--1-in-view .dashboard-horizontal-slider__card {
  padding-top: calc(var(--card-ratio) * 90%);
}

.dashboard-horizontal-slider__list--2-in-view .dashboard-horizontal-slider__card {
  width: calc(100% / 2 - 1em - 60px);
  padding-top: calc(var(--card-ratio) * (100% / 2 - 1em - 60px));
}

.dashboard-horizontal-slider__list--3-in-view .dashboard-horizontal-slider__card {
  width: calc(100% / 3 - 1em - 60px);
  padding-top: calc(var(--card-ratio) * (100% / 3 - 1em - 60px));
}

.dashboard-horizontal-slider__list--4-in-view .dashboard-horizontal-slider__card {
  width: calc(88% / 3 - 1em - 60px);
  padding-top: calc(var(--card-ratio) * (100% / 3 - 1em - 60px));
}
.dashboard-horizontal-slider__list--4-in-view .dashboard-horizontal-slider__card.compact_carousel {
  width: calc(87.5% / 3 - 1em - 37px) !important;
  padding-top: calc(var(--card-ratio) * (100% / 3 - 1em - 60px));
  border-radius: 12px;
}
body.body--touch-device .dashboard-horizontal-slider__card {
  scroll-snap-align: center;
}

.dashboard-horizontal-slider__empty-slide {
  box-shadow: none;
}

.dashboard-horizontal-slider__card + .dashboard-horizontal-slider__card {
  margin-left: 1em;
}

.dashboard__new-order-item-img-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dashboard__new-order-item-img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

body:not(.body--touch-device) .dashboard__new-order-item-img {
  transition: transform 0.3s;
}

body:not(.body--touch-device)
  a.dashboard__new-order-item-img-wrapper:hover
  .dashboard__new-order-item-img {
  transform: scale(1.05);
}

body:not(.body--touch-device)
  div.dashboard__new-order-item-img-wrapper:hover
  .dashboard__new-order-item-img {
  transform: scale(1.05);
}

.dashboard__slide-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.2s;
}

body:not(.body--touch-device) .dashboard__slide-button:hover {
  transform: scale(1.05);
}

.dashboard__new-order-item-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% - 93px);
}

.dashboard__new-order-item-name {
  margin: 0 0 5px;
  font-size: 0.875rem;
  line-height: 120%;
}

.dashboard__new-order-item-description {
  position: relative;
  min-height: 50px;
  max-height: 3.9em;
  margin: 0;
  overflow: hidden;
  font-size: 0.8125rem;
  line-height: 1.3em;
}

.dashboard__new-order-item-description:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.3em;
  margin-top: 0.2em;
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.83) 50%);
}

.dashboard__horizontal-slider-button {
  position: absolute;
  z-index: 10;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 10px - 18px - 4px);
}

.dashboard__horizontal-slider-previous-button {
  left: 0;
  background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.6));
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}

.dashboard__horizontal-slider-next-button {
  right: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.6), #fff);
}

.dashboard__horizontal-slider-button > .icon {
  width: 30px;
  height: 30px;
}

.horizontal-slider__status-bar {
  display: flex;
  justify-content: center;
}

.horizontal-slider-status-bar__step {
  width: 30px;
  height: 4px;
  border-radius: 4px;
}

.horizontal-slider-status-bar__step[data-is-active="true"] {
  background-color: #cfcfcf;
}

.horizontal-slider-status-bar__step[data-is-active="false"] {
  background-color: #eaeaea;
}

.horizontal-slider-status-bar__step + .horizontal-slider-status-bar__step {
  margin-left: 10px;
}

/**Desktop **/
@media screen and (min-width: 660px) {
  .dashboard-horizontal-slider__list--3-in-view .dashboard-horizontal-slider__card {
    width: calc(100% / 3 - 5em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 3 - 5em - 60px)) !important;
  }
  section.whatsNewSliderSection .dashboard-horizontal-slider__list--3-in-view .dashboard-horizontal-slider__card {
    width: calc(100% / 3 - 6.2em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 3 - 5em - 60px)) !important;
  }
  .dashboard-horizontal-slider__card.dashboard-horizontal-slider__card-featured {
    width: calc(100% / 3 - 14em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 2 - 3em - 60px)) !important;
  }

  .horizontal-slider__status-bar {
    margin-bottom: 20px;
  }

  .dashboard-horizontal-slider__list.featured-slider > .menu__category-card {
    min-width: 10%;
  }
}

/**Desktop Laptop Screen **/
@media screen and (min-width: 660px) and (max-width: 1920px) {
  .dashboard-horizontal-slider__card.dashboard-horizontal-slider__card-featured {
    min-width: 12%;
    width: calc(100% / 3 - 14em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 2 - 8em - 60px)) !important;
    min-height: 270px;
  }

  .dashboard-horizontal-slider__list.featured-slider > .menu__category-card {
    min-width: 10%;
    text-wrap: wrap;
  }
}

@media screen and (min-width: 660px) and (max-width: 1500px) {
  .dashboard-horizontal-slider__card.dashboard-horizontal-slider__card-featured {
    min-width: 10%;
    width: calc(100% / 3 - 7em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 2 - 60px)) !important;
    min-height: 300px;
  }
}

@media screen and (min-width: 660px) and (max-width: 1200px) {
  .dashboard-horizontal-slider__card.dashboard-horizontal-slider__card-featured {
    width: calc(100% / 3 - 6em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 2 - 30px)) !important;
  }
}

/** ipad **/
@media screen and (min-width: 660px) and (max-width: 1024px) {
  .dashboard-horizontal-slider__card.dashboard-horizontal-slider__card-featured {
    width: calc(100% / 3 - 6em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 2 - 30px)) !important;
    min-height: 250px;
  }

  .dashboard-horizontal-slider__list.featured-slider > .menu__category-card {
    min-width: 30%;
    text-wrap: wrap;
  }

  .dashboard-horizontal-slider__list--3-in-view .dashboard-horizontal-slider__card {
    width: calc(100% / 3 - 1em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 3 - 1em - 60px)) !important;
  }
}

/** ipad Mini **/
@media screen and (min-width: 660px) and (max-width: 850px) {
  .dashboard-horizontal-slider__card.dashboard-horizontal-slider__card-featured {
    width: calc(100% / 3 - 2em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 1.5)) !important;
    padding-bottom: 10px;
  }

  .dashboard-horizontal-slider__list.featured-slider > .menu__category-card {
    min-width: 30%;
    text-wrap: wrap;
  }
}
