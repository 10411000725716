.dashboard-order-buttons-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  padding-right: 20px;
}

.dashboard-order-buttons-container .dashboard-action-button:not(:last-child) {
  margin-right: 1em;
}

.dashboard-order-buttons__row {
  display: flex;

  margin-bottom: 10px;
}

.dashboard-order-buttons__inner-row {
  display: flex;
}

.dashboard-order-buttons__column {
  display: flex;
  flex-direction: column;
}

.dahsboard-order-buttons__order-button-icon-container {
  background-color: var(--color-primary);
  padding: 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  min-width: 16%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dahsboard-order-buttons__order-button-icon-container > svg {
  fill: var(--color-primary-color-contrast);
}

.dashboard-order-buttons__column.order-now {
  background-color: #fff;
  text-align: left;
  padding: 10px 10px 10px 20px;
}

.dashboard-order-buttons__column.order-now > span:first-child {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 3px;
}

.dashboard-order-buttons__column.order-now > span:last-child {
  font-weight: 400;
  font-size: 14px;
}
.dashboard-order-buttons__row.order-now {
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
}

.dashboard-order-buttons__column.favorite {
  justify-content: center;
  align-items: center;
  padding: 0;
}

.dashboard-order-buttons__column.favorite > svg {
  margin-bottom: 5px;
}

.dashboard-order-buttons__fav-container,
.dashboard-order-buttons__recent-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 11px 0px 11px 0px;
  min-width: 48%;
  font-size: 14px;
  font-weight: 500;
}

#dashboard-order-now-button {
  width: 100%;
}

@media screen and (max-width: 660px) {
  .dashboard-order-buttons__row:not(.order-now) {
    justify-content: space-between;
  }

  .dashboard-order-buttons-container > .dashboard-action-button {
    margin-bottom: 10px;
  }
}

/** Dashboard Static Order button **/
.dashboard-order-now-button-static {
  z-index: 9999;
  background-color: var(--color-primary);
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 4px 8px 3px #00000014;
  animation-name: expandStaticOrderButton;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes expandStaticOrderButton {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

.dashboard-order-now-button-static div {
  border-radius: 0;
  background-color: var(--color-primary);
  padding: 0;
  min-width: unset;
  font-size: 14px;
}

.dashboard-order-now-button-static .dahsboard-order-buttons__order-button-icon-container {
  padding: 10px;
}

.dashboard-order-now-button-static .dashboard-order-buttons__column.order-now,
.dashboard-order-now-button-static svg {
  background-color: var(--color-primary);
  color: var(--color-primary-color-contrast);
  fill: var(--color-primary-color-contrast);
}

.dashboard-order-now-button-static .icon--arrow-right {
  width: 12px;
  height: 12px;
  margin-left: 10px;
}

.dashboard-order-now-button-static .icon--bill {
  width: 20px;
  height: 20px;
}

.dashboard-order-now-button-static .dashboard-order-buttons__column.order-now > span {
  display: flex;
  align-items: center;
}

.dashboard-order-now-button-static .dashboard-order-buttons__column.order-now > span:first-child {
  font-size: 14px;
}
.dashboard-order-now-button-static .dashboard-order-buttons__column.order-now > span:last-child {
  font-size: 12px;
}

/** END OF Dashboard Static Order button **/

/** Desktop **/
@media screen and (min-width: 660px) {
  .dashboard-order-buttons-container {
    padding-right: 0;
  }
  .dashboard-order-buttons__column {
    text-wrap: wrap;
  }

  .dashboard-order-buttons__column {
    justify-content: center !important;
    align-items: flex-start !important;
  }

  .dahsboard-order-buttons__order-button-icon-container {
    background-color: #eeeeee;
    margin-right: 10px;
    padding: 5px;
    height: 100%;
    min-width: 60px;
    max-width: 60px;
  }

  .dahsboard-order-buttons__order-button-icon-container.order-now {
    background-color: var(--color-primary);
  }

  .dahsboard-order-buttons__order-button-icon-container.order-now > svg {
    fill: var(--color-primary-color-contrast);
  }

  .dahsboard-order-buttons__order-button-icon-container > .icon--star {
    fill: #f49c00 !important;
    color: #f49c00 !important;
    width: 30px;
    height: 30px;
  }
  .dahsboard-order-buttons__order-button-icon-container > .icon--heart {
    stroke: #fc8181;
    fill: #fc8181;
  }

  .dashboard-order-buttons__inner-row {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .dashboard-order-buttons-container button {
    margin-right: 1em;
  }

  .dashboard-order-buttons-container #dashboard-rewards-button {
    margin-right: 1em;
  }

  #dashboard-rewards-button.loyalty-only {
    margin-right: 0;
    max-width: 30%;
  }

  .dashboard-order-buttons-container button:last-child {
    margin-right: 0px;
  }

  .dashboard-order-buttons__fav-container,
  .dashboard-order-buttons__recent-container,
  .dashboard-order-buttons__order-now-container {
    background-color: #fff;
    border-radius: 8px;
    padding: 0;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 20%;
    font-size: 16px;
    font-weight: 500;
  }
  .dashboard-order-buttons__order-now-container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .dashboard-order-buttons__column.order-now {
    padding-left: 0;
  }

  .dashboard-order-buttons__column.reward-arrow {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
  }

  .dashboard-order-buttons__column.reward-arrow > .icon--arrow-up {
    width: 20px;
    height: 20px;
  }

  .dashboard-order-buttons__column.reward-arrow > .icon--arrow-down {
    width: 25px;
    height: 25px;
  }

  .dashboard-order-now-button-static div {
    align-items: center;
  }
}

/** Ipad Mini Screen **/
@media screen and (min-width: 661px) and (max-width: 1024px) {
  .dashboard-order-buttons-container > .dashboard-order-buttons__row {
    flex-wrap: wrap;
  }

  .full-length-recent-order-button {
    margin-top: 10px;
  }

  .right-aligend-fav-button {
    margin-right: 0 !important;
  }

  .dashboard-order-buttons-container > .dashboard-order-buttons__row > button,
  .dashboard-order-buttons-container > .dashboard-order-buttons__row > #dashboard-rewards-button {
    flex: calc(45% - 1em);
    min-width: unset;
    margin-right: 0;
  }

  .dashboard-order-buttons-container > .dashboard-order-buttons__row > button:nth-child(odd) {
    margin-right: 10px;
  }

  .dashboard-order-buttons-container > .dashboard-order-buttons__row > button:nth-child(1) {
    margin-bottom: 10px;
  }

  .dashboard-order-buttons-container > .dashboard-order-buttons__row > button:nth-child(2) {
    margin-bottom: 10px;
  }
  .dahsboard-order-buttons__order-button-icon-container {
    padding: 18px 0;
  }

  .dashboard-order-buttons-container > .dashboard-order-buttons__row > button:last-child {
    margin-right: 0px;
  }

  #dashboard-rewards-button {
    margin-bottom: 10px;
  }
}
