.order-item__options-form {
  opacity: 0;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
  animation: fadeIn 1s forwards;
}

.order-item__option-header {
  display: flex;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 15px;
  padding-bottom: 15px;

  position: relative;
  align-items: center;
  border-bottom: 1px solid #e2e1e1;
  justify-content: space-between;
  background-color: #eee;
}

.order-item__options-form .form__fieldset-legend:not(.form__fieldset-legend--nested) {
  display: none;
}

.order-item__options-form.visually-hidden {
  display: none;
}

.addon-modifier-modal__go-back-button {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}

.addon-modifier-modal__go-back-button > .icon {
  margin-right: 8px;
}

/* Form */
.addons-modifiers-form .form__fieldset-legend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-size: 1rem;
  font-weight: 600;
  padding: 18px 24px;
  background-color: #eee;
  position: relative;
}

.addons-modifiers-form .form__fieldset-legend .icon--minus,
.addons-modifiers-form .form__fieldset-legend .icon--plus {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.addons-modifiers-form .form__fieldset-legend > span:first-child {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.9em;
}

.nested-addons-fieldset-wrapper .form__fieldset--nested .form__fieldset-legend {
  padding-right: 0;
  margin-bottom: 0;
  background-color: transparent;
  padding-top: 10px;
  padding-bottom: 10px;
}

.order-item__option-heading .item-detail__option-note {
  text-transform: none;
  overflow: visible;
  text-overflow: clip;
}

.order-item__option-heading {
  margin: 0;
  font-size: 1em;
  font-weight: 600;
}

.order-item__option-heading > span > .icon--check {
  width: 18px;
  height: 18px;
  padding: 3px;
  stroke: #fff;
  stroke-width: 3px;
  background-color: #4caf52;
  border-radius: 50%;
  margin-left: 5px;
}

.order-item__option-heading > span {
  display: flex;
  align-items: center;
}

.order-item__option-heading .icon--minus,
.order-item__option-heading .icon--plus {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.addons-modifiers-form .form__field-label--checkbox-radio {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 60px);
  min-height: 44px;
  padding-left: 24px;
  font-size: 1rem;
}

.addons-modifiers-form .form__field-label--checkbox-radio::before {
  display: none;
}

/** Addon modifier custom checkbox and radio button positioning **/
.addons-modifiers-form .form__field-label--checkbox-radio::after {
  width: 25px;
  height: 25px;
  content: "";
  position: absolute;
  right: 24px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.addons-modifiers-form .form__field-label--checkbox-radio.is-not-checked::after {
  display: none;
}

.addons-modifiers-form .form__field-label--checkbox-radio.error::after {
  border-color: #ff2020;
}

.form__fieldset-legend-text--nested.item-detail__option-note.error,
.addons-modifiers-form__toggle-nested-options.error > .icon--plus {
  color: #ff2020;
}

input.form__field--checkbox:disabled + label.form__field-label--checkbox-radio::after {
  background-color: #ccc;
}

.form__field-label--radio::after {
  border-radius: 50% !important;
}

.addons-modifiers-form .form__field--radio:checked + .form__field-label--radio::after {
  background-image: url("../../../../_common/icons/IconCircleRegular.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.addons-modifiers-form
  .form__field--radio:checked
  + .form__field-label--radio.halfWay-progression::after {
  background-image: url("../../../../_common/icons/IconCircleHalf.svg");
  background-color: unset;
}

.addons-modifiers-form
  .form__field--radio:checked
  + .form__field-label--radio.full-progression::after {
  background-image: url("../../../../_common/icons/IconCircleFull.svg");
  background-color: unset;
}

.addons-modifiers-form .form__field--checkbox:checked + .form__field-label--checkbox::after {
  background-image: url("../../../../_common/icons/IconSquareRegular.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.addons-modifiers-form
  .form__field--checkbox:checked
  + .form__field-label--checkbox.halfWay-progression::after {
  background-image: url("../../../../_common/icons/IconSquareHalf.svg");
  border: none;
  border-radius: unset;
  background-color: unset;
}

.addons-modifiers-form
  .form__field--checkbox:checked
  + .form__field-label--checkbox.full-progression::after {
  background-image: url("../../../../_common/icons/IconSquareFull.svg");
  border: none;
  border-radius: unset;
  background-color: unset;
}

.addons-modifiers-form .form__field-wrapper--checkbox-radio + .form__field-wrapper--checkbox-radio {
  margin-top: 0;
  padding-top: 0;
}

.addons-modifiers-form .form__buttons-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 24px 24px;
  display: none;
}

.addons-modifiers-form .form__buttons-wrapper > .button {
  width: 50%;
}

.addons-modifiers-form .form__cancel-button {
  margin-right: 12px;
  background-color: #fff;
}

.item-detail__option-button > .icon--check {
  stroke: #fff;
  stroke-width: 3px;
  width: 18px;
  height: 18px;
  padding: 3px;
  background-color: #4caf52;
  border-radius: 100%;
}

.nested-addons-fieldset-wrapper {
  position: relative;
  margin-left: 36px;
  margin-right: 24px;
  padding: 5px 0;
  margin-top: 0;
}

.nested-addons-fieldset-wrapper + .nested-addons-fieldset-wrapper {
  border-top: 1px solid #dedede;
}

.form__fieldset--nested {
  opacity: 0;
  animation: fadeIn 0.6s forwards;
}

.form__fieldset--nested + .form__fieldset--nested {
  margin-top: 8px;
}

.form__fieldset--top-level > div > div.form__field-wrapper--checkbox-radio,
.form__fieldset > div > div.form__field-wrapper--checkbox {
  border-top: 1px solid #f2f2f2;
}

.form__field-wrapper--checkbox-radio__gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 19%,
    rgba(238, 238, 238, 1) 100%
  );
}

.form__field-wrapper + .form__fieldset--nested {
  margin-top: 8px;
}

.addons-modifiers-form__toggle-nested-options {
  position: absolute;
  display: flex;
  align-items: center;
  top: 8px;
  width: 100%;
  height: 30px;
  z-index: 1;
}

.addons-modifiers-form__toggle-nested-options > .icon--plus {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.addons-modifiers-form__toggle-nested-options > .icon--arrow-down {
  width: 24px;
  height: 24px;
  transition: transform 0.3s;
}

.addons-modifiers-form__toggle-nested-options > .icon--arrow-down.icon--rotate-right {
  transform: rotate(-90deg);
}

.form__fieldset-legend--nested {
  display: flex;
  align-items: center;
  min-height: 30px;
}

.form__fieldset-legend-text--nested {
  line-height: 110%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.form__fieldset-legend-text--nested > .icon--check {
  width: 15px;
  height: 15px;
  padding: 3px;
  stroke: #fff;
  stroke-width: 3px;
  background-color: #4caf52;
  border-radius: 50%;
  margin-left: 5px;
}

.form__field-wrappers {
  transform: scaleY(1);
  transform-origin: top center;
}

.form__field-wrappers--collapsing {
  animation: collapseNestedOptions 0.5s forwards;
}

.form__field-wrappers--expanding {
  animation: expandNestedOptions 0.5s forwards;
}

.menu-item__addon-price {
  min-width: 120px;
  text-align: right;
}

.item-addon__out-of-stock-label {
  color: #ff2020;
}

@keyframes collapseNestedOptions {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes expandNestedOptions {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.nested-modifiers__modal-wrapper {
  z-index: 1200;
}

.nested-modifiers__modal-wrapper > .modal {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 0;
}

.nested-modifiers__modal-wrapper .form__field-label--checkbox-radio::after {
  content: "";
  position: absolute;
  right: 24px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.nested-modifiers__modal-wrapper .form__field-label--radio,
.nested-modifiers__modal-wrapper .form__field-label--checkbox {
  padding: 0 24px;
}
.nested-modifiers__modal-wrapper .form__field-label--radio::after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.nested-modifiers__modal-wrapper .form__field-label--checkbox::after {
  width: 25px;
  height: 25px;
  border-radius: 4px;
}

.nested-modifiers__modal-wrapper .form__field-label--radio::before,
.nested-modifiers__modal-wrapper .form__field-label--checkbox-radio::before {
  display: none;
}

.nested-modifiers__option-wrapper {
  width: 100%;
  padding: 10px 0;
  margin: 0;
  display: flex;
  align-content: center;
  align-items: center;
}

.nested-modifiers__option-wrapper + .nested-modifiers__option-wrapper {
  border-top: 1px solid #dedede;
  margin-top: 0;
}

.nested-modifiers__modal-wrapper
  .form__field--checkbox:checked
  + .form__field-label--checkbox::after {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='icon icon--check'><polyline points='20 6 9 17 4 12'></polyline></svg>");
  background-repeat: no-repeat;
  background-position: center;
}

.nested-modifiers__modal-wrapper .form__field--radio:checked + .form__field-label--radio::after {
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'><circle cx='50' cy='50' r='30'/></svg>");
  background-repeat: no-repeat;
  background-position: center;
}

.nested-options__form-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #eee;
  padding: 20px 24px;
  align-items: center;
  margin-top: 0;
}

.nested-options__form-header > span {
  display: flex;
  align-items: center;
}

.nested-options__form-header > span > .icon--check {
  width: 18px;
  height: 18px;
  padding: 3px;
  stroke: #fff;
  stroke-width: 3px;
  background-color: #4caf52;
  border-radius: 50%;
  margin-left: 5px;
}

.nested-modifiers__modal-wrapper > .modal > .modal__buttons {
  width: 100%;
  margin-top: 0;
}

.nested-modifiers__modal-wrapper > .modal > .modal__buttons > button {
  width: 100%;
  border-radius: 0;
  margin: 0;
  padding: 15px 0;
}

.nested-modifiers__modal-wrapper .form__field-label--checkbox-radio::before {
}

.nested-modifiers__modal-wrapper .form__field-wrapper--checkbox {
  width: 100%;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.nested-modifiers__modal-wrapper > .modal {
  opacity: 1;
  animation: slideUp 0.5s forwards;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.nested-modifiers__modal-wrapper.form__field-wrappers--expanding,
.nested-modifiers__modal-wrapper.form__field-wrappers--collapsing {
  animation: none;
}

.nested-modifiers__modal-wrapper.form__field-wrappers--collapsing > .modal {
  animation: slideDown 0.5s forwards;
}

.addons-modifiers-form__toggle-nested-options > .icon--plus {
  margin-top: 4px;
}

.nested-addons-fieldset-wrapper.wizard legend {
  display: none;
}

.nested-addons-fieldset-wrapper.wizard {
  width: 100%;
  border-radius: 15px;
  border: 2px solid var(--color-primary);
  padding: 0;
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 0;
}

.nested-addons-fieldset-wrapper.wizard.smaller {
  margin: 10px auto;
  animation: scaleExpand 0.5s forwards;
}

@keyframes scaleExpand {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleExpandY {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

.nested-addons-fieldset-wrapper.wizard .form__field-wrappers {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.nested-addons-fieldset-wrapper.wizard .form__field-wrappers > div {
  padding: 0;
}

.nested-addons-fieldset-wrapper.wizard .form__field-wrappers > div:last-child {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.nested-addons-fieldset-wrapper.wizard .form__field-wrappers > header {
  padding: 15px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  font-weight: 500;
}

.nested-addons-fieldset-wrapper.wizard .form__field-wrappers > div > label {
  min-height: unset;
  text-align: left;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-left: 15px;
}

.nested-addons-fieldset-wrapper.wizard .form__field-wrappers > div > label:after {
  top: -8px;
  right: 11px;
}

.nested-group__done_button {
  background-color: var(--color-primary);
  color: var(--color-button-text);
  margin-top: 0;
  padding: 10px 0;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
}

.closed-nested-group.wizard {
  display: flex;
  justify-content: space-between !important;
  padding: 5px 5px;
  height: unset !important;
  align-items: center;
  border-radius: 0;
  width: 100%;
  margin: 0;
  background-color: var(--color-primary);
  color: var(--color-button-text);
  flex-direction: row !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border: 2px solid var(--color-primary) !important;
  animation: scaleExpandY 0.5s forwards;
}

.closed-nested-group.wizard.no-image-nested-group {
  width: 88%;
  margin: 0 auto 10px;
}

.closed-nested-group.wizard > button {
  color: var(--color-button-text);
}

.form__fieldset-addonModifier
  > .form__field-wrapper--checkbox-radio__background
  .closed-nested-group.wizard:first-child {
  margin-top: -10px;
}

.form__fieldset-addonModifier
  > .form__field-wrapper--checkbox-radio__background
  .closed-nested-group.wizard:not(:last-child) {
  border-radius: 0 !important;
}

.form__field-wrapper--checkbox-radio__gray-overlay {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  opacity: 0.6;
}

.menu__category-card.withQuickAddButton > .form__field-wrapper--checkbox-radio__gray-overlay {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  opacity: 0.6;
  z-index: 1;
  border-radius: 6px;
}

.form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background > div,
.form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient > div {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  border: 1px solid #f2f2f2;
}

.addons-modifiers-form .form__field-label--checkbox-radio.no-thankyou-option::after {
  top: 13px;
  right: 20px;
}

.no-image-addon_with-quantity {
  flex-direction: column;
  align-items: baseline;
  margin: 10px 0;
}

.no-image-addon_with-quantity + .addon-quantity-buttons__main-wrapper {
  padding: 0 25px 0 60px;
}

.no-image-addon_with-quantity-wrapper {
  flex-direction: column !important;
}

.no-image-addon_with-quantity-wrapper > .addon-quantity-buttons__main-wrapper {
  opacity: 0;
}

label.no-image-addon_with-quantity.form__field-label--checkbox-radio {
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
}

.addon-modifier-choice__overlay-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.addon-modifier-choice__overlay-button.with-quantity-buttons {
  height: 80%;
}

.form__field-wrapper--checkbox-radio__background.with-image {
  transform: scale(0);
}

.form__field-wrapper--checkbox-radio__background.no-image {
  transform: scaleY(0);
}

/** mobile specific style **/
@media screen and (max-width: 660px) {
  .form__fieldset-legend-text--nested {
    line-height: 110%;
    color: #ff2020;
    font-size: 0.9rem;
    font-weight: 400;
    background-color: transparent;
  }

  .nested-addons-fieldset-wrapper .form__fieldset--nested .form__fieldset-legend {
    padding-left: 17px;
  }

  .form__fieldset-addonModifier {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 0.75rem;
    padding: 0 30px;
  }

  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 45%;
    width: 8.625em;
    max-width: 8.625em;
  }
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background{
    max-width: 10rem;
  }
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background > div.is-checked,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient > div.is-checked {
    border: 2px solid var(--color-primary);
  }

  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background > div > img,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient > div > img {
    border-top-left-radius: 0.8em;
    border-top-right-radius: 0.8em;
  }

  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;
  }
  .form__fieldset-addonModifier .form__field-label--checkbox-radio,
  .no-image-group .nested-addons-fieldset-wrapper .form__field-label--checkbox-radio {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0 5px;
    margin: 10px 0;
    width: 100%;
  }

  .form__fieldset-addonModifier .form__field-label--checkbox-radio::after,
  .no-image-group .nested-addons-fieldset-wrapper .form__field-label--checkbox-radio::after {
    right: 8px;
    top: 8px;
    margin: 0;
  }

  .form__fieldset-addonModifier .form__field-label--checkbox-radio > span {
    text-align: center;
  }

  .no-image-group .form__field-label--checkbox-radio > span:nth-child(2) {
    margin-right: 10px;
  }

  label.no-image-addon_with-quantity.form__field-label--checkbox-radio {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 60px);
    min-height: 44px;
    padding-left: 24px;
    font-size: 1rem;
    align-items: center;
  }

  label.no-image-addon_with-quantity.form__field-label--checkbox-radio::after {
    top: -42px;
  }

  .no-image-addon_with-quantity-wrapper {
    align-items: baseline;
  }

  .no-image-addon_with-quantity-wrapper .addon-quantity-buttons__main-wrapper {
    padding: 0 35% !important;
  }

  .nested-addons-fieldset-wrapper.wizard .form__field-wrappers > div > label:after {
    top: -3px;
  }

  .addon-modifier_image {
    max-height: 156px;
    min-height: 156px;
    width: 100%;
    object-fit: cover;
  }

  .form__field-wrapper--checkbox-radio__background.with-image {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  .form__field-wrapper--checkbox-radio__background.with-image.with-quantity-buttons
    > div.form__field-wrapper--checkbox-radio {
    height: 100%;
    justify-content: space-between;
  }
}

/** Desktop specific design **/
@media screen and (min-width: 660px) {
  .desktop-nested-addons-fieldset-container {
    width: 100%;
  }
  .form__fieldset-addonModifier {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px;
  }

  div.form__field-wrapper--checkbox-radio__background.is-checked {
    border: 2px solid var(--color-primary);
  }

  div.form__field-wrapper--checkbox-radio__background.no-image.is-checked {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid var(--color-gray-light);
  }

  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 30%;
    width: 30%;
    max-width: 30%;
    border: 1px solid var(--color-gray-light);
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
  }

  .addons-modifiers-form .form__field-label--checkbox-radio {
    width: 100%;
    padding: 5px 15px;
    flex-direction: column;
    justify-content: center;
  }

  .no-image-group .form__field-label--checkbox-radio {
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
  }

  .no-image-group .form__field-label--checkbox-radio > span:first-child {
    text-align: left;
  }

  .no-image-group .form__field-label--checkbox-radio > span:nth-child(2) {
    text-align: right;
    margin-right: 10px;
  }

  .addons-modifiers-form .form__field-label--checkbox-radio > span {
    text-align: center;
  }

  .addons-modifiers-form .form__field-label--checkbox-radio::after {
    bottom: unset;
    top: 10px;
    right: 5px;
  }

  .form__field-wrapper--checkbox {
    flex-direction: column;
  }

  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background > div,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient > div {
    border-radius: 5px;
  }

  .form__fieldset--top-level > div > div.form__field-wrapper--checkbox-radio,
  .form__fieldset > div > div.form__field-wrapper--checkbox {
    border: none;
    position: relative;
  }

  .form__fieldset--top-level > div > div.form__field-wrapper--checkbox-radio > img,
  .form__fieldset > div > div.form__field-wrapper--checkbox > img {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }

  .nested-addons-fieldset-wrapper.wizard .form__field-wrappers > div > label {
    min-height: 40px;
  }
  .nested-addons-fieldset-wrapper.wizard .form__field-wrappers > div > label:after {
    top: 10px;
  }

  .nested-modifiers__option-wrapper {
    width: 45%;
  }

  .form__fieldset.form__fieldset--nested .form__field-wrappers > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 15px;
    flex-wrap: wrap;
  }

  .form__fieldset.form__fieldset--nested .form__field-wrappers > div label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
  }

  .form__fieldset.form__fieldset--nested .form__field-wrappers > div > div {
    border-top: none;
    border-bottom: 1px solid var(--color-gray-light);
    padding: 0;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .form__fieldset.form__fieldset--nested .form__field-wrappers > div label::after {
    top: 10px;
    right: 0;
  }

  .form__fieldset.form__fieldset--top-level.no-image-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .form__fieldset.form__fieldset--top-level.no-image-group
    div.form__field-wrapper--checkbox-radio__background {
    width: 48%;
    border-bottom: 1px solid var(--color-gray-light);
  }

  .form__fieldset.form__fieldset--top-level.no-image-group
    div.form__field-wrapper--checkbox-radio__background
    > div {
    margin: 10px 0;
  }

  .form__fieldset.form__fieldset--top-level.no-image-group
    div.form__field-wrapper--checkbox-radio__background
    > div {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
  }

  .no-image-addon_with-quantity + .addon-quantity-buttons__main-wrapper {
    padding: 0 20px;
  }

  .no-image-addon_with-quantity-wrapper .addon-quantity-buttons__main-wrapper {
    padding: 0 100px !important;
  }

  label.no-image-addon_with-quantity.form__field-label--checkbox-radio {
    width: 100%;
    padding-right: 35px;
  }

  .addons-modifiers-form .form__field-label--checkbox-radio.no-thankyou-option::after {
    top: 5px;
    right: 5px;
  }

  .form__fieldset-addonModifier {
    justify-content: flex-start;
  }
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 16%;
    width: 16%;
    max-width: 16%;
    margin-right: 5px;
  }

  .form__field-wrapper--checkbox-radio__background.with-image {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  .form__field-wrapper--checkbox-radio__background.with-image.with-quantity-buttons
    > div.form__field-wrapper--checkbox-radio {
    height: 100%;
    justify-content: space-between;
  }
  .addons-modifiers-form .form__field-label--checkbox-radio {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 661px) and (max-width: 800px) {
  .form__fieldset.form__fieldset--top-level.no-image-group
    div.form__field-wrapper--checkbox-radio__background {
    width: 100%;
  }

  .no-image-addon_with-quantity + .addon-quantity-buttons__main-wrapper {
    padding: 0 20px 0 230px;
  }

  .no-image-addon_with-quantity-wrapper .addon-quantity-buttons__main-wrapper {
    padding: 0 35% !important;
  }

  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 6.125em;
    width: 6.125em;
    max-width: 6.125em;
    margin-right: 5px;
  }

  .addon-modifier_image {
    max-height: 6em;
    min-height: 6em;
    width: 6em;
    object-fit: cover;
  }
}

@media screen and (min-width: 800px) {
  .no-image-addon_with-quantity-wrapper .addon-quantity-buttons__main-wrapper {
    padding: 0 35% !important;
  }

  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 7.125em;
    width: 7.125em;
    max-width: 7.125em;
    margin-right: 5px;
  }

  .addon-modifier_image {
    max-height: 7em;
    min-height: 7em;
    width: 7em;
    object-fit: cover;
  }
}

@media screen and (min-width: 900px) {
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 8.125em;
    width: 8.125em;
    max-width: 8.125em;
    margin-right: 5px;
  }

  .addon-modifier_image {
    max-height: 8em;
    min-height: 8em;
    width: 8em;
    object-fit: cover;
  }
}

@media screen and (min-width: 1000px) {
  .form__fieldset-addonModifier {
    justify-content: flex-start;
  }
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 9.125em;
    width: 9.125em;
    max-width: 9.125em;
    margin-right: 5px;
  }

  .addon-modifier_image {
    max-height: 9em;
    min-height: 9em;
    width: 9em;
    object-fit: cover;
  }
}

@media screen and (min-width: 1200px) {
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 10.125em;
    width: 10.125em;
    max-width: 10.125em;
    margin-right: 5px;
  }

  .addon-modifier_image {
    max-height: 10em;
    min-height: 10em;
    width: 10em;
    object-fit: cover;
  }
}
