.dashboard__selects-wrapper {
  display: flex;
  justify-content: center;
  height: 44px;
  border-radius: 9999px;
  box-shadow: 0 2px 6px 1px rgb(0 0 0 / 24%);
  overflow: hidden;
}

.dashboard__selects-wrapper + .dashboard__selects-wrapper {
  margin-top: 0.5em;
}

.dashboard-select-wrapper {
  position: relative;
  max-width: 50%;
  flex-grow: 1;
}

.dashboard-select-wrapper:first-child {
  max-width: 45%;
}

.dashboard-select-wrapper + .dashboard-select-wrapper {
  border-left: 1px solid #dcdcdc;
}

.dashboard-select {
  width: 100%;
  height: 100%;
  padding: 0 25px 0 42px;
  font-size: 13px;
  line-height: normal;
  background-color: #fff;
  border: none;
  appearance: none;
}

html[lang="pt"] .dashboard-select[name="order types"] {
  white-space: break-spaces;
  line-height: 110%;
}

.dashboard-select-wrapper > .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 17px;
  height: 17px;
}

.dashboard-select-wrapper > .icon:first-of-type:not(.icon--arrow-down) {
  left: 15px;
}

.dashboard-select-wrapper > .icon--arrow-down {
  right: 7px;
}

.dashboard-select__display-value {
  pointer-events: none; /* allows click-through to select */
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 42px;
  width: calc(100% - 42px - 25px);
  height: 33px;
  font-size: 13px;
  line-height: 33px; /* select's height - focus' outline */
  background-color: #fff; /* to cover select's native display value */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Prevents iPhone auto-zoom-in on input field focus */
.body--iOS .dashboard-select__display-value {
  font-size: 100%;
}
