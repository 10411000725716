.redeem-order-confirm__heading {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.redeem-rewards-slider {
  position: relative;
  display: flex;
  margin: 13px 0 -39px -34px;
  padding: 11px 0 39px calc(34px + 24px);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  list-style-type: none;
}

.redeem-rewards-slider__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 275px;
  padding-top: 24px;
  padding-left: 0;
  scroll-snap-align: center;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s cubic-bezier(0.19, 1.24, 0.26, 1);
}

.redeem-rewards-slider__item-spacing {
  width: 1px;
  height: 1px;
  margin-left: 7.5%;
}

.redeem-rewards-slider__item + .redeem-rewards-slider__item {
  margin-left: 24px;
}

.redeem-rewards-slider-item__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.redeem-rewards-slider-item__image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  margin-bottom: 18px;
  overflow: hidden;
}

.redeem-rewards-slider-item__text-wrapper {
  margin-bottom: 24px;
  padding: 0 24px;
  text-align: center;
}

.redeem-rewards-slider-item__price-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.redeem-rewards-slider-item__price {
  margin-right: 4px;
  color: #2a2a2a;
  font-size: 1.8rem;
  font-weight: 600;
}

.redeem-rewards-slider-item-redeemable .redeem-rewards-slider-item__price {
  color: #f49c00;
}

.redeem-rewards-slider-item__price + .icon--star {
  margin-right: -22px;
}

.redeem-rewards-slider-item__price-wrapper > .icon--star {
  width: 44px;
  height: 44px;
  fill: #2a2a2a;
  stroke: none;
}

.redeem-rewards-slider-item-redeemable .redeem-rewards-slider-item__price-wrapper > .icon--star {
  fill: #f49c00;
}

.redeem-rewards-slider-item__name {
  width: 180px;
  height: 1.2em;
  margin: 0 auto 12px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.redeem-rewards-slider-item__description {
  margin: 0 auto;
  font-size: 15px;
  text-align: center;
}

.redeem-rewards-slider-item__need-more-points {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px 12px 24px;
  background-color: var(--color-secondary);
}

.redeem-rewards-slider-item__need-more-points > .icon--lock {
  width: 18px;
  height: 18px;
  margin-bottom: 4px;
  color: #fff;
}

.redeem-rewards-slider-item__need-more-points-message {
  margin: 0;
  color: #fff;
  font-size: 15px;
  text-align: center;
}


/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .my-rewards__redeem-rewards-section{
    padding-bottom: 10em !important;
  }

}

/** End Of Desktop Layout **/