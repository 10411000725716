.order-status__progress-bar {
  position: relative;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.order-status__progress-bar-item {
  position: relative;
  counter-increment: list;
  color: #ccc;
}

.order-status__progress-bar-item.is-done,
.order-status__progress-bar-item.current,
.order-status__progress-bar-item.is-rejected {
  color: #2a2a2a;
}

.order-status__progress-bar-item:not(:last-child) {
  padding-bottom: 20px;
  padding-top: 0;
  margin-bottom: 10px;
}

.order-status__progress-bar-item::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -33px;
  top: -25px;
  height: 20px;
  width: 10px;
}

.order-status__progress-bar-item:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -45px;
  width: 25px;
  height: 25px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: #ccc;
  transition: background-color 0.4s ease-out, border 0.4s ease-out;
}

.order-status__progress-bar-item:not(:first-child)::before {
  border-left: 2px solid #ccc;
}

.order-status__progress-bar-item.is-done:not(:first-child)::before {
  border-left: 2px solid #4caf52;
}

.order-status__progress-bar-item.is-rejected:not(:first-child)::before {
  border-left: 2px solid red;
}

.order-status__progress-bar-item.current:not(:first-child)::before {
  border-left: 2px solid var(--color-secondary);
}

.order-status__progress-bar-item.is-done::after {
  color: #fff;
  text-align: center;
  border: 2px solid #4caf52;
  background-color: #4caf52;
  transition: background-color 0.4s ease-in, border 0.4s ease-in;
}

.order-status__progress-bar-item.current::after {
  padding-top: 1px;
  width: 25px;
  height: 25px;
  top: 0;
  left: -45px;
  font-size: 14px;
  text-align: center;
  color: #4caf52;
  border: 2px solid var(--color-secondary);
  background-color: var(--color-secondary);
  transition: background-color 0.4s ease-out, border 0.4s ease-out;
  /*Animation*/
}

.order-status__progress-bar-item.current {
  font-size: 20px;
  font-weight: bold;
}

.order-status__progress-bar-animation-circle {
  background-color: var(--color-secondary);
  width: 29px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  left: -47px;
  top: -2px;
  z-index: 9;
  display: inline-block;
  content: "";
  opacity: 0.4;
  /*Animation*/
  animation: circleScaleAnimation 1s alternate infinite ease-in-out;
  animation-delay: 1s;
}

.order-status__progress-bar-item.is-done > .order-status__progress-bar-animation-circle-hidden {
  background-color: var(--color-secondary);
  width: 29px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  left: -45px;
  top: 0px;
  z-index: 9;
  display: none;
  content: "";
  opacity: 0.4;
  /*Animation*/
  animation: circleTransitionAnimation 0.6s 1 ease-in-out forwards;
}

/** checkMark / xMark animation **/

@keyframes checkMark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 3px;
    opacity: 1;
  }
  40% {
    height: var(12px);
    width: var(5px);
    opacity: 1;
  }
  100% {
    height: 12px;
    width: 5px;
    opacity: 1;
  }
}

.checkMark,
.xMark {
  width: 29px;
  position: absolute;
  height: 29px;
  top: -1px;
  left: -45px;
  z-index: 9;
}

.checkMark.draw:after {
  animation-duration: 500ms;
  animation-timing-function: ease;
  animation-name: checkMark;
  transform: scaleX(-1) rotate(135deg);
}

.checkMark::after {
  opacity: 1;
  transform-origin: left top;
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
  content: "";
  position: absolute;
  width: 5px;
  height: 12px;
  top: 15px;
  left: 7px;
}

.xMark {
  background-color: red;
  border-radius: 50%;
  transition: background-color 0.4s ease-out;
}

.xMark:before,
.xMark:after {
  position: absolute;
  left: 13px;
  top: 6px;
  content: " ";
  height: 17px;
  width: 3px;
  background-color: #fff;
}
.xMark.draw:before {
  animation-duration: 500ms;
  animation-timing-function: ease;
  animation-name: xMarkRotateClockWise;
  transform: rotate(45deg);
}
.xMark.draw:after {
  animation-duration: 500ms;
  animation-timing-function: ease;
  animation-name: xMarkRotateCounterClockWise;
  transform: rotate(-45deg);
}

@keyframes xMarkRotateClockWise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes xMarkRotateCounterClockWise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-45deg);
  }
}

/** End of checkMark / xMark animation **/

/** Blue circle animation */
@keyframes circleScaleAnimation {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.5);
  }
}

/* Blue Circle to Green circle move down transition */
@keyframes circleTransitionAnimation {
  from {
    transform: translateY(0em);
    opacity: 0.4;
    background-color: var(--color-secondary);
    z-index: 9;
  }
  to {
    transform: translateY(3.7em);
    opacity: 0;
    background-color: #4caf52;
    z-index: unset;
  }
}

/**Desktop layout **/
@media screen and (min-width: 660px) {
  .order-status__progress-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 96%;
    padding-left: 0;
    margin-bottom: 0;
  }

  .order-status__progress-bar-item {
    padding-top: 40px !important;
    min-width: calc(100% / 11);
    text-align: center;
  }

  .order-status__progress-bar-item.is-done:not(:first-child)::before {
    border-left: none;
    border-bottom: 2px solid #4caf52;
  }

  .order-status__progress-bar-item.current:not(:first-child)::before {
    border-left: none;
    border-bottom: 2px solid var(--color-secondary);
  }

  .order-status__progress-bar-item.is-done:first-child::before {
    border: none;
  }

  .order-status__progress-bar-item:nth-child(2)::before {
    margin-left: 10px;
  }

  .order-status__progress-bar-item:not(:first-child)::before {
    border-left: none;
  }

  .order-status__progress-bar-item::before {
    left: -120%;
    top: -5px;
    height: 20px;
    width: 120%;
    border-left: none;
    border-bottom: 2px solid #ccc;
  }

  .order-status__progress-bar-item::after,
  .order-status__progress-bar-item.current::after,
  .checkMark {
    top: 0;
    left: 30%;
    height: 25px;
  }

  .xMark {
    left: 30%;
  }

  .order-status__progress-bar-animation-circle {
    left: 27.5%;
  }

  .order-status__order-details-container {
    background-color: #eaeaea;
    width: 85%;
    margin: 0 auto;
    padding: 2em;
    margin-top: 0;
  }

  .order-status__steps-wrapper {
    margin-top: 1em;
  }

  div.order-status__progress-bar-item.is-done {
    margin-bottom: 2em;
    margin-top: 1em;
  }
}

/** End of Desktop layout **/
