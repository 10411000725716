.payment-confirmation-container {
  width: 100%;
  height: 100%;
  color: #2a2a2a;
  padding: 0 5% 15em;
  overflow-y: scroll;
}

.payment-confirmation__image {
  margin: 35px 20px;
}

.payment-confirmation__checkmark {
  background: #bfd72f;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  -webkit-animation: appear 1s forwards, blink 0.5s 0.75s forwards;
  animation: appear 1s forwards, blink 0.5s 0.75s forwards;
}

.payment-confirmation__checkmark > .icon {
  width: 12px;
  height: 12px;
  stroke: #fff;
}

.payment-confirmation__heading {
  opacity: 0;
  transform: translateY(100px);
  font-size: 1.25rem;
  font-weight: 600;
  -webkit-animation: slide-in 0.5s forwards;
  animation: slide-in 0.5s forwards;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.payment-confirmation__thankyou-note {
  display: flex;
  justify-content: left;
  align-items: center;
}

@keyframes appear {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.payment-confirmation__button-wrapper {
  margin-top: 24px;
  padding-top: 24px;
}
