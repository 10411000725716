.rewards-module__toggle-reward-name-button > .icon--star {
  width: 1em;
  height: 1em;
}

.rewards-module__step-value{
    font-size: 0.8em;
}

.rewards-module__step-name{
    margin-top: 10px;
}

.rewards-module__stepper-wrapper{
    height: 4.5em;
}