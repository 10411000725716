/* App styles */
.App {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
}

.App__error {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 10%;
  color: #d34146;
  font-size: 0.95rem;
  font-weight: 600;
  text-align: center;
  background-color: #fff;
}

.ad2hs-prompt {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 12px;
  background-color: #fff;
  box-shadow: 0px -3px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.ad2hs-prompt__image-and-name {
  display: flex;
  align-items: center;
}

.ad2hs-prompt__image-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  overflow: hidden;
}

.ad2hs-prompt__install-button {
  height: 44px;
  padding: 0 12px;
  font-size: 0.95rem;
  border-radius: 4px;
}

.ad2hs-prompt__close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
}

.ad2hs-prompt__close-button > .icon--x {
  width: 18px;
  height: 18px;
}

.secondary-splash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9999;
}

.secondary-splash--closing {
  animation: fadeOut 0.3s forwards;
}

.secondary-splash__logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 128px;
  height: 128px;
}

.secondary-splash__name {
  position: absolute;
  bottom: 48px;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
}

button:focus,
input:focus,
optgroup:focus,
select:focus,
textarea:focus {
  outline-color: var(--color-primary);
}

@media screen and (min-width: 660px) {
  .App {
    min-height: 100%;
    overflow-y: auto;
  }
}

.orderTimeDatePopupContent > h2 {
  margin: 0 0 1.5em;
  font-size: 0.8em;
  font-weight: 400;
  text-align: center;
}

.orderTimeDatePopupContent {
  min-width: 15em;
}

.orderTimeDatePopupContent > .dashboard__selects-container {
  margin: 0 auto;
}

/* END OF App styles */
