.register__success-screen {
  width: 100%;
  height: 100%;
  z-index: 500;
}

.modal-wrapper--success-register {
  background-color: white;
  border-top: solid 5px #d64123;
}

.successful-register__heading {
  margin: 60px 0 0;
  text-align: center;
  font-size: 26px;
  font-weight: 400;
}

.register__success-login-button-wrapper {
  position: absolute;
  bottom: 85px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.register__success-login-button-wrapper > button {
  position: relative;
  display: block;
  margin: 0 auto;
}

.register__success-content-message {
  text-align: center;
}
@media screen and (min-width: 660px) {
  .register__success-content-message {
    margin-top: 3em;
  }
}
