.dashboard__module-main {
  position: relative;
}

.rewards-module__balance-and-my-rewards-link {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  flex-direction: column;
}

.rewards-module__balance-and-my-rewards-link > .rewards-module__balance-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rewards-module__balance-and-star {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.rewards-module__balance-and-star > div > div {
  display: flex;
  justify-content: space-between;
}

.rewards-module__balance-and-star > div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.rewards-module__balance {
  font-size: 1.5em;
  font-weight: 500;
}

.rewards-module__balance-and-star > div > div > span > .icon--star {
  width: 20px;
  height: 20px;
  fill: #f49c00;
  stroke: none;
}

.rewards-module__masked-card-text {
  color: #2a2a2a;
  font-size: 1.2rem;
  flex-direction: column;
  display: flex;
  align-items: flex-end;
}

.rewards-module__masked-card {
  font-size: 12px;
}

.rewards-module__balance-label {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}

.rewards-module__balance-label-header {
  font-size: 18px;
  font-weight: 600;
}

.rewards-module__balance-money-container {
  width: 51%;
}

.rewards-module__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.rewards-module__row:not(:last-child) {
  margin-bottom: 20px;
}

.rewards-module__my-rewards-link {
  font-size: 16px;
}

.rewards-module__column {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.rewards-module__stepper-wrapper {
  height: 4.5em;
}

.rewards-module__stepper {
  display: flex;
  list-style-type: none;
  width: 100%;
  height: 100%;
  margin: 0 0 0 -11.25px;
  padding-left: 0;
  overflow: auto;
  white-space: nowrap;
}

.rewards-module__step {
  position: relative;
  display: flex;
  color: #878786;
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.5;
  text-align: left;
  white-space: nowrap;
  overflow: visible;
}

.rewards-module__step-line {
  width: 3em;
  height: 2px;
  margin-top: calc(1.25em / 2);
  background-color: #878786;
}

.rewards-module__step--redeemable .rewards-module__step-line {
  background-color: #f49c00;
}

.rewards-module__step > .rewards-module__step-line:first-child {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rewards-module__step > .rewards-module__step-line:last-child {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rewards-module__step:first-child > .rewards-module__step-line {
  border-radius: 9999px 0 0 9999px;
}

.rewards-module__toggle-reward-name-button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 2.5em;
  height: 2.5em;
}

.rewards-module__toggle-reward-name-button--active > .icon--star {
  border: 1px solid #f49c00;
  border-radius: 50%;
}

.rewards-module__step--redeemable .rewards-module__toggle-reward-name-button {
  color: #f49c00;
}

.rewards-module__toggle-reward-name-button > .icon--star {
  width: 1.25em;
  height: 1.25em;
  fill: #878786;
  stroke: none;
}

.rewards-module__step--redeemable .icon--star {
  fill: #f49c00;
}

.rewards-module__step-name {
  position: absolute;
  top: 4em;
  width: 100%;
  font-size: 0.75em;
  font-weight: 400;
  text-align: center;
}

.rewards-module__step:first-child .rewards-module__step-name {
  left: 0;
  width: 55px;
  text-align: left;
}

.rewards-module__step:last-child .rewards-module__step-name {
  left: auto;
  right: 0;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rewards-module__step--redeemable .rewards-module__step-name {
  color: #2a2a2a;
}

.rewards-module__empty-step {
  display: flex;
  flex-grow: 1;
}

.rewards-module__empty-step .rewards-module__step-line {
  width: 100%;
}

.rewards-module__balance-money {
  font-size: 1rem;
}

.rewards-module__row > span:first-child {
  min-width: 60px;
}

.dashboard_rewards-module-question-container {
  margin-top: 10px;
}

@media screen and (min-width: 660px) {
  .rewards-module__balance-wrapper {
    display: flex;
    align-items: center;
  }

  .rewards-module__balance-and-star {
    margin-right: 8px;
  }

  .rewards-module__toggle-reward-name-button {
    font-size: 21px;
  }

  .rewards-module__links-wrapper {
    display: flex;
    flex-direction: column;
  }

  .rewards-module__links-wrapper > a + a {
    margin-top: 8px;
  }

  .rewards-module__stepper {
    overflow-x: hidden;
  }

  .rewards-module__stepper:hover {
    overflow-x: auto;
  }

  .rewards-module__masked-card-text {
    margin-right: 0.85em;
  }

  .rewards-module__row > .rewards-module__balance > .icon--star {
    width: 25px;
    height: 25px;
    margin-bottom: 2px;
  }

  .rewards-module__column {
    width: 100%;
  }
  .rewards-module__balance {
    align-items: center;
  }

  .rewards-module__row:not(:last-child) {
    margin-bottom: 10px;
  }

  .rewards-module__row {
    margin-bottom: 10px;
  }

  .dashboard__rewards-module {
    margin-bottom: 0 !important;
  }

  .rewards-module__row.view-rewards-link {
    margin-bottom: 0;
    margin-top: 15px;
  }
}

@media screen and (min-width: 1366px) {
  .rewards-module__stepper {
    overflow-x: hidden;
  }

  .rewards-module__stepper:hover {
    overflow-x: auto;
  }
}
