.menu-bill-toggle__container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.menu-bill-toggle__container > button {
  width: 50%;
  padding: 0.5em 0 0;
  font-weight: 600;
}

.menu-bill-toggle__button::after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 0px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
  margin-top: 0.5em;
}

.menu-bill-toggle__button.is-active:after {
  width: 100%;
  background: var(--color-primary);
}

.menu-bill-toggle__button.is-active {
  color: var(--color-primary);
}

.menu-bill-toggle__button > span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-bill-toggle__button > span > .icon {
  width: 20px;
  height: 20px;
}

.menu-bill-toggle__button.is-active > span > .icon {
  color: var(--color-primary);
  fill: var(--color-primary);
}
