.loading-dots {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto;
}

.loading-dot {
  display: block;
  width: 12px;
  height: 9px;
  border-radius: 50%;
  animation: loadingDotAnimation 1.5s infinite both;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.3s;
}

.loading-dot:nth-child(4) {
  animation-delay: 0.4s;
}

.loading-dots-container {
  display: flex;
  align-items: center;
}

@keyframes loadingDotAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}
