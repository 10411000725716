.giftCardDetailsWrapper {
  padding: 1.9rem 0 !important;
}

.giftCardSelectHeader {
  padding: 0px 1.9rem 1.9rem 1.7rem !important;
}

.giftCardImageWrapper {
  height: auto !important;
  width: auto !important;
  position: relative;
  top: 0 !important;
  left: 0 !important;
  margin-right: 1em;
}

.giftCardImage>img {
  object-fit: contain !important;
  height: 100% !important;
  max-height: 10.3rem !important;
  max-width: 17.3rem !important;
  position: relative;
  margin-bottom: 0;
  }
  
.giftCardImage.selectedCardImage {
  height: 10.5rem ;
  width: 17.6rem ;
}
.giftCardImage.selectedCardImage>img {
  height: 11.5rem !important;
  width: 17.6rem !important;
}

.mui_button_override {
  text-transform: none !important;
  color: inherit !important;
  font-size: 0.75rem !important;
  z-index: 1 !important;
}

.dateTimeInputComboContainer .rmdp-week:hover>.rmdp-day {
  background-color: inherit !important;
  color: #000 !important;
  border-radius: 50% !important;
}

.dateTimeInputComboContainer .rmdp-week:hover>.rmdp-day:first-child {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.dateTimeInputComboContainer .rmdp-week:hover>.rmdp-day:last-child {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.dateTimeInputComboContainer .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background-color: initial !important;
  color: var(--color-secondary) !important;
  border-radius: 12px;
}

.compactGiftCardCarouselContainer {
  display: flex;
  margin-left: 1em;
  width: 100%;
  align-self: end;
  background: transparent;
  margin-top: 0;
  flex-direction: column;
}

.compactGiftCardCarouselContainer>p {
  margin: 0;
  margin-bottom: 1.5rem;
}

.compactGiftCardSliderArrowButton {
  height: 100% !important;
}

.react-multi-carousel-list {
  display: flex;
  flex-direction: column;
}

.react-multi-carousel-track {
  display: flex;
  justify-content: center;
  transform: none !important;
  max-width: 500px;
}

.react-multi-carousel-item {
  width: 200px !important;
  height: 200px !important;
}

.switchGiftCardCarouselContainer {
  justify-content: space-between;
  height: 97.5%;
}

.switchCompactCarouselContainer {
  height: 90% !important;
}

.giftCardRecipientDetailsContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1.9em;
  z-index: 2;
}

.slideDownAnimation {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;

  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;

  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;

  visibility: visible !important;
}
.gcSelectedTitle{
  word-wrap: break-word;
}

@keyframes slideDown {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }

  50% {
    transform: translateY(-8.5%);
    opacity: 0;
  }


  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
  }

  50% {
    -webkit-transform: translateY(8%);
  }

  65% {
    -webkit-transform: translateY(-4%);
  }

  80% {
    -webkit-transform: translateY(4%);
  }

  95% {
    -webkit-transform: translateY(-2%);
  }

  100% {
    -webkit-transform: translateY(0%);
  }
}

.giftCardFormsLoadingOverlay{
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 4;
  background: #ffffff75;
}

.giftCard_sendMoreButtonWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 5em;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.giftCard_sendMoreButtonWrapper>button {
  border-radius: 4em !important;
  width: fit-content;
  padding: 0.75em 1.5em !important;
  border-width: 1.5px;
  border-color: #757575;
  color: #757575;
  text-transform: none;
  font-weight: bold;
}

.giftCard_sendMoreButtonWrapper>button.activeText {
  border-color: var(--color-secondary);
  color: var(--color-secondary);
}

.giftCard_sendMoreButtonWrapper>button svg {
  color: #757575 !important;
}

.giftCard_sendMoreButtonWrapper>button.activeText svg {
  color: var(--color-secondary) !important;
}
.gcRemoveButton > Button > span{
  color: var(--color-secondary) !important;
}
.recipientRightColumn{
  display:flex; 
  align-items:center;
}
.gcRecipientMinimumOneError{
  color: var(--color-error);
  padding:0;
  margin-right: 1.25rem;
  width: 100%;
}
.sendMoreButtonError{
  opacity: 0;
  position: absolute;
  bottom: 25px;
  transform: translateY(0);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ff2020;
  font-size: 14px;
  text-align: center;
  animation: showFABError 0.3s forwards;
}
@keyframes showFABError {
  100% {
    opacity: 1;
    transform: translateY(calc(-52px / 2 - 10px));
  }
}
.gcAddToOrderButtonDisabled{
  opacity: 0.5;
  pointer-events: none;
}
.hideSendMoreButton{
  display: none;
}

@media screen and (max-width: 660px) {
  .gcErrorHeaderRow{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .gcRecipientMinimumOneError > p{
    margin-bottom: 0;
    margin-top: 5px;
  }
  .giftCardImage.selectedCardImage>img {
    height: 6.5rem !important;
    width: 10rem !important;
  }
  .giftCardImage.selectedCardImage {
    height: auto !important;
    width: auto !important;
  }

  .switchCompactCarouselContainer {
    height: 90% !important;
  }

  .giftCardInvalidImageSlide>p {
    width: 130px;
  }
  .gcFormButton div.desktop-container{
    display: flex !important;
    flex-direction: row-reverse;
    align-items: center;
  }
  .inlineFormButton {
    width: fit-content;
    background-color: transparent !important;
    display: flex !important;
    justify-content: center;
  }
  .giftCardRecipientDetailsContainer{
    padding-bottom: 4em;
  }
}