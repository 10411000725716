.orderLevelSuggestedItemHeading{
  margin-top: 0;
  margin-bottom: 1.25em;
}
.suggestedItemOrderLevelGrid{
  padding: 0.5em 0.5em;
  gap: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
}
.suggested-item-order-level-card{
  max-height: 100px;
  border-radius: 4px !important;
  border-bottom-right-radius: 15px !important;
}
.remove-suggested-item-card-border{
  border-bottom-right-radius: 4px !important;
}
.suggested-item-list-image{
  height: 100% !important;
  width: 92px !important;
  position: relative !important;
}

.suggestedMenuItemDetailsRow{
  height: fit-content;
  display: flex;
}
.suggestedItemCardTextWrapper{
  height: fit-content !important;
}
.suggestedItemOrderLevelImageWrapper{
  height: 92px !important;
  width: fit-content !important;
  padding: 0 !important;
  border-radius: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  max-width: 92px;
  min-width: 92px;
  overflow: hidden;
}
.orderLevelSuggestedItemsPriceWrapper{
  padding: 0.5em 0;
  padding-right: 0.25em;
  overflow: hidden;
}
.orderLevelSuggestedItemsPriceWrapper span{
  font-size: 1rem !important;
}
.orderLevelSuggestedItemsPriceWrapper .suggestedItems__button_wrapper{
  padding-right: 0;
}
.orderLevelSuggestedItemsPriceWrapper .suggestedItems__button_wrapper button{
 font-size: 0.8rem;
}
.orderLevelSuggestedItemsPriceWrapper .cartItem__heading.orderLevelSuggestedItemHeading{
  width: 70%;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
}
.orderLevelSuggestedItemsPriceWrapper .cartItem__heading{
  width: 92% !important;
  white-space: nowrap;
}
.orderLevelSuggestedItemsPriceWrapper .suggestedItems__button_wrapper button.suggested_items__view-details{
  min-width: 65%;
  text-align: start;
}
.suggestedOrderLevelItemCardButton{
  height: 100% !important;
}
.suggestedMenuItemQuickAddButtonContainer{
  display: flex;
  flex-direction: row-reverse;
  position: relative;
}
.suggested-items-order-level-wrapper {
  overflow-y: visible;
  overflow-x: visible;
  height: auto;
}
.order-suggested-items-list-container{
  max-height: 100%;
  padding: 0;
  margin: 0;
}
.suggested-items-order-level-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 660px) {
  .suggestedItemOrderLevelGrid{
    max-height: 100%;
  }
  .suggested-items-order-level-wrapper {
    overflow-y: visible !important;
    overflow-x: visible;
    height: 95%;
    padding-bottom: 20px;
  }
  .suggestedItemOrderLevelGrid{
    gap: 0.75rem;
  }
  .order-suggested-items-list-container{
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media screen and (max-width: 860px) and (min-width: 661px) {
  .suggestedItemOrderLevelGrid{
    max-height: 100%;
    flex-direction: row;
  }
 .suggestedItemOrderLevelGrid li.suggested-item-order-level-card{
  width: 98% !important;
  }
  .orderLevelSuggestedItemsPriceWrapper .cartItem__heading.orderLevelSuggestedItemHeading{
    width: 70%;
    text-overflow: ellipsis;
    max-width: 80%;
    overflow: hidden;
  }
  .suggested-items-order-level-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
  }

}