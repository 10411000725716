.form--credit-card {
  max-width: 308px;
  margin: 0 auto;
}

.form__link-wrapper {
  text-align: center;
}

.form-wrapper > a {
  text-decoration: underline;
}

.form-heading {
  margin: 0 0 15px;
  font-size: 13px;
  font-weight: 600;
}

.form__fieldset + * {
  margin-top: 3em;
}

fieldset.form__field-wrapper + * {
  margin-top: 23px;
}

.form__field-wrapper + .form-submit__wrapper:not(:empty) {
  margin-top: 30px;
}

.form__field-wrapper--checkbox {
  display: flex;
  align-items: center;
  min-height: 27px;
}

.form__field-label--checkbox-radio {
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 120%;
  /* text-transform: capitalize; */
}

.form__field-label--checkbox {
  min-height: 27px;
  padding-left: 40px;
}

.form__field-label--regular-checkbox {
  font-size: 1rem;
  font-weight: 600;
}

.form__field-label--checkbox-greyed {
  color: #a09a9a;
}

.form__field-label--checkbox > a {
  text-decoration: underline;
}

.form__field-label--radio {
  padding-left: 32px;
  min-height: 22px;
}

.form__field-label--checkbox-radio::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form__field-label--checkbox::before {
  width: 25px;
  height: 25px;
  border-radius: 4px;
}

.form__field--checkbox:disabled + .form__field-label--checkbox:before,
.form__field--radio:disabled + .form__field-label--radio:before {
  background-color: #ececec;
}

.form__field-label--radio::before {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.form__field-wrapper--checkbox-radio + .form__field-wrapper--checkbox-radio {
  margin-top: 10px;
}

.form__field--checkbox:checked + .form__field-label--checkbox::before {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='icon icon--check'><polyline points='20 6 9 17 4 12'></polyline></svg>");
  background-repeat: no-repeat;
  background-position: center;
}

.form__field--radio:checked + .form__field-label--radio::before {
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'><circle cx='50' cy='50' r='30'/></svg>");
  background-repeat: no-repeat;
  background-position: center;
}

.form__field--checkbox-radio:focus + .form__field-label--checkbox-radio:before {
  outline: 1px solid #0086ff;
}

.checkbox__link {
  text-decoration: underline;
}

/*Submit button*/
.submit-button {
  padding: 15px;
  background: linear-gradient(var(--color-secondary), var(--color-secondary) 60%) no-repeat;
  border-radius: 50px;
  line-height: 100%;
}

.button--submit-form-wrapper {
  position: relative;
  width: 100%;
}

.button--submit-form {
  position: relative;
  display: block;
  margin: 0 auto;
  width: calc(100% - 36px);
  height: 50px;
  font-weight: 600;
  text-transform: uppercase;
}

.button--submit-form__success {
  /* position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;*/
  color: var(--color-button-text-disabled);
  /*font-size: 14px;*/
  background-color: var(--colo-disabled-background);
  clip-path: circle(0 at center);
  border-radius: 50px;
  animation: expandBackground 0.5s forwards;
  z-index: 2;
}

.button--submit-form__success > .icon--check {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 18px;
  margin: auto 0;
  width: 18px;
  height: 18px;
  color: var(--color-button-text-disabled);
}

.form__error {
  opacity: 0;
  transform: translateY(-50px);
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  max-width: 80%;
  margin: 0 auto;
  color: #ff2020;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  animation: showFormError 0.3s forwards;
}

@keyframes showFormError {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.form__error--hidden {
  animation: hideFormError 0.3s forwards;
}

@keyframes hideFormError {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

/* reCAPTCHA container */
.form__field-recaptcha-wrapper {
  transform: scale(0.85);
  transform-origin: 0 0;
}

/* Readjust error positioning to offset the scaled-down captcha */
.form__field-recaptcha-wrapper .form__field-error {
  right: -17.64%;
  min-width: 84px;
  font-size: 14px;
}

.button--intermediary-submit {
  display: block;
  min-width: 100px;
  height: 32px;
  margin: 0 auto;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  background-color: var(--color-primary);
  color: var(--color-button-text);
  text-transform: uppercase;
  border-radius: 9999px;
}

.save-credit-card__field-wrapper {
  display: none;
}

.save-credit-card__field-wrapper--enabled {
  display: block;
}

.form > .form-submit__wrapper {
  text-align: center;
}

.form.form--account-change-password > .form-submit__wrapper {
  max-width: 98vw;
}

.iconButtonMidAbsolute {
  left: 7rem;
}

.iconButtonEndAbsolute {
  right: 1rem;
  bottom: 11px !important;
}

.giftCardRecipientRowHeader {
  min-height: auto !important;
}

.selectInputValuePaddingLeft {
  padding-left: 2.8rem !important;
}
@media screen and (min-width: 660px) {
  .button--submit-form-wrapper > .button--primary {
    width: 75%;
    max-width: 250px;
  }

  .form--credit-card {
    max-width: unset;
    margin: 0 3em;
  }
}
@media screen and (max-width: 660px) {
  .gift-card-form-fieldset {
    padding: 0.25rem 1.875em 2rem 1.875rem !important;
  }
  .giftCardRecipientRowHeader{
    flex-wrap: wrap;
  }
}
