.main-content--pickup,
.main-content--delivery {
  /* prevent content overlap when keyboard is open on devices with smaller height */
  min-height: 300px;
  overflow: hidden;
}

.main-content--pickup .loading-spinner {
  top: 31.3%;
  bottom: auto;
}

.main-content--delivery .loading-spinner {
  position: fixed;
  top: 0;
  bottom: 0;
}

#google-map {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  scroll-behavior: auto;
  z-index: 999;
  min-height: 250px;
}

.map-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #fff;
  z-index: 900;
  overflow-y: scroll;
}

.main-content--pickup .map-overlay {
  z-index: 500;
}

.map-overlay__link--back {
  position: relative;
  top: 25px;
  left: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

.map-overlay__delivery-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 600; /* display on top of the overlay */
  padding: 0 1em 5em;
  margin-top: 2em;
}

.map-overlay__delivery-content .map-search-option__heading {
  position: relative;
  left: auto;
  width: auto;
  transform: translateX(100vh);
  margin: 0 0 30px;
  animation: slideInMapDeliveryFormHeading 0.6s forwards;
}

.map-search-options > * {
  z-index: 600; /* display on top of the overlay */
}

.map-overlay__delivery-content + .map-search-options > * {
  z-index: 400; /* display behind the overlay */
}

.map-search-options--minimized > * {
  z-index: 300;
}

.map-search-option__heading {
  position: absolute;
  left: 50%;
  transform: translate(100vh, -12%);
  width: 215px;
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
  white-space: pre-wrap;
  animation: slideInMapSearchOptionsHeading 0.6s forwards;
  margin-top: 1em;
}

.map-search-option__subheading {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
}

.map-search-option__geolocation-subheading {
  position: absolute;
  top: 29%;
  left: 50%;
  transform: translate(100vh, -29%);
  animation: slideInMapGeolocationSubheading 0.6s forwards;
}

.delivery-form-wrapper {
  width: 90%;
  max-width: 360px;
}

.map-search-option__searchbox-subheading {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(100vh, -55%);
  width: 240px;
  text-align: center;
  animation: slideInMapSearchboxSubheading 0.6s forwards;
}

.map-search-option__searchbox-wrapper {
  opacity: 0;
  position: absolute;
  top: 64%;
  left: 50%;
  transform: translate(-50%, -64%);
  width: 240px;
  animation: fadeIn 1.5s forwards;
}

.button--trigger-searchbox {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  width: 40px;
  height: 40px;
  margin: auto 0;
  background-color: #fff;
  border-radius: 50%;
}

.map-search-options--minimized {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transform: initial;
  transition: all 1s;
}

.map-search-options--minimized .map-search-option__searchbox-wrapper {
  left: calc(100vw - 40px - 18px);
  top: 18px;
  right: 18px;
  transform: initial;
  width: 40px;
  height: 40px;
  margin-top: 0;
  transition: all 1s;
}

.map-search-options--minimized .map-search-option__searchbox-wrapper input {
  margin-top: 0 !important;
  left: auto !important;
  margin-right: 20px !important;
}

.map-search-option__searchbox--minimized {
  width: 40px !important;
  transition: all 0.6s;
}

.map-search-option__searchbox--minimized input {
  width: 0 !important;
  padding: 0 !important;
  border: none !important;
  transition: all 0.6s;
}

.map-search-option__searchbox--minimized input.map-search-option__searchbox--expanded {
  width: 240px !important;
  right: 0 !important;
  left: auto !important;
  margin-right: 0 !important;
  padding: 0 25px !important;
  transition: 0.3s;
}

.map-search-option__searchbox--minimized .button--trigger-searchbox {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.google-maps__no-stores-found {
  opacity: 0;
  transform: translateY(100%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 660px;
  min-height: 101px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 30px 19px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.3);
  animation: slideUpNoStoresMessage 0.8s forwards;
  animation-delay: 1.1s;
  z-index: 300;
}

.google-maps--initial-search-performed.google-maps__no-stores-found {
  animation-delay: 0.1s;
}

.icon.icon--map-fav {
  width: 24px;
  height: 24px;
}

@keyframes slideInMapSearchOptionsHeading {
  to {
    opacity: 1;
    transform: translate(-50%, -12%);
  }
}

@keyframes slideInMapGeolocationSubheading {
  to {
    opacity: 1;
    transform: translate(-50%, -29%);
  }
}

@keyframes slideInMapSearchboxSubheading {
  to {
    opacity: 1;
    transform: translate(-50%, -55%);
  }
}

@keyframes slideInMapDeliveryFormHeading {
  to {
    transform: translateX(0);
  }
}

@keyframes slideUpNoStoresMessage {
  to {
    opacity: 1;
    transform: initial;
  }
}

@media screen and (min-width: 660px) {
  .main-content--pickup,
  .main-content--delivery {
    padding-bottom: 0;
  }

  .google-maps__stores-info-container {
    top: calc(100% - 129px - 5%);
  }

  .google-maps__stores-info-container--expanded {
    top: 5%;
  }

  .store-info__details-modal {
    height: 100%;
  }

  .store-info__details-modal > * {
    display: flex;
    max-width: 660px;
    margin: 0 auto;
  }

  #google-map {
    min-height: 50vh;
  }
}

.pac-container {
  z-index: 1155;
}
