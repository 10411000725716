.add-new-card-main-content {
  padding-top: 63px;
  border-top: solid 5px #2a2a2a;
}

.add-new-card__button--close {
  position: absolute;
  top: 9px;
  left: 9px;
  width: 50px;
  height: 50px;
}

.add-new-card__content-wrapper {
  padding: 0 24px;
}

.add-new-card__heading {
  margin: 0 0 19%;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.add-new-card__form-card {
  padding-top: 40px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 9px 14px 2px rgba(0, 0, 0, 0.21);
}

.add-new-card-form {
  margin-bottom: 36px;
  padding: 0 24px;
}

.add-new-card__scanner-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 24px;
  color: #2a2a2a;
  font-size: 14px;
  font-weight: 600;
  background-color: #f3f3f3;
}

.add-new-card__scanner-button > .icon--barcode-scan {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.modal--add-new-card-barcode-scanner {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

@media screen and (min-width: 660px) {
  .add-new-card-main-content {
    border-top: none;
  }

  .add-new-card__heading {
    margin-bottom: 2rem;
  }

  .add-new-card__content-wrapper {
    max-width: 440px;
    margin: 0 auto;
    padding: 0 24px;
  }

  #submit-add-new-card-form {
    max-width: 440px;
  }
}
