.menu-category-card__quick-add-button-container {
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom-left-radius: 0.4em;
  border-bottom-right-radius: 1em;
}

.menu-category-card__quick-add-button__quantity-input {
  max-width: 50%;
  text-align: center;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border: none;
  border-bottom: 1px solid var(--color-gray);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  margin: auto;
  height: calc(100% - 2px);
}

.menu-category-card__quick-add-button__quantity-input.collapse {
  animation-name: fadeOut;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
}

.menu-item-quick-button {
  background-color: var(--color-primary);
  border-radius: 50%;
  padding: 0.5em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
}

.menu-item-quick-button.plus.withCustomization:active::after {
  background-color: #000000;
  content: "";
  width: 100%;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 40px;
  opacity: 0.3;
}

.menu-item-quick-button.plus.withoutCustomization:active::after {
  background-color: #000000;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 40px;
  opacity: 0.3;
}

.menu-item-quick-button.minus:active::after {
  background-color: #000000;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 40px;
  opacity: 0.3;
}

.menu-item-quick-button.isEditButton:active::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  left: -3px;
  width: 50%;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  z-index: 0;
}

.menu-item-quick-button-container.withQuantity.withCustomization
  > .menu-item-quick-button:active::after {
  background-color: #000000;
  content: "";
  width: 110%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 40px;
  opacity: 0.3;
}

.menu-item-quick-button:disabled {
  color: unset;
  background-color: var(--color-primary);
}

.menu-item-quick-button.minus:disabled::after {
  background-color: #fff;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  opacity: 0.6;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/*.menu-item-quick-button.plus:disabled::after {
  background-color: #fff;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  left: unset;
  border-radius: 50%;
  opacity: 0.6;
  z-index: 4;
}*/

.menu-item-quick-button.plus:disabled > svg {
  opacity: 0.2;
}

.menu-item-quick-button.plus.withCustomization:disabled::after {
  background-color: #fff;
  content: "";
  width: 100%;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  left: unset;
  border-radius: 40px;
  opacity: 0.6;
  z-index: 4;
}

.menu-item-quick-button.plus {
  right: -5px;
  bottom: 0;
}

.menu-item-quick-button.plus:disabled {
  right: 0;
  bottom: 0;
}

.menu-item-quick-button.minus {
  position: absolute;
  animation-name: slideMinusButtonLeft;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  bottom: 0px;
  z-index: 4;
  min-height: 30px;
  min-width: 30px;
  padding: 0.4em;
  margin-bottom: 2px;
}

.menu-item-quick-button.plus.withQuantity {
  position: absolute;
}

.menu-item-quick-button.plus.withQuantity {
  bottom: 0;
  right: -5px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  height: 100%;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}

.menu-item-quick-button.isEditButton {
  width: 100%;
  background-color: transparent;
}

.menu-item-quick-button-container {
  background-color: transparent;
  min-height: 2.5em;
  min-width: 4.2em;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  width: 60%;
}

.menu-item-quick-button-container.expanded {
  width: 100%;
  flex-direction: row-reverse;
}

.menu-item-quick-button-container.withQuantity.withCustomization {
  /*background-color: var(--color-secondary);*/
  min-width: 4.2em;
  /*animation: expandHorizontally 1s ease-in;*/
}

.menu-item-quick-button-quantity-span {
  border-radius: 50%;
  padding: 0.1em;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 14px;
  bottom: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.menu-item-quick-button-container.withCustomization .menu-item-quick-button-quantity-span {
  bottom: 0;
  width: 0;
  min-width: 0;
  min-height: 0;
  padding: 0;
  transform: translateX(11px);
  animation: slideQuantityContainer 0.3s linear;
  animation-fill-mode: forwards;
}

.menu-item-quick-button-container.withCustomization
  .menu-item-quick-button-quantity-span.withoutPlus {
  bottom: 0;
  width: 0;
  min-width: 0;
  min-height: 0;
  padding: 0;
  transform: translateX(0px);
  animation: none;
}

.menu-item-quick-button-container.withoutCustomization .menu-item-quick-button-quantity-span {
  bottom: 0;
  width: 0;
  min-width: 0;
  min-height: 0;
  padding: 0;
  transform: translateX(11px);
  animation: slideQuantityContainerWithMinus 0.3s linear;
  animation-fill-mode: forwards;
}

.menu-item-quick-button-container__edit-quantity {
  background-color: var(--color-secondary);
  animation: slideEditContainer 0.3s linear;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  height: 100%;
  display: flex;
  border-radius: 40px;
  justify-content: space-between;
  align-items: center;
  right: 0px;
  position: absolute;
}

.menu-item-quick-button-container__edit-quantity.withoutPlus {
  background-color: var(--color-secondary);
  animation: slideEditContainerWithoutPlus 0.3s linear;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  height: 100%;
  display: flex;
  border-radius: 40px;
  justify-content: space-between;
  align-items: center;
  right: 0px;
  position: absolute;
}

@keyframes slideEditContainer {
  0% {
    width: 50px;
  }
  100% {
    width: 95px;
  }
}

@keyframes slideEditContainerWithoutPlus {
  0% {
    width: 50px;
  }
  100% {
    width: 75px;
  }
}

@keyframes slideEditContainerDesktop {
  0% {
    width: 50px;
  }
  100% {
    width: 100px;
  }
}

@keyframes slideQuantityContainer {
  0% {
    right: 0;
    z-index: 0;
    min-width: 0;
    min-height: 0;
    transform: translateX(11px);
  }
  100% {
    z-index: 2;
    min-width: 25px;
    min-height: 40px;
    transform: translateX(0);
    width: unset;
  }
}

@keyframes slideQuantityContainerWithMinus {
  0% {
    right: 0;
    z-index: 0;
    min-width: 0;
    min-height: 0;
    transform: translateX(11px);
  }
  100% {
    z-index: 2;
    min-width: 50px;
    min-height: 40px;
    transform: translateX(1);
    width: unset;
  }
}

.menu-item-quick-button-icon-span > .icon--edit {
  margin-left: 0.5em;
  padding: 0.2em;
}

/* Animations */
@keyframes slideMinusButtonLeft {
  0% {
    right: 0;
    opacity: 0;
  }
  100% {
    right: 45px;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes expandHorizontally {
  0% {
    min-width: 0;
  }
  100% {
    min-width: 4.2em;
  }
}
.orderLevelSuggestedItemQuickAddButtonContainer{
  max-height: 2.5em !important;
  min-height: auto !important;
}
.orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button-icon-span > .icon--edit{
  padding: 0.15em;
  padding-left: 0;
  margin-top:4px
}
.orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button.plus.withQuantity{
  bottom: -0.5px !important;
  right: -5px;
}
.orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button.minus{
  bottom: 1px;
  height: 30px !important;
  min-height: 30px !important;
}

.orderLevelSuggestedItemButtonPlusContainer.menu-item-quick-button.plus{
  max-height: 40px !important;
  min-height: auto !important;
  bottom: 0px;
  right: -5px;
  max-width: 1.66em;
}
.suggestedItemQuickAdd{
  position: absolute !important;
  border-radius: 100%;
  bottom: 0;
  background-color: #fff !important;
}
.suggestedItemQuickAdd .menu-item-quick-button {
  padding: 8px;
}
.orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button-container__edit-quantity{
  height: 40px;
  right: 6px !important;
  bottom: -0.5px;
}
.orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button-container__edit-quantity.withoutPlus {
  right: 2px !important;
}
.orderLevelSuggestedItemQuickAddButtonContainer .suggestedQuickAddButtonMinus.menu-item-quick-button.minus{
  background-color: transparent;
}
.orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button-quantity-span{
  font-size: 1rem;
}
@media screen and (min-width: 660px) {
  .menu-item-quick-button-container__edit-quantity {
    background-color: var(--color-secondary);
    animation: slideEditContainerDesktop 0.3s linear;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    height: 100%;
    display: flex;
    border-radius: 40px;
    justify-content: space-between;
    align-items: center;
    right: 0px;
    position: absolute;
  }
}

@media screen and (max-width: 660px) {
  .orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button.plus.withQuantity{
    bottom: -0.5px !important;
    right: -5px;
  }
  .orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button.minus{
    bottom: 1px;
    height: 30px !important;
    min-height: 30px !important;
  }
  
  .orderLevelSuggestedItemButtonPlusContainer.menu-item-quick-button.plus{
    max-height: 2.5em !important;
    min-height: auto !important;
    bottom: 0px;
    right: -5px;
    height: 40px
  }
  .orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button-container__edit-quantity{
    height: 40px;
    right: 0 !important;
    bottom: -1 px;
  }
  .suggestedItemQuickAdd .menu-item-quick-button{
    padding: 0.5em;
  }
  .orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button-icon-span > .icon--edit{
   
    margin-top:2px
  }
}


