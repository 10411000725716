.cart__tip-container {
  margin: 0 auto;
  border-radius: 4px;
  background-color: #fff;
}

.cart__tip-container > h2 {
  margin-top: 0;
  font-size: 16px;
}

.cart__tip-amounts {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
}

.cart__tip-button {
  padding: 5px 10px;
  border-radius: 9999px;
  font-size: 14px;
  font-weight: 500;
}

.cart__tip-button-selected {
  color: var(--color-button-text);
  background-color: var(--color-primary);
}

.cart__tip-custom-amount-input {
  border-radius: 0;
  padding: 10px 0;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #cdcdcd;
  background-clip: padding-box;
  line-height: normal;
  background-color: #fff;
  padding: 0 0 0 5px;
  width: 90%;
}

.cart__tip-custom-amount-label {
  font-size: 14px;
  font-weight: 700;
  font-size: 12px;
}

.cart__tip-custom-amount-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /*margin-top: 20px;*/
  padding: 0px 30px 20px;
}

.cart__tip-custom-amount-container > div {
  flex: 1 48%;
  position: relative;
}

.cart__tip-remove-custom-amount {
  position: absolute;
  right: 15px;
  bottom: -3px;
}

.cart__tip-remove-custom-amount > .icon--x-in-circle {
  width: 20px;
  height: 20px;
}

.cart__tip-custom-amount-input:focus {
  border-color: var(--color-secondary);
}

.cart__tip-custom-amount-currency-sign {
  margin-right: 5px;
}

@media screen and (min-width: 660px) {
  .cart__tip-remove-custom-amount {
    right: 15px;
    bottom: -3px;
  }
}

@media screen and (min-width: 850px) {
  .cart__tip-container > h2 {
    font-size: 1.5em;
  }

  .cart__tip-button,
  .cart__tip-custom-amount-label {
    font-size: 1em;
  }

  .cart__tip-container {
    padding: 2em 2em 3em 2em;
  }
}

@media screen and (min-width: 1200px) {
  .cart__tip-remove-custom-amount {
    right: 25px;
    bottom: -3px;
  }
}
