.menu-combo-summary {
  padding: 10px 7vw;
  border-top: 1px solid var(--color-gray-light);
}

.menu-combo-detail {
  opacity: 0;
  transform: scale(0);
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* enables iOS "momentum" style scrolling */
  z-index: 1000;
  animation: scaleUp 0.3s forwards;
  background-color: #fff;
  padding-bottom: 5em;
}

.menu-combo-detail--closing {
  animation: scaleDown 0.3s forwards;
}

.menu-combo-name {
  line-height: 120%;
}

/** Summary Section **/
.menu-combo-summary .menu-combo-summary__image-wrapper {
  border-radius: 4px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.menu-combo-summary__image-wrapper > img {
  max-width: 50% !important;
}

.menu-combo-summary-details__name {
  font-weight: bold;
  font-size: 20px;
}

.menu-combo-summary-details__price {
  font-weight: 500;
  color: var(--color-gray-text);
  padding: 3px 0;
}

.combo-menu-subgroup-item-price-calorie {
  float: right;
}

.combo-menu-subgroup-item-summary {
  margin: 15px 0;
}

.combo-menu-subgroup-item-price-calorie {
  font-size: 13px;
  color: var(--color-gray-text);
}

/** End Of Summary Section **/

.menu-combo-item-detail {
  opacity: 0;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /*overflow-y: auto;*/
  -webkit-overflow-scrolling: touch;
  /* enables iOS "momentum" style scrolling */
  z-index: 1000;
  animation: scaleUp 0.3s forwards;
  background-color: white;
  padding-bottom: 300%;
}

.menu-combo-item-detail--skip-animation {
  opacity: 1;
  transform: scale(1);
  animation: none;
}

.menu-combo-item-detail--closing {
  animation: scaleDown 0.3s forwards;
}

.menu-combo-back-button {
  margin-left: 10px;
  font-size: 14px;
}

.menu-combo-slider-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  /*padding: 28px 0 calc(57px + 24px);*/
  padding: 0 0 24px;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 10;
  -webkit-animation: growMenuSlider 0.35s forwards;
  animation: growMenuSlider 0.35s forwards;
}

.menu-combo-slider-container button.menu-category-card__button:disabled {
  background-color: transparent;
}

.menu-combo-slider-container > p,
.menu-combo-summary > p {
  font-size: 12px;
  text-align: left;
  margin-left: 42px;
  color: #969494;
  font-weight: 600;
}

.menu-combo-slider-container > p {
  max-width: 90vw;
  color: #2a2a2a;
  margin: 0;
  font-size: 15px;
}

.menu-combo-slider-container .calories-disclaimer {
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  color: #969494 !important;
  padding-left: 0px !important;
}

.menu-combo-summary .calories-disclaimer {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #969494 !important;
  padding-left: 0px !important;
}

.menu-combo-slider {
  min-height: 487px;
  overflow: auto !important;
}
.item-detail__top-content-combo {
  z-index: 90;
}

.item-details__transition-container__fav-button {
  visibility: hidden;
  display: none;
}

#top-hidden-item-name-detail.item-detail__top-content-combo {
  z-index: 100;
  justify-content: flex-start;
  padding-left: 16px;
}

#top-hidden-item-name-detail.item-detail__top-content-combo .icon--arrow-left {
  width: 18px;
  height: 18px;
  margin-top: 3px;
}

.item-detail__top-content-combo > .item-detail__button--close > .icon--x {
  margin-top: 6px;
}

.menu-combo-item-detail-header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.next-group-button-combo {
  background-color: var(--color-online-order-settings-background);
  position: sticky;
  bottom: 0;
  width: 100%;
  padding-bottom: 16px;
  padding-top: 16px;
  min-height: 86px;
}

.next-group-button-combo > div {
  display: flex;
  align-items: center;
}

.heading-with-icon.combo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-content: center;
}

.quantity-combo-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: 100%;
  flex-wrap: wrap;
  max-width: 60%;
  margin: 0;
}

.quantity-combo-details span:first-child {
  color: var(--color-error);
}

.quantity-combo-details span:last-child {
  color: var(--color-gray);
  font-size: 13px;
  text-align: right;
}

.quantity-combo-details span.is-complete {
  color: #4caf52;
}

.combo-name-and-disclaimer-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-weight: 700;
  margin: 0;
}

.combo-name-and-disclaimer-text-menu-item-name {
  font-size: 1em;
  font-weight: 600;
}

.combo-name-and-disclaimer-text > .menu-combo-summary__total-price-disclaimer {
  font-size: 13px;
  font-weight: 400;
  margin-top: 5px;
}

.main-combo-header {
  position: sticky;
  top: 0;
  z-index: 12;
}

.main-combo-header .page-title {
  /*font-size: 3vh;*/
}

.order-item__option-header.combo-subgroup-header {
  position: sticky;
  top: 109px;
  z-index: 11;
}

.item-detail__content.isMinimized.combo {
  top: 54px;
}

.desktop-order-menu__combo-item-check-mark {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}

.desktop-order-menu__combo-item-check-mark > .icon--check {
  stroke: #fff;
  stroke-width: 3px;
  width: 18px;
  height: 18px;
  padding: 3px;
  background-color: #4caf52;
  border-radius: 100%;
}

.combo-category-filters__wrapper {
  margin: 0 auto 0 0;
}

.menu-combo-loader-overlay {
  background-color: transparent;
  width: 100%;
  height: 500%;
  position: absolute;
  z-index: 1000;
}

/** Mobile Specific Design **/
@media screen and (max-width: 660px) {
  .menu-combo-slider-container {
    padding-left: 24px;
    padding-right: 24px;
  }
}

/** Desktop Design **/
@media screen and (min-width: 660px) {
  .menu-combo-detail {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-content: center;
    height: 99%;
  }
  .order-item__option-header.combo-subgroup-header {
    position: unset;
  }
  .desktop-menu__menu-combo-summary {
    display: flex;
    align-items: flex-start;
    align-content: center;
    flex-direction: row;
  }

  .menu-combo-detail .order-item__option-header {
    min-height: 70px;
  }

  .desktop-menu__menu-combo-summary .item-detail__image-wrapper {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }

  .desktop-menu__menu-combo-summary > div {
    flex: 1;
  }

  .desktop-menu__menu-combo-summary > ul {
    flex: 2;
  }

  .menu-combo-summary-wrapper > .special-instructions-wrapper .special-instructions__field {
    width: 100%;
  }

  .menu-combo-summary {
    padding: 0;
    padding-top: 20px;
  }

  .menu-combo-summary__image-wrapper > img {
    max-width: 70% !important;
  }

  .menu-combo-slider-container {
    z-index: unset;
    padding-bottom: 1em;
  }

  .menu-combo-detail {
    animation: none;
    -webkit-animation: none;
    transform: scale(1);
    opacity: 1;
    overflow-y: unset;
    z-index: unset;
    padding-top: 1.5em;
    padding-right: 24px;
    padding-left: 24px;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }

  .menu-combo-item-detail {
    border-bottom-left-radius: 0.6em;
    border-bottom-right-radius: 0.6em;
  }

  .menu-combo-item-detail-header .curved-shadow-overlay-hack,
  .menu-combo-item-detail-header {
    border-top-left-radius: 0.6em;
    border-top-right-radius: 0.6em;
  }

  .menu-combo-item-detail-header .curved-shadow-overlay-hack {
    background-color: #fff;
  }

  .menu-combo-item-detail-header .header-primary {
    color: #2a2a2a;
  }

  .menu-combo-item-detail-header .header-primary svg {
    color: #2a2a2a;
    fill: #2a2a2a;
  }

  .menu-combo-item-detail {
    padding-bottom: 0;
  }

  .menu-combo-item-detail > .item-detail__content.isMinimized.isCollapsed {
    top: 0;
  }

  .menu-combo-item-detail > .item-detail__content.isMaximized {
    top: 0;
  }

  .menu-item_main-container.menu-combo-item_main-container {
    position: relative;
    display: unset;
    background-color: #fff;
  }

  .menu-combo-detail.menu-item-detail-wizard {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .menu-item_main-container.menu-combo-item_main-container.menu-combo-item-details_main-container {
    display: flex;
    position: fixed;
  }

  .order-item__option-header.combo-subgroup-header {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .order-item__option-header.combo-subgroup-header > .heading-with-icon.combo {
    justify-content: space-between;
  }

  .menu-combo-item_main-container.menu-combo-item-details_main-container {
    background-color: #0000007a;
  }

  .menu-combo-item-detail .FAB-wrapper__combo-add-to-choice {
    border-bottom-left-radius: 0.6em;
    border-bottom-right-radius: 0.6em;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .menu-combo-detail .menu-combo-item-detail::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .menu-combo-detail .menu-combo-item-detail {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .desktop-order-menu__combo-item-check-mark {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
  }

  .desktop-order-menu__combo-item-check-mark > .icon--check {
    stroke: #fff;
    stroke-width: 3px;
    width: 18px;
    height: 18px;
    padding: 3px;
    background-color: #4caf52;
    border-radius: 100%;
  }

  .quantity-combo-details {
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    max-width: unset;
  }
  .menu-combo-summary {
    border-top: none;
  }

  .FAB--combo-confirm-choices-wrapper {
    bottom: 0;
  }

  .menu-combo-name {
    display: flex;
    flex-direction: column;
  }

  .menu-combo-name > p:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .menu-combo-summary-wrapper {
    width: 100%;
  }
  .menu-combo-item-details_main-container {
    z-index: 1005;
  }
}
