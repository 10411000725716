.address-validation__confirm-address {
  margin-top: 0px;
  margin-bottom: 2em;
  font-size: 0.8em;
  font-weight: normal;
}

.address-info-card__container {
  margin-bottom: 1em;
}

.dialog-modal__message .address-info-card__container {
  margin-top: 24px;
  margin-bottom: 24px;
}

.address-validation__street-address {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 0.9rem;
  width: fit-content;
}

.address-validation__city-province-postal {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 0.9rem;
  text-align: left;
}

.address-validation__apt-suite-unit-container {
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: normal;
  display: flex;
}

.address-validation__apt-suite-unit-header {
  font-size: 0.9rem;
  font-weight: normal;
}

.address-validation__apt-suite-unit {
  font-size: 0.9rem;
  margin-left: 5px;
  font-weight: normal !important;
}

.dialog-modal__message.confirm-address__unverified-address {
  margin: 0;
  padding-bottom: 32px;
}

.dialog-modal__message.confirm-address__unverified-address + .dialog-modal__buttons {
  margin-top: 0;
}

.address-validation__recommended-address-header-container {
  margin-bottom: 1em;
  margin-top: 0px;
  font-size: 1rem;
  border-bottom: 1px solid #d4d4d4;
  font-weight: normal;
}

.address-validation__unverified-address-header-container {
  border: none;
}

.address-validation__entered-address-container {
  padding-bottom: 24px;
  font-size: 0.9rem;
  border-bottom: 1px solid #d4d4d4;
}

.address-validation__recommended-address-container {
  padding: 24px 0px;
  font-size: 0.9rem;
}

.address-validation__entered-recommended-address-header {
  margin: 0px;
  font-size: 1rem;
}

.delivery-address__addresses_container {
  padding: 12px 24px;
  border-bottom: 1px solid var(--color-gray-light);
}

.delivery-address__addresses_container.isDefault {
  background-color: var(--color-gray-light);
}

.delivery-address__buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 12px 0px 0px;
}

.delivery-address__action-button {
  font-size: 0.9em;
  display: flex;
  align-items: center;
  color: #2a2a2a;
}

.delivery-address__action-button.isDefault {
  color: #2a2a2a;
}

.delivery-address__action-button .icon {
  color: #2a2a2a;
}

.delivery-address__action-button.isDefault .icon {
  color: #2a2a2a;
}

.delivery-address__remove-modify-buttons {
  display: contents;
}

.delivery-address__address-conatiner {
  display: flex;
  justify-content: space-between;
}

.delivery-address__selected-address-button {
  display: flex;
  align-items: center;
}

.delivery-address-container__overlay-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.delivery-address-container__overlay-button:disabled {
  background: transparent;
  color: unset;
}

@media screen and (min-width: 660px) {
  section > .delivery-address__addresses_container:first-child {
    margin-top: 10px;
  }
  .delivery-address__addresses_container {
    border: 2px solid var(--color-gray-light);
    border-radius: 8px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
  }

  .delivery-address__address-conatiner {
    padding-bottom: 10px;
  }

  .delivery-address__addresses_container .delivery-address__buttons-container {
    justify-content: flex-start;
  }

  .delivery-address__addresses_container .delivery-address__buttons-container button {
    margin-right: 2em;
  }

  .address-validation__entered-address-container,
  .address-validation__recommended-address-container {
    padding-right: 24px;
    padding-left: 24px;
  }

  .delivery-address__selected-address-button {
    position: absolute;
    right: 1.1em;
    top: 0;
    bottom: 0;
  }

  .address-validation__apt-suite-unit-container {
    margin-top: 0;
  }
  p.address-validation__recommended-address-header-container {
    font-size: 1.5rem;
    font-weight: normal;
    padding-left: 0;
  }

  .confirm-address__unverified-address + .dialog-modal__buttons {
    margin-top: 1.35em;
    width: 100%;
  }

  .confirm-address__unverified-address + .dialog-modal__buttons > button {
    padding-left: 1.5em;
    padding-right: 1.5em;
    max-width: unset;
    width: unset;
  }
}
