.cart-indicator__wrapper {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 13px;
  margin-bottom: 15px;
}

.cart-indicator {
  position: relative;
  /*width: 34px;
  height: 34px;
  transition: 0.25s transform, 0.25s opacity;
  margin-right: 10px;*/
}

.cart-indicator--empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
}

.cart-indicator>.icon--cart {
  width: 37px;
  /*width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  bottom: 0;*/
}

.cart-indicator--empty>.icon--cart {
  position: relative;
  left: auto;
  bottom: auto;
  margin-top: -2px;
}

.cart-indicator>.gift-indicator__counter {
  left: initial;
}

.gift-indicator__counter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  left: 4px !important;
  min-width: 23px;
  min-height: 23px;
  color: var(--color-button-text);
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  background-color: var(--color-online-order-settings-active-color);
  border-radius: 50%;
  color: var(--color-button-text);
  max-width: 23px;
}

.cart-indicator--blink {
  transform: scale(1.35);
  opacity: 0.35;
}

.giftCardCartIndicator {
  border-radius: 100%;
  background: var(--color-online-order-settings-background);
  padding: 0.75em;
  display: flex;
  align-items: center;
  width: 62px;
  height: 62px;
  justify-content: center;
  position: fixed;
  right: 25px;
  bottom: 80px;
  z-index: 1;
  box-shadow: 0 .5px 3px .5px rgb(0 0 0/15%)
}

.giftCardCartIndicatorCheckout {
  width: 40px;
  height: auto;
}

.giftCardCartIndicatorCheckout svg {
  width: 30px !important;
  height: 30px !important;
  margin-right: 0.25em;
}

.giftCardCartIndicatorCheckout .gift-indicator__counter {
  min-width: 17.5px;
  min-height: 17.5px;
  font-size: .75em;
  border-radius: 100%;
  line-height: normal;
  left: -7px !important;
  top: 0px;
}

.giftCardCartShoppingBagIndicator svg {
  height: 38px !important;
}

.giftCardCartShoppingBagIndicator .gift-indicator__counter {
  left: -4px !important;
}

/** Dektop Layout **/
@media screen and (min-width: 660px) {
  .cart-indicator__wrapper {
    display: none;
  }
}

/** End Of Desktop Layout **/