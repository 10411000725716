.dashboard__header {
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  margin-bottom: 1em;
}

.dashboard__greeting-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 28px;
  justify-content: space-between;
  width: 100%;
}

.dashboard__greeting-wrapper.not-loggedin {
  justify-content: flex-start;
}

.dashboard__greeting-wrapper.not-loggedin a {
  justify-content: flex-start;
  margin-left: 10px;
}

.dashboard__greeting-wrapper > .icon {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  color: #2a2a2a;
  margin-top: 14px;
}

.dashboard__greeting {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
  color: #2a2a2a;
  max-width: 80%;
}

.dashboard-header__content-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-header__language-dropdown-toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-header__language-dropdown-toggle-button > svg {
  width: 18px;
  height: 18px;
  color: var(--color-header-text-and-icon-colour);
}

.dashboard-header__language-dropdown {
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  max-width: 150px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
  transform-origin: top left;
  transform: scale(0);
  animation: revealLanguageDropdown 0.2s forwards;
}

.dashboard__rewards-module {
  width: 100%;
}
.user-greetings__rewards-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 12px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0 3.5px 3px 0.5px #00000026;
}

.user-greetings__rewards-container-loader {
  position: relative;
}

.dashboard__header-container {
  margin-bottom: 10px;
  padding: 0;
  padding-right: 20px;
}

.dashboard__greeting-loyalty-button {
  border-radius: 20px;
  padding: 3px 5px;
  position: relative;
}

.dashboard__greeting-loyalty-button.collapsed {
  background-color: #fff;
}

.dashboard-greeting__rewards-button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

@keyframes revealLanguageDropdown {
  to {
    transform: scale(1);
  }
}

/* Mobile Only **/
@media screen and (max-width: 660px) {
  .user-greetings__rewards-container {
    box-shadow: 0px 0.5px 3px 0.5px #00000026;
  }
}

@media screen and (min-width: 660px) {
  .dashboard__header {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 15px;
  }
  .dashboard__greeting-wrapper > .icon {
    width: 25px;
    height: 25px;
  }

  .dashboard__greeting {
    font-size: 1.3rem;
    max-width: 100%;
  }

  .user-greetings__rewards-container {
    border-top: 1px solid #eeeeee;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .expanded-rewards-details {
    border-bottom-right-radius: 0 !important;
    box-shadow: 0px 0.5px 3px 0.5px #00000026;
  }

  .expanded-rewards-details.dashboard-action-button {
    border-bottom-left-radius: 0 !important;
  }
  .expanded-rewards-details .dashboard-action-button__icon-container {
    border-bottom-left-radius: 0 !important;
  }
}
