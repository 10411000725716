/* AppHeader.css */
.header {
  padding: 0;
  background-color: #fff;
  z-index: 500;
  min-height: 54px;
  align-items: center;
  justify-content: space-between;
}

header > .curved-shadow-overlay-hack {
  background-color: var(--color-header-background-colour);
}

.header-primary {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  z-index: 200;
  width: 100%;
  height: 100%;
  color: var(--color-header-text-and-icon-colour);
  min-height: 54px;
}

.header-primary > *:first-child:not(.page-title) {
  position: absolute !important;
  left: 20px;
  top: 0;
  bottom: 0;
}

.header-primary > .headerAccountButton {
  position: absolute;
  right: 60px;
  top: 0;
  bottom: 0;
}

.header-primary > *:last-child {
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  left: unset;
  margin: auto;
}

.header-primary > .page-title + #item-details-page__fav-button {
  position: absolute;
  right: 55px;
  top: 0;
  bottom: 0;
  left: unset;
  margin: auto;
}

.header-primary svg {
  color: var(--color-header-text-and-icon-colour);
}

.header__link--back {
  position: relative;
  top: 0px;
  bottom: 0px;
  /*left: 14px;*/
}

.header__link--home {
  position: relative;

  /*left: 14px;*/
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  top: 0;
  width: 44px;
  height: 44px;
  right: 0;
}

.page-title + .header__link--home {
  left: auto;
}

.header__link--back > .icon {
  width: 20px;
  height: 20px;
}

.header__link--home > .icon {
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  fill: var(--color-header-text-and-icon-colour);
}

.page-title {
  min-height: 18px;
  max-width: calc(100% - 44px - 44px);
  margin: 0 auto;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
}
.mobile-app-header-logo_wrapper.page-title  {
  position: relative !important;
}
.button--cart {
  position: absolute;
  top: 13px;
  right: 20px;
}

/** App header order summary widget styling */
.app-header__order-summary-container {
  width: 100%;
  height: 200%;
  max-height: 200%;
  background-color: rgba(152, 152, 152, 0.7);
  position: fixed;
  z-index: 450;
  top: -20px;
  visibility: visible;
}

.app-header__order-summary-container.is-sliding-down > .orderDetailsSummary__container {
  opacity: 0;
  transform: translateY(100%);
  animation: slidedownorderSummary 0.5s forwards;
}

.app-header__order-summary-container.is-hidden {
  display: none;
}

.app-header_map-pin-button-highlighted > .icon--map-pin {
  fill: var(--color-primary);
}

.app-header_map-pin-button-highlighted > .icon--map-pin > circle {
  fill: white;
}

.app-header__order-summary-container__overlay-button {
  width: 100%;
  height: 100%;
}

.mobile-app-header-logo_wrapper {
  width: 35%;
  display: flex;
  justify-content: center;
}

.mobile-app-header-logo {
  max-height: 50px;
}

.header-primary-with-logo {
  padding-top: 0;
  padding-bottom: 0;
  height: unset;
}

.header.header-primary-with-logo {
  padding: 0;
}

.header-primary-with-logo > .header__link--back {
  position: unset;
  width: unset;
  height: unset;
}

@keyframes slidedownorderSummary {
  to {
    transform: translateY(98px);
    opacity: 1;
  }
}
/*.headerAccountButton {
  margin-left: 0.75rem;
}*/

.activeHeaderNavLink svg {
  color: var(--color-secondary) !important;
}
/* end of: AppHeader.css */
