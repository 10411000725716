.dashboard-pay-bill__module-body-wrapper {
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 20px;
  background-color: #fff;
}

.dashboard-pay-bill__module-column {
  padding: 0 0 12px 0;
  margin-top: 10px;
}

.dashboard-pay-bill__module-column-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.dashboard-pay-bill__module-image {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}

.dashboard-pay-bill__module-column > p {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}

.dashboard-pay-bill__module-column-button-container > button {
  padding: 12px 0px 12px 0px;
  width: 50%;
  white-space: normal;
}

.dashboard__module.dashboard-pay-bill__module {
  padding-right: 20px;
}
