.pay-bill__main-container {
  padding-left: 0;
  padding-right: 0;
}

.bill-items-group-container {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.bill__header-subtotal {
  font-size: 1.1em;
  font-weight: 600;
}

.bill__header-order-number {
  font-size: 14px;
}

.bill__header-details {
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 25px;
}

.bill__header-details > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.bill-payment__main-container > .bill__items_container {
  padding-bottom: 0;
}

.bill__items_container {
  padding-bottom: 5em;
}

.cartItem__wrapper > .billItem__checkbox {
  width: 10% !important;
}
.cartItem__wrapper > .billItem__infoWrapper {
  width: 90%;
  margin-top: 0;
  margin-left: 0px;
}

.collapsable-section__with-checkbox {
  position: relative;
  display: flex;
  align-items: center;
}

.billItemGroup__checkbox-wrapper {
  position: absolute;
  right: 19px;
}

.collapsable-section__label {
  padding-left: 30px;
}

.cart-summary__cart-column > .individual-login-button-container {
  display: none;
}

.cart-summary__container {
  align-items: baseline;
}

.billItem__checkbox > .icon--check,
.billItemGroup__checkbox-wrapper > .icon--check {
  width: 18px;
  height: 18px;
  padding: 3px;
  stroke: #fff;
  stroke-width: 3px;
  background-color: #4caf52;
  border-radius: 50%;
}

.billItemGroup__checkbox-wrapper > .icon--check {
  margin-right: 7px;
}

.bill__items_blurb {
  padding-left: 24px;
  padding-right: 24px;
}

.bill__last-update-timestamp__container {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bill__last-update-timestamp {
  font-size: 14px;
}

.bill__last-update-timestamp__container > button {
  display: flex;
  align-items: center;
  color: var(--color-secondary);
  font-weight: 600;
  margin-left: 10px;
}

.bill__last-update-timestamp__container > button > .icon--refresh {
  width: 13px;
  height: 13px;
  margin-right: 3px;
  color: var(--color-secondary);
  stroke-width: 3px;
}

.cartItem__wrapper.billItem__wrapper {
  align-items: flex-start;
  padding: 20px 25px 13px 25px;
  justify-content: flex-start;
}

#pay-one-bill:disabled {
  color: unset;
}

#pay-one-bill > .icon--minus {
  color: var(--color-button-text);
}
