.favorite-items-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: -0.75em;
  padding-left: 0;
  list-style-type: none;
}

.favorite-item {
  width: 100%;
  margin: 0.75em;
  padding: 1.3em;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #dadada;
  display: flex;
  flex-direction: column;
  position: relative;
}

.favorite-item .loading-spinner {
  z-index: 0;
}

@media screen and (min-width: 575px) {
  .favorite-item {
    width: calc(100% / 2 - 1.5em);
  }
}

@media screen and (min-width: 975px) {
  .favorite-item {
    width: calc(100% / 3 - 1.5em);
  }
}

.favorite-item-card__image-and-text-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2em;
}

.favorite-item__calorie-container {
  margin-bottom: 1.3em;
  font-size: 12px;
  text-align: center;
  margin-top: 1em;
}

.favorite-item-card__image-wrapper {
  height: 0 !important;
  padding-top: 10vh !important;
  min-width: 100px !important;
  max-width: 100px !important;
  margin-right: 1em;
  position: relative !important;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.favorite-item__image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100px;
  height: 100px !important;
  border-radius: inherit;
}

.favorite-item__text-wrapper {
  width: 100%;
}

.favorite-item__price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 0.85rem;
}

.favorite-item__price {
  margin-bottom: 5px;
  font-size: 0.8rem;
  font-weight: 600;
}

.favorite-item__modifiers-total {
  color: var(--color-secondary);
  font-size: 0.75rem;
}

.favorite-item__name {
  margin: 0 0 5px;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
}

.favorite-item__options-list {
  width: 100%;
  overflow: hidden;
}

.favorite-item__options-list .options-list__row {
  font-size: 0.75rem;
  color: #676464;
}

.options-list__row + .options-list__row {
  margin-top: 4px;
}

.favorite-item__buttons-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px;
  margin-top: 20px;
  background-image: linear-gradient(to top, #fff 0%, #fff 38%, rgba(255, 255, 255, 0) 100%);
}

.favorite-item__button__gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 127px;
  background-image: linear-gradient(to top, #fff 10%, #fff 50%, rgba(255, 255, 255, 0) 100%);
  transition: back;
  pointer-events: none;
}
.favorite-item__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.favorite-item__buttons-container > button {
  flex: 1;
  min-width: 0;
}

.favorite-item__nested-options {
  margin-left: 20px;
}

.favorite-item__item-details-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.favorite-item__item-details-inner-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.favorite-item__nested-options-list {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 660px) {
  .favorite-item-card__image-and-text-wrapper {
    max-height: 220px;
    min-height: 220px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
    padding-bottom: 5em;
  }

  .favorite-item-card__image-and-text-wrapper::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 660px) {
  .favorite-item__button__gradient {
    display: none;
  }

  .favorite-item-card__image-and-text-wrapper {
    padding-bottom: 1em;
  }

  .favorite-item-card__image-wrapper {
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
  }
}
