/* Cart.css */
.cart-content {
  background-color: var(--color-gray-light);
  /*padding-left: 5%;
  padding-right: 5%;*/
}

/* Cart Items */
.cartItem__container {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.cartItem__container + .link-wrapper--add-items {
  margin-top: 24px;
}

.cart-items-desktop .additional-disclaimer {
  padding-left: 24px;
  padding-right: 24px;
}

.item-detail__options-container .additional-disclaimer {
  padding-left: 0;
  padding-right: 0;
}

.cartItem {
  margin: 0 auto 4px;
  border-radius: 4px;
  text-align: left;
  position: relative;
  background-color: #fff;
}

.cartItem:last-child {
  margin-bottom: 0;
}

.cartItem__discounts-wrapper {
  width: 100%;
  margin-top: 10px;
}

.cartItem__discount-line {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.cartItem__discount-value,
.cartItem__discount-label {
  color: var(--color-secondary);
}

.cartItem__discount-value {
  display: flex;
  align-items: baseline;
}

.cartItem__discount-value > span:first-child {
  display: flex;
  align-items: center;
}

.cartItem__discount-value-points:after {
  content: "|";
  margin: 0 3px;
}

.cartItem__discount-value > span > .icon--star {
  width: 20px;
  height: 20px;
  fill: #f49c00;
  stroke: none;
}

.cartItem__discount-label {
  display: flex;
  align-items: center;
}

.cartItem__discount-label > .icon--x-in-circle {
  width: 18px;
  height: 18px;
  color: var(--color-secondary);
  margin-right: 5px;
}

/* Cart item transition animations */
.cartItem--animate-remove {
  transition: opacity 0.75s, transform 1s, max-height 1s, margin 1s;
  transform: scale(0);
  opacity: 0;
  max-height: 0;
  margin-bottom: 0;
}

.cartItem--animate-add {
  animation: 0.75s addItem forwards;
}

@keyframes addItem {
  from {
    transform: scale(0);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0;
  }

  to {
    transform: initial;
    opacity: 1;
    max-height: 600px;
    margin-bottom: 25px;
  }
}

.cartItem__wrapper {
  padding: 20px 30px 13px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.cartItem__infoWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.cart-item__name-price-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
}

.cartItem__heading {
  width: 70%;
  margin: 0 5% 0 0;
  font-size: 14px;
  font-weight: 600;
}

.orderLevelSuggestedItemsPriceWrapper .cartItem__heading{
  font-size: 0.9rem !important;
}
.orderLevelSuggestedItemsPriceWrapper .cartItem_keyDetails{
  font-size: 0.75rem !important;
}
.cartItem__price {
  display: block;
  width: 25%;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
}

.cartItem__modifiers {
  width: 100%;
  padding: 0;
  list-style: none;
  font-size: 13px;
  color: #676464;
  transition: max-height 0.35s;
}

.cart-item__option {
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
}

.cart-item__option-name {
  max-width: 205px;
  margin-right: 5%;
  margin-top: 0;
  margin-bottom: 0;
}

.cart-item__option-name > strong {
  margin-right: 5px;
}

.cart-combo-child-item__name,
.cart-combo-child-item__price {
  color: #2a2a2a;
  font-weight: 500;
}

.cart-combo-child-item__option-name::before {
  content: "- ";
  color: #ffffff00;
}

.cart-combo-child-item__nested-option-name::before {
  content: "---- ";
  color: #ffffff00;
}

.cart-combo-child-item__option-name {
  font-size: 12px;
}

.cart-item__special-instructions {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 19px;
  margin-top: 4px;
  font-size: 12px;
  font-style: italic;
  width: 100%;
  transition: max-height 0.35s;
}

.cart-item__special-instructions--expanded {
  max-height: 500px;
  white-space: unset;
  word-break: break-word;
}

.cartItem__modifier {
  display: inline;
}

.cartItem__image {
  max-width: 100px;
  margin: 0 5% 0 0;
  object-fit: cover;
}

.cartItem__button--delete,
.cartItem__button--add,
.cartItem__button--edit {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.cartItem__button-text {
  line-height: 14px;
}

.cartItem__icon {
  margin-right: 3px;
}

.cartItem__icon--delete {
  width: 18px;
  height: 18px;
  fill: #2d2d2d;
}

.cartItem__button--add .icon--plus {
  width: 14px;
  height: 14px;
  margin-right: 3px;
  color: #2d2d2d;
}

.cartItem__icon--edit {
  width: 14px;
  height: 14px;
  color: #2d2d2d;
}

/* Cart Item Buttons */
.cartItem__buttons-container {
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  display: flex;
  margin-top: 10px;
  border-top: 1px solid #dfdfdf;
  padding-top: 10px;
  position: relative;
}

/* Cart Footer */
.cart-footer {
  background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 100%);
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.icon__circle-wrapper {
  background-color: rgba(255, 255, 255, 0.4);
  width: 35px;
  height: 35px;
  border-radius: 100%;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.icon--arrow-full-right {
  width: 18px;
  height: 18px;
  stroke: #2a2a2a;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.link-wrapper--add-items {
  text-align: center;
}

.link--add-items {
  display: inline-flex;
  align-items: center;
}

.link--add-items > .icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.empty-cart-message,
.cart__1307-error {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  margin: 0 0 30px;
  font-size: 18px;
  text-align: center;
}

.customer-info__form-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  width: 100%;
  height: auto;
}

.customer-info__form-heading {
  margin: 0 0 35px;
  font-size: 22px;
  font-weight: normal;
  line-height: 1.2;
}

.cart-button__remove-all-items {
  display: flex;
  align-items: center;
  border: solid 1px;
  padding: 10px 15px;
  font-size: 14px;
}

.cart-button__remove-all-items-icon {
  transform: rotate(45deg);
  height: 16px;
  width: 16px;
  margin-right: 4px;
}

.cart-content__buttons {
  display: flex;
  align-items: center;
  margin-top: 30px;
  flex-wrap: wrap;
  padding: 20px 30px 13px;
}

.cart-content__buttons-single-button {
  justify-content: center;
}

.cart-content__buttons-double-buttons {
  justify-content: center;
}

.cart-content__buttons-double-buttons > button {
  flex: 1;
  flex-grow: 1;
  width: auto;
  min-width: 165px;
  min-height: 44px;
  max-width: 250px;
  margin: 5px;
  padding: 0.5em 1em;
  white-space: normal;
}

.cart-content__buttons-double-buttons > .button--primary-small:first-child {
  margin-right: 5px;
}

.customer-info__form-wrapper .form__field-label {
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 0.9rem;
  font-weight: 600;
  text-align: left;
  position: initial;
}

.customer-info__form-wrapper .form__field {
  border-radius: 0;
  width: 100%;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #cdcdcd;
}

.customer-info__modal {
  background-color: white;
  z-index: 1005;
}

.order-type-special-instructions__field {
  border: 1px solid #cdcdcd;
  width: 100%;
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 14px;
  color: gray;
  margin-top: 20px;
}

.order-type-special-instructions__additional-information {
  font-size: 12px;
  color: #5d5d5d;
  margin: 0;
  text-align: left;
}

.cartItem__wrapper > div:first-child {
  width: 100%;
}

/** cart item quantity buttons **/
/** quantity buttons **/
.cartItem__quantity-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cartItem__quantity-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartItem__quantity-button:active > .icon {
  background-color: #2a2a2a;
  color: white;
}

.cartItem__quantity-button:disabled {
  background-color: transparent;
}

.cartItem__quantity-button:disabled > .icon {
  opacity: 0.5;
}

.cartItem__quantity-button > .icon {
  width: 2em;
  height: 2em;
  padding: 5px;
  stroke-width: 1.5px;
  background-color: white;
  border-radius: 50%;
  border: solid 1.5px #2a2a2a;
  color: #2a2a2a;
}

.cartItem__quantity-input {
  background-color: transparent;
  border: none;
  border-bottom: solid 2px var(--color-gray-light);
  border-radius: 0;
  text-align: center;
  padding-bottom: 5px;
  width: 40%;
  margin-bottom: 15px;
  margin-right: 5px;
  margin-left: 5px;
}

/** end of quantity buttons **/

.cart-content__desktop-min-delivery-amount-message {
  width: 100%;
  position: fixed;
  color: #ff2020;
  display: flex;
  justify-content: center;
  bottom: 2em;
  padding: 20px 30px 13px;
  text-align: center;
  z-index: 1100;
}
.deliverySpendAmountErrorMessage.cart-content__desktop-min-delivery-amount-message {
 position: unset !important;
 background-color: white;
 z-index: unset;
}
.cart-content__desktop-min-delivery-amount-message > p {
  margin: 0;
}

/** Mobile layout only **/
@media screen and (max-width: 660px) {
  .special-instructions-wrapper.order-level__special-instructions {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 2em;
  }

  .cart-content
    + .order-settings-component__main-container.visually-hidden
    + .order-settings-component__time-selection-main-container
    > .order-settings-component__time-selection-inner-wrapper {
    bottom: 0;
  }

  .cart-content > .cart-items-desktop.collapsable-section__collapsed-content {
    padding-bottom: 0;
  }

  .checkout-content > .cart-items-desktop {
    padding-bottom: 0;
  }

  .checkout-content > .cart-content-desktop {
    margin-bottom: 20em;
  }
}

.cart-content__desktop-checkout-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  position: fixed;
  bottom: 0;

  background: rgb(255, 255, 255);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(50%, rgba(255, 255, 255, 1)),
    to(rgba(255, 255, 255, 0))
  );
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
}

.cart-content__mobile-checkout-buttons-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.cart-content__mobile-checkout-buttons-wrapper > button:first-child {
  margin-top: 10px;
}

.cart-content__mobile-checkout-buttons-wrapper > button {
  margin-bottom: 10px;
}
.cart-content__mobile-checkout-buttons-wrapper > button:last-child {
  margin-bottom: 20px;
}

.desktop-cart__link-and-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.cart-content__mobile-checkout-buttons-wrapper > .FAB__error {
  bottom: 105px;
}

.desktop-cart__link-and-button-wrapper.single-button {
  margin-bottom: 20px;
}

.checkout-content {
  padding-bottom: 0;
}

.cart-items-desktop.collapsable-section__expanded-content {
  overflow: visible;
}

.cartItem__quantity-span {
  font-size: 12px;
  margin-right: 10px;
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .cartItem__quantity-span {
    font-size: 0.9em;
    margin-right: 10px;
  }
  .customer-info__form-wrapper {
    width: 70%;
  }
  .customer-info__form-heading {
    text-align: center;
    font-size: 24px;
  }

  .cart-checkout__desktop-container {
    display: flex;
    justify-content: space-between;
    padding-top: 1em;
    background-color: #f9f9f9;
  }

  .cart-checkout__desktop-container > div {
    width: 49%;
  }

  .cart-checkout__desktop-container .cart-summary-tip-desktop {
    width: 100%;
  }

  .cart-checkout__desktop-container .cart-items-desktop {
    width: 100%;
  }

  .cart-checkout__desktop-container .cart-content-desktop {
    margin-top: 0;
  }
  .cart-checkout__desktop-container {
    background-color: #f9f9f9;
    padding-right: 24px;
    padding-left: 24px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .cart__tip-amounts,
  .cart__tip-custom-amount-container {
    padding-left: 0;
    padding-right: 0;
  }
  .cart__tip-amounts > .cart__tip-button:first-child {
    padding-left: 10px;
  }
  /* .cart__tip-amounts > .cart__tip-button:last-child {
    padding-right: 0;
  } */

  .cart__tip-container {
    padding-top: 0;
    padding-bottom: 2em;
  }

  .cart-checkout__desktop-container .cart-content-desktop {
    padding-right: 0;
  }

  .cart-content {
    background-color: #f9f9f9;
  }

  .cart-checkout__cart-items-collapsable__cart_indicator {
    display: flex;
    align-items: center;
  }

  .cart-checkout__cart-items-collapsable__cart_indicator > span {
    font-weight: 500;
    font-size: 14px;
  }

  .cart-checkout__cart-items-collapsable__cart_indicator > .cart-indicator > .icon--cart {
    width: 30px;
  }

  .cart-items-desktop-dinein {
    padding-right: 24px;
    padding-left: 24px;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
  }

  .confirmation-email__main-wrapper + .cart-items-desktop-dinein {
    margin-top: 0;
  }

  .cart-items-desktop-dinein .cart-items-desktop {
    width: unset;
  }

  .cart-items-desktop-dinein .cartItem__container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  .cart-items-desktop-dinein .cartItem__container > .cartItem {
    flex: 50%;
    max-width: 49%;
  }

  .cart-items-desktop-dinein .cartItem__container > .cartItem:nth-child(odd) {
    margin: 0 5px 5px 0;
  }
  .cart-items-desktop-dinein .cartItem__container > .cartItem:nth-child(even) {
    margin: 0 0 5px 0;
  }

  .cartItem__container {
    padding-bottom: 5em;
  }

  .cart-content__desktop-checkout-buttons
    > .desktop-cart__link-and-button-wrapper
    > button:first-child {
    margin-right: 10px;
  }

  .cart-items-desktop-dinein .cart-items-collapsable__content-buttons-container {
    padding: 0;
    max-width: 55%;
  }
}

@media screen and (min-width: 850px) {
  .cart-content__desktop-min-delivery-amount-message {
    bottom: 6em;
  }

  .cartItem {
    margin: 0 auto 5px;
  }

  .cartItem__wrapper {
    padding: 20px 20px 13px;
  }

  .cart-content-desktop {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    max-width: 1200px;
    /*margin: 2em auto 0;*/
  }

  .cart-summary-tip-desktop {
    position: sticky;
    position: -webkit-sticky;
    right: 5%;
    top: 1em;
    width: 47%;
  }

  .cart-items-desktop {
    width: 50%;
  }

  .cart-items-desktop-empty {
    margin: 0 auto;
  }

  .cart-content__desktop-checkout-buttons {
    padding-bottom: 2em;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(255, 255, 255);
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(50%, rgba(255, 255, 255, 1)),
      to(rgba(255, 255, 255, 0))
    );
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    padding-top: 3em;
  }

  .desktop-cart__link-and-button-wrapper {
    display: flex;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }

  .desktop-cart__link-and-button-wrapper > a {
    margin-right: 1em;
  }

  .desktop-cart__link-and-button-wrapper > button:hover,
  .desktop-cart__link-and-button-wrapper > a:hover {
    opacity: 0.7;
  }

  .cart-content {
    padding-bottom: 10em;
  }

  .main-content {
    overflow: unset;
  }

  .cartItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cartItem__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cartItem__wrapper > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .cartItem__image-wrapper {
    margin-right: 1em;
    width: 30%;
  }

  .cartItem__image {
    width: 100px;
    height: 100px;
    margin: 0;
    border-radius: 4px;
  }

  .cartItem__heading,
  .cartItem__price,
  .cart-item__option-name {
    font-size: 0.9em;
  }

  .cartItem__heading {
    width: 50%;
  }

  .cartItem__price {
    width: 40%;
  }

  .cartItem__button-text {
    font-size: 1.2em;
  }

  .cartItem__icon--delete,
  .cartItem__button--add .icon--plus,
  .cartItem__icon--edit {
    width: 1.5em;
    height: 1.5em;
  }

  .cartItem__discount-line {
    font-size: 1em;
  }

  .cart-content__buttons-double-buttons > button {
    flex: 1 calc(100% / 2 - 10px);
  }

  .desktop-cart__link-and-button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .desktop-cart__link-and-button-wrapper > button {
    max-width: 30%;
  }
}

@media screen and (min-width: 1280px) {
  .cart-items-desktop {
    margin: 0;
  }

  .cart-items-desktop-empty {
    margin: 0 auto;
  }
}

/** End Of Desktop Layout **/
