.dashboard-horizontal-slider__card-featured {
  --card-ratio: 205 / 400;
  position: relative;
  width: 40%;
  height: 0;
  overflow: hidden;
  border-radius: 9px;
  box-shadow: 0 0.5px 3px 0.5px rgb(0 0 0 / 15%);
  padding-top: 260px !important;
  background-color: #fff;
}

.dashboard__featured-item-img-wrapper {
  position: relative;
  width: 100%;
  height: 0;

  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  padding-top: calc(475 / 190 * 40%);
}

.dashboard__featured-item-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboard__featured-item-text-wrapper {
  white-space: normal;
  padding: 8px 8px 11px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.dashboard__featured-item-text-wrapper > span:first-child {
  font-size: 15px;
  font-weight: 500;
}

.dashboard__featured-item-name {
  margin: 0 0 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.dashboard__featured-item-calory {
  font-size: 12px;
}

.dashboard__featured-item-description {
  position: relative;
  min-height: 50px;
  max-height: 3.9em;
  margin: 0;
  font-size: 0.65em;
  line-height: 1.3em;
  overflow: hidden;
}

.dashboard__featured-item-description:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.3em;
  margin-top: 0.2em;
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.83) 50%);
}

.dashboard__featured-module {
  font-size: 16px;
}

.menu__category-card-featured.withQuickAddButton {
  border-bottom-right-radius: 1em;
}

@media screen and (min-width: 660px) {
  .dashboard-horizontal-slider__card-featured {
    width: calc(100% / 3 - 13em - 60px) !important;
    padding-top: 20% !important;
  }

  .dashboard__featured-item-price {
    font-size: 15px;
  }

  .dashboard__featured-carousel > .styles-module_item-provider__YgMwz {
    position: relative;
    overflow: hidden;
    padding: 0px 0 0px 0px;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }

  .menu__category-card-featured {
    position: relative;
    margin: 0 0.5em;
    border-radius: 0.5em;
    z-index: 0;
    height: 100%;
  }

  .menu__category-card-featured::marker {
    display: none !important;
    content: none !important;
  }

  .dashboard__featured-module.carousel {
    margin-bottom: 2em;
  }

  .dashboard__featured-carousel .dashboard__horizontal-slider-button {
    top: 0;
    bottom: 0;
    height: 100%;
  }

  .styles-module_item-tracker__3bypy
    > .styles-module_item-container__a8zaY:first-child
    > .menu__category-card-featured {
    margin-left: 0;
  }
  .featuredSliderIndicator{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 18px;
  }
}

@media screen and (min-width: 1920px) {
  .dashboard-horizontal-slider__card-featured {
    width: calc(100% / 3 - 13em - 60px) !important;
    padding-top: 20% !important;
  }
}
