.how-rewards-work-main-content {
  padding: 24px 24px 105px;
}

.how-rewards-work__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.how-rewards-work__section:nth-child(even) {
  flex-direction: row-reverse;
}

.how-rewards-work__section + .how-rewards-work__section {
  margin-top: 36px;
}

.how-rewards-work-section__icon-wrapper {
  min-width: 20%;
  width: 20%;
  margin-right: 5%;
}

.how-rewards-work-section__icon-wrapper > svg,
.how-rewards-work-section__icon-wrapper > img {
  max-width: 100%;
  height: auto;
}

.how-rewards-work-section__text-wrapper {
  flex-grow: 1;
}

.how-rewards-work__section p {
  margin: 0;
  max-width: 75ch;
}

.how-rewards-work__section p + * {
  margin-top: 4px;
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .how-rewards-work__section + .how-rewards-work__section {
    margin-top: 1.5em;
  }

  .how-rewards-work-main-content {
    padding: 2em 0 4em;
  }
}

@media screen and (min-width: 1025px) {
  .how-rewards-work-section__icon-wrapper {
    min-width: 5%;
    width: 5%;
  }
}
@media screen and (min-width: 660px) and (max-width: 1024px) {
  .how-rewards-work-section__icon-wrapper {
    min-width: 10%;
    width: 10%;
  }
}
/** End of Dektop Layout **/
