/* PromoCouponForm.css */
.promo-form__wrapper {
  width: 100%;
  max-width: 400px;
  padding: 50px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
}

.promo-disclaimer {
  font-size: 13px;
  margin: 40px 10px 0;
}

.icon--piggy-bank {
  width: 36px;
  height: 36px;
  fill: #6f6f6f;
  flex-shrink: 0;
}

#form--promo__submit-button,
#form--coupon__submit-button {
  margin-bottom: 30px;
}

#form--coupon {
  margin-top: 5em;
}

#apply-discount-coupon__button{
  margin-top: 4em;
}

#form--promo .button--submit-form-wrapper , #form--coupon .button--submit-form-wrapper{
  display: flex;
  justify-content: center;
}

.applied-promo__wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0 15px;
  position: relative;
}

.applied-promo__output {
  color: #2a2a2a;
  font-size: 14px;
  line-height: 100%;
}

.button--remove-promo {
  border-radius: 100%;
  display: inline-block;
  margin-right: 5px;
}

.button--remove-promo .icon--x-in-circle {
  /*display: block;
  width: 24px;
  height: 24px;
  padding: 4px;*/
  width: 18px;
  height: 18px;
  color: #2a2a2a;
}

.applied-promo__wrapper .in-place-dialog__container {
  /*width: 140%;
  left: -10%;*/
}

.promo-form__discount-output {
  margin-bottom: 15px;
  margin-top: 10px;
  color: #058000;
}
