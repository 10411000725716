.goBackSuggestedItemButton{
  display: flex;
  align-items: center;
 
}
.goBackSuggestedItemButtonContainer{
  background-color: #fff;
  width: 100%;
}
.goBackSuggestedItemButton > svg {
  margin-right: 0.25em;
  margin-top: 0.5px;
}
.suggested-item-details__desktop-close-button-container{
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 78px;
}
.suggested-item-details__closeAndFavoritesButtonContainer{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-top: 0;
  padding-bottom: 14px;
}
@media screen and (max-width: 660px) {
  .goBackSuggestedItemButtonContainer{
    margin: -8px 1em 1.5em 0;
    margin-left: 0px;
  }
  .goBackSuggestedItemButtonMobile{
    min-height: 30px;
    position: fixed;
    margin-top: 0;
    z-index: 9999999;
    padding: 1em 1em;
    padding-top: 1.38em;
    padding-left: 1.37em;
  }
  .paddingForBackToMenuItemButton{
    margin-top: 40px;
  }
  .suggestedItemDetail-main__Wrapper .item-detail__content.isMinimized.isCollapsed{
    max-height: 235px !important;
  }
}
@media screen and (min-width: 661px) {
  .suggestedItemDetail-main__Wrapper .item-detail__content.isMinimized {
    top: 78px !important;
  }
}