@media screen and (min-width: 660px) {
  .terms-and-conditions-main-content {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1280px) {
  .terms-and-conditions-main-content {
    margin-left: 10%;
    margin-right: 10%;
  }
}
