.giftCard_slide {
  height: 100%;
  width: 100%;
  overflow: hidden;
  object-fit: contain !important;
  transform: scale(1);
}

.giftCardSlideImage {

  height: 180px;
  object-fit: contain;
  margin-left: 1em;
}

.giftCard-button {
  transform: none !important;
  transition: none !important;
  position: relative !important;
  width: 100%;
  height: 100%;
}

.giftCard-button>img {
  position: relative !important;
}

.giftCard_card-ratio {
  --card-ratio: 240/400
}

.giftCard_list-wrapper {
  position: unset !important;
  box-shadow: none !important;
  margin-left: 0.5rem !important;
}

.giftCard_list-wrapper:first-child {
  margin-left: 0 !important;
}

.invertMargin {
  margin-left: 0 !important;
  margin-right: 0.9rem !important;
}

.carouselGiftCardSelector {
  padding-top: calc(var(--card-ratio)*(100% / 3 - 1em - 38px)) !important;
  --card-ratio: 220/400
}

.giftCardItem_display {
  width: calc(100% / 3 - 1em - 131.2px) !important;
  box-shadow: none !important;
  min-width: 12rem !important;
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.giftCardItem_display p.gcCardTitle{
  margin-top: 0.3rem;
  position: relative;
  bottom: 0 !important;
}

.selectedGiftCard {
  border: 2px solid var(--color-primary);
  -webkit-transform: scale(0.96);
  -moz-transform: scale(0.96);
  -o-transform: scale(0.96);
  transform: scale(0.96);
  padding: 2px 5px 0 5px;
  transition: 0.25s transform !important;
}


.giftCardSliderWrapper {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

/* NEW CUSTOM CAROUSEL CSS */


.slick-track {
  display: flex !important;
}

::marker {
  display: none !important;
}

div#compactCarouselWrapper> ul {
  list-style: none;
}

div#compactCarouselWrapper> ol {
  list-style: none;
}

#compactCarouselWrapper {
  margin: 0;
}

.custom-arrow-buttons {
  width: 45px;
  opacity: 1;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.6), #fff);
  z-index: 1 !important;
  justify-content: center;
  position: absolute;
  display: flex;
  height: 100%;
  opacity: 1;
  align-items: center;
  pointer-events: all;
  top: -2px;
}

.compact-arrows.custom-arrow-buttons {
  width: 25px;
  opacity: 1;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.6), #fff);
  z-index: 1 !important;
  justify-content: center;
  position: absolute;
  display: flex;
  height: 100%;
  align-items: center;
  pointer-events: all;
}

.carousel-right-arrow {
  right: -5px;
  padding: 8px;
}

.carousel-left-arrow {
  left: -5px;
  padding: 8px;
}

.switchCardCarousel {
  width: 200px !important;
  height: 150px !important;
}

.compactGiftCardSliderWrapper {
  padding-bottom: inherit !important;
}

.compactCarouselArrows {
  height: 100% !important;
}

.compact-carousel-slide-item {
  height: 5.5rem !important;
  width: 8rem !important;
  list-style: none !important;
  margin-right: 0.25rem;

}

.regular-carousel-slide-item {
  width: 98%;
  height: 98%;
  height: 12.8rem;
  width: 15rem !important;
  list-style: none !important;
  margin-right: 0.5em;
  margin-bottom: 0.69em;
  margin-right: 0.9rem;
  padding-bottom: 1em;
}

.giftCardInvalidImageSlide {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.giftCardInvalidImageSlide>p {
  border-bottom: 1.5px solid #8D8D8D;
  border-top: 1.5px solid #8D8D8D;
  padding: 0.55em 0;
  width: 80%;
  font-size: 81%;
  color: #3B3B3B;
  font-weight: bold;
  text-align: center;
  max-width: 170px;
  white-space: pre-wrap;
}

.giftCardInvalidImageSlide.giftCardInvalidImageSlideCompact>p {
  border-bottom: 1.5px solid #8D8D8D;
  border-top: 1.5px solid #8D8D8D;
  padding: 0.55em 0;
  width: 100px;
  font-size: 0.5em;
  color: #3B3B3B;
  font-weight: bold;
}

div#compactCarouselWrapper .styles-module_item-container__a8zaY {
  width: max-content !important;
  margin-right: 0.25rem;
}
p.gcCardTitle{
  margin-top: 0.3rem;
  margin-bottom: 0;
  font-size: 1rem;
  width: fit-content;
  position: relative;
  margin-left: 0.25rem;
  width: 100%;
  text-align: left;
}


ul.giftCardItems{
  align-items: center;
}
.gcImageContainer{
  width: 98%;
  height: 88%;
  overflow: hidden;
}

.disableArrows{
  display: none !important;
}

@media screen and (max-width: 700px) {
  p.gcCardTitle{
    margin-top: 0.3rem;
    font-size: 1rem;
    text-align: left;
    margin-left: 1rem;
    width: 100%;
  }
  .giftCardItem_display.regular-carousel-slide-item p.gcCardTitle{
    margin-bottom: 0rem !important;
    margin-left: 2rem;
  }
  .giftCardItem_display.regular-carousel-slide-item{
    width: 22rem !important;
    height: 16rem !important;
    margin-right: 0 !important;
  }
  div#compactCarouselWrapper .styles-module_item-container__a8zaY {
    width: unset !important;
    margin-right: 0;
  }

  .giftCard_list-wrapper {
    width: 100% !important;
    height: auto;
    margin-bottom: 1.5em;
    margin-left: 0 !important;
  }

  .giftCardItems {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
    padding-left: 1em !important;
  }

  .regular-carousel-slide-item .giftCardItem_display {
    margin-right: 0 !important;
    align-self: center;
    margin-bottom:  1em !important;
  }
  .giftCardItem_display p.gcCardTitle{
    font-size: 0.85rem !important;
  }
  .carousel_giftCardListWrapper {
    width: 85% !important;
    height: 16rem !important;
    padding-top: 0 !important;
    overflow: hidden;
  }

  .giftCardInvalidImageSlide>p {
    border-bottom: 1.5px solid #8D8D8D;
    border-top: 1.5px solid #8D8D8D;
    padding: 0.55em 0;
    width: 80%;
    font-size: 81%;
    color: #3B3B3B;
    font-weight: bold;
  }

  .regular-carousel-slide-item {
    list-style: none !important;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .giftCardItem_display.regular-carousel-slide-item{
    padding-bottom: 1rem !important;
  }
  .giftCardSliderWrapper {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .giftCardMobileSlider:first-child{
    margin-left: 0 !important;
    padding-left: 20px;
  }
}