/* MenuSlider.css */
.menu-slider-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 1em 0;
  background-color: #fff;
  z-index: 10;
  animation: growMenuSlider 0.35s forwards;
}

@keyframes growMenuSlider {
  from {
    transform: scale(0.1);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

.menu-slider-container--closing {
  animation: closeMenuSlider 0.35s forwards;
}

@keyframes closeMenuSlider {
  from {
    transform: none;
    opacity: 1;
  }
  to {
    transform: scale(0.1);
    opacity: 0;
  }
}

.menu-slider-container .additional-disclaimer {
  margin-top: 0;
  padding-left: 24px;
  padding-right: 24px;
}

/* Food Menu Items Slider Header */
.menu-slider__header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.menu-slider__heading {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
}

.menu-slider__link-back {
  position: absolute;
  left: 30px;
  top: 3px;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}
/* END OF Food Menu Items Slider Header */

.menu-slider {
  position: relative;
  display: flex;
  margin: 0 0 0 -3%;
  padding: 20px 0;
  list-style-type: none;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.menu-slider::-webkit-scrollbar {
  display: none;
}

body.body--iOS .menu-slider {
  /* enables iOS "momentum" style scrolling */
  -webkit-overflow-scrolling: touch;
}

@supports (-webkit-touch-callout: none) {
  .menu-slider {
    height: 100%;
  }
}

.menu-slider__item-spacing {
  width: 1px;
  height: 1px;
  margin-left: 7.5%;
  margin-bottom: 1.5em;
}

.menu-slider-container .calories-disclaimer {
  padding-left: 24px;
  padding-right: 24px;
}

@media screen and (max-width: 350px) {
  .header-secondary__dropdowns {
    max-width: 100%;
  }
}
