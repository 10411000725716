.combo-menu-item-summary > span::before {
  content: "- ";
}

.combo-nested-modifier-summary {
  padding-left: 10px;
}
.combo-nested-modifier-summary > li > span::before {
  content: "---- ";
  color: #ffffff00;
}

.combo-modifier-summary,
.combo-addon-summary {
  color: var(--color-gray-text);
  font-size: 12px;
  color: #676464;
}
.combo-addon-summary-price {
  float: right;
  font-size: 13px;
  color: var(--color-gray-text);
}
.combo-addon-summary-price::before {
  content: "+" !important;
  color: var(--color-gray-text) !important;
}

.menu-combo-summary-details {
  padding: 0;
  margin-bottom: 0;
}

.menu-combo-summary-details li p {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 1.6;
  margin: 0;
  align-items: center;
}

.menu-combo-summary-details__list-item {
  display: flex;
  border: 1px solid var(--color-gray-light);
  flex-direction: column;
  border-radius: 8px;
}

.combo-summary__edit-button-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: var(--color-gray-light);
  padding: 0.5em 1em;
  border-radius: 7px 7px 0 0;
}

.menu-combo-summary-details__list-item > .combo-summary__item-detail-container:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-light);
}

.combo-summary__item-detail-container {
  padding: 1em;
}

.menu-combo-summary-details > .menu-combo-summary-details__list-item:not(:last-child) {
  margin-bottom: 10px;
}

.menu-combo-summary-details__list-item-group-id {
  font-weight: 500;
  font-size: 13px;
}

.menu-combo-summary-details__list-item-edit-button {
  color: var(--color-secondary);
  font-size: 13px;
  display: flex;
  align-items: center;
}

.menu-combo-summary-details__list-item-edit-button > .icon--edit {
  color: var(--color-secondary);
}

.menu-combo-summary-details__list-item-name {
  font-weight: 500;
  color: #2a2a2a;
  font-size: 15px;
}

.menu-combo-summary-details__list-item-name > span {
  margin-right: 10px;
}

.menu-combo-summary > p.menu-combo-summary__total-price-disclaimer {
  margin-left: 0;
  margin-top: 1.5em;
}

li > p.menu-combo-summary__total-price-disclaimer {
  font-size: 14px;
  text-align: left;
  color: #969494;
  font-weight: 600;
}

.menu-combo-summary-wrapper {
  padding-bottom: 100px;
}

.menu-combo-summary-details__list-item__addonModifiers {
  list-style-type: none;
  padding: 0;
}

.menu-combo-summary-details__list-item__addonModifiers > li {
  border: none;
  text-align: left;
}

.menu-combo-summary-details__list-item__addonModifiers ul {
  list-style-type: none;
}

.menu-combo-summary-details__list-item__addonModifiers span > span {
  margin-right: 10px;
}
