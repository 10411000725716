.addon-quantity-buttons__main-wrapper {
  display: flex;
  padding: 0 5px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  /* margin-top: 15px; */
}

.addon-quantity-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--color-primary);
}

.addon-quantity-button:disabled {
  opacity: 0.3;
  border: 1px solid var(--color-primary);
}

.addon-quantity-button > .icon {
  color: var(--color-button-text);
}

.addon-quantity-button:disabled > .icon {
  color: var(--color-primary);
}

.addon-quantity-button:active {
  background-color: var(--color-button-text);
}

.addon-quantity-button:active > .icon {
  color: var(--color-primary);
}

.addon-quantity-input {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--color-gray-light);
  border-radius: 0;
  text-align: center;
  font-size: 1.2rem;
  padding-bottom: 2px;
  width: 45%;
  margin-bottom: 20px;
}
