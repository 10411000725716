.dashboard__module-header {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.dashboard__module-header > .icon {
  width: 1.125em;
  height: 1.125em;
}

.dashboard__module-heading {
  margin: 0 0 0 0.3em;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 100%;
}

.dashboard__module-header-button,
.dashboard__module-header-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

@media screen and (min-width: 660px) {
  .dashboard__module-heading {
    margin-left: 0;
  }
}
