.confirmation-email__container {
  display: flex;
  flex-direction: column;

  background-color: #fff;
}

.confirmation-email__body-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
}

.confirmation-email__header-wrapper {
  background-color: var(--color-gray-light);
}
.confirmation-email__header-wrapper > h3 {
  margin: 0.9em 30px;
  font-size: 16px;
}

#form--confirmation-email > .form-submit__wrapper {
  display: none;
}

#form--confirmation-email .form__field-label {
  font-size: 0.9rem;
}

/** Desktop Design **/
@media screen and (min-width: 660px) {
  .confirmation-email__main-wrapper {
    padding-left: 24px;
    padding-right: 24px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 1em;
  }

  .confirmation-email__body-wrapper input {
    max-width: 50%;
  }

  .additional-disclaimer + .confirmation-email__main-wrapper {
    margin-top: 0;
  }

  .confirmation-email__main-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 1em;
  }

  .confirmation-email__main-wrapper {
    padding: 0;
    margin-top: 0;
  }

  .cart-checkout__desktop-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}
