.gift-card__image-text-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.gift-card__image-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 83px;
  margin-right: 9.095%;
  border: 1px solid #efefef;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.gift-card__image {
  display: block;
}

.gift-card__balance-label {
  display: block;
  margin-bottom: 4px;
  font-size: 0.7rem;
  font-weight: 400;
  text-transform: uppercase;
}

.gift-card__balance {
  font-weight: 700;
}

.gift-card__registered-label {
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
}

.gift-card__select-wrapper {
  margin-top: 12px;
}

.gift-card__select-wrapper > select {
  height: 44px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 38px; /* accounts for the icon */
  line-height: 44px;
}

.gift-card__select-wrapper > .icon--arrow-down {
  right: 12px;
  width: 24px;
  height: 24px;
}

.gift-card__apply-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.gift-card__apply-button {
  position: relative;
  margin: 0;
}
