/* App Footer Subnav Item styles */
.footer__subnav-link {
  display: flex;
  width: 100%;
  padding-top: 14px;
}

.footer__subnav-link > svg,
.footer__subnav-link > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.footer__subnav-link > .icon--heart {
  stroke-width: 2;
  stroke: #2a2a2a;
}

.footer__subnav-link-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding-bottom: 14px;
  font-size: 15px;
  border-bottom: 1px solid var(--color-footer-navigation-item-border);
  color: var(--color-footer-text-and-icon-colour);
}

.footer__subnav-link-text > svg {
  fill: var(--color-footer-text-and-icon-colour);
}

.footer__subnav-item svg {
  stroke: var(--color-footer-text-and-icon-colour);
}

.footer__subnav-item svg.icon--order-status {
  stroke-width: 2px;
  width: 23px;
  height: 23px;
}

.footer__subnav-item svg.icon--utensils,
.footer__subnav-item svg.icon--transaction-history {
  fill: var(--color-footer-text-and-icon-colour);
}

.footer__subnav-link > .icon--script {
  stroke: none;
  fill: var(--color-footer-text-and-icon-colour);
}

.footer__subnav-item:last-child .footer__subnav-link-text {
  border-bottom: none;
}

.footer__subnav-link svg:last-child {
  width: 10px;
  height: 10px;
}

.footer__subnav-link .icon--gift-card {
  transform: rotate(15deg);
}

.footer-subnav__app-version {
  margin-top: 15px !important;
  font-size: 12px;
}
/* END OF App Footer Subnav Item styles */
