.hero-section-mobile {
  height: 0;
  overflow: hidden;
  padding-top: 50%;
  top: 61px;
  position: sticky;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0px 0px 11px 1px rgb(0 0 0 / 15%);
}

@media screen and (min-width: 660px) {
  .hero-section {
    height: 0;
    overflow: hidden;
    padding-top: 12%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: inset 0px 0px 11px 1px rgb(0 0 0 / 15%);
  }

  .hero-section.dashboard-hero {
    padding-top: 20%;
  }
}
