.orderDetailsSummary__container {
  position: relative;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid var(--color-gray-light);
}

.orderDetailsSummary__module-header {
  position: relative;
  margin-bottom: 10px;
}

.orderDetailsSummary__module-heading {
  margin: 0;
  padding-left: 26px;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 100%;
}

.orderDetailsSummary__module-header > .icon {
  position: absolute;
  bottom: 0;
  width: 17px;
  height: 17px;
}

.orderDetailsSummary__order-time-details {
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 500;
}

.orderDetailsSummary__container > .orderDetailsSummary__location-details:first-child {
  border-bottom: 1px solid var(--color-gray-light);
}

.orderDetailsSummary__location-details {
  position: relative;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.orderDetailsSummary__location-details-columns {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.orderDetailsSummary__location-details-columns .icon--edit {
  margin-left: 10px;
}

.orderDetailsSummary__location-details .icon--edit {
  width: 25px;
  height: 25px;
  color: var(--color-secondary);
}

.orderDetailsSummary__location-details > p,
.orderDetailsSummary__location-details-columns > p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.orderDetailsSummary__location-details > p > span,
.orderDetailsSummary__location-details-columns > p > span,
.orderDetailsSummary__user-delivery-address > div > span:first-child {
  font-weight: 600;
}
.orderDetailsSummary__location-details > p > span::after,
.orderDetailsSummary__user-delivery-address > div > span:first-child::after {
  content: " ";
  white-space: pre;
}

.orderDetailsSummary__user-delivery-address {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 0.5em;
}

.orderDetailsSummary__user-delivery-address > div {
  width: 90%;
}

.orderDetailsSummary__user-delivery-address > a {
  width: 10%;
  text-align: right;
}

/** Dektop Layout **/
@media screen and (min-width: 660px) {
  .orderDetailsSummary__container > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .orderDetailsSummary__container
    > div
    > .orderDetailsSummary__location-details
    > .orderDetailsSummary__location-details-time-and-type {
    align-items: center;
    align-content: center;
  }

  .orderDetailsSummary__container > div > .orderDetailsSummary__location-details {
    border: none !important;
    width: 47%;
  }

  .orderDetailsSummary__container > div > .orderDetailsSummary__location-details:last-child {
    justify-content: flex-end;
  }

  .orderDetailsSummary__location-details {
    justify-content: unset;
  }

  .orderDetailsSummary__location-details
    > .orderDetailsSummary__location-details-time-and-type
    > span
    > img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }

  .orderDetailsSummary__container > div > .orderDetailsSummary__location-details:first-child > p {
    font-size: 1.3em;
  }

  .orderDetailsSummary__location-details .icon--edit {
    margin-left: 20px;
  }

  .orderDetailsSummary__location-details-time-and-type {
    font-size: 1.3em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .orderDetailsSummary__location-details-time-and-type > span {
    font-weight: 600;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .orderDetailsSummary__location-details-time-and-type > span:first-child {
    margin-right: 5em;
  }
}
/** End Of Desktop Layout **/
