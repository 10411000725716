.add-credit-card-main-content {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #fff;
}

.add-credit-card-form__security-message-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.add-credit-card-form__security-message-wrapper > svg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.add-credit-card-form__security-message {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}

/* TODO: fix the common button components, then remove this override */
#form--add-credit-card__submit-button {
  display: block;
  margin: 0 auto;
}
