.order-types__buttons-container {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50%;
}

.order-types__button-wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 50%;
  align-items: center;
  margin: 0 auto 1.5em;
  font-size: 1.3em;
  width: 175px;
  justify-content: space-between;
}
.order-types__icon-wrapper {
  padding: 1em;
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.order-types__button {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.order-types__button > span {
  margin-left: 10px;
}

.order-types__button:active > .order-types__icon-wrapper {
  background-color: var(--color-active-button-generic-background);
}

.order-types__buttons-container > .dashboard__header {
  padding-left: 0;
  padding-top: 0;
}
.order-types__buttons-container > .dashboard__header > h1 {
  font-size: 1.4em;
  margin-top: 0;
  font-weight: 500;
}

/** mobile only layout **/
@media screen and (max-width: 660px) {
  .order-types__button-wrapper {
    width: unset;
  }
  .order-types__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .order-types__button > span {
    margin-left: 0;
    margin-top: 10px;
  }

  .order-types__main-container .dashboard__header-container {
    padding: 0.7em 24px;
    margin-bottom: 1em;
    margin-top: 10px;
  }
}

/** Desktop layout **/
@media screen and (min-width: 660px) {
  .order-types__buttons-container {
    align-items: center;
    margin-top: 2em;
    padding: 2em 1em;
    width: 35%;
    border: 1px solid var(--color-gray-light);
    border-radius: 10px;
    background-color: #fff;
  }

  .order-types__main-container {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .order-types__icon-wrapper {
    width: 120px;
    height: 120px;
  }
}

@media only screen and (max-width: 850px) and (min-width: 660px) {
  .order-types__buttons-container {
    width: 65vw;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 880px) {
  .order-types__buttons-container {
    width: 50vw;
  }
}
