.login-register__main-wrapper {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.login-or-register-buttons {
  bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*padding: 1em 0;*/
  width: 100%;
}

.button--circle-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid var(--color-primary);
  border-radius: 100px;
  width: 100%;
}

.button--circle-wrapper + .button--circle-wrapper {
  margin-top: 30px;
}

.button--circle-wrapper {
  opacity: 0;
  animation: slideCircleButtonIn 0.6s forwards;
  animation-delay: 3.2s;
}

.button--circle-wrapper:first-child {
  transform: translateX(100%);
}

.button--circle-wrapper:nth-child(2) {
  transform: translateX(-100%);
}

.button--circle-fade-out {
  animation: fadeOut 0.3s forwards;
}

.button--circle-move-up {
  animation: moveLoginCircleButtonUp 0.3s forwards;
  animation-delay: 0.3s;
}

.user-is-greeted .button--circle-wrapper {
  opacity: 1;
  transform: none;
}

.button--circle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 50%;
  padding: 1em 0;
}

.button--circle {
  /*box-shadow: 0px 9px 14px 2px rgba(0, 0, 0, 0.21);*/
}

.button-circle--pressed-in {
  animation: pressInCircleButton 0.3s forwards;
}

.button--circle__instruction,
.label--circle__instruction {
  width: 250px;
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  white-space: pre-wrap; /* for \n line-breaks to work in props */
}

.button--circle__instruction {
  opacity: 0;
  transform: translateY(15px);
  animation: slideUpCircleButtonLabel 1s forwards;
}

.button--circle-wrapper:first-child .button--circle__instruction {
  animation-delay: 3.7s; /* after button slide-in */
}

.button--circle-wrapper:nth-child(2) .button--circle__instruction {
  animation-delay: 4s; /* after button slide-in + delay after first label slide-up */
}

.user-is-greeted .button--circle__instruction {
  opacity: 1;
  transform: none;
}

.label--circle-wrapper {
  position: absolute;
  top: 59%;
  left: 50%;
  transform: translate(-50%, -59%);
  height: 360px;
  background-color: #fff;
}

.password__label--circle {
  color: #586320;
  font-size: 2.5rem;
  letter-spacing: 2.5px;
}

.label--circle__secondary-instruction {
  margin: 18px 0 0;
  font-weight: 400;
  text-align: center;
}

.label--circle > .icon {
  transform: scale(0);
  animation: scaleUpCircleLabelIcon 0.3s forwards;
}

.login-or-register__register > a > span {
  margin-left: 5px;
  color: var(--color-secondary);
  font-size: 1.1rem;
  font-weight: 500;
}

.login-register__bottom-link.link--continue-as-guest {
  color: var(--color-secondary);
  font-size: 1.1rem;
  font-weight: 500;
}

@keyframes slideCircleButtonIn {
  to {
    opacity: 1;
    transform: initial;
  }
}

@keyframes moveLoginCircleButtonUp {
  to {
    /* moves it in place of the Registration Circle Button */
    /* -circle button container - margin in-between 2 buttons */
    transform: translateY(calc(-165px - 30px));
  }
}

@keyframes slideUpCircleButtonLabel {
  to {
    opacity: 1;
    transform: initial;
  }
}

@keyframes pressInCircleButton {
  to {
    box-shadow: none;
  }
}

@keyframes scaleUpCircleLabelIcon {
  100% {
    transform: scale(1);
  }
}

@media screen and (max-height: 388px) {
  .label--circle-wrapper {
    visibility: hidden;
  }
}

@media screen and (max-width: 660px) {
  .login-register__main-wrapper {
    overflow-y: scroll;
  }
}
