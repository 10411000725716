.recent-order-content {
  padding: 0 0 85px;
  background: linear-gradient(180deg, #eee, #eee 80%, #fff);
  position: relative;
}

.recent-order {
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 0;
  padding-top: 1.2em;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid rgba(140, 140, 140, 0.25);
  border-radius: 5px;
}

.recent-order__current-order {
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 1.2em;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid rgba(140, 140, 140, 0.25);
  border-radius: 5px;
}

.recent-order__back-button {
  display: flex;
  width: 98%;
  max-width: 1200px;
  margin: 1.2em auto 0;
  gap: 2%;
}

.recent-order__back-button-label {
  margin-top: 0.15em;
}

.recent-order + .recent-order {
  margin-top: 0.2em;
}

.recent-order__section-header {
  max-width: 660px;
  line-height: normal;
  margin-top: 0px;
  font-size: 0.8rem;
}

.recent-order__main {
  padding: 0 24px;
  margin-bottom: 0px;
}

.recent-order__header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: #757575;
  font-size: 0.8rem;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  margin-left: 24px;
  margin-right: 24px;
}

.recent-order__order-details-store-name {
  font-size: larger;
  font-weight: bold;
  margin-bottom: 10px;
}

.recent-orders__order-status-button {
  width: 40%;
  font-size: 12px;
}

.recent-order__active-order-checkmark {
  font-size: 16px;
  list-style-type: none;
  margin-bottom: 0.5em;
  padding: 0.25em 0 0 2.3em;
  position: relative;
}

.recent-order__active-order-checkmark::before {
  content: " ";
  display: flex;
  border: solid 0.8em var(--color-secondary);
  border-radius: 0.8em;
  height: 0;
  width: 0;
  position: absolute;
  left: 0.3em;
  top: 40%;
  margin-top: -0.5em;
  box-sizing: content-box !important;
}

.recent-order__active-order-checkmark::after {
  content: " ";
  display: flex;
  width: 0.3em;
  height: 0.6em;
  border: solid white;
  border-width: 0 0.2em 0.2em 0;
  position: absolute;
  left: 0.8em;
  top: 40%;
  margin-top: -0.2em;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  box-sizing: content-box !important;
}

.recent-order__items-list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.recent-order__item {
  position: relative;
  padding-bottom: 0.5em;
}

.recent-order__item:before {
  position: absolute;
  top: 0;
  left: 0;
  color: #2a2a2a;
}

.recent-order__item-name-price {
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 4px;
}

.recent-order__order-units {
  font-size: 14px;
  font-weight: 600;
  color: #757575;
  width: 10%;
}

.recent-order__item-name {
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  width: 70%;
}

.recent-order__item-price {
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  width: 20%;
}

.recent-order__options {
  display: flex;
  color: #757575;
  font-size: 13px;
  font-weight: 400;
  margin: 5px 0;
  flex-direction: column;
}

.recent-order__option {
  display: inline-block;
}

.recent-order__options-heading {
  display: inline-block;
  margin: 0 4px 0 0;
  font-size: 13px;
  font-weight: 400;
}

.recent-order__options-list {
  display: flex;
  width: 90%;
  padding-left: 0;
  margin-left: 10%;
  list-style-type: none;
  flex-direction: column;
}

.recent-order__option-item-name-price {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.recent-order__option-item-name {
  margin: 0px;
  text-align: left;
  width: 80%;
}

.recent-order__option-item-price {
  margin: 0px;
  text-align: right;
  width: 20%;
}

.recent-order__options-nested-list {
  list-style-type: none;
  margin-left: -24px;
}

.recent-order__loading {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-top: 50%;
}

.recent-order__loading > .loading-spinner {
  position: relative !important;
}

.recent-order__loading-text {
  display: block;
  font-size: 15px;
  position: relative;
  top: 54%;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 10px;
  text-align: center;
}

.recent-order__footer {
  display: flex;
  align-self: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 1em;
  margin-right: 24px;
  margin-left: 24px;
  min-height: 60px;
  align-items: center;
  width: calc(100% - 48px);
}

.recent-order__footer-active-orders {
  display: block;
  align-self: center;
  justify-content: center;
  margin-right: 24px;
  margin-left: 24px;
  align-items: center;
  width: calc(100% - 48px);
}

.recent-order__footer-past-orders {
  display: flex;
  align-self: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 1.2em;
  min-height: 60px;
  align-items: center;
  width: calc(100% - 48px);
}
.show-only-order-details {
  justify-content: flex-end;
}
.recent-order__footer-completed-order-details {
  margin-left: 24px;
  margin-right: 24px;
}

.recent-order__footer-current-order {
  display: flex;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.2em;
}

.recent-order__footer-completed-current-order {
  display: flex;
  margin-bottom: 0px;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
  margin-top: 0px;
}

.recent-order__footer-reorder-button {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  position: fixed;
  bottom: 57px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.23573179271708689) 100%
  );
  display: flex;
}

.recent-order__reorder-button-desktop {
  align-self: flex-end;
  padding-top: 15px;
  padding-bottom: 1em;
  min-height: 60px;
  width: 200px;
}

.recent-order__footer-points {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.recent-order__footer-star-icon {
  margin: 0;
  padding: 0;
  color: #f49c00;
}

.recent-order__order-type {
  font-weight: 600;
}

.recent-order__order-from {
  font-weight: 600;
}

.recent-order__tip-total-active-orders {
  border-top: 2px solid #000000;
  display: block;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.recent-order__total-active-orders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.recent-order__tip-discount-active-orders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: normal;
  margin-top: 0px;
  padding-top: 1.2em;
}

.recent-order__tip-total-completed-orders {
  border-top: 2px solid #000000;
  display: block;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.recent-order__total-completed-orders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.recent-order__tip-discount-completed-orders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: normal;
}

.recent-order__border-bottom {
  border-bottom: 2px solid #e8e8e8;
  padding-bottom: 1.2em;
}

.recent-order__border-bottom-completed-order {
  border-bottom: 2px solid #e8e8e8;
}

.recent-order__total-past-orders {
  font-weight: 600;
  display: inline-flex;
  margin-top: 10px;
  margin-bottom: 0px;
}

.recent-order__total > div {
  display: flex;
  align-items: center;
}

.recent-order__reorder-button {
  width: 45%;
  font-size: 12px;
}

.recent-order__reorder-button > span {
  padding: 5px 0;
}

.recent-order__reorder-button--alert {
  color: #d34146;
  text-decoration: none;
  border-color: #d34146;
}

.recent-order__reorder-button > .icon {
  margin-right: 4px;
  width: 16px;
  height: 16px;
  color: var(--color-secondary);
}

.recent-order__reorder-button--alert > .icon {
  color: #d34146;
}

.recent-order__reorder-button--filled {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: none;
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-button-text);
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 600;
  min-height: 40px;
  white-space: normal;
  word-wrap: break-word;
}

.recent-order__reorder-button--filled-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: none;
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-button-text);
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 600;
  height: 40px;
}

.recent-order__reorder-button-view-details {
  position: relative;
  display: block;
  width: 70%;
  height: 52px;
  margin: 0 auto;
  padding: 0 15px;
  color: var(--color-button-text);
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  background-color: var(--color-primary);
  border-radius: 9999px;
  z-index: 1;
}

.recent-order__view-menu {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: none;
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-button-text);
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
  height: 40px;
  white-space: normal;
  word-wrap: break-word;
}

.recent-orders__no-orders-yet-label {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
}

.recent-orders__browse-menu-label {
  color: #757575;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 20px;
}

.recent-order-view-details-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: none;
  border: 2px solid var(--color-primary);
  background-color: white;
  color: var(--color-secondary-button-text);
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 600;
  min-height: 40px;
  white-space: normal;
  word-wrap: break-word;
}

.recent-order-view-details-button-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: none;
  border: 2px solid var(--color-primary);
  background-color:  white;
  color: var(--color-secondary-button-text);
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 600;
  height: 40px;
}

.recent-order__expand-more {
  display: block;
  text-align: center;
  align-items: center;
  margin: 10px auto;
  justify-content: center;
}

.recent-order__notification {
  opacity: 0;
  transform: translateX(100vw);
  position: fixed;
  bottom: 57px;
  /* App Footer height */
  left: 0;
  width: 100%;
  padding: 37px 18px 30px;
  color: #2a2a2a;
  background-color: #fff;
  animation: slideUpRecentOrderNotification 0.1s forwards;
  z-index: 800;
}

.recent-order__notification--alert {
  background-color: #d34146;
}

.recent-order__notification-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 0.9rem;
}

.recent-order__notification .icon {
  width: 18px;
  height: 18px;
  color: #2a2a2a;
}

.recent-order__notification .icon--triangle-alert {
  margin-right: 4px;
}

.recent-order__close-notification {
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.recent-order__no-orders-message {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-bottom: 60px;
  padding-top: 60px;
}

.recent-order__transition {
  position: relative;
  transition: top 2s ease 0s;
  top: 0;
}

.recent-order__no-orders-message > .icon--info {
  width: 30px;
  height: 30px;
  margin-bottom: 4px;
}

@keyframes slideUpRecentOrderNotification {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.recent-order__combo-child-items {
  margin: 10px 0;
  padding-left: 10px;
  list-style-type: none;
}

.recent-order__combo-child-items > li {
  margin-bottom: 10px;
  width: 90%;
  margin-left: 10%;
}

.recent-order__combo-child-item-name {
  margin: 0 0 2px;
  font-size: 0.8rem;
  font-weight: 600;
}

.load-more-button__container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 5em;
}

/** Past Order Calander - Mobile */

.past-orders__calendar-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 16px 0 16px;
}

.rmdp-container {
  width: 100%;
  position: relative;
  margin: 15px 0;
}

.rmdp-container > input {
  width: 100%;
  height: 30px;
}

.rmdp-container > .past-orders__icon-container {
  position: absolute;
  top: 7px;
  left: calc(100% - 20px);
}

.rmdp-container > .past-orders__icon-container > .icon-calendar {
  width: 15px;
  height: 15px;
}

.past-orders__calendar-label {
  font-weight: 550;
}

.rmdp-range {
  background-color: var(--color-primary) !important;
  color: var(--color-primary-color-contrast) !important;
}

.rmdp-week-day {
  color: var(--color-gray) !important;
}

.rmdp-arrow {
  border-color: #2a2a2a !important;
}

.rmdp-arrow-container.disabled .rmdp-arrow {
  border-color: var(--color-gray-light) !important;
}

.rmdp-arrow-container:hover {
  background-color: transparent !important;
  box-shadow: none !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--color-primary) !important;
}

.past-orders__calendar-input {
  padding-left: 5px;
  border: 1px solid var(--color-gray);
  border-radius: 4px;
}
.past-orders__calendar-input:focus {
  border: 1px solid var(--color-primary);
  border-radius: 4px;
}

.past-orders__calendar-day-selected {
  background-color: var(--color-primary);
  color: var(--color-primary-color-contrast);
}

.rmdp-header > div:first-child > div.rmdp-header-values {
  margin-left: 5px;
}

.rmdp-header > div:first-child > div.rmdp-header-values > span {
  padding-left: 0px;
}

.rmdp-ym .rmdp-today:not(.rmdp-selected) > span {
  background-color: transparent !important;
  color: var(--color-secondary) !important;
}

.rmdp-header-values > span {
  font-weight: 600;
}

.rmdp-disabled > span {
  color: #909194;
}

.rmdp-left > i,
.rmdp-right > i {
  width: 8px !important;
  height: 8px !important;
}

.recent-order-content > .makeStyles-root-142 {
  position: relative;
}

.recent-orders__loader {
  position: absolute;
  bottom: 5em;
  top: unset;
}

.rmdp-ym .rmdp-day {
  margin: 1em 0px !important;
}

.rmdp-ym {
  height: 3em !important;
}

.rmdp-month-picker {
  min-height: 100% !important;
  max-height: 100% !important;
  height: 14.5em !important;
}
.rmdp-day.rmdp-disabled{
  pointer-events: none !important;
}
.rmdp-header + div {
  height: 14.5em !important;
}
.rmdp-month-picker{
  height: 14.5em !important;
}
.rmdp-wrapper.rmdp-shadow,
.rmdp-ep-shadow:after {
  box-shadow: 0 0.5px 3px 0.5px rgb(0 0 0 / 15%) !important;
}

.past-orders__calendar-container .rmdp-selected-range-week, .past-orders__calendar-container .rmdp-selected-range-week:hover{
  border-radius: 20px;
  box-shadow: 0 0 3px #8798ad;
}
.past-orders__calendar-container .rmdp-range {
  box-shadow: none !important;
}

/** ENDOF Past Order Calander - Mobile */
@media screen and (min-width: 660px) {
  .recent-order {
    border-radius: 8px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .recent-order__current-order {
    padding-left: 20px;
    padding-right: 20px;
  }

  .recent-order__loading {
    padding-top: 25%;
  }

  .recent-order__footer-completed-current-order {
    width: 50%;
  }

  .recent-order__item {
    width: 50%;
  }

  .recent-order + .recent-order {
    margin-top: 0.5em;
  }

  .recent-order__reorder-button--filled {
    font-size: 0.9em;
    height: 40px;
  }

  .recent-order__tip-total-active-orders {
    width: 50%;
  }

  .recent-order__tip-total-completed-orders {
    width: 50%;
  }

  .recent-order__footer-completed-order-details {
    margin-bottom: 0px;
  }

  .recent-order {
    position: relative;
  }

  .recent-order__main {
    padding-bottom: 2.2em;
  }

  .recent-order__footer-past-orders {
    position: absolute;
    bottom: 0;
    right: 24px;
    min-width: 40%;
    max-width: 50%;
  }

  /** Past Order Calander - Desktop */
  .past-orders__calendar-label {
    width: 100%;
    display: flex;
  }
  .rmdp-container {
    width: 40%;
    margin-bottom: 0;
  }

  .recert-order__load-more-button {
    width: unset;
    padding-left: 4em;
    padding-right: 4em;
  }

  .rmdp-week:hover {
    background-color: transparent !important;
    border-radius: 6px;
    color: var(--color-primary-color-contrast);
  }

  .rmdp-week:hover > .rmdp-day {
    background-color: var(--color-primary) !important;
    color: var(--color-primary-color-contrast) !important;
  }

  .rmdp-week:hover > .rmdp-day:first-child {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .rmdp-week:hover > .rmdp-day:last-child {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
    background-color: transparent !important;
    color: var(--color-secondary) !important;
    border-radius: 6px;
  }

  .rmdp-day.rmdp-today.rmdp-selected > span {
    background-color: var(--color-primary) !important;
    border-radius: 12px;
  }

  .recent-orders__loader {
    position: absolute;
    bottom: 10em;
    top: unset;
  }

  .rmdp-header + div {
    height: 15em !important;
  }
}

.past-orders-container__no-active-orders {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
}

/** mobile only **/
@media screen and (max-width: 660px) {
  .rmdp-day span {
    font-size: 0.7rem !important;
    box-shadow: none !important;
  }
}
