.customization-modal__container {
  display: flex;
  flex-direction: column;
}

.customization-modal__container > .dialog-modal {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  padding: 0;
  margin-top: auto;
}

.customization-modal__container .dialog-modal__buttons {
  display: none;
}

.customization-modal__container .dialog-modal__close-button {
  margin-right: 0.5em;
}

.customization-modal__header {
  -webkit-box-shadow: 0px 3px 5px 0px rgba(46, 46, 46, 0.09);
  box-shadow: 0px 3px 5px 0px rgba(46, 46, 46, 0.09);
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 0.9em;
  padding-top: 1.5em;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.customization-modal__header h2 {
  margin-top: 0.5em;
  font-size: 1.2em;
}

.customization-modal__top-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.customization-modal__top-buttons button:disabled,
.customization-modal__top-buttons a:disabled {
  font-size: 0.8em;
  font-weight: 600;
  background-color: transparent;
}

.customization-modal__top-buttons button:not(:disabled),
.customization-modal__top-buttons a:not(:disabled) {
  color: var(--color-secondary);
  font-size: 0.8em;
  font-weight: 600;
}

ul.customization-modal__items {
  padding: 0;
  margin: 0;
}

ul.customization-modal__items .cartItem__wrapper {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

ul.customization-modal__items ul {
  padding-left: 0;
}

.customization-modal__items .cartItem {
  border-bottom: 3px solid var(--color-gray-light);
  border-radius: 0;
  list-style-type: none;
}

.customization-modal__items .cartItem.slideRight {
  transform: translateX(200%);
  animation: slideModalCartItem 0.5s ease-out, collapseModalCartItem 0.5s ease-out 0.5s both;
  animation-iteration-count: 1;
}

.customization-modal__done_button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-online-order-settings-background);
  min-height: 86px;
  position: sticky;
  bottom: 0;
  width: 100%;
}

.cart-item__option.indentOneLevel {
  margin-left: 1em;
}

.cart-item__option.indentTwoLevels {
  margin-left: 0.4em;
}

@media screen and (min-width: 660px) {
  .customization-modal__container > .dialog-modal {
    max-width: 1200px;
    border: 2px solid #fff;
    margin: auto;
    max-height: 90%;
  }

  .customization-modal__content__wrapper {
    padding-bottom: 2em;
  }

  .customization-modal__header {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }

  .customization-modal__done_button_container {
    border-radius: 0 0 7px 7px;
  }

  .customization-modal__done_button_container > .button {
    padding: 0.75em 1em;
    width: unset;
    font-size: 17px;
  }

  ul.customization-modal__items {
    margin-top: 0;
  }

  ul.customization-modal__items .cartItem__wrapper {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }

  ul.customization-modal__items .cartItem__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  ul.customization-modal__items .cartItem__wrapper > div {
    width: 45%;
  }

  .customization-modal__items .cartItem__buttons-container {
    border: none;
    padding: 0;
    margin: 0;
  }

  .customization-modal__items .cartItem__quantity-buttons-container > button > svg {
    display: block;
    width: 1.5em;
    height: 1.5em;
  }

  .customization-modal__container .dialog-modal__close-button {
    margin-right: 0.75em;
    margin-top: 0.75em;
    z-index: 1;
  }

  .customization-modal__items .cartItem {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .customization-modal__header h2 {
    margin-top: 0.5em;
    font-size: 0.9em;
  }

  .customization-modal__top-buttons button:not(:disabled),
  .customization-modal__top-buttons a:not(:disabled) {
    font-size: 0.7em;
    font-weight: 600;
  }

  .customization-modal__items h3,
  .customization-modal__items h3 + .cartItem__price {
    font-size: 0.9rem;
  }
}

@keyframes slideModalCartItem {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}

@keyframes collapseModalCartItem {
  from {
    max-height: 500px;
    margin: 0 auto 10px;
  }
  to {
    max-height: 0;
    margin: 0;
  }
}
