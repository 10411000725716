.giftCardInfo {
  margin-top: 0.5rem;
  align-items: baseline;
  display: flex;
}

.giftCardInfo>p {
  color: #494949;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 590;
  line-height: normal;
  width: 7.5rem;
  padding: 0;
  margin: 0;
}

.giftCardInfo>p:first-child {
  padding-right: 4rem;
  font-weight: 600;
}

.giftCardInfo>p:last-child {
  font-weight: 400;
  width: auto;
}

.gift-card-cart-content {
  flex-direction: row !important;
}

.giftCardCartDiscountButton {
  display: flex !important;
  justify-content: space-between !important;
  padding: 0 !important;
  font-weight: bold !important;
  margin-top: 10px !important;
}

.giftCardCartDiscountButton svg {
  float: right;
}

.giftCardCartDiscountValue {
  color: rgb(205, 58, 80);
}

.giftCardDiscountLine {
  display: flex;
  align-items: center;
}

.giftCardDiscountLine span:nth-child(2) {
  white-space: nowrap;
}

.giftCardDiscountLabel {
  word-wrap: break-word;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  margin-bottom:  0.5em;
}

.giftCardDiscountLabel p {
 margin: 0;
}
p.multiDiscountLabelText{
  margin-bottom: 0.5rem !important;
}

.giftCardDiscountLabel>button {
  padding: 0;
  margin-bottom: 4px;
  margin-right: 0.5em;
}

.giftCardCartIndicatorIcon {
  display: flex;
  align-items: center;
}

.giftCardCartSummaryContainer {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  min-height: 100px;
}

.fullRowWidth {
  width: 100%;
}

.giftCardCartSummaryContainer {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

}

.gcCartDiscountSection {
  padding-top: 1.3rem;
  padding-bottom: 2.6rem;
  min-height: 100px;
  border-radius: 4px;
  margin-top: 1em;
  flex-direction: column;
  position: relative;
}

.gcCartDiscountForm .gcDiscountButton {
  font-size: 0.9rem;
  margin-top: 0 !important;
  margin-left: 0.3rem;
  padding: 0.75rem 2.25rem !important
}

.gcCartDiscountForm .form__field-wrapper {
  width: 70%;
  min-width: 205px;
}

.gcCartDiscountForm .form-submit__wrapper {
  margin-top: 0 !important;
}

.gcCartDiscountForm #form--gcPromo {
  display: flex !important;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
}

.gcCartDiscountForm .button--submit-form-wrapper {
  margin-top: 0 !important;

}

.gcCartDiscountForm .form__error {
  display: none !important;
}

.gcDiscountError {
  margin-bottom: 0;
  color: var(--color-error);
  position: absolute;
  bottom: 15px;
}

.gcCartDiscountForm .form__error {
  text-align: center;
  display: flex;
  justify-self: center;
  width: 100%;
}

.collapsedGreetingContainer {
  display: flex;
  align-items: center;
}

.expandedGreetingContainer {
  display: flex;
  align-items: unset;
  flex-direction: column;
}

.collapsedGreetingContainer p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: unset;
  overflow-wrap: anywhere;
  margin: 0;
  word-break: break-word;
}
.expandedGreetingContainer p{
  word-break: break-word;
}
.collapsedGreetingContainer p, .expandedGreetingContainer p {
  padding: 0;
  margin: 0;
  color: #494949;
  font-size: 0.9rem;
  font-style: normal;
  overflow-wrap: anywhere;
}

.collapsedGreetingContainer button, .expandedGreetingContainer button {
  padding-top: 0;
  margin-top: 0;
  align-self: flex-end;
  margin-left: 2px;
  float: right;
  font-size: 0.9rem;
}

.expandedGreetingContainer button {
  margin-top: 0.5em;
}
#form--gc-customer-info .form-submit__wrapper{
  margin-top: 60px !important;
}
.gcCartSummary{
  position: unset !important;
}
.gcCartDiscountContainer{
  position: unset !important;
}
.gcCartItemTitle{
 word-wrap: break-word;
}
.gcCodeDiscountsList{
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.gcDiscountName{
  display: flex;
  gap: 0.5rem;
}
.gcDiscountValue p{
  white-space: nowrap;
}
.gcRemovePromoButton{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 0.5rem;
}
.gcRemovePromoButton button{
  color: var(--color-secondary);
  font-size: 0.8em;
  font-weight: 600;
  
}
.gcCartDiscountForm .form-submit__wrapper{
  width: 29% !important;
}
.gcDiscountButton {
  width: 98% !important;
  margin-right: 0 !important;
}
.bonusPromoValue{
  color: unset;
}
@media screen and (max-width: 660px) {

  .gcCartDiscountForm .form__field-wrapper {
    width: 100%;
    margin-right: 0.75rem;
  }
  .gcDiscountError{
    text-align: center;
    overflow: hidden;
  }

  .gcCartDiscountSection{
    border-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .gcCartDiscountSection{
    margin-top: 3px;
  }
  .giftCardDiscountLabel{
    padding-right: 0.5rem;
  }
}