.language-dropdown__list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.language-dropdown-list-item__link {
  display: block;
  padding: 8px 12px;
}

@media screen and (max-width: 660px) {
  .language-dropdown-list__item + .language-dropdown-list__item {
    border-top: 1px solid #f2f2f2;
  }
}
