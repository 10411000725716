.collapsable-section__header-container {
  padding: 0px 30px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-light);
  width: 100%;
  background-color: var(--color-gray-light);
}

.collapsable-section__header-container > .icon--minus,
.collapsable-section__header-container > .icon--plus {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.collapsable-section__header-container>h2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 0.9em 0;
  font-size: 1rem;
}

.collapsable-section__header-container > h4 > span {
  font-size: 0.85em;
}

.collapsable-section__collapsed-content {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: max-height 0.2s ease-out;
}

.collapsable-section__expanded-content {
  /*transition: max-height 0.2s ease-out;*/
  overflow: scroll;
  /*max-height: 100%;*/
}

.collapsable-section__expanded-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.collapsable-section__expanded-content {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.collapsable-section__white-border-bottom {
  border-bottom: 2px solid #fff;
}
