.payment-methods-main-content {
  background-color: #f5f5f5;
}

.payment-method-section svg {
  width: 21px;
  height: 21px;
  margin-right: 5px;
}

.payment-method-section__header {
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
  padding: 1em 30px;
}

.payment-method-section__header-gift-card {
  margin-bottom: 0;
}

.payment-method__gc-related-buttons-container,
.payment-method__cc-related-buttons-container {
  padding: 0 30px 1em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.payment-method-section__transfer-funds {
  margin-top: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.payment-method-section__row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 40px;
}

.payment-method-section__column {
  flex-direction: column;
}

.payment-method-section__main-giftcard {
  background-color: unset;
  padding: 0;
}

.payment-method-section__row.payment-methods__stored-gc {
  background-color: white;
  margin-bottom: 5px;
  padding: 1em 30px;
  flex-direction: column;
}

.payment-method-section__details-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: baseline;
}

.payment-method-section__details-gc-buttons {
  display: flex;
  width: 100%;
  margin-top: 0.5em;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.payment-method-section__details-gc-buttons > button:first-child {
  width: 100%;
  margin: 1em 0;
}

.payment-method-section__details-wallet-link-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0.4em;
}
.payment-method-section__details-wallet-link {
  width: 100%;
  display: flex;
  justify-content: center;
}

.icon.icon--google-wallet-en,
.icon.icon--google-wallet-fr,
.icon.icon--google-wallet-pt {
  width: 100%;
  height: unset;
  margin: 0;
}

.icon.icon--apple-wallet-en,
.icon.icon--apple-wallet-fr,
.icon.icon--apple-wallet-pt {
  width: 45%;
  height: unset;
  margin: 0;
}

.payment-method-section__details-gc-buttons > button:not(:first-child) {
  display: flex;
  align-items: center;
  color: var(--color-secondary);
}

.payment-method-section__details-gc-buttons > button:last-child > .icon--x-in-circle {
  color: var(--color-secondary);
}

.payment-method-section__heading {
  margin: 0;
  font-size: 17px;
  font-weight: 600;
}

.payment-method-section__icon-and-number-wrapper {
  display: flex;
  align-items: center;
}

.payment-method-section__number,
.payment-method-section__add-new,
.payment-method-section__balance {
  font-size: 15px;
  font-weight: 400;
  color: #2a2a2a;
}

.payment-method-section__balance {
  margin-top: 0.2em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.payment-method-section__balance > span > .icon--gold-star {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  margin-left: 3px;
}

.payment-method-section__balance > span:last-child:not(:first-child) {
  margin-left: 15px;
}

.payment-method-section__icon-and-number-wrapper {
  margin-bottom: 0.5em;
}

.payment-method-section__icon-and-number-wrapper > .icon--check {
  width: 18px;
  height: 18px;
  padding: 3px;
  stroke: #fff;
  stroke-width: 3px;
  background-color: #4caf52;
  border-radius: 50%;
}

.payment-method-section__add-new {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.payment-method-section__add-new + * {
  margin-top: 15px;
}

.payment-method-section__add-new > .icon--plus-circle,
.payment-method-section__transfer-funds > .icon--plus-circle {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.payment-method__cc-related-buttons-container + hr {
  border-color: #dedede;
  border-style: solid;
}

.payment-method-section__gift-cards {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.payment-method-section__name-and-expiry-wrapper {
  padding-left: 23px;
  color: #676767;
  font-size: 12px;
}

.payment-methods__remove-button {
  font-size: 14px;
  color: var(--color-secondary);
}

.payment-methods__stored-cc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 30px;
  background-color: white;
}

.payment-methods__stored-cc + hr {
  border-color: #e8e4e4;
  border-style: solid;
}

.payment-method__gc-transfer-funds-note {
  margin-top: 0;
}

.payment-methods__stored-cc-empty {
  padding: 1em 0 0 0;
  background-color: transparent;
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .payment-method-section__number {
    margin-bottom: 0;
  }

  .payment-methods-main-content .payment-methods-container {
    padding: 0;
  }

  .payment-methods-desktop-title {
    padding-top: 0.1em;
    padding-bottom: 0.5em;
    background-color: #f7f7f7;
    justify-content: center;
    display: flex;
    flex-direction: row;
  }

  .payment-methods__stored-cc > .payment-method-section__details {
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.12);
  }

  .payment-method-section__details {
    width: 48%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .payment-methods__stored-cc > .payment-method-section__details {
    padding: 1.5em;
  }

  .payment-method-section__main {
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
  }

  .payment-method-section__icon-and-number-wrapper {
    margin-bottom: 10px;
  }

  .payment-method-section__gift-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0;
  }

  .payment-method-section__row.payment-methods__stored-gc {
    flex: 0 48%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.12);
    padding: 1.5em;
  }

  .payment-methods__stored-gc > .payment-method-section__details {
    width: 100%;
  }

  .payment-method-section__row {
    margin-top: 15px;
  }

  .payment-methods-main-content {
    padding-bottom: 10em;
    padding-right: 0;
    padding-left: 0;
    background-color: #fff;
  }

  .payment-method-section__header {
    padding-right: 0;
    padding-left: 0;
  }

  .payment-method-section__main {
    padding-right: 0;
    padding-left: 0;
  }

  .payment-method__gc-related-buttons-container {
    padding-right: 0;
    padding-left: 0;
    justify-content: start;
    align-items: flex-start;
  }

  .payment-method__gc-related-buttons-container > a {
    margin-right: 5em;
  }

  .payment-method-section__details-wrapper > .payment-method-section__details {
    flex-direction: column;
    align-items: baseline;
    width: 100%;
  }

  .payment-method-section__name-and-expiry-wrapper {
    padding-left: 0;
  }

  .payment-method__cc-related-buttons-container {
    padding: 0;
  }

  .payment-methods__stored-cc + hr {
    border-color: #f3f3f3;
    border-style: solid;
    margin-top: 1em;
  }

  .payment-method__gc-related-buttons-container.payment-method-section__row {
    margin-top: 0;
  }

  .payment-method-section__details-gc-buttons {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .payment-method-section__details-gc-buttons > button:first-child {
    width: 40%;
    margin-right: 0 !important;
  }
}

/** End of Desktop Layout **/
