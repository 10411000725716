.payment-method__subheading {
  margin: 0 0 15px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

.custom-select__button--form {
  height: 43px;
  padding: 0 10px;
  color: #757575;
  font-size: 13px;
  text-align: left;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}

.custom-select__list-item--form {
  width: 100%;
}
