.giftCardOrderDetailsTotal {
  border-top: none !important;
  margin-bottom: 0;
  padding-top: 0.2em;
}

.giftCardOrderDetailsContent {
  display: "flex";
  flex-direction: "column";
  border-bottom: 1px solid lightgrey;
  width: 100%;
}

.giftCardOrderDetailsContent:last-child {
  border-bottom: none !important;
}

.buttonFilled {
  background-color: var(--color-primary);
  color: var(--color-button-text);
}
.giftCardNotLoggedInMessage{
  max-width: 400px;
}
.giftCardNotLoggedInMessage a {
  width: 100%;
}
.giftCardNotLoggedInMessage h3 {
  margin-top: 0;
  margin-bottom: 1rem;
}

.giftCardNotLoggedInMessage p {
  text-align: center;
  word-wrap: break-word;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.recentGiftCardOrderBackButtonLabel {
  margin-top: 0;
}
.gcRecentOrderDetailsContainer{
  min-width: 550px;
  width: fit-content;
  padding: 1.2em;
}
.gcRecentOrderDetailsContainer .recent-order__main{
  padding-bottom: 0 !important;
}
.gcRecentOrderDetailsWrapper{
  display: flex;
  align-items: flex-start;
}
.gcTotalCompletedOrder{
  margin-bottom: 0;
}
@media screen and (max-width: 600px) {
  .giftCardOrderDetailsContent {
    width: 100%;
  }
  .gcRecentOrderDetailsContainer{
    min-width: 100%;
  }
  .gcRecentOrderDetailsWrapper{
    display: block;
    align-items: unset;
  }
  .giftCardNotLoggedInMessage{
    max-width: 260px;
  }

}