.pay-in-store-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  height: 80%;
}

.pay-in-store-modal {
  font-size: 17px;
  font-weight: 600;
}

.modal-wrapper .pay-in-store-modal {
  transform: scale(0) translateY(60vh);
  transform-origin: bottom right;
  animation: movePayInStoreModalUp 0.3s forwards;
}

@keyframes movePayInStoreModalUp {
  100% {
    transform: scale(1) translateY(0);
  }
}

.modal-content {
  height: 100%;
  max-height: none;
}
