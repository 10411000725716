.menu-slider__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  width: 75vw;
  max-width: 325px;
  height: 65vh;
  margin-left: 3%;
  margin-right: 3%;
  padding-bottom: 1em;
  scroll-snap-align: center;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 20%);
  transition: transform 0.3s cubic-bezier(0.19, 1.24, 0.26, 1);
}

.menu-slider__item--in-view {
  transform: scale(1.1) translateY(6px);
}

.menu-slider__item-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-slider__item-image-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 103.5%;
  margin-bottom: 1em;
}

.menu-slider__item-image--loaded .menu-slider__item-image {
  opacity: 1;
}

.menu-slider__item-image {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.35s;
  border-top-left-radius: 0.4em;
  border-top-right-radius: 0.4em;
}

.menu-slider__item-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  margin: 0;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  text-align: center;
}

.menu-slider__item-price {
  margin-bottom: 5px;
  color: #3f3f3f;
  font-size: 1rem;
  font-weight: 600;
}

.menu-slider__item-calories {
  min-height: 16px;
  font-size: 0.75rem;
}

.menu-slider__item-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.menu-slider__image-underlay {
  position: relative;
  left: 0;
  top: 0;
  max-height: 100%;
}

.menu-slider__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
}
