/* textarea input */
.textareaInput {
  height: 100px !important;
  resize: none;
  border: 1px solid #dedede !important;
  border-radius: 0.5em !important;
}

.form_button-action-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  white-space: nowrap;
  margin-top: 0.75rem;
  width: 100%;
  position: absolute;
}

.form_button-action-submit,
.form_button-action-clear {
  min-width: fit-content;
  display: flex;
  align-items: center;
}

.form_button-action-submit>Button,
.form_button-action-clear>Button {
  background-color: transparent;
}

.form_button-action-submit>Button>span,
.form_button-action-clear>Button>span {
  margin-top: 0.12rem;
  text-transform: none;
  color: #757575;
  font-size: 16px;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-weight: 500;

}

.form_button-action-submit>Button.activeText>span,
.form_button-action-clear>Button.activeText>span {
  color: var(--color-primary);
}

.form_button-action-clear {
  margin-top: 0.10rem;
  display: flex;
  align-items: center;
}

.form_button-action-clear>span {
  margin-left: 0.22rem;
}

.form_button-action-submit {
  margin-right: 2rem;
}

.disabledQuickSubmitButton {
  pointer-events: none !important;

}

.disabledQuickSubmitButton:hover {
  pointer-events: none !important;
  box-shadow: none !important;
}

/* mobile view */
@media screen and (max-width:660px) {
  .textAreaWrapperSmall {
    width: 100% !important;
    margin-top: 2.5em;
  }
}