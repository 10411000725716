.order-settings-component__main-container {
  position: fixed;
  bottom: 56px;
  background-color: var(--color-online-order-settings-background);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: center;
  box-shadow: 0 0.5px 3px 0.5px rgb(0 0 0 / 15%);
  min-height: 62px;
}

.order-settings-component__column {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.order-settings-component__column:first-child {
  max-width: 37%;
  min-width: 37%;
}
.order-settings-component__column {
  max-width: 48%;
  min-width: 48%;
}

.order-settings-component__column.select-store {
  min-width: 85%;
  max-width: 85%;
}

.order-settings-component__column:not(:first-child) {
  margin-left: 10px;
}

.order-settings-component__column.cart-button {
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  min-width: unset;
  display: flex;
  justify-content: center;
}

.order-settings__right-arrow {
  height: 10px;
  position: absolute;
  top: 5px;
  right: 0;
}

.order-settings__cart-button {
  width: 100%;
  height: 100%;
}
.order-settings__cart-button > span {
  background-color: red;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
}

.order-settings-component__row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.order-settings-component__store-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.order-settings-component__time-wrapper {
  margin-bottom: 0px;
}

.order-settings-component__order-type {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 5px;
}

.order-settings-component__store {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.order-settings-component__store-button {
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-bottom: 2px;
}

.order-settings-component__store-button::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateX(-15px);
  border-bottom: 2px solid var(--color-online-order-settings-background-contrast);
  width: 100%;
}

.order-settings-component__time-selection-main-container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  padding-bottom: 57px;
  top: 0;
  left: 0;
  z-index: 1100;
}

.order-settings-component__time-selection-inner-wrapper {
  background-color: #fff;
  width: 100%;
  min-height: 85%;
  max-height: 85%;
  position: absolute;
  bottom: 57px;
  left: 0;
  border-radius: 8px;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.order-settings-component__time-selection-header-wrapper {
  background-color: #eee;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;
  border-radius: 8px;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.order-settings-component__time-selection-header-wrapper > h4 {
  margin-left: 24px;
}

.order-settings-component__time-date-selection-wrapper {
  min-height: 50px;
  width: 100%;
  background-color: var(--color-online-order-settings-background);
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-settings-component__time-date-selection-wrapper > button > .icon {
  margin-top: 4px;
  margin-left: 10px;
  margin-right: 10px;
}

.order-settings-component__time-options-selection-wrapper {
  overflow-y: scroll;
  max-height: 85vh;
  padding-bottom: 20em;
}

.order-settings-component__time-options-selection-wrapper .form__field-label--radio {
  padding-left: 24px;
  min-height: 50px;
}

.order-settings-component__time-options-selection-wrapper
  .form__field-wrapper--checkbox-radio
  + .form__field-wrapper--checkbox-radio {
  margin-top: 0;
}

.order-settings-component__time-options-selection-wrapper .form__field-label--radio::after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.order-settings-component__time-options-selection-wrapper
  .form__field-wrapper--checkbox-radio:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.order-settings-component__time-options-selection-wrapper
  .form__field-label--checkbox-radio::before {
  display: none;
}
.order-settings-component__time-options-selection-wrapper
  .form__field-label--checkbox-radio::after {
  content: "";
  position: absolute;
  right: 24px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.order-settings-component__time-options-selection-wrapper
  .form__field--radio:checked
  + .form__field-label--radio::after {
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'><circle cx='50' cy='50' r='30'/></svg>");
  background-repeat: no-repeat;
  background-position: center;
}

.order-settings-component__time-options-confirm-button-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  min-height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--color-primary);
}

.order-settings-component__main-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: relative;
}

.order-settings-component__date-list-container {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}
.order-settings-component__date-list {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  list-style-type: none;
  justify-content: space-between;
  padding-left: 0;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.order-settings-component__date-list::-webkit-scrollbar {
  display: none;
}

.order-settings-component__date-list > li > button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.month-span {
  font-size: 16px;
  font-weight: 300;
}

.date-span {
  font-weight: 900;
  font-size: 25px;
}

.day-span {
  font-size: 14px;
  font-weight: 300;
}

.date-button {
  transform: scale(0.9);
  padding: 10px;
  margin-right: 10px;
}
.active-date-button {
  transform: scale(1.2);
  transition: all 0.2s ease-in-out;
}

.order-settings-component__time-options-selection-wrapper > ol {
  list-style: none;
  padding-left: 0;
  height: 100%;
  overflow-y: scroll;
}

.time-option__day-after-label {
  margin-left: 5px;
  color: var(--color-gray);
}

@media screen and (max-width: 660px) {
  .order-settings-component__store {
    width: 91%;
  }
}

/** Desktop design **/
@media screen and (min-width: 660px) and (max-width: 700px) {
  .order-settings-component__main-container {
    bottom: 25px;
    padding: 20px 180px 20px 180px;
  }
}
@media screen and (min-width: 660px) {
  .order-settings-component__main-container {
    bottom: 27px !important;
    padding: 15px 50px 15px 50px !important;
  }

  .order-settings-component__main-container.no-terms-enabled {
    bottom: 25px !important;
    padding: 15px 50px 15px 50px !important;
  }

  .order-settings-component__order-type {
    margin-left: 0;
    font-size: 16px;
  }

  .order-settings-component__row {
    width: 100%;
    align-items: center;
  }

  .order-settings-component__column.order-type_component {
    margin-right: 20px;
  }
  .order-settings-component__column.order-type_component:not(:first-child) {
    margin-left: 50px;
  }

  .order-settings-component__column.store_component {
    width: 30% !important;
  }

  .order-settings-component__column.order-time_component {
    width: 20% !important;
  }

  .order-settings-component__column.order-button_component {
    width: 20% !important;
  }

  .order-settings-component__column.cart_component {
    width: unset !important;
  }

  .order-settings-component__time-wrapper {
    width: 100%;
    margin-bottom: 0;
  }

  .order-settings-component__time-selection-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 5em 0;
  }

  .order-settings-component__time-selection-inner-wrapper {
    position: relative;
    border-radius: 10px;
  }

  .order-settings-component__time-options-confirm-button-wrapper {
    border-end-end-radius: 10px;
    border-end-start-radius: 10px;
  }

  .order-settings-component__time-options-selection-wrapper {
    max-height: 100%;
  }

  .order-settings-component__date-list-container {
    display: flex;
    overflow: hidden;
    position: relative;
    scroll-behavior: smooth;
  }

  .order-settings-component__date-list__right-left {
    padding-right: 30px;
  }
  .order-settings-component__date-list__right-arrow {
    padding-left: 30px;
  }
  .order-settings-component__date-list {
    overflow: unset;
    scroll-behavior: smooth;
  }

  .order-settings-dates__horizontal-slider-button {
    position: absolute;
    z-index: 10;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .order-settings-dates__horizontal-slider-previous-button {
    left: 0;
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.6));
  }

  .order-settings-dates__horizontal-slider-next-button {
    right: 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.6), #fff);
  }

  .order-settings-component__time-selection-inner-wrapper {
    bottom: unset;
  }

  .order-settings-component__time-options-selection-wrapper {
    padding-bottom: 0;
    display: inline-block;
    width: 100%;
    overflow: hidden;
  }

  .order-settings-component__time-options-selection-wrapper > ol {
    max-height: calc(100vh - 500px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .order-settings-component__column {
    max-width: unset !important;
    min-width: unset !important;
  }

  .order-settings-component__store-button::before {
    transform: translateX(0);
  }

  .order-settings-component__column.store_component,
  .order-settings-component__column.order-time_component {
    flex-direction: row;
  }

  .order-settings-component__row {
    position: relative;
  }

  .order-settings-component__column.cart_component {
    position: absolute;
    right: 0;
  }

  .order-settings-component__store-wrapper {
    margin-left: 5px;
  }

  .order-button_component {
    position: absolute;
    right: 60px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .order-button_component > button {
    padding: 10px 50px !important;
    margin-right: 0 !important;
    width: 100% !important;
  }
  .order-setting-component__store-label-wrapper {
    width: 35%;
  }
}

@media screen and (min-width: 768px) {
  .order-settings-component__main-container {
    bottom: 25px;
    padding: 20px 100px 20px 100px;
  }
}

@media screen and (min-width: 1000px) {
  .order-settings-component__time-selection-inner-wrapper {
    width: 40%;
  }

  .order-settings-component__main-container {
    bottom: 25px;
    padding: 20px 100px 20px 100px;
  }
}
@media only screen and (min-width: 861px) and (max-width: 1000px) {
  .order-settings-component__time-selection-inner-wrapper {
    width: 40%;
  }
}

@media only screen and (min-width: 660px) and (max-width: 860px) {
  .order-settings-component__time-selection-inner-wrapper {
    width: 50% !important;
  }
}

/** Small Phones **/

@media only screen and (max-width: 450px) {
  .order-settings-component__column:first-child {
    max-width: 37%;
    min-width: 37%;
  }
  .order-settings-component__column {
    max-width: 48%;
    min-width: 48%;
  }
  .order-settings-component__column.select-store {
    min-width: 85%;
    max-width: 85%;
  }
}

@media only screen and (max-width: 400px) {
  .order-settings-component__column:first-child {
    max-width: 39%;
    min-width: 39%;
  }
  .order-settings-component__column {
    max-width: 45%;
    min-width: 45%;
  }
  .order-settings-component__column.select-store {
    min-width: 85%;
    max-width: 85%;
  }
}

@media only screen and (max-width: 350px) {
  .order-settings-component__column:first-child {
    max-width: 40%;
    min-width: 40%;
  }
  .order-settings-component__column {
    max-width: 45%;
    min-width: 45%;
  }
  .order-settings-component__column.select-store {
    min-width: 85%;
    max-width: 85%;
  }
}

@media only screen and (max-width: 320px) {
  .order-settings-component__column:first-child {
    max-width: 40%;
    min-width: 40%;
  }
  .order-settings-component__column {
    max-width: 44%;
    min-width: 44%;
    font-size: 0.8em;
  }
  .order-settings-component__column.select-store {
    min-width: 85%;
    max-width: 85%;
  }
}
