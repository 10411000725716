.menu__category-buttons-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: -0.2em -0.2em 1em;
  padding: 0;
  list-style-type: none;
}

.menu__category-filter-button-wrapper {
  margin: 0.2em;
}

.menu__category-filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  padding: 0 1em;
  height: 36px;
  font-size: 1em;
  font-weight: 600;
  line-height: 100%;
  border: 2px solid var(--color-secondary);
  border-radius: 9999px;
}

.menu__category-filter-button > .icon + * {
  margin-left: 0.2em;
}
