.icon--reward-cup {
  margin-bottom: 25px;
  flex-shrink: 0;
}

.rewards__points-line {
  margin-bottom: 25px;
  opacity: 1;
  transition: opacity 0.5s;
}

.rewards__points-line--loading {
  opacity: 0;
}

.rewards__container {
  position: relative;
  margin: 25px 0 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
}

.rewards-list__item {
  list-style: none;
  position: relative;
  animation: 0.5s fadeIn forwards;
}

.rewards-list__item + .rewards-list__item {
  margin-top: 20px;
}

.rewards__container--loading .rewards-list__item {
  animation: 0.5s fadeOut forwards;
}

.icon--reward-x {
  width: 13px;
  height: 13px;
}

.button--remove-reward {
  background-color: #cfcfcf;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  position: absolute;
  right: calc(100% + 10px);
  top: 9px;
}

.remove-reward__minus {
  font-weight: bold;
  display: block;
  position: relative;
  top: -2px;
}

.reward-button__number-wrapper {
  position: absolute;
  left: calc(100% + 10px);
  top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-button__number--animated {
  animation: 0.35s blinkRewardNumber forwards;
}

@keyframes blinkRewardNumber {
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: initial;
  }
}

.reward-button__number {
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
}

.button--white.button--reward {
  min-width: 185px;
  height: 40px;
  justify-content: center;
}

.icon--reward-star {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.icon--reward-check {
  width: 14px;
  height: 14px;
  margin-left: 10px;
  background-color: #4caf52;
  stroke: #fff;
  stroke-width: 3px;
  border-radius: 100%;
  padding: 3px;
}

.reward__loading-dots {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto;
}

.reward__loading-dot {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #d64123; /* BRAND COLOR */
  border-radius: 50%;
  animation: growLoadingDot 1.5s infinite both;
}

.reward__loading-dott:nth-child(2) {
  animation-delay: 0.2s;
}

.reward__loading-dot:nth-child(3) {
  animation-delay: 0.3s;
}

.reward__loading-dot:nth-child(4) {
  animation-delay: 0.4s;
}

@keyframes growLoadingDot {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.reward__error {
  color: #ff2020;
  font-style: italic;
}

.rewards__main-container {
  width: 100%;
  height: 100%;
  padding-bottom: 150px;
  overflow-y: auto;
}

.modal-wrapper--rewards-form > .modal.modal--fullscreen {
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.modal-wrapper--rewards-form {
  background-color: white;
  z-index: 1000;
}
.rewards-content {
  padding: 0 30px;
}

.my-rewards__points-and-card-select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rewards__main-container > .my-rewards__section {
  padding: 0 24px;
  margin-top: 24px;
}

.rewards-module__balance-and-star {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.rewards-module__balance-and-star > div {
  display: flex;
}

.rewards-module__balance-and-star > .rewards-form__point-balance-container {
  justify-content: flex-start;
}

.rewards-module__balance-and-star > .icon--star {
  width: 28px;
  height: 28px;
  fill: #f49c00;
  stroke: none;
}

.rewards-module__balance {
  margin-right: 4px;
  font-size: 1.5rem;
  font-weight: 500;
}

.rewards-module__balance-money {
  font-size: 14px;
}

.rewards__main-container > .my-rewards__header-container {
  padding-top: 1em;
}

.rewards-module__select {
  height: 44px;
  padding: 0 20px;
  background: none;
  border: none;
  appearance: none;
}

.my-rewards__option-row-shadow {
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  margin: 10px 0;
  border-radius: 5px;
  padding: 6px;
  background-color: white;
}

.my-rewards__option-row-shadow > td {
  min-height: 38px;
}

tr.my-rewards__option-row-shadow-selected.my-rewards__option-row--redeemable {
  border-top: 2px solid #d64123;
}

.my-rewards__option-row-shadow.my-rewards__option-row--redeemable {
  padding: 6px;
}

.my-rewards__option-cell--check > .icon--check {
  background-color: #4caf52;
  stroke: #fff;
  stroke-width: 3px;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  padding: 3px;
}

.my-rewards__option-cell--check {
  min-width: 20px;
}

.my-rewards__option-row-shadow > .my-rewards__option-cell--name {
  width: 70%;
}

.my-rewards__option-row-shadow > .my-rewards__option--name-rewards {
  width: 20%;
}

.header.curved-shadow-container.my-rewards__header {
  width: 100%;
}

.my-rewards__option-row.my-rewards__option-row-shadow
  + .my-rewards__option-row.my-rewards__option-row-shadow {
  border-top: none;
}

.my-rewards__option-row.my-rewards__option-row-shadow.my-rewards__option-row--redeemable.my-rewards__option-row-shadow-selected {
  border: 2px solid #d64123;
  box-shadow: none;
  background-color: white;
}

.my-rewards__option-row-centered-items {
  text-align: center;
  min-height: 53px;
}

.modal-wrapper--rewards-form > div > h1.modal__heading {
  font-size: 1.5rem;
}

.modal-wrapper--rewards-form {
  border-top: 5px solid #d64123;
}

.my-rewards__section.reward-form__points-and-card-select {
  background-color: #f2f2f2;
  margin-top: 0;
  width: 100%;
  display: block;
  padding: 1em 2em;
}

.rewards-form__skeleton {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 40px;
  background: rgb(247, 247, 247);
  margin-bottom: 1em;
}

.rewards-form__skeleton::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgb(247, 247, 247),
    hsla(0, 0%, 100%, 0.83) 50%,
    rgb(247, 247, 247)
  );
  animation: progress 1s ease-in-out infinite;
}

@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.reward-button__applied-indicator-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.reward-button__applied-indicator-wrapper > .icon--check {
  background-color: #4caf52;
  stroke: #fff;
  stroke-width: 3px;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  padding: 3px;
  margin-right: 10px;
}

.my-rewards__option-cell--quantity {
  display: flex !important;
  flex-direction: column;
  width: 55%;
}

/** quantity buttons **/
.reward__quantity-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.reward__quantity-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 1em;
  align-items: center;
}

.reward__quantity-wrapper > label {
  font-size: 0.85rem;
  margin-right: 1em;
}

.reward__quantity-buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.reward__quantity-button:active > .icon {
  background-color: #2a2a2a;
  color: white;
}

.reward__quantity-button:disabled {
  background-color: transparent;
}

.reward__quantity-button:disabled > .icon {
  opacity: 0.5;
}

.reward__quantity-button {
  display: flex;
}
.reward__quantity-button > .icon {
  width: 2em;
  height: 2em;
  padding: 5px;
  stroke-width: 1.5px;
  background-color: white;
  border-radius: 50%;
  border: solid 1.5px #2a2a2a;
  color: #2a2a2a;
}

.reward__quantity-input {
  background-color: transparent;
  border: none;
  border-bottom: solid 2px var(--color-gray-light);
  border-radius: 0;
  text-align: center;
  padding-bottom: 5px;
  width: 40%;
  min-width: 1em;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-left: 5px;
}

/** end of quantity buttons **/

/** Desktop Layout **/

@media screen and (min-width: 660px) {
  .my-rewards__option--button-container-rewards.use-rewards {
    margin: auto;
    width: 65%;
  }
  .reward__quantity-wrapper {
    margin: auto;
    width: 60%;
  }
  .modal-wrapper--rewards-form > .modal > .modal__heading {
    visibility: hidden;
  }

  .modal-wrapper--rewards-form > .modal > .modal__button--close {
    right: 9px;
    left: unset;
  }

  .my-rewards__option-cell.my-rewards__option-cell--quantity {
    width: 20%;
    flex-direction: row;
    margin-right: 2em;
  }

  .my-rewards__option-cell.my-rewards__option-cell--quantity > label {
    font-weight: 600;
    margin-right: 1em;
  }

  .my-rewards__option-cell.my-rewards__option-cell--button {
    width: 25%;
  }

  .my-rewards__option-cell.my-rewards__option-cell--name {
    width: 30%;
  }

  .my-rewards__option-cell.my-rewards__option--name-rewards {
    width: 20%;
  }

  .my-rewards__option-cell.my-rewards__option-cell--cost {
    width: 0;
  }

  .my-rewards__option-cell.my-rewards__option--cost-rewards {
    width: 0;
  }

  .my-rewards__option-row {
    width: 100%;
    margin: 1em 0;
  }
  .my-rewards__option-row.my-rewards__option-row-shadow:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  .my-rewards__section.my-rewards__options-container.use-rewards__section {
    padding-right: 0;
    padding-left: 0;
  }

  .my-rewards__option-cell--quantity > label {
    width: 47%;
  }

  .my-rewards__option-cell--quantity {
    display: flex !important;
    flex-direction: column;
    width: 55% !important;
  }

  /* quantity buttons */
  .reward__quantity-container {
    margin-top: 0;
  }

  .rewards__main-container {
    padding-left: 2em;
    padding-right: 2em;
  }
}

.reward-form__error-message-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding-top: 1em;
  padding-bottom: 1em;
}

.reward-form__error-message-container > p {
  text-align: center;
  color: #ff2020;
}

@media screen and (max-width: 660px) {
  .reward-form__error-message-container {
    padding: 0 24px;
  }
}

@media screen and (min-width: 660px) and (max-width: 860px) {
  .modal-wrapper--rewards-form {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    border-top: none !important;
  }

  .modal-wrapper--rewards-form > .modal {
    width: 95%;
    height: 95%;
    background-color: white;
  }

  .my-rewards__option-cell.my-rewards__option-cell--quantity {
    width: 20%;
    flex-direction: row;
    margin-right: 1em;
  }

  .my-rewards__option--cost-rewards {
    display: flex;
    position: relative;
    align-items: baseline;
    align-self: baseline;
    justify-content: center;
    margin: 1em 0 0 1em;
    color: #f49c00;
  }
}

@media screen and (min-width: 860px) and (max-width: 1280px) {
  .modal-wrapper--rewards-form {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    border-top: none !important;
  }

  .modal-wrapper--rewards-form > .modal {
    width: 90%;
    height: 90%;
    background-color: white;
  }

  .my-rewards__option-cell.my-rewards__option-cell--quantity > label {
    margin-right: 1rem;
  }
}

@media screen and (min-width: 1280px) {
  .modal-wrapper--rewards-form {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    border-top: none !important;
  }

  .modal-wrapper--rewards-form > .modal {
    width: 70%;
    height: 70%;
    background-color: white;
  }

  .my-rewards__option-cell.my-rewards__option-cell--quantity > label {
    margin-right: 1em;
  }
}

/** End of Desktop Layout **/
