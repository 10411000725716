.form--seat-row-section {
  padding: 24px 24px 100px 24px;
}

.form--seat-row-section__floating-button {
  bottom: 57px;
  padding-bottom: 17px;
}

.stadium-delivery-address {
  font-weight: normal !important;
}

.seat-row-section__error-container > p {
  display: flex;
  justify-content: center;
}

.seat-row-section__error-container > p > .icon--triangle-alert {
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

@media screen and (min-width: 660px) {
  .form--seat-row-section {
    width: 50%;
  }

  .seat-section__form-container .delivery-address__header-container,
  .seat-section__form-container .form--seat-row-section {
    padding-left: 0;
  }
}
