.credit-card-form__stored-credit-card-label {
  margin: 0 0 12px;
  font-size: 17px;
  font-weight: 600;
}

.credit-card-form__stored-credit-card {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}

.credit-card-form__stored-credit-card > svg {
  margin-right: 12px;
  padding: 3px;
  stroke-width: 3px;
  stroke: #fff;
  background-color: #4caf52;
  border-radius: 50%;
}

.credit-card-form__stored-credit-card-expiry {
  padding-left: 30px;
  color: #676767;
  font-size: 12px;
}

.save-credit-card-popup-buttons-container {
  display: flex;
}
