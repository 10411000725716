.main-content--dine-in-qr-scanner {
  /*padding: 24px;*/
}

.dine-in__qr-scanner-instructions {
  margin: 0;
  font-weight: normal;
  margin-bottom: 1em;
}

.dine-in__qr-scanner-instructions__container {
  background-color: var(--color-gray-light);
  padding: 24px;
}

.dine-in_qr-toggle-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.dine-in_qr-toggle-container > button {
  color: var(--color-secondary);
  font-weight: 600;
}

.dine-in__qr-scanner-container {
  position: relative;
  width: 300px;
  height: 300px;
  max-width: 35vh;
  max-height: 35vh;
  margin: 10% auto;
}

.dine-in-desktop-popup.dialog-modal-container {
  font-size: unset;
}

.dine-in-desktop-popup.dialog-modal-container > .dialog-modal {
  width: 500px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 0 0 0;
}

.dine-in-desktop-popup.dialog-modal-container .dine-in__table-number-form-wrapper {
  padding: 0;
  justify-content: center;
  display: flex;
  width: 100%;
}

.dine-in-desktop-popup.dialog-modal-container .order-stores__store-wrapper-dinein {
  background-color: var(--color-gray-light);
  padding: 10px 24px;
}

.dine-in-desktop-popup.dialog-modal-container .dine-in__qr-scanner-instructions,
.dine-in-desktop-popup.dialog-modal-container .dine-in__table-number-form-wrapper {
  padding-left: 24px;
  padding-right: 24px;
}

.dine-in-desktop-popup.dialog-modal-container .order-stores__use-location-button {
  margin-left: 24px;
}

.dine-in__video-stream-error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.qr-scanner__error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: red;
}

.dine-in__qr-scanner-wrapper {
  width: 100%;
  height: 100%;
}

.qr-scanner__video-stream {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.button--retry-qr-scan {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.qr-scanner__square-frame {
  position: absolute;
  top: -7px;
  left: -7px;
  margin: 0 auto;
  width: calc(100% + 14px);
  height: calc(100% + 14px);
  border-style: solid;
  border-width: 7px;
  border-image-source: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 80 80%22%3E%3Crect x=%224%22 y=%224%22 width=%2272%22 height=%2272%22 fill=%22transparent%22 stroke=%22%23565656%22 stroke-width=%2220%22 %2F%3E%3C%2Fsvg%3E");
  border-image-slice: 80%;
  border-image-width: 2em;
}

.dine-in__table-number-form-wrapper {
  position: relative;
  min-height: 153px;
  max-width: 100%;
  padding: 24px;
}

.dine-in__qr-scanner-iso-camera {
  padding-bottom: 2em;
  margin: 0 auto;
}

@media screen and (min-width: 660px) {
  .main-content--dine-in-qr-scanner {
    padding: 0;
  }

  .dine-in__table-number-form-wrapper {
    min-width: 15em;
    min-height: unset;
  }

  .dine-in__table-number-form__popup {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .dine-in__table-number-form__popup .form-submit__wrapper {
    margin-top: 0 !important;
  }
}
