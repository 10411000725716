.desktop-order-menu__top-categories-buttons {
  padding-top: 1.5%;
  margin-bottom: 2em;
}

.desktop-order-menu__top-categories {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5% 0 0 -0.5%;
  padding-left: 0;
  list-style-type: none;
}

.desktop-order-menu__top-category {
  margin: 0.2%;
}

.desktop-order-menu__top-category-button,
.desktop-order-menu__favorites-button {
  min-width: 150px;
  min-height: 35px;
  padding: 0 1em;
  font-size: 1em;
  font-weight: 600;
  line-height: 100%;
  border-radius: 9999px;
}

.desktop-order-menu__favorites-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktop-order-menu__favorites-button > .icon--heart {
  margin-right: 4px;
}

.desktop-order-menu__subcategories {
  display: flex;
  flex-wrap: wrap;
  margin: -1% 0 0 -1%;
  padding-left: 0;
  list-style-type: none;
}

.desktop-order-menu__card {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% / 3 - 2%);
  height: 0;
  margin: 1%;
  padding-top: 14%;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s;
}

.desktop-order-menu__card:hover {
  transform: translateY(-10px);
}

.desktop-order-menu__subcategory-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.desktop-order-menu__card-image-wrapper {
  position: absolute;
  left: 18px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  height: calc(100% - 36px);
  margin-right: 15px;
  overflow: hidden;
}

/*.desktop-order-menu__card-image-wrapper-with-padding {
  padding: 1em;
}*/

.desktop-order-menu__card-image {
  max-height: 100%;
  width: 100%;
  object-fit: contain;
}

.desktop-order-menu__card-text-wrapper {
  position: absolute;
  right: 18px;
  top: 0;
  bottom: 0;
  max-height: calc(100% - 36px);
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 35% - 15px - 36px);
}

.desktop-order-menu__card-text-wrapper--subcategory {
  right: 32px;
  width: calc(100% - 35% - 15px - 18px - 32px);
}

.desktop-order-menu__card-text-wrapper--item {
  justify-content: space-between;
}

.desktop-order-menu__card-heading {
  overflow: hidden;
  white-space: nowrap;
  margin: 0 0 0.5em;
  font-weight: 600;
}

.desktop-order-menu__card-heading--subcategory {
  font-size: 1.3em;
}

.desktop-order-menu__card-heading--item {
  font-size: 1.1em;
}

.desktop-order-menu__card > .icon--arrow-right {
  position: absolute;
  right: 1.5em;
  top: 0px;
  bottom: 0;
  margin: auto 0;
  width: 18px;
  height: 18px;
  fill: #333;
}

.desktop-order-menu__breadcrumbs-container {
  margin-bottom: 2%;
}

.desktop-order-menu__breadcrumbs-list {
  display: flex;
  align-items: center;

  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.desktop-order-menu__breadcrumb-item + li {
  margin-left: 1.5%;
  padding-left: 1.5%;
  border-left: 1px solid #aaa;
}

.desktop-order-menu__breadcrumb {
  font-size: 1em;
  font-weight: 600;
}

.desktop-order-menu__breadcrumb:disabled {
  color: #2a2a2a !important;
  opacity: 1;
  background: none;
}

.desktop-order-menu__items {
  display: flex;
  flex-wrap: wrap;
  margin: -1% 0 0 -1%;
  padding-left: 0;
}

.desktop-order-menu__item-description {
  position: relative;
  min-height: 35px;
  max-height: 1.9em;
  margin: 0 0 0.5em;
  overflow: hidden;
  font-size: 0.8125em;
  line-height: 1.3em;
}

.desktop-order-menu__item-description:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.3em;
  margin-top: 0.2em;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(hsla(0, 0%, 100%, 0)),
    color-stop(50%, hsla(0, 0%, 100%, 0.83))
  );
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.83) 50%);
}

.desktop-order-menu__item-calories {
  margin: 0 0 0.8em;
  font-size: 0.8125em;
}

.desktop-order-menu__item-price {
  font-size: 1.1em;
  font-weight: 600;
}

.desktop-order-menu__calories-disclaimer {
  margin: 3.3% auto 0;
  color: #6b6b6b;
  font-size: 0.9em;
}

.desktop-order-item-container {
  padding-top: 1em;
}

.desktop-order-item__image-and-text-wrappers {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 2%;
}

.desktop-order-item__image-wrapper {
  max-width: 30%;
  margin-right: 3%;
  min-width: 25%;
}

.desktop-order-item__text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 60%;
}

/*.desktop-order-item__text-wrapper > div:first-child {
  max-width: 85%;
}*/

.desktop-order-item__heading {
  margin: 0 0 0.5em;
  font-size: 1.5em;
}

.desktop-order-item__description--wrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  position: relative;
}
.desktop-order-item__description {
  margin: 0;
  font-size: 1em;
  white-space: pre-line;
  overflow: hidden;
  max-height: 16.1em;
}

.desktop-order-item__description--expanded {
  max-height: 100%;
  min-height: 5.5em;
  transition: max-height 0.5s;
  transition: min-height 0.5s;
  overflow: unset;
  display: inline;
}

.desktop-order-item__description--read-more {
  color: var(--color-secondary);
  margin-top: 10px;
}

.desktop_item_description__gradient {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 18px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 18%, rgba(255, 255, 255, 0) 100%);
}

.desktop-order-item__calories {
  font-size: 1.3em;
  margin-top: 10px;
}

.desktop-order-item__price-and-favorites-wrapper {
  margin-top: 1%;
  padding-top: 1%;
  border-top: 1px solid #dfdfdf;
}

.desktop-order-item__price {
  font-size: 1.3em;
  font-weight: 600;
}

.desktop-order-item__options-container {
  padding: 2% 0 5em 0;
  background: linear-gradient(180deg, #eee, #eee 80%, #fff);
}

.desktop-order-item__options-container > .desktop-container {
  padding-left: 24px;
  padding-right: 24px;
}

.desktop-order-item__options-heading {
  margin: 0 0 1.5em;
  font-size: 1.3em;
  font-weight: 600;
  text-align: center;
}

.desktop-order-item__option-header {
  margin-bottom: 1em;
}

.desktop-order-item__option-heading,
.form__fieldset-legend {
  margin: 0;
  font-size: 1em;
  font-weight: 600;
}

.desktop-order-item__options-form {
  position: relative;
}

.desktop-order-item__options-group + .desktop-order-item__options-group {
  margin-top: 2.5%;
}

.desktop-order-item__options-group + .special-instructions-wrapper {
  margin-top: 4%;
}

.desktop-order-item__options-form .form__fieldset-legend:not(.form__fieldset-legend--nested) {
  display: none;
}

.desktop-order-item__options-form .addons-modifiers-form .form__field-wrapper--checkbox-radio {
  padding: 0.3% 1%;
  background-color: #fff;
  border-radius: 4px;
}

.desktop-order-item__options-form .form__fieldset {
  margin: -0.5% 0 0 -0.5%;
}

.desktop-order-item__options-form .form__fieldset--nested {
  margin-top: 8px;
}

.desktop-order-item__options-form .form__fieldset + .form__fieldset--nested {
  margin-top: 2%;
}

.desktop-order-item__options-form .form__field-wrapper {
  display: inline-block;
  width: 49%;
  margin: 0.5%;
}

.desktop-order-item__options-form .form__fieldset + * {
  margin-top: 2.5%;
}

.desktop-order-item__options-form .form__field-label--checkbox-radio::before {
  left: 2%;
}

.desktop-order-item__options-form .form__fieldset-legend--nested {
  margin-bottom: 0.5em;
}

.desktop-order-item__options-form + .desktop-order-item__option-header {
  margin-top: 2.5%;
}

.form__buttons-wrapper {
  position: fixed;
  left: 0;
  bottom: 20px;
  width: 100%;
  z-index: 9999;
}

.form__buttons-wrapper > .desktop-container {
  display: flex;
  justify-content: space-between;
}

.form__buttons-wrapper > .desktop-container > * {
  flex: 1;
}

.desktop-order-item__option-heading > .icon--check {
  width: 18px;
  height: 18px;
  padding: 3px;
  stroke: #fff;
  stroke-width: 3px;
  background-color: #4caf52;
  border-radius: 50%;
}

.desktop-order-item__buttons {
  position: fixed;
  bottom: 28px;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 1em 0;
  background-color: var(--color-online-order-settings-background);
}

.desktop-order-item__buttons > .FAB__error {
  bottom: calc(52px / 2 + 95px);
}

.desktop-order-item__buttons .FAB-wrapper__gradient {
  background-image: linear-gradient(to top, #fff 0%, #fff 38%, rgba(255, 255, 255, 0) 100%);
}

/* .desktop-order-item__buttons .FAB-wrapper__gradient:after {
  opacity: 1;
} */

.desktop-order-item__button-wrappers {
  display: flex;
  justify-content: center;
}

.desktop-order-item__button-wrappers > * {
  flex: 1;
  /*max-width: 30%;*/
  width: 30%;
  transition: opacity 0.3s;
}

.desktop-order-item__button-wrappers > .order-settings__cart-button {
  width: unset;
  flex: unset;
}

.desktop-order-item__button-wrappers > *:hover {
  opacity: 0.7;
}

.desktop-order-item__button-wrappers > button:nth-child(2) {
  margin-right: 1em;
}

.desktop-order-item__back-to-menu-button {
  /*
  position: relative;
  margin-right: 2%;
  text-transform: uppercase;
  border-radius: 9999px;
  background-color: #fff;
*/
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: none;
  background-color: #ffffff;
  text-transform: uppercase;
  font-size: 1em;
  margin-right: 1em;
}

.desktop-order-item__button-wrappers .FAB {
  margin: 0;
}

@media screen and (min-width: 660px) and (max-width: 1024px) {
  .desktop-order-menu__calories-disclaimer {
    max-width: 100%;
    margin-bottom: 4em;
  }
}

.desktop-order-menu__combo-item-check-mark {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}

.desktop-order-menu__combo-item-check-mark > .icon--check {
  stroke: #fff;
  stroke-width: 3px;
  width: 18px;
  height: 18px;
  padding: 3px;
  background-color: #4caf52;
  border-radius: 100%;
}

.desktop-combo-item-details__out-of-stock-addons-error {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  color: #ff2020;
  font-size: 14px;
}

@media screen and (min-width: 660px) and (max-width: 1824px) {
  .desktop-order-menu__card {
    width: calc(100% / 2 - 2%);
    padding-top: 18%;
  }

  .desktop-order-menu__top-categories {
    margin: -1% 0 0 -1%;
  }

  .desktop-order-menu__top-category {
    margin: 0.2%;
  }
}

.dine-in__table-number-form__popup > div > div > button#form--table-number__submit-button {
  width: auto;
  max-width: none;
  padding-left: 3.5em;
  padding-right: 3.5em;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.8em;
  flex: 1 1;
  flex-grow: 0.5;
  height: 44px;
  letter-spacing: 0.2px;
}

.dine-in__table-number-form__popup > div > div > div.form__error {
  top: -60px;
}

.FAB--desktop__confirm-combo-changes__container {
  bottom: 28px;
}
.desktop-menu-button-add-to-order__container,
.FAB--desktop__confirm-combo-changes__container > .desktop-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.desktop-menu-button-add-to-order,
.desktop-menu-button-add-to-order__combo,
.FAB--desktop__confirm-combo-changes {
  width: unset;
  min-width: unset;
  flex: unset;
  padding: 0.8em 4em;
  margin: 0;
  font-size: 0.8em;
}

.desktop-menu-button-add-to-order + button.order-settings__cart-button {
  margin-left: 1em;
}

.desktop-menu-button-add-to-order__combo + button.button--cart-floating {
  margin-left: 1em;
  width: unset;
  margin-right: 0;
  max-width: 37px !important;
}

.desktop-order-item__button-wrappers__combo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.desktop-order-item__button-wrappers__combo > button {
  width: unset;
  min-width: 27%;
  flex: unset;
  padding: 0.8em 4em;
  margin: 0;
  font-size: 0.8em;
  margin-left: 1em;
  margin-right: 0 !important;
}
