.order-stores__main-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 57px;
}

.order-stores__address-map-container {
  padding: 24px;
  background-color: var(--color-gray-light);
  /*min-height: 250px;*/
  position: relative;
}

.order-stores__address-map-container-no-padding {
  padding: 0;
  min-height: unset;
}

.order-stores__address-map-container-no-padding > .desktop-container {
  padding-left: 0;
  padding-right: 0;
  max-width: unset;
}

.order-stores__address-header {
  font-weight: 500;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}

.order-stores__address-header + p {
  margin: 0.5em 1m;
}

.order-stores__address-location-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.order-stores__address-location-wrapper-border-bottom {
  border-bottom: 1px solid #d4d4d4;
}

.order-stores__address-wrapper {
  padding: 20px 0 10px 0;
}

.order-stores__use-location-wrapper {
  padding: 0 0 10px 0;
}

.order-stores__use-location-wrapper_delivery {
  padding-bottom: 20px;
}

.order-stores__address-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.order-stores__address-wrapper.order-stores__address-wrapper-absolute {
  width: 90%;
}

.order-stores__address-wrapper > button > .icon--search {
  width: 38px;
  height: 38px;
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  padding: 10px;
  background: white;
  margin-right: 10px;
}

.order-stores__address-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  padding-top: 10px;
  z-index: 1000;
}

.order-stores__remove-address-button {
  position: absolute;
  right: 0;
  top: 5px;
}

.order-stores__remove-address-button > .icon--x {
  width: 20px;
  height: 20px;
}

.order-stores__address-input-wrapper > span {
  color: #2a2a2a;
  font-size: 14px;
  margin-top: 10px;
  background-color: #ffffffba;
  display: inline;
  width: 100%;
  padding: 0.4em;
}

.order-stores__address-input-wrapper > input {
  width: 100%;
  min-height: 30px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #2a2a2a;
}

.order-stores__address-wrapper-absolute {
  position: absolute;
  z-index: 1004;
  transform: translate(10px, 10px);
}

.order-stores__use-location-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-left: -10px;
  border-bottom: none !important;
}

.order-stores__use-location-wrapper > button {
  white-space: normal;
  word-wrap: break-word;
}

.order-stores__use-location-wrapper > button > span {
  text-align: left;
}

.order-stores__use-location-wrapper-absolute {
  position: absolute;
  top: 70px;
  z-index: 1150;
  transform: translate(10px, 10px);
}

.order-stores__use-location-button {
  display: flex;
  align-items: center;
  position: relative;
}
.order-stores__use-location-button > .icon--target {
  width: 38px;
  height: 38px;
  padding: 10px;
  color: var(--color-secondary);
  margin-right: 5px;
}

.order-stores__use-location-button-inline {
  display: inline-flex;
  align-items: baseline;
  position: relative;
}

.order-stores__use-location-button-inline > .icon--target {
  position: absolute;
  top: -10px;
  left: -5px;
  background-color: transparent;
}

.order-stores__use-location-button-inline > span {
  margin-left: 30px;
}

.order-stores__communication-blurb-inline {
  line-height: 1.5;
}

.order-stores__filter-container {
  background-color: var(--color-gray-light);
}

.order-stores__filter-container > div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0;
}
.order-stores__filter-container > div > .MuiBox-root {
  width: 100%;
}

.order-stores__filter-container > div button {
  padding: 10px;
  width: 33%;
}
.order-stores__filter-container > div button:first-child {
  padding-left: 24px;
  text-align: left;
}

.order-stores__filter-container > div button:last-child {
  padding-right: 24px;
  text-align: right;
}

.order-stores__filter-container.isStadium > div button {
  width: 50%;
  text-align: center;
  padding: 0;
}

.order-stores__filter-container.isStadium > div {
  padding-left: 24px;
  padding-right: 24px;
}

.order-stores__delivery-filter-container {
  border-bottom: 1px solid #d4d4d4;
}

.order-stores__delivery-filter-container > div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 20px 24px 20px 24px;
}

.order-stores__filter-button.isActive {
  color: var(--color-online-order-settings-active-colour);
}

.order-stores__filter-button > span {
  text-transform: none;
}

.order-stores__filter-list-map-button {
  display: flex;
  align-items: center;
  align-content: center;
}

.order-stores__stores-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.order-stores__stores-container-stadium {
  padding-bottom: 57px;
}

.order-stores__stores-container-map {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 100%;
}

.order-stores__stores-container::-webkit-scrollbar {
  display: none;
}

/** Order Store info Card Styling **/
.order-stores__store-wrapper {
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.order-stores__store-wrapper:not(:first-child) {
  border-top: 1px solid var(--color-gray-light);
}

.order-stores__store-wrapper-dinein {
  padding: 0;
}

.order-stores__store-wrapper.order-stores__store-wrapper-dinein {
  min-height: unset;
}

.order-stores__store-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

.order-stores__store-column {
  display: flex;
  flex-direction: column;
  justify-self: center;
  max-width: 50%;
  min-width: 50%;
}

.order-stores__store-name {
  font-weight: 700;
}

.order-stores__store-address {
  color: #2a2a2a;
  font-size: 14px;
  margin-top: 0;
}

.order-stores__store-distance {
  color: var(--color-gray);
  font-size: 14px;
  margin-bottom: 3px;
}

.order-stores__store-closing {
  color: #2a2a2a;
  font-weight: 450;
  font-size: 14px;
}

.order-stores__store-hours-button,
.order-stores__store-direction-button {
  color: var(--color-secondary);
  font-weight: 500;
  font-size: 13px;
}

.order-stores__store-select-button.button--primary.button--primary-with-border {
  margin: 0 auto;
  width: unset;
  max-width: 100%;
  float: right;
  padding: 0.8em 1em;
  white-space: break-spaces;
}

.order-stores__store-info-wrapper {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1150;
}

.order-stores__store-info-header-container {
  width: 100%;
}
.order-stores__store-info-header-top {
  width: 100%;
  box-shadow: 0 2px 14px -2px rgb(0 0 0 / 77%);
  background-color: #fff;
  padding: 10px 20px;
  margin-top: 0;
  margin-bottom: 0;
}

.order-stores__store-info-header-top > h3 {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  margin: 0;
}

.order-stores__store-info-header-top > button {
  width: 40px;
  height: 40px;
}

.order-stores__store-info-header-bottom {
  margin: 0;
  width: 100%;
  background-color: var(--color-header-background-colour);
  padding: 10px 0;
  border: 1px solid var(--color-gray-light);
  border-left: none;
  border-right: none;
}

.order-stores__store-info-line {
  width: 100%;
  padding: 10px 24px;
  justify-content: flex-start;
}

.order-stores__store-info-line > span,
.order-stores__store-info-line > a {
  font-size: 14px;
  margin-left: 10px;
}

.order-stores__store-info-line__phone > a {
  color: var(--color-secondary);
  font-weight: 500;
}

.order-stores__store-info-line__hours {
  align-items: flex-start;
}

.order-stores__store-info-line__hours > tbody {
  margin-left: 10px;
  width: 100%;
  padding-right: 10px;
}

.order-stores__store-info-line__hours > tbody > tr {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.order-stores__store-info-line__actions > .order-stores__store-column {
  align-items: flex-start;
}

.order-stores__store-info-line__actions > .order-stores__store-column > span {
  font-size: 14px;
  margin-bottom: 5px;
}

.order-stores__store-info-line > .icon {
  width: 24px;
  height: 24px;
}
/** End of Order Store info Card Styling **/

.order-stores__communication-blurb {
  padding: 0 24px;
  font-size: 16px;
  line-height: 1.5;
}

.order-stores__store-info-inner-wrapper > .order-stores__store-info-header-container {
  max-width: 100%;
}

div > .button--primary-order-store-filter:first-child {
  margin-right: -40px;
}

.button--primary-order-store-filter {
  height: 35px;
}

.order-store__map-selected-store {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  animation: fadeIn 0.3s;
  z-index: 1000;
}

.order-stores__fav-button {
  position: relative;
  width: 24px;
  height: 24px;
}

.delivery-address-detail-container.flex__column {
  width: unset;
}

.delivery-address-detail-container.flex__column > p {
  margin: 0;
}

.delivery-address-detail-container.flex__column > p:first-child {
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
}

.delivery-address-detail-container.flex__column > p:last-child {
  margin-bottom: 5px;
}

/** Mobile only **/
@media screen and (max-width: 660px) {
  .order-store__map-selected-store {
    z-index: 1150;
  }
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {


  .order-stores__main-container {
    padding-bottom: 28px;
  }
  .order-stores__address-map-container {
    min-height: 30vh;
  }
  .order-stores__store-wrapper {
    padding: 20px 0px;
  }

  div > .button--primary-order-store-filter {
    padding: 0.5em 5em !important;
    width: unset;
    height: unset;
    min-width: unset;
  }

  .order-stores__filter-container > div button:last-child {
    text-align: center;
  }

  .order-stores__filter-container > div button:first-child {
    text-align: center;
  }

  .order-stores__delivery-filter-container > div {
    justify-content: flex-start;
  }

  .order-stores__stores-container-map {
    height: 100%;
  }

  .delivery_address__confirm-form-and-map {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 3em;
  }

  .delivery_address__confirm-form-and-map > .confirm-address__google-maps-container,
  .delivery_address__confirm-form-and-map > .form--confirm-address {
    width: 48%;
    height: 100%;
  }

  .delivery_address__confirm-form-and-map > .form--confirm-address.full-width {
    width: 100%;
  }

  .form--confirm-address {
    padding-left: 0;
    padding-right: 0;
  }

  .form--confirm-address,
  .form--confirm-address .form__field-wrapper--small:first-child {
    padding-top: 0;
    margin-top: 0;
    margin-left: 0;
  }
  .form--confirm-address .form__field-wrapper--small:nth-child(2) {
    margin-top: 0;
  }

  .order-stores__address-location-wrapper {
    padding: 0;
    padding-left: 24px;
    max-width: 1920px;
  }

  .order-stores__address-location-wrapper > div {
    margin-left: 24px;
    margin-right: 24px;
  }

  .order-stores__address-location-wrapper > div:nth-child(2) {
    border-bottom: 1px solid #d4d4d4;
    width: unset;
  }

  .order-stores__store-select-button.button--primary.button--primary-with-border {
    width: 60%;
    font-size: 15px;
  }

  .order-stores__store-info-wrapper {
    background-color: #00000094;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .order-stores__store-info-inner-wrapper {
    width: 42%;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
  }

  .order-stores__store-info-header-top {
    box-shadow: none;
    justify-content: unset;
    padding-top: 0;
  }

  .mobile-app-header-logo_wrapper {
    width: 20%;
  }

  .order-stores__store-row.x-button-row {
    padding: 10px 15px;
    justify-content: right;
  }

  .order-stores__store-info-line__hours > .order-stores__store-row {
    width: 100%;
    align-items: flex-start;
  }

  .order-stores__store-info-line__hours > .icon--clock {
    margin-top: 10px;
    margin-right: 10px;
  }
  .order-stores__store-column.hours-divider {
    width: 5%;
    display: inline-grid;
    margin-right: 20px;
  }

  .order-stores__store-column.hours-divider > hr {
    width: 1px;
    height: 100px;
    color: var(--color-gray-light);
    margin-right: 20px;
  }

  .order-stores__store-info-line > div {
    display: flex;
    align-items: center;
  }
  .order-stores__store-info-line > div > .icon--map-pin {
    margin-right: 10px;
  }

  .store-address-and-distance {
    justify-content: space-between;
  }

  .order-stores__store-info-line__actions {
    margin-bottom: 30px;
  }

  .order-stores__store-info-header-top > button {
    text-align: left;
  }

  .order-stores__store-info-line,
  .order-stores__store-info-header-top {
    padding-left: 50px;
    padding-right: 50px;
  }

  .order-stores__store-column.right-align {
    align-items: flex-end;
  }

  .order-stores__store-column.first-hours-column {
    border-right: 1px solid var(--color-gray-light);
    margin-right: 10px;
  }

  .order-stores__store-hours-button,
  .order-stores__store-direction-button {
    font-size: 15px;
  }

  .order-stores__address-wrapper {
    width: 50%;
  }

  .order-stores__address-wrapper.order-stores__address-wrapper-absolute {
    width: 35%;
  }
  .order-stores__communication-blurb {
    padding: 0;
  }

  .form--confirm-address .form__field-wrapper--checkbox {
    max-width: 50%;
    display: block;
  }
  .order-stores__use-location-button > .icon--target {
    padding-left: 0;
  }
}

@media screen and (min-width: 660px) and (max-width: 1080px) {
  .order-stores__store-info-inner-wrapper {
    width: 80%;
  }
}

@media screen and (min-width: 1081px) {
  .order-stores__store-info-inner-wrapper {
    width: 50%;
  }
}
