.FAB-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 25px;
  z-index: 1000;
}

.FAB-wrapper__item-details-page {
  bottom: 57px;
  padding-bottom: 9px;
  padding-top: 9px;
  background-color: var(--color-online-order-settings-background);
  min-height: 86px;
  display: flex;
  align-items: center;
  z-index: 1005;
}

.FAB-wrapper__item-details-page.wizard {
  bottom: 0px;
}

.FAB--add-to-order__with-cart-wrapper > .desktop-container {
  width: 75%;
}

.FAB-wrapper__item-details-page + .order-settings__cart-button {
  position: fixed;
  bottom: 77px;
  right: 10px;
  width: 50px;
  height: 50px;
  z-index: 1001;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  animation-delay: 500ms;
}

.FAB-wrapper__item-details-page.wizard + .order-settings__cart-button {
  bottom: 20px;
}

.FAB-wrapper__item-details-page.centered {
  justify-content: center;
}

.FAB-wrapper__item-details-page + .order-settings__cart-button .cart-indicator__counter {
  left: -4px !important;
}

.FAB-wrapper__item-details-page > .FAB-wrapper__gradient {
  display: none;
}

.FAB-wrapper__addMoney {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 3em;
  z-index: 1100;
}

.FAB-wrapper__addMoney .FAB__error {
  bottom: calc(52px / 2 + 45px);
}

.FAB-wrapper__gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 127px;
  background-image: linear-gradient(to top, #fff 0%, #fff 20%, rgba(255, 255, 255, 0) 100%);
  transition: back;
  pointer-events: none;
}

.FAB-wrapper__gradient:after {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, #fff 0%, #fff 80%, rgba(255, 255, 255, 0) 100%);
  transition: opacity 0.3s;
  pointer-events: none;
}

.FAB-wrapper__gradient--expanded:after {
  opacity: 1;
}

.FAB {
  position: relative;
  display: block;
  width: 70%;
  height: 52px;
  margin: 0 auto;
  padding: 0 15px;
  color: var(--color-button-text);
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  background-color: var(--color-primary);
  border-radius: 9999px;
  z-index: 1;
}

.FAB-wrapper--modal {
  z-index: 1100;
}

.FAB-wrapper--modal.update_account_info__floating-button,
.FAB-wrapper--modal.change-password__floating-button {
  z-index: 1000;
}
.FAB-wrapper--modal.change-password__floating-button,
.FAB-wrapper--modal.update_account_info__floating-button {
  z-index: 1152;
}
.FAB--large {
  width: 90%;
}

.FAB--left-aligned {
  margin-left: 30px;
}

.FAB__error {
  opacity: 0;
  position: absolute;
  bottom: calc(52px / 2 + 35px);
  transform: translateY(0);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ff2020;
  font-size: 14px;
  text-align: center;
  animation: showFABError 0.3s forwards;
}

@media screen and (max-width: 660px) {
  .FAB-wrapper__item-details-page + .order-settings__cart-button {
    z-index: 1005;
  }
}

@media screen and (min-width: 660px) {
  /*.FAB-wrapper__item-details-page {
    bottom: 57px;
    padding-bottom: 9px;
    padding-top: 9px;
    background-color: var(--color-online-order-settings-background);
    min-height: 45px;
    max-height: 50px;
    display: flex;
    align-items: center;
  }

  .FAB {
    width: 100%;
    height: 52px;
    margin: 0 auto;
    padding: 0 15px;
  }
  .FAB--add-to-order__with-cart {
    margin-left: 0%;
    width: 68%;
    transition: width 0.5s;
    white-space: normal;
    word-wrap: break-word;
  }*/
  .FAB__error {
    bottom: calc(52px / 2 + 50px);
  }

  .desktop-menu__menu-combo-summary + .FAB__error {
    position: fixed;
    bottom: calc(52px / 2 + 70px);
  }

  .FAB-wrapper__item-details-page.wizard + .order-settings__cart-button {
    bottom: 0px;
  }

  .FAB-wrapper__addMoney .FAB__error {
    bottom: calc(52px / 2 + 45px);
    display: flex;
    justify-content: flex-end;
    padding-right: calc(50px + 6em) !important;
  }
}

@keyframes showFABError {
  100% {
    opacity: 1;
    transform: translateY(calc(-52px / 2 - 10px));
  }
}

.FAB__error--closing {
  animation: hideFABError 0.3s forwards;
}

@keyframes hideFABError {
  0% {
    opacity: 1;
    transform: translateY(calc(-52px / 2 - 10px));
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

.FAB__success {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /*color: #fff;*/
  clip-path: circle(0 at center);
  border-radius: 50px;
  animation: expandBackground 0.5s forwards;
  z-index: 2;
}

@keyframes expandBackground {
  100% {
    clip-path: circle(400px at center);
  }
}

.FAB__success > .icon--check {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 18px;
  margin: auto 0;
  width: 18px;
  height: 18px;
  color: var(--color-button-text-disabled);
}

.FAB--add-to-order,
.FAB-wrapper__combo-add-to-choice button {
  color: var(--color-add-to-order-button-text);
  background-color: var(--color-add-to-order-button);
  margin: 0 auto;
  font-size: 14px;
  white-space: normal;
  word-wrap: break-word;
}

.FAB-wrapper__combo-save-changes {
  bottom: 0;
}

.save-changes-FAB {
  bottom: 0;
  background-color: var(--color-online-order-settings-background);
  padding: 1em 0;
}

.save-changes-FAB > .FAB-wrapper__gradient {
  display: none;
}

@media screen and (min-width: 660px) {
  .FAB--add-to-order {
    font-size: 17px;
  }
  .desktop-container > .FAB--add-to-order__with-cart {
    /*margin-left: 0;*/    
  }

  .save-changes-FAB {
    background-color: var(--color-online-order-settings-background);
    padding: 1em 0;
    bottom: 28px;
  }

  .save-changes-FAB > .FAB-wrapper__gradient {
    display: none;
  }

  .save-changes-FAB .FAB--modal-level-2 {
    width: unset;
    min-width: unset;
    flex: unset;
    margin: 0;
    padding: 0 4em;
  }

  .save-changes-FAB > .desktop-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .FAB-wrapper__item-details-page.wizard + .order-settings__cart-button {
    position: absolute;
    bottom: 18px;
    right: 28px;
    z-index: 1005;
  }

  .FAB-wrapper__item-details-page.wizard > .desktop-container > .order-settings__cart-button {
    position: absolute;
    bottom: 0px;
    top: 0;
    right: 24px;
    z-index: 1005;
    width: 50px;
    height: 50px;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    animation-delay: 500ms;
    background-color: transparent;
  }

  .FAB-wrapper__item-details-page.wizard
    > .desktop-container
    > .order-settings__cart-button
    .cart-indicator__counter {
    left: -4px !important;
  }

  .FAB-wrapper__combo-add-to-choice > .FAB__error,
  .FAB-wrapper__item-details-page > .FAB__error,
  .FAB-wrapper.save-changes-FAB > .FAB__error {
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 53%, rgba(255, 255, 255, 0) 100%);
    padding: 2.5em 1em 1em;
    bottom: 50px;
  }
}

/** Mobile specific **/
@media screen and (max-width: 660px) {
  .FAB-wrapper__combo-add-to-choice > .desktop-container {
    width: 100%;
  }

  .FAB-wrapper__item-details-page > .desktop-container {
    width: 100%;
  }

  .FAB--combo-confirm-choices-wrapper {
    bottom: 0;
    justify-content: center;
  }

  .FAB--add-to-order__with-cart-wrapper > .desktop-container {
    width: 75%;
  }

  .FAB-wrapper__combo-add-to-choice > .FAB__error,
  .FAB-wrapper__item-details-page > .FAB__error,
  .FAB-wrapper.save-changes-FAB > .FAB__error {
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 64%, rgba(255, 255, 255, 0) 100%);
    padding: 2.5em 1em 1em;
    bottom: 50px;
  }

}

.FAB--add-to-order__with-cart {
  margin-left: 5%;
  width: 68%;
  transition: width 0.5s;
  white-space: normal;
  word-wrap: break-word;
}

.FAB--modal-level-2 {
  white-space: normal;
  word-wrap: break-word;
}
