.cart-content-desktop {
  /*padding-top: 10px;*/
}

.cart-summary__container {
  display: flex;
  flex-shrink: 0; /* Fixes cropping on iOS */
  justify-content: space-between;
  width: 100%;
  /*margin-bottom: 10px;*/
  padding: 10px 30px 20px 30px;
  background-color: white;
}

.cart-summary__cart-column .cart-indicator {
  margin-right: 8px;
}

.cart-summary__line {
  font-size: 16px;
  line-height: 100%;
  display: flex;
  justify-content: space-between;
}

.cart-summary__label-service-charge-label {
  max-width: 50%;
  word-wrap: break-word;
}

.cart-summary__line + .cart-summary__line {
  margin-top: 10px;
}

.button--white {
  display: flex;
  align-items: center;
  font-size: 0.7em;
  margin-right: 0 !important;
}

.button--add-promo {
  margin-top: 15px;
  width: 100%;
}

.cart-summary__cart-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin-right: 10%;
}

.cart-summary__cart-column > button {
  white-space: normal;
}

.cart-summary__totals-column {
  position: relative;
  width: 50%;
}

.cart-summary__note {
  font-style: italic;
  font-size: 13px;
}

.cart-summary__line--total {
  padding-top: 10px;
  margin-top: 15px;
  border-top: 1px solid #ccc;
  font-size: 19px;
  font-weight: 600;
  text-transform: uppercase;
}

.cart-summary__line--coupon {
  padding-top: 10px;
  margin-top: 15px;
  border-top: 1px solid #ccc;
}

.cart-summary__line--coupon + .cart-summary__line--total {
  padding-top: 0;
  border: none;
}

.cart-summary__discount-value {
  color: #ff2020;
}

.modal-wrapper--apply-discounts .modal__heading {
  font-size: 18px;
}

.modal-wrapper--apply-discounts {
  overflow-y: auto;
  border-top: 5px solid #d64123;
}

.modal-wrapper--apply-discounts .modal--fullscreen {
  padding-top: 40px;
  overflow-y: auto;
}

.button--use-rewards {
  margin-top: 15px;
  justify-content: center;
  width: 100%;
}

.use-rewards__icon--star {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  color: var(--color-button-text);
}
.cartSummaryWrapper{
  display: flex;
  width: 100%; 
  flex-direction: column;
}
/** Dektop Layout **/

@media screen and (min-width: 850px) {
  .cart-summary__container {
    padding: 2em;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .cart-summary__cart-column > button:first-child {
    margin-top: 0;
  }

  .cart-summary__line {
    font-size: 1.2em;
  }

  .modal--coupon-discount {
    z-index: 1005;
  }
}

@media screen and (max-width: 950px) {
  .cart-summary__line {
    font-size: 1em;
  }
}
/** End of Desktop Layout **/
