.guest-giftcard__balance-summary {
  margin-bottom: 18px;
  text-align: right;
}

.guest-giftcard__balance-summary > * + * {
  margin-top: 16px;
}

.guest-giftcard__applied-cards {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 44px;
  padding-left: 0;
  line-height: 100%;
}

.guest-giftcard__value-column {
  display: inline-block;
  min-width: 53px;
}

.guest-giftcard__balance-summary + .button--intermediary-submit {
  margin-top: 18px;
}

.guest-giftcard__post-apply-total-wrapper {
  padding-top: 16px;
  color: #058000;
  font-weight: 600;
  border-top: 1px solid #ccc;
}

.guest-giftcard__post-apply-total--remaining {
  color: #ff2020;
}

.guest-giftcard-remaining {
  padding-top: 18px;
  border-top: 1px solid #ccc;
}

.guest-giftcard-remaining__instruction {
  margin-top: 0;
  margin-bottom: 18px;
  font-size: 0.9rem;
  text-align: center;
}

.button--remove-gift-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: auto 0;
  background-color: #ececec;
  border-radius: 50%;
}

.button--remove-gift-card > .icon--x {
  width: 14px;
  height: 14px;
  stroke-width: 3px;
  color: #757575;
}

.guest-giftcard__remainder-wrapper {
  font-size: 0.8rem;
  max-width: 100%;
}

.guest-giftcard__name {
  margin-bottom: 4px;
  letter-spacing: 0.5px;
  font-size: 15px;
}

.button--giftcard-remainder-option {
  display: flex;
  align-items: center;
  min-height: 24px;
  margin-bottom: 18px;
  font-size: 0.9rem;
  text-decoration: underline;
}

#form--apply-giftcard + .button--giftcard-remainder-option {
  margin-top: 36px;
}

.button--giftcard-remainder-option > .icon {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.guest-giftcard__order-total-wrapper {
  font-weight: 600;
}

.guest-giftcard__applied-card-row {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.guest-giftcard__applied-card-row + .guest-giftcard__applied-card-row {
  margin-top: 18px;
}

.gift-card__row_contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
