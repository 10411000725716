.mobile-hamburger-menu-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 30px 30px 5em 30px;
  z-index: 1150;
  overflow-y: scroll;
}

.mobile-hamburger-menu-x-icon {
  margin-bottom: 36px;
}

.mobile-hamburger-menu-x-icon .icon--x {
  color: #2f2f2f;
}

.footer-subnav__list.mobile-hamburger-menu {
  position: relative;
  background: transparent;
  border-radius: 5px;
  bottom: unset;
  margin: 0;
  margin-top: 1em;
  width: 100%;
  height: 100%;
  padding: 0;
  z-index: 1000;
}

.footer-subnav__list.mobile-hamburger-menu .footer__subnav-link-text {
  border: none;
}

.mobile-hamburger-menu-button {
  width: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
}
