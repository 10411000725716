.item-detail__quantity-container {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
}

.item-detail__quantity-buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4px 5px 10px 5px;
  align-items: center;
}

.item-detail__quantity-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: var(--color-primary);
  border-radius: 50%;
  transition: background 0.1s;
}

.item-detail__quantity-button:active {
  background: none;
  background-color: #2a2a2a;
}

.item-detail__quantity-button > .icon {
  stroke-width: 2px;
  transition: color 0.1s;
  stroke: var(--color-button-text);
}

.item-detail__quantity-button:active > .icon {
  color: white;
}

.item-detail__quantity-button:disabled {
  opacity: 1;
  background-color: #fff;
  border: 1px solid var(--color-gray-light);
}

.item-detail__quantity-button:disabled > .icon {
  stroke: var(--color-gray-light);
}

.item-detail__quantity-input {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--color-gray-light);
  border-radius: 0;
  text-align: center;
  font-size: 1.2rem;
  padding-bottom: 5px;
  width: 50%;
  margin-bottom: 25px;
}

/** Mobile Specific Design **/

@media screen and (max-width: 660px) {
  .item-detail__quantity-buttons-container {
    background-color: #fff;
    padding: 1em 7em;
  }

  .item-detail__quantity-container {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .item-detail__quantity-container > .item-detail__options-subheading {
    margin: 0;
  }
}

/** Desktop layout **/
@media screen and (min-width: 660px) {
  .item-detail__quantity-container {
    margin-top: 2em;
  }

  .item-detail__quantity-buttons-container {
    width: 50%;
    margin: 0 auto;
  }

  .item-detail__quantity-header {
    margin: 0;
    font-size: 1em;
    font-weight: 600;
  }

  .item-detail__quantity-container {
    padding-left: 0;
    padding-right: 0;
  }
  .item-detail__quantity-buttons-container {
    margin-top: 1em;
    padding-left: 15%;
    padding-right: 15%;
  }
}
/** end of desktop layout **/
