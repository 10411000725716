.dashboard-giftcard-buttons-section {
  padding-right: 20px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.dashboard-giftcard-buttons-section .dashboard-action-button:not(:last-child) {
  margin-bottom: 10px;
}

.dashboard-giftcard-buttons-section .dashboard-action-button:not(:last-child) {
  margin-right: 1em;
}

@media screen and (min-width: 660px) {
  .dashboard-giftcard-buttons-section .dashboard-action-button:not(:last-child) {
    margin-bottom: 0;
  }

  .dashboard-giftcard-buttons-section {
    padding-right: 0;
    flex-direction: row;
    justify-content: space-between;
  }
}
