/* Global app styles */
* {
  box-sizing: border-box;
}

:root {
  --color-button-text-disabled: #dadae0;
  --color-disabled-background: #efefef;
  --color-error: #ff2020;
}

html,
body,
#root {
  height: 100%;
}

#root {
  background-color: #fff;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  color: #2a2a2a;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #2a2a2a; /* visually hide the notch */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent; /* prevent iOS from dimming interactive elements on touch */
  -webkit-touch-callout: none; /* disable default callouts */
}

/* Prevents iPhone auto-zoom-in on input field focus */
.body--iOS input,
.body--iOS select,
.body--iOS textarea {
  font-size: 100% !important;
}

.main-content {
  position: relative;
  display: block; /* for older IE */
  flex: 1 0; /* for flex-grow to work on iOS  */
  padding-bottom: 57px; /* App footer's height */
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}

/* TEMP selector */
.main-content--iframe-wrapper {
  height: calc(100vh - 71px - 57px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* enables iOS "momentum" style scrolling */
  z-index: 1;
}

.main-content--with-FAB {
  padding-bottom: calc(
    52px + 25px + 25px
  ); /* button height + distance from screen bottom + space above button */
}

.iframe--cws4 {
  border: none;
}

.space-between-wrapper {
  display: flex;
  justify-content: space-between;
}

.space-between-wrapper--center-aligned {
  align-items: center;
}
/* END OF TEMP selector */

.main-content--short-footer {
  padding-bottom: 70px;
}

/* Links */
a {
  color: #2a2a2a;
  text-decoration: none;
  background-color: transparent; /* removes gray background on active links in IE 10 */
}

.rtb-container a {
  text-decoration: underline;
}

.link {
  text-decoration: underline;
}

.link--small {
  display: inline-block;
  margin-top: 10px;
  color: #757575;
  font-size: 0.8rem;
}

.link--border {
  display: inline-block;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 100%;
  border: 1.2px solid var(--color-secondary);
  border-radius: 50px;
}
/* END OF Links */

b,
strong {
  font-weight: bolder; /* corrects font weight in Chrome, Edge, and Safari */
}

small {
  font-size: 80%; /* corrects font size in all browsers */
}

/* Buttons */
button,
select {
  color: #2a2a2a;
}

/* browser default reset */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

button,
input {
  overflow: visible; /* show the overflow in IE & Edge */
}

button,
select {
  text-transform: none; /* Remove text transform inheritance in Edge, Firefox, and IE */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; /* corrects the inability to style clickable types in iOS and Safari */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Remove the inner border and padding in Firefox */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; /* restores the focus styles unset by the previous rule */
}

button:disabled {
  pointer-events: none;
  cursor: unset;
  color: #dadae0;
  background-color: #efefef;
  border: none;
}

button,
.button {
  user-select: none; /* prevent text selection */
  white-space: nowrap;
  background: none;
  border: none;
}

.button--primary {
  position: relative;
  padding: 1em;
  color: var(--color-button-text);
  line-height: 100%;
  background-color: var(--color-primary);
  border-radius: 9999px;
  font-weight: 600;
  text-transform: uppercase;
  width: 75%;
  font-size: 1em;
}

.button--secondary {
  border: 2px solid var(--color-secondary);
  background-color: #ffffff;
  color: var(--color-secondary);
  font-weight: 600;
  text-transform: uppercase;
}

.button--primary-with-border {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: none;
  border: 2px solid var(--color-secondary-button-border);
  background-color: #ffffff;
  color: var(--color-secondary-button-text);
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 600;
}

.button--primary-with-border:active {
  background-color: var(--color-active-button-generic-background);
}

.button--primary-small {
  padding: 0.8em 0.5em;
  font-size: 0.8em;
}

.button-container--single-bottom {
  position: fixed;
  left: 0;
  bottom: 3.5em;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 50px 0 24px 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.23573179271708689) 100%
  );
}

.button-container--multiple-bottom {
  flex-direction: column;
  align-items: center;
}

.button-container--multiple-bottom > *:first-child {
  margin-bottom: 10px;
}

.button-container--single-relative {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}

.button-container--single-relative {
  display: flex;
  justify-content: center;
  position: relative;
}

.bottom-padding {
  padding-bottom: 235px !important;
}

a.button--primary {
  text-align: center;
}

/* FIXME: this needs to go asap */
div > .button--primary-small:first-child {
  margin-right: 10px;
}

.button--primary-with-border > svg {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  color: #2a2a2a;
}

/*.button--secondary {
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid #2a2a2a;
  border-radius: 8px;
}*/

.button--pill-shaped {
  border-radius: 9999px;
}

.button--form-next {
  display: block;
  width: 214px;
  margin: 0 auto;
  font-weight: 600;
  text-transform: uppercase;
}

.button--add-to-favorite {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin: 0 auto;
}

.button--section-header,
.link--section-header {
  font-size: 0.8em;
  font-weight: 600;
}
/* END OF Buttons */

/* Form elements */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /* Override for Chrome's input autofill background color */
  box-shadow: 0 0 0 30px rgb(255, 255, 255) inset !important;
}

textarea {
  appearance: none;
  overflow: auto; /* remove default vertical scrollbar in IE 10+ */
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0.01em 0 0 0;
  border: 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

legend {
  display: table;
  margin: 0;
  padding: 0;
  width: 100%;
}
/* END OF Form elements */

/* Icons */
svg {
  color: #2a2a2a;
}

svg {
  pointer-events: none;
}
/* END OF Icons */

.heading-with-icon {
  display: flex;
  align-items: center;
}

.heading-with-icon > * + * {
  margin-left: 8px;
}

/* Images */
img {
  max-width: 100%;
  height: auto;
  border-style: none; /* removes border on images inside links in IE 10 */
}
/* END OF Images */

/* RTB Container - Text */
.rtb-container p {
  font-size: 1rem;
  line-height: 1.7;
}

.rtb-container h1:first-of-type,
.rtb-container h2:first-of-type,
.rtb-container h3:first-of-type,
.rtb-container h4:first-of-type,
.rtb-container h5:first-of-type,
.rtb-container h6:first-of-type,
.rtb-container p:first-of-type {
  margin-top: 0;
}
/* END OF RTB Container - Text */

/* Shadows */
.elevation--far {
  box-shadow: 0 8px 10px rgba(2, 10, 6, 0.2);
}

.elevation--mid {
  box-shadow: 0 5px 14px 2px rgba(0, 0, 0, 0.21);
}

.elevation--close {
  box-shadow: 0 5px 10px rgba(2, 10, 6, 0.4);
}

.curved-shadow-container {
  position: relative;
}

.curved-shadow-container:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 10px;
  border-bottom-left-radius: 100px 10px;
  border-bottom-right-radius: 100px 10px;
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.1);
}

.curved-shadow-overlay-hack {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  pointer-events: none;
  z-index: 1;
}

.curved-shadow-overlay-hack ~ *,
.curved-shadow-overlay-hack ~ * > * {
  z-index: 2;
}
/* END OF Shadows */

/* Misc */

/* Loading Spinner */
.loading-spinner {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 45px;
  height: 45px;
  border: 4px solid #ddd;
  border-top: 4px solid var(--color-primary);
  border-radius: 50%;
  animation: spinLoader 1s linear infinite;
  z-index: 1000;
}

.loading-spinner--default {
  border: 4px solid #ddd;
  border-top: 4px solid #757575;
  animation: spinDefaultLoader 1s linear infinite;
}

@keyframes spinLoader {
  100% {
    border-top-color: var(--color-primary);
    transform: rotate(360deg);
  }
}

@keyframes spinDefaultLoader {
  100% {
    border-top-color: #757575;
    transform: rotate(360deg);
  }
}
/* END OF Loading Spinner */

/* Skeleton Loading */
.skeleton-loader {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: skeletonPulse 1.2s ease-in-out infinite;
}

@keyframes skeletonPulse {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: -135% 0%;
  }
}

.skeleton-loader__line {
  width: 100%;
  border-radius: 5px;
}

.skeleton-loader__line:before {
  content: "\00a0"; /* empty content to let loader element inherit line-height */
}
/* END OF Skeleton Loading */

body.modal--open {
  overflow-y: hidden;
}

.scroll-indicator {
  position: sticky;
  left: 0;
  bottom: 0;
  pointer-events: none;
}

.scroll-indicator:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 60px;
  background: linear-gradient(to top, #2a2a2a38, transparent);
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.hide-element{
  display: none !important; 
}
::selection {
  color: #2a2a2a;
  background: rgba(0, 134, 255, 0.09);
}
/* END OF Misc */

/* Marqui */
.content-from-marqui-rtb {
  animation: fadeIn 0.2s forwards;
}

.content-from-marqui-rtb p {
  margin-top: 0;
  font-size: 13px;
  line-height: 1.6;
}

.content-from-marqui-rtb ul {
  padding-left: 16px;
}

.content-from-marqui-rtb a {
  text-decoration: underline;
}

.additional-disclaimer {
  width: 100%;
  margin: 1em auto 2em;
  color: #2a2a2a !important;
  font-size: 0.75rem !important;
  line-height: 1.5 !important;
  font-weight: unset !important;
}

.disabled_order_button {
  opacity: 0.35;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.flex__column {
  display: flex;
  flex-direction: column;
  width: 49%;
}

.full-width-column {
  width: 100%;
}

.space-between {
  justify-content: space-between;
}

.flex-align-start {
  align-items: flex-start;
}

/* Global Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  100% {
    opacity: 0;
  }
}
/* END OF Global Animations */

.full__width {
  width: 100%;
}

.blue-link-button {
  color: var(--color-secondary) !important;
  text-decoration: none !important;
  font-size: 16px;
  font-weight: 600;
}

.icon--star {
  fill: #f49c00;
  stroke: none;
  width: 20px;
  height: 20px;
}

.footer__nav-link > .icon--star {
  fill: none;
}

@media screen and (min-width: 660px) {
  .main-content {
    overflow-x: visible;
    overflow-y: visible;
  }

  .desktop-container {
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }

  .additional-disclaimer {
    font-size: 1em !important;
  }

  .main-content .additional-disclaimer {
    max-width: 1200px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .desktop-container .additional-disclaimer {
    padding-left: 0;
    padding-right: 0;
  }

  .desktop-container {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }

  .desktop-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 0px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 0.8em;
  }

  .desktop-footer__wrapper {
    display: flex;
    justify-content: space-between;
  }

  .desktop-footer__nav-list {
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -0.2em 1em -0.2em -0.75em;
    padding-left: 0;
  }

  .desktop-footer__nav-list > li {
    margin: 0.2em 0.75em;
  }

  .desktop-footer__link {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
  }

  .desktop-footer__link:hover {
    text-decoration: underline;
  }

  .desktop-footer__app-version-wrapper {
    font-size: 0.8em;
    text-align: right;
    white-space: nowrap;
  }
}

@media screen and (min-width: 1280px) {
  .desktop-footer {
    z-index: 1;
  }

  .button--primary {
    min-width: 30%;
  }

  .button-container--single-desktop {
    position: relative;
    display: flex;
    justify-content: flex-end;
    background: none;
    left: 0;
    bottom: 0;
  }

  p {
    font-size: 1em;
  }

  .desktop-page-header-section {
    padding-top: 5%;
  }

  .desktop-page-header-container {
    position: relative;
    margin-bottom: 2.8%;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .desktop-page-header {
    margin: 0;
    font-size: 1.5em;
    font-weight: 500;
    line-height: 100%;
    padding-left: 0;
  }
}

@media screen and (max-width: 660px) {
  .content-from-marqui-rtb {
    padding: 2em 24px 2em;
  }
}
