.payment-content {
  /*padding: 25px;*/
}

.payment-methods-container {
  padding: 25px;
}
.payment-summary {
  margin-top: 1em;
  margin-bottom: 28px;
}

.payment-content .additional-disclaimer {
  padding-left: 0;
  padding-right: 0;
}

.payment-summary__row {
  text-align: center;
}

.payment-summary__label,
.payment-summary__output {
  font-weight: 700;
  font-size: 18px;
}

.payment-summary__label {
  display: inline-block;
  margin: 0 15px 0 0;
  text-transform: uppercase;
}

.payment-methods {
  transform: scaleY(0);
  transform-origin: top center;
  position: relative;
  min-height: 100%;
  margin: 0 0 36px;
  padding-left: 0;
  list-style-type: none;
  animation: expandMethods 0.5s forwards;
}

.payment-methods--collapsed {
  animation: collapseMethods 0.5s forwards;
}

.expanded-payment-method__footer {
  display: flex;
  justify-content: center;
  margin-top: 36px;
  padding-top: 18px;
  border-top: 1px solid #efefef;
}

.expanded-payment-method__footer-button {
  display: flex;
  align-items: center;
  color: var(--color-secondary);
  font-size: 0.9rem;
  font-weight: 500;
}

.expanded-payment-method__footer-button > .icon {
  width: 13px;
  height: 13px;
  margin-right: 4px;
  stroke: var(--color-secondary);
}

.expanded-payment-method__footer-button > .icon--credit-card {
  fill: var(--color-secondary);
}

.button--apple-pay {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 51px;
  padding: 0 10px;
  text-transform: capitalize;
  background-color: #1a202c;
  border-radius: 3px;
  box-shadow: 0 0 10px 1px rgba(2, 10, 6, 0.15);
}

@keyframes expandMethods {
  to {
    transform: scaleY(1);
  }
}

@keyframes collapseMethods {
  to {
    transform: scaleY(1);
  }
}

.icon.icon--paypal2 {
  width: 145px;
  margin-top: 1em;
}

@media screen and (min-width: 660px) {
  .payment-content {
    max-width: 50%;
    margin: 2em auto 0;
  }
  .icon.icon--paypal2 {
    margin-top: 1.5em;
  }

  .google-pay-checkout-button__container .gpay-button-fill {
    padding-right: 24px;
    padding-left: 24px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    display: flex;
    justify-content: center;
  }
}

.paypal-button__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.paypal-button__container > p {
  text-align: center;
}

.paypal-button__continue-button {
  margin-top: 1em;
}

.google-pay-button__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.google-pay-button__container > p {
  text-align: center;
}

.google-pay-checkout-button__container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 25px;
  z-index: 1000;
}

.google-pay-button-container {
  position: relative;
  display: block !important;
  width: 70%;
  height: 52px;
  margin: 0 auto;
  z-index: 1;
}

.apple-pay-button__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.apple-pay-button__container > p {
  text-align: center;
}

.apple-pay-button__container .icon--apple-pay {
  padding-top: 5px;
}

.apple-pay-checkout-button__container {
  width: 100%;
  max-width: 1200px;
  height: 100px;
  position: fixed;
  display: flex;
  bottom: 0;
  padding-bottom: 25px;
  z-index: 1000;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.apple-pay-button {
  width: 50%;
  height: 52px;
  bottom: 25px;
  z-index: 1;
  box-sizing: border-box;
  padding: 0px 0px;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: check-out; /* Use any supported button type. */
}

.apple-pay-button-black {
  -apple-pay-button-style: black;
  margin: 0 auto;
}

.apple-pay-button-white {
  -apple-pay-button-style: white;
}

.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}

.form--credit-card__submit-button button {
  max-width: 100%;
  min-width: 70%;
  white-space: normal;
  word-wrap: break-word;
  padding: 0.8em 0.5em;
}

.form--credit-card__submit-button button > .FAB__success {
  white-space: normal;
  word-wrap: break-word;
}

#form--stored-credit-card > .form__field-wrapper:first-child {
  margin-top: 2em;
}
