.login__form-wrapper {
  background-color: #fff;
  animation: fadeIn 0.3s forwards;
}

.login-with-temp-password__heading,
.change-temp-password__heading {
  position: relative;
  margin: 0px;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
}

.form--login__submit-button-wrapper {
  display: flex;
  justify-content: center;
}
#form--login__submit-button {
  /* width: unset;
  max-width: unset;
  min-width: 75%; */
  white-space: normal;
  word-wrap: break-word;
}

#form--login__submit-button > .button--submit-form__success {
  white-space: normal;
  word-wrap: break-word;
}

.login-with-temp-password__wrapper,
.change-temp-password__wrapper {
  padding: 4em 2em 2em 2em;
}

@media screen and (min-width: 660px) {
  #form--login .form__field-wrapper--checkbox {
    align-items: unset;
  }

  .login-with-temp-password__wrapper,
  .change-temp-password__wrapper {
    justify-content: center;
    max-width: 60%;
    min-width: 60%;
    border-radius: 10px;
    border: 1px solid var(--color-gray-light);
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    padding: 4em;
    height: auto;
    background-color: #fff;
  }
}

@media screen and (min-width: 1028px) {
  .login-with-temp-password__wrapper,
  .change-temp-password__wrapper {
    max-width: 45%;
    min-width: 45%;
    padding: 4em;
  }
}
