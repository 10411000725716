.address-suggestion-list_wrapper {
  list-style-type: none;
  padding: 0;
  background-color: #fff;
  margin-top: 0;
  position: absolute;
  z-index: 1100;
  top: 2.5em;
  border: 1px solid var(--color-gray-light);
}

.address-suggestion-list_wrapper li {
  padding: 10px 5px;
  border-bottom: 1px solid var(--color-gray-light);
  display: flex;
  align-content: center;
  font-size: 13px;
  position: relative;
}

.address-suggestion-list_wrapper li:hover {
  background-color: var(--color-gray-light);
}

.address-suggestion-list-item-button {
  position: absolute;
  color: transparent;
  background-color: transparent;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.MuiInput-underline:after {
  border-bottom: 2px solid var(--color-primary) !important;
}

.form--confirm-address {
  padding: 24px 24px 100px 24px;
}

.delivery-address__saved-addresses-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.delivery-address__header-container {
  padding: 0px 24px;
}

.delivery-address__header-container > h4 {
  margin: 24px 0px 0px 0px;
}

.delivery-address__header-your-addresses {
  font-size: 1.17rem;
}
.delivery-address__header-your-addresses + p {
  font-size: 16px;
}
.confirm-address__google-maps-container {
  max-height: 30vh;
}

.confirm-address__google-maps-container-dialog-modal {
  max-height: 30vh;
  position: relative;
  display: flex;
  width: -webkit-fill-available;
  margin-left: -24px;
  margin-right: -24px;
  min-height: revert;
  bottom: 0px;
}

.confirm-address__unverified-address {
  font-size: 0.9rem;
  font-weight: 600;
}

.address-validation__entered-address-radio-container {
  display: flex;
  justify-content: space-between;
}

.MuiFormControl-root {
  display: flex !important;
}

.MuiFormControlLabel-root {
  margin-right: 0px !important;
}

.Mui-focused .icon--search {
  color: var(--color-primary) !important;
}

#google-map-autocomplete-address::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: 10px 10px;
}

@media screen and (min-width: 660px) {
  .delivery-address__form-container > .delivery-address__header-container {
    padding: 0;
  }

  .confirm-address__google-maps-container-dialog-modal #google-map {
    z-index: -1;
  }
}
