.individual-login-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  background-color: #fff;
  align-items: center;
}

.individual-login-button-container.float-right {
  justify-content: right;
}

.individual-login-button-container.no-right-padding {
  padding-right: 0;
}

.individual-login-button,
.individual-register-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-secondary);
  font-weight: 600;
  font-size: 14px;
}

.individual-login-button::after {
  content: "|";
  color: var(--color-secondary);
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 600;
  font-size: 14px;
}

.individual-login-button > .icon {
  background-color: var(--color-secondary);
  border-radius: 50%;
  padding: 3px;
  margin-right: 5px;
  width: 18px;
  height: auto;
}
