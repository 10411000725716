.desktop-header__subnav--mega {
  left: auto;
  transform: none;
  margin-left: 0;
  right: 0;
}

.desktop-header-mega-subnav__column + .desktop-header-mega-subnav__column {
  margin-left: 3em;
}

.desktop-header-mega-subnav__content {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.desktop-header-mega-subnav__content:before {
  left: auto;
  right: 2.5em;
}

.desktop-header-mega-subnav__heading {
  margin: 0 0 0.7em;
  white-space: nowrap;
  font-size: 1.17em;
  border-bottom: 2px solid var(--color-header-navigation-item-border);
  border-radius: 1px;
  padding-bottom: 0.3em;
  color: var(--color-header-text-and-icon-colour);
}

.desktop-header-mega-subnav__list {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.desktop-header-mega-subnav__logout-button {
  min-height: 25px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-weight: 500;
  color: var(--color-header-text-and-icon-colour);
}

.desktop-header-mega-subnav__logout-button {
  transition: opacity 0.2s;
}

.desktop-header-mega-subnav__logout-button:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1125px) {
  /* Mega submenu */
  .desktop-header-mega-subnav__content {
    flex-direction: column;
    padding-top: 1em;
    padding-bottom: 1em;
    border-radius: 0;
    box-shadow: none;
  }

  .desktop-header-mega-subnav__column + .desktop-header-mega-subnav__column {
    margin-left: 0;
    margin-top: 1em;
  }

  .desktop-header-mega-subnav__heading {
    margin-bottom: 0.2em;
    padding-bottom: 0;
    border-bottom: none;
    border-radius: 0;
    text-align: left;
    font-size: 0.95em;
  }

  .desktop-header-mega-subnav__list {
    padding-left: 0.5em;
  }

  .desktop-header-mega-subnav__list > li {
    text-align: left;
  }

  .desktop-header-mega-subnav__logout-button {
    display: block;
    margin-left: auto;
  }
}
