.button--geolocation-wrapper {
  opacity: 0;
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -37%);
  animation: fadeIn 1.5s forwards;
}

.button--geolocation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.button--geolocation-wrapper__main-ring {
  position: absolute;
  display: block;
  width: 48px;
  height: 48px;
  left: -5px;
  border-width: 6px;
  border-style: solid;
  border-radius: 50%;
  animation: pulseGPSButtonDot 1.75s cubic-bezier(0.46, 0.03, 0.52, 0.96) -0.4s infinite;
}

.order-stores__use-location-wrapper-absolute
  > .order-stores__use-location-button
  > .button--geolocation-wrapper__main-ring {
  left: -5px;
}

.button--geolocation-wrapper__inner-ring {
  position: absolute;
  /*top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);*/
  width: 38px;
  height: 38px;
  border: 6px solid hsla(10, 72%, 70%, 1);
  border-radius: 50%;
}

.button--geolocation-wrapper__large-pulse {
  opacity: 0.5;
  display: block;
  width: 72px;
  height: 72px;
  left: -17px;
  border-radius: 50%;
  position: absolute;
  animation: pulseGPSButtonRing 1.75s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.order-stores__use-location-wrapper-absolute
  > .order-stores__use-location-button
  > .button--geolocation-wrapper__large-pulse {
  left: -18px;
}

.button--geolocation > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
}

.map-search-options--minimized .button--geolocation-wrapper {
  top: 18px;
  left: 18px;
  transform: initial;
  margin-top: 0;
  transition: all 1s;
}

.map-search-options--minimized .button--geolocation {
  width: 40px;
  height: 40px;
  min-width: 0;
  min-height: 0;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.map-search-options--minimized .button--geolocation-wrapper__main-ring {
  width: 40px;
  height: 40px;
  border-width: 4px;
  animation: none;
}

.map-search-options--minimized .button--geolocation-wrapper__inner-ring,
.map-search-options--minimized .button--geolocation-wrapper__large-pulse {
  display: none;
}

.button--geolocation-wrapper__main-ring-inline {
  left: -40px;
  bottom: -16px;
}

.button--geolocation-wrapper__inner-ring-inline {
  left: -35px;
  bottom: -11px;
}

.button--geolocation-wrapper__large-pulse-inline {
  left: -52px;
  bottom: -29px;
}

@keyframes pulseGPSButtonRing {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulseGPSButtonDot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

@media screen and (min-width: 660px) {
  .button--geolocation-wrapper__main-ring {
    left: -10px;
  }
  .button--geolocation-wrapper__inner-ring {
    left: -5px;
  }
  .button--geolocation-wrapper__large-pulse {
    left: -22px;
  }
}
