/* Global app styles */
* {
  box-sizing: border-box;
}

:root {
  --color-button-text-disabled: #dadae0;
  --color-disabled-background: #efefef;
  --color-error: #ff2020;
}

html,
body,
#root {
  height: 100%;
}

#root {
  background-color: #fff;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  color: #2a2a2a;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #2a2a2a; /* visually hide the notch */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent; /* prevent iOS from dimming interactive elements on touch */
  -webkit-touch-callout: none; /* disable default callouts */
}

/* Prevents iPhone auto-zoom-in on input field focus */
.body--iOS input,
.body--iOS select,
.body--iOS textarea {
  font-size: 100% !important;
}

.main-content {
  position: relative;
  display: block; /* for older IE */
  flex: 1 0; /* for flex-grow to work on iOS  */
  padding-bottom: 57px; /* App footer's height */
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}

/* TEMP selector */
.main-content--iframe-wrapper {
  height: calc(100vh - 71px - 57px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* enables iOS "momentum" style scrolling */
  z-index: 1;
}

.main-content--with-FAB {
  padding-bottom: calc(
    52px + 25px + 25px
  ); /* button height + distance from screen bottom + space above button */
}

.iframe--cws4 {
  border: none;
}

.space-between-wrapper {
  display: flex;
  justify-content: space-between;
}

.space-between-wrapper--center-aligned {
  align-items: center;
}
/* END OF TEMP selector */

.main-content--short-footer {
  padding-bottom: 70px;
}

/* Links */
a {
  color: #2a2a2a;
  text-decoration: none;
  background-color: transparent; /* removes gray background on active links in IE 10 */
}

.rtb-container a {
  text-decoration: underline;
}

.link {
  text-decoration: underline;
}

.link--small {
  display: inline-block;
  margin-top: 10px;
  color: #757575;
  font-size: 0.8rem;
}

.link--border {
  display: inline-block;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 100%;
  border: 1.2px solid var(--color-secondary);
  border-radius: 50px;
}
/* END OF Links */

b,
strong {
  font-weight: bolder; /* corrects font weight in Chrome, Edge, and Safari */
}

small {
  font-size: 80%; /* corrects font size in all browsers */
}

/* Buttons */
button,
select {
  color: #2a2a2a;
}

/* browser default reset */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

button,
input {
  overflow: visible; /* show the overflow in IE & Edge */
}

button,
select {
  text-transform: none; /* Remove text transform inheritance in Edge, Firefox, and IE */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; /* corrects the inability to style clickable types in iOS and Safari */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Remove the inner border and padding in Firefox */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; /* restores the focus styles unset by the previous rule */
}

button:disabled {
  pointer-events: none;
  cursor: unset;
  color: #dadae0;
  background-color: #efefef;
  border: none;
}

button,
.button {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none; /* prevent text selection */
  white-space: nowrap;
  background: none;
  border: none;
}

.button--primary {
  position: relative;
  padding: 1em;
  color: var(--color-button-text);
  line-height: 100%;
  background-color: var(--color-primary);
  border-radius: 9999px;
  font-weight: 600;
  text-transform: uppercase;
  width: 75%;
  font-size: 1em;
}

.button--secondary {
  border: 2px solid var(--color-secondary);
  background-color: #ffffff;
  color: var(--color-secondary);
  font-weight: 600;
  text-transform: uppercase;
}

.button--primary-with-border {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: none;
  border: 2px solid var(--color-secondary-button-border);
  background-color: #ffffff;
  color: var(--color-secondary-button-text);
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 600;
}

.button--primary-with-border:active {
  background-color: var(--color-active-button-generic-background);
}

.button--primary-small {
  padding: 0.8em 0.5em;
  font-size: 0.8em;
}

.button-container--single-bottom {
  position: fixed;
  left: 0;
  bottom: 3.5em;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 50px 0 24px 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.23573179271708689) 100%
  );
}

.button-container--multiple-bottom {
  flex-direction: column;
  align-items: center;
}

.button-container--multiple-bottom > *:first-child {
  margin-bottom: 10px;
}

.button-container--single-relative {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}

.button-container--single-relative {
  display: flex;
  justify-content: center;
  position: relative;
}

.bottom-padding {
  padding-bottom: 235px !important;
}

a.button--primary {
  text-align: center;
}

/* FIXME: this needs to go asap */
div > .button--primary-small:first-child {
  margin-right: 10px;
}

.button--primary-with-border > svg {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  color: #2a2a2a;
}

/*.button--secondary {
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid #2a2a2a;
  border-radius: 8px;
}*/

.button--pill-shaped {
  border-radius: 9999px;
}

.button--form-next {
  display: block;
  width: 214px;
  margin: 0 auto;
  font-weight: 600;
  text-transform: uppercase;
}

.button--add-to-favorite {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin: 0 auto;
}

.button--section-header,
.link--section-header {
  font-size: 0.8em;
  font-weight: 600;
}
/* END OF Buttons */

/* Form elements */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /* Override for Chrome's input autofill background color */
  box-shadow: 0 0 0 30px rgb(255, 255, 255) inset !important;
}

textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  overflow: auto; /* remove default vertical scrollbar in IE 10+ */
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0.01em 0 0 0;
  border: 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

legend {
  display: table;
  margin: 0;
  padding: 0;
  width: 100%;
}
/* END OF Form elements */

/* Icons */
svg {
  color: #2a2a2a;
}

svg {
  pointer-events: none;
}
/* END OF Icons */

.heading-with-icon {
  display: flex;
  align-items: center;
}

.heading-with-icon > * + * {
  margin-left: 8px;
}

/* Images */
img {
  max-width: 100%;
  height: auto;
  border-style: none; /* removes border on images inside links in IE 10 */
}
/* END OF Images */

/* RTB Container - Text */
.rtb-container p {
  font-size: 1rem;
  line-height: 1.7;
}

.rtb-container h1:first-of-type,
.rtb-container h2:first-of-type,
.rtb-container h3:first-of-type,
.rtb-container h4:first-of-type,
.rtb-container h5:first-of-type,
.rtb-container h6:first-of-type,
.rtb-container p:first-of-type {
  margin-top: 0;
}
/* END OF RTB Container - Text */

/* Shadows */
.elevation--far {
  box-shadow: 0 8px 10px rgba(2, 10, 6, 0.2);
}

.elevation--mid {
  box-shadow: 0 5px 14px 2px rgba(0, 0, 0, 0.21);
}

.elevation--close {
  box-shadow: 0 5px 10px rgba(2, 10, 6, 0.4);
}

.curved-shadow-container {
  position: relative;
}

.curved-shadow-container:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 10px;
  border-bottom-left-radius: 100px 10px;
  border-bottom-right-radius: 100px 10px;
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.1);
}

.curved-shadow-overlay-hack {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  pointer-events: none;
  z-index: 1;
}

.curved-shadow-overlay-hack ~ *,
.curved-shadow-overlay-hack ~ * > * {
  z-index: 2;
}
/* END OF Shadows */

/* Misc */

/* Loading Spinner */
.loading-spinner {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 45px;
  height: 45px;
  border: 4px solid #ddd;
  border-top: 4px solid var(--color-primary);
  border-radius: 50%;
  animation: spinLoader 1s linear infinite;
  z-index: 1000;
}

.loading-spinner--default {
  border: 4px solid #ddd;
  border-top: 4px solid #757575;
  animation: spinDefaultLoader 1s linear infinite;
}

@keyframes spinLoader {
  100% {
    border-top-color: var(--color-primary);
    transform: rotate(360deg);
  }
}

@keyframes spinDefaultLoader {
  100% {
    border-top-color: #757575;
    transform: rotate(360deg);
  }
}
/* END OF Loading Spinner */

/* Skeleton Loading */
.skeleton-loader {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: skeletonPulse 1.2s ease-in-out infinite;
}

@keyframes skeletonPulse {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: -135% 0%;
  }
}

.skeleton-loader__line {
  width: 100%;
  border-radius: 5px;
}

.skeleton-loader__line:before {
  content: "\A0"; /* empty content to let loader element inherit line-height */
}
/* END OF Skeleton Loading */

body.modal--open {
  overflow-y: hidden;
}

.scroll-indicator {
  position: sticky;
  left: 0;
  bottom: 0;
  pointer-events: none;
}

.scroll-indicator:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 60px;
  background: linear-gradient(to top, #2a2a2a38, transparent);
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.hide-element{
  display: none !important; 
}
::-moz-selection {
  color: #2a2a2a;
  background: rgba(0, 134, 255, 0.09);
}
::selection {
  color: #2a2a2a;
  background: rgba(0, 134, 255, 0.09);
}
/* END OF Misc */

/* Marqui */
.content-from-marqui-rtb {
  animation: fadeIn 0.2s forwards;
}

.content-from-marqui-rtb p {
  margin-top: 0;
  font-size: 13px;
  line-height: 1.6;
}

.content-from-marqui-rtb ul {
  padding-left: 16px;
}

.content-from-marqui-rtb a {
  text-decoration: underline;
}

.additional-disclaimer {
  width: 100%;
  margin: 1em auto 2em;
  color: #2a2a2a !important;
  font-size: 0.75rem !important;
  line-height: 1.5 !important;
  font-weight: unset !important;
}

.disabled_order_button {
  opacity: 0.35;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.flex__column {
  display: flex;
  flex-direction: column;
  width: 49%;
}

.full-width-column {
  width: 100%;
}

.space-between {
  justify-content: space-between;
}

.flex-align-start {
  align-items: flex-start;
}

/* Global Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  100% {
    opacity: 0;
  }
}
/* END OF Global Animations */

.full__width {
  width: 100%;
}

.blue-link-button {
  color: var(--color-secondary) !important;
  text-decoration: none !important;
  font-size: 16px;
  font-weight: 600;
}

.icon--star {
  fill: #f49c00;
  stroke: none;
  width: 20px;
  height: 20px;
}

.footer__nav-link > .icon--star {
  fill: none;
}

@media screen and (min-width: 660px) {
  .main-content {
    overflow-x: visible;
    overflow-y: visible;
  }

  .desktop-container {
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }

  .additional-disclaimer {
    font-size: 1em !important;
  }

  .main-content .additional-disclaimer {
    max-width: 1200px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .desktop-container .additional-disclaimer {
    padding-left: 0;
    padding-right: 0;
  }

  .desktop-container {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }

  .desktop-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 0px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 0.8em;
  }

  .desktop-footer__wrapper {
    display: flex;
    justify-content: space-between;
  }

  .desktop-footer__nav-list {
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -0.2em 1em -0.2em -0.75em;
    padding-left: 0;
  }

  .desktop-footer__nav-list > li {
    margin: 0.2em 0.75em;
  }

  .desktop-footer__link {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
  }

  .desktop-footer__link:hover {
    text-decoration: underline;
  }

  .desktop-footer__app-version-wrapper {
    font-size: 0.8em;
    text-align: right;
    white-space: nowrap;
  }
}

@media screen and (min-width: 1280px) {
  .desktop-footer {
    z-index: 1;
  }

  .button--primary {
    min-width: 30%;
  }

  .button-container--single-desktop {
    position: relative;
    display: flex;
    justify-content: flex-end;
    background: none;
    left: 0;
    bottom: 0;
  }

  p {
    font-size: 1em;
  }

  .desktop-page-header-section {
    padding-top: 5%;
  }

  .desktop-page-header-container {
    position: relative;
    margin-bottom: 2.8%;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .desktop-page-header {
    margin: 0;
    font-size: 1.5em;
    font-weight: 500;
    line-height: 100%;
    padding-left: 0;
  }
}

@media screen and (max-width: 660px) {
  .content-from-marqui-rtb {
    padding: 2em 24px 2em;
  }
}

.cart-indicator__wrapper {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 13px;
  margin-bottom: 15px;
}

.cart-indicator {
  position: relative;
  /*width: 34px;
  height: 34px;
  transition: 0.25s transform, 0.25s opacity;
  margin-right: 10px;*/
}

.cart-indicator--empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
}

.cart-indicator > .icon--cart {
  width: 37px;
  /*width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  bottom: 0;*/
}

.cart-indicator--empty > .icon--cart {
  position: relative;
  left: auto;
  bottom: auto;
  margin-top: -2px;
}

.cart-indicator > .cart-indicator__counter {
  left: auto;
  left: initial;
}

.cart-indicator__counter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 20px !important;
  min-width: 23px;
  min-height: 23px;
  color: var(--color-button-text);
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  background-color: var(--color-online-order-settings-active-color);
  border-radius: 50%;
  color: var(--color-button-text);
  max-width: 23px;
}

.cart-indicator--blink {
  transform: scale(1.35);
  opacity: 0.35;
}

/** Dektop Layout **/
@media screen and (min-width: 660px) {
  .cart-indicator__wrapper {
    display: none;
  }
}
/** End Of Desktop Layout **/

.address-validation__confirm-address {
  margin-top: 0px;
  margin-bottom: 2em;
  font-size: 0.8em;
  font-weight: normal;
}

.address-info-card__container {
  margin-bottom: 1em;
}

.dialog-modal__message .address-info-card__container {
  margin-top: 24px;
  margin-bottom: 24px;
}

.address-validation__street-address {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 0.9rem;
  width: -moz-fit-content;
  width: fit-content;
}

.address-validation__city-province-postal {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 0.9rem;
  text-align: left;
}

.address-validation__apt-suite-unit-container {
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: normal;
  display: flex;
}

.address-validation__apt-suite-unit-header {
  font-size: 0.9rem;
  font-weight: normal;
}

.address-validation__apt-suite-unit {
  font-size: 0.9rem;
  margin-left: 5px;
  font-weight: normal !important;
}

.dialog-modal__message.confirm-address__unverified-address {
  margin: 0;
  padding-bottom: 32px;
}

.dialog-modal__message.confirm-address__unverified-address + .dialog-modal__buttons {
  margin-top: 0;
}

.address-validation__recommended-address-header-container {
  margin-bottom: 1em;
  margin-top: 0px;
  font-size: 1rem;
  border-bottom: 1px solid #d4d4d4;
  font-weight: normal;
}

.address-validation__unverified-address-header-container {
  border: none;
}

.address-validation__entered-address-container {
  padding-bottom: 24px;
  font-size: 0.9rem;
  border-bottom: 1px solid #d4d4d4;
}

.address-validation__recommended-address-container {
  padding: 24px 0px;
  font-size: 0.9rem;
}

.address-validation__entered-recommended-address-header {
  margin: 0px;
  font-size: 1rem;
}

.delivery-address__addresses_container {
  padding: 12px 24px;
  border-bottom: 1px solid var(--color-gray-light);
}

.delivery-address__addresses_container.isDefault {
  background-color: var(--color-gray-light);
}

.delivery-address__buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 12px 0px 0px;
}

.delivery-address__action-button {
  font-size: 0.9em;
  display: flex;
  align-items: center;
  color: #2a2a2a;
}

.delivery-address__action-button.isDefault {
  color: #2a2a2a;
}

.delivery-address__action-button .icon {
  color: #2a2a2a;
}

.delivery-address__action-button.isDefault .icon {
  color: #2a2a2a;
}

.delivery-address__remove-modify-buttons {
  display: contents;
}

.delivery-address__address-conatiner {
  display: flex;
  justify-content: space-between;
}

.delivery-address__selected-address-button {
  display: flex;
  align-items: center;
}

.delivery-address-container__overlay-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.delivery-address-container__overlay-button:disabled {
  background: transparent;
  color: unset;
}

@media screen and (min-width: 660px) {
  section > .delivery-address__addresses_container:first-child {
    margin-top: 10px;
  }
  .delivery-address__addresses_container {
    border: 2px solid var(--color-gray-light);
    border-radius: 8px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
  }

  .delivery-address__address-conatiner {
    padding-bottom: 10px;
  }

  .delivery-address__addresses_container .delivery-address__buttons-container {
    justify-content: flex-start;
  }

  .delivery-address__addresses_container .delivery-address__buttons-container button {
    margin-right: 2em;
  }

  .address-validation__entered-address-container,
  .address-validation__recommended-address-container {
    padding-right: 24px;
    padding-left: 24px;
  }

  .delivery-address__selected-address-button {
    position: absolute;
    right: 1.1em;
    top: 0;
    bottom: 0;
  }

  .address-validation__apt-suite-unit-container {
    margin-top: 0;
  }
  p.address-validation__recommended-address-header-container {
    font-size: 1.5rem;
    font-weight: normal;
    padding-left: 0;
  }

  .confirm-address__unverified-address + .dialog-modal__buttons {
    margin-top: 1.35em;
    width: 100%;
  }

  .confirm-address__unverified-address + .dialog-modal__buttons > button {
    padding-left: 1.5em;
    padding-right: 1.5em;
    max-width: unset;
    width: unset;
  }
}

.orderDetailsSummary__container {
  position: relative;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid var(--color-gray-light);
}

.orderDetailsSummary__module-header {
  position: relative;
  margin-bottom: 10px;
}

.orderDetailsSummary__module-heading {
  margin: 0;
  padding-left: 26px;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 100%;
}

.orderDetailsSummary__module-header > .icon {
  position: absolute;
  bottom: 0;
  width: 17px;
  height: 17px;
}

.orderDetailsSummary__order-time-details {
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 500;
}

.orderDetailsSummary__container > .orderDetailsSummary__location-details:first-child {
  border-bottom: 1px solid var(--color-gray-light);
}

.orderDetailsSummary__location-details {
  position: relative;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.orderDetailsSummary__location-details-columns {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.orderDetailsSummary__location-details-columns .icon--edit {
  margin-left: 10px;
}

.orderDetailsSummary__location-details .icon--edit {
  width: 25px;
  height: 25px;
  color: var(--color-secondary);
}

.orderDetailsSummary__location-details > p,
.orderDetailsSummary__location-details-columns > p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.orderDetailsSummary__location-details > p > span,
.orderDetailsSummary__location-details-columns > p > span,
.orderDetailsSummary__user-delivery-address > div > span:first-child {
  font-weight: 600;
}
.orderDetailsSummary__location-details > p > span::after,
.orderDetailsSummary__user-delivery-address > div > span:first-child::after {
  content: " ";
  white-space: pre;
}

.orderDetailsSummary__user-delivery-address {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 0.5em;
}

.orderDetailsSummary__user-delivery-address > div {
  width: 90%;
}

.orderDetailsSummary__user-delivery-address > a {
  width: 10%;
  text-align: right;
}

/** Dektop Layout **/
@media screen and (min-width: 660px) {
  .orderDetailsSummary__container > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .orderDetailsSummary__container
    > div
    > .orderDetailsSummary__location-details
    > .orderDetailsSummary__location-details-time-and-type {
    align-items: center;
    align-content: center;
  }

  .orderDetailsSummary__container > div > .orderDetailsSummary__location-details {
    border: none !important;
    width: 47%;
  }

  .orderDetailsSummary__container > div > .orderDetailsSummary__location-details:last-child {
    justify-content: flex-end;
  }

  .orderDetailsSummary__location-details {
    justify-content: unset;
  }

  .orderDetailsSummary__location-details
    > .orderDetailsSummary__location-details-time-and-type
    > span
    > img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }

  .orderDetailsSummary__container > div > .orderDetailsSummary__location-details:first-child > p {
    font-size: 1.3em;
  }

  .orderDetailsSummary__location-details .icon--edit {
    margin-left: 20px;
  }

  .orderDetailsSummary__location-details-time-and-type {
    font-size: 1.3em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .orderDetailsSummary__location-details-time-and-type > span {
    font-weight: 600;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .orderDetailsSummary__location-details-time-and-type > span:first-child {
    margin-right: 5em;
  }
}
/** End Of Desktop Layout **/

.language-dropdown__list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.language-dropdown-list-item__link {
  display: block;
  padding: 8px 12px;
}

@media screen and (max-width: 660px) {
  .language-dropdown-list__item + .language-dropdown-list__item {
    border-top: 1px solid #f2f2f2;
  }
}

.dialog-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.3s;
  z-index: 999999;
}

.dialog-modal {
  opacity: 0;
  transform: translateY(150px);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 660px;
  padding: calc(44px + 0.5em) 1.5em 2em;
  background-color: #fff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  animation: slideUpConfirmationModal 0.3s forwards;
  animation-delay: 0.15s;
}

.dialog-modal__heading {
  margin: 0 0 12px;
  padding-bottom: 12px;
  font-size: 1.3em;
  font-weight: 600;
  border-bottom: 1px solid #dedede;
}

.dialog-modal__message {
  margin: 0 0 24px;
  font-size: 16px;
  text-align: left;
}

.dialog-modal__position-absolute {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  bottom: 24px;
  z-index: 1000;
}

.dialog-modal__position-relative {
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  bottom: 24px;
  z-index: 1000;
}

.dialog-modal__recommended-address-container {
  padding-bottom: 0px;
  padding-left: 0;
  padding-right: 0;
}

.dialog-modal__recommended-address-container > div {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.dialog-modal__recommended-address-container > .dialog-modal__message {
  box-shadow: 0 7px 10px -6px rgb(182 182 182/77%);
}

.dialog-modal__recommended-address-container
  .address-validation__recommended-address-header-container {
  border-bottom: none;
}

.dialog-modal__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2em;
}

.dialog-modal__button {
  flex: 1 1;
  flex-grow: 0.5;
  width: auto;
  max-width: 250px;
  min-height: 44px;
  padding: 0.5em 1em;
  letter-spacing: 0.2px;
  white-space: normal;
}

.expired-session_popup h2 {
  margin-top: 0;
}
.expired-session_popup p {
  line-height: 1.5;
}

.dialog-modal__cancel-button {
  margin-right: 12px;
  color: #2a2a2a;
  border: 2px solid #2a2a2a;
}

.dialog-modal__confirm-button {
  color: #fff;
  background-color: #2a2a2a;
}

.dialog-modal__close-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
}

.dialog-modal__reorder-flow {
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-button-text);
}

.dialog-modal__reorder-flow .loading-spinner {
  width: 3em;
  height: 3em;
}

.dialog-modal__close-button > svg {
  width: 18px;
  height: 18px;
}

.address-validation__primary-address-checkbox {
  font-weight: bold;
  font-size: 0.9rem;
}

.address-validation__primary-address-checkbox > input {
  margin-right: 10px;
}

@keyframes slideUpConfirmationModal {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dialog-modal__verification-issue-container .confirm-address__unverified-address,
.dialog-modal__verification-issue-container .address-info-card__container,
.dialog-modal__verification-issue-container
  .address-validation__unverified-address-header-container {
  margin: 0;
}
.dialog-modal__verification-issue-container .address-validation__recommended-address-container {
  padding-bottom: 0;
}

.address-confirmation__missing-component h2 {
  border-bottom: 1px solid var(--color-gray);
}
.dialog-button-centered{
  justify-content: center !important;
}
.dialog-button-centered button.dialog-modal__button{
  max-width: 300px !important;
  min-width: 250px !important;
}
/** Desktop Layout **/
@media screen and (min-width: 1025px) {
  .dialog-modal-container {
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dialog-modal {
    position: relative;
    padding: 3em 2em 2em 2em;
    border-radius: 8px;
  }

  .dialog-modal__buttons {
    margin-top: 2em;
    justify-content: flex-end;
  }

  .dialog-modal__buttons-two {
    justify-content: center;
  }

  .dialog-modal__reorder-flow .loading-spinner {
    width: 2em;
    height: 2em;
  }
  .wideDialogModal .dialog-modal{
    min-width: 850px !important;
    max-width: 1000px !important;
  }

}
@media screen and (min-width: 750px) and (max-width: 1000px) {
  .wideDialogModal .dialog-modal {
    min-width: 700px !important;
    max-width: 90% !important;
  }
}
@media screen and (min-width: 1001px) {
  .wideDialogModal .dialog-modal {
    min-width: 850px !important;
    max-width: 950px !important;
  }
}
@media screen and (min-width: 660px) {
  .dialog-modal__button {
    flex-grow: 0.5em;
  }
  .dialog-modal__buttons > .button {
    font-size: 0.7em;
  }

  .address-validation__recommended-address-header-container {
    padding-left: 24px;
    padding-right: 24px;
    border: none;
  }

  .dialog-modal__recommended-address-container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 1em;
    height: 98%;
  }

  .dialog-modal__address-issue-container {
    padding-left: 0;
    padding-right: 0;
    padding-top: 1em;
  }

  .dialog-modal__recommended-address-container .dialog-modal__message,
  .dialog-modal__address-issue-container .dialog-modal__message {
    box-shadow: 0 7px 10px -6px rgb(182 182 182/77%);
    margin-bottom: 0;
  }

  .address-validation__form-and-map {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    height: 100%;
  }

  .address-validation__form-and-map > form {
    width: 35%;
    margin-top: 1em;
  }

  .address-validation__form-and-map > .confirm-address__google-maps-container-dialog-modal {
    width: 65%;
    margin: 0;
    height: 100%;
    max-height: unset;
  }

  .dialog-modal__recommended-address-container .dialog-modal__position_absolute {
    justify-content: center;
    margin-top: 0;
    bottom: 24px;
  }

  .address-validation__address-issue-container {
    margin-top: 1em;
  }

  .address-validation__desktop-button-container {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .address-confirmation__missing-component h2 {
    padding-left: 0;
    padding-right: 0;
  }

  .dialog-modal__missing-component-container p {
    font-size: 16px;
  }

  .expired-session_popup .dialog-modal__button {
    flex: 1 1;
    flex-grow: 0.4;
    width: auto;
    max-width: unset;
    min-height: 44px;
    padding: 0 12px;
    letter-spacing: 0.2px;
    white-space: normal;
  }

  .expired-session_popup h2 {
    margin-top: 0;
    font-size: 24px;
  }
  .expired-session_popup p {
    line-height: 1.5;
    font-size: 16px;
  }

  .xp-subscription-container > h2 {
    font-size: 24px;
  }

  .xp-subscription-container > p {
    line-height: 1.5;
    font-size: 16px;
  }
}

@media screen and (min-width: 660px) and (max-width: 1024px) {
  .dialog-modal-container {
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dialog-modal {
    position: relative;
    padding: 3em 2em 2em 2em;
    border-radius: 8px;
  }

  .dialog-modal__buttons {
    margin-top: 2em;
    justify-content: flex-end;
  }

  .dialog-modal__buttons-two {
    justify-content: center;
  }
}

/** End Of Desktop Layout **/

@media screen and (max-width: 660px) {
 .fullScreenMobileModal .dialog-modal{
  height: 100%;
 }
 .fullScreenMobileModal .dialog-modal>div:first-child{
  height: 90.5%;
 }
}
.fav-button__loading-spinner {
  position: relative;
  margin: 0;
  width: 25px;
  height: 25px;
}

/* App Footer Subnav Item styles */
.footer__subnav-link {
  display: flex;
  width: 100%;
  padding-top: 14px;
}

.footer__subnav-link > svg,
.footer__subnav-link > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.footer__subnav-link > .icon--heart {
  stroke-width: 2;
  stroke: #2a2a2a;
}

.footer__subnav-link-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding-bottom: 14px;
  font-size: 15px;
  border-bottom: 1px solid var(--color-footer-navigation-item-border);
  color: var(--color-footer-text-and-icon-colour);
}

.footer__subnav-link-text > svg {
  fill: var(--color-footer-text-and-icon-colour);
}

.footer__subnav-item svg {
  stroke: var(--color-footer-text-and-icon-colour);
}

.footer__subnav-item svg.icon--order-status {
  stroke-width: 2px;
  width: 23px;
  height: 23px;
}

.footer__subnav-item svg.icon--utensils,
.footer__subnav-item svg.icon--transaction-history {
  fill: var(--color-footer-text-and-icon-colour);
}

.footer__subnav-link > .icon--script {
  stroke: none;
  fill: var(--color-footer-text-and-icon-colour);
}

.footer__subnav-item:last-child .footer__subnav-link-text {
  border-bottom: none;
}

.footer__subnav-link svg:last-child {
  width: 10px;
  height: 10px;
}

.footer__subnav-link .icon--gift-card {
  transform: rotate(15deg);
}

.footer-subnav__app-version {
  margin-top: 15px !important;
  font-size: 12px;
}
/* END OF App Footer Subnav Item styles */

/* Modal.css */
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal-wrapper--select-order-store {
  z-index: 1100;
}

.modal-wrapper--fullscreen {
  background-color: transparent;
}

.modal-wrapper--transparent {
  background-color: rgba(244, 244, 244, 0.97);
}

.modal {
  opacity: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding: 25px;
  border-radius: 8px;
}

.modal--default {
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* enables iOS "momentum" style scrolling */
  transform: translateY(100%) scale(0);
  transform-origin: center bottom;
  animation: slideInFromBottomAndGrow 0.3s forwards;
  background-color: #fff;
}

@keyframes slideInFromBottomAndGrow {
  100% {
    transform: none;
    transform: initial;
    opacity: 1;
  }
}

@keyframes slideOutToBottomAndShrink {
  0% {
    transform: none;
    transform: initial;
    opacity: 1;
  }
  100% {
    transform: translateY(100%) scale(0);
    opacity: 0;
  }
}

.modal--fullscreen {
  transform: translateX(100%);
  width: 100%;
  height: 100%;
  padding: 63px 25px 85px;
  border-radius: 0;
  animation: slideInFromRight 0.3s forwards;
  overflow-y: auto;
}

.modal--vertical-animation > .modal--fullscreen {
  transform: translateY(100%);
  animation: slideInFromBottom 0.3s forwards;
}

.modal--fullscreen.modal--static {
  opacity: 1;
  transform: none;
  transform: initial;
  width: 100%;
  height: 100%;
  padding: 85px 25px;
  border-radius: 0;
  animation: none;
}

@keyframes slideInFromRight {
  100% {
    transform: none;
    transform: initial;
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  100% {
    transform: none;
    transform: initial;
    opacity: 1;
  }
}

.modal--slideOutToRight {
  animation: slideOutToRight 0.2s forwards;
}

@keyframes slideOutToRight {
  0% {
    transform: none;
    transform: initial;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.modal--iframe {
  width: 90%;
  height: 90%;
  transform: translateY(100%) scale(0);
  transform-origin: center bottom;
  animation: slideInFromBottomAndGrow 0.3s forwards;
}

.modal--slideOutToBottomAndShrink {
  animation: slideOutToBottomAndShrink 0.2s forwards;
}

.modal__button--close,
.modal__link--back {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

.modal--fullscreen .modal__button--close,
.modal--fullscreen .modal__link--back {
  top: 9px;
  left: 9px;
}

.modal--default .modal__button--close,
.modal--default .modal__link--back {
  top: 0;
  right: 0;
}

.modal__heading {
  margin: 0 0 20px;
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.2;
}

.modal--fullscreen .modal__heading {
  font-size: 1.5rem;
}

.modal__buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modal--default .form-submit__wrapper .button,
.modal__buttons > button {
  /* min-width: 80px; /* to avoid pill-shaped button to turn into a circle 
  width: auto;
  height: 40px;
  font-size: 0.8rem;
  text-transform: uppercase;*/
}

.modal--transparent {
  background-color: transparent;
}

.modal-wrapper--recent-locations-closes-soon {
  z-index: 1100;
}

.modal-wrapper--bottom-aligned {
  align-items: flex-end;
}

.modal--bottom-aligned {
  align-items: flex-start;
  width: 100%;
  max-height: 90%;
  padding-top: 48px;
  padding-bottom: 48px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* enables iOS "momentum" style scrolling */
  transform: translateY(100%) scale(0);
  transform-origin: center bottom;
  animation: slideInFromBottomAndGrow 0.3s forwards;
  background-color: #fff;
  border-radius: 0;
}

.modal--bottom-aligned > .modal__button--close {
  top: 0;
  right: 0;
}

@media screen and (min-width: 660px) {
  .modal {
    width: auto;
    width: initial;
    padding: 3em 2em;
    max-width: 90%;
  }

  .modal > .modal__buttons {
    margin-top: 2em;
    justify-content: center;
  }

  .modal.modal--fullscreen {
    width: 90%;
  }
}

.FAB-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 25px;
  z-index: 1000;
}

.FAB-wrapper__item-details-page {
  bottom: 57px;
  padding-bottom: 9px;
  padding-top: 9px;
  background-color: var(--color-online-order-settings-background);
  min-height: 86px;
  display: flex;
  align-items: center;
  z-index: 1005;
}

.FAB-wrapper__item-details-page.wizard {
  bottom: 0px;
}

.FAB--add-to-order__with-cart-wrapper > .desktop-container {
  width: 75%;
}

.FAB-wrapper__item-details-page + .order-settings__cart-button {
  position: fixed;
  bottom: 77px;
  right: 10px;
  width: 50px;
  height: 50px;
  z-index: 1001;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  animation-delay: 500ms;
}

.FAB-wrapper__item-details-page.wizard + .order-settings__cart-button {
  bottom: 20px;
}

.FAB-wrapper__item-details-page.centered {
  justify-content: center;
}

.FAB-wrapper__item-details-page + .order-settings__cart-button .cart-indicator__counter {
  left: -4px !important;
}

.FAB-wrapper__item-details-page > .FAB-wrapper__gradient {
  display: none;
}

.FAB-wrapper__addMoney {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 3em;
  z-index: 1100;
}

.FAB-wrapper__addMoney .FAB__error {
  bottom: calc(52px / 2 + 45px);
}

.FAB-wrapper__gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 127px;
  background-image: linear-gradient(to top, #fff 0%, #fff 20%, rgba(255, 255, 255, 0) 100%);
  transition: back;
  pointer-events: none;
}

.FAB-wrapper__gradient:after {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, #fff 0%, #fff 80%, rgba(255, 255, 255, 0) 100%);
  transition: opacity 0.3s;
  pointer-events: none;
}

.FAB-wrapper__gradient--expanded:after {
  opacity: 1;
}

.FAB {
  position: relative;
  display: block;
  width: 70%;
  height: 52px;
  margin: 0 auto;
  padding: 0 15px;
  color: var(--color-button-text);
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  background-color: var(--color-primary);
  border-radius: 9999px;
  z-index: 1;
}

.FAB-wrapper--modal {
  z-index: 1100;
}

.FAB-wrapper--modal.update_account_info__floating-button,
.FAB-wrapper--modal.change-password__floating-button {
  z-index: 1000;
}
.FAB-wrapper--modal.change-password__floating-button,
.FAB-wrapper--modal.update_account_info__floating-button {
  z-index: 1152;
}
.FAB--large {
  width: 90%;
}

.FAB--left-aligned {
  margin-left: 30px;
}

.FAB__error {
  opacity: 0;
  position: absolute;
  bottom: calc(52px / 2 + 35px);
  transform: translateY(0);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ff2020;
  font-size: 14px;
  text-align: center;
  animation: showFABError 0.3s forwards;
}

@media screen and (max-width: 660px) {
  .FAB-wrapper__item-details-page + .order-settings__cart-button {
    z-index: 1005;
  }
}

@media screen and (min-width: 660px) {
  /*.FAB-wrapper__item-details-page {
    bottom: 57px;
    padding-bottom: 9px;
    padding-top: 9px;
    background-color: var(--color-online-order-settings-background);
    min-height: 45px;
    max-height: 50px;
    display: flex;
    align-items: center;
  }

  .FAB {
    width: 100%;
    height: 52px;
    margin: 0 auto;
    padding: 0 15px;
  }
  .FAB--add-to-order__with-cart {
    margin-left: 0%;
    width: 68%;
    transition: width 0.5s;
    white-space: normal;
    word-wrap: break-word;
  }*/
  .FAB__error {
    bottom: calc(52px / 2 + 50px);
  }

  .desktop-menu__menu-combo-summary + .FAB__error {
    position: fixed;
    bottom: calc(52px / 2 + 70px);
  }

  .FAB-wrapper__item-details-page.wizard + .order-settings__cart-button {
    bottom: 0px;
  }

  .FAB-wrapper__addMoney .FAB__error {
    bottom: calc(52px / 2 + 45px);
    display: flex;
    justify-content: flex-end;
    padding-right: calc(50px + 6em) !important;
  }
}

@keyframes showFABError {
  100% {
    opacity: 1;
    transform: translateY(calc(-52px / 2 - 10px));
  }
}

.FAB__error--closing {
  animation: hideFABError 0.3s forwards;
}

@keyframes hideFABError {
  0% {
    opacity: 1;
    transform: translateY(calc(-52px / 2 - 10px));
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

.FAB__success {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /*color: #fff;*/
  clip-path: circle(0 at center);
  border-radius: 50px;
  animation: expandBackground 0.5s forwards;
  z-index: 2;
}

@keyframes expandBackground {
  100% {
    clip-path: circle(400px at center);
  }
}

.FAB__success > .icon--check {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 18px;
  margin: auto 0;
  width: 18px;
  height: 18px;
  color: var(--color-button-text-disabled);
}

.FAB--add-to-order,
.FAB-wrapper__combo-add-to-choice button {
  color: var(--color-add-to-order-button-text);
  background-color: var(--color-add-to-order-button);
  margin: 0 auto;
  font-size: 14px;
  white-space: normal;
  word-wrap: break-word;
}

.FAB-wrapper__combo-save-changes {
  bottom: 0;
}

.save-changes-FAB {
  bottom: 0;
  background-color: var(--color-online-order-settings-background);
  padding: 1em 0;
}

.save-changes-FAB > .FAB-wrapper__gradient {
  display: none;
}

@media screen and (min-width: 660px) {
  .FAB--add-to-order {
    font-size: 17px;
  }
  .desktop-container > .FAB--add-to-order__with-cart {
    /*margin-left: 0;*/    
  }

  .save-changes-FAB {
    background-color: var(--color-online-order-settings-background);
    padding: 1em 0;
    bottom: 28px;
  }

  .save-changes-FAB > .FAB-wrapper__gradient {
    display: none;
  }

  .save-changes-FAB .FAB--modal-level-2 {
    width: unset;
    min-width: unset;
    flex: unset;
    margin: 0;
    padding: 0 4em;
  }

  .save-changes-FAB > .desktop-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .FAB-wrapper__item-details-page.wizard + .order-settings__cart-button {
    position: absolute;
    bottom: 18px;
    right: 28px;
    z-index: 1005;
  }

  .FAB-wrapper__item-details-page.wizard > .desktop-container > .order-settings__cart-button {
    position: absolute;
    bottom: 0px;
    top: 0;
    right: 24px;
    z-index: 1005;
    width: 50px;
    height: 50px;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    animation-delay: 500ms;
    background-color: transparent;
  }

  .FAB-wrapper__item-details-page.wizard
    > .desktop-container
    > .order-settings__cart-button
    .cart-indicator__counter {
    left: -4px !important;
  }

  .FAB-wrapper__combo-add-to-choice > .FAB__error,
  .FAB-wrapper__item-details-page > .FAB__error,
  .FAB-wrapper.save-changes-FAB > .FAB__error {
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 53%, rgba(255, 255, 255, 0) 100%);
    padding: 2.5em 1em 1em;
    bottom: 50px;
  }
}

/** Mobile specific **/
@media screen and (max-width: 660px) {
  .FAB-wrapper__combo-add-to-choice > .desktop-container {
    width: 100%;
  }

  .FAB-wrapper__item-details-page > .desktop-container {
    width: 100%;
  }

  .FAB--combo-confirm-choices-wrapper {
    bottom: 0;
    justify-content: center;
  }

  .FAB--add-to-order__with-cart-wrapper > .desktop-container {
    width: 75%;
  }

  .FAB-wrapper__combo-add-to-choice > .FAB__error,
  .FAB-wrapper__item-details-page > .FAB__error,
  .FAB-wrapper.save-changes-FAB > .FAB__error {
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 64%, rgba(255, 255, 255, 0) 100%);
    padding: 2.5em 1em 1em;
    bottom: 50px;
  }

}

.FAB--add-to-order__with-cart {
  margin-left: 5%;
  width: 68%;
  transition: width 0.5s;
  white-space: normal;
  word-wrap: break-word;
}

.FAB--modal-level-2 {
  white-space: normal;
  word-wrap: break-word;
}

.form--credit-card {
  max-width: 308px;
  margin: 0 auto;
}

.form__link-wrapper {
  text-align: center;
}

.form-wrapper > a {
  text-decoration: underline;
}

.form-heading {
  margin: 0 0 15px;
  font-size: 13px;
  font-weight: 600;
}

.form__fieldset + * {
  margin-top: 3em;
}

fieldset.form__field-wrapper + * {
  margin-top: 23px;
}

.form__field-wrapper + .form-submit__wrapper:not(:empty) {
  margin-top: 30px;
}

.form__field-wrapper--checkbox {
  display: flex;
  align-items: center;
  min-height: 27px;
}

.form__field-label--checkbox-radio {
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 120%;
  /* text-transform: capitalize; */
}

.form__field-label--checkbox {
  min-height: 27px;
  padding-left: 40px;
}

.form__field-label--regular-checkbox {
  font-size: 1rem;
  font-weight: 600;
}

.form__field-label--checkbox-greyed {
  color: #a09a9a;
}

.form__field-label--checkbox > a {
  text-decoration: underline;
}

.form__field-label--radio {
  padding-left: 32px;
  min-height: 22px;
}

.form__field-label--checkbox-radio::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form__field-label--checkbox::before {
  width: 25px;
  height: 25px;
  border-radius: 4px;
}

.form__field--checkbox:disabled + .form__field-label--checkbox:before,
.form__field--radio:disabled + .form__field-label--radio:before {
  background-color: #ececec;
}

.form__field-label--radio::before {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.form__field-wrapper--checkbox-radio + .form__field-wrapper--checkbox-radio {
  margin-top: 10px;
}

.form__field--checkbox:checked + .form__field-label--checkbox::before {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='icon icon--check'><polyline points='20 6 9 17 4 12'></polyline></svg>");
  background-repeat: no-repeat;
  background-position: center;
}

.form__field--radio:checked + .form__field-label--radio::before {
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'><circle cx='50' cy='50' r='30'/></svg>");
  background-repeat: no-repeat;
  background-position: center;
}

.form__field--checkbox-radio:focus + .form__field-label--checkbox-radio:before {
  outline: 1px solid #0086ff;
}

.checkbox__link {
  text-decoration: underline;
}

/*Submit button*/
.submit-button {
  padding: 15px;
  background: linear-gradient(var(--color-secondary), var(--color-secondary) 60%) no-repeat;
  border-radius: 50px;
  line-height: 100%;
}

.button--submit-form-wrapper {
  position: relative;
  width: 100%;
}

.button--submit-form {
  position: relative;
  display: block;
  margin: 0 auto;
  width: calc(100% - 36px);
  height: 50px;
  font-weight: 600;
  text-transform: uppercase;
}

.button--submit-form__success {
  /* position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;*/
  color: var(--color-button-text-disabled);
  /*font-size: 14px;*/
  background-color: var(--colo-disabled-background);
  clip-path: circle(0 at center);
  border-radius: 50px;
  animation: expandBackground 0.5s forwards;
  z-index: 2;
}

.button--submit-form__success > .icon--check {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 18px;
  margin: auto 0;
  width: 18px;
  height: 18px;
  color: var(--color-button-text-disabled);
}

.form__error {
  opacity: 0;
  transform: translateY(-50px);
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  max-width: 80%;
  margin: 0 auto;
  color: #ff2020;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  animation: showFormError 0.3s forwards;
}

@keyframes showFormError {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.form__error--hidden {
  animation: hideFormError 0.3s forwards;
}

@keyframes hideFormError {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

/* reCAPTCHA container */
.form__field-recaptcha-wrapper {
  transform: scale(0.85);
  transform-origin: 0 0;
}

/* Readjust error positioning to offset the scaled-down captcha */
.form__field-recaptcha-wrapper .form__field-error {
  right: -17.64%;
  min-width: 84px;
  font-size: 14px;
}

.button--intermediary-submit {
  display: block;
  min-width: 100px;
  height: 32px;
  margin: 0 auto;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  background-color: var(--color-primary);
  color: var(--color-button-text);
  text-transform: uppercase;
  border-radius: 9999px;
}

.save-credit-card__field-wrapper {
  display: none;
}

.save-credit-card__field-wrapper--enabled {
  display: block;
}

.form > .form-submit__wrapper {
  text-align: center;
}

.form.form--account-change-password > .form-submit__wrapper {
  max-width: 98vw;
}

.iconButtonMidAbsolute {
  left: 7rem;
}

.iconButtonEndAbsolute {
  right: 1rem;
  bottom: 11px !important;
}

.giftCardRecipientRowHeader {
  min-height: auto !important;
}

.selectInputValuePaddingLeft {
  padding-left: 2.8rem !important;
}
@media screen and (min-width: 660px) {
  .button--submit-form-wrapper > .button--primary {
    width: 75%;
    max-width: 250px;
  }

  .form--credit-card {
    max-width: unset;
    margin: 0 3em;
  }
}
@media screen and (max-width: 660px) {
  .gift-card-form-fieldset {
    padding: 0.25rem 1.875em 2rem 1.875rem !important;
  }
  .giftCardRecipientRowHeader{
    flex-wrap: wrap;
  }
}

/*FormInput.css*/
.form__field-wrapper {
  position: relative;
}

.form__field-wrapper + * {
  margin-top: 2.5em;
}

.form__field-wrapper--small {
  display: inline-block;
  width: 48%;
  margin-top: 3em;
}

.form__field-wrapper--small + .form__field-wrapper--small:nth-child(odd) {
  margin-left: 4%;
}

.form__field-wrapper--small + .form__field-wrapper--small:nth-child(2) {
  margin-left: 4%;
}

.form__field-label {
  top: 0;
  bottom: 0;
  left: 18px;
  display: flex;
  align-items: center;
  line-height: normal;
  pointer-events: none;
  transition: font-size 0.3s, transform 0.3s;
  position: static;
  margin: 0 0 12px;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
}

.form__field {
  width: 100%;
  color: #2a2a2a;
  line-height: normal;
  background-color: #fff;
  background-clip: padding-box; /* removes iOS shadow */
  height: 42px;
  padding: 10px 12px;
  font-size: 1rem;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #dedede;
}

.form__field--with-right-button {
  /* button width + padding */
  padding-right: calc(42px + 8px);
}

.form__field-wrapper--invalid .form__field {
  border-bottom: 1px solid #ff2020;
}

.form__field--select {
  background-color: #fff;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; /* removes native browser arrow down icon */
}

.form__field + .icon--arrow-down {
  position: absolute;
  top: auto;
  bottom: 6px;
  right: 7px;
  width: 25px;
  height: 25px;
  margin: auto;
}

.form__field::-moz-placeholder {
  color: transparent;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:focus::-moz-placeholder {
  color: #ccc;
  -moz-transition: color 0.2s;
  transition: color 0.2s;
}

.form__field:focus::placeholder {
  color: #ccc;
  transition: color 0.2s;
}

.form__field:focus {
  border-color: var(--color-secondary);
}

.button--show-password {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 43px;
  height: 100%;
}

.button--show-password > .icon {
  margin-right: 12px;
  color: #757575;
}

.form__field-error {
  opacity: 0;
  transform: translate(-10px, 40px);
  position: absolute;
  top: 100%;
  right: 0;
  /* max-width: 65%; */
  padding: 2px 6px;
  color: #ff2020;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  animation: showFieldError 0.25s forwards;
  pointer-events: none;
  z-index: 600;
}

.inputInfoText {
  position: absolute;
  font-size: 0.9em;
    bottom: -25px;
}

@keyframes showFieldError {
  100% {
    opacity: 1;
    transform: translate(-10px, -12px);
  }
}

.form__field-wrapper--small > .form__field-error {
  max-width: none; /* reset for longer errors in short fields */
}
.currencySymbolPadding {
  padding-left: 2.5em;
}
@media screen and (max-width:660px) {
  .inputInfoTextMargin{
    margin-bottom:  4.2em;
  }
}
select.form__field {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.selectIconStart {
  position: absolute;
  z-index: 2;
  margin: 0;
  padding: 0;
  left: 20px;
  bottom: 12px;
  font-size: 1.1rem;
  font-weight: 500
}
/* .form__field-wrapper--in-focus > .form__field-label {
  font-size: 12px;
  transform: translateY(-9px);
} */

.gcAmountSelectDisabled{
  opacity: 1 !important;
  border: none !important;
  pointer-events: none;
}
.mobile-hamburger-menu-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 30px 30px 5em 30px;
  z-index: 1150;
  overflow-y: scroll;
}

.mobile-hamburger-menu-x-icon {
  margin-bottom: 36px;
}

.mobile-hamburger-menu-x-icon .icon--x {
  color: #2f2f2f;
}

.footer-subnav__list.mobile-hamburger-menu {
  position: relative;
  background: transparent;
  border-radius: 5px;
  bottom: unset;
  margin: 0;
  margin-top: 1em;
  width: 100%;
  height: 100%;
  padding: 0;
  z-index: 1000;
}

.footer-subnav__list.mobile-hamburger-menu .footer__subnav-link-text {
  border: none;
}

.mobile-hamburger-menu-button {
  width: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
}

/* AppHeader.css */
.header {
  padding: 0;
  background-color: #fff;
  z-index: 500;
  min-height: 54px;
  align-items: center;
  justify-content: space-between;
}

header > .curved-shadow-overlay-hack {
  background-color: var(--color-header-background-colour);
}

.header-primary {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  z-index: 200;
  width: 100%;
  height: 100%;
  color: var(--color-header-text-and-icon-colour);
  min-height: 54px;
}

.header-primary > *:first-child:not(.page-title) {
  position: absolute !important;
  left: 20px;
  top: 0;
  bottom: 0;
}

.header-primary > .headerAccountButton {
  position: absolute;
  right: 60px;
  top: 0;
  bottom: 0;
}

.header-primary > *:last-child {
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  left: unset;
  margin: auto;
}

.header-primary > .page-title + #item-details-page__fav-button {
  position: absolute;
  right: 55px;
  top: 0;
  bottom: 0;
  left: unset;
  margin: auto;
}

.header-primary svg {
  color: var(--color-header-text-and-icon-colour);
}

.header__link--back {
  position: relative;
  top: 0px;
  bottom: 0px;
  /*left: 14px;*/
}

.header__link--home {
  position: relative;

  /*left: 14px;*/
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  top: 0;
  width: 44px;
  height: 44px;
  right: 0;
}

.page-title + .header__link--home {
  left: auto;
}

.header__link--back > .icon {
  width: 20px;
  height: 20px;
}

.header__link--home > .icon {
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  fill: var(--color-header-text-and-icon-colour);
}

.page-title {
  min-height: 18px;
  max-width: calc(100% - 44px - 44px);
  margin: 0 auto;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
}
.mobile-app-header-logo_wrapper.page-title  {
  position: relative !important;
}
.button--cart {
  position: absolute;
  top: 13px;
  right: 20px;
}

/** App header order summary widget styling */
.app-header__order-summary-container {
  width: 100%;
  height: 200%;
  max-height: 200%;
  background-color: rgba(152, 152, 152, 0.7);
  position: fixed;
  z-index: 450;
  top: -20px;
  visibility: visible;
}

.app-header__order-summary-container.is-sliding-down > .orderDetailsSummary__container {
  opacity: 0;
  transform: translateY(100%);
  animation: slidedownorderSummary 0.5s forwards;
}

.app-header__order-summary-container.is-hidden {
  display: none;
}

.app-header_map-pin-button-highlighted > .icon--map-pin {
  fill: var(--color-primary);
}

.app-header_map-pin-button-highlighted > .icon--map-pin > circle {
  fill: white;
}

.app-header__order-summary-container__overlay-button {
  width: 100%;
  height: 100%;
}

.mobile-app-header-logo_wrapper {
  width: 35%;
  display: flex;
  justify-content: center;
}

.mobile-app-header-logo {
  max-height: 50px;
}

.header-primary-with-logo {
  padding-top: 0;
  padding-bottom: 0;
  height: unset;
}

.header.header-primary-with-logo {
  padding: 0;
}

.header-primary-with-logo > .header__link--back {
  position: unset;
  width: unset;
  height: unset;
}

@keyframes slidedownorderSummary {
  to {
    transform: translateY(98px);
    opacity: 1;
  }
}
/*.headerAccountButton {
  margin-left: 0.75rem;
}*/

.activeHeaderNavLink svg {
  color: var(--color-secondary) !important;
}
/* end of: AppHeader.css */

/* App Footer's Item styles */
.footer__nav-item {
  flex: 1 1;
  width: 10%;
}

.footer__nav-item--settings {
  flex-grow: 0;
}

.footer__nav-item + .footer__nav-item--settings {
  margin-left: 12px;
}

.footer__nav-item--settings .footer__nav-button {
  justify-content: center;
  width: 38px;
  height: 100%;
}

.footer__nav-button,
.footer__nav-link {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 0 12px;
  line-height: 1.15;
}

.footer__nav-item--active-module .footer__nav-button,
.footer__nav-item--active-module .footer__nav-link {
  color: var(--color-secondary);
}
.giftCardFooterIcon {
  fill: var(--color-footer-text-and-icon-colour);
}
.footer__nav-item--active-module .footer__nav-button > svg.giftCardFooterIcon,
.footer__nav-item--active-module .footer__nav-link > svg.giftCardFooterIcon {
  fill: var(--color-header-footer-active-text-colour);
}

.footer__nav-item--active-module .footer__nav-link svg {
  fill: var(--color-secondary);
}

.footer__nav-button > svg,
.footer__nav-link > svg {
  height: 20px;
  margin-bottom: 3px;
  stroke: var(--color-footer-text-and-icon-colour);
}

.footer__nav-link > svg.icon--qr,
.footer__nav-button > svg.icon--utensils {
  stroke: none;
  fill: var(--color-footer-text-and-icon-colour);
}

.footer__nav-item--settings .footer__nav-button > svg {
  margin-bottom: 0;
}

.footer__nav-item--active-module .footer__nav-button > svg,
.footer__nav-item--active-module .footer__nav-link > svg {
  stroke: var(--color-header-footer-active-text-colour);
  fill: var(--color-header-footer-active-text-colour);
}

.footer__nav-item--active-module .footer__nav-link > svg.icon--star {
  fill: transparent;
}

.footer__nav-item--active-module .footer__nav-button > .icon--utensils,
.footer__nav-item--active-module .footer__nav-link > .icon--utensils {
  fill: var(--color-header-footer-active-text-colour);
}

.footer__nav-item--active-module .footer__nav-button > .icon--qr,
.footer__nav-item--active-module .footer__nav-link > .icon--qr {
  stroke: none;
  fill: var(--color-header-footer-active-text-colour);
}

.footer__nav-item-title {
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  pointer-events: none;
}
div.giftCardMobileLinkDotIndicator{
  position: relative;
}
div.giftCardMobileLinkDotIndicator span{
  position: absolute;
  right: -2px;
  top: 3px;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: var(--color-gift-nav-link-indicator-colour);
}
/* END OF App Footer's Item styles */

/** For small mobile devices such as iphone SE */
@media screen and (max-width: 390px) {
  .footer__nav-item span {
    font-size: 10px;
  }
}

/* App Footer styles */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  /*padding-left: 3%;*/
  background-color: var(--color-footer-background-colour);
  /*box-shadow: 0 -2px 20px 3px rgba(0, 0, 0, 0.2);*/
  z-index: 1100;
  border-top: 1px solid #eee;
}

.main-content--pickup ~ .footer,
.main-content--delivery ~ .footer {
  z-index: 400;
}

.footer--open-subnav {
  box-shadow: 0px -10px 20px 3px rgba(0, 0, 0, 0.2);
}

.footer__nav-list {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.footer-subnav__list {
  opacity: 1;
  transform: translateY(0);
  position: absolute;
  bottom: 55px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 89.4%;
  padding: 12px 24px 32px;
  list-style-type: none;
  /*(animation: slideUpSubnav 0.2s forwards;*/
}

.footer__subnav-background.mobile-hamburger-menu {
  top: 70px;
  border-radius: 5px;
  opacity: 0;
}

.footer__subnav-background.mobile-hamburger-menu + .footer__subnav-overlay {
  opacity: 0;
}

.footer__more-options {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 100%;
}

.footer__subnav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  z-index: 1000;
  animation: fadeIn 300ms;
}

.button--pay-in-store {
  position: fixed;
  bottom: calc(57px + 11px); /* footer height + distance from footer */
  right: 18px;
  z-index: 100;
  min-width: 40%;
  width: auto;
  display: flex;
  justify-content: center;
  color: var(--color-pay-in-store-button-text);
  background-color: var(--color-pay-in-store-button);
  box-shadow: 0 5px 10px rgba(2, 10, 6, 0.33);
}

.footer__subnav-background {
  opacity: 0;
  transform: translateY(100%);
  position: fixed;
  z-index: 1001;
  bottom: 57px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 89.4%;
  background-color: var(--color-footer-background-colour);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.21);
  animation: slideUpSubnav 0.2s forwards;
}

/** Prevents double tab zoom on footer buttons **/
.body--iOS .footer__nav-item {
  touch-action: manipulation;
}

.button--pay-in-store.button--pay-in-store-left-aligned {
  left: 18px;
  right: unset;
}
@keyframes slideUpSubnav {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media screen and (min-width: 660px) {
  .button--pay-in-store {
    box-shadow: 0 5px 10px rgba(2, 10, 6, 0.33);
    bottom: 5em;
    right: 2em;
    min-width: 0;
    padding: 1em 5em;
  }
}
/* END OF App Footer styles */

/* Containers */
.desktop-header {
  position: relative;
  z-index: 1005;
  background-color: var(--color-header-background-colour);
}

.desktop-header-container {
  display: flex;
  align-items: center;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
}
/* END OF: Containers */

/* Client Logo */
.desktop-app-header__logo-wrapper {
  display: inline-block;
  height: 60px;
  margin-right: 2em;
  transition: opacity 0.3s;
}

.desktop-app-header__logo-link {
  transition: opacity 0.3s;
}

.desktop-app-header__logo-link:hover {
  opacity: 0.7;
}

.app-header__logo {
  max-height: 100%;
  width: auto;
  max-width: 250px;
}
/* END OF: Client Logo */

/* Navigation */
#desktop-header__menu-toggle {
  display: none;
}

#desktop-header__menu-toggle > svg {
  color: var(--color-header-text-and-icon-colour);
  position: absolute;
  right: 0;
}

.desktop-header__nav-menu {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  animation: fadeIn 0.1s;
}

.desktop-header__nav-list {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.desktop-header__nav-item {
  position: relative;
  display: flex;
  align-items: center;
}

.desktop-header__nav-item + li {
  margin-left: 2em;
}

.desktop-header__nav-item[data-item="language"] {
  margin-left: auto;
}

.desktop-header__nav-item[data-item="sign-in"] {
  text-align: right;
}

.desktop-header__nav-item[data-item="sign-in"]:after {
  content: "/";
  color: var(--color-header-text-and-icon-colour);
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.desktop-header__nav-item[data-item="register"] {
  margin-left: 0;
  text-align: left;
}

.desktop-header__nav-item--active > .desktop-header__nav-link,
.desktop-header__nav-item--active > button {
  color: var(--color-header-active-text-colour);
}

.desktop-header__nav-link,
.desktop-header__nav-button {
  font-size: 1em;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  transition: opacity 0.3s;
  cursor: pointer;
  color: var(--color-header-text-and-icon-colour);
  text-wrap: wrap;
}

.desktop-header__nav-link:hover,
.desktop-header__nav-button:hover {
  opacity: 0.7;
}

/* Submenu dropdowns */
.desktop-header__subnav {
  display: none;
  position: absolute;
  z-index: 550;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 50px;
  /* offset for submenu toggle button */
  margin-left: calc(-17px / 2);
}

.desktop-header-subnav__content {
  position: relative;
  display: flex;
  padding-left: 2em;
  padding-right: 2em;
  background-color: var(--color-header-background-colour);
  border-radius: 5px;
  box-shadow: 0 2px 15px 0px rgb(0 0 0 / 20%);
}

.desktop-header-subnav__content:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 15px solid var(--color-header-background-colour);
}

.desktop-header-subnav__list {
  list-style-type: none;
  flex-direction: column;
  padding-top: 1em;
  padding-bottom: 1em;
}

.desktop-header-subnav__list-item {
  white-space: nowrap;
}

.desktop-header-nav__submenu-link,
.desktop-header-nav__submenu-button {
  margin: 0.5em 0;
  white-space: nowrap;
  transition: opacity 0.2s;
  color: var(--color-header-text-and-icon-colour);
}

.desktop-header-nav__submenu-link:hover,
.desktop-header-nav__submenu-button:hover {
  opacity: 0.7;
}

.desktop-header-nav__submenu-link {
  display: block;
}

.desktop-header-nav__submenu-button {
  text-align: left;
}

.desktop-header-nav-item__show-submenu-button {
  display: flex;
  align-items: center;
}

.desktop-header-nav-item__show-submenu-button svg {
  fill: var(--color-header-text-and-icon-colour);
}

.desktop-header-nav-item__show-submenu-button[aria-expanded="true"] + .desktop-header__subnav {
  display: block;
}

.desktop-header__nav-item--active > button + button .icon--arrow-down,
.desktop-header__nav-item--active > .desktop-header__nav-link + button .icon--arrow-down {
  fill: var(--color-header-active-text-colour);
}

/* END OF: Submenu dropdowns */

@media screen and (max-width: 1125px) {
  #desktop-header__menu-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    margin-left: auto;
    position: relative;
  }

  .desktop-header__nav-menu {
    display: none;
    transform-origin: top right;
    transform: scale(0);
    position: absolute;
    top: calc(1.6em + 57px);
    right: 50px;
    width: 310px;
    max-width: 90vw;
    text-align: right;
    background-color: var(--color-header-background-colour);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25em;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    overflow: hidden;
  }

  #desktop-header__menu-toggle[aria-expanded="true"] + .desktop-header__nav-menu {
    display: block;
    animation: scaleUpFromCorner 0.2s forwards;
  }

  @keyframes scaleUpFromCorner {
    to {
      transform: scale(1);
    }
  }

  .desktop-header__nav-list {
    display: block;
  }

  .desktop-header__nav-item {
    width: 100%;
    flex-wrap: wrap;
  }

  .desktop-header__nav-item + li {
    margin-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .desktop-header__nav-item[data-item="sign-in"],
  .desktop-header__nav-item[data-item="register"] {
    display: inline-flex;
    vertical-align: middle;
    width: 50%;
  }

  .desktop-header__nav-item[data-item="sign-in"]:after {
    display: none;
  }

  .desktop-header__nav-item[data-item="register"] {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  .desktop-header__nav-link,
  .desktop-header__nav-button {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 44px;
    padding-left: 2em;
    padding-right: 1em;
  }

  .desktop-header-nav-item__show-submenu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  .desktop-header-nav-item__show-submenu-button[aria-expanded="true"] + .desktop-header__subnav {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    padding-top: 0;
    margin-left: 0;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .desktop-header-subnav__content:before {
    display: none;
  }

  .desktop-header-subnav__content {
    padding: 0.5em 1em 0.5em 2.5em;
    background-color: var(--color-header-background-colour);
    border-radius: 0;
    box-shadow: none;
  }

  .desktop-header-subnav__list-item {
    text-align: left;
  }

  .desktop-header-nav__submenu-link {
    display: inline-block;
  }
  .desktop-header {
    z-index: 1005;
  }
}

.desktop-header__subnav--mega {
  left: auto;
  transform: none;
  margin-left: 0;
  right: 0;
}

.desktop-header-mega-subnav__column + .desktop-header-mega-subnav__column {
  margin-left: 3em;
}

.desktop-header-mega-subnav__content {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.desktop-header-mega-subnav__content:before {
  left: auto;
  right: 2.5em;
}

.desktop-header-mega-subnav__heading {
  margin: 0 0 0.7em;
  white-space: nowrap;
  font-size: 1.17em;
  border-bottom: 2px solid var(--color-header-navigation-item-border);
  border-radius: 1px;
  padding-bottom: 0.3em;
  color: var(--color-header-text-and-icon-colour);
}

.desktop-header-mega-subnav__list {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.desktop-header-mega-subnav__logout-button {
  min-height: 25px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-weight: 500;
  color: var(--color-header-text-and-icon-colour);
}

.desktop-header-mega-subnav__logout-button {
  transition: opacity 0.2s;
}

.desktop-header-mega-subnav__logout-button:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1125px) {
  /* Mega submenu */
  .desktop-header-mega-subnav__content {
    flex-direction: column;
    padding-top: 1em;
    padding-bottom: 1em;
    border-radius: 0;
    box-shadow: none;
  }

  .desktop-header-mega-subnav__column + .desktop-header-mega-subnav__column {
    margin-left: 0;
    margin-top: 1em;
  }

  .desktop-header-mega-subnav__heading {
    margin-bottom: 0.2em;
    padding-bottom: 0;
    border-bottom: none;
    border-radius: 0;
    text-align: left;
    font-size: 0.95em;
  }

  .desktop-header-mega-subnav__list {
    padding-left: 0.5em;
  }

  .desktop-header-mega-subnav__list > li {
    text-align: left;
  }

  .desktop-header-mega-subnav__logout-button {
    display: block;
    margin-left: auto;
  }
}

.giftCardLinkDotIndicator {
  position: absolute;
  right: -12px;
  top: 4.5px;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: var(--color-gift-nav-link-indicator-colour);
}
.hero-section-mobile {
  height: 0;
  overflow: hidden;
  padding-top: 50%;
  top: 61px;
  position: sticky;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0px 0px 11px 1px rgb(0 0 0 / 15%);
}

@media screen and (min-width: 660px) {
  .hero-section {
    height: 0;
    overflow: hidden;
    padding-top: 12%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: inset 0px 0px 11px 1px rgb(0 0 0 / 15%);
  }

  .hero-section.dashboard-hero {
    padding-top: 20%;
  }
}

.order-settings-component__main-container {
  position: fixed;
  bottom: 56px;
  background-color: var(--color-online-order-settings-background);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: center;
  box-shadow: 0 0.5px 3px 0.5px rgb(0 0 0 / 15%);
  min-height: 62px;
}

.order-settings-component__column {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.order-settings-component__column:first-child {
  max-width: 37%;
  min-width: 37%;
}
.order-settings-component__column {
  max-width: 48%;
  min-width: 48%;
}

.order-settings-component__column.select-store {
  min-width: 85%;
  max-width: 85%;
}

.order-settings-component__column:not(:first-child) {
  margin-left: 10px;
}

.order-settings-component__column.cart-button {
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  min-width: unset;
  display: flex;
  justify-content: center;
}

.order-settings__right-arrow {
  height: 10px;
  position: absolute;
  top: 5px;
  right: 0;
}

.order-settings__cart-button {
  width: 100%;
  height: 100%;
}
.order-settings__cart-button > span {
  background-color: red;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
}

.order-settings-component__row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.order-settings-component__store-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.order-settings-component__time-wrapper {
  margin-bottom: 0px;
}

.order-settings-component__order-type {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 5px;
}

.order-settings-component__store {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.order-settings-component__store-button {
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-bottom: 2px;
}

.order-settings-component__store-button::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateX(-15px);
  border-bottom: 2px solid var(--color-online-order-settings-background-contrast);
  width: 100%;
}

.order-settings-component__time-selection-main-container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  padding-bottom: 57px;
  top: 0;
  left: 0;
  z-index: 1100;
}

.order-settings-component__time-selection-inner-wrapper {
  background-color: #fff;
  width: 100%;
  min-height: 85%;
  max-height: 85%;
  position: absolute;
  bottom: 57px;
  left: 0;
  border-radius: 8px;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.order-settings-component__time-selection-header-wrapper {
  background-color: #eee;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;
  border-radius: 8px;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.order-settings-component__time-selection-header-wrapper > h4 {
  margin-left: 24px;
}

.order-settings-component__time-date-selection-wrapper {
  min-height: 50px;
  width: 100%;
  background-color: var(--color-online-order-settings-background);
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-settings-component__time-date-selection-wrapper > button > .icon {
  margin-top: 4px;
  margin-left: 10px;
  margin-right: 10px;
}

.order-settings-component__time-options-selection-wrapper {
  overflow-y: scroll;
  max-height: 85vh;
  padding-bottom: 20em;
}

.order-settings-component__time-options-selection-wrapper .form__field-label--radio {
  padding-left: 24px;
  min-height: 50px;
}

.order-settings-component__time-options-selection-wrapper
  .form__field-wrapper--checkbox-radio
  + .form__field-wrapper--checkbox-radio {
  margin-top: 0;
}

.order-settings-component__time-options-selection-wrapper .form__field-label--radio::after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.order-settings-component__time-options-selection-wrapper
  .form__field-wrapper--checkbox-radio:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.order-settings-component__time-options-selection-wrapper
  .form__field-label--checkbox-radio::before {
  display: none;
}
.order-settings-component__time-options-selection-wrapper
  .form__field-label--checkbox-radio::after {
  content: "";
  position: absolute;
  right: 24px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.order-settings-component__time-options-selection-wrapper
  .form__field--radio:checked
  + .form__field-label--radio::after {
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'><circle cx='50' cy='50' r='30'/></svg>");
  background-repeat: no-repeat;
  background-position: center;
}

.order-settings-component__time-options-confirm-button-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  min-height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--color-primary);
}

.order-settings-component__main-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: relative;
}

.order-settings-component__date-list-container {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}
.order-settings-component__date-list {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  list-style-type: none;
  justify-content: space-between;
  padding-left: 0;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.order-settings-component__date-list::-webkit-scrollbar {
  display: none;
}

.order-settings-component__date-list > li > button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.month-span {
  font-size: 16px;
  font-weight: 300;
}

.date-span {
  font-weight: 900;
  font-size: 25px;
}

.day-span {
  font-size: 14px;
  font-weight: 300;
}

.date-button {
  transform: scale(0.9);
  padding: 10px;
  margin-right: 10px;
}
.active-date-button {
  transform: scale(1.2);
  transition: all 0.2s ease-in-out;
}

.order-settings-component__time-options-selection-wrapper > ol {
  list-style: none;
  padding-left: 0;
  height: 100%;
  overflow-y: scroll;
}

.time-option__day-after-label {
  margin-left: 5px;
  color: var(--color-gray);
}

@media screen and (max-width: 660px) {
  .order-settings-component__store {
    width: 91%;
  }
}

/** Desktop design **/
@media screen and (min-width: 660px) and (max-width: 700px) {
  .order-settings-component__main-container {
    bottom: 25px;
    padding: 20px 180px 20px 180px;
  }
}
@media screen and (min-width: 660px) {
  .order-settings-component__main-container {
    bottom: 27px !important;
    padding: 15px 50px 15px 50px !important;
  }

  .order-settings-component__main-container.no-terms-enabled {
    bottom: 25px !important;
    padding: 15px 50px 15px 50px !important;
  }

  .order-settings-component__order-type {
    margin-left: 0;
    font-size: 16px;
  }

  .order-settings-component__row {
    width: 100%;
    align-items: center;
  }

  .order-settings-component__column.order-type_component {
    margin-right: 20px;
  }
  .order-settings-component__column.order-type_component:not(:first-child) {
    margin-left: 50px;
  }

  .order-settings-component__column.store_component {
    width: 30% !important;
  }

  .order-settings-component__column.order-time_component {
    width: 20% !important;
  }

  .order-settings-component__column.order-button_component {
    width: 20% !important;
  }

  .order-settings-component__column.cart_component {
    width: unset !important;
  }

  .order-settings-component__time-wrapper {
    width: 100%;
    margin-bottom: 0;
  }

  .order-settings-component__time-selection-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 5em 0;
  }

  .order-settings-component__time-selection-inner-wrapper {
    position: relative;
    border-radius: 10px;
  }

  .order-settings-component__time-options-confirm-button-wrapper {
    border-end-end-radius: 10px;
    border-end-start-radius: 10px;
  }

  .order-settings-component__time-options-selection-wrapper {
    max-height: 100%;
  }

  .order-settings-component__date-list-container {
    display: flex;
    overflow: hidden;
    position: relative;
    scroll-behavior: smooth;
  }

  .order-settings-component__date-list__right-left {
    padding-right: 30px;
  }
  .order-settings-component__date-list__right-arrow {
    padding-left: 30px;
  }
  .order-settings-component__date-list {
    overflow: unset;
    scroll-behavior: smooth;
  }

  .order-settings-dates__horizontal-slider-button {
    position: absolute;
    z-index: 10;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .order-settings-dates__horizontal-slider-previous-button {
    left: 0;
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.6));
  }

  .order-settings-dates__horizontal-slider-next-button {
    right: 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.6), #fff);
  }

  .order-settings-component__time-selection-inner-wrapper {
    bottom: unset;
  }

  .order-settings-component__time-options-selection-wrapper {
    padding-bottom: 0;
    display: inline-block;
    width: 100%;
    overflow: hidden;
  }

  .order-settings-component__time-options-selection-wrapper > ol {
    max-height: calc(100vh - 500px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .order-settings-component__column {
    max-width: unset !important;
    min-width: unset !important;
  }

  .order-settings-component__store-button::before {
    transform: translateX(0);
  }

  .order-settings-component__column.store_component,
  .order-settings-component__column.order-time_component {
    flex-direction: row;
  }

  .order-settings-component__row {
    position: relative;
  }

  .order-settings-component__column.cart_component {
    position: absolute;
    right: 0;
  }

  .order-settings-component__store-wrapper {
    margin-left: 5px;
  }

  .order-button_component {
    position: absolute;
    right: 60px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .order-button_component > button {
    padding: 10px 50px !important;
    margin-right: 0 !important;
    width: 100% !important;
  }
  .order-setting-component__store-label-wrapper {
    width: 35%;
  }
}

@media screen and (min-width: 768px) {
  .order-settings-component__main-container {
    bottom: 25px;
    padding: 20px 100px 20px 100px;
  }
}

@media screen and (min-width: 1000px) {
  .order-settings-component__time-selection-inner-wrapper {
    width: 40%;
  }

  .order-settings-component__main-container {
    bottom: 25px;
    padding: 20px 100px 20px 100px;
  }
}
@media only screen and (min-width: 861px) and (max-width: 1000px) {
  .order-settings-component__time-selection-inner-wrapper {
    width: 40%;
  }
}

@media only screen and (min-width: 660px) and (max-width: 860px) {
  .order-settings-component__time-selection-inner-wrapper {
    width: 50% !important;
  }
}

/** Small Phones **/

@media only screen and (max-width: 450px) {
  .order-settings-component__column:first-child {
    max-width: 37%;
    min-width: 37%;
  }
  .order-settings-component__column {
    max-width: 48%;
    min-width: 48%;
  }
  .order-settings-component__column.select-store {
    min-width: 85%;
    max-width: 85%;
  }
}

@media only screen and (max-width: 400px) {
  .order-settings-component__column:first-child {
    max-width: 39%;
    min-width: 39%;
  }
  .order-settings-component__column {
    max-width: 45%;
    min-width: 45%;
  }
  .order-settings-component__column.select-store {
    min-width: 85%;
    max-width: 85%;
  }
}

@media only screen and (max-width: 350px) {
  .order-settings-component__column:first-child {
    max-width: 40%;
    min-width: 40%;
  }
  .order-settings-component__column {
    max-width: 45%;
    min-width: 45%;
  }
  .order-settings-component__column.select-store {
    min-width: 85%;
    max-width: 85%;
  }
}

@media only screen and (max-width: 320px) {
  .order-settings-component__column:first-child {
    max-width: 40%;
    min-width: 40%;
  }
  .order-settings-component__column {
    max-width: 44%;
    min-width: 44%;
    font-size: 0.8em;
  }
  .order-settings-component__column.select-store {
    min-width: 85%;
    max-width: 85%;
  }
}

.login-register__main-wrapper {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.login-or-register-buttons {
  bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*padding: 1em 0;*/
  width: 100%;
}

.button--circle-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid var(--color-primary);
  border-radius: 100px;
  width: 100%;
}

.button--circle-wrapper + .button--circle-wrapper {
  margin-top: 30px;
}

.button--circle-wrapper {
  opacity: 0;
  animation: slideCircleButtonIn 0.6s forwards;
  animation-delay: 3.2s;
}

.button--circle-wrapper:first-child {
  transform: translateX(100%);
}

.button--circle-wrapper:nth-child(2) {
  transform: translateX(-100%);
}

.button--circle-fade-out {
  animation: fadeOut 0.3s forwards;
}

.button--circle-move-up {
  animation: moveLoginCircleButtonUp 0.3s forwards;
  animation-delay: 0.3s;
}

.user-is-greeted .button--circle-wrapper {
  opacity: 1;
  transform: none;
}

.button--circle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 50%;
  padding: 1em 0;
}

.button--circle {
  /*box-shadow: 0px 9px 14px 2px rgba(0, 0, 0, 0.21);*/
}

.button-circle--pressed-in {
  animation: pressInCircleButton 0.3s forwards;
}

.button--circle__instruction,
.label--circle__instruction {
  width: 250px;
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  white-space: pre-wrap; /* for \n line-breaks to work in props */
}

.button--circle__instruction {
  opacity: 0;
  transform: translateY(15px);
  animation: slideUpCircleButtonLabel 1s forwards;
}

.button--circle-wrapper:first-child .button--circle__instruction {
  animation-delay: 3.7s; /* after button slide-in */
}

.button--circle-wrapper:nth-child(2) .button--circle__instruction {
  animation-delay: 4s; /* after button slide-in + delay after first label slide-up */
}

.user-is-greeted .button--circle__instruction {
  opacity: 1;
  transform: none;
}

.label--circle-wrapper {
  position: absolute;
  top: 59%;
  left: 50%;
  transform: translate(-50%, -59%);
  height: 360px;
  background-color: #fff;
}

.password__label--circle {
  color: #586320;
  font-size: 2.5rem;
  letter-spacing: 2.5px;
}

.label--circle__secondary-instruction {
  margin: 18px 0 0;
  font-weight: 400;
  text-align: center;
}

.label--circle > .icon {
  transform: scale(0);
  animation: scaleUpCircleLabelIcon 0.3s forwards;
}

.login-or-register__register > a > span {
  margin-left: 5px;
  color: var(--color-secondary);
  font-size: 1.1rem;
  font-weight: 500;
}

.login-register__bottom-link.link--continue-as-guest {
  color: var(--color-secondary);
  font-size: 1.1rem;
  font-weight: 500;
}

@keyframes slideCircleButtonIn {
  to {
    opacity: 1;
    transform: none;
    transform: initial;
  }
}

@keyframes moveLoginCircleButtonUp {
  to {
    /* moves it in place of the Registration Circle Button */
    /* -circle button container - margin in-between 2 buttons */
    transform: translateY(calc(-165px - 30px));
  }
}

@keyframes slideUpCircleButtonLabel {
  to {
    opacity: 1;
    transform: none;
    transform: initial;
  }
}

@keyframes pressInCircleButton {
  to {
    box-shadow: none;
  }
}

@keyframes scaleUpCircleLabelIcon {
  100% {
    transform: scale(1);
  }
}

@media screen and (max-height: 388px) {
  .label--circle-wrapper {
    visibility: hidden;
  }
}

@media screen and (max-width: 660px) {
  .login-register__main-wrapper {
    overflow-y: scroll;
  }
}

.login__form-wrapper {
  background-color: #fff;
  animation: fadeIn 0.3s forwards;
}

.login-with-temp-password__heading,
.change-temp-password__heading {
  position: relative;
  margin: 0px;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
}

.form--login__submit-button-wrapper {
  display: flex;
  justify-content: center;
}
#form--login__submit-button {
  /* width: unset;
  max-width: unset;
  min-width: 75%; */
  white-space: normal;
  word-wrap: break-word;
}

#form--login__submit-button > .button--submit-form__success {
  white-space: normal;
  word-wrap: break-word;
}

.login-with-temp-password__wrapper,
.change-temp-password__wrapper {
  padding: 4em 2em 2em 2em;
}

@media screen and (min-width: 660px) {
  #form--login .form__field-wrapper--checkbox {
    align-items: unset;
  }

  .login-with-temp-password__wrapper,
  .change-temp-password__wrapper {
    justify-content: center;
    max-width: 60%;
    min-width: 60%;
    border-radius: 10px;
    border: 1px solid var(--color-gray-light);
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    padding: 4em;
    height: auto;
    background-color: #fff;
  }
}

@media screen and (min-width: 1028px) {
  .login-with-temp-password__wrapper,
  .change-temp-password__wrapper {
    max-width: 45%;
    min-width: 45%;
    padding: 4em;
  }
}

.login-register__form-wrapper {
  bottom: 85px;
  left: 0;
  right: 0;
  padding: 0 35px;
  width: 100%;
  margin: 35px auto;
}

.forgot-password__confirm-email-message {
  width: 100%;
  margin: 0.5em auto 0.5em auto;
}

.forgot-password__form-wrapper,
.login-temporary-password__form-wrapper,
.new-password__form-wrapper {
  transform: translateX(100vw);
  animation: slideInLoginRegisterForm 0.5s forwards;
}

.login-temporary-password__form-wrapper {
  padding: 0px;
}

.forgot-password__main-wrapper {
  padding: 4em 2em 2em 2em;
}

.login-register__bottom-link-wrapper {
  opacity: 0;
  bottom: 10px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  animation: fadeIn 0.2s forwards;
}

.link--forgot-password-wrapper {
  position: relative;
  animation-delay: 1s;
}

.user-is-greeted .link--continue-as-guest-wrapper {
  opacity: 1;
  animation-delay: 0s;
}

.login-register__bottom-link {
  display: inline-block;
  height: 44px;
  padding: 0px 18px;
  color: var(--color-secondary);
  font-size: 1.1rem;
  line-height: 44px;
  font-weight: 500;
}

.link--continue-as-guest-fade-out,
.greeting-message-fade-out {
  animation: fadeOut 0.3s forwards;
}

@keyframes slideInLoginRegisterForm {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.login__form-wrapper .form__field {
  border-radius: 0;
  width: 100%;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #cdcdcd;
}

.login__form-wrapper .form__field-label {
  display: block;
  text-align: left;
  position: static;
  position: initial;
}

/* .login__form-wrapper .form__field-wrapper--in-focus > .form__field-label {
  transform: none;
  transition: none;
} */

@media screen and (max-width: 660px) {
  .login-register__main-container {
    padding-bottom: 0;
  }
}

.login__form-wrapper .form-submit__wrapper {
  /*padding: 0 20%;*/
}

@media screen and (min-width: 660px) {
  .forgot-password__confirm-email-message {
    margin: 2.5em auto 2.5em auto;
    display: flex;
    justify-content: center;
  }

  .button--submit-form-wrapper {
    margin-top: 3em;
  }

  .login-register__form-wrapper {
    padding: 60px 60px 60px;
    margin: unset;
  }

  .login-register__main-container {
    display: flex;
    justify-content: center;
  }

  .login-register__main-wrapper {
    max-width: 60%;
    min-width: 60%;
    border-radius: 10px;
    border: 1px solid var(--color-gray-light);
    margin-top: 2em;
    height: auto;
    background-color: #fff;
  }

  .login-or-register-buttons {
    position: relative;
    padding-top: 70px;
  }

  .login-register__main-wrapper {
    margin-top: 1em;
  }

  .login-temporary-password__form-wrapper {
    margin-top: 2.5em;
    padding: 0px;
  }

  .new-password__form-wrapper {
    margin-top: 2.5em;
    padding: 0px;
  }

  .forgot-password__main-wrapper {
    padding: 4em;
  }
}

@media screen and (min-width: 1028px) {
  .login-register__main-wrapper {
    max-width: 45%;
    min-width: 45%;
  }

  .login-temporary-password__form-wrapper {
    margin-top: 2.5em;
    padding: 0px;
  }

  .new-password__form-wrapper {
    margin-top: 2.5em;
    padding: 0px;
  }

  .forgot-password__main-wrapper {
    padding: 4em;
  }
}

.dashboard-welcome-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d64123;
  z-index: 1000;
}

.dashboard-welcome-overlay__content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-welcome-overlay__circle-mask {
  transform: scale(0);
  width: 210px;
  height: 210px;
  clip-path: circle(50%);
  margin-bottom: 36px;
  background-color: #fff;
  animation: scaleUpDashboardWelcomeCircleMask 0.3s forwards cubic-bezier(0.74, 1.03, 1, 1.13);
}

@keyframes scaleUpDashboardWelcomeCircleMask {
  100% {
    transform: scale(1);
  }
}

.dashboard-welcome-overlay__heading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%);
  margin: 0;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.75rem;
  text-align: center;
  white-space: nowrap;
}

.dashboard-welcome-overlay__loading-text {
  margin: 0 0 18px;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
}

.dashboard-welcome-overlay__loading-dots {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto;
}

.dashboard-welcome-overlay__loading-dot {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  animation: growWelcomeLoadingDot 1.5s infinite;
  animation-delay: 0.2s;
}

@keyframes growWelcomeLoadingDot {
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.dashboard-welcome-overlay__loading-dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dashboard-welcome-overlay__loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}

.dashboard-welcome-overlay__loading-dot:nth-child(4) {
  animation-delay: 0.5s;
}

.dashboard__module-main {
  position: relative;
}

.rewards-module__balance-and-my-rewards-link {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  flex-direction: column;
}

.rewards-module__balance-and-my-rewards-link > .rewards-module__balance-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rewards-module__balance-and-star {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.rewards-module__balance-and-star > div > div {
  display: flex;
  justify-content: space-between;
}

.rewards-module__balance-and-star > div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.rewards-module__balance {
  font-size: 1.5em;
  font-weight: 500;
}

.rewards-module__balance-and-star > div > div > span > .icon--star {
  width: 20px;
  height: 20px;
  fill: #f49c00;
  stroke: none;
}

.rewards-module__masked-card-text {
  color: #2a2a2a;
  font-size: 1.2rem;
  flex-direction: column;
  display: flex;
  align-items: flex-end;
}

.rewards-module__masked-card {
  font-size: 12px;
}

.rewards-module__balance-label {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}

.rewards-module__balance-label-header {
  font-size: 18px;
  font-weight: 600;
}

.rewards-module__balance-money-container {
  width: 51%;
}

.rewards-module__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.rewards-module__row:not(:last-child) {
  margin-bottom: 20px;
}

.rewards-module__my-rewards-link {
  font-size: 16px;
}

.rewards-module__column {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.rewards-module__stepper-wrapper {
  height: 4.5em;
}

.rewards-module__stepper {
  display: flex;
  list-style-type: none;
  width: 100%;
  height: 100%;
  margin: 0 0 0 -11.25px;
  padding-left: 0;
  overflow: auto;
  white-space: nowrap;
}

.rewards-module__step {
  position: relative;
  display: flex;
  color: #878786;
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.5;
  text-align: left;
  white-space: nowrap;
  overflow: visible;
}

.rewards-module__step-line {
  width: 3em;
  height: 2px;
  margin-top: calc(1.25em / 2);
  background-color: #878786;
}

.rewards-module__step--redeemable .rewards-module__step-line {
  background-color: #f49c00;
}

.rewards-module__step > .rewards-module__step-line:first-child {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rewards-module__step > .rewards-module__step-line:last-child {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rewards-module__step:first-child > .rewards-module__step-line {
  border-radius: 9999px 0 0 9999px;
}

.rewards-module__toggle-reward-name-button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 2.5em;
  height: 2.5em;
}

.rewards-module__toggle-reward-name-button--active > .icon--star {
  border: 1px solid #f49c00;
  border-radius: 50%;
}

.rewards-module__step--redeemable .rewards-module__toggle-reward-name-button {
  color: #f49c00;
}

.rewards-module__toggle-reward-name-button > .icon--star {
  width: 1.25em;
  height: 1.25em;
  fill: #878786;
  stroke: none;
}

.rewards-module__step--redeemable .icon--star {
  fill: #f49c00;
}

.rewards-module__step-name {
  position: absolute;
  top: 4em;
  width: 100%;
  font-size: 0.75em;
  font-weight: 400;
  text-align: center;
}

.rewards-module__step:first-child .rewards-module__step-name {
  left: 0;
  width: 55px;
  text-align: left;
}

.rewards-module__step:last-child .rewards-module__step-name {
  left: auto;
  right: 0;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rewards-module__step--redeemable .rewards-module__step-name {
  color: #2a2a2a;
}

.rewards-module__empty-step {
  display: flex;
  flex-grow: 1;
}

.rewards-module__empty-step .rewards-module__step-line {
  width: 100%;
}

.rewards-module__balance-money {
  font-size: 1rem;
}

.rewards-module__row > span:first-child {
  min-width: 60px;
}

.dashboard_rewards-module-question-container {
  margin-top: 10px;
}

@media screen and (min-width: 660px) {
  .rewards-module__balance-wrapper {
    display: flex;
    align-items: center;
  }

  .rewards-module__balance-and-star {
    margin-right: 8px;
  }

  .rewards-module__toggle-reward-name-button {
    font-size: 21px;
  }

  .rewards-module__links-wrapper {
    display: flex;
    flex-direction: column;
  }

  .rewards-module__links-wrapper > a + a {
    margin-top: 8px;
  }

  .rewards-module__stepper {
    overflow-x: hidden;
  }

  .rewards-module__stepper:hover {
    overflow-x: auto;
  }

  .rewards-module__masked-card-text {
    margin-right: 0.85em;
  }

  .rewards-module__row > .rewards-module__balance > .icon--star {
    width: 25px;
    height: 25px;
    margin-bottom: 2px;
  }

  .rewards-module__column {
    width: 100%;
  }
  .rewards-module__balance {
    align-items: center;
  }

  .rewards-module__row:not(:last-child) {
    margin-bottom: 10px;
  }

  .rewards-module__row {
    margin-bottom: 10px;
  }

  .dashboard__rewards-module {
    margin-bottom: 0 !important;
  }

  .rewards-module__row.view-rewards-link {
    margin-bottom: 0;
    margin-top: 15px;
  }
}

@media screen and (min-width: 1366px) {
  .rewards-module__stepper {
    overflow-x: hidden;
  }

  .rewards-module__stepper:hover {
    overflow-x: auto;
  }
}

.dashboard__header {
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  margin-bottom: 1em;
}

.dashboard__greeting-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 28px;
  justify-content: space-between;
  width: 100%;
}

.dashboard__greeting-wrapper.not-loggedin {
  justify-content: flex-start;
}

.dashboard__greeting-wrapper.not-loggedin a {
  justify-content: flex-start;
  margin-left: 10px;
}

.dashboard__greeting-wrapper > .icon {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  color: #2a2a2a;
  margin-top: 14px;
}

.dashboard__greeting {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
  color: #2a2a2a;
  max-width: 80%;
}

.dashboard-header__content-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-header__language-dropdown-toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-header__language-dropdown-toggle-button > svg {
  width: 18px;
  height: 18px;
  color: var(--color-header-text-and-icon-colour);
}

.dashboard-header__language-dropdown {
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  max-width: 150px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
  transform-origin: top left;
  transform: scale(0);
  animation: revealLanguageDropdown 0.2s forwards;
}

.dashboard__rewards-module {
  width: 100%;
}
.user-greetings__rewards-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 12px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0 3.5px 3px 0.5px #00000026;
}

.user-greetings__rewards-container-loader {
  position: relative;
}

.dashboard__header-container {
  margin-bottom: 10px;
  padding: 0;
  padding-right: 20px;
}

.dashboard__greeting-loyalty-button {
  border-radius: 20px;
  padding: 3px 5px;
  position: relative;
}

.dashboard__greeting-loyalty-button.collapsed {
  background-color: #fff;
}

.dashboard-greeting__rewards-button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

@keyframes revealLanguageDropdown {
  to {
    transform: scale(1);
  }
}

/* Mobile Only **/
@media screen and (max-width: 660px) {
  .user-greetings__rewards-container {
    box-shadow: 0px 0.5px 3px 0.5px #00000026;
  }
}

@media screen and (min-width: 660px) {
  .dashboard__header {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 15px;
  }
  .dashboard__greeting-wrapper > .icon {
    width: 25px;
    height: 25px;
  }

  .dashboard__greeting {
    font-size: 1.3rem;
    max-width: 100%;
  }

  .user-greetings__rewards-container {
    border-top: 1px solid #eeeeee;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .expanded-rewards-details {
    border-bottom-right-radius: 0 !important;
    box-shadow: 0px 0.5px 3px 0.5px #00000026;
  }

  .expanded-rewards-details.dashboard-action-button {
    border-bottom-left-radius: 0 !important;
  }
  .expanded-rewards-details .dashboard-action-button__icon-container {
    border-bottom-left-radius: 0 !important;
  }
}

.customization-modal__container {
  display: flex;
  flex-direction: column;
}

.customization-modal__container > .dialog-modal {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  padding: 0;
  margin-top: auto;
}

.customization-modal__container .dialog-modal__buttons {
  display: none;
}

.customization-modal__container .dialog-modal__close-button {
  margin-right: 0.5em;
}

.customization-modal__header {
  box-shadow: 0px 3px 5px 0px rgba(46, 46, 46, 0.09);
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 0.9em;
  padding-top: 1.5em;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.customization-modal__header h2 {
  margin-top: 0.5em;
  font-size: 1.2em;
}

.customization-modal__top-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.customization-modal__top-buttons button:disabled,
.customization-modal__top-buttons a:disabled {
  font-size: 0.8em;
  font-weight: 600;
  background-color: transparent;
}

.customization-modal__top-buttons button:not(:disabled),
.customization-modal__top-buttons a:not(:disabled) {
  color: var(--color-secondary);
  font-size: 0.8em;
  font-weight: 600;
}

ul.customization-modal__items {
  padding: 0;
  margin: 0;
}

ul.customization-modal__items .cartItem__wrapper {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

ul.customization-modal__items ul {
  padding-left: 0;
}

.customization-modal__items .cartItem {
  border-bottom: 3px solid var(--color-gray-light);
  border-radius: 0;
  list-style-type: none;
}

.customization-modal__items .cartItem.slideRight {
  transform: translateX(200%);
  animation: slideModalCartItem 0.5s ease-out, collapseModalCartItem 0.5s ease-out 0.5s both;
  animation-iteration-count: 1;
}

.customization-modal__done_button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-online-order-settings-background);
  min-height: 86px;
  position: sticky;
  bottom: 0;
  width: 100%;
}

.cart-item__option.indentOneLevel {
  margin-left: 1em;
}

.cart-item__option.indentTwoLevels {
  margin-left: 0.4em;
}

@media screen and (min-width: 660px) {
  .customization-modal__container > .dialog-modal {
    max-width: 1200px;
    border: 2px solid #fff;
    margin: auto;
    max-height: 90%;
  }

  .customization-modal__content__wrapper {
    padding-bottom: 2em;
  }

  .customization-modal__header {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }

  .customization-modal__done_button_container {
    border-radius: 0 0 7px 7px;
  }

  .customization-modal__done_button_container > .button {
    padding: 0.75em 1em;
    width: unset;
    font-size: 17px;
  }

  ul.customization-modal__items {
    margin-top: 0;
  }

  ul.customization-modal__items .cartItem__wrapper {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }

  ul.customization-modal__items .cartItem__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  ul.customization-modal__items .cartItem__wrapper > div {
    width: 45%;
  }

  .customization-modal__items .cartItem__buttons-container {
    border: none;
    padding: 0;
    margin: 0;
  }

  .customization-modal__items .cartItem__quantity-buttons-container > button > svg {
    display: block;
    width: 1.5em;
    height: 1.5em;
  }

  .customization-modal__container .dialog-modal__close-button {
    margin-right: 0.75em;
    margin-top: 0.75em;
    z-index: 1;
  }

  .customization-modal__items .cartItem {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .customization-modal__header h2 {
    margin-top: 0.5em;
    font-size: 0.9em;
  }

  .customization-modal__top-buttons button:not(:disabled),
  .customization-modal__top-buttons a:not(:disabled) {
    font-size: 0.7em;
    font-weight: 600;
  }

  .customization-modal__items h3,
  .customization-modal__items h3 + .cartItem__price {
    font-size: 0.9rem;
  }
}

@keyframes slideModalCartItem {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}

@keyframes collapseModalCartItem {
  from {
    max-height: 500px;
    margin: 0 auto 10px;
  }
  to {
    max-height: 0;
    margin: 0;
  }
}

.menu-category-card__quick-add-button-container {
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom-left-radius: 0.4em;
  border-bottom-right-radius: 1em;
}

.menu-category-card__quick-add-button__quantity-input {
  max-width: 50%;
  text-align: center;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border: none;
  border-bottom: 1px solid var(--color-gray);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  margin: auto;
  height: calc(100% - 2px);
}

.menu-category-card__quick-add-button__quantity-input.collapse {
  animation-name: fadeOut;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
}

.menu-item-quick-button {
  background-color: var(--color-primary);
  border-radius: 50%;
  padding: 0.5em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
}

.menu-item-quick-button.plus.withCustomization:active::after {
  background-color: #000000;
  content: "";
  width: 100%;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 40px;
  opacity: 0.3;
}

.menu-item-quick-button.plus.withoutCustomization:active::after {
  background-color: #000000;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 40px;
  opacity: 0.3;
}

.menu-item-quick-button.minus:active::after {
  background-color: #000000;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 40px;
  opacity: 0.3;
}

.menu-item-quick-button.isEditButton:active::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  left: -3px;
  width: 50%;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  z-index: 0;
}

.menu-item-quick-button-container.withQuantity.withCustomization
  > .menu-item-quick-button:active::after {
  background-color: #000000;
  content: "";
  width: 110%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 40px;
  opacity: 0.3;
}

.menu-item-quick-button:disabled {
  color: unset;
  background-color: var(--color-primary);
}

.menu-item-quick-button.minus:disabled::after {
  background-color: #fff;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  opacity: 0.6;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/*.menu-item-quick-button.plus:disabled::after {
  background-color: #fff;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  left: unset;
  border-radius: 50%;
  opacity: 0.6;
  z-index: 4;
}*/

.menu-item-quick-button.plus:disabled > svg {
  opacity: 0.2;
}

.menu-item-quick-button.plus.withCustomization:disabled::after {
  background-color: #fff;
  content: "";
  width: 100%;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  left: unset;
  border-radius: 40px;
  opacity: 0.6;
  z-index: 4;
}

.menu-item-quick-button.plus {
  right: -5px;
  bottom: 0;
}

.menu-item-quick-button.plus:disabled {
  right: 0;
  bottom: 0;
}

.menu-item-quick-button.minus {
  position: absolute;
  animation-name: slideMinusButtonLeft;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  bottom: 0px;
  z-index: 4;
  min-height: 30px;
  min-width: 30px;
  padding: 0.4em;
  margin-bottom: 2px;
}

.menu-item-quick-button.plus.withQuantity {
  position: absolute;
}

.menu-item-quick-button.plus.withQuantity {
  bottom: 0;
  right: -5px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  height: 100%;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}

.menu-item-quick-button.isEditButton {
  width: 100%;
  background-color: transparent;
}

.menu-item-quick-button-container {
  background-color: transparent;
  min-height: 2.5em;
  min-width: 4.2em;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  width: 60%;
}

.menu-item-quick-button-container.expanded {
  width: 100%;
  flex-direction: row-reverse;
}

.menu-item-quick-button-container.withQuantity.withCustomization {
  /*background-color: var(--color-secondary);*/
  min-width: 4.2em;
  /*animation: expandHorizontally 1s ease-in;*/
}

.menu-item-quick-button-quantity-span {
  border-radius: 50%;
  padding: 0.1em;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 14px;
  bottom: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.menu-item-quick-button-container.withCustomization .menu-item-quick-button-quantity-span {
  bottom: 0;
  width: 0;
  min-width: 0;
  min-height: 0;
  padding: 0;
  transform: translateX(11px);
  animation: slideQuantityContainer 0.3s linear;
  animation-fill-mode: forwards;
}

.menu-item-quick-button-container.withCustomization
  .menu-item-quick-button-quantity-span.withoutPlus {
  bottom: 0;
  width: 0;
  min-width: 0;
  min-height: 0;
  padding: 0;
  transform: translateX(0px);
  animation: none;
}

.menu-item-quick-button-container.withoutCustomization .menu-item-quick-button-quantity-span {
  bottom: 0;
  width: 0;
  min-width: 0;
  min-height: 0;
  padding: 0;
  transform: translateX(11px);
  animation: slideQuantityContainerWithMinus 0.3s linear;
  animation-fill-mode: forwards;
}

.menu-item-quick-button-container__edit-quantity {
  background-color: var(--color-secondary);
  animation: slideEditContainer 0.3s linear;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  height: 100%;
  display: flex;
  border-radius: 40px;
  justify-content: space-between;
  align-items: center;
  right: 0px;
  position: absolute;
}

.menu-item-quick-button-container__edit-quantity.withoutPlus {
  background-color: var(--color-secondary);
  animation: slideEditContainerWithoutPlus 0.3s linear;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  height: 100%;
  display: flex;
  border-radius: 40px;
  justify-content: space-between;
  align-items: center;
  right: 0px;
  position: absolute;
}

@keyframes slideEditContainer {
  0% {
    width: 50px;
  }
  100% {
    width: 95px;
  }
}

@keyframes slideEditContainerWithoutPlus {
  0% {
    width: 50px;
  }
  100% {
    width: 75px;
  }
}

@keyframes slideEditContainerDesktop {
  0% {
    width: 50px;
  }
  100% {
    width: 100px;
  }
}

@keyframes slideQuantityContainer {
  0% {
    right: 0;
    z-index: 0;
    min-width: 0;
    min-height: 0;
    transform: translateX(11px);
  }
  100% {
    z-index: 2;
    min-width: 25px;
    min-height: 40px;
    transform: translateX(0);
    width: unset;
  }
}

@keyframes slideQuantityContainerWithMinus {
  0% {
    right: 0;
    z-index: 0;
    min-width: 0;
    min-height: 0;
    transform: translateX(11px);
  }
  100% {
    z-index: 2;
    min-width: 50px;
    min-height: 40px;
    transform: translateX(1);
    width: unset;
  }
}

.menu-item-quick-button-icon-span > .icon--edit {
  margin-left: 0.5em;
  padding: 0.2em;
}

/* Animations */
@keyframes slideMinusButtonLeft {
  0% {
    right: 0;
    opacity: 0;
  }
  100% {
    right: 45px;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes expandHorizontally {
  0% {
    min-width: 0;
  }
  100% {
    min-width: 4.2em;
  }
}
.orderLevelSuggestedItemQuickAddButtonContainer{
  max-height: 2.5em !important;
  min-height: auto !important;
}
.orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button-icon-span > .icon--edit{
  padding: 0.15em;
  padding-left: 0;
  margin-top:4px
}
.orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button.plus.withQuantity{
  bottom: -0.5px !important;
  right: -5px;
}
.orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button.minus{
  bottom: 1px;
  height: 30px !important;
  min-height: 30px !important;
}

.orderLevelSuggestedItemButtonPlusContainer.menu-item-quick-button.plus{
  max-height: 40px !important;
  min-height: auto !important;
  bottom: 0px;
  right: -5px;
  max-width: 1.66em;
}
.suggestedItemQuickAdd{
  position: absolute !important;
  border-radius: 100%;
  bottom: 0;
  background-color: #fff !important;
}
.suggestedItemQuickAdd .menu-item-quick-button {
  padding: 8px;
}
.orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button-container__edit-quantity{
  height: 40px;
  right: 6px !important;
  bottom: -0.5px;
}
.orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button-container__edit-quantity.withoutPlus {
  right: 2px !important;
}
.orderLevelSuggestedItemQuickAddButtonContainer .suggestedQuickAddButtonMinus.menu-item-quick-button.minus{
  background-color: transparent;
}
.orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button-quantity-span{
  font-size: 1rem;
}
@media screen and (min-width: 660px) {
  .menu-item-quick-button-container__edit-quantity {
    background-color: var(--color-secondary);
    animation: slideEditContainerDesktop 0.3s linear;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    height: 100%;
    display: flex;
    border-radius: 40px;
    justify-content: space-between;
    align-items: center;
    right: 0px;
    position: absolute;
  }
}

@media screen and (max-width: 660px) {
  .orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button.plus.withQuantity{
    bottom: -0.5px !important;
    right: -5px;
  }
  .orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button.minus{
    bottom: 1px;
    height: 30px !important;
    min-height: 30px !important;
  }
  
  .orderLevelSuggestedItemButtonPlusContainer.menu-item-quick-button.plus{
    max-height: 2.5em !important;
    min-height: auto !important;
    bottom: 0px;
    right: -5px;
    height: 40px
  }
  .orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button-container__edit-quantity{
    height: 40px;
    right: 0 !important;
    bottom: -1 px;
  }
  .suggestedItemQuickAdd .menu-item-quick-button{
    padding: 0.5em;
  }
  .orderLevelSuggestedItemQuickAddButtonContainer .menu-item-quick-button-icon-span > .icon--edit{
   
    margin-top:2px
  }
}



.menu__category-card {
  position: relative;
  margin: 0.5em;
  width: calc(50% - 1em);
  border: 1px solid #dadada;
  border-radius: 0.5em;
  z-index: 0;
  /*overflow: hidden;*/
}

.menu__category-card.withQuickAddButton {
  border-bottom-right-radius: 1em;
}

.menu-category-card__content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media screen and (max-width: 320px) {
  .menu__category-card {
    width: calc(100% - 1em);
  }

  .styles-module_item-container__a8zaY {
    width: calc(100% - 1em);
  }
}

@media screen and (min-width: 540px) {
  .menu__category-card {
    width: calc(100% / 3 - 1em);
  }
  .styles-module_item-container__a8zaY {
    width: calc(100% / 3 - 1em);
  }
}

@media screen and (min-width: 1080px) {
  .menu__category-card {
    width: calc(100% / 5 - 1em) !important;
  }

  .styles-module_item-container__a8zaY {
    width: calc(100% / 5 - 1em) !important;
  }
}

@media screen and (min-width: 1280px) {
  .menu__category-card {
    width: calc(100% / 5 - 1em);
  }

  .styles-module_item-container__a8zaY {
    width: calc(100% / 5 - 1em) !important;
  }
}

@media screen and (min-width: 1545px) {
  .menu__category-card {
    width: calc(100% / 6 - 1em);
  }
  .styles-module_item-container__a8zaY {
    width: calc(100% / 6 - 1em) !important;
  }
}

.menu-category-card__image-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  background-color: #fff;
  overflow: hidden;
  border-top-left-radius: 0.4em;
  border-top-right-radius: 0.4em;
}

.menu-category-card__image,
.menu-category-card__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.menu-category-card__image-wrapper > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}

.menu-category-card___text-wrapper {
  flex-grow: 1;
  padding: 0.5em 0.5em 0.7em;
  background-color: #fff;
  overflow: hidden;
  border-bottom-left-radius: 0.4em;
  border-bottom-right-radius: 0.4em;
}

.menu-category-card__name {
  --line-height: 0.9rem;
  --max-lines: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: var(--line-height);
  height: calc(var(--line-height) * var(--max-lines));
  overflow: hidden;
}

.menu-category-card__price {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
}

.menu-category-card__limit {
  margin-top: 0.7rem;
  padding: 0 0.5em;
}

.menu-category-card__calories {
  text-align: center;
  font-size: 0.9rem;
}

.menu-category-card__button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dashboard__module-header {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.dashboard__module-header > .icon {
  width: 1.125em;
  height: 1.125em;
}

.dashboard__module-heading {
  margin: 0 0 0 0.3em;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 100%;
}

.dashboard__module-header-button,
.dashboard__module-header-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

@media screen and (min-width: 660px) {
  .dashboard__module-heading {
    margin-left: 0;
  }
}

.dashboard__module--horizontal-slider .dashboard-section__body.desktop-container {
  padding-right: 0;
}

.dashboard__horizontal-slider-wrapper {
  position: relative;
  overflow: hidden;
  padding: 10px 0 18px 0px;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.dashboard-horizontal-slider__list.featured-slider {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin: -0.5em;
  padding: 0;
  list-style-type: none;
  animation: fadeIn 0.2s forwards;
}

.dashboard-horizontal-slider__list.featured-slider > .menu__category-card {
  border: none;
  box-shadow: none;
}
.dashboard-horizontal-slider__list > .dashboard-horizontal-slider__card:first-child {
  margin-left: 2px;
}

@media screen and (max-width: 660px) {
  .dashboard__module--horizontal-slider .dashboard-section__body.desktop-container {
    padding-left: 0;
  }

  .dashboard-horizontal-slider__list .menu-category-card___text-wrapper {
    text-wrap: wrap;
  }

  .dashboard-horizontal-slider__list.featured-slider > .menu__category-card {
    min-width: 50%;
  }
}

@media screen and (min-width: 1248px) {
  .dashboard-section__body.desktop-container .dashboard__horizontal-slider-wrapper {
    margin-left: -24px;
  }
}

.dashboard__horizontal-slider-wrapper::-webkit-scrollbar {
  display: none;
}

body.body--touch-device .dashboard__horizontal-slider-wrapper {
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

body.body--iOS .dashboard__horizontal-slider-wrapper {
  -webkit-overflow-scrolling: touch; /* enables iOS "momentum" style scrolling */
}

.dashboard-horizontal-slider__list {
  white-space: nowrap;
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  transition: transform 0.3s;
}

.dashboard-slide__image-and-text-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  flex-direction: column;
}

body:not(.body--touch-device) .dashboard__featured-item-img {
  transition: transform 0.3s;
}

body:not(.body--touch-device)
  .dashboard-slide__image-and-text-wrapper:hover
  .dashboard__featured-item-img {
  transform: scale(1.1);
}

.dashboard-horizontal-slider__list > li {
  display: inline-block;
}

.dashboard-horizontal-slider__card {
  --card-ratio: 205 / 400;
  position: relative;
  width: 90%;
  height: 0;
  overflow: hidden;
  border-radius: 9px;
  box-shadow: 0 0.5px 3px 0.5px rgb(0 0 0 / 15%);
}

.dashboard-horizontal-slider__list--1-in-view .dashboard-horizontal-slider__card {
  padding-top: calc(var(--card-ratio) * 90%);
}

.dashboard-horizontal-slider__list--2-in-view .dashboard-horizontal-slider__card {
  width: calc(100% / 2 - 1em - 60px);
  padding-top: calc(var(--card-ratio) * (100% / 2 - 1em - 60px));
}

.dashboard-horizontal-slider__list--3-in-view .dashboard-horizontal-slider__card {
  width: calc(100% / 3 - 1em - 60px);
  padding-top: calc(var(--card-ratio) * (100% / 3 - 1em - 60px));
}

.dashboard-horizontal-slider__list--4-in-view .dashboard-horizontal-slider__card {
  width: calc(88% / 3 - 1em - 60px);
  padding-top: calc(var(--card-ratio) * (100% / 3 - 1em - 60px));
}
.dashboard-horizontal-slider__list--4-in-view .dashboard-horizontal-slider__card.compact_carousel {
  width: calc(87.5% / 3 - 1em - 37px) !important;
  padding-top: calc(var(--card-ratio) * (100% / 3 - 1em - 60px));
  border-radius: 12px;
}
body.body--touch-device .dashboard-horizontal-slider__card {
  scroll-snap-align: center;
}

.dashboard-horizontal-slider__empty-slide {
  box-shadow: none;
}

.dashboard-horizontal-slider__card + .dashboard-horizontal-slider__card {
  margin-left: 1em;
}

.dashboard__new-order-item-img-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dashboard__new-order-item-img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
}

body:not(.body--touch-device) .dashboard__new-order-item-img {
  transition: transform 0.3s;
}

body:not(.body--touch-device)
  a.dashboard__new-order-item-img-wrapper:hover
  .dashboard__new-order-item-img {
  transform: scale(1.05);
}

body:not(.body--touch-device)
  div.dashboard__new-order-item-img-wrapper:hover
  .dashboard__new-order-item-img {
  transform: scale(1.05);
}

.dashboard__slide-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.2s;
}

body:not(.body--touch-device) .dashboard__slide-button:hover {
  transform: scale(1.05);
}

.dashboard__new-order-item-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% - 93px);
}

.dashboard__new-order-item-name {
  margin: 0 0 5px;
  font-size: 0.875rem;
  line-height: 120%;
}

.dashboard__new-order-item-description {
  position: relative;
  min-height: 50px;
  max-height: 3.9em;
  margin: 0;
  overflow: hidden;
  font-size: 0.8125rem;
  line-height: 1.3em;
}

.dashboard__new-order-item-description:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.3em;
  margin-top: 0.2em;
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.83) 50%);
}

.dashboard__horizontal-slider-button {
  position: absolute;
  z-index: 10;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 10px - 18px - 4px);
}

.dashboard__horizontal-slider-previous-button {
  left: 0;
  background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.6));
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}

.dashboard__horizontal-slider-next-button {
  right: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.6), #fff);
}

.dashboard__horizontal-slider-button > .icon {
  width: 30px;
  height: 30px;
}

.horizontal-slider__status-bar {
  display: flex;
  justify-content: center;
}

.horizontal-slider-status-bar__step {
  width: 30px;
  height: 4px;
  border-radius: 4px;
}

.horizontal-slider-status-bar__step[data-is-active="true"] {
  background-color: #cfcfcf;
}

.horizontal-slider-status-bar__step[data-is-active="false"] {
  background-color: #eaeaea;
}

.horizontal-slider-status-bar__step + .horizontal-slider-status-bar__step {
  margin-left: 10px;
}

/**Desktop **/
@media screen and (min-width: 660px) {
  .dashboard-horizontal-slider__list--3-in-view .dashboard-horizontal-slider__card {
    width: calc(100% / 3 - 5em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 3 - 5em - 60px)) !important;
  }
  section.whatsNewSliderSection .dashboard-horizontal-slider__list--3-in-view .dashboard-horizontal-slider__card {
    width: calc(100% / 3 - 6.2em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 3 - 5em - 60px)) !important;
  }
  .dashboard-horizontal-slider__card.dashboard-horizontal-slider__card-featured {
    width: calc(100% / 3 - 14em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 2 - 3em - 60px)) !important;
  }

  .horizontal-slider__status-bar {
    margin-bottom: 20px;
  }

  .dashboard-horizontal-slider__list.featured-slider > .menu__category-card {
    min-width: 10%;
  }
}

/**Desktop Laptop Screen **/
@media screen and (min-width: 660px) and (max-width: 1920px) {
  .dashboard-horizontal-slider__card.dashboard-horizontal-slider__card-featured {
    min-width: 12%;
    width: calc(100% / 3 - 14em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 2 - 8em - 60px)) !important;
    min-height: 270px;
  }

  .dashboard-horizontal-slider__list.featured-slider > .menu__category-card {
    min-width: 10%;
    text-wrap: wrap;
  }
}

@media screen and (min-width: 660px) and (max-width: 1500px) {
  .dashboard-horizontal-slider__card.dashboard-horizontal-slider__card-featured {
    min-width: 10%;
    width: calc(100% / 3 - 7em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 2 - 60px)) !important;
    min-height: 300px;
  }
}

@media screen and (min-width: 660px) and (max-width: 1200px) {
  .dashboard-horizontal-slider__card.dashboard-horizontal-slider__card-featured {
    width: calc(100% / 3 - 6em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 2 - 30px)) !important;
  }
}

/** ipad **/
@media screen and (min-width: 660px) and (max-width: 1024px) {
  .dashboard-horizontal-slider__card.dashboard-horizontal-slider__card-featured {
    width: calc(100% / 3 - 6em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 2 - 30px)) !important;
    min-height: 250px;
  }

  .dashboard-horizontal-slider__list.featured-slider > .menu__category-card {
    min-width: 30%;
    text-wrap: wrap;
  }

  .dashboard-horizontal-slider__list--3-in-view .dashboard-horizontal-slider__card {
    width: calc(100% / 3 - 1em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 3 - 1em - 60px)) !important;
  }
}

/** ipad Mini **/
@media screen and (min-width: 660px) and (max-width: 850px) {
  .dashboard-horizontal-slider__card.dashboard-horizontal-slider__card-featured {
    width: calc(100% / 3 - 2em - 60px) !important;
    padding-top: calc(var(--card-ratio) * (100% / 1.5)) !important;
    padding-bottom: 10px;
  }

  .dashboard-horizontal-slider__list.featured-slider > .menu__category-card {
    min-width: 30%;
    text-wrap: wrap;
  }
}

.dashboard-horizontal-slider__card-featured {
  --card-ratio: 205 / 400;
  position: relative;
  width: 40%;
  height: 0;
  overflow: hidden;
  border-radius: 9px;
  box-shadow: 0 0.5px 3px 0.5px rgb(0 0 0 / 15%);
  padding-top: 260px !important;
  background-color: #fff;
}

.dashboard__featured-item-img-wrapper {
  position: relative;
  width: 100%;
  height: 0;

  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  padding-top: calc(475 / 190 * 40%);
}

.dashboard__featured-item-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.dashboard__featured-item-text-wrapper {
  white-space: normal;
  padding: 8px 8px 11px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.dashboard__featured-item-text-wrapper > span:first-child {
  font-size: 15px;
  font-weight: 500;
}

.dashboard__featured-item-name {
  margin: 0 0 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.dashboard__featured-item-calory {
  font-size: 12px;
}

.dashboard__featured-item-description {
  position: relative;
  min-height: 50px;
  max-height: 3.9em;
  margin: 0;
  font-size: 0.65em;
  line-height: 1.3em;
  overflow: hidden;
}

.dashboard__featured-item-description:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.3em;
  margin-top: 0.2em;
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.83) 50%);
}

.dashboard__featured-module {
  font-size: 16px;
}

.menu__category-card-featured.withQuickAddButton {
  border-bottom-right-radius: 1em;
}

@media screen and (min-width: 660px) {
  .dashboard-horizontal-slider__card-featured {
    width: calc(100% / 3 - 13em - 60px) !important;
    padding-top: 20% !important;
  }

  .dashboard__featured-item-price {
    font-size: 15px;
  }

  .dashboard__featured-carousel > .styles-module_item-provider__YgMwz {
    position: relative;
    overflow: hidden;
    padding: 0px 0 0px 0px;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }

  .menu__category-card-featured {
    position: relative;
    margin: 0 0.5em;
    border-radius: 0.5em;
    z-index: 0;
    height: 100%;
  }

  .menu__category-card-featured::marker {
    display: none !important;
    content: none !important;
  }

  .dashboard__featured-module.carousel {
    margin-bottom: 2em;
  }

  .dashboard__featured-carousel .dashboard__horizontal-slider-button {
    top: 0;
    bottom: 0;
    height: 100%;
  }

  .styles-module_item-tracker__3bypy
    > .styles-module_item-container__a8zaY:first-child
    > .menu__category-card-featured {
    margin-left: 0;
  }
  .featuredSliderIndicator{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 18px;
  }
}

@media screen and (min-width: 1920px) {
  .dashboard-horizontal-slider__card-featured {
    width: calc(100% / 3 - 13em - 60px) !important;
    padding-top: 20% !important;
  }
}

.dashboard-pay-bill__module-body-wrapper {
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 20px;
  background-color: #fff;
}

.dashboard-pay-bill__module-column {
  padding: 0 0 12px 0;
  margin-top: 10px;
}

.dashboard-pay-bill__module-column-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.dashboard-pay-bill__module-image {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}

.dashboard-pay-bill__module-column > p {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}

.dashboard-pay-bill__module-column-button-container > button {
  padding: 12px 0px 12px 0px;
  width: 50%;
  white-space: normal;
}

.dashboard__module.dashboard-pay-bill__module {
  padding-right: 20px;
}

.dashboard-news-feed__list-container {
  list-style-type: none;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.dashboard-news-feed__card {
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.dashboard-news-feed__image-container,
.dashboard-news-feed__image-container > img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dashboard-news-feed__title {
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
}

.dashboard-news-feed__blurb {
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-height: 1.3;
}
.dashboard-news-feed__blurb-expanded {
  max-height: unset !important;
  -webkit-line-clamp: unset !important;
  -webkit-box-orient: unset !important;
  display: block !important;
}

.dashboard-news-feed__disclaimer-container {
  margin-bottom: 10px;
}
.dashboard-news-feed__disclaimer {
  font-size: 14px;
  font-weight: 400;
}
.dashboard-news-feed__disclaimer::before {
  content: "*";
  margin-right: 3px;
}

.dashboard-news-feed-blurb-container {
  height: 100%;
}

.dashboard-news-feed__button-conatainer {
  display: flex;
  flex-direction: row;
  text-align: right;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  position: relative;
  width: 100%;
}

.dashboard-news-feed__button-conatainer.expanded {
  position: relative;
  margin-top: 2em;
  padding-left: 0;
  padding-right: 0;
}

.dashboard-news-feed__button-conatainer.no-image:not(.with-read-more) {
  flex-direction: row-reverse;
  padding-right: 0;
}

.dashboard-news-feed__button-conatainer.no-image.with-read-more {
  padding-left: 0;
  padding-right: 0;
}

.dashboard-news-feed__button-conatainer.with-image.without-read-more {
  flex-direction: row-reverse;
}

.dashboard-news-feed__button {
  width: unset;
  max-width: 100%;
  padding-left: 2em;
  padding-right: 2em;
  white-space: normal;
  word-wrap: break-word;
  margin: 0 !important;
}

.dashboard-news-feed-section {
  padding-right: 20px !important;
}

.dashboard-news-feed__card
  > .dashboard-news-feed__column:only-child
  > .dashboard-news-feed__button-conatainer {
  position: relative;
  right: 0;
  bottom: 0;
}

.dashboard-news-feed__blurb.dashboard-news-feed__blurb-expanded
  ~ .dashboard-news-feed__button-conatainer {
  position: relative;
  right: 0;
  bottom: 0;
}

/* Mobile Only */
@media screen and (max-width: 660px) {
  .dashboard-news-feed__card {
    position: relative;
  }

  .dashboard-news-feed__title {
    padding-top: 1em;
  }

  .dashboard-news-feed__title,
  .dashboard-news-feed__blurb {
    padding-left: 1em;
    padding-right: 1em;
  }

  .dashboard-news-feed__card ~ *:only-child {
    padding-left: 1em;
    padding-right: 1em;
  }

  .no-content-button-container {
    position: relative;
    right: 0;
    padding-right: 1em;
    padding-left: 1em;
    margin-top: 30px;
  }

  .with-content-button-container {
    position: relative;
  }

  .dashboard-news-feed__blurb {
    -webkit-line-clamp: 1;
  }

  .dashboard-news-feed__button-conatainer {
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 20px;
  }

  .dashboard-news-feed__button-conatainer.expanded {
    position: relative;
    margin-top: 2em;
    padding-left: 1em;
    padding-right: 1em;
  }

  .dashboard-news-feed__button-conatainer.no-image:not(.with-read-more) {
    flex-direction: row-reverse;
    padding-right: 1em;
  }

  .dashboard-news-feed__button-conatainer.no-image.with-read-more {
    padding-left: 1em;
    padding-right: 1em;
  }

  .dashboard-news-feed__button-conatainer.with-image.without-read-more {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 660px) {
  .dashboard-news-feed__card {
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
  }
  .dashboard-news-feed__image-container {
    width: 100%;
    display: flex;
  }

  .dashboard-news-feed__image-container > img {
    width: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .dashboard-news-feed__column {
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;
  }

  .dashboard-news-feed__read-more-button {
    text-align: left;
  }

  .dashboard-news-feed-section {
    padding-right: 0 !important;
  }
}

/** Ipad Mini **/
@media screen and (min-width: 768px) and (max-width: 820px) {
  .dashboard-news-feed__blurb {
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: 400;
    text-overflow: ellipsis;
    max-height: 400px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
}

/** Ipad Air **/
@media screen and (min-width: 820px) {
  .dashboard-news-feed__blurb {
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: 400;
    text-overflow: ellipsis;
    max-height: 400px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
}

@media screen and (min-width: 1025px) {
  .dashboard-news-feed__blurb {
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: 400;
    text-overflow: ellipsis;
    max-height: 400px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .dashboard-news-feed__blurb.dashboard-news-feed__blurb-expanded
    ~ .dashboard-news-feed__button-conatainer {
    position: absolute;
    right: 1em;
    bottom: 1em;
  }

  .dashboard-news-feed__blurb-expanded ~ .dashboard-news-feed__button-conatainer {
    position: relative !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  .dashboard-news-feed__column.news-feed-content {
    padding: 40px !important;
  }

  .dashboard-news-feed__card
    > .dashboard-news-feed__column:only-child
    > .dashboard-news-feed__button-conatainer {
    right: 0 !important;
    bottom: 0 !important;
  }

  .dashboard-news-feed__blurb.dashboard-news-feed__blurb-expanded
    ~ .dashboard-news-feed__button-conatainer {
    position: relative !important;
    right: 0 !important;
    bottom: 0 !important;
  }
}

.dashboard-action-button {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  max-height: 60px;
  min-height: 60px;

  flex: calc(45% - 1em) 1;
  min-width: unset;
}

.dashboard-action-button.left-aligned {
  justify-content: flex-start;
}

.dashboard-action-button.center-aligned {
  flex-direction: column;
  justify-content: center;
  padding: 11px 0;
}

.dashboard-action-button.left-aligned > .dashboard-action-button__icon-container {
  min-width: 60px;
  max-width: 60px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  min-height: 60px;
}

.dashboard-action-button.center-aligned > .dashboard-action-button__icon-container {
  width: 18px;
  height: 18px;
  margin-bottom: 10px;
}

.dashboard-action-button.left-aligned
  > .dashboard-action-button__icon-container
  > .dashboard-action-button__icon {
  width: 24px;
  height: 24px;
}

.dashboard-action-button.left-aligned > .dashboard-action-button__title-container {
  padding: 10px 10px 10px 20px;
}

.dashboard-action-button__title-container {
  display: flex;
  flex-direction: column;
}
.dashboard-action-button__title {
  font-weight: 600;
  font-size: 16px;
}

.dashboard-action-button__subtitle {
  margin-top: 3px;
  font-weight: 400;
  font-size: 14px;
}

.dashboard-action-button__button {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dashboard-action-button__arrow-container {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.dashboard-action-button.split-action-button {
  width: 45%;
  margin-right: 10px;
}
.dashboard-order-buttons__row > .dashboard-action-button.split-action-button:last-child {
  margin-right: 0;
}

.dashboard-action-button.split-action-button .dashboard-action-button__title {
  text-align: center;
}

/** For small mobile devices such as iphone SE */
@media screen and (max-width: 390px) {
  .dashboard-action-button__title {
    font-size: 14px;
  }
}

.dashboard-giftcard-buttons-section {
  padding-right: 20px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.dashboard-giftcard-buttons-section .dashboard-action-button:not(:last-child) {
  margin-bottom: 10px;
}

.dashboard-giftcard-buttons-section .dashboard-action-button:not(:last-child) {
  margin-right: 1em;
}

@media screen and (min-width: 660px) {
  .dashboard-giftcard-buttons-section .dashboard-action-button:not(:last-child) {
    margin-bottom: 0;
  }

  .dashboard-giftcard-buttons-section {
    padding-right: 0;
    flex-direction: row;
    justify-content: space-between;
  }
}

.dashboard__module {
  transform: translateY(40%);
}

.dashboard__order-module {
  /* fix for expanded custom dropdowns cut-off by the module below */
  position: relative;
  z-index: 1;
}

.dashboard__module--horizontal-slider {
  /* for horizontal slider to go off-screen on the right */
  padding-right: 0;
}

.dashboard--animate .dashboard__module {
  animation: slideUpDashboardSection 0.25s forwards;
  animation-timing-function: ease-out;
}

.dashboard__module-subheading {
  margin: 0 0 4px;
  color: #757575;
  font-size: 0.8em;
  font-weight: 400;
}

.dashboard__notification {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  color: #fff;
  background-color: #2a2a2a;
  z-index: 800;
}

.dashboard__notification--alert {
  background-color: #d34146;
}

.dashboard__notification-text {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 0.9rem;
}

.dashboard__notification .icon {
  width: 18px;
  height: 18px;
  color: #fff;
}

.dashboard__notification .icon--triangle-alert {
  margin-right: 4px;
}

.dashboard__close-notification {
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.dashboard__notification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #989898b8;
  animation: fadeIn 300ms;
  z-index: 900;
}

.dashboard__confirm-notification {
  display: block;
  min-width: 153px;
  height: 36px;
  margin-top: 30px;
  margin-left: auto;
  padding: 0 24px;
  color: #fff;
  text-transform: uppercase;
  border: 1px solid #fff;
  border-radius: 4px;
}

@keyframes slideUpDashboardSection {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDownTimeStampContainer {
  from {
    transform: translateY(-6em);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideUpTimeStampContainer {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-15em);
  }
}

.last-login-timestamp__container {
  z-index: 9999;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  padding: 0.5em 1.5em;
  background-color: #fbfbfb;
  color: black;
  box-shadow: 0px 2px 3px 0px rgb(148 148 148 / 20%);
}

.last-login-timestamp__container_slidedown {
  animation: slideDownTimeStampContainer 1s forwards;
}

.last-login-timestamp__container_slideup {
  animation: slideUpTimeStampContainer 1s forwards;
}

.dashboard-header {
  position: sticky;
  top: 0;
}

.dashboard-main-content {
  background-color: transparent;
}

#main-content-dashboard {
  background-color: #f5f5f5;
}

.dashboard-sections-container {
  margin-top: -20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #f5f5f5;
  position: relative;
  padding: 20px 0px 5em 20px;
}

.dashboard-main-content .additional-disclaimer {
  padding-left: 24px;
  padding-right: 24px;
}

.xp-subscription-container > h2 {
  margin: 0 0 0.4em 0;
}

.dashboard-scroll-up-button {
  position: fixed;
  right: 16px;
  bottom: 135px;
  background-color: #ffffff;
  opacity: 0.8;
  border-radius: 50%;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px 0px #00000026;
  z-index: 1100;
}

.dashboard-scroll-up-button > .icon {
  stroke: black;
}

.dashboard-scroll-up-button.hidden {
  opacity: 0.8;
  animation: scrollButtonFadeOutMobile 0.5s forwards;
}

.dashboard-scroll-up-button.not-visible {
  display: none;
}

@media screen and (min-width: 660px) {
  .dashboard__module:first-child {
    margin-bottom: 2%;
  }

  .dashboard-main-content {
    font-size: 21px;
    overflow-x: visible;
    overflow-y: visible;
  }

  .dashboard__module-subheading {
    margin-bottom: 12px;
  }

  .last-login-timestamp__container {
    z-index: 9999;
    position: fixed;
    right: 0;
    top: unset;
    width: 100%;
    padding: 1em;
    background-color: #fbfbfb;
    color: black;
    text-align: center;
    box-shadow: 0px -2px 3px 0px rgb(148 148 148 / 39%);
  }

  @keyframes slideDownTimeStampContainerDesktop {
    from {
      bottom: 0;
    }

    to {
      bottom: -8em;
    }
  }

  @keyframes slideUpTimeStampContainerDesktop {
    from {
      bottom: -8em;
    }

    to {
      bottom: 0;
    }
  }

  .last-login-timestamp__container_slidedown {
    animation: slideUpTimeStampContainerDesktop 1s forwards;
  }

  .last-login-timestamp__container_slideup {
    animation: slideDownTimeStampContainerDesktop 1s forwards;
  }

  .dashboard-sections-container {
    border-radius: 0px;
    margin-top: 0;
  }

  .dashboard-scroll-up-button {
    position: fixed;
    right: 50px;
    bottom: 120px;
    background-color: #ffffff;
    border-radius: 50%;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 2px 0px #00000026;
    z-index: 1100;
    opacity: 0;
    animation: scrollButtonFadeInDesktop 0.5s forwards;
  }

  .dashboard-scroll-up-button.hidden {
    opacity: 1;
    animation: scrollButtonFadeOutDesktop 0.5s forwards;
  }

  .dashboard-action-buttons-container {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
    margin-bottom: 15px;
  }
  .dashboard-action-buttons-container > .dashboard-action-button {
    margin-bottom: 10px !important;
    margin-right: 1em !important;
    flex: calc(25% - 1em) 1;
  }

  .dashboard-action-buttons-container > .dashboard-action-button:nth-child(4) {
    margin-right: 0 !important;
  }

  .dashboard-action-buttons-container > .dashboard-action-button:last-child {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 660px) {
  .dashboard-main-content .additional-disclaimer {
    margin-bottom: 6em;
  }

  .dashboard-action-buttons-container {
    padding-right: 20px;
  }
  .dashboard-action-buttons-container > .dashboard-action-button {
    margin-bottom: 10px !important;
  }
}
/* Ipad */
@media screen and (min-width: 661px) and (max-width: 1024px) {
  .dashboard-action-buttons-container {
    justify-content: space-between;
  }
  .dashboard-action-buttons-container
    > .dashboard-action-button#dashboard-rewards-button:not(:first-child) {
    margin-right: 0 !important;
  }
  .dashboard-action-buttons-container > .dashboard-action-button {
    flex: calc(45% - 1em) 1;
    max-width: 49%;
  }

  .dashboard-action-buttons-container > .dashboard-action-button:nth-child(2) {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 1248px) {
  .dashboard-sections-container .desktop-container {
    /*padding-left: 24px;
    padding-right: 24px;*/
  }
}

@keyframes scrollButtonFadeInMobile {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.8;
  }
}

@keyframes scrollButtonFadeInDesktop {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scrollButtonFadeOutMobile {
  from {
    opacity: 0.8;
  }

  to {
    opacity: 0;
  }
}

@keyframes scrollButtonFadeOutDesktop {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.dashboard-order-buttons-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  padding-right: 20px;
}

.dashboard-order-buttons-container .dashboard-action-button:not(:last-child) {
  margin-right: 1em;
}

.dashboard-order-buttons__row {
  display: flex;

  margin-bottom: 10px;
}

.dashboard-order-buttons__inner-row {
  display: flex;
}

.dashboard-order-buttons__column {
  display: flex;
  flex-direction: column;
}

.dahsboard-order-buttons__order-button-icon-container {
  background-color: var(--color-primary);
  padding: 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  min-width: 16%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dahsboard-order-buttons__order-button-icon-container > svg {
  fill: var(--color-primary-color-contrast);
}

.dashboard-order-buttons__column.order-now {
  background-color: #fff;
  text-align: left;
  padding: 10px 10px 10px 20px;
}

.dashboard-order-buttons__column.order-now > span:first-child {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 3px;
}

.dashboard-order-buttons__column.order-now > span:last-child {
  font-weight: 400;
  font-size: 14px;
}
.dashboard-order-buttons__row.order-now {
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
}

.dashboard-order-buttons__column.favorite {
  justify-content: center;
  align-items: center;
  padding: 0;
}

.dashboard-order-buttons__column.favorite > svg {
  margin-bottom: 5px;
}

.dashboard-order-buttons__fav-container,
.dashboard-order-buttons__recent-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 11px 0px 11px 0px;
  min-width: 48%;
  font-size: 14px;
  font-weight: 500;
}

#dashboard-order-now-button {
  width: 100%;
}

@media screen and (max-width: 660px) {
  .dashboard-order-buttons__row:not(.order-now) {
    justify-content: space-between;
  }

  .dashboard-order-buttons-container > .dashboard-action-button {
    margin-bottom: 10px;
  }
}

/** Dashboard Static Order button **/
.dashboard-order-now-button-static {
  z-index: 9999;
  background-color: var(--color-primary);
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 4px 8px 3px #00000014;
  animation-name: expandStaticOrderButton;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes expandStaticOrderButton {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

.dashboard-order-now-button-static div {
  border-radius: 0;
  background-color: var(--color-primary);
  padding: 0;
  min-width: unset;
  font-size: 14px;
}

.dashboard-order-now-button-static .dahsboard-order-buttons__order-button-icon-container {
  padding: 10px;
}

.dashboard-order-now-button-static .dashboard-order-buttons__column.order-now,
.dashboard-order-now-button-static svg {
  background-color: var(--color-primary);
  color: var(--color-primary-color-contrast);
  fill: var(--color-primary-color-contrast);
}

.dashboard-order-now-button-static .icon--arrow-right {
  width: 12px;
  height: 12px;
  margin-left: 10px;
}

.dashboard-order-now-button-static .icon--bill {
  width: 20px;
  height: 20px;
}

.dashboard-order-now-button-static .dashboard-order-buttons__column.order-now > span {
  display: flex;
  align-items: center;
}

.dashboard-order-now-button-static .dashboard-order-buttons__column.order-now > span:first-child {
  font-size: 14px;
}
.dashboard-order-now-button-static .dashboard-order-buttons__column.order-now > span:last-child {
  font-size: 12px;
}

/** END OF Dashboard Static Order button **/

/** Desktop **/
@media screen and (min-width: 660px) {
  .dashboard-order-buttons-container {
    padding-right: 0;
  }
  .dashboard-order-buttons__column {
    text-wrap: wrap;
  }

  .dashboard-order-buttons__column {
    justify-content: center !important;
    align-items: flex-start !important;
  }

  .dahsboard-order-buttons__order-button-icon-container {
    background-color: #eeeeee;
    margin-right: 10px;
    padding: 5px;
    height: 100%;
    min-width: 60px;
    max-width: 60px;
  }

  .dahsboard-order-buttons__order-button-icon-container.order-now {
    background-color: var(--color-primary);
  }

  .dahsboard-order-buttons__order-button-icon-container.order-now > svg {
    fill: var(--color-primary-color-contrast);
  }

  .dahsboard-order-buttons__order-button-icon-container > .icon--star {
    fill: #f49c00 !important;
    color: #f49c00 !important;
    width: 30px;
    height: 30px;
  }
  .dahsboard-order-buttons__order-button-icon-container > .icon--heart {
    stroke: #fc8181;
    fill: #fc8181;
  }

  .dashboard-order-buttons__inner-row {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .dashboard-order-buttons-container button {
    margin-right: 1em;
  }

  .dashboard-order-buttons-container #dashboard-rewards-button {
    margin-right: 1em;
  }

  #dashboard-rewards-button.loyalty-only {
    margin-right: 0;
    max-width: 30%;
  }

  .dashboard-order-buttons-container button:last-child {
    margin-right: 0px;
  }

  .dashboard-order-buttons__fav-container,
  .dashboard-order-buttons__recent-container,
  .dashboard-order-buttons__order-now-container {
    background-color: #fff;
    border-radius: 8px;
    padding: 0;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 20%;
    font-size: 16px;
    font-weight: 500;
  }
  .dashboard-order-buttons__order-now-container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .dashboard-order-buttons__column.order-now {
    padding-left: 0;
  }

  .dashboard-order-buttons__column.reward-arrow {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
  }

  .dashboard-order-buttons__column.reward-arrow > .icon--arrow-up {
    width: 20px;
    height: 20px;
  }

  .dashboard-order-buttons__column.reward-arrow > .icon--arrow-down {
    width: 25px;
    height: 25px;
  }

  .dashboard-order-now-button-static div {
    align-items: center;
  }
}

/** Ipad Mini Screen **/
@media screen and (min-width: 661px) and (max-width: 1024px) {
  .dashboard-order-buttons-container > .dashboard-order-buttons__row {
    flex-wrap: wrap;
  }

  .full-length-recent-order-button {
    margin-top: 10px;
  }

  .right-aligend-fav-button {
    margin-right: 0 !important;
  }

  .dashboard-order-buttons-container > .dashboard-order-buttons__row > button,
  .dashboard-order-buttons-container > .dashboard-order-buttons__row > #dashboard-rewards-button {
    flex: calc(45% - 1em) 1;
    min-width: unset;
    margin-right: 0;
  }

  .dashboard-order-buttons-container > .dashboard-order-buttons__row > button:nth-child(odd) {
    margin-right: 10px;
  }

  .dashboard-order-buttons-container > .dashboard-order-buttons__row > button:nth-child(1) {
    margin-bottom: 10px;
  }

  .dashboard-order-buttons-container > .dashboard-order-buttons__row > button:nth-child(2) {
    margin-bottom: 10px;
  }
  .dahsboard-order-buttons__order-button-icon-container {
    padding: 18px 0;
  }

  .dashboard-order-buttons-container > .dashboard-order-buttons__row > button:last-child {
    margin-right: 0px;
  }

  #dashboard-rewards-button {
    margin-bottom: 10px;
  }
}

/* LoginRegisterUserAlreadyExists.css */
.register-user-exists {
  width: 100%;
  margin-top: 3em;
  z-index: 1000;
  animation: fadeIn 1s forwards;
  height: 100%;
}

.register-user-exists__content {
  position: relative;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -40%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
}

.register-user-exists__header {
  position: relative;
  width: 210px;
  height: 210px;
  margin-bottom: 36px;
}

.register-user-exists__questionMarks {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.questionMark {
  position: absolute;
}

.questionMark:nth-child(even) {
  animation: floatQuestionMark 3s infinite;
}

.questionMark:nth-child(odd) {
  animation: floatQuestionMark 6s infinite;
}

@keyframes floatQuestionMark {
  0% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0.5;
  }
}

.register-user-exists__heading {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -65%);
  margin: 0;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.75rem;
}

.register-user-exists__text {
  margin: 0 0 30px;
  font-size: 0.9rem;
  font-weight: 600;
  text-align: left;
}

.button--submit-form {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 50px;
  font-weight: 600;
  text-transform: uppercase;
}

.button--submit-form + .button--submit-form {
  margin-top: 20px;
}

.modal--fullscreen.register-user-exists__modal > h1 {
  margin: 30px 0 0;
  text-align: center;
  font-size: 1.8rem;
}

.register-user-exists__singin-button {
  position: relative;
  display: block;
  margin: 40px auto;
  height: 50px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 50px;
}
/* END OF Dashboard Welcome Overlay */

.button--go-back {
  opacity: 0;
  position: absolute;
  top: 14px;
  left: 14px;
  width: 44px;
  height: 44px;
  animation: fadeIn 1s forwards;
}

.register-form-wrapper {
  width: 85%;
  margin: 3em auto;
  background-color: #fff;
  animation: fadeIn 0.3s forwards;
  max-width: 1200px;
}

.form-register {
  min-height: 130px;
  margin-bottom: 23px;
}

.form-register__rewards-step {
  min-height: 190px;
  margin-bottom: 0;
}

.form-register__request-rewards-card {
  min-height: 99px;
}

.form-register__password-step {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 218px; /* with "Keep me logged-in" checkbox */
  margin-bottom: 0;
}

.form-register__field-wrapper {
  opacity: 0;
  transform: translateX(100vw);
  top: 0;
  width: 100%;
  text-align: center;
}

.form-register__checkbox-field-wrapper {
  top: auto;
  bottom: 68px;
}

.form-register__field-wrapper--active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 2s, transform 1s;
}

.form-register__field-wrapper--inactive {
  height: 0;
}

.button--form-next,
.button--form-previous {
  display: block;
  width: 50%;
  margin: 0 auto;
  font-weight: 600;
  text-transform: uppercase;
}

.button--form-previous {
  margin-right: 15px;
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1);
}

.button--form-previous-inactive {
  transform: translateX(-100vw);
}

.button--form-previous-active {
  transform: translateX(0);
  transition: transform 1s;
}

.button--form-slide-right {
  transform: translateX(5px);
  transition: transform 1s;
}

.button-form-slide-middle {
  transform: translateX(-50%);
  transition: transform 1s;
}

.form-register__stepper {
  opacity: 0;
  color: #757575;
  font-size: 0.8rem;
  text-align: center;
  animation: fadeIn 0.2s forwards;
}

.register-form-wrapper select {
  background-color: white;
}

.button-wrapper--register-account {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.register-form-buttons-wrapper {
  display: flex;
  justify-content: space-between;
}

.button--form-next-inactive {
  transform: translateX(100vw);
}

.form--registration > .form__fieldset > .form-heading {
  margin: 0;
}

.form--registration > .form-submit__wrapper {
  text-align: center;
  margin-top: 3em;
}

.registration-form__custom-label-styling {
  align-items: baseline;
}

@media screen and (min-width: 660px) {
  .form__field-wrapper--register-checkbox {
    flex-direction: unset !important;
  }
}

.register__success-screen {
  width: 100%;
  height: 100%;
  z-index: 500;
}

.modal-wrapper--success-register {
  background-color: white;
  border-top: solid 5px #d64123;
}

.successful-register__heading {
  margin: 60px 0 0;
  text-align: center;
  font-size: 26px;
  font-weight: 400;
}

.register__success-login-button-wrapper {
  position: absolute;
  bottom: 85px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.register__success-login-button-wrapper > button {
  position: relative;
  display: block;
  margin: 0 auto;
}

.register__success-content-message {
  text-align: center;
}
@media screen and (min-width: 660px) {
  .register__success-content-message {
    margin-top: 3em;
  }
}

.forgot-password__heading {
  position: relative;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 1.5em;
}

.forgot-password__form-wrapper > form > .form__field-wrapper > input,
.new-password__form-wrapper > form > .form__field-wrapper > input {
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid #cdcdcd;
}

.forgot-password__form-wrapper > form > .form__field-wrapper > label,
.new-password__form-wrapper > form > .form__field-wrapper > label {
  left: 0;
}

.new-password__form-wrapper {
  padding: 0px;
}

#form--forgot-password > .form-submit__wrapper > .button--submit-form-wrapper > button {
  width: 75%;
}

@media screen and (min-width: 660px) {
  .forgot-password__main-wrapper {
    justify-content: center;
    max-width: 60%;
    min-width: 60%;
    border-radius: 10px;
    border: 1px solid var(--color-gray-light);
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    background-color: #fff;
  }

}

@media screen and (min-width: 1028px) {
  .forgot-password__main-wrapper {
    max-width: 45%;
    min-width: 45%;
  }
}

.main-content--pickup,
.main-content--delivery {
  /* prevent content overlap when keyboard is open on devices with smaller height */
  min-height: 300px;
  overflow: hidden;
}

.main-content--pickup .loading-spinner {
  top: 31.3%;
  bottom: auto;
}

.main-content--delivery .loading-spinner {
  position: fixed;
  top: 0;
  bottom: 0;
}

#google-map {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  scroll-behavior: auto;
  z-index: 999;
  min-height: 250px;
}

.map-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #fff;
  z-index: 900;
  overflow-y: scroll;
}

.main-content--pickup .map-overlay {
  z-index: 500;
}

.map-overlay__link--back {
  position: relative;
  top: 25px;
  left: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

.map-overlay__delivery-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 600; /* display on top of the overlay */
  padding: 0 1em 5em;
  margin-top: 2em;
}

.map-overlay__delivery-content .map-search-option__heading {
  position: relative;
  left: auto;
  width: auto;
  transform: translateX(100vh);
  margin: 0 0 30px;
  animation: slideInMapDeliveryFormHeading 0.6s forwards;
}

.map-search-options > * {
  z-index: 600; /* display on top of the overlay */
}

.map-overlay__delivery-content + .map-search-options > * {
  z-index: 400; /* display behind the overlay */
}

.map-search-options--minimized > * {
  z-index: 300;
}

.map-search-option__heading {
  position: absolute;
  left: 50%;
  transform: translate(100vh, -12%);
  width: 215px;
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
  white-space: pre-wrap;
  animation: slideInMapSearchOptionsHeading 0.6s forwards;
  margin-top: 1em;
}

.map-search-option__subheading {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
}

.map-search-option__geolocation-subheading {
  position: absolute;
  top: 29%;
  left: 50%;
  transform: translate(100vh, -29%);
  animation: slideInMapGeolocationSubheading 0.6s forwards;
}

.delivery-form-wrapper {
  width: 90%;
  max-width: 360px;
}

.map-search-option__searchbox-subheading {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(100vh, -55%);
  width: 240px;
  text-align: center;
  animation: slideInMapSearchboxSubheading 0.6s forwards;
}

.map-search-option__searchbox-wrapper {
  opacity: 0;
  position: absolute;
  top: 64%;
  left: 50%;
  transform: translate(-50%, -64%);
  width: 240px;
  animation: fadeIn 1.5s forwards;
}

.button--trigger-searchbox {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  width: 40px;
  height: 40px;
  margin: auto 0;
  background-color: #fff;
  border-radius: 50%;
}

.map-search-options--minimized {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transform: none;
  transform: initial;
  transition: all 1s;
}

.map-search-options--minimized .map-search-option__searchbox-wrapper {
  left: calc(100vw - 40px - 18px);
  top: 18px;
  right: 18px;
  transform: none;
  transform: initial;
  width: 40px;
  height: 40px;
  margin-top: 0;
  transition: all 1s;
}

.map-search-options--minimized .map-search-option__searchbox-wrapper input {
  margin-top: 0 !important;
  left: auto !important;
  margin-right: 20px !important;
}

.map-search-option__searchbox--minimized {
  width: 40px !important;
  transition: all 0.6s;
}

.map-search-option__searchbox--minimized input {
  width: 0 !important;
  padding: 0 !important;
  border: none !important;
  transition: all 0.6s;
}

.map-search-option__searchbox--minimized input.map-search-option__searchbox--expanded {
  width: 240px !important;
  right: 0 !important;
  left: auto !important;
  margin-right: 0 !important;
  padding: 0 25px !important;
  transition: 0.3s;
}

.map-search-option__searchbox--minimized .button--trigger-searchbox {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.google-maps__no-stores-found {
  opacity: 0;
  transform: translateY(100%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 660px;
  min-height: 101px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 30px 19px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.3);
  animation: slideUpNoStoresMessage 0.8s forwards;
  animation-delay: 1.1s;
  z-index: 300;
}

.google-maps--initial-search-performed.google-maps__no-stores-found {
  animation-delay: 0.1s;
}

.icon.icon--map-fav {
  width: 24px;
  height: 24px;
}

@keyframes slideInMapSearchOptionsHeading {
  to {
    opacity: 1;
    transform: translate(-50%, -12%);
  }
}

@keyframes slideInMapGeolocationSubheading {
  to {
    opacity: 1;
    transform: translate(-50%, -29%);
  }
}

@keyframes slideInMapSearchboxSubheading {
  to {
    opacity: 1;
    transform: translate(-50%, -55%);
  }
}

@keyframes slideInMapDeliveryFormHeading {
  to {
    transform: translateX(0);
  }
}

@keyframes slideUpNoStoresMessage {
  to {
    opacity: 1;
    transform: none;
    transform: initial;
  }
}

@media screen and (min-width: 660px) {
  .main-content--pickup,
  .main-content--delivery {
    padding-bottom: 0;
  }

  .google-maps__stores-info-container {
    top: calc(100% - 129px - 5%);
  }

  .google-maps__stores-info-container--expanded {
    top: 5%;
  }

  .store-info__details-modal {
    height: 100%;
  }

  .store-info__details-modal > * {
    display: flex;
    max-width: 660px;
    margin: 0 auto;
  }

  #google-map {
    min-height: 50vh;
  }
}

.pac-container {
  z-index: 1155;
}

.button--geolocation-wrapper {
  opacity: 0;
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -37%);
  animation: fadeIn 1.5s forwards;
}

.button--geolocation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.button--geolocation-wrapper__main-ring {
  position: absolute;
  display: block;
  width: 48px;
  height: 48px;
  left: -5px;
  border-width: 6px;
  border-style: solid;
  border-radius: 50%;
  animation: pulseGPSButtonDot 1.75s cubic-bezier(0.46, 0.03, 0.52, 0.96) -0.4s infinite;
}

.order-stores__use-location-wrapper-absolute
  > .order-stores__use-location-button
  > .button--geolocation-wrapper__main-ring {
  left: -5px;
}

.button--geolocation-wrapper__inner-ring {
  position: absolute;
  /*top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);*/
  width: 38px;
  height: 38px;
  border: 6px solid hsla(10, 72%, 70%, 1);
  border-radius: 50%;
}

.button--geolocation-wrapper__large-pulse {
  opacity: 0.5;
  display: block;
  width: 72px;
  height: 72px;
  left: -17px;
  border-radius: 50%;
  position: absolute;
  animation: pulseGPSButtonRing 1.75s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.order-stores__use-location-wrapper-absolute
  > .order-stores__use-location-button
  > .button--geolocation-wrapper__large-pulse {
  left: -18px;
}

.button--geolocation > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
}

.map-search-options--minimized .button--geolocation-wrapper {
  top: 18px;
  left: 18px;
  transform: none;
  transform: initial;
  margin-top: 0;
  transition: all 1s;
}

.map-search-options--minimized .button--geolocation {
  width: 40px;
  height: 40px;
  min-width: 0;
  min-height: 0;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.map-search-options--minimized .button--geolocation-wrapper__main-ring {
  width: 40px;
  height: 40px;
  border-width: 4px;
  animation: none;
}

.map-search-options--minimized .button--geolocation-wrapper__inner-ring,
.map-search-options--minimized .button--geolocation-wrapper__large-pulse {
  display: none;
}

.button--geolocation-wrapper__main-ring-inline {
  left: -40px;
  bottom: -16px;
}

.button--geolocation-wrapper__inner-ring-inline {
  left: -35px;
  bottom: -11px;
}

.button--geolocation-wrapper__large-pulse-inline {
  left: -52px;
  bottom: -29px;
}

@keyframes pulseGPSButtonRing {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulseGPSButtonDot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

@media screen and (min-width: 660px) {
  .button--geolocation-wrapper__main-ring {
    left: -10px;
  }
  .button--geolocation-wrapper__inner-ring {
    left: -5px;
  }
  .button--geolocation-wrapper__large-pulse {
    left: -22px;
  }
}

.loading-dots {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto;
}

.loading-dot {
  display: block;
  width: 12px;
  height: 9px;
  border-radius: 50%;
  animation: loadingDotAnimation 1.5s infinite both;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.3s;
}

.loading-dot:nth-child(4) {
  animation-delay: 0.4s;
}

.loading-dots-container {
  display: flex;
  align-items: center;
}

@keyframes loadingDotAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.store-info__details-modal {
  opacity: 0;
  transform: translateX(100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 57px);
  padding: 16px 25px;
  background-color: #fff;
  animation: slideInStoreInfoDetails 0.3s forwards;
  z-index: 300;
}

.store-info__details-modal--closing {
  animation: slideOutStoreInfoDetails 0.3s forwards;
}
.store-info-details__hours-table {
  margin-left: 10px;
}
.store-info-details__hours-table tr {
line-height: 1.5;
}
.button--close-info-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 44px;
  min-height: 44px;
  margin: 0 auto 15px;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

.button--close-info-dialog > svg {
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

.google-maps__store-info-details {
  margin-bottom: 19px;
  padding: 0;
  border-top: none;
  overflow-y: visible;
}

.store-info-details__name {
  line-height: 1.3;
  white-space: normal;
  text-overflow: clip;
}

.store-info__address {
  margin: 0;
  color: #555;
  font-size: 14px;
  line-height: 1.7;
}

.store-info-details__phone-wrapper {
  display: inline-flex;
  align-items: center;
  margin-bottom: 19px;
  font-size: 14px;
}

.store-info-details__phone-wrapper > svg {
  margin-right: 8px;
}
.store-info-details__table-wrapper {
  position: relative;
}

.store-info-details__table-wrapper > svg {
  position: absolute;
  top: 8px;
  left: 0;
  width: 20px;
  height: 20px;
}

.store-info-details__table {
  position: relative;
  width: 100%;
  padding-left: 30px;
}

.store-info-details__hours--today {
  font-weight: 500;
}

.store-info-details__table td {
  padding: 3px 0;
  font-size: 14px;
}

.store-info-details__table td[align="left"] {
  width: 40%;
  text-transform: capitalize;
}

.store-info-details__table td[align="right"] {
  width: 60%;
}

.store-info-details__table-row--active {
  font-weight: 600;
}

@keyframes slideInStoreInfoDetails {
  to {
    opacity: 1;
    transform: none;
    transform: initial;
  }
}

@keyframes slideOutStoreInfoDetails {
  from {
    opacity: 1;
    transform: none;
    transform: initial;
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

.main-content--dine-in-qr-scanner {
  /*padding: 24px;*/
}

.dine-in__qr-scanner-instructions {
  margin: 0;
  font-weight: normal;
  margin-bottom: 1em;
}

.dine-in__qr-scanner-instructions__container {
  background-color: var(--color-gray-light);
  padding: 24px;
}

.dine-in_qr-toggle-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.dine-in_qr-toggle-container > button {
  color: var(--color-secondary);
  font-weight: 600;
}

.dine-in__qr-scanner-container {
  position: relative;
  width: 300px;
  height: 300px;
  max-width: 35vh;
  max-height: 35vh;
  margin: 10% auto;
}

.dine-in-desktop-popup.dialog-modal-container {
  font-size: unset;
}

.dine-in-desktop-popup.dialog-modal-container > .dialog-modal {
  width: 500px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 0 0 0;
}

.dine-in-desktop-popup.dialog-modal-container .dine-in__table-number-form-wrapper {
  padding: 0;
  justify-content: center;
  display: flex;
  width: 100%;
}

.dine-in-desktop-popup.dialog-modal-container .order-stores__store-wrapper-dinein {
  background-color: var(--color-gray-light);
  padding: 10px 24px;
}

.dine-in-desktop-popup.dialog-modal-container .dine-in__qr-scanner-instructions,
.dine-in-desktop-popup.dialog-modal-container .dine-in__table-number-form-wrapper {
  padding-left: 24px;
  padding-right: 24px;
}

.dine-in-desktop-popup.dialog-modal-container .order-stores__use-location-button {
  margin-left: 24px;
}

.dine-in__video-stream-error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.qr-scanner__error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: red;
}

.dine-in__qr-scanner-wrapper {
  width: 100%;
  height: 100%;
}

.qr-scanner__video-stream {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.button--retry-qr-scan {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.qr-scanner__square-frame {
  position: absolute;
  top: -7px;
  left: -7px;
  margin: 0 auto;
  width: calc(100% + 14px);
  height: calc(100% + 14px);
  border-style: solid;
  border-width: 7px;
  border-image-source: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 80 80%22%3E%3Crect x=%224%22 y=%224%22 width=%2272%22 height=%2272%22 fill=%22transparent%22 stroke=%22%23565656%22 stroke-width=%2220%22 %2F%3E%3C%2Fsvg%3E");
  border-image-slice: 80%;
  border-image-width: 2em;
}

.dine-in__table-number-form-wrapper {
  position: relative;
  min-height: 153px;
  max-width: 100%;
  padding: 24px;
}

.dine-in__qr-scanner-iso-camera {
  padding-bottom: 2em;
  margin: 0 auto;
}

@media screen and (min-width: 660px) {
  .main-content--dine-in-qr-scanner {
    padding: 0;
  }

  .dine-in__table-number-form-wrapper {
    min-width: 15em;
    min-height: unset;
  }

  .dine-in__table-number-form__popup {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .dine-in__table-number-form__popup .form-submit__wrapper {
    margin-top: 0 !important;
  }
}

.menu__category-buttons-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: -0.2em -0.2em 1em;
  padding: 0;
  list-style-type: none;
}

.menu__category-filter-button-wrapper {
  margin: 0.2em;
}

.menu__category-filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  padding: 0 1em;
  height: 36px;
  font-size: 1em;
  font-weight: 600;
  line-height: 100%;
  border: 2px solid var(--color-secondary);
  border-radius: 9999px;
}

.menu__category-filter-button > .icon + * {
  margin-left: 0.2em;
}

.menu-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.2em;
}

.menu-breadcrumbs__item {
  font-weight: 500;
  line-height: 100%;
}

.menu-breadcrumbs__link {
  color: var(--color-secondary);
}

.menu-breadcrumbs > .icon {
  width: 10px;
  height: 10px;
  margin: 0 0.5em;
  fill: #2a2a2a;
}

.favorite-items-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: -0.75em;
  padding-left: 0;
  list-style-type: none;
}

.favorite-item {
  width: 100%;
  margin: 0.75em;
  padding: 1.3em;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #dadada;
  display: flex;
  flex-direction: column;
  position: relative;
}

.favorite-item .loading-spinner {
  z-index: 0;
}

@media screen and (min-width: 575px) {
  .favorite-item {
    width: calc(100% / 2 - 1.5em);
  }
}

@media screen and (min-width: 975px) {
  .favorite-item {
    width: calc(100% / 3 - 1.5em);
  }
}

.favorite-item-card__image-and-text-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2em;
}

.favorite-item__calorie-container {
  margin-bottom: 1.3em;
  font-size: 12px;
  text-align: center;
  margin-top: 1em;
}

.favorite-item-card__image-wrapper {
  height: 0 !important;
  padding-top: 10vh !important;
  min-width: 100px !important;
  max-width: 100px !important;
  margin-right: 1em;
  position: relative !important;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.favorite-item__image {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100px;
  height: 100px !important;
  border-radius: inherit;
}

.favorite-item__text-wrapper {
  width: 100%;
}

.favorite-item__price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 0.85rem;
}

.favorite-item__price {
  margin-bottom: 5px;
  font-size: 0.8rem;
  font-weight: 600;
}

.favorite-item__modifiers-total {
  color: var(--color-secondary);
  font-size: 0.75rem;
}

.favorite-item__name {
  margin: 0 0 5px;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
}

.favorite-item__options-list {
  width: 100%;
  overflow: hidden;
}

.favorite-item__options-list .options-list__row {
  font-size: 0.75rem;
  color: #676464;
}

.options-list__row + .options-list__row {
  margin-top: 4px;
}

.favorite-item__buttons-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px;
  margin-top: 20px;
  background-image: linear-gradient(to top, #fff 0%, #fff 38%, rgba(255, 255, 255, 0) 100%);
}

.favorite-item__button__gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 127px;
  background-image: linear-gradient(to top, #fff 10%, #fff 50%, rgba(255, 255, 255, 0) 100%);
  transition: back;
  pointer-events: none;
}
.favorite-item__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.favorite-item__buttons-container > button {
  flex: 1 1;
  min-width: 0;
}

.favorite-item__nested-options {
  margin-left: 20px;
}

.favorite-item__item-details-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.favorite-item__item-details-inner-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.favorite-item__nested-options-list {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 660px) {
  .favorite-item-card__image-and-text-wrapper {
    max-height: 220px;
    min-height: 220px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
    padding-bottom: 5em;
  }

  .favorite-item-card__image-and-text-wrapper::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 660px) {
  .favorite-item__button__gradient {
    display: none;
  }

  .favorite-item-card__image-and-text-wrapper {
    padding-bottom: 1em;
  }

  .favorite-item-card__image-wrapper {
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
  }
}

.menu-bill-toggle__container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.menu-bill-toggle__container > button {
  width: 50%;
  padding: 0.5em 0 0;
  font-weight: 600;
}

.menu-bill-toggle__button::after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 0px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
  margin-top: 0.5em;
}

.menu-bill-toggle__button.is-active:after {
  width: 100%;
  background: var(--color-primary);
}

.menu-bill-toggle__button.is-active {
  color: var(--color-primary);
}

.menu-bill-toggle__button > span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-bill-toggle__button > span > .icon {
  width: 20px;
  height: 20px;
}

.menu-bill-toggle__button.is-active > span > .icon {
  color: var(--color-primary);
  fill: var(--color-primary);
}

.menu-container {
  padding-top: 1.5em;
  padding-bottom: 12em;
}

.mobile-container {
  padding-left: 24px;
  padding-right: 24px;
}

.menu__category-cards-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  margin: -0.5em;
  padding: 0;
  list-style-type: none;
  animation: fadeIn 0.2s forwards;
}

.menu__back-to-previous-level-link {
  display: inline-flex;
  align-items: center;
  min-height: 30px;
  margin-bottom: 1em;
}

.menu__back-to-previous-level-link > .icon {
  width: 12px;
  height: 12px;
  margin-right: 0.6em;
}

.menu-back-to-previous-level-link__text {
  font-size: 1.2rem;
  font-weight: 600;
}

.menu__empty-message {
  margin: 2em 0 0;
  font-size: 1.2rem;
  text-align: center;
}

.payment-method__credit-card-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
}

.payment-method__credit-card-icons > .icon {
  width: 40px;
  height: 40px;
  fill: #2a2a2a;
}

.payment-method__credit-card-icons > .icon.cc-type--inactive {
  fill: #94a3bc;
}

.payment-method__credit-card-icons > .icon.cc-type--inactive.icon--alelo > path:nth-child(3) {
  fill: #94a3bc !important;
}

.payment-method__credit-card-icons > .icon + .icon {
  margin-left: 10px;
}

.credit-card-form__stored-credit-card-label {
  margin: 0 0 12px;
  font-size: 17px;
  font-weight: 600;
}

.credit-card-form__stored-credit-card {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}

.credit-card-form__stored-credit-card > svg {
  margin-right: 12px;
  padding: 3px;
  stroke-width: 3px;
  stroke: #fff;
  background-color: #4caf52;
  border-radius: 50%;
}

.credit-card-form__stored-credit-card-expiry {
  padding-left: 30px;
  color: #676767;
  font-size: 12px;
}

.save-credit-card-popup-buttons-container {
  display: flex;
}

.payment-overlay__container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.97);
  animation: fadeIn 0.3s;
  z-index: 999999;
}

.payment-overlay {
  opacity: 0;
  transform: translateY(150px);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: 900px;
  padding: calc(44px + 0.5em) 1.5em 2em;
  animation: slideUpConfirmationModal 0.3s forwards;
  animation-delay: 0.15s;
}

.payment-overlay__text {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.payment-overlay__header {
  display: flex;
  position: relative;
  min-height: 65px;
}

.payment-overlay__header-dine-in {
  display: flex;
  position: relative;
  min-height: 90px;
}

.payment-overlay__header-text {
  transform: translateX(100%);
  margin: auto 24px 0px 24px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: 65%;
  font-size: 1.1rem;
  position: unset;
  animation: slideInFromRight 0.2s forwards;
}

.payment-overlay__header-text-dine-in {
  transform: translateX(100%);
  margin: auto 0px auto 24px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: 65%;
  font-size: 1.1rem;
  position: unset;
  animation: slideInFromRight 0.2s forwards;
}

/* appear - on overlay load */
.slide-appear {
  opacity: 0;
  z-index: 1;
}

.slide-appear.slide-appear-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

/* on overlay text change - enter*/
.slide-enter {
  opacity: 0;
  transform: scale(0.97) translateY(5px);
  z-index: 1;
}

.slide-enter.slide-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: opacity 300ms linear 200ms, transform 300ms ease-in-out 200ms;
}

/* on overlay text change - exit */
.slide-exit {
  opacity: 0;
  transform: scale(1) translateY(0);
  display: none;
}

.slide-exit.slide-exit-active {
  opacity: 0;
  transform: scale(0.97) translateY(5px);
  transition: opacity 150ms linear, transform 150ms ease-out;
  display: none;
}

.slide-exit-done {
  opacity: 0;
}

.payment-overlay__secondary-header-text {
  min-width: -moz-fit-content;
  min-width: fit-content;
  right: -25px;
  margin-top: 40px;
  margin-left: 24px;
  bottom: 0;
  font-size: 0.8rem;
  font-weight: normal;
  color: #676767;
  transition: opacity 1s ease;
}

.payment-overlay__progress-bar-start {
  margin-left: 24px;
}

.payment-overlay__progress-bar-finish {
  margin-right: 24px;
}

.payment-overlay__do-not-refresh {
  margin: 24px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-size: 1rem;
}

.checkmark > svg {
  margin-right: 12px;
  padding: 3px;
  stroke-width: 3px;
  stroke: #fff;
  background-color: #4caf52;
  border-radius: 50%;
}

.MuiLinearProgress-colorPrimary {
  background-color: var(--color-gray-light) !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: var(--color-primary) !important;
}

@keyframes slideUpConfirmationModal {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromRight {
  100% {
    transform: none;
    transform: initial;
    opacity: 1;
  }
}

/**Desktop layout **/
@media screen and (min-width: 660px) {
  .payment-overlay__text {
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}

.payment-method__subheading {
  margin: 0 0 15px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

.custom-select__button--form {
  height: 43px;
  padding: 0 10px;
  color: #757575;
  font-size: 13px;
  text-align: left;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}

.custom-select__list-item--form {
  width: 100%;
}

.guest-giftcard__balance-summary {
  margin-bottom: 18px;
  text-align: right;
}

.guest-giftcard__balance-summary > * + * {
  margin-top: 16px;
}

.guest-giftcard__applied-cards {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 44px;
  padding-left: 0;
  line-height: 100%;
}

.guest-giftcard__value-column {
  display: inline-block;
  min-width: 53px;
}

.guest-giftcard__balance-summary + .button--intermediary-submit {
  margin-top: 18px;
}

.guest-giftcard__post-apply-total-wrapper {
  padding-top: 16px;
  color: #058000;
  font-weight: 600;
  border-top: 1px solid #ccc;
}

.guest-giftcard__post-apply-total--remaining {
  color: #ff2020;
}

.guest-giftcard-remaining {
  padding-top: 18px;
  border-top: 1px solid #ccc;
}

.guest-giftcard-remaining__instruction {
  margin-top: 0;
  margin-bottom: 18px;
  font-size: 0.9rem;
  text-align: center;
}

.button--remove-gift-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: auto 0;
  background-color: #ececec;
  border-radius: 50%;
}

.button--remove-gift-card > .icon--x {
  width: 14px;
  height: 14px;
  stroke-width: 3px;
  color: #757575;
}

.guest-giftcard__remainder-wrapper {
  font-size: 0.8rem;
  max-width: 100%;
}

.guest-giftcard__name {
  margin-bottom: 4px;
  letter-spacing: 0.5px;
  font-size: 15px;
}

.button--giftcard-remainder-option {
  display: flex;
  align-items: center;
  min-height: 24px;
  margin-bottom: 18px;
  font-size: 0.9rem;
  text-decoration: underline;
}

#form--apply-giftcard + .button--giftcard-remainder-option {
  margin-top: 36px;
}

.button--giftcard-remainder-option > .icon {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.guest-giftcard__order-total-wrapper {
  font-weight: 600;
}

.guest-giftcard__applied-card-row {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.guest-giftcard__applied-card-row + .guest-giftcard__applied-card-row {
  margin-top: 18px;
}

.gift-card__row_contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.gift-card__image-text-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.gift-card__image-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 83px;
  margin-right: 9.095%;
  border: 1px solid #efefef;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.gift-card__image {
  display: block;
}

.gift-card__balance-label {
  display: block;
  margin-bottom: 4px;
  font-size: 0.7rem;
  font-weight: 400;
  text-transform: uppercase;
}

.gift-card__balance {
  font-weight: 700;
}

.gift-card__registered-label {
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
}

.gift-card__select-wrapper {
  margin-top: 12px;
}

.gift-card__select-wrapper > select {
  height: 44px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 38px; /* accounts for the icon */
  line-height: 44px;
}

.gift-card__select-wrapper > .icon--arrow-down {
  right: 12px;
  width: 24px;
  height: 24px;
}

.gift-card__apply-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.gift-card__apply-button {
  position: relative;
  margin: 0;
}

.payment-method {
  width: 100%;
}

.payment-methods--collapsed > .payment-method + .payment-method {
  margin-top: 0;
}

.payment-methods--collapsed > .payment-method:not(.payment-method--active) {
  position: absolute;
  top: -10px;
  transform: scale(0.9);
  transition: opacity 0.6s;
}

.payment-method + .payment-method {
  margin-top: 10px;
}

.payment-method__button {
  position: relative;
  width: 100%;
  height: 51px;
  padding: 0 10px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 10px 1px rgba(2, 10, 6, 0.15);
  z-index: 1;
}

.payment-method--active > .payment-method__button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.payment-methods--collapsed .payment-method__button {
  box-shadow: 0px 0px 5px 0px rgba(2, 10, 6, 0.06);
}

.payment-methods--collapsed > .payment-method--active > .payment-method__button {
  box-shadow: 0px 2px 10px 1px rgba(2, 10, 6, 0.2);
}

.payment-method__only-button:disabled {
  color: #2a2a2a;
  background-color: #fff;
}

.payment-method__button > .icon:first-child {
  position: absolute;
  left: 30px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.payment-method__button > .icon:last-child {
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.payment-method__button > .icon[class*="arrow"] {
  width: 14px;
  height: 14px;
}

.payment-method__button > .icon:not([class*="arrow"]) {
  width: 20px;
  height: 20px;
}

.payment-method__gpay-button > .icon:not([class*="arrow"]) {
  width: 60px;
  height: 50px;
}

.payment-method__gpay-button > .icon:first-child {
  left: 15px !important;
}

.payment-method__applepay-button > .icon:not([class*="arrow"]) {
  width: 40px;
  height: 40px;
}

.payment-method__applepay-button > .icon:first-child {
  left: 20px !important;
}

.payment-method__details {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 203px;
  padding: 30px 28px 20px;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.15);
  animation: expandDetails 0.3s forwards;
}

.payment-method__details:empty {
  display: none;
}

@keyframes expandDetails {
  100% {
    opacity: 1;
    transform: none;
    transform: initial;
  }
}

.coupon-notice {
  color: #ff2020;
}

.payment-content {
  /*padding: 25px;*/
}

.payment-methods-container {
  padding: 25px;
}
.payment-summary {
  margin-top: 1em;
  margin-bottom: 28px;
}

.payment-content .additional-disclaimer {
  padding-left: 0;
  padding-right: 0;
}

.payment-summary__row {
  text-align: center;
}

.payment-summary__label,
.payment-summary__output {
  font-weight: 700;
  font-size: 18px;
}

.payment-summary__label {
  display: inline-block;
  margin: 0 15px 0 0;
  text-transform: uppercase;
}

.payment-methods {
  transform: scaleY(0);
  transform-origin: top center;
  position: relative;
  min-height: 100%;
  margin: 0 0 36px;
  padding-left: 0;
  list-style-type: none;
  animation: expandMethods 0.5s forwards;
}

.payment-methods--collapsed {
  animation: collapseMethods 0.5s forwards;
}

.expanded-payment-method__footer {
  display: flex;
  justify-content: center;
  margin-top: 36px;
  padding-top: 18px;
  border-top: 1px solid #efefef;
}

.expanded-payment-method__footer-button {
  display: flex;
  align-items: center;
  color: var(--color-secondary);
  font-size: 0.9rem;
  font-weight: 500;
}

.expanded-payment-method__footer-button > .icon {
  width: 13px;
  height: 13px;
  margin-right: 4px;
  stroke: var(--color-secondary);
}

.expanded-payment-method__footer-button > .icon--credit-card {
  fill: var(--color-secondary);
}

.button--apple-pay {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 51px;
  padding: 0 10px;
  text-transform: capitalize;
  background-color: #1a202c;
  border-radius: 3px;
  box-shadow: 0 0 10px 1px rgba(2, 10, 6, 0.15);
}

@keyframes expandMethods {
  to {
    transform: scaleY(1);
  }
}

@keyframes collapseMethods {
  to {
    transform: scaleY(1);
  }
}

.icon.icon--paypal2 {
  width: 145px;
  margin-top: 1em;
}

@media screen and (min-width: 660px) {
  .payment-content {
    max-width: 50%;
    margin: 2em auto 0;
  }
  .icon.icon--paypal2 {
    margin-top: 1.5em;
  }

  .google-pay-checkout-button__container .gpay-button-fill {
    padding-right: 24px;
    padding-left: 24px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    display: flex;
    justify-content: center;
  }
}

.paypal-button__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.paypal-button__container > p {
  text-align: center;
}

.paypal-button__continue-button {
  margin-top: 1em;
}

.google-pay-button__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.google-pay-button__container > p {
  text-align: center;
}

.google-pay-checkout-button__container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 25px;
  z-index: 1000;
}

.google-pay-button-container {
  position: relative;
  display: block !important;
  width: 70%;
  height: 52px;
  margin: 0 auto;
  z-index: 1;
}

.apple-pay-button__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.apple-pay-button__container > p {
  text-align: center;
}

.apple-pay-button__container .icon--apple-pay {
  padding-top: 5px;
}

.apple-pay-checkout-button__container {
  width: 100%;
  max-width: 1200px;
  height: 100px;
  position: fixed;
  display: flex;
  bottom: 0;
  padding-bottom: 25px;
  z-index: 1000;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.apple-pay-button {
  width: 50%;
  height: 52px;
  bottom: 25px;
  z-index: 1;
  box-sizing: border-box;
  padding: 0px 0px;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: check-out; /* Use any supported button type. */
}

.apple-pay-button-black {
  -apple-pay-button-style: black;
  margin: 0 auto;
}

.apple-pay-button-white {
  -apple-pay-button-style: white;
}

.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}

.form--credit-card__submit-button button {
  max-width: 100%;
  min-width: 70%;
  white-space: normal;
  word-wrap: break-word;
  padding: 0.8em 0.5em;
}

.form--credit-card__submit-button button > .FAB__success {
  white-space: normal;
  word-wrap: break-word;
}

#form--stored-credit-card > .form__field-wrapper:first-child {
  margin-top: 2em;
}

.individual-login-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  background-color: #fff;
  align-items: center;
}

.individual-login-button-container.float-right {
  justify-content: right;
}

.individual-login-button-container.no-right-padding {
  padding-right: 0;
}

.individual-login-button,
.individual-register-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-secondary);
  font-weight: 600;
  font-size: 14px;
}

.individual-login-button::after {
  content: "|";
  color: var(--color-secondary);
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 600;
  font-size: 14px;
}

.individual-login-button > .icon {
  background-color: var(--color-secondary);
  border-radius: 50%;
  padding: 3px;
  margin-right: 5px;
  width: 18px;
  height: auto;
}

.addon-quantity-buttons__main-wrapper {
  display: flex;
  padding: 0 5px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  /* margin-top: 15px; */
}

.addon-quantity-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--color-primary);
}

.addon-quantity-button:disabled {
  opacity: 0.3;
  border: 1px solid var(--color-primary);
}

.addon-quantity-button > .icon {
  color: var(--color-button-text);
}

.addon-quantity-button:disabled > .icon {
  color: var(--color-primary);
}

.addon-quantity-button:active {
  background-color: var(--color-button-text);
}

.addon-quantity-button:active > .icon {
  color: var(--color-primary);
}

.addon-quantity-input {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--color-gray-light);
  border-radius: 0;
  text-align: center;
  font-size: 1.2rem;
  padding-bottom: 2px;
  width: 45%;
  margin-bottom: 20px;
}

.order-item__options-form {
  opacity: 0;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
  animation: fadeIn 1s forwards;
}

.order-item__option-header {
  display: flex;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 15px;
  padding-bottom: 15px;

  position: relative;
  align-items: center;
  border-bottom: 1px solid #e2e1e1;
  justify-content: space-between;
  background-color: #eee;
}

.order-item__options-form .form__fieldset-legend:not(.form__fieldset-legend--nested) {
  display: none;
}

.order-item__options-form.visually-hidden {
  display: none;
}

.addon-modifier-modal__go-back-button {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}

.addon-modifier-modal__go-back-button > .icon {
  margin-right: 8px;
}

/* Form */
.addons-modifiers-form .form__fieldset-legend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-size: 1rem;
  font-weight: 600;
  padding: 18px 24px;
  background-color: #eee;
  position: relative;
}

.addons-modifiers-form .form__fieldset-legend .icon--minus,
.addons-modifiers-form .form__fieldset-legend .icon--plus {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.addons-modifiers-form .form__fieldset-legend > span:first-child {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.9em;
}

.nested-addons-fieldset-wrapper .form__fieldset--nested .form__fieldset-legend {
  padding-right: 0;
  margin-bottom: 0;
  background-color: transparent;
  padding-top: 10px;
  padding-bottom: 10px;
}

.order-item__option-heading .item-detail__option-note {
  text-transform: none;
  overflow: visible;
  text-overflow: clip;
}

.order-item__option-heading {
  margin: 0;
  font-size: 1em;
  font-weight: 600;
}

.order-item__option-heading > span > .icon--check {
  width: 18px;
  height: 18px;
  padding: 3px;
  stroke: #fff;
  stroke-width: 3px;
  background-color: #4caf52;
  border-radius: 50%;
  margin-left: 5px;
}

.order-item__option-heading > span {
  display: flex;
  align-items: center;
}

.order-item__option-heading .icon--minus,
.order-item__option-heading .icon--plus {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.addons-modifiers-form .form__field-label--checkbox-radio {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 60px);
  min-height: 44px;
  padding-left: 24px;
  font-size: 1rem;
}

.addons-modifiers-form .form__field-label--checkbox-radio::before {
  display: none;
}

/** Addon modifier custom checkbox and radio button positioning **/
.addons-modifiers-form .form__field-label--checkbox-radio::after {
  width: 25px;
  height: 25px;
  content: "";
  position: absolute;
  right: 24px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.addons-modifiers-form .form__field-label--checkbox-radio.is-not-checked::after {
  display: none;
}

.addons-modifiers-form .form__field-label--checkbox-radio.error::after {
  border-color: #ff2020;
}

.form__fieldset-legend-text--nested.item-detail__option-note.error,
.addons-modifiers-form__toggle-nested-options.error > .icon--plus {
  color: #ff2020;
}

input.form__field--checkbox:disabled + label.form__field-label--checkbox-radio::after {
  background-color: #ccc;
}

.form__field-label--radio::after {
  border-radius: 50% !important;
}

.addons-modifiers-form .form__field--radio:checked + .form__field-label--radio::after {
  background-image: url(/cws5/app/static/media/IconCircleRegular.b753df2b.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.addons-modifiers-form
  .form__field--radio:checked
  + .form__field-label--radio.halfWay-progression::after {
  background-image: url(/cws5/app/static/media/IconCircleHalf.46060773.svg);
  background-color: unset;
}

.addons-modifiers-form
  .form__field--radio:checked
  + .form__field-label--radio.full-progression::after {
  background-image: url(/cws5/app/static/media/IconCircleFull.f76bd60b.svg);
  background-color: unset;
}

.addons-modifiers-form .form__field--checkbox:checked + .form__field-label--checkbox::after {
  background-image: url(/cws5/app/static/media/IconSquareRegular.fade2cee.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.addons-modifiers-form
  .form__field--checkbox:checked
  + .form__field-label--checkbox.halfWay-progression::after {
  background-image: url(/cws5/app/static/media/IconSquareHalf.a0e6c169.svg);
  border: none;
  border-radius: unset;
  background-color: unset;
}

.addons-modifiers-form
  .form__field--checkbox:checked
  + .form__field-label--checkbox.full-progression::after {
  background-image: url(/cws5/app/static/media/IconSquareFull.eba47c69.svg);
  border: none;
  border-radius: unset;
  background-color: unset;
}

.addons-modifiers-form .form__field-wrapper--checkbox-radio + .form__field-wrapper--checkbox-radio {
  margin-top: 0;
  padding-top: 0;
}

.addons-modifiers-form .form__buttons-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 24px 24px;
  display: none;
}

.addons-modifiers-form .form__buttons-wrapper > .button {
  width: 50%;
}

.addons-modifiers-form .form__cancel-button {
  margin-right: 12px;
  background-color: #fff;
}

.item-detail__option-button > .icon--check {
  stroke: #fff;
  stroke-width: 3px;
  width: 18px;
  height: 18px;
  padding: 3px;
  background-color: #4caf52;
  border-radius: 100%;
}

.nested-addons-fieldset-wrapper {
  position: relative;
  margin-left: 36px;
  margin-right: 24px;
  padding: 5px 0;
  margin-top: 0;
}

.nested-addons-fieldset-wrapper + .nested-addons-fieldset-wrapper {
  border-top: 1px solid #dedede;
}

.form__fieldset--nested {
  opacity: 0;
  animation: fadeIn 0.6s forwards;
}

.form__fieldset--nested + .form__fieldset--nested {
  margin-top: 8px;
}

.form__fieldset--top-level > div > div.form__field-wrapper--checkbox-radio,
.form__fieldset > div > div.form__field-wrapper--checkbox {
  border-top: 1px solid #f2f2f2;
}

.form__field-wrapper--checkbox-radio__gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 19%,
    rgba(238, 238, 238, 1) 100%
  );
}

.form__field-wrapper + .form__fieldset--nested {
  margin-top: 8px;
}

.addons-modifiers-form__toggle-nested-options {
  position: absolute;
  display: flex;
  align-items: center;
  top: 8px;
  width: 100%;
  height: 30px;
  z-index: 1;
}

.addons-modifiers-form__toggle-nested-options > .icon--plus {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.addons-modifiers-form__toggle-nested-options > .icon--arrow-down {
  width: 24px;
  height: 24px;
  transition: transform 0.3s;
}

.addons-modifiers-form__toggle-nested-options > .icon--arrow-down.icon--rotate-right {
  transform: rotate(-90deg);
}

.form__fieldset-legend--nested {
  display: flex;
  align-items: center;
  min-height: 30px;
}

.form__fieldset-legend-text--nested {
  line-height: 110%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.form__fieldset-legend-text--nested > .icon--check {
  width: 15px;
  height: 15px;
  padding: 3px;
  stroke: #fff;
  stroke-width: 3px;
  background-color: #4caf52;
  border-radius: 50%;
  margin-left: 5px;
}

.form__field-wrappers {
  transform: scaleY(1);
  transform-origin: top center;
}

.form__field-wrappers--collapsing {
  animation: collapseNestedOptions 0.5s forwards;
}

.form__field-wrappers--expanding {
  animation: expandNestedOptions 0.5s forwards;
}

.menu-item__addon-price {
  min-width: 120px;
  text-align: right;
}

.item-addon__out-of-stock-label {
  color: #ff2020;
}

@keyframes collapseNestedOptions {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes expandNestedOptions {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.nested-modifiers__modal-wrapper {
  z-index: 1200;
}

.nested-modifiers__modal-wrapper > .modal {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 0;
}

.nested-modifiers__modal-wrapper .form__field-label--checkbox-radio::after {
  content: "";
  position: absolute;
  right: 24px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.nested-modifiers__modal-wrapper .form__field-label--radio,
.nested-modifiers__modal-wrapper .form__field-label--checkbox {
  padding: 0 24px;
}
.nested-modifiers__modal-wrapper .form__field-label--radio::after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.nested-modifiers__modal-wrapper .form__field-label--checkbox::after {
  width: 25px;
  height: 25px;
  border-radius: 4px;
}

.nested-modifiers__modal-wrapper .form__field-label--radio::before,
.nested-modifiers__modal-wrapper .form__field-label--checkbox-radio::before {
  display: none;
}

.nested-modifiers__option-wrapper {
  width: 100%;
  padding: 10px 0;
  margin: 0;
  display: flex;
  align-content: center;
  align-items: center;
}

.nested-modifiers__option-wrapper + .nested-modifiers__option-wrapper {
  border-top: 1px solid #dedede;
  margin-top: 0;
}

.nested-modifiers__modal-wrapper
  .form__field--checkbox:checked
  + .form__field-label--checkbox::after {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='icon icon--check'><polyline points='20 6 9 17 4 12'></polyline></svg>");
  background-repeat: no-repeat;
  background-position: center;
}

.nested-modifiers__modal-wrapper .form__field--radio:checked + .form__field-label--radio::after {
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'><circle cx='50' cy='50' r='30'/></svg>");
  background-repeat: no-repeat;
  background-position: center;
}

.nested-options__form-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #eee;
  padding: 20px 24px;
  align-items: center;
  margin-top: 0;
}

.nested-options__form-header > span {
  display: flex;
  align-items: center;
}

.nested-options__form-header > span > .icon--check {
  width: 18px;
  height: 18px;
  padding: 3px;
  stroke: #fff;
  stroke-width: 3px;
  background-color: #4caf52;
  border-radius: 50%;
  margin-left: 5px;
}

.nested-modifiers__modal-wrapper > .modal > .modal__buttons {
  width: 100%;
  margin-top: 0;
}

.nested-modifiers__modal-wrapper > .modal > .modal__buttons > button {
  width: 100%;
  border-radius: 0;
  margin: 0;
  padding: 15px 0;
}

.nested-modifiers__modal-wrapper .form__field-label--checkbox-radio::before {
}

.nested-modifiers__modal-wrapper .form__field-wrapper--checkbox {
  width: 100%;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.nested-modifiers__modal-wrapper > .modal {
  opacity: 1;
  animation: slideUp 0.5s forwards;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.nested-modifiers__modal-wrapper.form__field-wrappers--expanding,
.nested-modifiers__modal-wrapper.form__field-wrappers--collapsing {
  animation: none;
}

.nested-modifiers__modal-wrapper.form__field-wrappers--collapsing > .modal {
  animation: slideDown 0.5s forwards;
}

.addons-modifiers-form__toggle-nested-options > .icon--plus {
  margin-top: 4px;
}

.nested-addons-fieldset-wrapper.wizard legend {
  display: none;
}

.nested-addons-fieldset-wrapper.wizard {
  width: 100%;
  border-radius: 15px;
  border: 2px solid var(--color-primary);
  padding: 0;
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 0;
}

.nested-addons-fieldset-wrapper.wizard.smaller {
  margin: 10px auto;
  animation: scaleExpand 0.5s forwards;
}

@keyframes scaleExpand {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleExpandY {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

.nested-addons-fieldset-wrapper.wizard .form__field-wrappers {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.nested-addons-fieldset-wrapper.wizard .form__field-wrappers > div {
  padding: 0;
}

.nested-addons-fieldset-wrapper.wizard .form__field-wrappers > div:last-child {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.nested-addons-fieldset-wrapper.wizard .form__field-wrappers > header {
  padding: 15px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  font-weight: 500;
}

.nested-addons-fieldset-wrapper.wizard .form__field-wrappers > div > label {
  min-height: unset;
  text-align: left;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-left: 15px;
}

.nested-addons-fieldset-wrapper.wizard .form__field-wrappers > div > label:after {
  top: -8px;
  right: 11px;
}

.nested-group__done_button {
  background-color: var(--color-primary);
  color: var(--color-button-text);
  margin-top: 0;
  padding: 10px 0;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
}

.closed-nested-group.wizard {
  display: flex;
  justify-content: space-between !important;
  padding: 5px 5px;
  height: unset !important;
  align-items: center;
  border-radius: 0;
  width: 100%;
  margin: 0;
  background-color: var(--color-primary);
  color: var(--color-button-text);
  flex-direction: row !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border: 2px solid var(--color-primary) !important;
  animation: scaleExpandY 0.5s forwards;
}

.closed-nested-group.wizard.no-image-nested-group {
  width: 88%;
  margin: 0 auto 10px;
}

.closed-nested-group.wizard > button {
  color: var(--color-button-text);
}

.form__fieldset-addonModifier
  > .form__field-wrapper--checkbox-radio__background
  .closed-nested-group.wizard:first-child {
  margin-top: -10px;
}

.form__fieldset-addonModifier
  > .form__field-wrapper--checkbox-radio__background
  .closed-nested-group.wizard:not(:last-child) {
  border-radius: 0 !important;
}

.form__field-wrapper--checkbox-radio__gray-overlay {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  opacity: 0.6;
}

.menu__category-card.withQuickAddButton > .form__field-wrapper--checkbox-radio__gray-overlay {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  opacity: 0.6;
  z-index: 1;
  border-radius: 6px;
}

.form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background > div,
.form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient > div {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  border: 1px solid #f2f2f2;
}

.addons-modifiers-form .form__field-label--checkbox-radio.no-thankyou-option::after {
  top: 13px;
  right: 20px;
}

.no-image-addon_with-quantity {
  flex-direction: column;
  align-items: baseline;
  margin: 10px 0;
}

.no-image-addon_with-quantity + .addon-quantity-buttons__main-wrapper {
  padding: 0 25px 0 60px;
}

.no-image-addon_with-quantity-wrapper {
  flex-direction: column !important;
}

.no-image-addon_with-quantity-wrapper > .addon-quantity-buttons__main-wrapper {
  opacity: 0;
}

label.no-image-addon_with-quantity.form__field-label--checkbox-radio {
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
}

.addon-modifier-choice__overlay-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.addon-modifier-choice__overlay-button.with-quantity-buttons {
  height: 80%;
}

.form__field-wrapper--checkbox-radio__background.with-image {
  transform: scale(0);
}

.form__field-wrapper--checkbox-radio__background.no-image {
  transform: scaleY(0);
}

/** mobile specific style **/
@media screen and (max-width: 660px) {
  .form__fieldset-legend-text--nested {
    line-height: 110%;
    color: #ff2020;
    font-size: 0.9rem;
    font-weight: 400;
    background-color: transparent;
  }

  .nested-addons-fieldset-wrapper .form__fieldset--nested .form__fieldset-legend {
    padding-left: 17px;
  }

  .form__fieldset-addonModifier {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    grid-gap: 0.75rem;
    gap: 0.75rem;
    padding: 0 30px;
  }

  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 45% 1;
    width: 8.625em;
    max-width: 8.625em;
  }
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background{
    max-width: 10rem;
  }
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background > div.is-checked,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient > div.is-checked {
    border: 2px solid var(--color-primary);
  }

  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background > div > img,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient > div > img {
    border-top-left-radius: 0.8em;
    border-top-right-radius: 0.8em;
  }

  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;
  }
  .form__fieldset-addonModifier .form__field-label--checkbox-radio,
  .no-image-group .nested-addons-fieldset-wrapper .form__field-label--checkbox-radio {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0 5px;
    margin: 10px 0;
    width: 100%;
  }

  .form__fieldset-addonModifier .form__field-label--checkbox-radio::after,
  .no-image-group .nested-addons-fieldset-wrapper .form__field-label--checkbox-radio::after {
    right: 8px;
    top: 8px;
    margin: 0;
  }

  .form__fieldset-addonModifier .form__field-label--checkbox-radio > span {
    text-align: center;
  }

  .no-image-group .form__field-label--checkbox-radio > span:nth-child(2) {
    margin-right: 10px;
  }

  label.no-image-addon_with-quantity.form__field-label--checkbox-radio {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 60px);
    min-height: 44px;
    padding-left: 24px;
    font-size: 1rem;
    align-items: center;
  }

  label.no-image-addon_with-quantity.form__field-label--checkbox-radio::after {
    top: -42px;
  }

  .no-image-addon_with-quantity-wrapper {
    align-items: baseline;
  }

  .no-image-addon_with-quantity-wrapper .addon-quantity-buttons__main-wrapper {
    padding: 0 35% !important;
  }

  .nested-addons-fieldset-wrapper.wizard .form__field-wrappers > div > label:after {
    top: -3px;
  }

  .addon-modifier_image {
    max-height: 156px;
    min-height: 156px;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

  .form__field-wrapper--checkbox-radio__background.with-image {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  .form__field-wrapper--checkbox-radio__background.with-image.with-quantity-buttons
    > div.form__field-wrapper--checkbox-radio {
    height: 100%;
    justify-content: space-between;
  }
}

/** Desktop specific design **/
@media screen and (min-width: 660px) {
  .desktop-nested-addons-fieldset-container {
    width: 100%;
  }
  .form__fieldset-addonModifier {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px;
  }

  div.form__field-wrapper--checkbox-radio__background.is-checked {
    border: 2px solid var(--color-primary);
  }

  div.form__field-wrapper--checkbox-radio__background.no-image.is-checked {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid var(--color-gray-light);
  }

  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 30% 1;
    width: 30%;
    max-width: 30%;
    border: 1px solid var(--color-gray-light);
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
  }

  .addons-modifiers-form .form__field-label--checkbox-radio {
    width: 100%;
    padding: 5px 15px;
    flex-direction: column;
    justify-content: center;
  }

  .no-image-group .form__field-label--checkbox-radio {
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
  }

  .no-image-group .form__field-label--checkbox-radio > span:first-child {
    text-align: left;
  }

  .no-image-group .form__field-label--checkbox-radio > span:nth-child(2) {
    text-align: right;
    margin-right: 10px;
  }

  .addons-modifiers-form .form__field-label--checkbox-radio > span {
    text-align: center;
  }

  .addons-modifiers-form .form__field-label--checkbox-radio::after {
    bottom: unset;
    top: 10px;
    right: 5px;
  }

  .form__field-wrapper--checkbox {
    flex-direction: column;
  }

  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background > div,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient > div {
    border-radius: 5px;
  }

  .form__fieldset--top-level > div > div.form__field-wrapper--checkbox-radio,
  .form__fieldset > div > div.form__field-wrapper--checkbox {
    border: none;
    position: relative;
  }

  .form__fieldset--top-level > div > div.form__field-wrapper--checkbox-radio > img,
  .form__fieldset > div > div.form__field-wrapper--checkbox > img {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }

  .nested-addons-fieldset-wrapper.wizard .form__field-wrappers > div > label {
    min-height: 40px;
  }
  .nested-addons-fieldset-wrapper.wizard .form__field-wrappers > div > label:after {
    top: 10px;
  }

  .nested-modifiers__option-wrapper {
    width: 45%;
  }

  .form__fieldset.form__fieldset--nested .form__field-wrappers > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 15px;
    flex-wrap: wrap;
  }

  .form__fieldset.form__fieldset--nested .form__field-wrappers > div label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
  }

  .form__fieldset.form__fieldset--nested .form__field-wrappers > div > div {
    border-top: none;
    border-bottom: 1px solid var(--color-gray-light);
    padding: 0;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .form__fieldset.form__fieldset--nested .form__field-wrappers > div label::after {
    top: 10px;
    right: 0;
  }

  .form__fieldset.form__fieldset--top-level.no-image-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .form__fieldset.form__fieldset--top-level.no-image-group
    div.form__field-wrapper--checkbox-radio__background {
    width: 48%;
    border-bottom: 1px solid var(--color-gray-light);
  }

  .form__fieldset.form__fieldset--top-level.no-image-group
    div.form__field-wrapper--checkbox-radio__background
    > div {
    margin: 10px 0;
  }

  .form__fieldset.form__fieldset--top-level.no-image-group
    div.form__field-wrapper--checkbox-radio__background
    > div {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
  }

  .no-image-addon_with-quantity + .addon-quantity-buttons__main-wrapper {
    padding: 0 20px;
  }

  .no-image-addon_with-quantity-wrapper .addon-quantity-buttons__main-wrapper {
    padding: 0 100px !important;
  }

  label.no-image-addon_with-quantity.form__field-label--checkbox-radio {
    width: 100%;
    padding-right: 35px;
  }

  .addons-modifiers-form .form__field-label--checkbox-radio.no-thankyou-option::after {
    top: 5px;
    right: 5px;
  }

  .form__fieldset-addonModifier {
    justify-content: flex-start;
  }
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 16% 1;
    width: 16%;
    max-width: 16%;
    margin-right: 5px;
  }

  .form__field-wrapper--checkbox-radio__background.with-image {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  .form__field-wrapper--checkbox-radio__background.with-image.with-quantity-buttons
    > div.form__field-wrapper--checkbox-radio {
    height: 100%;
    justify-content: space-between;
  }
  .addons-modifiers-form .form__field-label--checkbox-radio {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 661px) and (max-width: 800px) {
  .form__fieldset.form__fieldset--top-level.no-image-group
    div.form__field-wrapper--checkbox-radio__background {
    width: 100%;
  }

  .no-image-addon_with-quantity + .addon-quantity-buttons__main-wrapper {
    padding: 0 20px 0 230px;
  }

  .no-image-addon_with-quantity-wrapper .addon-quantity-buttons__main-wrapper {
    padding: 0 35% !important;
  }

  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 6.125em 1;
    width: 6.125em;
    max-width: 6.125em;
    margin-right: 5px;
  }

  .addon-modifier_image {
    max-height: 6em;
    min-height: 6em;
    width: 6em;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media screen and (min-width: 800px) {
  .no-image-addon_with-quantity-wrapper .addon-quantity-buttons__main-wrapper {
    padding: 0 35% !important;
  }

  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 7.125em 1;
    width: 7.125em;
    max-width: 7.125em;
    margin-right: 5px;
  }

  .addon-modifier_image {
    max-height: 7em;
    min-height: 7em;
    width: 7em;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media screen and (min-width: 900px) {
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 8.125em 1;
    width: 8.125em;
    max-width: 8.125em;
    margin-right: 5px;
  }

  .addon-modifier_image {
    max-height: 8em;
    min-height: 8em;
    width: 8em;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media screen and (min-width: 1000px) {
  .form__fieldset-addonModifier {
    justify-content: flex-start;
  }
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 9.125em 1;
    width: 9.125em;
    max-width: 9.125em;
    margin-right: 5px;
  }

  .addon-modifier_image {
    max-height: 9em;
    min-height: 9em;
    width: 9em;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media screen and (min-width: 1200px) {
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__background,
  .form__fieldset-addonModifier > .form__field-wrapper--checkbox-radio__gradient {
    flex: 10.125em 1;
    width: 10.125em;
    max-width: 10.125em;
    margin-right: 5px;
  }

  .addon-modifier_image {
    max-height: 10em;
    min-height: 10em;
    width: 10em;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.item-detail__quantity-container {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
}

.item-detail__quantity-buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4px 5px 10px 5px;
  align-items: center;
}

.item-detail__quantity-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: var(--color-primary);
  border-radius: 50%;
  transition: background 0.1s;
}

.item-detail__quantity-button:active {
  background: none;
  background-color: #2a2a2a;
}

.item-detail__quantity-button > .icon {
  stroke-width: 2px;
  transition: color 0.1s;
  stroke: var(--color-button-text);
}

.item-detail__quantity-button:active > .icon {
  color: white;
}

.item-detail__quantity-button:disabled {
  opacity: 1;
  background-color: #fff;
  border: 1px solid var(--color-gray-light);
}

.item-detail__quantity-button:disabled > .icon {
  stroke: var(--color-gray-light);
}

.item-detail__quantity-input {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--color-gray-light);
  border-radius: 0;
  text-align: center;
  font-size: 1.2rem;
  padding-bottom: 5px;
  width: 50%;
  margin-bottom: 25px;
}

/** Mobile Specific Design **/

@media screen and (max-width: 660px) {
  .item-detail__quantity-buttons-container {
    background-color: #fff;
    padding: 1em 7em;
  }

  .item-detail__quantity-container {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .item-detail__quantity-container > .item-detail__options-subheading {
    margin: 0;
  }
}

/** Desktop layout **/
@media screen and (min-width: 660px) {
  .item-detail__quantity-container {
    margin-top: 2em;
  }

  .item-detail__quantity-buttons-container {
    width: 50%;
    margin: 0 auto;
  }

  .item-detail__quantity-header {
    margin: 0;
    font-size: 1em;
    font-weight: 600;
  }

  .item-detail__quantity-container {
    padding-left: 0;
    padding-right: 0;
  }
  .item-detail__quantity-buttons-container {
    margin-top: 1em;
    padding-left: 15%;
    padding-right: 15%;
  }
}
/** end of desktop layout **/

/* MenuItemDetails.css */
.menu-item-detail-wizard {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /*overflow-y: auto;*/
  -webkit-overflow-scrolling: touch; /* enables iOS "momentum" style scrolling */
  z-index: 1000;
  padding-bottom: 300%;
}

.menu-item-detail-wizard p {
  margin: 0;
}

.menu-item-detail {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 93%;
  /*overflow-y: auto;*/
  -webkit-overflow-scrolling: touch; /* enables iOS "momentum" style scrolling */
  z-index: 1000;
}

.menu-item-detail--animate-in {
  opacity: 0;
  transform: scale(0);
  animation: scaleUp 0.3s forwards;
}

.menu-item-detail--skip-animation {
  opacity: 1;
  transform: scale(1);
  animation: none;
}

.item-details__cart-indicator {
  position: fixed;
  bottom: calc(57px + 16px);
  right: 16px;
  z-index: 1100;
  background: linear-gradient(180deg, #fff, #fff 35%, #f6f6f6 71%, #ededed 94%, #ededed);
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
}

@keyframes scaleUp {
  100% {
    opacity: 1;
    transform: none;
  }
}

.menu-item-detail--closing {
  animation: scaleDown 0.3s forwards;
}

@keyframes scaleDown {
  0% {
    opacity: 1;
    transform: none;
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes hideOrderButton {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -100px;
  }
}

@keyframes showOrderButton {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 0;
  }
}

.hide-add-to-order {
  animation: hideOrderButton 0.3s forwards;
}

.show-add-to-order {
  animation: showOrderButton 0.3s forwards;
}

.item-detail__button--close {
  padding-top: 30px;
}
.combo-item-detail__button--close__wrapper {
  padding: 0 24px;
}

.item-details-main__Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

.item-detail__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0; /* Fixes iOS cropping */
  padding: 30px;
  background-color: #fff;
  transition: all 0.5s;
}

.item-detail__top-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
}

.item-detail__transition-container {
  background-color: transparent;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 0px;
  left: 0;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  z-index: 2;
}

.item-detail__transition-container.wizard {
  top: 73px;
  max-height: 50%;
}

.item-detail__transition-container > .item-detail__heading {
  margin: 0;
  opacity: 0;
}

.item-detail__transition-container.show {
  box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.77);
  background-color: #fff;
  opacity: 1;
  transition: background-color 0.5s, box-shadow 0.5s, opacity 0.5s;
}

.item-detail__transition-container.hide {
  box-shadow: none;
  background-color: transparent;
  transition: background-color 0.5s, box-shadow 0.5s, opacity 0.5s;
}

.item-detail__transition-container.hide.wizard {
  opacity: 0;
}

.item-detail__transition-container.show > .item-detail__heading {
  opacity: 1;
  transition: opacity 0.5s;
}

.item-detail__transition-container.hide > .item-detail__heading {
  opacity: 0;
  transition: opacity 0.5s;
}

.item-detail__transition-container > .item-detail__button--close {
  padding-top: 0;
  width: 40px;
  height: 40px;
}

.item-details__top-content__fav-button {
  padding-top: 30px;
  width: unset;
  height: unset;
}

.item-detail__image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 225px;
  height: 219px;
  margin-bottom: 25px;
  border-radius: 4px;
  overflow: hidden;
}

.item-detail__image-wrapper.wizard {
  width: 225px;
  border-radius: 4px;
  overflow: hidden;
  width: 40%;
  height: unset;
  margin-bottom: 0;
  max-width: 225px;
}

.item-detail__transition-container .item-detail__image-wrapper {
  width: 20%;
  margin: 0 15px 0 0;
}

.item-detail__transition-container .item_detail__secondary-info_wrapper {
  width: 100%;
}

.item-detail__transition-container .item-detail__description {
  height: calc(13px * 1 * 1.5);
  -webkit-line-clamp: 1;
  max-width: 180px;
}

.item-detail__transition-container .item__description--read-more {
  top: 0;
  right: 0;
  position: absolute;
  margin-top: 0;
}

.item-detail__transition-container
  .item-detail__description--expanded
  + .item__description--read-more {
  position: relative;
}

.item_detail__secondary-info_wrapper {
  width: 58%;
}

.item_detail__secondary-info_wrapper.expanded {
  width: 100%;
}

.item-detail__image {
  width: 100px;
  height: 100px !important;
  border-radius: inherit;
}

.item-detail__heading.wizard {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 700;
  padding-bottom: 8px;
}

.item-detail__heading {
  margin: 0 auto 20px;
  font-size: 1.25rem;
  font-weight: 500;
}

.item-detail__options-subheading {
  font-weight: 600;
  margin: 0 0 5px;
}

.item-detail__options + .item-detail__options-subheading {
  margin-top: 15px;
}

.item_detail__top-content.wizard {
  width: 100%;
}

.item-detail__secondary-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.item-detail__secondary-info.wizard {
  position: relative;
}

.item-detail__description {
  position: relative;
  max-height: 20em;
  overflow: hidden;
  margin: 0 15px 0 0;
  font-size: 13px;
  line-height: 1.5;
  text-align: left;
  transition: max-height 0.2s;
  white-space: pre-line;
  width: 100%;
  word-wrap: break-word;
}

.item-detail__description.wizard {
  max-width: 400px;
  height: calc(13px * 3 * 1.5);
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin: 0 15px 0 0;
  font-size: 13px;
  line-height: 1.5;
  width: 100%;
}

.item-detail__price-calories {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.item-detail__price-calories > p {
  margin: 0;
}

.item-detail__description-wrapper {
  width: 100%;
}

.item-detail__description--expanded {
  max-height: 100%;
  min-height: 5.5em;
  transition: max-height 0.5s;
  transition: min-height 0.5s;
  display: unset;
}

.item-detail__description.wizard.item-detail__description--expanded {
  overflow: visible;
}

.item-detail__description--expanded.wizard {
  height: unset;
  display: block;
}

.item__description--read-more {
  color: var(--color-secondary);
  text-align: right;
  float: right;
}

.item-details__description-button {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 10;
}

.item__description--read-more-container {
  width: 100%;
}

.item_description__gradient {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 18px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 18%, rgba(255, 255, 255, 0) 100%);
}

.item-detail__calories.wizard,
.item-detail__price.wizard {
  color: #757575;
  font-size: 1rem;
  font-weight: 600;
}

.item-detail__calories,
.item-detail__price {
  color: #757575;
  font-size: 1.125rem;
  font-weight: 700;
  margin-left: 5px;
}

.item-detail__price-width {
  display: flex;
  justify-content: flex-end;
  width: inherit;
}

.item-detail__calories:before {
  content: "|";
  margin-right: 5px;
}

.item-detail__calories.wizard:before {
  content: "";
  display: none;
}

.item-detail__calories.wizard {
  margin-left: 0;
}

.item-detail__calories-no-price {
  margin-left: 0;
}
.item-detail__calories-no-price::before {
  content: none;
  margin: 0;
}

.item-detail__options-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  flex-shrink: 0; /* Fixes iOS cropping */
  width: 100%;
  padding: 0 0 300%;
  background: #fff;
}

.item-detail__options-heading {
  margin: 0 0 10px;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  text-align: center;
}

.item-detail__options-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* enables iOS "momentum" style scrolling */
  scroll-behavior: smooth;
}

.item-detail__options-group + .item-detail__options-subheading {
  margin-top: 12px;
}

.item-detail__options-group + .special-instructions-wrapper {
  margin-top: 36px;
}

.item-detail__options {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  flex-direction: column;
}

.item-detail__options:empty {
  display: none; /* when option groups don't have names */
}

.item-detail__options + .item-detail__options {
  margin-top: 18px;
}

.item-detail__option + .item-detail__option {
  margin-top: 0px;
}

.item-detail__option:last-child:not(:first-child) > .item-detail__option-button {
  margin-right: 30px;
}

.item-detail__option-button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  min-height: 53px;
  padding: 10px 20px;
  background: linear-gradient(180deg, #fff 0, #fff 35%, #f6f6f6 71%, #ededed 94%, #ededed);
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.12);
}

.item-detail__option-text-container {
  text-align: left;
}

.item-detail__option-button--instructions {
  margin: 18px 0 0;
}

.item-detail__option-button-name {
  font-size: 0.8125rem;
  font-weight: 500;
  text-align: left;
  margin-bottom: 5px;
}

.item-detail__option-note {
  color: #4a5568;
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
}

.item-detail__option-note.icon--plus,
.item-detail__option-note.icon--minus {
  width: 15px;
  height: 15px;
  stroke-width: 3px;
  margin-right: 3px;
}

.item-detail__form__fieldset-legend-button {
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.item-detail__form-expanded {
  /* Set our transitions up. */
  max-height: 100%;
}

.item-detail__form-collapsed {
  max-height: 0;
  overflow: hidden;
}

.item-detail__option-note--mandatory {
  color: #ff2020;
}

button:disabled .item-detail__option-note--mandatory {
  color: #dadae0;
}

.item-detail__option-button:disabled > .icon--pencil {
  color: #dadae0;
}

.item-detail__option-button > .icon--pencil {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 20px;
}

.modal__form {
  width: 100%;
  margin-bottom: 20px;
}

.modal-wrapper--item-options {
  z-index: 1200;
}

.item-options__modal-hint {
  margin-bottom: 10px;
  display: block;
  color: #4a5568;
}

.item-options__modal-hint--mandatory {
  color: #ff2020;
}

.modal__form-field-row {
  position: relative;
}

.modal__form-field-row + .modal__form-field-row {
  margin-top: 15px;
}

.modal__form-subfield {
  margin-left: 30px;
  margin-top: 15px;
}

.modal__legend {
  margin-bottom: 25px;
  font-size: 1.0625rem;
  font-weight: 600;
  text-align: center;
}

.modal__legend--subfield {
  margin-bottom: 15px;
}

.modal__option-label::before {
  content: "";
  position: absolute;
  top: 5px;
  margin: auto 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  background-color: #eee;
}

.modal__form-subfield-group {
  margin-top: 15px;
  transition: max-height 0.25s;
  max-height: 0;
  overflow: hidden;
}

.modal__option-checkbox:checked ~ .modal__form-subfield-group {
  max-height: 500px;
}

.modal__form-subfield-row {
  margin-left: 40px;
  position: relative;
}

.modal__form-subfield-row + .modal__form-subfield-row {
  margin-top: 15px;
}

.modal__sub-option-label {
  padding-left: 40px;
}

.modal__sub-option-label::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  background-color: #eee;
}

.modal__option-checkbox:checked + .modal__option-label::before {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='icon icon--check'><polyline points='20 6 9 17 4 12'></polyline></svg>");
  background-repeat: no-repeat;
  background-position: center;
}

.modal__option-checkbox:disabled + .modal__option-label {
  color: #cdcdcd;
}

.modal__option-checkbox:disabled + .modal__option-label:before {
  background-color: #f5f5f5;
}

.modal__option-checkbox:focus + .modal__option-label::before {
  outline: 1px solid #4299e1;
}

.modal__option-name {
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: capitalize;
}

.modal__option-price {
  color: #4299e1;
  font-size: 0.8125rem;
  display: block;
}

.modal__option-checkmark {
  background-color: #4caf52;
  stroke: #fff;
  stroke-width: 3px;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  padding: 3px;
}

.modal__options-hint {
  margin-bottom: 25px;
  font-size: 1rem;
}

.special-instructions-wrapper {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.special-instructions-wrapper > .order-item__option-header {
  background-color: #eee;
}

.special-instructions__field {
  width: 80%;
  padding: 10px 18px;
  font-size: 0.875rem;
  font-weight: 400;
  border: none;
  border-radius: 4px;
  border: 1px solid var(--color-gray-light);
  margin: 15px auto;
  margin-bottom: 20px;
}
.suggested-item-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 95%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
}
.itemLevelSuggestedItemsHeading{
  margin-bottom: 5px;
}
.comboSuggestedItemWrapper{
  height: 100% !important;
}
.suggested-items-list{
  padding: 10px 24px;
}
.suggested-item-card{
  width: calc(75% / 3 - 1em) !important;
  max-width: 170px;
}

.suggested-item-order-level-card{
  width: calc(100% / 2 - 1em) !important;
  max-width: auto;
  margin: 0 !important;
}
/* Add to Favorites button */
.heart-icon--flicker {
  animation: flickerFavoritesButton 0.35s forwards;
}

@keyframes flickerFavoritesButton {
  50% {
    transform: scale(2);
    opacity: 0.5;
  }
  100% {
    transform: none;
    transform: initial;
    opacity: 1;
  }
}

.item-detail__image-overlap-cotnainer,
.item-detail__image-underlay {
  position: relative;
  left: 0;
  top: 0;
  max-height: 100%;
}

.item-detail__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.desktop-order-menu__card-image.item-detail__image-overlay {
  -o-object-fit: cover;
     object-fit: cover;
}

.item-detail__option {
  width: 100%;
}

.menu-item-detail #special-instructions,
.desktop-order-item__options-container #special-instructions {
  background-color: #fff !important;
}

.menu-item__favourite-dialog-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu-item__favourite-dialog-modal p {
  margin-bottom: 36px;
}

.menu-item__favourite-dialog-modal-buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.menu-item__favourite-dialog-modal + .dialog-modal__buttons {
  display: none;
}

@keyframes slideUp {
  0% {
    transform: scale(2);
  }
  100% {
    transform: none;
    transform: initial;
  }
}

.menu-item__top-content-animation {
  top: 73px;
  max-height: 15%;
  background-color: transparent;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  left: 0;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  z-index: 2;

  transition: all 3s;
}

.menu-item__top-content-animation.show {
  box-shadow: 0px 2px 14px -2px rgb(0 0 0 / 77%);
  background-color: #fff;
  opacity: 1;
  transition: background-color 0.5s, box-shadow 0.5s, opacity 0.5s;
}

.menu-item__top-content-animation.show .testClass {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu-item__top-content-animation.show .item-detail__image-wrapper {
  float: unset;
  width: 10%;
}

.item-detail__content.isMinimized {
  position: sticky;
  z-index: 999999;
  box-shadow: 0px 2px 10px -2px rgba(182, 182, 182, 0.77);
  background-color: #fff;
  opacity: 1;
  max-height: 115px;
  top: 86px;
  transition: all 0.5s;
}

.item-detail__content .item-detail__image-wrapper {
  max-width: 100px;
  max-height: 100px;
  margin-right: 10px;
  width: unset;
}

.item-detail__content.isMinimized .item-detail__description--expanded {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: unset;
}

.item-detail__content.isMinimized .item__description--read-more {
  font-size: 0.9rem;
}

.item-detail__content.isMinimized.isExpanded .item-detail__price-calories {
  margin-top: 10px;
  margin-bottom: 0;
}

.item-detail__content.isMinimized.isExpanded {
  max-height: unset;
}

.item-detail__content.isMinimized.isExpanded .item__description--read-more {
  position: unset;
  margin-bottom: 10px;
}

.item-detail__content.isMinimized.isCollapsed {
  min-height: 107px;
  max-height: 210px;
  overflow: hidden;
  /*transition: max-height 0.5s;*/
}

.item-detail__content.isMinimized + .item-detail__options-container {
  padding-top: 100px;
}

.item-detail__content.isMaximized {
  position: sticky;
  max-height: 2000px;
  /*top: 0;
  z-index: 99999;*/
  box-shadow: 0px 2px 10px -2px rgb(182 182 182 / 77%);
  /*transition: max-height 0.5s;*/
}

.quantity-special-instruction__container {
  width: 100%;
}

.animate-scale-down {
  width: 0 !important;
  margin: 0 !important;
  transition: width 0.5s, margin 0.5s;
}

.item__description--read-less {
  margin-top: 10px;
  margin-bottom: 10px;
}

.item_details__name-price-wrapper {
  flex-grow: 1;
}

.item-detail__description__wrapper {
  margin-top: 10px;
  align-items: flex-end;
}

.item_details__name-price-container {
  flex-grow: 1;
}

.item-detail__image-wrapper.wizard {
  height: 0;
  padding-top: 30%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.item-detail__image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
}

/** Mobile Specific Style **/
@media screen and (max-width: 660px) {
  .item-detail__option-text-container {
    text-align: right;
  }

  .item-detail__content.isMinimized {
    top: 0;
    width: 100%;
  }

  .addons-modifiers-form .form__field-label--checkbox-radio.no-thankyou-option::after {
    right: 8px;
    top: 8px;
  }
  .suggested-item-card{
    width: calc(100% / 2 - 1em) !important;
    max-width: unset !important;
  }
  .suggested-item-order-level-card{
    width: 98%  !important;
    max-width: auto;
  }
}

/** Desktop Design **/
@media screen and (min-width: 660px) {
  .item-details-main__Wrapper {
    max-width: 1200px;
    border-radius: 0.5em;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .menu-item-detail-wizard {
    padding-bottom: 0;
  }
  .item_detail__top-content.wizard {
    padding-top: 0;
  }
  .menu-item_main-container {
    position: fixed;
    z-index: 1005;
    width: 100%;
    height: 100%;
    background-color: #0000007a;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-bottom: 0;
  }

  .menu-combo-item_main-container {
    z-index: 1004;
  }

  .item-details-main__Wrapper {
    height: 99%;
    border-radius: 12px;
    max-width: 1200px;
    background-color: #fff;
    border: 2px solid #fff;
    margin-left: 24px;
    margin-right: 24px;
    width: 100%;
  }

  .item-detail__content.isMinimized {
    top: 40px;
  }

  .item-detail__content.isMaximized {
    top: unset;
    box-shadow: 0px 2px 4px -2px rgb(182 182 182 / 77%);
  }

  .order-item__options-form {
    padding: 0px 24px 24px;
  }

  .form__fieldset-addonModifier {
    padding: 30px 0;
  }

  .item-details-main__Wrapper::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .item-detail__description.wizard {
    max-width: unset;
  }

  .item-detail__content.isMinimized {
    box-shadow: 0px 7px 10px -6px rgb(182 182 182 / 77%);
  }
  .item-detail__price-calories .item-detail__content.isMinimized.isCollapsed {
    width: 100%;
    max-height: 100px;
    position: sticky;
    top: 40px;
  }

  .item-detail__content.isMinimized.isExpanded .item-detail__price-calories {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .item-detail__content.isMinimized .item-detail__image-wrapper,
  .item-detail__content.isMaximized .item-detail__image-wrapper {
    margin-bottom: 12px;
  }

  .item-detail__options-container {
    background-color: #fff;
  }
  .order-item__option-header {
    background-color: #eee;
  }

  .desktop-next-group-button__container {
    position: sticky;
    bottom: 0;
  }

  .desktop-next-group-button {
    padding: 1em 0;
    background-color: var(--color-online-order-settings-background);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .desktop-next-group-button__container .FAB-wrapper {
    position: unset;
  }

  .desktop-next-group-button__container .save-changes-FAB > .desktop-container {
    justify-content: center;
  }

  .item-detail__content.isMinimized.isExpanded {
    width: 100%;
    max-height: unset;
    position: sticky;
    top: 40px;
  }

  /*.item-detail__content.isMinimized + .item-detail__options-container {
    margin-top: 100px;
  }*/

  .item-details__desktop-close-button-container {
    min-height: 40px;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 999999;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background-color: #fff;
  }

  .form__fieldset.form__fieldset--top-level.no-image-group
    div.form__field-wrapper--checkbox-radio__background
    > div
    > label.regular-option,
  .form__fieldset.form__fieldset--top-level.no-image-group
    div.form__field-wrapper--checkbox-radio__background
    > div
    > label.no-thankyou-option {
    align-items: center;
  }
  .item-detail__options-container {
    padding-bottom: 100%;
  }

  .item-detail__content.isMinimized.isCollapsed {
    max-height: 210px;
  }

  .item-detail__content .item-detail__image-wrapper {
    max-width: 100px;
    max-height: 100%;
    width: 40%;
    margin-bottom: 0 !important;
  }

  .item-detail__image-container {
    align-items: flex-start;
  }

  .item-detail__image-wrapper.wizard {
    height: 100px !important;
    padding-top: 10vh !important;
    width: 100% !important;
    position: relative !important;
    overflow: hidden !important;
  }

  .item-detail__image {
    top: 0;
    left: 0;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.combo-menu-item-summary > span::before {
  content: "- ";
}

.combo-nested-modifier-summary {
  padding-left: 10px;
}
.combo-nested-modifier-summary > li > span::before {
  content: "---- ";
  color: #ffffff00;
}

.combo-modifier-summary,
.combo-addon-summary {
  color: var(--color-gray-text);
  font-size: 12px;
  color: #676464;
}
.combo-addon-summary-price {
  float: right;
  font-size: 13px;
  color: var(--color-gray-text);
}
.combo-addon-summary-price::before {
  content: "+" !important;
  color: var(--color-gray-text) !important;
}

.menu-combo-summary-details {
  padding: 0;
  margin-bottom: 0;
}

.menu-combo-summary-details li p {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 1.6;
  margin: 0;
  align-items: center;
}

.menu-combo-summary-details__list-item {
  display: flex;
  border: 1px solid var(--color-gray-light);
  flex-direction: column;
  border-radius: 8px;
}

.combo-summary__edit-button-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: var(--color-gray-light);
  padding: 0.5em 1em;
  border-radius: 7px 7px 0 0;
}

.menu-combo-summary-details__list-item > .combo-summary__item-detail-container:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-light);
}

.combo-summary__item-detail-container {
  padding: 1em;
}

.menu-combo-summary-details > .menu-combo-summary-details__list-item:not(:last-child) {
  margin-bottom: 10px;
}

.menu-combo-summary-details__list-item-group-id {
  font-weight: 500;
  font-size: 13px;
}

.menu-combo-summary-details__list-item-edit-button {
  color: var(--color-secondary);
  font-size: 13px;
  display: flex;
  align-items: center;
}

.menu-combo-summary-details__list-item-edit-button > .icon--edit {
  color: var(--color-secondary);
}

.menu-combo-summary-details__list-item-name {
  font-weight: 500;
  color: #2a2a2a;
  font-size: 15px;
}

.menu-combo-summary-details__list-item-name > span {
  margin-right: 10px;
}

.menu-combo-summary > p.menu-combo-summary__total-price-disclaimer {
  margin-left: 0;
  margin-top: 1.5em;
}

li > p.menu-combo-summary__total-price-disclaimer {
  font-size: 14px;
  text-align: left;
  color: #969494;
  font-weight: 600;
}

.menu-combo-summary-wrapper {
  padding-bottom: 100px;
}

.menu-combo-summary-details__list-item__addonModifiers {
  list-style-type: none;
  padding: 0;
}

.menu-combo-summary-details__list-item__addonModifiers > li {
  border: none;
  text-align: left;
}

.menu-combo-summary-details__list-item__addonModifiers ul {
  list-style-type: none;
}

.menu-combo-summary-details__list-item__addonModifiers span > span {
  margin-right: 10px;
}

.combo-item-detail__option-button {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.combo-item-detail__option-button > .icon--check {
  stroke: #fff;
  stroke-width: 3px;
  width: 18px;
  height: 18px;
  padding: 3px;
  background-color: #4caf52;
  border-radius: 100%;
}

.menu__category-card.chosen-combo {
  border: 2px solid #4caf52;
}

.progress-bar-container {
  padding: 0;
}

:root {
  --color-white: #fff;
  --color-black: #333;
  --color-gray: #75787b;
  --color-gray-light: #ebebeb;
  --color-gray-disabled: #e8e8e8;
  --color-blue: #0086ff;
  --color-blue-dark: #383;
  --font-size-small: 0.75rem;
  --font-size-default: 0.875rem;
  --color-gray-text: #4e4e4e;
}

/*
.progress-bar .is-active:before {
  border-color: var(--color-secondary);
}
.progress-bar li:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--color-gray-disabled);
  border-radius: 50%;
  border: 2px solid var(--color-white);
  position: absolute;
  bottom: -7px;
  z-index: 3;
  transition: all 0.2s ease-in-out;
}

.progress-bar li:last-child:before {
  left: auto;
}

.progress-bar .is-active:before {
  background-color: var(--color-white);
  border-color: var(--color-secondary);
}

.progress-bar .is-complete:before {
  background-color: var(--color-secondary) !important;
}
*/

.progress-bar {
  overflow: auto;
  width: 100%;
  white-space: nowrap;
  height: 100%;
  padding: 10px 0 10px 24px;
  margin: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.progress-bar::-webkit-scrollbar {
  display: none;
}

.progress-bar li {
  position: relative;
  font-size: 0.875rem;
  font-size: var(--font-size-default);
  line-height: 1.5;
  color: var(--color-secondary);
  font-weight: 600;
  white-space: nowrap;
  overflow: visible;
  min-width: 55px;
  text-align: left;
  display: inline-block;
  clear: both;
  margin-bottom: 2px;
}

.progress-bar li:last-child {
  border-bottom: none;
}

.progress-bar button {
  font-weight: 600;
  transition: opacity 0.3s ease-in-out;
  background-color: #fff;
  border-radius: 50%;
  padding: 6px 10px;
  border: 1px solid #ebebeb;
  border: 1px solid var(--color-gray-light);
}

.progress-bar .is-active > button {
  border: 2px solid var(--color-primary);
  background-color: #fff;
  padding: 7px 11px;
}

.progress-bar li:after {
  content: "";
  display: block;
  width: 100%;
  position: absolute;
  bottom: calc(50% - 2px);
  left: 0;
  z-index: -1;
  border-bottom: 2px solid #ebebeb;
  border-bottom: 2px solid var(--color-gray-light);
}

.progress-bar .is-complete:after {
  border-bottom: 2px solid var(--color-primary);
}

.progress-bar .is-complete button {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.progress-bar .is-active:hover {
  cursor: pointer;
}

.progress-bar .is-complete:last-child:after,
.progress-bar .is-active:last-child:after {
  width: 0;
}

.progress-bar li.is-summary > button {
  border: none;
}

.progress-bar li.is-active.summary > button:not(:disabled) {
  background-color: unset;
  color: var(--color-secondary);
}

.progress-bar-container {
  position: sticky;
  z-index: 11;
  top: 53px;
  background-color: #fff;
}

.progress-bar-item-disabled {
  opacity: 0.2;
}

.progress-bar-container_bottom_border {
  display: none;
}

@media screen and (min-width: 660px) {
  .progress-bar-container {
    position: unset;
    margin: 0 auto 0 0;
  }

  .progress-bar {
    padding-inline-start: 0;
  }

  .progress-bar li {
    min-width: 110px;
  }

  .progress-bar li:last-child::after {
    display: none;
  }

  .progress-bar-container_bottom_border {
    display: block;
    margin: 1em 0;
    width: 100%;
    border: 1px solid #ebebeb;
    border: 1px solid var(--color-gray-light);
  }
}

/* MenuSlider.css */
.menu-slider-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 1em 0;
  background-color: #fff;
  z-index: 10;
  animation: growMenuSlider 0.35s forwards;
}

@keyframes growMenuSlider {
  from {
    transform: scale(0.1);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

.menu-slider-container--closing {
  animation: closeMenuSlider 0.35s forwards;
}

@keyframes closeMenuSlider {
  from {
    transform: none;
    opacity: 1;
  }
  to {
    transform: scale(0.1);
    opacity: 0;
  }
}

.menu-slider-container .additional-disclaimer {
  margin-top: 0;
  padding-left: 24px;
  padding-right: 24px;
}

/* Food Menu Items Slider Header */
.menu-slider__header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.menu-slider__heading {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
}

.menu-slider__link-back {
  position: absolute;
  left: 30px;
  top: 3px;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}
/* END OF Food Menu Items Slider Header */

.menu-slider {
  position: relative;
  display: flex;
  margin: 0 0 0 -3%;
  padding: 20px 0;
  list-style-type: none;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.menu-slider::-webkit-scrollbar {
  display: none;
}

body.body--iOS .menu-slider {
  /* enables iOS "momentum" style scrolling */
  -webkit-overflow-scrolling: touch;
}

@supports (-webkit-touch-callout: none) {
  .menu-slider {
    height: 100%;
  }
}

.menu-slider__item-spacing {
  width: 1px;
  height: 1px;
  margin-left: 7.5%;
  margin-bottom: 1.5em;
}

.menu-slider-container .calories-disclaimer {
  padding-left: 24px;
  padding-right: 24px;
}

@media screen and (max-width: 350px) {
  .header-secondary__dropdowns {
    max-width: 100%;
  }
}

.menu-slider__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  width: 75vw;
  max-width: 325px;
  height: 65vh;
  margin-left: 3%;
  margin-right: 3%;
  padding-bottom: 1em;
  scroll-snap-align: center;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 20%);
  transition: transform 0.3s cubic-bezier(0.19, 1.24, 0.26, 1);
}

.menu-slider__item--in-view {
  transform: scale(1.1) translateY(6px);
}

.menu-slider__item-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-slider__item-image-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 103.5%;
  margin-bottom: 1em;
}

.menu-slider__item-image--loaded .menu-slider__item-image {
  opacity: 1;
}

.menu-slider__item-image {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 0.35s;
  border-top-left-radius: 0.4em;
  border-top-right-radius: 0.4em;
}

.menu-slider__item-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  margin: 0;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  text-align: center;
}

.menu-slider__item-price {
  margin-bottom: 5px;
  color: #3f3f3f;
  font-size: 1rem;
  font-weight: 600;
}

.menu-slider__item-calories {
  min-height: 16px;
  font-size: 0.75rem;
}

.menu-slider__item-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.menu-slider__image-underlay {
  position: relative;
  left: 0;
  top: 0;
  max-height: 100%;
}

.menu-slider__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
}

.menu-combo-summary {
  padding: 10px 7vw;
  border-top: 1px solid var(--color-gray-light);
}

.menu-combo-detail {
  opacity: 0;
  transform: scale(0);
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* enables iOS "momentum" style scrolling */
  z-index: 1000;
  animation: scaleUp 0.3s forwards;
  background-color: #fff;
  padding-bottom: 5em;
}

.menu-combo-detail--closing {
  animation: scaleDown 0.3s forwards;
}

.menu-combo-name {
  line-height: 120%;
}

/** Summary Section **/
.menu-combo-summary .menu-combo-summary__image-wrapper {
  border-radius: 4px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.menu-combo-summary__image-wrapper > img {
  max-width: 50% !important;
}

.menu-combo-summary-details__name {
  font-weight: bold;
  font-size: 20px;
}

.menu-combo-summary-details__price {
  font-weight: 500;
  color: var(--color-gray-text);
  padding: 3px 0;
}

.combo-menu-subgroup-item-price-calorie {
  float: right;
}

.combo-menu-subgroup-item-summary {
  margin: 15px 0;
}

.combo-menu-subgroup-item-price-calorie {
  font-size: 13px;
  color: var(--color-gray-text);
}

/** End Of Summary Section **/

.menu-combo-item-detail {
  opacity: 0;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /*overflow-y: auto;*/
  -webkit-overflow-scrolling: touch;
  /* enables iOS "momentum" style scrolling */
  z-index: 1000;
  animation: scaleUp 0.3s forwards;
  background-color: white;
  padding-bottom: 300%;
}

.menu-combo-item-detail--skip-animation {
  opacity: 1;
  transform: scale(1);
  animation: none;
}

.menu-combo-item-detail--closing {
  animation: scaleDown 0.3s forwards;
}

.menu-combo-back-button {
  margin-left: 10px;
  font-size: 14px;
}

.menu-combo-slider-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  /*padding: 28px 0 calc(57px + 24px);*/
  padding: 0 0 24px;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 10;
  animation: growMenuSlider 0.35s forwards;
}

.menu-combo-slider-container button.menu-category-card__button:disabled {
  background-color: transparent;
}

.menu-combo-slider-container > p,
.menu-combo-summary > p {
  font-size: 12px;
  text-align: left;
  margin-left: 42px;
  color: #969494;
  font-weight: 600;
}

.menu-combo-slider-container > p {
  max-width: 90vw;
  color: #2a2a2a;
  margin: 0;
  font-size: 15px;
}

.menu-combo-slider-container .calories-disclaimer {
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  color: #969494 !important;
  padding-left: 0px !important;
}

.menu-combo-summary .calories-disclaimer {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #969494 !important;
  padding-left: 0px !important;
}

.menu-combo-slider {
  min-height: 487px;
  overflow: auto !important;
}
.item-detail__top-content-combo {
  z-index: 90;
}

.item-details__transition-container__fav-button {
  visibility: hidden;
  display: none;
}

#top-hidden-item-name-detail.item-detail__top-content-combo {
  z-index: 100;
  justify-content: flex-start;
  padding-left: 16px;
}

#top-hidden-item-name-detail.item-detail__top-content-combo .icon--arrow-left {
  width: 18px;
  height: 18px;
  margin-top: 3px;
}

.item-detail__top-content-combo > .item-detail__button--close > .icon--x {
  margin-top: 6px;
}

.menu-combo-item-detail-header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.next-group-button-combo {
  background-color: var(--color-online-order-settings-background);
  position: sticky;
  bottom: 0;
  width: 100%;
  padding-bottom: 16px;
  padding-top: 16px;
  min-height: 86px;
}

.next-group-button-combo > div {
  display: flex;
  align-items: center;
}

.heading-with-icon.combo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-content: center;
}

.quantity-combo-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: 100%;
  flex-wrap: wrap;
  max-width: 60%;
  margin: 0;
}

.quantity-combo-details span:first-child {
  color: var(--color-error);
}

.quantity-combo-details span:last-child {
  color: var(--color-gray);
  font-size: 13px;
  text-align: right;
}

.quantity-combo-details span.is-complete {
  color: #4caf52;
}

.combo-name-and-disclaimer-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-weight: 700;
  margin: 0;
}

.combo-name-and-disclaimer-text-menu-item-name {
  font-size: 1em;
  font-weight: 600;
}

.combo-name-and-disclaimer-text > .menu-combo-summary__total-price-disclaimer {
  font-size: 13px;
  font-weight: 400;
  margin-top: 5px;
}

.main-combo-header {
  position: sticky;
  top: 0;
  z-index: 12;
}

.main-combo-header .page-title {
  /*font-size: 3vh;*/
}

.order-item__option-header.combo-subgroup-header {
  position: sticky;
  top: 109px;
  z-index: 11;
}

.item-detail__content.isMinimized.combo {
  top: 54px;
}

.desktop-order-menu__combo-item-check-mark {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}

.desktop-order-menu__combo-item-check-mark > .icon--check {
  stroke: #fff;
  stroke-width: 3px;
  width: 18px;
  height: 18px;
  padding: 3px;
  background-color: #4caf52;
  border-radius: 100%;
}

.combo-category-filters__wrapper {
  margin: 0 auto 0 0;
}

.menu-combo-loader-overlay {
  background-color: transparent;
  width: 100%;
  height: 500%;
  position: absolute;
  z-index: 1000;
}

/** Mobile Specific Design **/
@media screen and (max-width: 660px) {
  .menu-combo-slider-container {
    padding-left: 24px;
    padding-right: 24px;
  }
}

/** Desktop Design **/
@media screen and (min-width: 660px) {
  .menu-combo-detail {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-content: center;
    height: 99%;
  }
  .order-item__option-header.combo-subgroup-header {
    position: unset;
  }
  .desktop-menu__menu-combo-summary {
    display: flex;
    align-items: flex-start;
    align-content: center;
    flex-direction: row;
  }

  .menu-combo-detail .order-item__option-header {
    min-height: 70px;
  }

  .desktop-menu__menu-combo-summary .item-detail__image-wrapper {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }

  .desktop-menu__menu-combo-summary > div {
    flex: 1 1;
  }

  .desktop-menu__menu-combo-summary > ul {
    flex: 2 1;
  }

  .menu-combo-summary-wrapper > .special-instructions-wrapper .special-instructions__field {
    width: 100%;
  }

  .menu-combo-summary {
    padding: 0;
    padding-top: 20px;
  }

  .menu-combo-summary__image-wrapper > img {
    max-width: 70% !important;
  }

  .menu-combo-slider-container {
    z-index: unset;
    padding-bottom: 1em;
  }

  .menu-combo-detail {
    animation: none;
    -webkit-animation: none;
    transform: scale(1);
    opacity: 1;
    overflow-y: unset;
    z-index: unset;
    padding-top: 1.5em;
    padding-right: 24px;
    padding-left: 24px;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }

  .menu-combo-item-detail {
    border-bottom-left-radius: 0.6em;
    border-bottom-right-radius: 0.6em;
  }

  .menu-combo-item-detail-header .curved-shadow-overlay-hack,
  .menu-combo-item-detail-header {
    border-top-left-radius: 0.6em;
    border-top-right-radius: 0.6em;
  }

  .menu-combo-item-detail-header .curved-shadow-overlay-hack {
    background-color: #fff;
  }

  .menu-combo-item-detail-header .header-primary {
    color: #2a2a2a;
  }

  .menu-combo-item-detail-header .header-primary svg {
    color: #2a2a2a;
    fill: #2a2a2a;
  }

  .menu-combo-item-detail {
    padding-bottom: 0;
  }

  .menu-combo-item-detail > .item-detail__content.isMinimized.isCollapsed {
    top: 0;
  }

  .menu-combo-item-detail > .item-detail__content.isMaximized {
    top: 0;
  }

  .menu-item_main-container.menu-combo-item_main-container {
    position: relative;
    display: unset;
    background-color: #fff;
  }

  .menu-combo-detail.menu-item-detail-wizard {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .menu-item_main-container.menu-combo-item_main-container.menu-combo-item-details_main-container {
    display: flex;
    position: fixed;
  }

  .order-item__option-header.combo-subgroup-header {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .order-item__option-header.combo-subgroup-header > .heading-with-icon.combo {
    justify-content: space-between;
  }

  .menu-combo-item_main-container.menu-combo-item-details_main-container {
    background-color: #0000007a;
  }

  .menu-combo-item-detail .FAB-wrapper__combo-add-to-choice {
    border-bottom-left-radius: 0.6em;
    border-bottom-right-radius: 0.6em;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .menu-combo-detail .menu-combo-item-detail::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .menu-combo-detail .menu-combo-item-detail {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .desktop-order-menu__combo-item-check-mark {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
  }

  .desktop-order-menu__combo-item-check-mark > .icon--check {
    stroke: #fff;
    stroke-width: 3px;
    width: 18px;
    height: 18px;
    padding: 3px;
    background-color: #4caf52;
    border-radius: 100%;
  }

  .quantity-combo-details {
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    max-width: unset;
  }
  .menu-combo-summary {
    border-top: none;
  }

  .FAB--combo-confirm-choices-wrapper {
    bottom: 0;
  }

  .menu-combo-name {
    display: flex;
    flex-direction: column;
  }

  .menu-combo-name > p:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .menu-combo-summary-wrapper {
    width: 100%;
  }
  .menu-combo-item-details_main-container {
    z-index: 1005;
  }
}

.collapsable-section__header-container {
  padding: 0px 30px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-light);
  width: 100%;
  background-color: var(--color-gray-light);
}

.collapsable-section__header-container > .icon--minus,
.collapsable-section__header-container > .icon--plus {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.collapsable-section__header-container>h2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 0.9em 0;
  font-size: 1rem;
}

.collapsable-section__header-container > h4 > span {
  font-size: 0.85em;
}

.collapsable-section__collapsed-content {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: max-height 0.2s ease-out;
}

.collapsable-section__expanded-content {
  /*transition: max-height 0.2s ease-out;*/
  overflow: scroll;
  /*max-height: 100%;*/
}

.collapsable-section__expanded-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.collapsable-section__expanded-content {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.collapsable-section__white-border-bottom {
  border-bottom: 2px solid #fff;
}

.cart-items-collapsable__content-buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 0 30px 10px 30px;
  width: 100%;
  background-color: var(--color-gray-light);
}

.cart-items-collapsable__content-buttons-container > button,
.cart-items-collapsable__content-buttons-container > a {
  color: var(--color-secondary);
  font-size: 0.8em;
  font-weight: 600;
}

@media screen and (min-width: 850px) {
  .cart-items-desktop {
    width: 100%;
  }
}

/* Cart.css */
.cart-content {
  background-color: var(--color-gray-light);
  /*padding-left: 5%;
  padding-right: 5%;*/
}

/* Cart Items */
.cartItem__container {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.cartItem__container + .link-wrapper--add-items {
  margin-top: 24px;
}

.cart-items-desktop .additional-disclaimer {
  padding-left: 24px;
  padding-right: 24px;
}

.item-detail__options-container .additional-disclaimer {
  padding-left: 0;
  padding-right: 0;
}

.cartItem {
  margin: 0 auto 4px;
  border-radius: 4px;
  text-align: left;
  position: relative;
  background-color: #fff;
}

.cartItem:last-child {
  margin-bottom: 0;
}

.cartItem__discounts-wrapper {
  width: 100%;
  margin-top: 10px;
}

.cartItem__discount-line {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.cartItem__discount-value,
.cartItem__discount-label {
  color: var(--color-secondary);
}

.cartItem__discount-value {
  display: flex;
  align-items: baseline;
}

.cartItem__discount-value > span:first-child {
  display: flex;
  align-items: center;
}

.cartItem__discount-value-points:after {
  content: "|";
  margin: 0 3px;
}

.cartItem__discount-value > span > .icon--star {
  width: 20px;
  height: 20px;
  fill: #f49c00;
  stroke: none;
}

.cartItem__discount-label {
  display: flex;
  align-items: center;
}

.cartItem__discount-label > .icon--x-in-circle {
  width: 18px;
  height: 18px;
  color: var(--color-secondary);
  margin-right: 5px;
}

/* Cart item transition animations */
.cartItem--animate-remove {
  transition: opacity 0.75s, transform 1s, max-height 1s, margin 1s;
  transform: scale(0);
  opacity: 0;
  max-height: 0;
  margin-bottom: 0;
}

.cartItem--animate-add {
  animation: 0.75s addItem forwards;
}

@keyframes addItem {
  from {
    transform: scale(0);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0;
  }

  to {
    transform: none;
    transform: initial;
    opacity: 1;
    max-height: 600px;
    margin-bottom: 25px;
  }
}

.cartItem__wrapper {
  padding: 20px 30px 13px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.cartItem__infoWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.cart-item__name-price-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
}

.cartItem__heading {
  width: 70%;
  margin: 0 5% 0 0;
  font-size: 14px;
  font-weight: 600;
}

.orderLevelSuggestedItemsPriceWrapper .cartItem__heading{
  font-size: 0.9rem !important;
}
.orderLevelSuggestedItemsPriceWrapper .cartItem_keyDetails{
  font-size: 0.75rem !important;
}
.cartItem__price {
  display: block;
  width: 25%;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
}

.cartItem__modifiers {
  width: 100%;
  padding: 0;
  list-style: none;
  font-size: 13px;
  color: #676464;
  transition: max-height 0.35s;
}

.cart-item__option {
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
}

.cart-item__option-name {
  max-width: 205px;
  margin-right: 5%;
  margin-top: 0;
  margin-bottom: 0;
}

.cart-item__option-name > strong {
  margin-right: 5px;
}

.cart-combo-child-item__name,
.cart-combo-child-item__price {
  color: #2a2a2a;
  font-weight: 500;
}

.cart-combo-child-item__option-name::before {
  content: "- ";
  color: #ffffff00;
}

.cart-combo-child-item__nested-option-name::before {
  content: "---- ";
  color: #ffffff00;
}

.cart-combo-child-item__option-name {
  font-size: 12px;
}

.cart-item__special-instructions {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 19px;
  margin-top: 4px;
  font-size: 12px;
  font-style: italic;
  width: 100%;
  transition: max-height 0.35s;
}

.cart-item__special-instructions--expanded {
  max-height: 500px;
  white-space: unset;
  word-break: break-word;
}

.cartItem__modifier {
  display: inline;
}

.cartItem__image {
  max-width: 100px;
  margin: 0 5% 0 0;
  -o-object-fit: cover;
     object-fit: cover;
}

.cartItem__button--delete,
.cartItem__button--add,
.cartItem__button--edit {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.cartItem__button-text {
  line-height: 14px;
}

.cartItem__icon {
  margin-right: 3px;
}

.cartItem__icon--delete {
  width: 18px;
  height: 18px;
  fill: #2d2d2d;
}

.cartItem__button--add .icon--plus {
  width: 14px;
  height: 14px;
  margin-right: 3px;
  color: #2d2d2d;
}

.cartItem__icon--edit {
  width: 14px;
  height: 14px;
  color: #2d2d2d;
}

/* Cart Item Buttons */
.cartItem__buttons-container {
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  display: flex;
  margin-top: 10px;
  border-top: 1px solid #dfdfdf;
  padding-top: 10px;
  position: relative;
}

/* Cart Footer */
.cart-footer {
  background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 100%);
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.icon__circle-wrapper {
  background-color: rgba(255, 255, 255, 0.4);
  width: 35px;
  height: 35px;
  border-radius: 100%;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.icon--arrow-full-right {
  width: 18px;
  height: 18px;
  stroke: #2a2a2a;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.link-wrapper--add-items {
  text-align: center;
}

.link--add-items {
  display: inline-flex;
  align-items: center;
}

.link--add-items > .icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.empty-cart-message,
.cart__1307-error {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  margin: 0 0 30px;
  font-size: 18px;
  text-align: center;
}

.customer-info__form-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  width: 100%;
  height: auto;
}

.customer-info__form-heading {
  margin: 0 0 35px;
  font-size: 22px;
  font-weight: normal;
  line-height: 1.2;
}

.cart-button__remove-all-items {
  display: flex;
  align-items: center;
  border: solid 1px;
  padding: 10px 15px;
  font-size: 14px;
}

.cart-button__remove-all-items-icon {
  transform: rotate(45deg);
  height: 16px;
  width: 16px;
  margin-right: 4px;
}

.cart-content__buttons {
  display: flex;
  align-items: center;
  margin-top: 30px;
  flex-wrap: wrap;
  padding: 20px 30px 13px;
}

.cart-content__buttons-single-button {
  justify-content: center;
}

.cart-content__buttons-double-buttons {
  justify-content: center;
}

.cart-content__buttons-double-buttons > button {
  flex: 1 1;
  flex-grow: 1;
  width: auto;
  min-width: 165px;
  min-height: 44px;
  max-width: 250px;
  margin: 5px;
  padding: 0.5em 1em;
  white-space: normal;
}

.cart-content__buttons-double-buttons > .button--primary-small:first-child {
  margin-right: 5px;
}

.customer-info__form-wrapper .form__field-label {
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 0.9rem;
  font-weight: 600;
  text-align: left;
  position: static;
  position: initial;
}

.customer-info__form-wrapper .form__field {
  border-radius: 0;
  width: 100%;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #cdcdcd;
}

.customer-info__modal {
  background-color: white;
  z-index: 1005;
}

.order-type-special-instructions__field {
  border: 1px solid #cdcdcd;
  width: 100%;
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 14px;
  color: gray;
  margin-top: 20px;
}

.order-type-special-instructions__additional-information {
  font-size: 12px;
  color: #5d5d5d;
  margin: 0;
  text-align: left;
}

.cartItem__wrapper > div:first-child {
  width: 100%;
}

/** cart item quantity buttons **/
/** quantity buttons **/
.cartItem__quantity-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cartItem__quantity-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartItem__quantity-button:active > .icon {
  background-color: #2a2a2a;
  color: white;
}

.cartItem__quantity-button:disabled {
  background-color: transparent;
}

.cartItem__quantity-button:disabled > .icon {
  opacity: 0.5;
}

.cartItem__quantity-button > .icon {
  width: 2em;
  height: 2em;
  padding: 5px;
  stroke-width: 1.5px;
  background-color: white;
  border-radius: 50%;
  border: solid 1.5px #2a2a2a;
  color: #2a2a2a;
}

.cartItem__quantity-input {
  background-color: transparent;
  border: none;
  border-bottom: solid 2px var(--color-gray-light);
  border-radius: 0;
  text-align: center;
  padding-bottom: 5px;
  width: 40%;
  margin-bottom: 15px;
  margin-right: 5px;
  margin-left: 5px;
}

/** end of quantity buttons **/

.cart-content__desktop-min-delivery-amount-message {
  width: 100%;
  position: fixed;
  color: #ff2020;
  display: flex;
  justify-content: center;
  bottom: 2em;
  padding: 20px 30px 13px;
  text-align: center;
  z-index: 1100;
}
.deliverySpendAmountErrorMessage.cart-content__desktop-min-delivery-amount-message {
 position: unset !important;
 background-color: white;
 z-index: unset;
}
.cart-content__desktop-min-delivery-amount-message > p {
  margin: 0;
}

/** Mobile layout only **/
@media screen and (max-width: 660px) {
  .special-instructions-wrapper.order-level__special-instructions {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 2em;
  }

  .cart-content
    + .order-settings-component__main-container.visually-hidden
    + .order-settings-component__time-selection-main-container
    > .order-settings-component__time-selection-inner-wrapper {
    bottom: 0;
  }

  .cart-content > .cart-items-desktop.collapsable-section__collapsed-content {
    padding-bottom: 0;
  }

  .checkout-content > .cart-items-desktop {
    padding-bottom: 0;
  }

  .checkout-content > .cart-content-desktop {
    margin-bottom: 20em;
  }
}

.cart-content__desktop-checkout-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  position: fixed;
  bottom: 0;

  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
}

.cart-content__mobile-checkout-buttons-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.cart-content__mobile-checkout-buttons-wrapper > button:first-child {
  margin-top: 10px;
}

.cart-content__mobile-checkout-buttons-wrapper > button {
  margin-bottom: 10px;
}
.cart-content__mobile-checkout-buttons-wrapper > button:last-child {
  margin-bottom: 20px;
}

.desktop-cart__link-and-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.cart-content__mobile-checkout-buttons-wrapper > .FAB__error {
  bottom: 105px;
}

.desktop-cart__link-and-button-wrapper.single-button {
  margin-bottom: 20px;
}

.checkout-content {
  padding-bottom: 0;
}

.cart-items-desktop.collapsable-section__expanded-content {
  overflow: visible;
}

.cartItem__quantity-span {
  font-size: 12px;
  margin-right: 10px;
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .cartItem__quantity-span {
    font-size: 0.9em;
    margin-right: 10px;
  }
  .customer-info__form-wrapper {
    width: 70%;
  }
  .customer-info__form-heading {
    text-align: center;
    font-size: 24px;
  }

  .cart-checkout__desktop-container {
    display: flex;
    justify-content: space-between;
    padding-top: 1em;
    background-color: #f9f9f9;
  }

  .cart-checkout__desktop-container > div {
    width: 49%;
  }

  .cart-checkout__desktop-container .cart-summary-tip-desktop {
    width: 100%;
  }

  .cart-checkout__desktop-container .cart-items-desktop {
    width: 100%;
  }

  .cart-checkout__desktop-container .cart-content-desktop {
    margin-top: 0;
  }
  .cart-checkout__desktop-container {
    background-color: #f9f9f9;
    padding-right: 24px;
    padding-left: 24px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .cart__tip-amounts,
  .cart__tip-custom-amount-container {
    padding-left: 0;
    padding-right: 0;
  }
  .cart__tip-amounts > .cart__tip-button:first-child {
    padding-left: 10px;
  }
  /* .cart__tip-amounts > .cart__tip-button:last-child {
    padding-right: 0;
  } */

  .cart__tip-container {
    padding-top: 0;
    padding-bottom: 2em;
  }

  .cart-checkout__desktop-container .cart-content-desktop {
    padding-right: 0;
  }

  .cart-content {
    background-color: #f9f9f9;
  }

  .cart-checkout__cart-items-collapsable__cart_indicator {
    display: flex;
    align-items: center;
  }

  .cart-checkout__cart-items-collapsable__cart_indicator > span {
    font-weight: 500;
    font-size: 14px;
  }

  .cart-checkout__cart-items-collapsable__cart_indicator > .cart-indicator > .icon--cart {
    width: 30px;
  }

  .cart-items-desktop-dinein {
    padding-right: 24px;
    padding-left: 24px;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
  }

  .confirmation-email__main-wrapper + .cart-items-desktop-dinein {
    margin-top: 0;
  }

  .cart-items-desktop-dinein .cart-items-desktop {
    width: unset;
  }

  .cart-items-desktop-dinein .cartItem__container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  .cart-items-desktop-dinein .cartItem__container > .cartItem {
    flex: 50% 1;
    max-width: 49%;
  }

  .cart-items-desktop-dinein .cartItem__container > .cartItem:nth-child(odd) {
    margin: 0 5px 5px 0;
  }
  .cart-items-desktop-dinein .cartItem__container > .cartItem:nth-child(even) {
    margin: 0 0 5px 0;
  }

  .cartItem__container {
    padding-bottom: 5em;
  }

  .cart-content__desktop-checkout-buttons
    > .desktop-cart__link-and-button-wrapper
    > button:first-child {
    margin-right: 10px;
  }

  .cart-items-desktop-dinein .cart-items-collapsable__content-buttons-container {
    padding: 0;
    max-width: 55%;
  }
}

@media screen and (min-width: 850px) {
  .cart-content__desktop-min-delivery-amount-message {
    bottom: 6em;
  }

  .cartItem {
    margin: 0 auto 5px;
  }

  .cartItem__wrapper {
    padding: 20px 20px 13px;
  }

  .cart-content-desktop {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    max-width: 1200px;
    /*margin: 2em auto 0;*/
  }

  .cart-summary-tip-desktop {
    position: sticky;
    position: -webkit-sticky;
    right: 5%;
    top: 1em;
    width: 47%;
  }

  .cart-items-desktop {
    width: 50%;
  }

  .cart-items-desktop-empty {
    margin: 0 auto;
  }

  .cart-content__desktop-checkout-buttons {
    padding-bottom: 2em;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    padding-top: 3em;
  }

  .desktop-cart__link-and-button-wrapper {
    display: flex;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }

  .desktop-cart__link-and-button-wrapper > a {
    margin-right: 1em;
  }

  .desktop-cart__link-and-button-wrapper > button:hover,
  .desktop-cart__link-and-button-wrapper > a:hover {
    opacity: 0.7;
  }

  .cart-content {
    padding-bottom: 10em;
  }

  .main-content {
    overflow: unset;
  }

  .cartItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cartItem__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cartItem__wrapper > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .cartItem__image-wrapper {
    margin-right: 1em;
    width: 30%;
  }

  .cartItem__image {
    width: 100px;
    height: 100px;
    margin: 0;
    border-radius: 4px;
  }

  .cartItem__heading,
  .cartItem__price,
  .cart-item__option-name {
    font-size: 0.9em;
  }

  .cartItem__heading {
    width: 50%;
  }

  .cartItem__price {
    width: 40%;
  }

  .cartItem__button-text {
    font-size: 1.2em;
  }

  .cartItem__icon--delete,
  .cartItem__button--add .icon--plus,
  .cartItem__icon--edit {
    width: 1.5em;
    height: 1.5em;
  }

  .cartItem__discount-line {
    font-size: 1em;
  }

  .cart-content__buttons-double-buttons > button {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(100% / 2 - 10px);
  }

  .desktop-cart__link-and-button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .desktop-cart__link-and-button-wrapper > button {
    max-width: 30%;
  }
}

@media screen and (min-width: 1280px) {
  .cart-items-desktop {
    margin: 0;
  }

  .cart-items-desktop-empty {
    margin: 0 auto;
  }
}

/** End Of Desktop Layout **/

.orderLevelSuggestedItemHeading{
  margin-top: 0;
  margin-bottom: 1.25em;
}
.suggestedItemOrderLevelGrid{
  padding: 0.5em 0.5em;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
}
.suggested-item-order-level-card{
  max-height: 100px;
  border-radius: 4px !important;
  border-bottom-right-radius: 15px !important;
}
.remove-suggested-item-card-border{
  border-bottom-right-radius: 4px !important;
}
.suggested-item-list-image{
  height: 100% !important;
  width: 92px !important;
  position: relative !important;
}

.suggestedMenuItemDetailsRow{
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}
.suggestedItemCardTextWrapper{
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.suggestedItemOrderLevelImageWrapper{
  height: 92px !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding: 0 !important;
  border-radius: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  max-width: 92px;
  min-width: 92px;
  overflow: hidden;
}
.orderLevelSuggestedItemsPriceWrapper{
  padding: 0.5em 0;
  padding-right: 0.25em;
  overflow: hidden;
}
.orderLevelSuggestedItemsPriceWrapper span{
  font-size: 1rem !important;
}
.orderLevelSuggestedItemsPriceWrapper .suggestedItems__button_wrapper{
  padding-right: 0;
}
.orderLevelSuggestedItemsPriceWrapper .suggestedItems__button_wrapper button{
 font-size: 0.8rem;
}
.orderLevelSuggestedItemsPriceWrapper .cartItem__heading.orderLevelSuggestedItemHeading{
  width: 70%;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
}
.orderLevelSuggestedItemsPriceWrapper .cartItem__heading{
  width: 92% !important;
  white-space: nowrap;
}
.orderLevelSuggestedItemsPriceWrapper .suggestedItems__button_wrapper button.suggested_items__view-details{
  min-width: 65%;
  text-align: start;
}
.suggestedOrderLevelItemCardButton{
  height: 100% !important;
}
.suggestedMenuItemQuickAddButtonContainer{
  display: flex;
  flex-direction: row-reverse;
  position: relative;
}
.suggested-items-order-level-wrapper {
  overflow-y: visible;
  overflow-x: visible;
  height: auto;
}
.order-suggested-items-list-container{
  max-height: 100%;
  padding: 0;
  margin: 0;
}
.suggested-items-order-level-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 660px) {
  .suggestedItemOrderLevelGrid{
    max-height: 100%;
  }
  .suggested-items-order-level-wrapper {
    overflow-y: visible !important;
    overflow-x: visible;
    height: 95%;
    padding-bottom: 20px;
  }
  .suggestedItemOrderLevelGrid{
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }
  .order-suggested-items-list-container{
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media screen and (max-width: 860px) and (min-width: 661px) {
  .suggestedItemOrderLevelGrid{
    max-height: 100%;
    flex-direction: row;
  }
 .suggestedItemOrderLevelGrid li.suggested-item-order-level-card{
  width: 98% !important;
  }
  .orderLevelSuggestedItemsPriceWrapper .cartItem__heading.orderLevelSuggestedItemHeading{
    width: 70%;
    text-overflow: ellipsis;
    max-width: 80%;
    overflow: hidden;
  }
  .suggested-items-order-level-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
  }

}
.suggestedItems__main_container {
  background-color: #fff;
}

.suggestedItems__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.suggestedItems__image_wrapper {
  display: flex;
}

.suggestedItems__list > li:not(:last-child) {
  border-bottom: 3px solid var(--color-gray-light);
}

.suggestedItems__info_container {
  display: flex;
  align-items: center;
  width: 100%;
}

.suggestedItems__name_price_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1;
  padding: 10px 0;
}

.suggestedItems__name_price_wrapper > *:not(:last-child) {
  margin-bottom: 5px;
  margin-left: 10px;
}

.suggestedItems__name_price_wrapper span {
  font-weight: 600;
}

.suggestedItems__info_container img {
  width: 100px;
  height: 100px;
  -o-object-fit: cover;
     object-fit: cover;
}

.suggestedItems__button_wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-right: 30px;
  margin-left: 10px;
}

.suggestedItems__button_wrapper > button {
  max-width: 50%;
  white-space: normal;
}

.suggested_items__view-details {
  color: var(--color-secondary);
  font-size: 0.8em;
  font-weight: 600;
}

.suggested_items__item-description {
  padding: 0 30px;
}

.suggested_items__item-description > p {
  font-size: 14px;
}

.suggested_items__item-description-hidden {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: max-height 0.2s ease-out;
}

.suggestedItems__customize:active,
.suggestedItems__add_to_cart:active {
  opacity: 0.85;
}

.suggestedItems__customize {
  width: unset;
  padding: 0.6em 1.2em;
}

/** Desktop only **/
@media screen and (min-width: 660px) {
  .suggestedItems__collapsable_wrapper {
    max-width: 1200px;
    margin: 20px auto 0 auto;
    padding: 0 24px;
  }

  .suggestedItems__collapsable_wrapper > #suggestedItemsCollapsable {
    width: 100%;
    padding: 0 24px;
  }

  .suggestedItems__main_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #f9f9f9;
  }

  .suggestedItems__main_container > .suggestedItems__list {
    flex: 50% 1;
    max-width: 49%;
  }

  .suggestedItems__list > li {
    background-color: #fff;
  }
}

/** Plus to check button **/
*,
*:before,
*:after {
  box-sizing: border-box;
}
.c-button-reset {
  display: inline-block;
  font-family: inherit;
  font-size: 1em;
  outline: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  text-shadow: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}
.c-plus-to-check {
  position: relative;
  min-width: 40px;
  min-height: 40px;
  width: 2.5em;
  height: 2.5em;
  background-color: #fff;
}
.c-plus-to-check:before,
.c-plus-to-check:after {
  background-color: var(--color-button-text);
  content: "";
  display: inline-block;
  position: absolute;
  top: 46%;
  left: 0.75em;
  right: 0.75em;
  height: 2px;
  transition: transform 300ms cubic-bezier(0.75, -0.6, 0.14, 1.59) 150ms;
  will-change: transform background-color;
}
.c-plus-to-check:before {
  transform: translate(0, 0) rotate(-90deg) scale(1, 1);
}
.c-plus-to-check:after {
  transform: translate(0, 0) rotate(180deg) scale(1, 1);
}
.c-plus-to-check__text {
  position: absolute;
  top: -3125rem;
  left: -3125rem;
  font-size: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
}
.c-plus-to-check[data-state="active"]:before,
.c-plus-to-check[data-state="active"]:after {
  background-color: var(--color-button-text);
  transition-duration: 150ms;
  transition-timing-function: ease-out;
  transition-delay: 0s;
}
.c-plus-to-check[data-state="active"]:before {
  transform: translate(calc(39% - 0.2em), -0.1em) rotate(-45deg) scale(1, 1);
}
.c-plus-to-check[data-state="active"]:after {
  transform: translate(-30%, 0.175em) rotate(45deg) scale(0.43, 1);
}

.suggestedItems__add_to_cart {
  background-color: var(--color-primary);
  border-radius: 50%;
  display: flex;
  padding: 10px;
  position: absolute;
  right: 30px;
  bottom: -10px;
}

.suggestedItems__add_to_cart > .icon--plus {
  color: var(--color-button-text);
}

/* PromoCouponForm.css */
.promo-form__wrapper {
  width: 100%;
  max-width: 400px;
  padding: 50px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
}

.promo-disclaimer {
  font-size: 13px;
  margin: 40px 10px 0;
}

.icon--piggy-bank {
  width: 36px;
  height: 36px;
  fill: #6f6f6f;
  flex-shrink: 0;
}

#form--promo__submit-button,
#form--coupon__submit-button {
  margin-bottom: 30px;
}

#form--coupon {
  margin-top: 5em;
}

#apply-discount-coupon__button{
  margin-top: 4em;
}

#form--promo .button--submit-form-wrapper , #form--coupon .button--submit-form-wrapper{
  display: flex;
  justify-content: center;
}

.applied-promo__wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0 15px;
  position: relative;
}

.applied-promo__output {
  color: #2a2a2a;
  font-size: 14px;
  line-height: 100%;
}

.button--remove-promo {
  border-radius: 100%;
  display: inline-block;
  margin-right: 5px;
}

.button--remove-promo .icon--x-in-circle {
  /*display: block;
  width: 24px;
  height: 24px;
  padding: 4px;*/
  width: 18px;
  height: 18px;
  color: #2a2a2a;
}

.applied-promo__wrapper .in-place-dialog__container {
  /*width: 140%;
  left: -10%;*/
}

.promo-form__discount-output {
  margin-bottom: 15px;
  margin-top: 10px;
  color: #058000;
}

.icon--reward-cup {
  margin-bottom: 25px;
  flex-shrink: 0;
}

.rewards__points-line {
  margin-bottom: 25px;
  opacity: 1;
  transition: opacity 0.5s;
}

.rewards__points-line--loading {
  opacity: 0;
}

.rewards__container {
  position: relative;
  margin: 25px 0 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
}

.rewards-list__item {
  list-style: none;
  position: relative;
  animation: 0.5s fadeIn forwards;
}

.rewards-list__item + .rewards-list__item {
  margin-top: 20px;
}

.rewards__container--loading .rewards-list__item {
  animation: 0.5s fadeOut forwards;
}

.icon--reward-x {
  width: 13px;
  height: 13px;
}

.button--remove-reward {
  background-color: #cfcfcf;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  position: absolute;
  right: calc(100% + 10px);
  top: 9px;
}

.remove-reward__minus {
  font-weight: bold;
  display: block;
  position: relative;
  top: -2px;
}

.reward-button__number-wrapper {
  position: absolute;
  left: calc(100% + 10px);
  top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-button__number--animated {
  animation: 0.35s blinkRewardNumber forwards;
}

@keyframes blinkRewardNumber {
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: none;
    transform: initial;
  }
}

.reward-button__number {
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
}

.button--white.button--reward {
  min-width: 185px;
  height: 40px;
  justify-content: center;
}

.icon--reward-star {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.icon--reward-check {
  width: 14px;
  height: 14px;
  margin-left: 10px;
  background-color: #4caf52;
  stroke: #fff;
  stroke-width: 3px;
  border-radius: 100%;
  padding: 3px;
}

.reward__loading-dots {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto;
}

.reward__loading-dot {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #d64123; /* BRAND COLOR */
  border-radius: 50%;
  animation: growLoadingDot 1.5s infinite both;
}

.reward__loading-dott:nth-child(2) {
  animation-delay: 0.2s;
}

.reward__loading-dot:nth-child(3) {
  animation-delay: 0.3s;
}

.reward__loading-dot:nth-child(4) {
  animation-delay: 0.4s;
}

@keyframes growLoadingDot {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.reward__error {
  color: #ff2020;
  font-style: italic;
}

.rewards__main-container {
  width: 100%;
  height: 100%;
  padding-bottom: 150px;
  overflow-y: auto;
}

.modal-wrapper--rewards-form > .modal.modal--fullscreen {
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.modal-wrapper--rewards-form {
  background-color: white;
  z-index: 1000;
}
.rewards-content {
  padding: 0 30px;
}

.my-rewards__points-and-card-select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rewards__main-container > .my-rewards__section {
  padding: 0 24px;
  margin-top: 24px;
}

.rewards-module__balance-and-star {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.rewards-module__balance-and-star > div {
  display: flex;
}

.rewards-module__balance-and-star > .rewards-form__point-balance-container {
  justify-content: flex-start;
}

.rewards-module__balance-and-star > .icon--star {
  width: 28px;
  height: 28px;
  fill: #f49c00;
  stroke: none;
}

.rewards-module__balance {
  margin-right: 4px;
  font-size: 1.5rem;
  font-weight: 500;
}

.rewards-module__balance-money {
  font-size: 14px;
}

.rewards__main-container > .my-rewards__header-container {
  padding-top: 1em;
}

.rewards-module__select {
  height: 44px;
  padding: 0 20px;
  background: none;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.my-rewards__option-row-shadow {
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  margin: 10px 0;
  border-radius: 5px;
  padding: 6px;
  background-color: white;
}

.my-rewards__option-row-shadow > td {
  min-height: 38px;
}

tr.my-rewards__option-row-shadow-selected.my-rewards__option-row--redeemable {
  border-top: 2px solid #d64123;
}

.my-rewards__option-row-shadow.my-rewards__option-row--redeemable {
  padding: 6px;
}

.my-rewards__option-cell--check > .icon--check {
  background-color: #4caf52;
  stroke: #fff;
  stroke-width: 3px;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  padding: 3px;
}

.my-rewards__option-cell--check {
  min-width: 20px;
}

.my-rewards__option-row-shadow > .my-rewards__option-cell--name {
  width: 70%;
}

.my-rewards__option-row-shadow > .my-rewards__option--name-rewards {
  width: 20%;
}

.header.curved-shadow-container.my-rewards__header {
  width: 100%;
}

.my-rewards__option-row.my-rewards__option-row-shadow
  + .my-rewards__option-row.my-rewards__option-row-shadow {
  border-top: none;
}

.my-rewards__option-row.my-rewards__option-row-shadow.my-rewards__option-row--redeemable.my-rewards__option-row-shadow-selected {
  border: 2px solid #d64123;
  box-shadow: none;
  background-color: white;
}

.my-rewards__option-row-centered-items {
  text-align: center;
  min-height: 53px;
}

.modal-wrapper--rewards-form > div > h1.modal__heading {
  font-size: 1.5rem;
}

.modal-wrapper--rewards-form {
  border-top: 5px solid #d64123;
}

.my-rewards__section.reward-form__points-and-card-select {
  background-color: #f2f2f2;
  margin-top: 0;
  width: 100%;
  display: block;
  padding: 1em 2em;
}

.rewards-form__skeleton {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 40px;
  background: rgb(247, 247, 247);
  margin-bottom: 1em;
}

.rewards-form__skeleton::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgb(247, 247, 247),
    hsla(0, 0%, 100%, 0.83) 50%,
    rgb(247, 247, 247)
  );
  animation: progress 1s ease-in-out infinite;
}

@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.reward-button__applied-indicator-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.reward-button__applied-indicator-wrapper > .icon--check {
  background-color: #4caf52;
  stroke: #fff;
  stroke-width: 3px;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  padding: 3px;
  margin-right: 10px;
}

.my-rewards__option-cell--quantity {
  display: flex !important;
  flex-direction: column;
  width: 55%;
}

/** quantity buttons **/
.reward__quantity-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.reward__quantity-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 1em;
  align-items: center;
}

.reward__quantity-wrapper > label {
  font-size: 0.85rem;
  margin-right: 1em;
}

.reward__quantity-buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.reward__quantity-button:active > .icon {
  background-color: #2a2a2a;
  color: white;
}

.reward__quantity-button:disabled {
  background-color: transparent;
}

.reward__quantity-button:disabled > .icon {
  opacity: 0.5;
}

.reward__quantity-button {
  display: flex;
}
.reward__quantity-button > .icon {
  width: 2em;
  height: 2em;
  padding: 5px;
  stroke-width: 1.5px;
  background-color: white;
  border-radius: 50%;
  border: solid 1.5px #2a2a2a;
  color: #2a2a2a;
}

.reward__quantity-input {
  background-color: transparent;
  border: none;
  border-bottom: solid 2px var(--color-gray-light);
  border-radius: 0;
  text-align: center;
  padding-bottom: 5px;
  width: 40%;
  min-width: 1em;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-left: 5px;
}

/** end of quantity buttons **/

/** Desktop Layout **/

@media screen and (min-width: 660px) {
  .my-rewards__option--button-container-rewards.use-rewards {
    margin: auto;
    width: 65%;
  }
  .reward__quantity-wrapper {
    margin: auto;
    width: 60%;
  }
  .modal-wrapper--rewards-form > .modal > .modal__heading {
    visibility: hidden;
  }

  .modal-wrapper--rewards-form > .modal > .modal__button--close {
    right: 9px;
    left: unset;
  }

  .my-rewards__option-cell.my-rewards__option-cell--quantity {
    width: 20%;
    flex-direction: row;
    margin-right: 2em;
  }

  .my-rewards__option-cell.my-rewards__option-cell--quantity > label {
    font-weight: 600;
    margin-right: 1em;
  }

  .my-rewards__option-cell.my-rewards__option-cell--button {
    width: 25%;
  }

  .my-rewards__option-cell.my-rewards__option-cell--name {
    width: 30%;
  }

  .my-rewards__option-cell.my-rewards__option--name-rewards {
    width: 20%;
  }

  .my-rewards__option-cell.my-rewards__option-cell--cost {
    width: 0;
  }

  .my-rewards__option-cell.my-rewards__option--cost-rewards {
    width: 0;
  }

  .my-rewards__option-row {
    width: 100%;
    margin: 1em 0;
  }
  .my-rewards__option-row.my-rewards__option-row-shadow:hover {
    transform: translateY(-10px);
  }
  .my-rewards__section.my-rewards__options-container.use-rewards__section {
    padding-right: 0;
    padding-left: 0;
  }

  .my-rewards__option-cell--quantity > label {
    width: 47%;
  }

  .my-rewards__option-cell--quantity {
    display: flex !important;
    flex-direction: column;
    width: 55% !important;
  }

  /* quantity buttons */
  .reward__quantity-container {
    margin-top: 0;
  }

  .rewards__main-container {
    padding-left: 2em;
    padding-right: 2em;
  }
}

.reward-form__error-message-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding-top: 1em;
  padding-bottom: 1em;
}

.reward-form__error-message-container > p {
  text-align: center;
  color: #ff2020;
}

@media screen and (max-width: 660px) {
  .reward-form__error-message-container {
    padding: 0 24px;
  }
}

@media screen and (min-width: 660px) and (max-width: 860px) {
  .modal-wrapper--rewards-form {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    border-top: none !important;
  }

  .modal-wrapper--rewards-form > .modal {
    width: 95%;
    height: 95%;
    background-color: white;
  }

  .my-rewards__option-cell.my-rewards__option-cell--quantity {
    width: 20%;
    flex-direction: row;
    margin-right: 1em;
  }

  .my-rewards__option--cost-rewards {
    display: flex;
    position: relative;
    align-items: baseline;
    align-self: baseline;
    justify-content: center;
    margin: 1em 0 0 1em;
    color: #f49c00;
  }
}

@media screen and (min-width: 860px) and (max-width: 1280px) {
  .modal-wrapper--rewards-form {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    border-top: none !important;
  }

  .modal-wrapper--rewards-form > .modal {
    width: 90%;
    height: 90%;
    background-color: white;
  }

  .my-rewards__option-cell.my-rewards__option-cell--quantity > label {
    margin-right: 1rem;
  }
}

@media screen and (min-width: 1280px) {
  .modal-wrapper--rewards-form {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    border-top: none !important;
  }

  .modal-wrapper--rewards-form > .modal {
    width: 70%;
    height: 70%;
    background-color: white;
  }

  .my-rewards__option-cell.my-rewards__option-cell--quantity > label {
    margin-right: 1em;
  }
}

/** End of Desktop Layout **/

.my-rewards__section {
  padding: 0 24px;
}

.my-rewards__section + .my-rewards__section {
  margin-top: 15px;
}

.my-rewards__redeem-rewards-section {
  padding-left: 0;
  padding-right: 0;
}

.redeem-rewards-section__text-container {
  padding: 0 24px;
}

.my-rewards__points-and-card-select > .desktop-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rewards-module__balance-and-star {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.rewards-module__balance-and-star > div {
  display: flex;
}

.rewards-module__balance-and-star.my-rewards > div {
  justify-content: flex-start;
}

.rewards-module__balance {
  margin-right: 4px;
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
}

.rewards-module__balance-and-star > div > .icon--star {
  width: 20px;
  height: 20px;
  fill: #f49c00;
  stroke: none;
}

.rewards-module__default-card-label {
  color: #757575;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.rewards-module__select-wrapper {
  position: relative;
}

.rewards-module__select-wrapper > .icon--arrow-down {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.rewards-module__select {
  height: 44px;
  padding: 0 20px;
  background: none;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.my-rewards-section__header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 12px;
}

.my-rewards-section__header-link {
  color: var(--color-secondary);
  font-size: 15px;
  font-weight: 500;
  text-align: right;
}

.my-rewards-section__heading {
  margin: 0;
  font-size: 1.25rem;
}

.my-rewards-section__text {
  margin: 0 0 12px;
}

.redeem-reward-button {
  margin-bottom: 36px;
}

.my-rewards__options-table {
  width: 100%;
}

.my-rewards__options-table-section + .my-rewards__options-table-section {
  display: block;
  margin-top: 12px;
}

.my-rewards__options-table-section-heading,
.my-rewards-section__subheading {
  font-size: 1rem;
  font-weight: 600;
  line-height: 100%;
  text-align: left;
}

.my-rewards__options-table-section-heading {
  display: block;
  margin-bottom: 8px;
}

.my-rewards-section__subheading {
  margin: 0 0 8px;
  line-height: normal;
}

.my-rewards-section__text + .my-rewards-section__subheading {
  margin-top: 24px;
}

.my-rewards__option-header-row {
  display: flex;
  justify-content: flex-end;
}

.my-rewards__option-header-cell--expiry {
  color: #757575;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 100%;
}

.my-rewards__option-row {
  display: block;
  /* padding: 10px 0 10px 0; */
}

.reward-details-modal {
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
  background-color: #fff;
  animation: scaleUpRewardDetails 0.3s forwards;
}

.my-rewards__option-row .icon--star {
  min-width: 18px;
  margin-right: 4px;
  fill: #878786;
  stroke: none;
}

.my-rewards__option-row--redeemable .icon--star {
  fill: #f49c00;
}

.my-rewards__option-row + .my-rewards__quantity-button-row {
  border-top: none;
}

.my-rewards__option-cell {
  display: grid;
  align-items: center;
  font-size: 0.9rem;
  line-height: 130%;
}

.my-rewards__option-cell--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 75px;
  min-height: 75px;
  max-width: 75px;
  max-height: 75px;
  overflow: hidden;
}

.my-rewards__option-cell--icon > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.my-rewards__option-cell--icon > svg {
  width: 100%;
  height: 100%;
}

.my-rewards__option-row--redeemable .my-rewards__option-cell--icon > svg * {
  fill: #f49c00;
}

.my-rewards__option--icon-rewards {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 75px;
  min-height: 75px;
  max-width: 75px;
  max-height: 75px;
  overflow: hidden;
}

.my-rewards__option--icon-rewards > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.my-rewards__option--icon-rewards > svg {
  width: 100%;
  height: 100%;
}

.my-rewards__option-row--redeemable .my-rewards__option--icon-rewards > svg * {
  fill: #f49c00;
}

.my-rewards__option-cell--cost {
  display: flex;
  align-items: center;
}

.my-rewards__option--cost-rewards {
  display: inline;
}

.my-rewards__option-row--redeemable .my-rewards__option-cell--cost {
  color: #f49c00;
}

.my-rewards__option-row--redeemable .my-rewards__option--cost-rewards {
  color: #f49c00;
}

.my-rewards__option-container {
  display: flex;
}

.my-rewards__option-cell-container {
  display: grid;
  margin-left: 1em;
  justify-content: space-between;
}

.my-rewards__option-cell-container .icon--star {
  fill: #f49c00;
  color: #f49c00;
  height: 1rem;
  width: 1rem;
  margin-left: 4px;
}

.my-rewards__option-cell-container .my-rewards__option-cell--cost {
  color: #f49c00;
  display: flex;
  font-weight: 600;
}

.my-rewards__option-cell-container .my-rewards__option-cell--cost > svg * {
  fill: #f49c00;
}

.my-rewards__option-cell-container .my-rewards__option--cost-rewards {
  color: #f49c00;
  display: flex;
  font-weight: 600;
}

.my-rewards__option-cell-container .my-rewards__option--cost-rewards > svg * {
  fill: #f49c00;
}

.my-rewards__option-container-rewards {
  display: grid;
  margin-left: 1em;
  justify-content: space-between;
}

.my-rewards__option-container-rewards .icon--star {
  fill: #f49c00;
  color: #f49c00;
  height: 1rem;
  width: 1rem;
  margin-left: 4px;
}

.my-rewards__option-container-rewards .my-rewards__option--cost-rewards {
  color: #f49c00;
  display: flex;
  font-weight: 600;
}

.my-rewards__option-container-rewards .my-rewards__option--cost-rewards > svg * {
  fill: #f49c00;
}

.my-rewards__option-cell--button-container {
  display: flex;
  justify-content: center;
  max-width: 65%;
  align-self: center;
  margin: 0 auto;
}

.my-rewards__option-cell--button-container .button--primary-small {
  margin-right: 0px !important;
}

.my-rewards__option--button-container-rewards {
  display: flex;
  justify-content: center;
  max-width: 85%;
  align-self: center;
  margin: 0 auto;
}

.my-rewards__option--button-container-rewards .button--primary-small {
  margin-right: 0px !important;
}

.my-rewards__reward-description > p {
  font-size: 14px;
}

.my-rewards__reward-description-hidden {
  max-height: 24px;
  overflow: hidden;
  visibility: hidden;
  transition: max-height 0.2s ease-out;
}

.my-rewards__option-cell--name {
  padding: 0 4px 0 0;
  margin-right: 0.5em;
  font-weight: 600;
}

.my-rewards__option--name-rewards {
  padding: 0 4px 0 0;
  margin-right: 0.5em;
  font-weight: 600;
}

.my-rewards__option-cell--quantity > label {
  width: 100%;
  text-align: left;
}

.my-rewards__option-cell--quantity > select {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--color-gray-light);
  border-radius: 0;
  -moz-text-align-last: center;
       text-align-last: center;
  padding: 5px 0;
  width: 100%;
}

.my-rewards__quantity-button-row {
  align-items: flex-end;
  padding-bottom: 15px;
}

.my-rewards__option-cell--button {
  flex-direction: column;
  justify-content: center;
  display: inline-flex;
}

.my-rewards__option-cell--expiry {
  font-size: 0.75rem;
  text-align: center;
  margin-top: 10px;
}

.my-rewards__option--expiry-rewards,
.my-rewards__option--balance {
  font-size: 0.75rem;
  text-align: center;
  margin-top: 10px;
}

.my-rewards__option--balance {
  margin-top: 0.5em;
  text-align: left;
  margin-bottom: 0.5em;
}

.my-rewards__option--expiry-rewards {
  margin-bottom: 0.5em;
  text-align: left;
}

.my-rewards__option--balance + .my-rewards__option--expiry-rewards {
  margin-top: 0;
}

.my-rewards__option-cell--button {
  width: 45%;
}

.my-rewards__option-cell--button > button {
  width: 100%;
}

.my-reward__success-message-header {
  font-weight: 700;
  font-size: 1.1em;
  margin: 0;
}

.my-reward__success-message-reward-name {
  font-weight: 15px;
}

.my-reward__success-message-reward-detail {
  margin: 0;
}

.my-reward__success-message-header-wrapper {
  display: flex;
  align-content: baseline;
  margin-bottom: 1em;
}

.my-reward__success-message-header-wrapper > div:first-child {
  display: flex;
  align-items: center;
}

.my-reward__success-message-header-wrapper > div > .icon--check {
  background-color: #4caf52;
  border-radius: 50%;
  stroke: white;
  stroke-width: 4px;
  padding: 3px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.my-reward__success-message-header-wrapper + .my-reward__success-message-reward-detail {
  margin-bottom: 8px;
}

.my-reward__success-message-reward-transaction {
  font-size: 14px;
}

.my-rewards__redirect-buttons-container {
  display: flex;
  justify-content: center;
}

.my-rewards-main-content {
  background-color: #f2f2f2;
}

.my-rewards__options-container {
  padding-top: 1.5em;
  padding-bottom: 1em;
  background-color: white;
}

.my-rewards__confirmation-popup > span {
  position: relative;
  margin-right: 20px;
}

.my-rewards__confirmation-popup > span > .icon--star {
  width: 20px;
  height: 20px;
  fill: #f49c00;
  stroke: none;
  position: relative;
  top: 3px;
  left: -1px;
}

.my-rewards__option-row {
  display: flex;
  justify-content: space-between;
}

.my-rewards__wrapper:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-light);
}

.my-rewards__wrapper {
  padding: 24px 0px;
}

.my-rewards__wrapper-use-rewards {
  padding: 24px 0px;
  border-bottom: 1px solid var(--color-gray-light);
}

.my-rewards__wrapper-use-rewards:last-child {
  margin-bottom: 0.8em;
}

.my-rewards__redirect-dialog p {
  margin-top: 0;
  line-height: 1.5;
  font-size: 16px;
}

.my-rewards__redirect-dialog .dialog-modal__buttons {
  display: none;
}

@keyframes scaleUpRewardDetails {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 660px) {
  .my-rewards-main-content {
    padding-top: 15px;
    margin-bottom: 57px;
    /* AppFooter height */
  }
}

@media screen and (min-width: 598px) and (max-width: 660px) {
  .my-rewards__confirmation-popup > span > .icon--star {
    top: 0;
  }
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .my-rewards__wrapper-desktop {
    padding: 24px 0px;
  }

  .my-rewards__wrapper-desktop:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-light);
  }

  .my-rewards__wrapper-desktop-use-rewards {
    padding: 24px 0px;
    border-bottom: 1px solid var(--color-gray-light);
  }

  .my-rewards__wrapper-desktop-use-rewards:last-child {
    margin-bottom: 0.8em;
  }

  .my-rewards__option-cell--name {
    padding: 0 0 4px 0;
    margin-right: 0.5em;
    font-weight: 600;
  }

  .my-rewards__reward-description-visible {
    width: -moz-fit-content;
    width: fit-content;
    min-width: 165%;
  }

  .my-rewards__option--name-rewards {
    padding: 0 0 4px 0;
    margin-right: 0.5em;
    font-weight: 600;
  }

  .button--primary-small {
    padding: 1em !important;
  }

  .my-rewards__redirect-buttons-container .button--primary-small {
    padding: 0 12px !important;
    max-width: 250px;
    min-height: 44px;
    font-size: 0.7em;
  }

  .my-rewards__option-cell--button-container {
    display: flex;
    justify-content: left;
    width: 40%;
    position: relative;
    align-self: baseline;
  }

  .my-rewards__option--button-container-rewards {
    display: flex;
    justify-content: flex-end;

    position: relative;
    align-self: baseline;
    margin: auto 0;
    max-width: 30%;
    min-width: 30%;
  }

  .my-rewards__reward-description-hidden {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: max-height 0.2s ease-out;
  }

  .my-rewards__option-cell-container {
    display: flex;
    position: relative;
    margin: 0em 2em 0 1em;
    align-self: center;
    width: 70%;
  }

  .my-rewards__option-container-rewards {
    display: grid;
    position: relative;
    margin: 1em 2em 0 1em;
    align-self: flex-start;
    width: 70%;
  }

  .my-rewards__option-container-use-rewards {
    display: block;
    position: relative;
    margin: 1em 0 0 0;
    align-self: flex-start;
    width: 20%;
  }

  .my-rewards__option-cell--cost {
    display: flex;
    position: relative;
    align-items: baseline;
    align-self: baseline;
    justify-content: center;
    margin: 0em 1em 0 2em;
  }

  .my-rewards__option-cell--cost {
    color: #f49c00;
  }

  .my-rewards__option--cost-rewards {
    display: flex;
    position: relative;
    align-items: baseline;
    align-self: baseline;
    justify-content: center;
    margin: 1em 1em 0 1em;
    color: #f49c00;
  }

  .icon--star {
    fill: #f49c00;
    color: #f49c00;
    height: 1rem;
    width: 1rem;
    margin-left: 4px;
  }

  .my-rewards__option-cell--cost {
    color: #f49c00;
    display: flex;
  }

  .my-rewards__option-cell--cost > svg * {
    fill: #f49c00;
  }

  .my-rewards__option--cost-rewards {
    color: #f49c00;
    display: flex;
  }

  .my-rewards__option--cost-rewards > svg * {
    fill: #f49c00;
  }

  .my-rewards__option-cell--icon {
    align-self: normal;
  }

  .my-rewards__option-container {
    align-items: center;
  }

  .my-rewards__confirmation-popup > span > .icon--star {
    position: relative;
    top: 3px;
    left: -3px;
    right: unset;
    margin: 0;
    width: 24px;
    height: 24px;
  }

  .my-rewards-main-content {
    background-color: white;
  }

  .my-rewards__section + .my-rewards__section {
    margin-top: 0;
    margin-bottom: 5em;
  }

  .my-rewards__points-and-card-select {
    background-color: #f5f5f5;
    padding: 1em 0;
    margin-bottom: 3%;
  }

  .rewards-module__stepper {
    display: flex;
    list-style-type: none;
    width: 100%;
    height: 100%;
    margin: 0 0 0 -11.25px;
    padding-left: 0;
    overflow: auto;
    white-space: nowrap;
    min-height: 5.5em;
  }

  .my-rewards__points-scroll-wrapper {
    background-color: white;
    padding-bottom: 2em;
    padding-right: 0;
    overflow-y: visible;
    min-height: 5.5em;
  }

  .rewards-module__stepper {
    overflow-x: hidden;
  }

  .rewards-module__stepper:hover {
    overflow-x: auto;
  }

  .my-rewards__option-cell--cost {
    width: 10%;
  }

  .my-rewards__option--cost-rewards {
    width: 10%;
  }

  .rewards-module__select {
    font-weight: bold;
  }

  .rewards-module__select-wrapper > .icon--arrow-down {
    width: 30px;
    height: 30px;
    right: -10px;
  }

  .my-rewards-section__header-link {
    font-weight: bold;
    font-size: 1em;
  }

  .my-rewards__options-table {
    margin-top: 2em;
  }

  .my-rewards__options-table-section-heading,
  .my-rewards__option-header-cell--expiry,
  .my-rewards__options-table-section-heading,
  .my-rewards-section__subheading {
    font-weight: bold;
    font-size: 1.1em;
  }

  .my-rewards-section__header {
    margin-bottom: 2em;
  }

  .my-rewards__option-cell > .icon--star,
  .redeem-rewards-slider-item__price-wrapper > .icon--star {
    width: 2.5em;
    height: 2.5em;
  }

  .my-rewards__option-cell--cost,
  .redeem-rewards-slider-item__price {
    font-size: 1.3em;
    font-weight: 600;
  }

  .my-rewards__option--cost-rewards,
  .redeem-rewards-slider-item__price {
    font-size: 1.3em;
    font-weight: 600;
  }

  .my-rewards__option-name {
    /* font-size: 1.3em; */
    font-weight: 600;
  }

  .redeem-reward-button {
    width: 58%;
  }

  .redeem-rewards-slider {
    margin-top: 3em;
    margin-left: 10%;
  }

  .my-rewards__option-cell--icon {
    width: 6%;
  }

  .my-rewards__option--icon-rewards {
    width: 6%;
  }

  .my-rewards__option-cell--button {
    width: 49% !important;
  }

  .reward-form__options-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .dashboard__rewards-module.my-rewards__section.my-rewards__points-scroll-wrapper {
    padding: 0;
  }
  .my-rewards__section.my-rewards__options-container {
    padding-left: 0;
    padding-right: 0;
  }

  .my-rewards__option--balance {
    margin-top: 1em;
    text-align: left;
    margin-bottom: 1em;
  }

  .my-rewards__option--expiry-rewards {
    margin-bottom: 1em;
    text-align: left;
  }

  .my-rewards__option--balance + .my-rewards__option--expiry-rewards {
    margin-top: 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .my-rewards__section.my-rewards__options-container:not(.use-rewards__section) {
    max-width: 100%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .my-rewards__section.my-rewards__options-container:not(.use-rewards__section) {
    max-width: 70%;
  }
}

@media screen and (min-width: 660px) and (max-width: 1024px) {
  .my-rewards__reward-description-desktop > p {
    font-size: 14px;
    width: -moz-max-content;
    width: max-content;
    max-width: 350%;
  }

  .button--primary-small {
    padding: 0.8em 2em !important;
    font-size: 0.6em;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .my-rewards__reward-description-desktop > p {
    font-size: 14px;
    width: -moz-max-content;
    width: max-content;
    max-width: 360%;
  }
}

@media screen and (min-width: 1280px) {
  .my-rewards__reward-description-desktop > p {
    font-size: 14px;
    width: -moz-max-content;
    width: max-content;
    max-width: 360%;
  }
}

/** End of Desktop Layout **/

.cart-content-desktop {
  /*padding-top: 10px;*/
}

.cart-summary__container {
  display: flex;
  flex-shrink: 0; /* Fixes cropping on iOS */
  justify-content: space-between;
  width: 100%;
  /*margin-bottom: 10px;*/
  padding: 10px 30px 20px 30px;
  background-color: white;
}

.cart-summary__cart-column .cart-indicator {
  margin-right: 8px;
}

.cart-summary__line {
  font-size: 16px;
  line-height: 100%;
  display: flex;
  justify-content: space-between;
}

.cart-summary__label-service-charge-label {
  max-width: 50%;
  word-wrap: break-word;
}

.cart-summary__line + .cart-summary__line {
  margin-top: 10px;
}

.button--white {
  display: flex;
  align-items: center;
  font-size: 0.7em;
  margin-right: 0 !important;
}

.button--add-promo {
  margin-top: 15px;
  width: 100%;
}

.cart-summary__cart-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin-right: 10%;
}

.cart-summary__cart-column > button {
  white-space: normal;
}

.cart-summary__totals-column {
  position: relative;
  width: 50%;
}

.cart-summary__note {
  font-style: italic;
  font-size: 13px;
}

.cart-summary__line--total {
  padding-top: 10px;
  margin-top: 15px;
  border-top: 1px solid #ccc;
  font-size: 19px;
  font-weight: 600;
  text-transform: uppercase;
}

.cart-summary__line--coupon {
  padding-top: 10px;
  margin-top: 15px;
  border-top: 1px solid #ccc;
}

.cart-summary__line--coupon + .cart-summary__line--total {
  padding-top: 0;
  border: none;
}

.cart-summary__discount-value {
  color: #ff2020;
}

.modal-wrapper--apply-discounts .modal__heading {
  font-size: 18px;
}

.modal-wrapper--apply-discounts {
  overflow-y: auto;
  border-top: 5px solid #d64123;
}

.modal-wrapper--apply-discounts .modal--fullscreen {
  padding-top: 40px;
  overflow-y: auto;
}

.button--use-rewards {
  margin-top: 15px;
  justify-content: center;
  width: 100%;
}

.use-rewards__icon--star {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  color: var(--color-button-text);
}
.cartSummaryWrapper{
  display: flex;
  width: 100%; 
  flex-direction: column;
}
/** Dektop Layout **/

@media screen and (min-width: 850px) {
  .cart-summary__container {
    padding: 2em;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .cart-summary__cart-column > button:first-child {
    margin-top: 0;
  }

  .cart-summary__line {
    font-size: 1.2em;
  }

  .modal--coupon-discount {
    z-index: 1005;
  }
}

@media screen and (max-width: 950px) {
  .cart-summary__line {
    font-size: 1em;
  }
}
/** End of Desktop Layout **/

.cart__tip-container {
  margin: 0 auto;
  border-radius: 4px;
  background-color: #fff;
}

.cart__tip-container > h2 {
  margin-top: 0;
  font-size: 16px;
}

.cart__tip-amounts {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
}

.cart__tip-button {
  padding: 5px 10px;
  border-radius: 9999px;
  font-size: 14px;
  font-weight: 500;
}

.cart__tip-button-selected {
  color: var(--color-button-text);
  background-color: var(--color-primary);
}

.cart__tip-custom-amount-input {
  border-radius: 0;
  padding: 10px 0;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #cdcdcd;
  background-clip: padding-box;
  line-height: normal;
  background-color: #fff;
  padding: 0 0 0 5px;
  width: 90%;
}

.cart__tip-custom-amount-label {
  font-size: 14px;
  font-weight: 700;
  font-size: 12px;
}

.cart__tip-custom-amount-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /*margin-top: 20px;*/
  padding: 0px 30px 20px;
}

.cart__tip-custom-amount-container > div {
  flex: 1 1 48%;
  position: relative;
}

.cart__tip-remove-custom-amount {
  position: absolute;
  right: 15px;
  bottom: -3px;
}

.cart__tip-remove-custom-amount > .icon--x-in-circle {
  width: 20px;
  height: 20px;
}

.cart__tip-custom-amount-input:focus {
  border-color: var(--color-secondary);
}

.cart__tip-custom-amount-currency-sign {
  margin-right: 5px;
}

@media screen and (min-width: 660px) {
  .cart__tip-remove-custom-amount {
    right: 15px;
    bottom: -3px;
  }
}

@media screen and (min-width: 850px) {
  .cart__tip-container > h2 {
    font-size: 1.5em;
  }

  .cart__tip-button,
  .cart__tip-custom-amount-label {
    font-size: 1em;
  }

  .cart__tip-container {
    padding: 2em 2em 3em 2em;
  }
}

@media screen and (min-width: 1200px) {
  .cart__tip-remove-custom-amount {
    right: 25px;
    bottom: -3px;
  }
}

.confirmation-email__container {
  display: flex;
  flex-direction: column;

  background-color: #fff;
}

.confirmation-email__body-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
}

.confirmation-email__header-wrapper {
  background-color: var(--color-gray-light);
}
.confirmation-email__header-wrapper > h3 {
  margin: 0.9em 30px;
  font-size: 16px;
}

#form--confirmation-email > .form-submit__wrapper {
  display: none;
}

#form--confirmation-email .form__field-label {
  font-size: 0.9rem;
}

/** Desktop Design **/
@media screen and (min-width: 660px) {
  .confirmation-email__main-wrapper {
    padding-left: 24px;
    padding-right: 24px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 1em;
  }

  .confirmation-email__body-wrapper input {
    max-width: 50%;
  }

  .additional-disclaimer + .confirmation-email__main-wrapper {
    margin-top: 0;
  }

  .confirmation-email__main-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 1em;
  }

  .confirmation-email__main-wrapper {
    padding: 0;
    margin-top: 0;
  }

  .cart-checkout__desktop-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

.blink {
  animation-name: blink;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes blink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes blink-star {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    transform: scale(1.75);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.delay-1 {
  animation-delay: 0.35s;
}

.delay-2 {
  animation-delay: 0.7s;
}

/* OrderConfirmation styles */
.main-content--center-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.order-confirmation-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
  padding: 0 5% 50px;
  overflow-y: scroll;
}

.order-confirmation__checkmark {
  background: #bfd72f;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  animation: appear 1s forwards, blink 0.5s 0.75s forwards;
}

.order-confirmation__checkmark > .icon {
  width: 12px;
  height: 12px;
  stroke: #fff;
}

.order-confirmation__heading {
  opacity: 0;
  transform: translateY(100px);
  font-size: 1.25rem;
  font-weight: 600;
  animation: slide-in 0.5s forwards;
  animation-delay: 0.1s;
}

.order-confirmation__order-summary {
  opacity: 0;
  margin-bottom: 15px;
  transform: translateY(10px);
  animation: slide-in 0.5s forwards;
  animation-delay: 0.35s;
  text-align: left;
  line-height: 1.5;
}

.order-confirmation__total {
  opacity: 0;
  margin-bottom: 30px;
  font-weight: 600;
  transform: translateY(10px);
  animation: slide-in 0.5s forwards;
  animation-delay: 0.7s;
}

@keyframes slide-in {
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.reward__container {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #d64123;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
  margin-bottom: 25px;
  opacity: 0;
  transform: translateY(10px);
  animation-delay: 0.4s;
  animation: appear 1s forwards;
}

@keyframes appear {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.earned-reward {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
}

.reward-message {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
}

.earned-reward__value {
  font-size: 20px;
  font-weight: 600;
}

.earned-reward > .icon--star {
  width: 18px;
  color: #d64123;
  fill: #d64123;
  animation: blink-star 0.5s forwards;
}

.earned-reward > .icon--star:first-child {
  animation-delay: 1s;
}

.earned-reward > .icon--star:last-child {
  animation-delay: 1.15s;
}

.rewards-link {
  opacity: 0;
  font-size: 13px;
  transform: translateY(10px);
  animation: appear 1s forwards;
  animation-delay: 0.15s;
  margin-bottom: 10px;
}

.order-confirmation__points-diclaimer {
  line-height: 1.5;
}

.order-confirmation__order-id {
  margin-bottom: 15px;
  line-height: 1.5;
}

.order-confirmation__order-id > span {
  font-weight: 600;
}

.order-confirmation__image {
  margin: 35px 20px;
}

.order-confirmation__thankyou-note {
  display: flex;
  justify-content: left;
  align-items: center;
}

.order-confirmation__rewards-bottom {
  margin-top: 36px;
}

.order-confirmation__rewards-bottom > a {
  white-space: normal;
}

.add-new-loyalty-card-button {
  padding: 10px 18px;
  font-size: 13px;
  line-height: 100%;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  font-weight: 550;
}

.order-confirmation__points-diclaimer > span > svg {
  width: 20px;
  height: 20px;
  vertical-align: top;
  left: 0;
}

.order-confirmation__points-issued {
  font-weight: bold;
}

.order-confirmation__ratings-main-container {
  background-color: var(--color-gray-light);
  padding: 1em;
  margin-top: 1.3em;
}
.order-confirmation__ratings-header {
  font-weight: 700;
  text-align: center;
  margin-top: 0;
}

.order-confirmation__ratings-button-container {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 0 auto;
}

.order-confirmation__ratings-button-container > a {
  margin-right: 10px;
  background-color: var(--color-secondary);
  padding: 0.6em;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.order-confirmation__ratings-button-container > a > svg {
  color: white;
  width: 30px;
  height: 27px;
}
/** Desktop Layout **/

@media screen and (min-width: 660px) and (max-width: 1600px) {
  .order-confirmation__image {
    text-align: center;
  }

  .order-confirmation__image > img {
    max-width: 40%;
  }
  .order-confirmation__points-diclaimer {
    margin-bottom: 2em;
  }

  .order-confirmation-container {
    max-width: 80%;
    overflow-y: auto;
    margin: 0 auto;
    text-align: center;
  }

  .order-confirmation__thankyou-note,
  .order-confirmation__order-id,
  .order-confirmation__order-summary {
    justify-content: center;
    text-align: center;
  }

  .order-confirmation__rewards-bottom,
  .order-confirmation__check-my-reward-link-wrapper {
    padding-right: 25%;
    padding-left: 25%;
    bottom: 0;
    margin-top: 0;
    padding-top: 0;
  }

  .order-confirmation__rewards-bottom.order-confirmation__rewards-bottom__bottom-margin {
    margin-bottom: 3em;
  }

  .order-confirmation__rewards-bottom {
    max-width: 98vw;
  }
}

@media screen and (min-width: 1601px) {
  .order-confirmation__image {
    text-align: center;
  }
  .order-confirmation__image > img {
    max-width: 50%;
  }
  .order-confirmation-container {
    max-width: 70%;
    overflow-y: auto;
    margin: 0 auto;
    text-align: center;
  }
  .order-confirmation__points-diclaimer {
    margin-bottom: 2em;
  }

  .order-confirmation__thankyou-note,
  .order-confirmation__order-id,
  .order-confirmation__order-summary {
    justify-content: center;
    text-align: center;
  }

  .order-confirmation__rewards-bottom {
    padding-right: 25%;
    padding-left: 25%;
  }
}

.order-confirmation__check-my-reward-link {
  color: var(--color-secondary);
  font-weight: 600;
}

.order-confirmation__check-my-reward-link-wrapper {
  margin-top: 15px;
}

/** End of desktop Layout **/

.recent-order-content {
  padding: 0 0 85px;
  background: linear-gradient(180deg, #eee, #eee 80%, #fff);
  position: relative;
}

.recent-order {
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 0;
  padding-top: 1.2em;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid rgba(140, 140, 140, 0.25);
  border-radius: 5px;
}

.recent-order__current-order {
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 1.2em;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid rgba(140, 140, 140, 0.25);
  border-radius: 5px;
}

.recent-order__back-button {
  display: flex;
  width: 98%;
  max-width: 1200px;
  margin: 1.2em auto 0;
  grid-gap: 2%;
  gap: 2%;
}

.recent-order__back-button-label {
  margin-top: 0.15em;
}

.recent-order + .recent-order {
  margin-top: 0.2em;
}

.recent-order__section-header {
  max-width: 660px;
  line-height: normal;
  margin-top: 0px;
  font-size: 0.8rem;
}

.recent-order__main {
  padding: 0 24px;
  margin-bottom: 0px;
}

.recent-order__header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: #757575;
  font-size: 0.8rem;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  margin-left: 24px;
  margin-right: 24px;
}

.recent-order__order-details-store-name {
  font-size: larger;
  font-weight: bold;
  margin-bottom: 10px;
}

.recent-orders__order-status-button {
  width: 40%;
  font-size: 12px;
}

.recent-order__active-order-checkmark {
  font-size: 16px;
  list-style-type: none;
  margin-bottom: 0.5em;
  padding: 0.25em 0 0 2.3em;
  position: relative;
}

.recent-order__active-order-checkmark::before {
  content: " ";
  display: flex;
  border: solid 0.8em var(--color-secondary);
  border-radius: 0.8em;
  height: 0;
  width: 0;
  position: absolute;
  left: 0.3em;
  top: 40%;
  margin-top: -0.5em;
  box-sizing: content-box !important;
}

.recent-order__active-order-checkmark::after {
  content: " ";
  display: flex;
  width: 0.3em;
  height: 0.6em;
  border: solid white;
  border-width: 0 0.2em 0.2em 0;
  position: absolute;
  left: 0.8em;
  top: 40%;
  margin-top: -0.2em;
  transform: rotate(45deg);
  box-sizing: content-box !important;
}

.recent-order__items-list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.recent-order__item {
  position: relative;
  padding-bottom: 0.5em;
}

.recent-order__item:before {
  position: absolute;
  top: 0;
  left: 0;
  color: #2a2a2a;
}

.recent-order__item-name-price {
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 4px;
}

.recent-order__order-units {
  font-size: 14px;
  font-weight: 600;
  color: #757575;
  width: 10%;
}

.recent-order__item-name {
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  width: 70%;
}

.recent-order__item-price {
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  width: 20%;
}

.recent-order__options {
  display: flex;
  color: #757575;
  font-size: 13px;
  font-weight: 400;
  margin: 5px 0;
  flex-direction: column;
}

.recent-order__option {
  display: inline-block;
}

.recent-order__options-heading {
  display: inline-block;
  margin: 0 4px 0 0;
  font-size: 13px;
  font-weight: 400;
}

.recent-order__options-list {
  display: flex;
  width: 90%;
  padding-left: 0;
  margin-left: 10%;
  list-style-type: none;
  flex-direction: column;
}

.recent-order__option-item-name-price {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.recent-order__option-item-name {
  margin: 0px;
  text-align: left;
  width: 80%;
}

.recent-order__option-item-price {
  margin: 0px;
  text-align: right;
  width: 20%;
}

.recent-order__options-nested-list {
  list-style-type: none;
  margin-left: -24px;
}

.recent-order__loading {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-top: 50%;
}

.recent-order__loading > .loading-spinner {
  position: relative !important;
}

.recent-order__loading-text {
  display: block;
  font-size: 15px;
  position: relative;
  top: 54%;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 10px;
  text-align: center;
}

.recent-order__footer {
  display: flex;
  align-self: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 1em;
  margin-right: 24px;
  margin-left: 24px;
  min-height: 60px;
  align-items: center;
  width: calc(100% - 48px);
}

.recent-order__footer-active-orders {
  display: block;
  align-self: center;
  justify-content: center;
  margin-right: 24px;
  margin-left: 24px;
  align-items: center;
  width: calc(100% - 48px);
}

.recent-order__footer-past-orders {
  display: flex;
  align-self: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 1.2em;
  min-height: 60px;
  align-items: center;
  width: calc(100% - 48px);
}
.show-only-order-details {
  justify-content: flex-end;
}
.recent-order__footer-completed-order-details {
  margin-left: 24px;
  margin-right: 24px;
}

.recent-order__footer-current-order {
  display: flex;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.2em;
}

.recent-order__footer-completed-current-order {
  display: flex;
  margin-bottom: 0px;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
  margin-top: 0px;
}

.recent-order__footer-reorder-button {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  position: fixed;
  bottom: 57px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.23573179271708689) 100%
  );
  display: flex;
}

.recent-order__reorder-button-desktop {
  align-self: flex-end;
  padding-top: 15px;
  padding-bottom: 1em;
  min-height: 60px;
  width: 200px;
}

.recent-order__footer-points {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.recent-order__footer-star-icon {
  margin: 0;
  padding: 0;
  color: #f49c00;
}

.recent-order__order-type {
  font-weight: 600;
}

.recent-order__order-from {
  font-weight: 600;
}

.recent-order__tip-total-active-orders {
  border-top: 2px solid #000000;
  display: block;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.recent-order__total-active-orders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.recent-order__tip-discount-active-orders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: normal;
  margin-top: 0px;
  padding-top: 1.2em;
}

.recent-order__tip-total-completed-orders {
  border-top: 2px solid #000000;
  display: block;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.recent-order__total-completed-orders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.recent-order__tip-discount-completed-orders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: normal;
}

.recent-order__border-bottom {
  border-bottom: 2px solid #e8e8e8;
  padding-bottom: 1.2em;
}

.recent-order__border-bottom-completed-order {
  border-bottom: 2px solid #e8e8e8;
}

.recent-order__total-past-orders {
  font-weight: 600;
  display: inline-flex;
  margin-top: 10px;
  margin-bottom: 0px;
}

.recent-order__total > div {
  display: flex;
  align-items: center;
}

.recent-order__reorder-button {
  width: 45%;
  font-size: 12px;
}

.recent-order__reorder-button > span {
  padding: 5px 0;
}

.recent-order__reorder-button--alert {
  color: #d34146;
  text-decoration: none;
  border-color: #d34146;
}

.recent-order__reorder-button > .icon {
  margin-right: 4px;
  width: 16px;
  height: 16px;
  color: var(--color-secondary);
}

.recent-order__reorder-button--alert > .icon {
  color: #d34146;
}

.recent-order__reorder-button--filled {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: none;
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-button-text);
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 600;
  min-height: 40px;
  white-space: normal;
  word-wrap: break-word;
}

.recent-order__reorder-button--filled-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: none;
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-button-text);
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 600;
  height: 40px;
}

.recent-order__reorder-button-view-details {
  position: relative;
  display: block;
  width: 70%;
  height: 52px;
  margin: 0 auto;
  padding: 0 15px;
  color: var(--color-button-text);
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  background-color: var(--color-primary);
  border-radius: 9999px;
  z-index: 1;
}

.recent-order__view-menu {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: none;
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-button-text);
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
  height: 40px;
  white-space: normal;
  word-wrap: break-word;
}

.recent-orders__no-orders-yet-label {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
}

.recent-orders__browse-menu-label {
  color: #757575;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 20px;
}

.recent-order-view-details-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: none;
  border: 2px solid var(--color-primary);
  background-color: white;
  color: var(--color-secondary-button-text);
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 600;
  min-height: 40px;
  white-space: normal;
  word-wrap: break-word;
}

.recent-order-view-details-button-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: none;
  border: 2px solid var(--color-primary);
  background-color:  white;
  color: var(--color-secondary-button-text);
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 600;
  height: 40px;
}

.recent-order__expand-more {
  display: block;
  text-align: center;
  align-items: center;
  margin: 10px auto;
  justify-content: center;
}

.recent-order__notification {
  opacity: 0;
  transform: translateX(100vw);
  position: fixed;
  bottom: 57px;
  /* App Footer height */
  left: 0;
  width: 100%;
  padding: 37px 18px 30px;
  color: #2a2a2a;
  background-color: #fff;
  animation: slideUpRecentOrderNotification 0.1s forwards;
  z-index: 800;
}

.recent-order__notification--alert {
  background-color: #d34146;
}

.recent-order__notification-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 0.9rem;
}

.recent-order__notification .icon {
  width: 18px;
  height: 18px;
  color: #2a2a2a;
}

.recent-order__notification .icon--triangle-alert {
  margin-right: 4px;
}

.recent-order__close-notification {
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.recent-order__no-orders-message {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-bottom: 60px;
  padding-top: 60px;
}

.recent-order__transition {
  position: relative;
  transition: top 2s ease 0s;
  top: 0;
}

.recent-order__no-orders-message > .icon--info {
  width: 30px;
  height: 30px;
  margin-bottom: 4px;
}

@keyframes slideUpRecentOrderNotification {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.recent-order__combo-child-items {
  margin: 10px 0;
  padding-left: 10px;
  list-style-type: none;
}

.recent-order__combo-child-items > li {
  margin-bottom: 10px;
  width: 90%;
  margin-left: 10%;
}

.recent-order__combo-child-item-name {
  margin: 0 0 2px;
  font-size: 0.8rem;
  font-weight: 600;
}

.load-more-button__container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 5em;
}

/** Past Order Calander - Mobile */

.past-orders__calendar-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 16px 0 16px;
}

.rmdp-container {
  width: 100%;
  position: relative;
  margin: 15px 0;
}

.rmdp-container > input {
  width: 100%;
  height: 30px;
}

.rmdp-container > .past-orders__icon-container {
  position: absolute;
  top: 7px;
  left: calc(100% - 20px);
}

.rmdp-container > .past-orders__icon-container > .icon-calendar {
  width: 15px;
  height: 15px;
}

.past-orders__calendar-label {
  font-weight: 550;
}

.rmdp-range {
  background-color: var(--color-primary) !important;
  color: var(--color-primary-color-contrast) !important;
}

.rmdp-week-day {
  color: var(--color-gray) !important;
}

.rmdp-arrow {
  border-color: #2a2a2a !important;
}

.rmdp-arrow-container.disabled .rmdp-arrow {
  border-color: var(--color-gray-light) !important;
}

.rmdp-arrow-container:hover {
  background-color: transparent !important;
  box-shadow: none !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--color-primary) !important;
}

.past-orders__calendar-input {
  padding-left: 5px;
  border: 1px solid var(--color-gray);
  border-radius: 4px;
}
.past-orders__calendar-input:focus {
  border: 1px solid var(--color-primary);
  border-radius: 4px;
}

.past-orders__calendar-day-selected {
  background-color: var(--color-primary);
  color: var(--color-primary-color-contrast);
}

.rmdp-header > div:first-child > div.rmdp-header-values {
  margin-left: 5px;
}

.rmdp-header > div:first-child > div.rmdp-header-values > span {
  padding-left: 0px;
}

.rmdp-ym .rmdp-today:not(.rmdp-selected) > span {
  background-color: transparent !important;
  color: var(--color-secondary) !important;
}

.rmdp-header-values > span {
  font-weight: 600;
}

.rmdp-disabled > span {
  color: #909194;
}

.rmdp-left > i,
.rmdp-right > i {
  width: 8px !important;
  height: 8px !important;
}

.recent-order-content > .makeStyles-root-142 {
  position: relative;
}

.recent-orders__loader {
  position: absolute;
  bottom: 5em;
  top: unset;
}

.rmdp-ym .rmdp-day {
  margin: 1em 0px !important;
}

.rmdp-ym {
  height: 3em !important;
}

.rmdp-month-picker {
  min-height: 100% !important;
  max-height: 100% !important;
  height: 14.5em !important;
}
.rmdp-day.rmdp-disabled{
  pointer-events: none !important;
}
.rmdp-header + div {
  height: 14.5em !important;
}
.rmdp-month-picker{
  height: 14.5em !important;
}
.rmdp-wrapper.rmdp-shadow,
.rmdp-ep-shadow:after {
  box-shadow: 0 0.5px 3px 0.5px rgb(0 0 0 / 15%) !important;
}

.past-orders__calendar-container .rmdp-selected-range-week, .past-orders__calendar-container .rmdp-selected-range-week:hover{
  border-radius: 20px;
  box-shadow: 0 0 3px #8798ad;
}
.past-orders__calendar-container .rmdp-range {
  box-shadow: none !important;
}

/** ENDOF Past Order Calander - Mobile */
@media screen and (min-width: 660px) {
  .recent-order {
    border-radius: 8px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .recent-order__current-order {
    padding-left: 20px;
    padding-right: 20px;
  }

  .recent-order__loading {
    padding-top: 25%;
  }

  .recent-order__footer-completed-current-order {
    width: 50%;
  }

  .recent-order__item {
    width: 50%;
  }

  .recent-order + .recent-order {
    margin-top: 0.5em;
  }

  .recent-order__reorder-button--filled {
    font-size: 0.9em;
    height: 40px;
  }

  .recent-order__tip-total-active-orders {
    width: 50%;
  }

  .recent-order__tip-total-completed-orders {
    width: 50%;
  }

  .recent-order__footer-completed-order-details {
    margin-bottom: 0px;
  }

  .recent-order {
    position: relative;
  }

  .recent-order__main {
    padding-bottom: 2.2em;
  }

  .recent-order__footer-past-orders {
    position: absolute;
    bottom: 0;
    right: 24px;
    min-width: 40%;
    max-width: 50%;
  }

  /** Past Order Calander - Desktop */
  .past-orders__calendar-label {
    width: 100%;
    display: flex;
  }
  .rmdp-container {
    width: 40%;
    margin-bottom: 0;
  }

  .recert-order__load-more-button {
    width: unset;
    padding-left: 4em;
    padding-right: 4em;
  }

  .rmdp-week:hover {
    background-color: transparent !important;
    border-radius: 6px;
    color: var(--color-primary-color-contrast);
  }

  .rmdp-week:hover > .rmdp-day {
    background-color: var(--color-primary) !important;
    color: var(--color-primary-color-contrast) !important;
  }

  .rmdp-week:hover > .rmdp-day:first-child {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .rmdp-week:hover > .rmdp-day:last-child {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
    background-color: transparent !important;
    color: var(--color-secondary) !important;
    border-radius: 6px;
  }

  .rmdp-day.rmdp-today.rmdp-selected > span {
    background-color: var(--color-primary) !important;
    border-radius: 12px;
  }

  .recent-orders__loader {
    position: absolute;
    bottom: 10em;
    top: unset;
  }

  .rmdp-header + div {
    height: 15em !important;
  }
}

.past-orders-container__no-active-orders {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
}

/** mobile only **/
@media screen and (max-width: 660px) {
  .rmdp-day span {
    font-size: 0.7rem !important;
    box-shadow: none !important;
  }
}

.order-status-content {
  background: linear-gradient(180deg, #eee, #eee 80%, #fff);
}

.order-status__header {
  padding-top: 10px;
  padding-bottom: 10px;
}

.order-status__header-wrapper {
  padding-left: 30px;
  padding-right: 30px;
}

.order-status__content-wrapper {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  margin-bottom: 0.2em;
}

.order-status__order-id {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
  font-size: 15px;
}

.order-status__order-info {
  border-bottom: 1px dashed #eee;
}

.order-status__order-id > span {
  display: flex;
  align-items: center;
}

.order-status__order-id > span > .icon--clock {
  margin-right: 5px;
  width: 13px;
  height: 13px;
  margin-bottom: 1px;
}

/** Order Details Section **/

.order-status__order-details-status-header {
  font-size: 1.25em;
  font-weight: bold;
  display: flex;
  margin-top: 10px;
  margin-bottom: 0px;
}

.order-status__order-details-confirm-label {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1.2rem;
}

.order-status__order-details-header {
  font-size: 1.25em;
  font-weight: bold;
}

.order-status__text-decoration {
  display: inline-flex;
  text-decoration: none;
}

.order-status__order-details-container {
  margin-top: 1em;
}

.order-status__order-details-button-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.order-status__order-details-button-wrapper > button {
  margin-right: 5px;
  color: var(--color-secondary);
  font-weight: 600;
  font-size: 17px;
}

.order-status__order-details-button-wrapper > .icon--arrow-right,
.order-status__order-id.order-status__collapsable-order-time-id > button > .icon--arrow-right {
  fill: var(--color-secondary);
  height: 13px;
  width: 13px;
  transform: rotate(0);
  transition: transform 0.25s ease-in;
}

.order-status__order-details-button-wrapper > .icon--arrow-right.is-down,
.order-status__order-id.order-status__collapsable-order-time-id
  > button
  > .icon--arrow-right.is-down {
  transform: rotate(90deg);
  transition: transform 0.25s ease-in;
}

.order-status__order-details-disclaimer {
  font-size: 0.8rem;
  border-bottom: 2px solid #e8e8e8;
  padding-bottom: 1.2em;
  display: block;
}

.order-status__order-details-phone-number {
  font-weight: bold;
}

.order-status__order-details {
  margin-bottom: 1em;
  overflow: hidden;
  height: 100%;
  transform: scaleY(1);
  transition: transform 5s ease-in;
}

.order-status__order-details-date-address {
  font-size: 16px;
  line-height: 1.5;
}

.order-status__order-details-date-address__header {
  font-weight: 600;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 0px;
}

.order-status__order-details-items-header,
.order-status__order-details-total {
  font-size: 16px;
  font-weight: 600;
}

.order-status__order-details-items-list {
  list-style-type: none;
  padding-left: 0;
}

.order-status__completed-order-reorder-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-status__completed-order-reorder-desktop-child {
  width: 100%;
  margin-top: auto;
}

.order-status__completed-order-reorder-desktop-child:nth-child(2) {
  width: 100%;
  margin-top: auto;
  display: inline-block;
  justify-content: right;
  text-align: -webkit-right;
}

.order-status__order-details-items-list-current-order {
  list-style-type: none;
  padding-top: 1.2em;
  padding-left: 0;
  border-top: 2px solid #e8e8e8;
}

.order-status__collapsable-container {
  padding-bottom: 30px;
}
.order-status__collapsable-order-time-id {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.order-status__content-wrapper.collapsable {
  padding-bottom: 0;
}

.order-status__collapsable-order-details {
  padding-bottom: 30px;
}

.order-status__collapsable-order-details .order-status__order-details-container,
.order-status__collapsable-order-details .order-status__order-details-items-list {
  margin-top: 0;
}

.order-status__collapsed-steps-wrapper {
  padding-top: 1em;
}

.order-status__last-update-timestamp {
  font-size: 12px;
}

/** END of Order Details Section **/

/** Mobile Only **/
@media screen and (max-width: 660px) {
  .order-status__content-wrapper {
    padding-right: 0;
    padding-left: 0;
  }

  .order-status__collapsable-order-time-status-wrapper,
  .order-status__order-details,
  .order-status__steps-wrapper {
    padding-left: 20px;
    padding-right: 30px;
  }
}

/** Desktop Layout **/

@media screen and (min-width: 660px) {
  .order-status__header-wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .order-status__content-main-wrapper {
    width: 100%;
    background-color: #fff;
  }

  .order-status__order-id {
    padding-top: 30px;
    margin: 0 auto;
  }

  .order-status__order-details-confirm-label {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 1.2rem;
  }

  .order-status__desktop-title {
    padding-top: 0.1em;
    padding-bottom: 0.5em;
    background-color: #f7f7f7;
    justify-content: center;
    display: flex;
    flex-direction: row;
  }
  .order-status__progress-bar-item:not(:last-child) {
    margin-right: 10%;
  }

  .order-status__progress-bar-item:first-child {
    min-width: unset;
  }

  .order-status__order-details-container {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .order-status__order-details-disclaimer {
    font-size: 14px;
  }
}

/** ENd of Desktop layout **/

.order-status__progress-bar {
  position: relative;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.order-status__progress-bar-item {
  position: relative;
  counter-increment: list;
  color: #ccc;
}

.order-status__progress-bar-item.is-done,
.order-status__progress-bar-item.current,
.order-status__progress-bar-item.is-rejected {
  color: #2a2a2a;
}

.order-status__progress-bar-item:not(:last-child) {
  padding-bottom: 20px;
  padding-top: 0;
  margin-bottom: 10px;
}

.order-status__progress-bar-item::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -33px;
  top: -25px;
  height: 20px;
  width: 10px;
}

.order-status__progress-bar-item:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -45px;
  width: 25px;
  height: 25px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: #ccc;
  transition: background-color 0.4s ease-out, border 0.4s ease-out;
}

.order-status__progress-bar-item:not(:first-child)::before {
  border-left: 2px solid #ccc;
}

.order-status__progress-bar-item.is-done:not(:first-child)::before {
  border-left: 2px solid #4caf52;
}

.order-status__progress-bar-item.is-rejected:not(:first-child)::before {
  border-left: 2px solid red;
}

.order-status__progress-bar-item.current:not(:first-child)::before {
  border-left: 2px solid var(--color-secondary);
}

.order-status__progress-bar-item.is-done::after {
  color: #fff;
  text-align: center;
  border: 2px solid #4caf52;
  background-color: #4caf52;
  transition: background-color 0.4s ease-in, border 0.4s ease-in;
}

.order-status__progress-bar-item.current::after {
  padding-top: 1px;
  width: 25px;
  height: 25px;
  top: 0;
  left: -45px;
  font-size: 14px;
  text-align: center;
  color: #4caf52;
  border: 2px solid var(--color-secondary);
  background-color: var(--color-secondary);
  transition: background-color 0.4s ease-out, border 0.4s ease-out;
  /*Animation*/
}

.order-status__progress-bar-item.current {
  font-size: 20px;
  font-weight: bold;
}

.order-status__progress-bar-animation-circle {
  background-color: var(--color-secondary);
  width: 29px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  left: -47px;
  top: -2px;
  z-index: 9;
  display: inline-block;
  content: "";
  opacity: 0.4;
  /*Animation*/
  animation: circleScaleAnimation 1s alternate infinite ease-in-out;
  animation-delay: 1s;
}

.order-status__progress-bar-item.is-done > .order-status__progress-bar-animation-circle-hidden {
  background-color: var(--color-secondary);
  width: 29px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  left: -45px;
  top: 0px;
  z-index: 9;
  display: none;
  content: "";
  opacity: 0.4;
  /*Animation*/
  animation: circleTransitionAnimation 0.6s 1 ease-in-out forwards;
}

/** checkMark / xMark animation **/

@keyframes checkMark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 3px;
    opacity: 1;
  }
  40% {
    height: var(12px);
    width: var(5px);
    opacity: 1;
  }
  100% {
    height: 12px;
    width: 5px;
    opacity: 1;
  }
}

.checkMark,
.xMark {
  width: 29px;
  position: absolute;
  height: 29px;
  top: -1px;
  left: -45px;
  z-index: 9;
}

.checkMark.draw:after {
  animation-duration: 500ms;
  animation-timing-function: ease;
  animation-name: checkMark;
  transform: scaleX(-1) rotate(135deg);
}

.checkMark::after {
  opacity: 1;
  transform-origin: left top;
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
  content: "";
  position: absolute;
  width: 5px;
  height: 12px;
  top: 15px;
  left: 7px;
}

.xMark {
  background-color: red;
  border-radius: 50%;
  transition: background-color 0.4s ease-out;
}

.xMark:before,
.xMark:after {
  position: absolute;
  left: 13px;
  top: 6px;
  content: " ";
  height: 17px;
  width: 3px;
  background-color: #fff;
}
.xMark.draw:before {
  animation-duration: 500ms;
  animation-timing-function: ease;
  animation-name: xMarkRotateClockWise;
  transform: rotate(45deg);
}
.xMark.draw:after {
  animation-duration: 500ms;
  animation-timing-function: ease;
  animation-name: xMarkRotateCounterClockWise;
  transform: rotate(-45deg);
}

@keyframes xMarkRotateClockWise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes xMarkRotateCounterClockWise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-45deg);
  }
}

/** End of checkMark / xMark animation **/

/** Blue circle animation */
@keyframes circleScaleAnimation {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.5);
  }
}

/* Blue Circle to Green circle move down transition */
@keyframes circleTransitionAnimation {
  from {
    transform: translateY(0em);
    opacity: 0.4;
    background-color: var(--color-secondary);
    z-index: 9;
  }
  to {
    transform: translateY(3.7em);
    opacity: 0;
    background-color: #4caf52;
    z-index: unset;
  }
}

/**Desktop layout **/
@media screen and (min-width: 660px) {
  .order-status__progress-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 96%;
    padding-left: 0;
    margin-bottom: 0;
  }

  .order-status__progress-bar-item {
    padding-top: 40px !important;
    min-width: calc(100% / 11);
    text-align: center;
  }

  .order-status__progress-bar-item.is-done:not(:first-child)::before {
    border-left: none;
    border-bottom: 2px solid #4caf52;
  }

  .order-status__progress-bar-item.current:not(:first-child)::before {
    border-left: none;
    border-bottom: 2px solid var(--color-secondary);
  }

  .order-status__progress-bar-item.is-done:first-child::before {
    border: none;
  }

  .order-status__progress-bar-item:nth-child(2)::before {
    margin-left: 10px;
  }

  .order-status__progress-bar-item:not(:first-child)::before {
    border-left: none;
  }

  .order-status__progress-bar-item::before {
    left: -120%;
    top: -5px;
    height: 20px;
    width: 120%;
    border-left: none;
    border-bottom: 2px solid #ccc;
  }

  .order-status__progress-bar-item::after,
  .order-status__progress-bar-item.current::after,
  .checkMark {
    top: 0;
    left: 30%;
    height: 25px;
  }

  .xMark {
    left: 30%;
  }

  .order-status__progress-bar-animation-circle {
    left: 27.5%;
  }

  .order-status__order-details-container {
    background-color: #eaeaea;
    width: 85%;
    margin: 0 auto;
    padding: 2em;
    margin-top: 0;
  }

  .order-status__steps-wrapper {
    margin-top: 1em;
  }

  div.order-status__progress-bar-item.is-done {
    margin-bottom: 2em;
    margin-top: 1em;
  }
}

/** End of Desktop layout **/

.order-types__buttons-container {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50%;
}

.order-types__button-wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 50%;
  align-items: center;
  margin: 0 auto 1.5em;
  font-size: 1.3em;
  width: 175px;
  justify-content: space-between;
}
.order-types__icon-wrapper {
  padding: 1em;
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.order-types__button {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.order-types__button > span {
  margin-left: 10px;
}

.order-types__button:active > .order-types__icon-wrapper {
  background-color: var(--color-active-button-generic-background);
}

.order-types__buttons-container > .dashboard__header {
  padding-left: 0;
  padding-top: 0;
}
.order-types__buttons-container > .dashboard__header > h1 {
  font-size: 1.4em;
  margin-top: 0;
  font-weight: 500;
}

/** mobile only layout **/
@media screen and (max-width: 660px) {
  .order-types__button-wrapper {
    width: unset;
  }
  .order-types__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .order-types__button > span {
    margin-left: 0;
    margin-top: 10px;
  }

  .order-types__main-container .dashboard__header-container {
    padding: 0.7em 24px;
    margin-bottom: 1em;
    margin-top: 10px;
  }
}

/** Desktop layout **/
@media screen and (min-width: 660px) {
  .order-types__buttons-container {
    align-items: center;
    margin-top: 2em;
    padding: 2em 1em;
    width: 35%;
    border: 1px solid var(--color-gray-light);
    border-radius: 10px;
    background-color: #fff;
  }

  .order-types__main-container {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .order-types__icon-wrapper {
    width: 120px;
    height: 120px;
  }
}

@media only screen and (max-width: 850px) and (min-width: 660px) {
  .order-types__buttons-container {
    width: 65vw;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 880px) {
  .order-types__buttons-container {
    width: 50vw;
  }
}

.address-suggestion-list_wrapper {
  list-style-type: none;
  padding: 0;
  background-color: #fff;
  margin-top: 0;
  position: absolute;
  z-index: 1100;
  top: 2.5em;
  border: 1px solid var(--color-gray-light);
}

.address-suggestion-list_wrapper li {
  padding: 10px 5px;
  border-bottom: 1px solid var(--color-gray-light);
  display: flex;
  align-content: center;
  font-size: 13px;
  position: relative;
}

.address-suggestion-list_wrapper li:hover {
  background-color: var(--color-gray-light);
}

.address-suggestion-list-item-button {
  position: absolute;
  color: transparent;
  background-color: transparent;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.MuiInput-underline:after {
  border-bottom: 2px solid var(--color-primary) !important;
}

.form--confirm-address {
  padding: 24px 24px 100px 24px;
}

.delivery-address__saved-addresses-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.delivery-address__header-container {
  padding: 0px 24px;
}

.delivery-address__header-container > h4 {
  margin: 24px 0px 0px 0px;
}

.delivery-address__header-your-addresses {
  font-size: 1.17rem;
}
.delivery-address__header-your-addresses + p {
  font-size: 16px;
}
.confirm-address__google-maps-container {
  max-height: 30vh;
}

.confirm-address__google-maps-container-dialog-modal {
  max-height: 30vh;
  position: relative;
  display: flex;
  width: -webkit-fill-available;
  margin-left: -24px;
  margin-right: -24px;
  min-height: revert;
  bottom: 0px;
}

.confirm-address__unverified-address {
  font-size: 0.9rem;
  font-weight: 600;
}

.address-validation__entered-address-radio-container {
  display: flex;
  justify-content: space-between;
}

.MuiFormControl-root {
  display: flex !important;
}

.MuiFormControlLabel-root {
  margin-right: 0px !important;
}

.Mui-focused .icon--search {
  color: var(--color-primary) !important;
}

#google-map-autocomplete-address::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: 10px 10px;
}

@media screen and (min-width: 660px) {
  .delivery-address__form-container > .delivery-address__header-container {
    padding: 0;
  }

  .confirm-address__google-maps-container-dialog-modal #google-map {
    z-index: -1;
  }
}

.order-stores__main-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 57px;
}

.order-stores__address-map-container {
  padding: 24px;
  background-color: var(--color-gray-light);
  /*min-height: 250px;*/
  position: relative;
}

.order-stores__address-map-container-no-padding {
  padding: 0;
  min-height: unset;
}

.order-stores__address-map-container-no-padding > .desktop-container {
  padding-left: 0;
  padding-right: 0;
  max-width: unset;
}

.order-stores__address-header {
  font-weight: 500;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}

.order-stores__address-header + p {
  margin: 0.5em 1m;
}

.order-stores__address-location-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.order-stores__address-location-wrapper-border-bottom {
  border-bottom: 1px solid #d4d4d4;
}

.order-stores__address-wrapper {
  padding: 20px 0 10px 0;
}

.order-stores__use-location-wrapper {
  padding: 0 0 10px 0;
}

.order-stores__use-location-wrapper_delivery {
  padding-bottom: 20px;
}

.order-stores__address-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.order-stores__address-wrapper.order-stores__address-wrapper-absolute {
  width: 90%;
}

.order-stores__address-wrapper > button > .icon--search {
  width: 38px;
  height: 38px;
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  padding: 10px;
  background: white;
  margin-right: 10px;
}

.order-stores__address-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  padding-top: 10px;
  z-index: 1000;
}

.order-stores__remove-address-button {
  position: absolute;
  right: 0;
  top: 5px;
}

.order-stores__remove-address-button > .icon--x {
  width: 20px;
  height: 20px;
}

.order-stores__address-input-wrapper > span {
  color: #2a2a2a;
  font-size: 14px;
  margin-top: 10px;
  background-color: #ffffffba;
  display: inline;
  width: 100%;
  padding: 0.4em;
}

.order-stores__address-input-wrapper > input {
  width: 100%;
  min-height: 30px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #2a2a2a;
}

.order-stores__address-wrapper-absolute {
  position: absolute;
  z-index: 1004;
  transform: translate(10px, 10px);
}

.order-stores__use-location-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-left: -10px;
  border-bottom: none !important;
}

.order-stores__use-location-wrapper > button {
  white-space: normal;
  word-wrap: break-word;
}

.order-stores__use-location-wrapper > button > span {
  text-align: left;
}

.order-stores__use-location-wrapper-absolute {
  position: absolute;
  top: 70px;
  z-index: 1150;
  transform: translate(10px, 10px);
}

.order-stores__use-location-button {
  display: flex;
  align-items: center;
  position: relative;
}
.order-stores__use-location-button > .icon--target {
  width: 38px;
  height: 38px;
  padding: 10px;
  color: var(--color-secondary);
  margin-right: 5px;
}

.order-stores__use-location-button-inline {
  display: inline-flex;
  align-items: baseline;
  position: relative;
}

.order-stores__use-location-button-inline > .icon--target {
  position: absolute;
  top: -10px;
  left: -5px;
  background-color: transparent;
}

.order-stores__use-location-button-inline > span {
  margin-left: 30px;
}

.order-stores__communication-blurb-inline {
  line-height: 1.5;
}

.order-stores__filter-container {
  background-color: var(--color-gray-light);
}

.order-stores__filter-container > div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0;
}
.order-stores__filter-container > div > .MuiBox-root {
  width: 100%;
}

.order-stores__filter-container > div button {
  padding: 10px;
  width: 33%;
}
.order-stores__filter-container > div button:first-child {
  padding-left: 24px;
  text-align: left;
}

.order-stores__filter-container > div button:last-child {
  padding-right: 24px;
  text-align: right;
}

.order-stores__filter-container.isStadium > div button {
  width: 50%;
  text-align: center;
  padding: 0;
}

.order-stores__filter-container.isStadium > div {
  padding-left: 24px;
  padding-right: 24px;
}

.order-stores__delivery-filter-container {
  border-bottom: 1px solid #d4d4d4;
}

.order-stores__delivery-filter-container > div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 20px 24px 20px 24px;
}

.order-stores__filter-button.isActive {
  color: var(--color-online-order-settings-active-colour);
}

.order-stores__filter-button > span {
  text-transform: none;
}

.order-stores__filter-list-map-button {
  display: flex;
  align-items: center;
  align-content: center;
}

.order-stores__stores-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.order-stores__stores-container-stadium {
  padding-bottom: 57px;
}

.order-stores__stores-container-map {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 100%;
}

.order-stores__stores-container::-webkit-scrollbar {
  display: none;
}

/** Order Store info Card Styling **/
.order-stores__store-wrapper {
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.order-stores__store-wrapper:not(:first-child) {
  border-top: 1px solid var(--color-gray-light);
}

.order-stores__store-wrapper-dinein {
  padding: 0;
}

.order-stores__store-wrapper.order-stores__store-wrapper-dinein {
  min-height: unset;
}

.order-stores__store-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

.order-stores__store-column {
  display: flex;
  flex-direction: column;
  justify-self: center;
  max-width: 50%;
  min-width: 50%;
}

.order-stores__store-name {
  font-weight: 700;
}

.order-stores__store-address {
  color: #2a2a2a;
  font-size: 14px;
  margin-top: 0;
}

.order-stores__store-distance {
  color: var(--color-gray);
  font-size: 14px;
  margin-bottom: 3px;
}

.order-stores__store-closing {
  color: #2a2a2a;
  font-weight: 450;
  font-size: 14px;
}

.order-stores__store-hours-button,
.order-stores__store-direction-button {
  color: var(--color-secondary);
  font-weight: 500;
  font-size: 13px;
}

.order-stores__store-select-button.button--primary.button--primary-with-border {
  margin: 0 auto;
  width: unset;
  max-width: 100%;
  float: right;
  padding: 0.8em 1em;
  white-space: break-spaces;
}

.order-stores__store-info-wrapper {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1150;
}

.order-stores__store-info-header-container {
  width: 100%;
}
.order-stores__store-info-header-top {
  width: 100%;
  box-shadow: 0 2px 14px -2px rgb(0 0 0 / 77%);
  background-color: #fff;
  padding: 10px 20px;
  margin-top: 0;
  margin-bottom: 0;
}

.order-stores__store-info-header-top > h3 {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  margin: 0;
}

.order-stores__store-info-header-top > button {
  width: 40px;
  height: 40px;
}

.order-stores__store-info-header-bottom {
  margin: 0;
  width: 100%;
  background-color: var(--color-header-background-colour);
  padding: 10px 0;
  border: 1px solid var(--color-gray-light);
  border-left: none;
  border-right: none;
}

.order-stores__store-info-line {
  width: 100%;
  padding: 10px 24px;
  justify-content: flex-start;
}

.order-stores__store-info-line > span,
.order-stores__store-info-line > a {
  font-size: 14px;
  margin-left: 10px;
}

.order-stores__store-info-line__phone > a {
  color: var(--color-secondary);
  font-weight: 500;
}

.order-stores__store-info-line__hours {
  align-items: flex-start;
}

.order-stores__store-info-line__hours > tbody {
  margin-left: 10px;
  width: 100%;
  padding-right: 10px;
}

.order-stores__store-info-line__hours > tbody > tr {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.order-stores__store-info-line__actions > .order-stores__store-column {
  align-items: flex-start;
}

.order-stores__store-info-line__actions > .order-stores__store-column > span {
  font-size: 14px;
  margin-bottom: 5px;
}

.order-stores__store-info-line > .icon {
  width: 24px;
  height: 24px;
}
/** End of Order Store info Card Styling **/

.order-stores__communication-blurb {
  padding: 0 24px;
  font-size: 16px;
  line-height: 1.5;
}

.order-stores__store-info-inner-wrapper > .order-stores__store-info-header-container {
  max-width: 100%;
}

div > .button--primary-order-store-filter:first-child {
  margin-right: -40px;
}

.button--primary-order-store-filter {
  height: 35px;
}

.order-store__map-selected-store {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  animation: fadeIn 0.3s;
  z-index: 1000;
}

.order-stores__fav-button {
  position: relative;
  width: 24px;
  height: 24px;
}

.delivery-address-detail-container.flex__column {
  width: unset;
}

.delivery-address-detail-container.flex__column > p {
  margin: 0;
}

.delivery-address-detail-container.flex__column > p:first-child {
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
}

.delivery-address-detail-container.flex__column > p:last-child {
  margin-bottom: 5px;
}

/** Mobile only **/
@media screen and (max-width: 660px) {
  .order-store__map-selected-store {
    z-index: 1150;
  }
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {


  .order-stores__main-container {
    padding-bottom: 28px;
  }
  .order-stores__address-map-container {
    min-height: 30vh;
  }
  .order-stores__store-wrapper {
    padding: 20px 0px;
  }

  div > .button--primary-order-store-filter {
    padding: 0.5em 5em !important;
    width: unset;
    height: unset;
    min-width: unset;
  }

  .order-stores__filter-container > div button:last-child {
    text-align: center;
  }

  .order-stores__filter-container > div button:first-child {
    text-align: center;
  }

  .order-stores__delivery-filter-container > div {
    justify-content: flex-start;
  }

  .order-stores__stores-container-map {
    height: 100%;
  }

  .delivery_address__confirm-form-and-map {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 3em;
  }

  .delivery_address__confirm-form-and-map > .confirm-address__google-maps-container,
  .delivery_address__confirm-form-and-map > .form--confirm-address {
    width: 48%;
    height: 100%;
  }

  .delivery_address__confirm-form-and-map > .form--confirm-address.full-width {
    width: 100%;
  }

  .form--confirm-address {
    padding-left: 0;
    padding-right: 0;
  }

  .form--confirm-address,
  .form--confirm-address .form__field-wrapper--small:first-child {
    padding-top: 0;
    margin-top: 0;
    margin-left: 0;
  }
  .form--confirm-address .form__field-wrapper--small:nth-child(2) {
    margin-top: 0;
  }

  .order-stores__address-location-wrapper {
    padding: 0;
    padding-left: 24px;
    max-width: 1920px;
  }

  .order-stores__address-location-wrapper > div {
    margin-left: 24px;
    margin-right: 24px;
  }

  .order-stores__address-location-wrapper > div:nth-child(2) {
    border-bottom: 1px solid #d4d4d4;
    width: unset;
  }

  .order-stores__store-select-button.button--primary.button--primary-with-border {
    width: 60%;
    font-size: 15px;
  }

  .order-stores__store-info-wrapper {
    background-color: #00000094;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .order-stores__store-info-inner-wrapper {
    width: 42%;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
  }

  .order-stores__store-info-header-top {
    box-shadow: none;
    justify-content: unset;
    padding-top: 0;
  }

  .mobile-app-header-logo_wrapper {
    width: 20%;
  }

  .order-stores__store-row.x-button-row {
    padding: 10px 15px;
    justify-content: right;
  }

  .order-stores__store-info-line__hours > .order-stores__store-row {
    width: 100%;
    align-items: flex-start;
  }

  .order-stores__store-info-line__hours > .icon--clock {
    margin-top: 10px;
    margin-right: 10px;
  }
  .order-stores__store-column.hours-divider {
    width: 5%;
    display: inline-grid;
    margin-right: 20px;
  }

  .order-stores__store-column.hours-divider > hr {
    width: 1px;
    height: 100px;
    color: var(--color-gray-light);
    margin-right: 20px;
  }

  .order-stores__store-info-line > div {
    display: flex;
    align-items: center;
  }
  .order-stores__store-info-line > div > .icon--map-pin {
    margin-right: 10px;
  }

  .store-address-and-distance {
    justify-content: space-between;
  }

  .order-stores__store-info-line__actions {
    margin-bottom: 30px;
  }

  .order-stores__store-info-header-top > button {
    text-align: left;
  }

  .order-stores__store-info-line,
  .order-stores__store-info-header-top {
    padding-left: 50px;
    padding-right: 50px;
  }

  .order-stores__store-column.right-align {
    align-items: flex-end;
  }

  .order-stores__store-column.first-hours-column {
    border-right: 1px solid var(--color-gray-light);
    margin-right: 10px;
  }

  .order-stores__store-hours-button,
  .order-stores__store-direction-button {
    font-size: 15px;
  }

  .order-stores__address-wrapper {
    width: 50%;
  }

  .order-stores__address-wrapper.order-stores__address-wrapper-absolute {
    width: 35%;
  }
  .order-stores__communication-blurb {
    padding: 0;
  }

  .form--confirm-address .form__field-wrapper--checkbox {
    max-width: 50%;
    display: block;
  }
  .order-stores__use-location-button > .icon--target {
    padding-left: 0;
  }
}

@media screen and (min-width: 660px) and (max-width: 1080px) {
  .order-stores__store-info-inner-wrapper {
    width: 80%;
  }
}

@media screen and (min-width: 1081px) {
  .order-stores__store-info-inner-wrapper {
    width: 50%;
  }
}

.pay-bill__main-container {
  padding-left: 0;
  padding-right: 0;
}

.bill-items-group-container {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.bill__header-subtotal {
  font-size: 1.1em;
  font-weight: 600;
}

.bill__header-order-number {
  font-size: 14px;
}

.bill__header-details {
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 25px;
}

.bill__header-details > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.bill-payment__main-container > .bill__items_container {
  padding-bottom: 0;
}

.bill__items_container {
  padding-bottom: 5em;
}

.cartItem__wrapper > .billItem__checkbox {
  width: 10% !important;
}
.cartItem__wrapper > .billItem__infoWrapper {
  width: 90%;
  margin-top: 0;
  margin-left: 0px;
}

.collapsable-section__with-checkbox {
  position: relative;
  display: flex;
  align-items: center;
}

.billItemGroup__checkbox-wrapper {
  position: absolute;
  right: 19px;
}

.collapsable-section__label {
  padding-left: 30px;
}

.cart-summary__cart-column > .individual-login-button-container {
  display: none;
}

.cart-summary__container {
  align-items: baseline;
}

.billItem__checkbox > .icon--check,
.billItemGroup__checkbox-wrapper > .icon--check {
  width: 18px;
  height: 18px;
  padding: 3px;
  stroke: #fff;
  stroke-width: 3px;
  background-color: #4caf52;
  border-radius: 50%;
}

.billItemGroup__checkbox-wrapper > .icon--check {
  margin-right: 7px;
}

.bill__items_blurb {
  padding-left: 24px;
  padding-right: 24px;
}

.bill__last-update-timestamp__container {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bill__last-update-timestamp {
  font-size: 14px;
}

.bill__last-update-timestamp__container > button {
  display: flex;
  align-items: center;
  color: var(--color-secondary);
  font-weight: 600;
  margin-left: 10px;
}

.bill__last-update-timestamp__container > button > .icon--refresh {
  width: 13px;
  height: 13px;
  margin-right: 3px;
  color: var(--color-secondary);
  stroke-width: 3px;
}

.cartItem__wrapper.billItem__wrapper {
  align-items: flex-start;
  padding: 20px 25px 13px 25px;
  justify-content: flex-start;
}

#pay-one-bill:disabled {
  color: unset;
}

#pay-one-bill > .icon--minus {
  color: var(--color-button-text);
}

.payment-confirmation-container {
  width: 100%;
  height: 100%;
  color: #2a2a2a;
  padding: 0 5% 15em;
  overflow-y: scroll;
}

.payment-confirmation__image {
  margin: 35px 20px;
}

.payment-confirmation__checkmark {
  background: #bfd72f;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  animation: appear 1s forwards, blink 0.5s 0.75s forwards;
}

.payment-confirmation__checkmark > .icon {
  width: 12px;
  height: 12px;
  stroke: #fff;
}

.payment-confirmation__heading {
  opacity: 0;
  transform: translateY(100px);
  font-size: 1.25rem;
  font-weight: 600;
  animation: slide-in 0.5s forwards;
  animation-delay: 0.1s;
}

.payment-confirmation__thankyou-note {
  display: flex;
  justify-content: left;
  align-items: center;
}

@keyframes appear {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.payment-confirmation__button-wrapper {
  margin-top: 24px;
  padding-top: 24px;
}

.form--seat-row-section {
  padding: 24px 24px 100px 24px;
}

.form--seat-row-section__floating-button {
  bottom: 57px;
  padding-bottom: 17px;
}

.stadium-delivery-address {
  font-weight: normal !important;
}

.seat-row-section__error-container > p {
  display: flex;
  justify-content: center;
}

.seat-row-section__error-container > p > .icon--triangle-alert {
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

@media screen and (min-width: 660px) {
  .form--seat-row-section {
    width: 50%;
  }

  .seat-section__form-container .delivery-address__header-container,
  .seat-section__form-container .form--seat-row-section {
    padding-left: 0;
  }
}

.goBackSuggestedItemButton{
  display: flex;
  align-items: center;
 
}
.goBackSuggestedItemButtonContainer{
  background-color: #fff;
  width: 100%;
}
.goBackSuggestedItemButton > svg {
  margin-right: 0.25em;
  margin-top: 0.5px;
}
.suggested-item-details__desktop-close-button-container{
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 78px;
}
.suggested-item-details__closeAndFavoritesButtonContainer{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-top: 0;
  padding-bottom: 14px;
}
@media screen and (max-width: 660px) {
  .goBackSuggestedItemButtonContainer{
    margin: -8px 1em 1.5em 0;
    margin-left: 0px;
  }
  .goBackSuggestedItemButtonMobile{
    min-height: 30px;
    position: fixed;
    margin-top: 0;
    z-index: 9999999;
    padding: 1em 1em;
    padding-top: 1.38em;
    padding-left: 1.37em;
  }
  .paddingForBackToMenuItemButton{
    margin-top: 40px;
  }
  .suggestedItemDetail-main__Wrapper .item-detail__content.isMinimized.isCollapsed{
    max-height: 235px !important;
  }
}
@media screen and (min-width: 661px) {
  .suggestedItemDetail-main__Wrapper .item-detail__content.isMinimized {
    top: 78px !important;
  }
}
.redeem-order-confirm__heading {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.redeem-rewards-slider {
  position: relative;
  display: flex;
  margin: 13px 0 -39px -34px;
  padding: 11px 0 39px calc(34px + 24px);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  list-style-type: none;
}

.redeem-rewards-slider__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 275px;
  padding-top: 24px;
  padding-left: 0;
  scroll-snap-align: center;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s cubic-bezier(0.19, 1.24, 0.26, 1);
}

.redeem-rewards-slider__item-spacing {
  width: 1px;
  height: 1px;
  margin-left: 7.5%;
}

.redeem-rewards-slider__item + .redeem-rewards-slider__item {
  margin-left: 24px;
}

.redeem-rewards-slider-item__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.redeem-rewards-slider-item__image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  margin-bottom: 18px;
  overflow: hidden;
}

.redeem-rewards-slider-item__text-wrapper {
  margin-bottom: 24px;
  padding: 0 24px;
  text-align: center;
}

.redeem-rewards-slider-item__price-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.redeem-rewards-slider-item__price {
  margin-right: 4px;
  color: #2a2a2a;
  font-size: 1.8rem;
  font-weight: 600;
}

.redeem-rewards-slider-item-redeemable .redeem-rewards-slider-item__price {
  color: #f49c00;
}

.redeem-rewards-slider-item__price + .icon--star {
  margin-right: -22px;
}

.redeem-rewards-slider-item__price-wrapper > .icon--star {
  width: 44px;
  height: 44px;
  fill: #2a2a2a;
  stroke: none;
}

.redeem-rewards-slider-item-redeemable .redeem-rewards-slider-item__price-wrapper > .icon--star {
  fill: #f49c00;
}

.redeem-rewards-slider-item__name {
  width: 180px;
  height: 1.2em;
  margin: 0 auto 12px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.redeem-rewards-slider-item__description {
  margin: 0 auto;
  font-size: 15px;
  text-align: center;
}

.redeem-rewards-slider-item__need-more-points {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px 12px 24px;
  background-color: var(--color-secondary);
}

.redeem-rewards-slider-item__need-more-points > .icon--lock {
  width: 18px;
  height: 18px;
  margin-bottom: 4px;
  color: #fff;
}

.redeem-rewards-slider-item__need-more-points-message {
  margin: 0;
  color: #fff;
  font-size: 15px;
  text-align: center;
}


/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .my-rewards__redeem-rewards-section{
    padding-bottom: 10em !important;
  }

}

/** End Of Desktop Layout **/
.rewards-module__toggle-reward-name-button > .icon--star {
  width: 1em;
  height: 1em;
}

.rewards-module__step-value{
    font-size: 0.8em;
}

.rewards-module__step-name{
    margin-top: 10px;
}

.rewards-module__stepper-wrapper{
    height: 4.5em;
}
.how-rewards-work-main-content {
  padding: 24px 24px 105px;
}

.how-rewards-work__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.how-rewards-work__section:nth-child(even) {
  flex-direction: row-reverse;
}

.how-rewards-work__section + .how-rewards-work__section {
  margin-top: 36px;
}

.how-rewards-work-section__icon-wrapper {
  min-width: 20%;
  width: 20%;
  margin-right: 5%;
}

.how-rewards-work-section__icon-wrapper > svg,
.how-rewards-work-section__icon-wrapper > img {
  max-width: 100%;
  height: auto;
}

.how-rewards-work-section__text-wrapper {
  flex-grow: 1;
}

.how-rewards-work__section p {
  margin: 0;
  max-width: 75ch;
}

.how-rewards-work__section p + * {
  margin-top: 4px;
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .how-rewards-work__section + .how-rewards-work__section {
    margin-top: 1.5em;
  }

  .how-rewards-work-main-content {
    padding: 2em 0 4em;
  }
}

@media screen and (min-width: 1025px) {
  .how-rewards-work-section__icon-wrapper {
    min-width: 5%;
    width: 5%;
  }
}
@media screen and (min-width: 660px) and (max-width: 1024px) {
  .how-rewards-work-section__icon-wrapper {
    min-width: 10%;
    width: 10%;
  }
}
/** End of Dektop Layout **/

.rewards-main-content {
  padding: 24px 24px 85px;
}

.rewards-section + .rewards-section {
  margin-top: 24px;
}

.rewards-section__heading {
  margin: 0 0 12px;
  font-size: 1.25rem;
}

.rewards-section__subheading {
  margin: 0 0 8px;
  font-size: 1rem;
  font-weight: 600;
}

.rewards-section p {
  margin: 0 0 12px;
}

.rewards-section p a {
  color: #0086ff;
  font-weight: 600;
}

.add-new-loyalty-card-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 36px;
}

.add-new-loyalty-card-button {
  padding: 10px 18px;
  font-size: 13px;
  line-height: 100%;
  border: 1.2px solid var(--color-primary);
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  color: var(--color-button-text);
  font-weight: 550;
}

.loyalty-guest-landing__register-link {
  width: auto;
  min-width: 180px;
  max-width: 350px;
  margin-right: 1em;
  text-wrap: wrap;
}

@media screen and (max-width: 660px) {
  .rewards__login-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
  }
  .rewards__login-buttons > button {
    margin: 0 !important;
    min-width: 48%;
    max-width: 48%;
  }
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .rewards-main-content {
    padding-top: 2em;
    padding-bottom: 4em;
  }
}

/** End of Dektop Layout **/

.account-form-modal-container {
  width: 100%;
}

.account-form-modal-container .modal__heading {
  margin-bottom: 44px;
  font-size: 1.7rem;
  text-align: center;
}

.account-main-content {
  padding-bottom: 85px;
}

.account-section {
  padding: 0;
}

.account-section--email-and-logout {
  margin-bottom: 18px;
  padding-top: 18px;
  padding-bottom: 18px;
  background-color: #f9f9f9;
}

.account-section:nth-child(2) + .account-section,
.account-section:nth-child(3) + .account-section {
  margin-top: 18px;
}

.account-section__email {
  margin-bottom: 0;
  font-weight: 600;
}

.account-section__logout-button {
  height: 36px;
}

.account-section__heading {
  margin: 0 0 18px;
  font-size: 1.25em;
}

.account-section__editable-items-list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.account-section__editable-items-list > .navListItemDivider {
  margin-top: 8px;
}

.account-section__editable-item + .account-section__editable-item {
  margin-top: 8px;
}

.account-section__edit-item-button,
.account-section__edit-item-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 36px;
}

.account-section__payment-methods-counter {
  margin-left: 4px;
}

.account-section__edit-item-button > .icon--arrow-right,
.account-section__edit-item-link > .icon--arrow-right {
  width: 12px;
  height: 12px;
}

.modal-wrapper--account-update {
  background-color: #fff;
  z-index: 1151;
}

.modal-wrapper--account-update > .modal--fullscreen {
  padding-bottom: 125px;
}

.account-form-modal-container
  .form__field-wrapper--checkbox-radio
  + .form__field-wrapper--checkbox-radio {
  margin-top: 10px;
}

/* .account-form-modal-container .form__field-wrapper--in-focus > .form__field-label {
  transform: none;
} */

.account-form-modal-container .form__field {
  height: 42px;
  font-size: 1rem;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #dedede;
}

.account-form-modal-container .form__field:focus {
  border-color: var(--color-secondary);
}

.account-form-modal-container .form__field::-moz-placeholder {
  color: #aaa;
}

.account-form-modal-container .form__field::placeholder {
  color: #aaa;
}

.account-form-modal-container .form__field:focus::-moz-placeholder {
  color: #aaa;
  -moz-transition: none;
  transition: none;
}

.account-form-modal-container .form__field:focus::placeholder {
  color: #aaa;
  transition: none;
}

.account-form-modal-container .form__field + .icon--arrow-down {
  top: auto;
  bottom: 6px;
  right: -6px;
  width: 25px;
  height: 25px;
}

.account-form-modal-container .current-field-value {
  border-bottom: none;
}

/* TEMP: delete once link destinations are set up */
.account-section__recent-loyalty-transactions-link,
.account-section__recent-card-transactions-link {
  pointer-events: none;
}

/** Mobile only **/
@media screen and (max-width: 660px) {
  .account-form-modal-container {
    padding-bottom: 5em;
  }

  .account-deletion-submit-button-wrapper {
    margin-top: 4em;
  }

  .account-deletion-back-to-home > a {
    width: unset;
    min-width: unset;
    padding: 1em 5em;
  }

  .account-deletion-back-to-home {
    margin-top: 6em;
    display: flex;
    justify-content: center;
  }

  .account-deletion-main-content,
  .account-deletion-confirmation-main-content {
    margin-top: 1em;
  }
}

.request_account_deletion {
  color: var(--color-error);
}

.giftCardRecentOrdersNavListItem {
  border-top: 1px solid #e0e0e0;
  width: 100%;
  padding-top: 0.25rem;
}

@media screen and (max-width: 660px) {
  .giftCardRecentOrdersNavListItem {
    border: none;
    padding-top: 0;
  }
}

.navListItemDivider {
  border-top: 1px solid #e0e0e0;
  width: 100%;
  padding-top: 0.25rem;
  margin-top: 0.25rem;
}
/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .account-info__page-title,
  .account-info__change-password-page-title {
    background-color: #f7f7f7;
  }

  .account-info__page-title,
  .account-info__change-password-page-title {
    padding-top: 0.1em;
    padding-bottom: 0.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .account-info__change-password-page-title > div {
    margin: 1em 0;
  }

  .account-info__change-password-title {
    font-size: 1.2em;
  }

  /*.account-info__change-password-main-container {
    margin-right: 10%;
    margin-left: 10%;
    padding-bottom: 10em;
  }*/

  .form.form--account-update,
  .form--account-change-password {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 5em;
  }

  .form--account-update .form__field-wrapper,
  .form--account-change-password .form__field-wrapper {
    flex: 0 1 50%;
  }

  .form--account-update .form__field-wrapper:not(.form__field-wrapper--checkbox-radio),
  .form--account-change-password .form__field-wrapper:not(.form__field-wrapper--checkbox-radio) {
    margin-top: 3em !important;
  }

  .form--account-update
    .form__field-wrapper:nth-child(odd):not(.form__field-wrapper--checkbox-radio),
  .form--account-change-password
    .form__field-wrapper:nth-child(odd):not(.form__field-wrapper--checkbox-radio) {
    padding-right: 5em;
  }

  .form--account-update .form__field-wrapper:nth-child(odd) .icon--arrow-down {
    right: 72px;
  }

  .form--account-update
    .form__field-wrapper:nth-child(even):not(.form__field-wrapper--checkbox-radio),
  .form--account-change-password
    .form__field-wrapper:nth-child(even):not(.form__field-wrapper--checkbox-radio) {
    padding-left: 5em;
  }

  .form--account-update .form-submit__wrapper,
  .form--account-change-password .form-submit__wrapper {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 25% 3em 25%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0.23573179271708689) 100%
    );
  }

  .delete-account-desktop-title {
    padding-top: 0.1em;
    padding-bottom: 0.5em;
    background-color: #f7f7f7;
    justify-content: center;
    display: flex;
    flex-direction: row;
  }

  .account-deletion-submit-button-wrapper {
    display: flex;
    margin-top: 4em;
  }

  .account-deletion-submit-button-wrapper > .form__error {
    display: flex;
    margin-left: 5em;
  }

  .account-deletion-back-to-home > a {
    width: unset;
    min-width: unset;
    padding: 1em 5em;
  }

  .account-deletion-back-to-home {
    margin-top: 6em;
    display: flex;
    justify-content: flex-start;
  }

  .account-deletion-title {
    font-size: 1.3em;
  }
  .account-deletion-subtitle {
    font-size: 1.5em;
  }

  .account-deletion-confirmation-main-content > .desktop-container,
  .account-deletion-main-content > .desktop-container {
    margin-top: 2em;
  }
  .navListItemDivider li {
    text-align: left;
  }
}

.change-password__floating-button,
.update_account_info__floating-button {
  bottom: 57px;
  padding-bottom: 17px;
}

.account__delivery-address-header {
  font-size: 1.5rem;
  padding: 0px 24px;
  margin-bottom: 0;
}

@media screen and (max-width: 660px) {
  .account-info__page-title,
  .account-info__change-password-page-title {
    background-color: #f7f7f7;
    margin-bottom: 1em;
  }

  .account-info__page-title > div,
  .account-info__change-password-page-title > div {
    margin: 2em 10%;
  }

  .account-info__main-container,
  .account-info__change-password-main-container {
    margin-right: 10%;
    margin-left: 10%;
    padding-bottom: 10em;
  }

  .desktop-header-container {
    padding-right: 5%;
    padding-left: 5%;
  }

  .account-info__form-wrapper {
    padding: 0 2em;
  }

  .form--account-update .form-submit__wrapper,
  .form--account-change-password .form-submit__wrapper {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 25% 5em 25%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0.23573179271708689) 100%
    );
    display: none;
  }

  #form--change-password .form-submit__wrapper {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }

  #form--update-account-details .form-submit__wrapper {
    display: block;
    padding-left: 0;
    padding-right: 0;
    position: sticky;
    padding-bottom: 0;
    z-index: 999999;
  }

  .account-form-update-info-modal-container {
    position: relative;
    padding-bottom: 7em;
  }

  #form--change-password .button--submit-form-wrapper > button,
  #form--update-account-details .button--submit-form-wrapper > button {
    position: relative;
    display: block;
    width: 70%;
    height: 52px;
    margin: 0 auto;
    padding: 0 15px;
    color: var(--color-button-text);
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    background-color: var(--color-primary);
    border-radius: 9999px;
    z-index: 1;
  }
}

@media screen and (min-width: 660px) {
  .account__delivery-address-header {
    max-width: 1200px;
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
  }
  .giftCardRecentOrdersNavListItem {
    padding-top: 0;
  }
}

/** End of Desktop Layout **/

.add-credit-card-main-content {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #fff;
}

.add-credit-card-form__security-message-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.add-credit-card-form__security-message-wrapper > svg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.add-credit-card-form__security-message {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}

/* TODO: fix the common button components, then remove this override */
#form--add-credit-card__submit-button {
  display: block;
  margin: 0 auto;
}

.payment-methods-main-content {
  background-color: #f5f5f5;
}

.payment-method-section svg {
  width: 21px;
  height: 21px;
  margin-right: 5px;
}

.payment-method-section__header {
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
  padding: 1em 30px;
}

.payment-method-section__header-gift-card {
  margin-bottom: 0;
}

.payment-method__gc-related-buttons-container,
.payment-method__cc-related-buttons-container {
  padding: 0 30px 1em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.payment-method-section__transfer-funds {
  margin-top: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.payment-method-section__row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 40px;
}

.payment-method-section__column {
  flex-direction: column;
}

.payment-method-section__main-giftcard {
  background-color: unset;
  padding: 0;
}

.payment-method-section__row.payment-methods__stored-gc {
  background-color: white;
  margin-bottom: 5px;
  padding: 1em 30px;
  flex-direction: column;
}

.payment-method-section__details-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: baseline;
}

.payment-method-section__details-gc-buttons {
  display: flex;
  width: 100%;
  margin-top: 0.5em;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.payment-method-section__details-gc-buttons > button:first-child {
  width: 100%;
  margin: 1em 0;
}

.payment-method-section__details-wallet-link-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0.4em;
}
.payment-method-section__details-wallet-link {
  width: 100%;
  display: flex;
  justify-content: center;
}

.icon.icon--google-wallet-en,
.icon.icon--google-wallet-fr,
.icon.icon--google-wallet-pt {
  width: 100%;
  height: unset;
  margin: 0;
}

.icon.icon--apple-wallet-en,
.icon.icon--apple-wallet-fr,
.icon.icon--apple-wallet-pt {
  width: 45%;
  height: unset;
  margin: 0;
}

.payment-method-section__details-gc-buttons > button:not(:first-child) {
  display: flex;
  align-items: center;
  color: var(--color-secondary);
}

.payment-method-section__details-gc-buttons > button:last-child > .icon--x-in-circle {
  color: var(--color-secondary);
}

.payment-method-section__heading {
  margin: 0;
  font-size: 17px;
  font-weight: 600;
}

.payment-method-section__icon-and-number-wrapper {
  display: flex;
  align-items: center;
}

.payment-method-section__number,
.payment-method-section__add-new,
.payment-method-section__balance {
  font-size: 15px;
  font-weight: 400;
  color: #2a2a2a;
}

.payment-method-section__balance {
  margin-top: 0.2em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.payment-method-section__balance > span > .icon--gold-star {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  margin-left: 3px;
}

.payment-method-section__balance > span:last-child:not(:first-child) {
  margin-left: 15px;
}

.payment-method-section__icon-and-number-wrapper {
  margin-bottom: 0.5em;
}

.payment-method-section__icon-and-number-wrapper > .icon--check {
  width: 18px;
  height: 18px;
  padding: 3px;
  stroke: #fff;
  stroke-width: 3px;
  background-color: #4caf52;
  border-radius: 50%;
}

.payment-method-section__add-new {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.payment-method-section__add-new + * {
  margin-top: 15px;
}

.payment-method-section__add-new > .icon--plus-circle,
.payment-method-section__transfer-funds > .icon--plus-circle {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.payment-method__cc-related-buttons-container + hr {
  border-color: #dedede;
  border-style: solid;
}

.payment-method-section__gift-cards {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.payment-method-section__name-and-expiry-wrapper {
  padding-left: 23px;
  color: #676767;
  font-size: 12px;
}

.payment-methods__remove-button {
  font-size: 14px;
  color: var(--color-secondary);
}

.payment-methods__stored-cc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 30px;
  background-color: white;
}

.payment-methods__stored-cc + hr {
  border-color: #e8e4e4;
  border-style: solid;
}

.payment-method__gc-transfer-funds-note {
  margin-top: 0;
}

.payment-methods__stored-cc-empty {
  padding: 1em 0 0 0;
  background-color: transparent;
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .payment-method-section__number {
    margin-bottom: 0;
  }

  .payment-methods-main-content .payment-methods-container {
    padding: 0;
  }

  .payment-methods-desktop-title {
    padding-top: 0.1em;
    padding-bottom: 0.5em;
    background-color: #f7f7f7;
    justify-content: center;
    display: flex;
    flex-direction: row;
  }

  .payment-methods__stored-cc > .payment-method-section__details {
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.12);
  }

  .payment-method-section__details {
    width: 48%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .payment-methods__stored-cc > .payment-method-section__details {
    padding: 1.5em;
  }

  .payment-method-section__main {
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
  }

  .payment-method-section__icon-and-number-wrapper {
    margin-bottom: 10px;
  }

  .payment-method-section__gift-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0;
  }

  .payment-method-section__row.payment-methods__stored-gc {
    flex: 0 1 48%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.12);
    padding: 1.5em;
  }

  .payment-methods__stored-gc > .payment-method-section__details {
    width: 100%;
  }

  .payment-method-section__row {
    margin-top: 15px;
  }

  .payment-methods-main-content {
    padding-bottom: 10em;
    padding-right: 0;
    padding-left: 0;
    background-color: #fff;
  }

  .payment-method-section__header {
    padding-right: 0;
    padding-left: 0;
  }

  .payment-method-section__main {
    padding-right: 0;
    padding-left: 0;
  }

  .payment-method__gc-related-buttons-container {
    padding-right: 0;
    padding-left: 0;
    justify-content: start;
    align-items: flex-start;
  }

  .payment-method__gc-related-buttons-container > a {
    margin-right: 5em;
  }

  .payment-method-section__details-wrapper > .payment-method-section__details {
    flex-direction: column;
    align-items: baseline;
    width: 100%;
  }

  .payment-method-section__name-and-expiry-wrapper {
    padding-left: 0;
  }

  .payment-method__cc-related-buttons-container {
    padding: 0;
  }

  .payment-methods__stored-cc + hr {
    border-color: #f3f3f3;
    border-style: solid;
    margin-top: 1em;
  }

  .payment-method__gc-related-buttons-container.payment-method-section__row {
    margin-top: 0;
  }

  .payment-method-section__details-gc-buttons {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .payment-method-section__details-gc-buttons > button:first-child {
    width: 40%;
    margin-right: 0 !important;
  }
}

/** End of Desktop Layout **/

.addMoney__container {
  width: 100%;
  overflow: auto;
  background-color: white;
  position: absolute;
  height: 100%;
  z-index: 1100;
}

.addMoney__label {
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.addMoney__cvv-label {
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: -25px;
}

.addMoney__auto-reload-container > span {
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
}

.addMoney__from-wrapper {
  padding: 7% 7%;
}

.addMoney__from-wrapper > .form__field-wrapper:first-child {
  margin-top: 0;
}

.addMoney__input-field {
  width: 100%;
  padding: 18px 18px 7px;
  color: #2a2a2a;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0 5px 5px 0;
  background-clip: padding-box;
}

#method-of-payment__select,
.addMoney__input-field,
#reload-frequency__select,
#gift-card__select {
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;
}

.money-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  height: 40px;
  text-align: center;
  background: #d0d0d0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.input-container {
  /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.addMoney__auto-reload-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.add-money__credit-card-form {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1100;
  overflow: scroll;
}

.addMoney__stored-cc-details-container {
  display: flex;
}

.addMoney__cvv-container {
  margin-left: 10%;
}

.addMoney__cvv-input {
  margin-top: 25px;
  width: 100%;
  color: #2a2a2a;
  line-height: normal;
  background-color: #fff;
  background-clip: padding-box; /* removes iOS shadow */
  height: 35px;
  padding: 10px 12px;
  font-size: 1rem;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #dedede;
}

.addMoney__cvv-input-wrapper {
  display: inline-block;
  margin-top: -1.6em;
}

.addMoney__cvv-input-label {
  top: 0;
  bottom: 0;
  left: 18px;
  display: flex;
  align-items: center;
  line-height: normal;
  pointer-events: none;
  transition: font-size 0.3s, transform 0.3s;
  position: static;
  margin: 0 0 7%;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
}

.addMoney__stored-cc-container {
  margin-right: 10%;
}

.addMoney__title {
  margin: 1em 0;
}

.addMoney__stored-cc,
.addMoney__add-new-cc {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.addMoney__stored-cc > span,
.addMoney__add-new-cc > span {
  margin-left: 10px;
  font-weight: 500;
  font-size: 15px;
}

.addMoney__stored-cc > .icon--check {
  stroke: #fff;
  stroke-width: 3px;
  width: 18px;
  height: 18px;
  padding: 3px;
  background-color: #4caf52;
  border-radius: 100%;
}

.addMoney__stored-cc-expiry {
  padding-left: 30px;
  color: #676767;
  font-size: 12px;
}

.addMoney__add-new-cc > .icon--plus-circle {
  width: 25px;
  height: 25px;
  padding: 3px;
  stroke-width: 3px;
}

.addMoney__auto-reload-elements {
  position: relative;
  animation-name: moveupReloadElements;
  animation-duration: 0.5s;
}

@keyframes moveupReloadElements {
  from {
    bottom: -200px;
  }

  to {
    bottom: 0px;
  }
}

.fadeOutContainer {
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms, visibility 500ms;
}

.fadeInContainer {
  visibility: visible;
  opacity: 1;
  transition: opacity 500ms, visibility 500ms;
}

.slideUpContainer {
  transform: translateY(-70px);
  -webkit-transform: translateY(-70px);
  transition: all 600ms;
}

.slideDownContainer {
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  transition: all 600ms;
}

/** Slider Style **/
.switch {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #bfd72f;
}

input:focus + .slider {
  box-shadow: 0 0 1px #bfd72f;
}

input:checked + .slider:before {
  transform: translateX(66px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/** End Of Slider Style **/

.addMoney__manual-reload-disbaled {
  visibility: hidden;
}

.addMoney__manual-reload-disbaled > * {
  display: none;
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .addMoney__container {
    z-index: 0;
  }

  .addMoney__from-wrapper {
    padding: 0% 7% 7%;
  }
}
/** End of Desktop Layout **/

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .addMoney__from-wrapper {
    padding: 0% 7% 7%;
    max-width: 100vw;
    margin: 0;
  }

  .FAB-wrapper.FAB-wrapper__addMoney {
    max-width: 100vw;
  }

  .FAB-wrapper.FAB-wrapper__addMoney .desktop-container {
    display: flex;
    justify-content: flex-end;
  }

  .FAB-wrapper.FAB-wrapper__addMoney .desktop-container > button {
    margin: 0;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .addMoney__from-wrapper {
    padding: 0% 7% 7%;
    max-width: 80vw;
    margin: 0;
  }

  .FAB-wrapper.FAB-wrapper__addMoney {
    max-width: 80vw;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .addMoney__from-wrapper {
    padding: 0% 7% 7%;
    max-width: 50vw;
    margin: 0;
  }

  .FAB-wrapper.FAB-wrapper__addMoney {
    max-width: 50vw;
  }
}

/** Mobile Devices Layout **/
@media screen and (max-width: 439px) {
  .addMoney__stored-cc,
  .addMoney__add-new-cc {
    margin-top: 0;
  }
}

/** End of Mobile Devices Layout **/

.transfer-funds__container {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #fff;
}

.transfer-funds__form_wrapper {
  padding: 3em 30px 1em 30px;
  height: 90%;
  overflow: auto;
}

.transfer-funds__success-message-reward-detail {
  margin: 0;
}

.transfer-funds__success-message-header-wrapper {
  display: flex;
  align-content: baseline;
  margin-bottom: 1em;
}

.transfer-funds__success-message-header-wrapper > div:first-child {
  display: flex;
  align-items: center;
}

.transfer-funds__success-message-header > span > .icon--check {
  background-color: #4caf52;
  border-radius: 50%;
  stroke: white;
  stroke-width: 4px;
  padding: 3px;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.transfer-funds__success-message-header-wrapper + .transfer-funds__success-message-reward-detail {
  margin-bottom: 8px;
}
.transfer-funds__success-message-reward-transaction {
  font-size: 14px;
}

.transfer-funds__success-message-header {
  font-weight: 700;
  font-size: 1.1em;
  margin: 0;
}

.transfer-funds__check-balance-button-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 2em 0;
  align-items: baseline;
}

.transfer-funds__check-balance-button-wrapper > div {
  width: 50%;
  flex-direction: row;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.transfer-funds__check-balance-button-wrapper > div > span > svg {
  top: 3px;
}

.transfer-funds__check-balance-button-wrapper > button {
  min-width: 45%;
  width: unset;
}

.form--transfer-funds {
  height: 100%;
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .transfer-funds__success-message-header > span > .icon--check {
    width: 24px;
    height: 24px;
  }

  .transfer-funds__success-message-reward-detail {
    font-size: 0.95em;
  }

  .transfer-funds__form_wrapper {
    padding-right: 5%;
    padding-left: 5%;
    display: flex;
    width: 65%;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }

  .transfer-funds__check-balance-button-wrapper > div {
    width: 28%;
  }
}

@media screen and (min-width: 1280px) {
  .transfer-funds__form_wrapper {
    padding-right: 10%;
    padding-left: 10%;
  }
}

/** End of Desktop Layout **/

/** Mobile Layout **/
@media screen and (max-width: 660px) {
  .transfer-funds__form_wrapper > form > .form-submit__wrapper {
    padding-bottom: 8em;
  }
}
/** End of Mobile Layout **/

/* TODO: organize this better and fix class names to follow BEM */

.scan-in-store-main-content {
  padding: 1em 0 calc(57px + 45px);
  background-color: #f5f5f5;
}

.scan-in-store__active-card-info-and-qr-code-info {
  padding-left: 24px;
  padding-right: 24px;
}

.scan-in-store__active-card-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5em;
}

.scan-in-store-active-card-info__points-and-balance {
  margin-right: 1em;
}

.scan-in-store-active-card-info__points-and-star {
  display: flex;
  align-items: center;
}

.scan-in-store-active-card-info__points {
  margin-right: 4px;
  font-size: 1.5rem;
  font-weight: 500;
}

.scan-in-store-active-card-info__points-and-star > .icon--star {
  stroke: none;
  fill: #f49c00;
  width: 28px;
  height: 28px;
}

.scan-in-store-active-card-info__balance {
  font-size: 14px;
  font-weight: 500;
}

.pay-in-store__select-giftcard-wrapper {
  position: relative;
}

.pay-in-store__select-giftcard {
  height: 40px;
  padding-right: 21px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background-color: transparent;
}

.pay-in-store__select-giftcard:disabled {
  opacity: 1;
}

.pay-in-store__select-giftcard-wrapper > .icon--arrow-down {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.icon--gold-star {
  position: relative;
  top: 2px;
  left: 5px;
  width: 20px;
  height: 20px;
  fill: #333;
}

.payInStore__selectedCard-card-container {
  margin-bottom: 2em;
  background-color: #fff;
}

.payInStore__selectedCard-card {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 15px 1px rgb(0 0 0 / 26%);
  overflow: hidden;
}

.scan-in-store__qr-code-content {
  padding: 1.5em;
}

.payInStore__selectedCard-barcode-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
}

.payInStore__selectedCard-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -0.3em;
  margin-right: -0.3em;
}

.payInStore__selectedCard-buttons > .button--primary-small {
  flex: 1 1;
  width: auto;
  margin: 0.2em 0.3em;
  margin-right: 0.3em !important;
  padding-left: 1em;
  padding-right: 1em;
}

.payInStore__selectedCard-buttons > .payment-method-section__details-wallet-link {
  margin-bottom: 1em;
}

.payInStore__selectedCard-barcode-number {
  text-align: center;
  letter-spacing: 7px;
  margin-bottom: 0.8em;
}

.payInStore__selectedCard-barcode {
  min-height: 70px;
}

.payInStore__selectedCard-barcode > svg {
  display: block;
  margin: 0 auto;
  width: 200px;
  height: 200px;
}

.payInStore__additional-cards-container h3 {
  margin-bottom: 0;
}

.scan-in-store-qr-card__refresh-code-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0 auto 1.2em;
  padding-left: 2em;
  padding-right: 2em;
}

.scan-in-store-qr-card__refresh-code-button > .icon--refresh {
  width: 0.8em;
  height: 0.8em;
  margin-right: 4px;
  color: var(--color-button-text);
}

.scan-in-store__add-money-and-autoreload {
  padding: 1em 1.5em;
}

.scan-in-store__autoreload-status {
  margin-top: 0;
  margin-bottom: 1.5em;
}

.add-money {
  padding: 10px 18px;
  font-size: 13px;
  line-height: 100%;
  background-color: var(--color-primary);
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  color: var(--color-button-text);
  font-weight: 500;
}

.add-money > .icon--plus {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  color: var(--color-button-text);
}

.barcode-type {
  height: 40px;
  color: var(--color-secondary);
  font-size: 14px;
  font-weight: 500;
}

.add-new-card-button {
  background-color: #e2e2e2;
  border: dashed 1px #c7c7c7;
  border-radius: 5px;
  margin-right: 2%;
  margin-bottom: 2%;
  display: inline-block;
  position: relative;
  max-height: 25vw;
}

.payInStore__wallet-cards {
  display: flex;
  flex-wrap: wrap;
}

.payInStore__wallet-cards > div,
.payInStore__wallet-cards > button {
  flex: 0 1 48%;
  font-size: 14px;
}

.payInStore__add-new-card {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1.2;
}

.additional-card > img {
  border-radius: 5px;
  min-height: 25vw;
  max-height: 25vw;
}

.additional-card {
  margin-right: 2%;
  margin-bottom: 2%;
}

.additional-card-details {
  color: #8e8d8d;
  font-size: 12px;
}

.additional-card-selected {
  position: relative;
}
.additional-card-selected > .additional-card-details {
  color: black;
}

.additional-card-selected > .icon--check {
  stroke: #fff;
  stroke-width: 3px;
  width: 20px;
  height: 20px;
  padding: 3px;
  background-color: #4caf52;
  border-radius: 100%;
  position: absolute;
  right: -3px;
  top: -3px;
}

.scan-in-store-active-card-info__expiry {
  max-width: 150px;
  word-wrap: break-word;
  font-size: 13px;
  color: #8e8d8d;
  font-weight: 500;
  margin-top: 5px;
  margin-left: 5px;
}

.payInStore__how-to-container {
  padding: 5%;
}

.payInStore__how-to-container > h2 {
  margin: 0 0 12px;
  font-size: 1.25rem;
}

.payInStore__how-to-container > p {
  margin: 0 0 12px;
}

.payInStore__how-to--add-card {
  margin-top: 40px;
  text-align: right;
}

.scan-in-store__gift-cards-header-and-transfer-funds {
  padding-left: 24px;
  padding-right: 24px;
}

.scan-in-store-gift-cards-section__header {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.scan-in-store-gift-cards-section__header > .icon--gift {
  width: 21px;
  height: 21px;
  margin-right: 5px;
}

.scan-in-store-gift-cards-section__link {
  display: flex;
  align-items: center;
}

.scan-in-store-gift-cards-section__link + a {
  margin-top: 0.5em;
}

.scan-in-store-gift-cards-section__link > .icon--plus-circle {
  fill: #fff;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.scan-in-store-gift-cards-section__transfer-funds:not(:empty) {
  margin-bottom: 2em;
}

.scan-in-store-gift-cards-section__transfer-funds-text {
  margin-top: 0;
}

.scan-in-store__gift-cards-list {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.scan-in-store-gift-cards-list__item {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 1em 24px 1em calc(24px + 20px + 5px);
  background-color: #fff;
}

.scan-in-store-gift-cards-list__item + li {
  margin-top: 5px;
}

.scan-in-store-gift-cards-list-item-info__icon-and-masked-number {
  display: flex;
  align-items: center;
}

.scan-in-store-gift-cards-list-item-info__icon-and-masked-number {
  margin-bottom: 0.5em;
}

.scan-in-store-gift-cards-list-item-info__icon-wrapper {
  position: absolute;
  top: 1em;
  left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background-color: #4caf52;
  border-radius: 50%;
}

.scan-in-store-gift-cards-list-item-info__icon-wrapper > .icon--check {
  width: 12px;
  height: 12px;
  stroke: #fff;
  stroke-width: 3px;
}

.scan-in-store-gift-cards-list-item-info__masked-number {
  color: #2a2a2a;
  font-size: 15px;
}

.scan-in-store-gift-cards-list-item-info__name-and-expiry {
  color: #757575;
  font-size: 12px;
}

.add-new-card-main-content {
  padding-top: 63px;
  border-top: solid 5px #2a2a2a;
}

.add-new-card__button--close {
  position: absolute;
  top: 9px;
  left: 9px;
  width: 50px;
  height: 50px;
}

.add-new-card__content-wrapper {
  padding: 0 24px;
}

.add-new-card__heading {
  margin: 0 0 19%;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.add-new-card__form-card {
  padding-top: 40px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 9px 14px 2px rgba(0, 0, 0, 0.21);
}

.add-new-card-form {
  margin-bottom: 36px;
  padding: 0 24px;
}

.add-new-card__scanner-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 24px;
  color: #2a2a2a;
  font-size: 14px;
  font-weight: 600;
  background-color: #f3f3f3;
}

.add-new-card__scanner-button > .icon--barcode-scan {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.modal--add-new-card-barcode-scanner {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

@media screen and (min-width: 660px) {
  .add-new-card-main-content {
    border-top: none;
  }

  .add-new-card__heading {
    margin-bottom: 2rem;
  }

  .add-new-card__content-wrapper {
    max-width: 440px;
    margin: 0 auto;
    padding: 0 24px;
  }

  #submit-add-new-card-form {
    max-width: 440px;
  }
}

@media screen and (min-width: 660px) {
  .terms-and-conditions-main-content {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1280px) {
  .terms-and-conditions-main-content {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.dashboard__selects-wrapper {
  display: flex;
  justify-content: center;
  height: 44px;
  border-radius: 9999px;
  box-shadow: 0 2px 6px 1px rgb(0 0 0 / 24%);
  overflow: hidden;
}

.dashboard__selects-wrapper + .dashboard__selects-wrapper {
  margin-top: 0.5em;
}

.dashboard-select-wrapper {
  position: relative;
  max-width: 50%;
  flex-grow: 1;
}

.dashboard-select-wrapper:first-child {
  max-width: 45%;
}

.dashboard-select-wrapper + .dashboard-select-wrapper {
  border-left: 1px solid #dcdcdc;
}

.dashboard-select {
  width: 100%;
  height: 100%;
  padding: 0 25px 0 42px;
  font-size: 13px;
  line-height: normal;
  background-color: #fff;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

html[lang="pt"] .dashboard-select[name="order types"] {
  white-space: break-spaces;
  line-height: 110%;
}

.dashboard-select-wrapper > .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 17px;
  height: 17px;
}

.dashboard-select-wrapper > .icon:first-of-type:not(.icon--arrow-down) {
  left: 15px;
}

.dashboard-select-wrapper > .icon--arrow-down {
  right: 7px;
}

.dashboard-select__display-value {
  pointer-events: none; /* allows click-through to select */
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 42px;
  width: calc(100% - 42px - 25px);
  height: 33px;
  font-size: 13px;
  line-height: 33px; /* select's height - focus' outline */
  background-color: #fff; /* to cover select's native display value */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Prevents iPhone auto-zoom-in on input field focus */
.body--iOS .dashboard-select__display-value {
  font-size: 100%;
}

.availableGiftCardsContainer {
  margin: 0;
  padding: 20px 50px;
}

.carouselHeadingContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.1rem;
  padding-left: 0.15rem;
}

.carouselHeadingContainer > h1 {
  margin: 0;
  font-size: 1.5em;
  font-weight: 500;
}

.giftCardCarouselSection {
  margin-bottom: 2.5rem;
  opacity: 1;
  transform: none;
}
.giftCardsListPaddingTop {
  padding-top: 1.2em;
}
.giftCardDetailsConfirmedMessageListContainer {
  display: flex;
  justify-content: center;
}
.giftCardDetailsConfirmedMessageList {
  list-style: disc !important;
  max-width: 600px;
  white-space: pre-wrap;
  text-align: left;
  margin: 0 !important;
}
.giftCardDetailsConfirmedMessageList > li > p {
  margin-top: 0;
}
.gcOrderConfirmPadding {
  padding-top: 3rem;
}
.gcMenuBreadcrumbs {
  margin-bottom: 1.9rem;
}
.gcOrderConfirmationCheckmark svg {
  stroke: #fff;
}

.gift-card-tabs span.MuiTab-wrapper {
  text-wrap: wrap;
}

@media screen and (max-width: 560px) {
  .availableGiftCardsContainer {
    margin: 0;
    padding: 20px 0 20px 0;
  }

  .carouselHeadingContainer {
    padding-right: 1.5em;
    padding-left: 20px;
  }

  .categorizedCardContainer {
    margin: 1.5em;
    margin-left: 20px;
    margin-top: 0;
  }

  .categorizedCardContainer ul {
    padding-left: 0 !important;
  }
  .giftCardDetailsConfirmedMessageList {
    padding-left: 1em;
    padding-right: 1em;
    max-width: 100%;
  }
}

.cart-indicator__wrapper {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 13px;
  margin-bottom: 15px;
}

.cart-indicator {
  position: relative;
  /*width: 34px;
  height: 34px;
  transition: 0.25s transform, 0.25s opacity;
  margin-right: 10px;*/
}

.cart-indicator--empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
}

.cart-indicator>.icon--cart {
  width: 37px;
  /*width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  bottom: 0;*/
}

.cart-indicator--empty>.icon--cart {
  position: relative;
  left: auto;
  bottom: auto;
  margin-top: -2px;
}

.cart-indicator>.gift-indicator__counter {
  left: auto;
  left: initial;
}

.gift-indicator__counter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  left: 4px !important;
  min-width: 23px;
  min-height: 23px;
  color: var(--color-button-text);
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  background-color: var(--color-online-order-settings-active-color);
  border-radius: 50%;
  color: var(--color-button-text);
  max-width: 23px;
}

.cart-indicator--blink {
  transform: scale(1.35);
  opacity: 0.35;
}

.giftCardCartIndicator {
  border-radius: 100%;
  background: var(--color-online-order-settings-background);
  padding: 0.75em;
  display: flex;
  align-items: center;
  width: 62px;
  height: 62px;
  justify-content: center;
  position: fixed;
  right: 25px;
  bottom: 80px;
  z-index: 1;
  box-shadow: 0 .5px 3px .5px rgb(0 0 0/15%)
}

.giftCardCartIndicatorCheckout {
  width: 40px;
  height: auto;
}

.giftCardCartIndicatorCheckout svg {
  width: 30px !important;
  height: 30px !important;
  margin-right: 0.25em;
}

.giftCardCartIndicatorCheckout .gift-indicator__counter {
  min-width: 17.5px;
  min-height: 17.5px;
  font-size: .75em;
  border-radius: 100%;
  line-height: normal;
  left: -7px !important;
  top: 0px;
}

.giftCardCartShoppingBagIndicator svg {
  height: 38px !important;
}

.giftCardCartShoppingBagIndicator .gift-indicator__counter {
  left: -4px !important;
}

/** Dektop Layout **/
@media screen and (min-width: 660px) {
  .cart-indicator__wrapper {
    display: none;
  }
}

/** End Of Desktop Layout **/
.giftCard_slide {
  height: 100%;
  width: 100%;
  overflow: hidden;
  -o-object-fit: contain !important;
     object-fit: contain !important;
  transform: scale(1);
}

.giftCardSlideImage {

  height: 180px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-left: 1em;
}

.giftCard-button {
  transform: none !important;
  transition: none !important;
  position: relative !important;
  width: 100%;
  height: 100%;
}

.giftCard-button>img {
  position: relative !important;
}

.giftCard_card-ratio {
  --card-ratio: 240/400
}

.giftCard_list-wrapper {
  position: unset !important;
  box-shadow: none !important;
  margin-left: 0.5rem !important;
}

.giftCard_list-wrapper:first-child {
  margin-left: 0 !important;
}

.invertMargin {
  margin-left: 0 !important;
  margin-right: 0.9rem !important;
}

.carouselGiftCardSelector {
  padding-top: calc(var(--card-ratio)*(100% / 3 - 1em - 38px)) !important;
  --card-ratio: 220/400
}

.giftCardItem_display {
  width: calc(100% / 3 - 1em - 131.2px) !important;
  box-shadow: none !important;
  min-width: 12rem !important;
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.giftCardItem_display p.gcCardTitle{
  margin-top: 0.3rem;
  position: relative;
  bottom: 0 !important;
}

.selectedGiftCard {
  border: 2px solid var(--color-primary);
  transform: scale(0.96);
  padding: 2px 5px 0 5px;
  transition: 0.25s transform !important;
}


.giftCardSliderWrapper {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

/* NEW CUSTOM CAROUSEL CSS */


.slick-track {
  display: flex !important;
}

::marker {
  display: none !important;
}

div#compactCarouselWrapper> ul {
  list-style: none;
}

div#compactCarouselWrapper> ol {
  list-style: none;
}

#compactCarouselWrapper {
  margin: 0;
}

.custom-arrow-buttons {
  width: 45px;
  opacity: 1;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.6), #fff);
  z-index: 1 !important;
  justify-content: center;
  position: absolute;
  display: flex;
  height: 100%;
  opacity: 1;
  align-items: center;
  pointer-events: all;
  top: -2px;
}

.compact-arrows.custom-arrow-buttons {
  width: 25px;
  opacity: 1;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.6), #fff);
  z-index: 1 !important;
  justify-content: center;
  position: absolute;
  display: flex;
  height: 100%;
  align-items: center;
  pointer-events: all;
}

.carousel-right-arrow {
  right: -5px;
  padding: 8px;
}

.carousel-left-arrow {
  left: -5px;
  padding: 8px;
}

.switchCardCarousel {
  width: 200px !important;
  height: 150px !important;
}

.compactGiftCardSliderWrapper {
  padding-bottom: inherit !important;
}

.compactCarouselArrows {
  height: 100% !important;
}

.compact-carousel-slide-item {
  height: 5.5rem !important;
  width: 8rem !important;
  list-style: none !important;
  margin-right: 0.25rem;

}

.regular-carousel-slide-item {
  width: 98%;
  height: 98%;
  height: 12.8rem;
  width: 15rem !important;
  list-style: none !important;
  margin-right: 0.5em;
  margin-bottom: 0.69em;
  margin-right: 0.9rem;
  padding-bottom: 1em;
}

.giftCardInvalidImageSlide {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.giftCardInvalidImageSlide>p {
  border-bottom: 1.5px solid #8D8D8D;
  border-top: 1.5px solid #8D8D8D;
  padding: 0.55em 0;
  width: 80%;
  font-size: 81%;
  color: #3B3B3B;
  font-weight: bold;
  text-align: center;
  max-width: 170px;
  white-space: pre-wrap;
}

.giftCardInvalidImageSlide.giftCardInvalidImageSlideCompact>p {
  border-bottom: 1.5px solid #8D8D8D;
  border-top: 1.5px solid #8D8D8D;
  padding: 0.55em 0;
  width: 100px;
  font-size: 0.5em;
  color: #3B3B3B;
  font-weight: bold;
}

div#compactCarouselWrapper .styles-module_item-container__a8zaY {
  width: -moz-max-content !important;
  width: max-content !important;
  margin-right: 0.25rem;
}
p.gcCardTitle{
  margin-top: 0.3rem;
  margin-bottom: 0;
  font-size: 1rem;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin-left: 0.25rem;
  width: 100%;
  text-align: left;
}


ul.giftCardItems{
  align-items: center;
}
.gcImageContainer{
  width: 98%;
  height: 88%;
  overflow: hidden;
}

.disableArrows{
  display: none !important;
}

@media screen and (max-width: 700px) {
  p.gcCardTitle{
    margin-top: 0.3rem;
    font-size: 1rem;
    text-align: left;
    margin-left: 1rem;
    width: 100%;
  }
  .giftCardItem_display.regular-carousel-slide-item p.gcCardTitle{
    margin-bottom: 0rem !important;
    margin-left: 2rem;
  }
  .giftCardItem_display.regular-carousel-slide-item{
    width: 22rem !important;
    height: 16rem !important;
    margin-right: 0 !important;
  }
  div#compactCarouselWrapper .styles-module_item-container__a8zaY {
    width: unset !important;
    margin-right: 0;
  }

  .giftCard_list-wrapper {
    width: 100% !important;
    height: auto;
    margin-bottom: 1.5em;
    margin-left: 0 !important;
  }

  .giftCardItems {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
    padding-left: 1em !important;
  }

  .regular-carousel-slide-item .giftCardItem_display {
    margin-right: 0 !important;
    align-self: center;
    margin-bottom:  1em !important;
  }
  .giftCardItem_display p.gcCardTitle{
    font-size: 0.85rem !important;
  }
  .carousel_giftCardListWrapper {
    width: 85% !important;
    height: 16rem !important;
    padding-top: 0 !important;
    overflow: hidden;
  }

  .giftCardInvalidImageSlide>p {
    border-bottom: 1.5px solid #8D8D8D;
    border-top: 1.5px solid #8D8D8D;
    padding: 0.55em 0;
    width: 80%;
    font-size: 81%;
    color: #3B3B3B;
    font-weight: bold;
  }

  .regular-carousel-slide-item {
    list-style: none !important;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .giftCardItem_display.regular-carousel-slide-item{
    padding-bottom: 1rem !important;
  }
  .giftCardSliderWrapper {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .giftCardMobileSlider:first-child{
    margin-left: 0 !important;
    padding-left: 20px;
  }
}
.giftCardOrderDetailsTotal {
  border-top: none !important;
  margin-bottom: 0;
  padding-top: 0.2em;
}

.giftCardOrderDetailsContent {
  display: "flex";
  flex-direction: "column";
  border-bottom: 1px solid lightgrey;
  width: 100%;
}

.giftCardOrderDetailsContent:last-child {
  border-bottom: none !important;
}

.buttonFilled {
  background-color: var(--color-primary);
  color: var(--color-button-text);
}
.giftCardNotLoggedInMessage{
  max-width: 400px;
}
.giftCardNotLoggedInMessage a {
  width: 100%;
}
.giftCardNotLoggedInMessage h3 {
  margin-top: 0;
  margin-bottom: 1rem;
}

.giftCardNotLoggedInMessage p {
  text-align: center;
  word-wrap: break-word;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.recentGiftCardOrderBackButtonLabel {
  margin-top: 0;
}
.gcRecentOrderDetailsContainer{
  min-width: 550px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.2em;
}
.gcRecentOrderDetailsContainer .recent-order__main{
  padding-bottom: 0 !important;
}
.gcRecentOrderDetailsWrapper{
  display: flex;
  align-items: flex-start;
}
.gcTotalCompletedOrder{
  margin-bottom: 0;
}
@media screen and (max-width: 600px) {
  .giftCardOrderDetailsContent {
    width: 100%;
  }
  .gcRecentOrderDetailsContainer{
    min-width: 100%;
  }
  .gcRecentOrderDetailsWrapper{
    display: block;
    align-items: unset;
  }
  .giftCardNotLoggedInMessage{
    max-width: 260px;
  }

}
.giftCardDetailsWrapper {
  padding: 1.9rem 0 !important;
}

.giftCardSelectHeader {
  padding: 0px 1.9rem 1.9rem 1.7rem !important;
}

.giftCardImageWrapper {
  height: auto !important;
  width: auto !important;
  position: relative;
  top: 0 !important;
  left: 0 !important;
  margin-right: 1em;
}

.giftCardImage>img {
  -o-object-fit: contain !important;
     object-fit: contain !important;
  height: 100% !important;
  max-height: 10.3rem !important;
  max-width: 17.3rem !important;
  position: relative;
  margin-bottom: 0;
  }
  
.giftCardImage.selectedCardImage {
  height: 10.5rem ;
  width: 17.6rem ;
}
.giftCardImage.selectedCardImage>img {
  height: 11.5rem !important;
  width: 17.6rem !important;
}

.mui_button_override {
  text-transform: none !important;
  color: inherit !important;
  font-size: 0.75rem !important;
  z-index: 1 !important;
}

.dateTimeInputComboContainer .rmdp-week:hover>.rmdp-day {
  background-color: inherit !important;
  color: #000 !important;
  border-radius: 50% !important;
}

.dateTimeInputComboContainer .rmdp-week:hover>.rmdp-day:first-child {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.dateTimeInputComboContainer .rmdp-week:hover>.rmdp-day:last-child {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.dateTimeInputComboContainer .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background-color: transparent !important;
  background-color: initial !important;
  color: var(--color-secondary) !important;
  border-radius: 12px;
}

.compactGiftCardCarouselContainer {
  display: flex;
  margin-left: 1em;
  width: 100%;
  align-self: end;
  background: transparent;
  margin-top: 0;
  flex-direction: column;
}

.compactGiftCardCarouselContainer>p {
  margin: 0;
  margin-bottom: 1.5rem;
}

.compactGiftCardSliderArrowButton {
  height: 100% !important;
}

.react-multi-carousel-list {
  display: flex;
  flex-direction: column;
}

.react-multi-carousel-track {
  display: flex;
  justify-content: center;
  transform: none !important;
  max-width: 500px;
}

.react-multi-carousel-item {
  width: 200px !important;
  height: 200px !important;
}

.switchGiftCardCarouselContainer {
  justify-content: space-between;
  height: 97.5%;
}

.switchCompactCarouselContainer {
  height: 90% !important;
}

.giftCardRecipientDetailsContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1.9em;
  z-index: 2;
}

.slideDownAnimation {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;

  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;

  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;

  visibility: visible !important;
}
.gcSelectedTitle{
  word-wrap: break-word;
}

@keyframes slideDown {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }

  50% {
    transform: translateY(-8.5%);
    opacity: 0;
  }


  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.giftCardFormsLoadingOverlay{
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 4;
  background: #ffffff75;
}

.giftCard_sendMoreButtonWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 5em;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.giftCard_sendMoreButtonWrapper>button {
  border-radius: 4em !important;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.75em 1.5em !important;
  border-width: 1.5px;
  border-color: #757575;
  color: #757575;
  text-transform: none;
  font-weight: bold;
}

.giftCard_sendMoreButtonWrapper>button.activeText {
  border-color: var(--color-secondary);
  color: var(--color-secondary);
}

.giftCard_sendMoreButtonWrapper>button svg {
  color: #757575 !important;
}

.giftCard_sendMoreButtonWrapper>button.activeText svg {
  color: var(--color-secondary) !important;
}
.gcRemoveButton > Button > span{
  color: var(--color-secondary) !important;
}
.recipientRightColumn{
  display:flex; 
  align-items:center;
}
.gcRecipientMinimumOneError{
  color: var(--color-error);
  padding:0;
  margin-right: 1.25rem;
  width: 100%;
}
.sendMoreButtonError{
  opacity: 0;
  position: absolute;
  bottom: 25px;
  transform: translateY(0);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ff2020;
  font-size: 14px;
  text-align: center;
  animation: showFABError 0.3s forwards;
}
@keyframes showFABError {
  100% {
    opacity: 1;
    transform: translateY(calc(-52px / 2 - 10px));
  }
}
.gcAddToOrderButtonDisabled{
  opacity: 0.5;
  pointer-events: none;
}
.hideSendMoreButton{
  display: none;
}

@media screen and (max-width: 660px) {
  .gcErrorHeaderRow{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .gcRecipientMinimumOneError > p{
    margin-bottom: 0;
    margin-top: 5px;
  }
  .giftCardImage.selectedCardImage>img {
    height: 6.5rem !important;
    width: 10rem !important;
  }
  .giftCardImage.selectedCardImage {
    height: auto !important;
    width: auto !important;
  }

  .switchCompactCarouselContainer {
    height: 90% !important;
  }

  .giftCardInvalidImageSlide>p {
    width: 130px;
  }
  .gcFormButton div.desktop-container{
    display: flex !important;
    flex-direction: row-reverse;
    align-items: center;
  }
  .inlineFormButton {
    width: -moz-fit-content;
    width: fit-content;
    background-color: transparent !important;
    display: flex !important;
    justify-content: center;
  }
  .giftCardRecipientDetailsContainer{
    padding-bottom: 4em;
  }
}
/* textarea input */
.textareaInput {
  height: 100px !important;
  resize: none;
  border: 1px solid #dedede !important;
  border-radius: 0.5em !important;
}

.form_button-action-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  white-space: nowrap;
  margin-top: 0.75rem;
  width: 100%;
  position: absolute;
}

.form_button-action-submit,
.form_button-action-clear {
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: flex;
  align-items: center;
}

.form_button-action-submit>Button,
.form_button-action-clear>Button {
  background-color: transparent;
}

.form_button-action-submit>Button>span,
.form_button-action-clear>Button>span {
  margin-top: 0.12rem;
  text-transform: none;
  color: #757575;
  font-size: 16px;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-weight: 500;

}

.form_button-action-submit>Button.activeText>span,
.form_button-action-clear>Button.activeText>span {
  color: var(--color-primary);
}

.form_button-action-clear {
  margin-top: 0.10rem;
  display: flex;
  align-items: center;
}

.form_button-action-clear>span {
  margin-left: 0.22rem;
}

.form_button-action-submit {
  margin-right: 2rem;
}

.disabledQuickSubmitButton {
  pointer-events: none !important;

}

.disabledQuickSubmitButton:hover {
  pointer-events: none !important;
  box-shadow: none !important;
}

/* mobile view */
@media screen and (max-width:660px) {
  .textAreaWrapperSmall {
    width: 100% !important;
    margin-top: 2.5em;
  }
}
.dateTimeInputComboContainer {
  max-width: 300px;
}

.dateTimeInputComboContainer label {
  margin: 1.5px;
}

.formDatepickerInput {
  resize: none;
  border: 1px solid #dedede !important;
  border-radius: 0.5em !important;
  width: 300px !important;
  height: 42px !important;
}

.formTimepickerInput {
  resize: none;
  border: 1px solid #dedede !important;
  border-radius: 0.5em !important;
  max-width: 150px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none !important;
  height: 35px !important;
}

.dateTimeInputComboContainer .rmdp-container {
  width: 300px !important;
}

.dateTimeInputComboContainer .rmdp-day.rmdp-today span {
  background-color: transparent !important;
  color: #0086ff !important;
}

.dateTimeInputComboContainer .rmdp-day.rmdp-today.rmdp-selected span {
  background-color: transparent !important;
  color: white !important;
}

.dateTimeInputComboContainer .rmdp-day span:hover {
  border-radius: none !important;
  color: var(--color-secondary);
}

.dateTimeInputComboContainer .rmdp-day span.sd {
  border-radius: 50% !important;
}

.dateTimeInputComboContainer .form-visuaally-hidden-button {
  visibility: hidden !important;
}
.dateTimeInputComboContainer
input[name="hour"],
.dateTimeInputComboContainer input[name="minute"] {
  padding: 0.15rem 0 !important;
}

.dateTimeInputComboContainer .rmdp-day.rmdp-today.rmdp-day.rmdp-selected span {
  color: #fff !important;
  background-color: var(--color-primary) !important;
}

.dateTimeInputComboContainer .rmdp-day.rmdp-today.rmdp-selected span:hover {
  color: #0086ff !important;
  background-color: #fff !important;
}

.dateTimeInputComboContainer .rmdp-time-picker.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateTimeInputComboContainer .rmdp-time-picker.bottom div {
  max-width: 40px;
}

.dateTimeInputComboContainer .rmdp-time-picker.bottom div:nth-child(3) button {
  display: none;
}

.dateTimeInputComboContainer .rmdp-time-picker.bottom div:nth-child(3) input {
  pointer-events: none;
  color: #757575
}

.dateTimeInputComboContainer .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--color-primary) !important;
  color: white !important;
}
.dateTimeInputComboContainer div.rmdp-header-values span:last-child {
  margin-left: 4px;
}
.disabledCalendarInput{
  pointer-events: none;
  border: none !important;
}
.dateTimeInputComboContainer .rmdp-selected span {
  box-shadow: none !important;
}

/* mobile view */
@media screen and (max-width:660px) {
 .dateTimeInputComboContainer .rmdp-header-values>span {
    font-size: 0.85rem;
  }

  .dateTimeInputComboContainer .rmdp-week-day {
    font-size: 0.85em !important;
  }

  .dateTimeInputComboContainer .rmdp-day>span {
    font-size: 0.85em !important;
  }

  .dateTimeInputComboContainer .rmdp-mobile {
    min-width: 300px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .dateTimeInputComboContainer .rmdp-mobile .rmdp-calendar {
    width: 100%;
    margin-bottom: 1em !important;
  }

  .dateTimeInputComboContainer .rmdp-mobile .rmdp-calendar .rmdp-day-picker>div {
    width: 100% !important;
    font-size: 1em !important;
  }

  .dateTimeInputComboContainer .rmdp-calendar-container-mobile div.rmdp-action-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .dateTimeInputComboContainer .rmdp-calendar-container-mobile div.rmdp-action-buttons button {
    color: var(--color-primary);
    font-size: 1em !important;
  }

}
.formSelectInputCombo {
  position: relative;
  display: flex;
  width: 100%;
}
.formSelectInputComboSelect{
  min-width: 100%;
  position: relative;
}
.comboSelectField{
  min-width: 100%;
}
.formSelectInputComboSelect.gcSelectInputShrink{
  min-width: 50%;
  position: relative;
}
.gcSelectInputShrink .comboSelectField{
  min-width: 50%;
}
.formSelectInputComboInput{
  position: relative;
  margin-left: 1em;
  display: flex;
  width: 100%;
}
.formSelectInputCombo>input {
  padding-left: 1em;
}
.formSelectInputComboInput label {
  position: absolute;
  top: -71px;
  left: 0;
}
@media screen and (max-width: 660px) {
  .formSelectInputCombo {
    flex-direction: column;
  }
  .formSelectInputComboSelect{
    min-width: 100%;
  }
  .formSelectInputComboInput{
    margin-top: 5em;
    margin-left: 0;
  }
}
.giftCardInfo {
  margin-top: 0.5rem;
  align-items: baseline;
  display: flex;
}

.giftCardInfo>p {
  color: #494949;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 590;
  line-height: normal;
  width: 7.5rem;
  padding: 0;
  margin: 0;
}

.giftCardInfo>p:first-child {
  padding-right: 4rem;
  font-weight: 600;
}

.giftCardInfo>p:last-child {
  font-weight: 400;
  width: auto;
}

.gift-card-cart-content {
  flex-direction: row !important;
}

.giftCardCartDiscountButton {
  display: flex !important;
  justify-content: space-between !important;
  padding: 0 !important;
  font-weight: bold !important;
  margin-top: 10px !important;
}

.giftCardCartDiscountButton svg {
  float: right;
}

.giftCardCartDiscountValue {
  color: rgb(205, 58, 80);
}

.giftCardDiscountLine {
  display: flex;
  align-items: center;
}

.giftCardDiscountLine span:nth-child(2) {
  white-space: nowrap;
}

.giftCardDiscountLabel {
  word-wrap: break-word;
  display: flex;
  justify-content: space-between;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  margin-bottom:  0.5em;
}

.giftCardDiscountLabel p {
 margin: 0;
}
p.multiDiscountLabelText{
  margin-bottom: 0.5rem !important;
}

.giftCardDiscountLabel>button {
  padding: 0;
  margin-bottom: 4px;
  margin-right: 0.5em;
}

.giftCardCartIndicatorIcon {
  display: flex;
  align-items: center;
}

.giftCardCartSummaryContainer {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  min-height: 100px;
}

.fullRowWidth {
  width: 100%;
}

.giftCardCartSummaryContainer {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

}

.gcCartDiscountSection {
  padding-top: 1.3rem;
  padding-bottom: 2.6rem;
  min-height: 100px;
  border-radius: 4px;
  margin-top: 1em;
  flex-direction: column;
  position: relative;
}

.gcCartDiscountForm .gcDiscountButton {
  font-size: 0.9rem;
  margin-top: 0 !important;
  margin-left: 0.3rem;
  padding: 0.75rem 2.25rem !important
}

.gcCartDiscountForm .form__field-wrapper {
  width: 70%;
  min-width: 205px;
}

.gcCartDiscountForm .form-submit__wrapper {
  margin-top: 0 !important;
}

.gcCartDiscountForm #form--gcPromo {
  display: flex !important;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
}

.gcCartDiscountForm .button--submit-form-wrapper {
  margin-top: 0 !important;

}

.gcCartDiscountForm .form__error {
  display: none !important;
}

.gcDiscountError {
  margin-bottom: 0;
  color: var(--color-error);
  position: absolute;
  bottom: 15px;
}

.gcCartDiscountForm .form__error {
  text-align: center;
  display: flex;
  justify-self: center;
  width: 100%;
}

.collapsedGreetingContainer {
  display: flex;
  align-items: center;
}

.expandedGreetingContainer {
  display: flex;
  align-items: unset;
  flex-direction: column;
}

.collapsedGreetingContainer p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: unset;
  overflow-wrap: anywhere;
  margin: 0;
  word-break: break-word;
}
.expandedGreetingContainer p{
  word-break: break-word;
}
.collapsedGreetingContainer p, .expandedGreetingContainer p {
  padding: 0;
  margin: 0;
  color: #494949;
  font-size: 0.9rem;
  font-style: normal;
  overflow-wrap: anywhere;
}

.collapsedGreetingContainer button, .expandedGreetingContainer button {
  padding-top: 0;
  margin-top: 0;
  align-self: flex-end;
  margin-left: 2px;
  float: right;
  font-size: 0.9rem;
}

.expandedGreetingContainer button {
  margin-top: 0.5em;
}
#form--gc-customer-info .form-submit__wrapper{
  margin-top: 60px !important;
}
.gcCartSummary{
  position: unset !important;
}
.gcCartDiscountContainer{
  position: unset !important;
}
.gcCartItemTitle{
 word-wrap: break-word;
}
.gcCodeDiscountsList{
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.gcDiscountName{
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.gcDiscountValue p{
  white-space: nowrap;
}
.gcRemovePromoButton{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 0.5rem;
}
.gcRemovePromoButton button{
  color: var(--color-secondary);
  font-size: 0.8em;
  font-weight: 600;
  
}
.gcCartDiscountForm .form-submit__wrapper{
  width: 29% !important;
}
.gcDiscountButton {
  width: 98% !important;
  margin-right: 0 !important;
}
.bonusPromoValue{
  color: unset;
}
@media screen and (max-width: 660px) {

  .gcCartDiscountForm .form__field-wrapper {
    width: 100%;
    margin-right: 0.75rem;
  }
  .gcDiscountError{
    text-align: center;
    overflow: hidden;
  }

  .gcCartDiscountSection{
    border-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .gcCartDiscountSection{
    margin-top: 3px;
  }
  .giftCardDiscountLabel{
    padding-right: 0.5rem;
  }
}
.desktop-order-menu__top-categories-buttons {
  padding-top: 1.5%;
  margin-bottom: 2em;
}

.desktop-order-menu__top-categories {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5% 0 0 -0.5%;
  padding-left: 0;
  list-style-type: none;
}

.desktop-order-menu__top-category {
  margin: 0.2%;
}

.desktop-order-menu__top-category-button,
.desktop-order-menu__favorites-button {
  min-width: 150px;
  min-height: 35px;
  padding: 0 1em;
  font-size: 1em;
  font-weight: 600;
  line-height: 100%;
  border-radius: 9999px;
}

.desktop-order-menu__favorites-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktop-order-menu__favorites-button > .icon--heart {
  margin-right: 4px;
}

.desktop-order-menu__subcategories {
  display: flex;
  flex-wrap: wrap;
  margin: -1% 0 0 -1%;
  padding-left: 0;
  list-style-type: none;
}

.desktop-order-menu__card {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% / 3 - 2%);
  height: 0;
  margin: 1%;
  padding-top: 14%;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s;
}

.desktop-order-menu__card:hover {
  transform: translateY(-10px);
}

.desktop-order-menu__subcategory-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.desktop-order-menu__card-image-wrapper {
  position: absolute;
  left: 18px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  height: calc(100% - 36px);
  margin-right: 15px;
  overflow: hidden;
}

/*.desktop-order-menu__card-image-wrapper-with-padding {
  padding: 1em;
}*/

.desktop-order-menu__card-image {
  max-height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.desktop-order-menu__card-text-wrapper {
  position: absolute;
  right: 18px;
  top: 0;
  bottom: 0;
  max-height: calc(100% - 36px);
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 35% - 15px - 36px);
}

.desktop-order-menu__card-text-wrapper--subcategory {
  right: 32px;
  width: calc(100% - 35% - 15px - 18px - 32px);
}

.desktop-order-menu__card-text-wrapper--item {
  justify-content: space-between;
}

.desktop-order-menu__card-heading {
  overflow: hidden;
  white-space: nowrap;
  margin: 0 0 0.5em;
  font-weight: 600;
}

.desktop-order-menu__card-heading--subcategory {
  font-size: 1.3em;
}

.desktop-order-menu__card-heading--item {
  font-size: 1.1em;
}

.desktop-order-menu__card > .icon--arrow-right {
  position: absolute;
  right: 1.5em;
  top: 0px;
  bottom: 0;
  margin: auto 0;
  width: 18px;
  height: 18px;
  fill: #333;
}

.desktop-order-menu__breadcrumbs-container {
  margin-bottom: 2%;
}

.desktop-order-menu__breadcrumbs-list {
  display: flex;
  align-items: center;

  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.desktop-order-menu__breadcrumb-item + li {
  margin-left: 1.5%;
  padding-left: 1.5%;
  border-left: 1px solid #aaa;
}

.desktop-order-menu__breadcrumb {
  font-size: 1em;
  font-weight: 600;
}

.desktop-order-menu__breadcrumb:disabled {
  color: #2a2a2a !important;
  opacity: 1;
  background: none;
}

.desktop-order-menu__items {
  display: flex;
  flex-wrap: wrap;
  margin: -1% 0 0 -1%;
  padding-left: 0;
}

.desktop-order-menu__item-description {
  position: relative;
  min-height: 35px;
  max-height: 1.9em;
  margin: 0 0 0.5em;
  overflow: hidden;
  font-size: 0.8125em;
  line-height: 1.3em;
}

.desktop-order-menu__item-description:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.3em;
  margin-top: 0.2em;
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.83) 50%);
}

.desktop-order-menu__item-calories {
  margin: 0 0 0.8em;
  font-size: 0.8125em;
}

.desktop-order-menu__item-price {
  font-size: 1.1em;
  font-weight: 600;
}

.desktop-order-menu__calories-disclaimer {
  margin: 3.3% auto 0;
  color: #6b6b6b;
  font-size: 0.9em;
}

.desktop-order-item-container {
  padding-top: 1em;
}

.desktop-order-item__image-and-text-wrappers {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 2%;
}

.desktop-order-item__image-wrapper {
  max-width: 30%;
  margin-right: 3%;
  min-width: 25%;
}

.desktop-order-item__text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 60%;
}

/*.desktop-order-item__text-wrapper > div:first-child {
  max-width: 85%;
}*/

.desktop-order-item__heading {
  margin: 0 0 0.5em;
  font-size: 1.5em;
}

.desktop-order-item__description--wrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  position: relative;
}
.desktop-order-item__description {
  margin: 0;
  font-size: 1em;
  white-space: pre-line;
  overflow: hidden;
  max-height: 16.1em;
}

.desktop-order-item__description--expanded {
  max-height: 100%;
  min-height: 5.5em;
  transition: max-height 0.5s;
  transition: min-height 0.5s;
  overflow: unset;
  display: inline;
}

.desktop-order-item__description--read-more {
  color: var(--color-secondary);
  margin-top: 10px;
}

.desktop_item_description__gradient {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 18px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 18%, rgba(255, 255, 255, 0) 100%);
}

.desktop-order-item__calories {
  font-size: 1.3em;
  margin-top: 10px;
}

.desktop-order-item__price-and-favorites-wrapper {
  margin-top: 1%;
  padding-top: 1%;
  border-top: 1px solid #dfdfdf;
}

.desktop-order-item__price {
  font-size: 1.3em;
  font-weight: 600;
}

.desktop-order-item__options-container {
  padding: 2% 0 5em 0;
  background: linear-gradient(180deg, #eee, #eee 80%, #fff);
}

.desktop-order-item__options-container > .desktop-container {
  padding-left: 24px;
  padding-right: 24px;
}

.desktop-order-item__options-heading {
  margin: 0 0 1.5em;
  font-size: 1.3em;
  font-weight: 600;
  text-align: center;
}

.desktop-order-item__option-header {
  margin-bottom: 1em;
}

.desktop-order-item__option-heading,
.form__fieldset-legend {
  margin: 0;
  font-size: 1em;
  font-weight: 600;
}

.desktop-order-item__options-form {
  position: relative;
}

.desktop-order-item__options-group + .desktop-order-item__options-group {
  margin-top: 2.5%;
}

.desktop-order-item__options-group + .special-instructions-wrapper {
  margin-top: 4%;
}

.desktop-order-item__options-form .form__fieldset-legend:not(.form__fieldset-legend--nested) {
  display: none;
}

.desktop-order-item__options-form .addons-modifiers-form .form__field-wrapper--checkbox-radio {
  padding: 0.3% 1%;
  background-color: #fff;
  border-radius: 4px;
}

.desktop-order-item__options-form .form__fieldset {
  margin: -0.5% 0 0 -0.5%;
}

.desktop-order-item__options-form .form__fieldset--nested {
  margin-top: 8px;
}

.desktop-order-item__options-form .form__fieldset + .form__fieldset--nested {
  margin-top: 2%;
}

.desktop-order-item__options-form .form__field-wrapper {
  display: inline-block;
  width: 49%;
  margin: 0.5%;
}

.desktop-order-item__options-form .form__fieldset + * {
  margin-top: 2.5%;
}

.desktop-order-item__options-form .form__field-label--checkbox-radio::before {
  left: 2%;
}

.desktop-order-item__options-form .form__fieldset-legend--nested {
  margin-bottom: 0.5em;
}

.desktop-order-item__options-form + .desktop-order-item__option-header {
  margin-top: 2.5%;
}

.form__buttons-wrapper {
  position: fixed;
  left: 0;
  bottom: 20px;
  width: 100%;
  z-index: 9999;
}

.form__buttons-wrapper > .desktop-container {
  display: flex;
  justify-content: space-between;
}

.form__buttons-wrapper > .desktop-container > * {
  flex: 1 1;
}

.desktop-order-item__option-heading > .icon--check {
  width: 18px;
  height: 18px;
  padding: 3px;
  stroke: #fff;
  stroke-width: 3px;
  background-color: #4caf52;
  border-radius: 50%;
}

.desktop-order-item__buttons {
  position: fixed;
  bottom: 28px;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 1em 0;
  background-color: var(--color-online-order-settings-background);
}

.desktop-order-item__buttons > .FAB__error {
  bottom: calc(52px / 2 + 95px);
}

.desktop-order-item__buttons .FAB-wrapper__gradient {
  background-image: linear-gradient(to top, #fff 0%, #fff 38%, rgba(255, 255, 255, 0) 100%);
}

/* .desktop-order-item__buttons .FAB-wrapper__gradient:after {
  opacity: 1;
} */

.desktop-order-item__button-wrappers {
  display: flex;
  justify-content: center;
}

.desktop-order-item__button-wrappers > * {
  flex: 1 1;
  /*max-width: 30%;*/
  width: 30%;
  transition: opacity 0.3s;
}

.desktop-order-item__button-wrappers > .order-settings__cart-button {
  width: unset;
  flex: unset;
}

.desktop-order-item__button-wrappers > *:hover {
  opacity: 0.7;
}

.desktop-order-item__button-wrappers > button:nth-child(2) {
  margin-right: 1em;
}

.desktop-order-item__back-to-menu-button {
  /*
  position: relative;
  margin-right: 2%;
  text-transform: uppercase;
  border-radius: 9999px;
  background-color: #fff;
*/
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: none;
  background-color: #ffffff;
  text-transform: uppercase;
  font-size: 1em;
  margin-right: 1em;
}

.desktop-order-item__button-wrappers .FAB {
  margin: 0;
}

@media screen and (min-width: 660px) and (max-width: 1024px) {
  .desktop-order-menu__calories-disclaimer {
    max-width: 100%;
    margin-bottom: 4em;
  }
}

.desktop-order-menu__combo-item-check-mark {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}

.desktop-order-menu__combo-item-check-mark > .icon--check {
  stroke: #fff;
  stroke-width: 3px;
  width: 18px;
  height: 18px;
  padding: 3px;
  background-color: #4caf52;
  border-radius: 100%;
}

.desktop-combo-item-details__out-of-stock-addons-error {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  color: #ff2020;
  font-size: 14px;
}

@media screen and (min-width: 660px) and (max-width: 1824px) {
  .desktop-order-menu__card {
    width: calc(100% / 2 - 2%);
    padding-top: 18%;
  }

  .desktop-order-menu__top-categories {
    margin: -1% 0 0 -1%;
  }

  .desktop-order-menu__top-category {
    margin: 0.2%;
  }
}

.dine-in__table-number-form__popup > div > div > button#form--table-number__submit-button {
  width: auto;
  max-width: none;
  padding-left: 3.5em;
  padding-right: 3.5em;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.8em;
  flex: 1 1;
  flex-grow: 0.5;
  height: 44px;
  letter-spacing: 0.2px;
}

.dine-in__table-number-form__popup > div > div > div.form__error {
  top: -60px;
}

.FAB--desktop__confirm-combo-changes__container {
  bottom: 28px;
}
.desktop-menu-button-add-to-order__container,
.FAB--desktop__confirm-combo-changes__container > .desktop-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.desktop-menu-button-add-to-order,
.desktop-menu-button-add-to-order__combo,
.FAB--desktop__confirm-combo-changes {
  width: unset;
  min-width: unset;
  flex: unset;
  padding: 0.8em 4em;
  margin: 0;
  font-size: 0.8em;
}

.desktop-menu-button-add-to-order + button.order-settings__cart-button {
  margin-left: 1em;
}

.desktop-menu-button-add-to-order__combo + button.button--cart-floating {
  margin-left: 1em;
  width: unset;
  margin-right: 0;
  max-width: 37px !important;
}

.desktop-order-item__button-wrappers__combo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.desktop-order-item__button-wrappers__combo > button {
  width: unset;
  min-width: 27%;
  flex: unset;
  padding: 0.8em 4em;
  margin: 0;
  font-size: 0.8em;
  margin-left: 1em;
  margin-right: 0 !important;
}

/* App styles */
.App {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
}

.App__error {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 10%;
  color: #d34146;
  font-size: 0.95rem;
  font-weight: 600;
  text-align: center;
  background-color: #fff;
}

.ad2hs-prompt {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 12px;
  background-color: #fff;
  box-shadow: 0px -3px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.ad2hs-prompt__image-and-name {
  display: flex;
  align-items: center;
}

.ad2hs-prompt__image-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  overflow: hidden;
}

.ad2hs-prompt__install-button {
  height: 44px;
  padding: 0 12px;
  font-size: 0.95rem;
  border-radius: 4px;
}

.ad2hs-prompt__close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
}

.ad2hs-prompt__close-button > .icon--x {
  width: 18px;
  height: 18px;
}

.secondary-splash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9999;
}

.secondary-splash--closing {
  animation: fadeOut 0.3s forwards;
}

.secondary-splash__logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 128px;
  height: 128px;
}

.secondary-splash__name {
  position: absolute;
  bottom: 48px;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
}

button:focus,
input:focus,
optgroup:focus,
select:focus,
textarea:focus {
  outline-color: var(--color-primary);
}

@media screen and (min-width: 660px) {
  .App {
    min-height: 100%;
    overflow-y: auto;
  }
}

.orderTimeDatePopupContent > h2 {
  margin: 0 0 1.5em;
  font-size: 0.8em;
  font-weight: 400;
  text-align: center;
}

.orderTimeDatePopupContent {
  min-width: 15em;
}

.orderTimeDatePopupContent > .dashboard__selects-container {
  margin: 0 auto;
}

/* END OF App styles */

.pay-in-store-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  height: 80%;
}

.pay-in-store-modal {
  font-size: 17px;
  font-weight: 600;
}

.modal-wrapper .pay-in-store-modal {
  transform: scale(0) translateY(60vh);
  transform-origin: bottom right;
  animation: movePayInStoreModalUp 0.3s forwards;
}

@keyframes movePayInStoreModalUp {
  100% {
    transform: scale(1) translateY(0);
  }
}

.modal-content {
  height: 100%;
  max-height: none;
}

.new-update-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.3s;
  z-index: 1100;
}

.new-update-modal {
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(150px);
  width: 100%;
  padding: 48px 24px 24px;
  background-color: #fff;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  animation: slideUpNewVersionModal 0.3s forwards;
  animation-delay: 0.15s;
}

@keyframes slideUpNewVersionModal {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.new-update-modal__message {
  margin: 0;
}

.new-update-modal__buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2em;
}

.new-update-modal__submit-button {
  flex: 1 1;
  flex-grow: 0.5;
  max-width: 190px;
  height: 44px;
  padding-top: 0;
  padding-bottom: 0;
  letter-spacing: 0.2px;
}

@media screen and (min-width: 1025px) {
  .new-update-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
  }

  .new-update-modal {
    position: relative;
    width: auto;
    width: initial;
    max-width: 90%;
    padding: 3em 2em 2em 2em;
    border-radius: 8px;
  }
}

@media screen and (min-width: 660px) {
  .new-update-modal__submit-button {
    width: auto;
    max-width: none;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 0.8em;
  }
}

