.payment-overlay__container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.97);
  animation: fadeIn 0.3s;
  z-index: 999999;
}

.payment-overlay {
  opacity: 0;
  transform: translateY(150px);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: 900px;
  padding: calc(44px + 0.5em) 1.5em 2em;
  animation: slideUpConfirmationModal 0.3s forwards;
  animation-delay: 0.15s;
}

.payment-overlay__text {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.payment-overlay__header {
  display: flex;
  position: relative;
  min-height: 65px;
}

.payment-overlay__header-dine-in {
  display: flex;
  position: relative;
  min-height: 90px;
}

.payment-overlay__header-text {
  transform: translateX(100%);
  margin: auto 24px 0px 24px;
  max-width: fit-content;
  min-width: 65%;
  font-size: 1.1rem;
  position: unset;
  animation: slideInFromRight 0.2s forwards;
}

.payment-overlay__header-text-dine-in {
  transform: translateX(100%);
  margin: auto 0px auto 24px;
  max-width: fit-content;
  min-width: 65%;
  font-size: 1.1rem;
  position: unset;
  animation: slideInFromRight 0.2s forwards;
}

/* appear - on overlay load */
.slide-appear {
  opacity: 0;
  z-index: 1;
}

.slide-appear.slide-appear-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

/* on overlay text change - enter*/
.slide-enter {
  opacity: 0;
  transform: scale(0.97) translateY(5px);
  z-index: 1;
}

.slide-enter.slide-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: opacity 300ms linear 200ms, transform 300ms ease-in-out 200ms;
}

/* on overlay text change - exit */
.slide-exit {
  opacity: 0;
  transform: scale(1) translateY(0);
  display: none;
}

.slide-exit.slide-exit-active {
  opacity: 0;
  transform: scale(0.97) translateY(5px);
  transition: opacity 150ms linear, transform 150ms ease-out;
  display: none;
}

.slide-exit-done {
  opacity: 0;
}

.payment-overlay__secondary-header-text {
  min-width: fit-content;
  right: -25px;
  margin-top: 40px;
  margin-left: 24px;
  bottom: 0;
  font-size: 0.8rem;
  font-weight: normal;
  color: #676767;
  transition: opacity 1s ease;
}

.payment-overlay__progress-bar-start {
  margin-left: 24px;
}

.payment-overlay__progress-bar-finish {
  margin-right: 24px;
}

.payment-overlay__do-not-refresh {
  margin: 24px;
  max-width: fit-content;
  font-size: 1rem;
}

.checkmark > svg {
  margin-right: 12px;
  padding: 3px;
  stroke-width: 3px;
  stroke: #fff;
  background-color: #4caf52;
  border-radius: 50%;
}

.MuiLinearProgress-colorPrimary {
  background-color: var(--color-gray-light) !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: var(--color-primary) !important;
}

@keyframes slideUpConfirmationModal {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromRight {
  100% {
    transform: initial;
    opacity: 1;
  }
}

/**Desktop layout **/
@media screen and (min-width: 660px) {
  .payment-overlay__text {
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
