.cart-indicator__wrapper {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 13px;
  margin-bottom: 15px;
}

.cart-indicator {
  position: relative;
  /*width: 34px;
  height: 34px;
  transition: 0.25s transform, 0.25s opacity;
  margin-right: 10px;*/
}

.cart-indicator--empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
}

.cart-indicator > .icon--cart {
  width: 37px;
  /*width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  bottom: 0;*/
}

.cart-indicator--empty > .icon--cart {
  position: relative;
  left: auto;
  bottom: auto;
  margin-top: -2px;
}

.cart-indicator > .cart-indicator__counter {
  left: initial;
}

.cart-indicator__counter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 20px !important;
  min-width: 23px;
  min-height: 23px;
  color: var(--color-button-text);
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  background-color: var(--color-online-order-settings-active-color);
  border-radius: 50%;
  color: var(--color-button-text);
  max-width: 23px;
}

.cart-indicator--blink {
  transform: scale(1.35);
  opacity: 0.35;
}

/** Dektop Layout **/
@media screen and (min-width: 660px) {
  .cart-indicator__wrapper {
    display: none;
  }
}
/** End Of Desktop Layout **/
