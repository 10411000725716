.suggestedItems__main_container {
  background-color: #fff;
}

.suggestedItems__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.suggestedItems__image_wrapper {
  display: flex;
}

.suggestedItems__list > li:not(:last-child) {
  border-bottom: 3px solid var(--color-gray-light);
}

.suggestedItems__info_container {
  display: flex;
  align-items: center;
  width: 100%;
}

.suggestedItems__name_price_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 10px 0;
}

.suggestedItems__name_price_wrapper > *:not(:last-child) {
  margin-bottom: 5px;
  margin-left: 10px;
}

.suggestedItems__name_price_wrapper span {
  font-weight: 600;
}

.suggestedItems__info_container img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.suggestedItems__button_wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-right: 30px;
  margin-left: 10px;
}

.suggestedItems__button_wrapper > button {
  max-width: 50%;
  white-space: normal;
}

.suggested_items__view-details {
  color: var(--color-secondary);
  font-size: 0.8em;
  font-weight: 600;
}

.suggested_items__item-description {
  padding: 0 30px;
}

.suggested_items__item-description > p {
  font-size: 14px;
}

.suggested_items__item-description-hidden {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: max-height 0.2s ease-out;
}

.suggestedItems__customize:active,
.suggestedItems__add_to_cart:active {
  opacity: 0.85;
}

.suggestedItems__customize {
  width: unset;
  padding: 0.6em 1.2em;
}

/** Desktop only **/
@media screen and (min-width: 660px) {
  .suggestedItems__collapsable_wrapper {
    max-width: 1200px;
    margin: 20px auto 0 auto;
    padding: 0 24px;
  }

  .suggestedItems__collapsable_wrapper > #suggestedItemsCollapsable {
    width: 100%;
    padding: 0 24px;
  }

  .suggestedItems__main_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #f9f9f9;
  }

  .suggestedItems__main_container > .suggestedItems__list {
    flex: 50%;
    max-width: 49%;
  }

  .suggestedItems__list > li {
    background-color: #fff;
  }
}

/** Plus to check button **/
*,
*:before,
*:after {
  box-sizing: border-box;
}
.c-button-reset {
  display: inline-block;
  font-family: inherit;
  font-size: 1em;
  outline: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  text-shadow: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}
.c-plus-to-check {
  position: relative;
  min-width: 40px;
  min-height: 40px;
  width: 2.5em;
  height: 2.5em;
  background-color: #fff;
}
.c-plus-to-check:before,
.c-plus-to-check:after {
  background-color: var(--color-button-text);
  content: "";
  display: inline-block;
  position: absolute;
  top: 46%;
  left: 0.75em;
  right: 0.75em;
  height: 2px;
  transition: transform 300ms cubic-bezier(0.75, -0.6, 0.14, 1.59) 150ms;
  will-change: transform background-color;
}
.c-plus-to-check:before {
  transform: translate(0, 0) rotate(-90deg) scale(1, 1);
}
.c-plus-to-check:after {
  transform: translate(0, 0) rotate(180deg) scale(1, 1);
}
.c-plus-to-check__text {
  position: absolute;
  top: -3125rem;
  left: -3125rem;
  font-size: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
}
.c-plus-to-check[data-state="active"]:before,
.c-plus-to-check[data-state="active"]:after {
  background-color: var(--color-button-text);
  transition-duration: 150ms;
  transition-timing-function: ease-out;
  transition-delay: 0s;
}
.c-plus-to-check[data-state="active"]:before {
  transform: translate(calc(39% - 0.2em), -0.1em) rotate(-45deg) scale(1, 1);
}
.c-plus-to-check[data-state="active"]:after {
  transform: translate(-30%, 0.175em) rotate(45deg) scale(0.43, 1);
}

.suggestedItems__add_to_cart {
  background-color: var(--color-primary);
  border-radius: 50%;
  display: flex;
  padding: 10px;
  position: absolute;
  right: 30px;
  bottom: -10px;
}

.suggestedItems__add_to_cart > .icon--plus {
  color: var(--color-button-text);
}
