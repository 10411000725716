.progress-bar-container {
  padding: 0;
}

:root {
  --color-white: #fff;
  --color-black: #333;
  --color-gray: #75787b;
  --color-gray-light: #ebebeb;
  --color-gray-disabled: #e8e8e8;
  --color-blue: #0086ff;
  --color-blue-dark: #383;
  --font-size-small: 0.75rem;
  --font-size-default: 0.875rem;
  --color-gray-text: #4e4e4e;
}

/*
.progress-bar .is-active:before {
  border-color: var(--color-secondary);
}
.progress-bar li:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--color-gray-disabled);
  border-radius: 50%;
  border: 2px solid var(--color-white);
  position: absolute;
  bottom: -7px;
  z-index: 3;
  transition: all 0.2s ease-in-out;
}

.progress-bar li:last-child:before {
  left: auto;
}

.progress-bar .is-active:before {
  background-color: var(--color-white);
  border-color: var(--color-secondary);
}

.progress-bar .is-complete:before {
  background-color: var(--color-secondary) !important;
}
*/

.progress-bar {
  overflow: auto;
  width: 100%;
  white-space: nowrap;
  height: 100%;
  padding: 10px 0 10px 24px;
  margin: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.progress-bar::-webkit-scrollbar {
  display: none;
}

.progress-bar li {
  position: relative;
  font-size: var(--font-size-default);
  line-height: 1.5;
  color: var(--color-secondary);
  font-weight: 600;
  white-space: nowrap;
  overflow: visible;
  min-width: 55px;
  text-align: left;
  display: inline-block;
  clear: both;
  margin-bottom: 2px;
}

.progress-bar li:last-child {
  border-bottom: none;
}

.progress-bar button {
  font-weight: 600;
  transition: opacity 0.3s ease-in-out;
  background-color: #fff;
  border-radius: 50%;
  padding: 6px 10px;
  border: 1px solid var(--color-gray-light);
}

.progress-bar .is-active > button {
  border: 2px solid var(--color-primary);
  background-color: #fff;
  padding: 7px 11px;
}

.progress-bar li:after {
  content: "";
  display: block;
  width: 100%;
  position: absolute;
  bottom: calc(50% - 2px);
  left: 0;
  z-index: -1;
  border-bottom: 2px solid var(--color-gray-light);
}

.progress-bar .is-complete:after {
  border-bottom: 2px solid var(--color-primary);
}

.progress-bar .is-complete button {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.progress-bar .is-active:hover {
  cursor: pointer;
}

.progress-bar .is-complete:last-child:after,
.progress-bar .is-active:last-child:after {
  width: 0;
}

.progress-bar li.is-summary > button {
  border: none;
}

.progress-bar li.is-active.summary > button:not(:disabled) {
  background-color: unset;
  color: var(--color-secondary);
}

.progress-bar-container {
  position: sticky;
  z-index: 11;
  top: 53px;
  background-color: #fff;
}

.progress-bar-item-disabled {
  opacity: 0.2;
}

.progress-bar-container_bottom_border {
  display: none;
}

@media screen and (min-width: 660px) {
  .progress-bar-container {
    position: unset;
    margin: 0 auto 0 0;
  }

  .progress-bar {
    padding-inline-start: 0;
  }

  .progress-bar li {
    min-width: 110px;
  }

  .progress-bar li:last-child::after {
    display: none;
  }

  .progress-bar-container_bottom_border {
    display: block;
    margin: 1em 0;
    width: 100%;
    border: 1px solid var(--color-gray-light);
  }
}
