.rewards-main-content {
  padding: 24px 24px 85px;
}

.rewards-section + .rewards-section {
  margin-top: 24px;
}

.rewards-section__heading {
  margin: 0 0 12px;
  font-size: 1.25rem;
}

.rewards-section__subheading {
  margin: 0 0 8px;
  font-size: 1rem;
  font-weight: 600;
}

.rewards-section p {
  margin: 0 0 12px;
}

.rewards-section p a {
  color: #0086ff;
  font-weight: 600;
}

.add-new-loyalty-card-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 36px;
}

.add-new-loyalty-card-button {
  padding: 10px 18px;
  font-size: 13px;
  line-height: 100%;
  border: 1.2px solid var(--color-primary);
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  color: var(--color-button-text);
  font-weight: 550;
}

.loyalty-guest-landing__register-link {
  width: auto;
  min-width: 180px;
  max-width: 350px;
  margin-right: 1em;
  text-wrap: wrap;
}

@media screen and (max-width: 660px) {
  .rewards__login-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
  }
  .rewards__login-buttons > button {
    margin: 0 !important;
    min-width: 48%;
    max-width: 48%;
  }
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .rewards-main-content {
    padding-top: 2em;
    padding-bottom: 4em;
  }
}

/** End of Dektop Layout **/
