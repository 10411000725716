.new-update-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.3s;
  z-index: 1100;
}

.new-update-modal {
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(150px);
  width: 100%;
  padding: 48px 24px 24px;
  background-color: #fff;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  animation: slideUpNewVersionModal 0.3s forwards;
  animation-delay: 0.15s;
}

@keyframes slideUpNewVersionModal {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.new-update-modal__message {
  margin: 0;
}

.new-update-modal__buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2em;
}

.new-update-modal__submit-button {
  flex: 1 1;
  flex-grow: 0.5;
  max-width: 190px;
  height: 44px;
  padding-top: 0;
  padding-bottom: 0;
  letter-spacing: 0.2px;
}

@media screen and (min-width: 1025px) {
  .new-update-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
  }

  .new-update-modal {
    position: relative;
    width: auto;
    width: initial;
    max-width: 90%;
    padding: 3em 2em 2em 2em;
    border-radius: 8px;
  }
}

@media screen and (min-width: 660px) {
  .new-update-modal__submit-button {
    width: auto;
    max-width: none;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 0.8em;
  }
}
