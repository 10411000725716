.payment-method {
  width: 100%;
}

.payment-methods--collapsed > .payment-method + .payment-method {
  margin-top: 0;
}

.payment-methods--collapsed > .payment-method:not(.payment-method--active) {
  position: absolute;
  top: -10px;
  transform: scale(0.9);
  transition: opacity 0.6s;
}

.payment-method + .payment-method {
  margin-top: 10px;
}

.payment-method__button {
  position: relative;
  width: 100%;
  height: 51px;
  padding: 0 10px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 10px 1px rgba(2, 10, 6, 0.15);
  z-index: 1;
}

.payment-method--active > .payment-method__button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.payment-methods--collapsed .payment-method__button {
  box-shadow: 0px 0px 5px 0px rgba(2, 10, 6, 0.06);
}

.payment-methods--collapsed > .payment-method--active > .payment-method__button {
  box-shadow: 0px 2px 10px 1px rgba(2, 10, 6, 0.2);
}

.payment-method__only-button:disabled {
  color: #2a2a2a;
  background-color: #fff;
}

.payment-method__button > .icon:first-child {
  position: absolute;
  left: 30px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.payment-method__button > .icon:last-child {
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.payment-method__button > .icon[class*="arrow"] {
  width: 14px;
  height: 14px;
}

.payment-method__button > .icon:not([class*="arrow"]) {
  width: 20px;
  height: 20px;
}

.payment-method__gpay-button > .icon:not([class*="arrow"]) {
  width: 60px;
  height: 50px;
}

.payment-method__gpay-button > .icon:first-child {
  left: 15px !important;
}

.payment-method__applepay-button > .icon:not([class*="arrow"]) {
  width: 40px;
  height: 40px;
}

.payment-method__applepay-button > .icon:first-child {
  left: 20px !important;
}

.payment-method__details {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 203px;
  padding: 30px 28px 20px;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.15);
  animation: expandDetails 0.3s forwards;
}

.payment-method__details:empty {
  display: none;
}

@keyframes expandDetails {
  100% {
    opacity: 1;
    transform: initial;
  }
}

.coupon-notice {
  color: #ff2020;
}
