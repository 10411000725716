.dashboard-action-button {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  max-height: 60px;
  min-height: 60px;

  flex: calc(45% - 1em);
  min-width: unset;
}

.dashboard-action-button.left-aligned {
  justify-content: flex-start;
}

.dashboard-action-button.center-aligned {
  flex-direction: column;
  justify-content: center;
  padding: 11px 0;
}

.dashboard-action-button.left-aligned > .dashboard-action-button__icon-container {
  min-width: 60px;
  max-width: 60px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  min-height: 60px;
}

.dashboard-action-button.center-aligned > .dashboard-action-button__icon-container {
  width: 18px;
  height: 18px;
  margin-bottom: 10px;
}

.dashboard-action-button.left-aligned
  > .dashboard-action-button__icon-container
  > .dashboard-action-button__icon {
  width: 24px;
  height: 24px;
}

.dashboard-action-button.left-aligned > .dashboard-action-button__title-container {
  padding: 10px 10px 10px 20px;
}

.dashboard-action-button__title-container {
  display: flex;
  flex-direction: column;
}
.dashboard-action-button__title {
  font-weight: 600;
  font-size: 16px;
}

.dashboard-action-button__subtitle {
  margin-top: 3px;
  font-weight: 400;
  font-size: 14px;
}

.dashboard-action-button__button {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dashboard-action-button__arrow-container {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.dashboard-action-button.split-action-button {
  width: 45%;
  margin-right: 10px;
}
.dashboard-order-buttons__row > .dashboard-action-button.split-action-button:last-child {
  margin-right: 0;
}

.dashboard-action-button.split-action-button .dashboard-action-button__title {
  text-align: center;
}

/** For small mobile devices such as iphone SE */
@media screen and (max-width: 390px) {
  .dashboard-action-button__title {
    font-size: 14px;
  }
}
