.menu__category-card {
  position: relative;
  margin: 0.5em;
  width: calc(50% - 1em);
  border: 1px solid #dadada;
  border-radius: 0.5em;
  z-index: 0;
  /*overflow: hidden;*/
}

.menu__category-card.withQuickAddButton {
  border-bottom-right-radius: 1em;
}

.menu-category-card__content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media screen and (max-width: 320px) {
  .menu__category-card {
    width: calc(100% - 1em);
  }

  .styles-module_item-container__a8zaY {
    width: calc(100% - 1em);
  }
}

@media screen and (min-width: 540px) {
  .menu__category-card {
    width: calc(100% / 3 - 1em);
  }
  .styles-module_item-container__a8zaY {
    width: calc(100% / 3 - 1em);
  }
}

@media screen and (min-width: 1080px) {
  .menu__category-card {
    width: calc(100% / 5 - 1em) !important;
  }

  .styles-module_item-container__a8zaY {
    width: calc(100% / 5 - 1em) !important;
  }
}

@media screen and (min-width: 1280px) {
  .menu__category-card {
    width: calc(100% / 5 - 1em);
  }

  .styles-module_item-container__a8zaY {
    width: calc(100% / 5 - 1em) !important;
  }
}

@media screen and (min-width: 1545px) {
  .menu__category-card {
    width: calc(100% / 6 - 1em);
  }
  .styles-module_item-container__a8zaY {
    width: calc(100% / 6 - 1em) !important;
  }
}

.menu-category-card__image-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  background-color: #fff;
  overflow: hidden;
  border-top-left-radius: 0.4em;
  border-top-right-radius: 0.4em;
}

.menu-category-card__image,
.menu-category-card__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu-category-card__image-wrapper > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}

.menu-category-card___text-wrapper {
  flex-grow: 1;
  padding: 0.5em 0.5em 0.7em;
  background-color: #fff;
  overflow: hidden;
  border-bottom-left-radius: 0.4em;
  border-bottom-right-radius: 0.4em;
}

.menu-category-card__name {
  --line-height: 0.9rem;
  --max-lines: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: var(--line-height);
  height: calc(var(--line-height) * var(--max-lines));
  overflow: hidden;
}

.menu-category-card__price {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
}

.menu-category-card__limit {
  margin-top: 0.7rem;
  padding: 0 0.5em;
}

.menu-category-card__calories {
  text-align: center;
  font-size: 0.9rem;
}

.menu-category-card__button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
