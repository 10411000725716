.my-rewards__section {
  padding: 0 24px;
}

.my-rewards__section + .my-rewards__section {
  margin-top: 15px;
}

.my-rewards__redeem-rewards-section {
  padding-left: 0;
  padding-right: 0;
}

.redeem-rewards-section__text-container {
  padding: 0 24px;
}

.my-rewards__points-and-card-select > .desktop-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rewards-module__balance-and-star {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.rewards-module__balance-and-star > div {
  display: flex;
}

.rewards-module__balance-and-star.my-rewards > div {
  justify-content: flex-start;
}

.rewards-module__balance {
  margin-right: 4px;
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
}

.rewards-module__balance-and-star > div > .icon--star {
  width: 20px;
  height: 20px;
  fill: #f49c00;
  stroke: none;
}

.rewards-module__default-card-label {
  color: #757575;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.rewards-module__select-wrapper {
  position: relative;
}

.rewards-module__select-wrapper > .icon--arrow-down {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.rewards-module__select {
  height: 44px;
  padding: 0 20px;
  background: none;
  border: none;
  appearance: none;
}

.my-rewards-section__header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 12px;
}

.my-rewards-section__header-link {
  color: var(--color-secondary);
  font-size: 15px;
  font-weight: 500;
  text-align: right;
}

.my-rewards-section__heading {
  margin: 0;
  font-size: 1.25rem;
}

.my-rewards-section__text {
  margin: 0 0 12px;
}

.redeem-reward-button {
  margin-bottom: 36px;
}

.my-rewards__options-table {
  width: 100%;
}

.my-rewards__options-table-section + .my-rewards__options-table-section {
  display: block;
  margin-top: 12px;
}

.my-rewards__options-table-section-heading,
.my-rewards-section__subheading {
  font-size: 1rem;
  font-weight: 600;
  line-height: 100%;
  text-align: left;
}

.my-rewards__options-table-section-heading {
  display: block;
  margin-bottom: 8px;
}

.my-rewards-section__subheading {
  margin: 0 0 8px;
  line-height: normal;
}

.my-rewards-section__text + .my-rewards-section__subheading {
  margin-top: 24px;
}

.my-rewards__option-header-row {
  display: flex;
  justify-content: flex-end;
}

.my-rewards__option-header-cell--expiry {
  color: #757575;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 100%;
}

.my-rewards__option-row {
  display: block;
  /* padding: 10px 0 10px 0; */
}

.reward-details-modal {
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
  background-color: #fff;
  animation: scaleUpRewardDetails 0.3s forwards;
}

.my-rewards__option-row .icon--star {
  min-width: 18px;
  margin-right: 4px;
  fill: #878786;
  stroke: none;
}

.my-rewards__option-row--redeemable .icon--star {
  fill: #f49c00;
}

.my-rewards__option-row + .my-rewards__quantity-button-row {
  border-top: none;
}

.my-rewards__option-cell {
  display: grid;
  align-items: center;
  font-size: 0.9rem;
  line-height: 130%;
}

.my-rewards__option-cell--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 75px;
  min-height: 75px;
  max-width: 75px;
  max-height: 75px;
  overflow: hidden;
}

.my-rewards__option-cell--icon > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.my-rewards__option-cell--icon > svg {
  width: 100%;
  height: 100%;
}

.my-rewards__option-row--redeemable .my-rewards__option-cell--icon > svg * {
  fill: #f49c00;
}

.my-rewards__option--icon-rewards {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 75px;
  min-height: 75px;
  max-width: 75px;
  max-height: 75px;
  overflow: hidden;
}

.my-rewards__option--icon-rewards > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.my-rewards__option--icon-rewards > svg {
  width: 100%;
  height: 100%;
}

.my-rewards__option-row--redeemable .my-rewards__option--icon-rewards > svg * {
  fill: #f49c00;
}

.my-rewards__option-cell--cost {
  display: flex;
  align-items: center;
}

.my-rewards__option--cost-rewards {
  display: inline;
}

.my-rewards__option-row--redeemable .my-rewards__option-cell--cost {
  color: #f49c00;
}

.my-rewards__option-row--redeemable .my-rewards__option--cost-rewards {
  color: #f49c00;
}

.my-rewards__option-container {
  display: flex;
}

.my-rewards__option-cell-container {
  display: grid;
  margin-left: 1em;
  justify-content: space-between;
}

.my-rewards__option-cell-container .icon--star {
  fill: #f49c00;
  color: #f49c00;
  height: 1rem;
  width: 1rem;
  margin-left: 4px;
}

.my-rewards__option-cell-container .my-rewards__option-cell--cost {
  color: #f49c00;
  display: flex;
  font-weight: 600;
}

.my-rewards__option-cell-container .my-rewards__option-cell--cost > svg * {
  fill: #f49c00;
}

.my-rewards__option-cell-container .my-rewards__option--cost-rewards {
  color: #f49c00;
  display: flex;
  font-weight: 600;
}

.my-rewards__option-cell-container .my-rewards__option--cost-rewards > svg * {
  fill: #f49c00;
}

.my-rewards__option-container-rewards {
  display: grid;
  margin-left: 1em;
  justify-content: space-between;
}

.my-rewards__option-container-rewards .icon--star {
  fill: #f49c00;
  color: #f49c00;
  height: 1rem;
  width: 1rem;
  margin-left: 4px;
}

.my-rewards__option-container-rewards .my-rewards__option--cost-rewards {
  color: #f49c00;
  display: flex;
  font-weight: 600;
}

.my-rewards__option-container-rewards .my-rewards__option--cost-rewards > svg * {
  fill: #f49c00;
}

.my-rewards__option-cell--button-container {
  display: flex;
  justify-content: center;
  max-width: 65%;
  align-self: center;
  margin: 0 auto;
}

.my-rewards__option-cell--button-container .button--primary-small {
  margin-right: 0px !important;
}

.my-rewards__option--button-container-rewards {
  display: flex;
  justify-content: center;
  max-width: 85%;
  align-self: center;
  margin: 0 auto;
}

.my-rewards__option--button-container-rewards .button--primary-small {
  margin-right: 0px !important;
}

.my-rewards__reward-description > p {
  font-size: 14px;
}

.my-rewards__reward-description-hidden {
  max-height: 24px;
  overflow: hidden;
  visibility: hidden;
  transition: max-height 0.2s ease-out;
}

.my-rewards__option-cell--name {
  padding: 0 4px 0 0;
  margin-right: 0.5em;
  font-weight: 600;
}

.my-rewards__option--name-rewards {
  padding: 0 4px 0 0;
  margin-right: 0.5em;
  font-weight: 600;
}

.my-rewards__option-cell--quantity > label {
  width: 100%;
  text-align: left;
}

.my-rewards__option-cell--quantity > select {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--color-gray-light);
  border-radius: 0;
  text-align-last: center;
  padding: 5px 0;
  width: 100%;
}

.my-rewards__quantity-button-row {
  align-items: flex-end;
  padding-bottom: 15px;
}

.my-rewards__option-cell--button {
  flex-direction: column;
  justify-content: center;
  display: inline-flex;
}

.my-rewards__option-cell--expiry {
  font-size: 0.75rem;
  text-align: center;
  margin-top: 10px;
}

.my-rewards__option--expiry-rewards,
.my-rewards__option--balance {
  font-size: 0.75rem;
  text-align: center;
  margin-top: 10px;
}

.my-rewards__option--balance {
  margin-top: 0.5em;
  text-align: left;
  margin-bottom: 0.5em;
}

.my-rewards__option--expiry-rewards {
  margin-bottom: 0.5em;
  text-align: left;
}

.my-rewards__option--balance + .my-rewards__option--expiry-rewards {
  margin-top: 0;
}

.my-rewards__option-cell--button {
  width: 45%;
}

.my-rewards__option-cell--button > button {
  width: 100%;
}

.my-reward__success-message-header {
  font-weight: 700;
  font-size: 1.1em;
  margin: 0;
}

.my-reward__success-message-reward-name {
  font-weight: 15px;
}

.my-reward__success-message-reward-detail {
  margin: 0;
}

.my-reward__success-message-header-wrapper {
  display: flex;
  align-content: baseline;
  margin-bottom: 1em;
}

.my-reward__success-message-header-wrapper > div:first-child {
  display: flex;
  align-items: center;
}

.my-reward__success-message-header-wrapper > div > .icon--check {
  background-color: #4caf52;
  border-radius: 50%;
  stroke: white;
  stroke-width: 4px;
  padding: 3px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.my-reward__success-message-header-wrapper + .my-reward__success-message-reward-detail {
  margin-bottom: 8px;
}

.my-reward__success-message-reward-transaction {
  font-size: 14px;
}

.my-rewards__redirect-buttons-container {
  display: flex;
  justify-content: center;
}

.my-rewards-main-content {
  background-color: #f2f2f2;
}

.my-rewards__options-container {
  padding-top: 1.5em;
  padding-bottom: 1em;
  background-color: white;
}

.my-rewards__confirmation-popup > span {
  position: relative;
  margin-right: 20px;
}

.my-rewards__confirmation-popup > span > .icon--star {
  width: 20px;
  height: 20px;
  fill: #f49c00;
  stroke: none;
  position: relative;
  top: 3px;
  left: -1px;
}

.my-rewards__option-row {
  display: flex;
  justify-content: space-between;
}

.my-rewards__wrapper:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-light);
}

.my-rewards__wrapper {
  padding: 24px 0px;
}

.my-rewards__wrapper-use-rewards {
  padding: 24px 0px;
  border-bottom: 1px solid var(--color-gray-light);
}

.my-rewards__wrapper-use-rewards:last-child {
  margin-bottom: 0.8em;
}

.my-rewards__redirect-dialog p {
  margin-top: 0;
  line-height: 1.5;
  font-size: 16px;
}

.my-rewards__redirect-dialog .dialog-modal__buttons {
  display: none;
}

@keyframes scaleUpRewardDetails {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 660px) {
  .my-rewards-main-content {
    padding-top: 15px;
    margin-bottom: 57px;
    /* AppFooter height */
  }
}

@media screen and (min-width: 598px) and (max-width: 660px) {
  .my-rewards__confirmation-popup > span > .icon--star {
    top: 0;
  }
}

/** Desktop Layout **/
@media screen and (min-width: 660px) {
  .my-rewards__wrapper-desktop {
    padding: 24px 0px;
  }

  .my-rewards__wrapper-desktop:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-light);
  }

  .my-rewards__wrapper-desktop-use-rewards {
    padding: 24px 0px;
    border-bottom: 1px solid var(--color-gray-light);
  }

  .my-rewards__wrapper-desktop-use-rewards:last-child {
    margin-bottom: 0.8em;
  }

  .my-rewards__option-cell--name {
    padding: 0 0 4px 0;
    margin-right: 0.5em;
    font-weight: 600;
  }

  .my-rewards__reward-description-visible {
    width: fit-content;
    min-width: 165%;
  }

  .my-rewards__option--name-rewards {
    padding: 0 0 4px 0;
    margin-right: 0.5em;
    font-weight: 600;
  }

  .button--primary-small {
    padding: 1em !important;
  }

  .my-rewards__redirect-buttons-container .button--primary-small {
    padding: 0 12px !important;
    max-width: 250px;
    min-height: 44px;
    font-size: 0.7em;
  }

  .my-rewards__option-cell--button-container {
    display: flex;
    justify-content: left;
    width: 40%;
    position: relative;
    align-self: baseline;
  }

  .my-rewards__option--button-container-rewards {
    display: flex;
    justify-content: flex-end;

    position: relative;
    align-self: baseline;
    margin: auto 0;
    max-width: 30%;
    min-width: 30%;
  }

  .my-rewards__reward-description-hidden {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: max-height 0.2s ease-out;
  }

  .my-rewards__option-cell-container {
    display: flex;
    position: relative;
    margin: 0em 2em 0 1em;
    align-self: center;
    width: 70%;
  }

  .my-rewards__option-container-rewards {
    display: grid;
    position: relative;
    margin: 1em 2em 0 1em;
    align-self: flex-start;
    width: 70%;
  }

  .my-rewards__option-container-use-rewards {
    display: block;
    position: relative;
    margin: 1em 0 0 0;
    align-self: flex-start;
    width: 20%;
  }

  .my-rewards__option-cell--cost {
    display: flex;
    position: relative;
    align-items: baseline;
    align-self: baseline;
    justify-content: center;
    margin: 0em 1em 0 2em;
  }

  .my-rewards__option-cell--cost {
    color: #f49c00;
  }

  .my-rewards__option--cost-rewards {
    display: flex;
    position: relative;
    align-items: baseline;
    align-self: baseline;
    justify-content: center;
    margin: 1em 1em 0 1em;
    color: #f49c00;
  }

  .icon--star {
    fill: #f49c00;
    color: #f49c00;
    height: 1rem;
    width: 1rem;
    margin-left: 4px;
  }

  .my-rewards__option-cell--cost {
    color: #f49c00;
    display: flex;
  }

  .my-rewards__option-cell--cost > svg * {
    fill: #f49c00;
  }

  .my-rewards__option--cost-rewards {
    color: #f49c00;
    display: flex;
  }

  .my-rewards__option--cost-rewards > svg * {
    fill: #f49c00;
  }

  .my-rewards__option-cell--icon {
    align-self: normal;
  }

  .my-rewards__option-container {
    align-items: center;
  }

  .my-rewards__confirmation-popup > span > .icon--star {
    position: relative;
    top: 3px;
    left: -3px;
    right: unset;
    margin: 0;
    width: 24px;
    height: 24px;
  }

  .my-rewards-main-content {
    background-color: white;
  }

  .my-rewards__section + .my-rewards__section {
    margin-top: 0;
    margin-bottom: 5em;
  }

  .my-rewards__points-and-card-select {
    background-color: #f5f5f5;
    padding: 1em 0;
    margin-bottom: 3%;
  }

  .rewards-module__stepper {
    display: flex;
    list-style-type: none;
    width: 100%;
    height: 100%;
    margin: 0 0 0 -11.25px;
    padding-left: 0;
    overflow: auto;
    white-space: nowrap;
    min-height: 5.5em;
  }

  .my-rewards__points-scroll-wrapper {
    background-color: white;
    padding-bottom: 2em;
    padding-right: 0;
    overflow-y: visible;
    min-height: 5.5em;
  }

  .rewards-module__stepper {
    overflow-x: hidden;
  }

  .rewards-module__stepper:hover {
    overflow-x: auto;
  }

  .my-rewards__option-cell--cost {
    width: 10%;
  }

  .my-rewards__option--cost-rewards {
    width: 10%;
  }

  .rewards-module__select {
    font-weight: bold;
  }

  .rewards-module__select-wrapper > .icon--arrow-down {
    width: 30px;
    height: 30px;
    right: -10px;
  }

  .my-rewards-section__header-link {
    font-weight: bold;
    font-size: 1em;
  }

  .my-rewards__options-table {
    margin-top: 2em;
  }

  .my-rewards__options-table-section-heading,
  .my-rewards__option-header-cell--expiry,
  .my-rewards__options-table-section-heading,
  .my-rewards-section__subheading {
    font-weight: bold;
    font-size: 1.1em;
  }

  .my-rewards-section__header {
    margin-bottom: 2em;
  }

  .my-rewards__option-cell > .icon--star,
  .redeem-rewards-slider-item__price-wrapper > .icon--star {
    width: 2.5em;
    height: 2.5em;
  }

  .my-rewards__option-cell--cost,
  .redeem-rewards-slider-item__price {
    font-size: 1.3em;
    font-weight: 600;
  }

  .my-rewards__option--cost-rewards,
  .redeem-rewards-slider-item__price {
    font-size: 1.3em;
    font-weight: 600;
  }

  .my-rewards__option-name {
    /* font-size: 1.3em; */
    font-weight: 600;
  }

  .redeem-reward-button {
    width: 58%;
  }

  .redeem-rewards-slider {
    margin-top: 3em;
    margin-left: 10%;
  }

  .my-rewards__option-cell--icon {
    width: 6%;
  }

  .my-rewards__option--icon-rewards {
    width: 6%;
  }

  .my-rewards__option-cell--button {
    width: 49% !important;
  }

  .reward-form__options-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .dashboard__rewards-module.my-rewards__section.my-rewards__points-scroll-wrapper {
    padding: 0;
  }
  .my-rewards__section.my-rewards__options-container {
    padding-left: 0;
    padding-right: 0;
  }

  .my-rewards__option--balance {
    margin-top: 1em;
    text-align: left;
    margin-bottom: 1em;
  }

  .my-rewards__option--expiry-rewards {
    margin-bottom: 1em;
    text-align: left;
  }

  .my-rewards__option--balance + .my-rewards__option--expiry-rewards {
    margin-top: 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .my-rewards__section.my-rewards__options-container:not(.use-rewards__section) {
    max-width: 100%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .my-rewards__section.my-rewards__options-container:not(.use-rewards__section) {
    max-width: 70%;
  }
}

@media screen and (min-width: 660px) and (max-width: 1024px) {
  .my-rewards__reward-description-desktop > p {
    font-size: 14px;
    width: max-content;
    max-width: 350%;
  }

  .button--primary-small {
    padding: 0.8em 2em !important;
    font-size: 0.6em;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .my-rewards__reward-description-desktop > p {
    font-size: 14px;
    width: max-content;
    max-width: 360%;
  }
}

@media screen and (min-width: 1280px) {
  .my-rewards__reward-description-desktop > p {
    font-size: 14px;
    width: max-content;
    max-width: 360%;
  }
}

/** End of Desktop Layout **/
