.dialog-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.3s;
  z-index: 999999;
}

.dialog-modal {
  opacity: 0;
  transform: translateY(150px);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 660px;
  padding: calc(44px + 0.5em) 1.5em 2em;
  background-color: #fff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  animation: slideUpConfirmationModal 0.3s forwards;
  animation-delay: 0.15s;
}

.dialog-modal__heading {
  margin: 0 0 12px;
  padding-bottom: 12px;
  font-size: 1.3em;
  font-weight: 600;
  border-bottom: 1px solid #dedede;
}

.dialog-modal__message {
  margin: 0 0 24px;
  font-size: 16px;
  text-align: left;
}

.dialog-modal__position-absolute {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  bottom: 24px;
  z-index: 1000;
}

.dialog-modal__position-relative {
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  bottom: 24px;
  z-index: 1000;
}

.dialog-modal__recommended-address-container {
  padding-bottom: 0px;
  padding-left: 0;
  padding-right: 0;
}

.dialog-modal__recommended-address-container > div {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.dialog-modal__recommended-address-container > .dialog-modal__message {
  box-shadow: 0 7px 10px -6px rgb(182 182 182/77%);
}

.dialog-modal__recommended-address-container
  .address-validation__recommended-address-header-container {
  border-bottom: none;
}

.dialog-modal__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2em;
}

.dialog-modal__button {
  flex: 1;
  flex-grow: 0.5;
  width: auto;
  max-width: 250px;
  min-height: 44px;
  padding: 0.5em 1em;
  letter-spacing: 0.2px;
  white-space: normal;
}

.expired-session_popup h2 {
  margin-top: 0;
}
.expired-session_popup p {
  line-height: 1.5;
}

.dialog-modal__cancel-button {
  margin-right: 12px;
  color: #2a2a2a;
  border: 2px solid #2a2a2a;
}

.dialog-modal__confirm-button {
  color: #fff;
  background-color: #2a2a2a;
}

.dialog-modal__close-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
}

.dialog-modal__reorder-flow {
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-button-text);
}

.dialog-modal__reorder-flow .loading-spinner {
  width: 3em;
  height: 3em;
}

.dialog-modal__close-button > svg {
  width: 18px;
  height: 18px;
}

.address-validation__primary-address-checkbox {
  font-weight: bold;
  font-size: 0.9rem;
}

.address-validation__primary-address-checkbox > input {
  margin-right: 10px;
}

@keyframes slideUpConfirmationModal {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dialog-modal__verification-issue-container .confirm-address__unverified-address,
.dialog-modal__verification-issue-container .address-info-card__container,
.dialog-modal__verification-issue-container
  .address-validation__unverified-address-header-container {
  margin: 0;
}
.dialog-modal__verification-issue-container .address-validation__recommended-address-container {
  padding-bottom: 0;
}

.address-confirmation__missing-component h2 {
  border-bottom: 1px solid var(--color-gray);
}
.dialog-button-centered{
  justify-content: center !important;
}
.dialog-button-centered button.dialog-modal__button{
  max-width: 300px !important;
  min-width: 250px !important;
}
/** Desktop Layout **/
@media screen and (min-width: 1025px) {
  .dialog-modal-container {
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dialog-modal {
    position: relative;
    padding: 3em 2em 2em 2em;
    border-radius: 8px;
  }

  .dialog-modal__buttons {
    margin-top: 2em;
    justify-content: flex-end;
  }

  .dialog-modal__buttons-two {
    justify-content: center;
  }

  .dialog-modal__reorder-flow .loading-spinner {
    width: 2em;
    height: 2em;
  }
  .wideDialogModal .dialog-modal{
    min-width: 850px !important;
    max-width: 1000px !important;
  }

}
@media screen and (min-width: 750px) and (max-width: 1000px) {
  .wideDialogModal .dialog-modal {
    min-width: 700px !important;
    max-width: 90% !important;
  }
}
@media screen and (min-width: 1001px) {
  .wideDialogModal .dialog-modal {
    min-width: 850px !important;
    max-width: 950px !important;
  }
}
@media screen and (min-width: 660px) {
  .dialog-modal__button {
    flex-grow: 0.5em;
  }
  .dialog-modal__buttons > .button {
    font-size: 0.7em;
  }

  .address-validation__recommended-address-header-container {
    padding-left: 24px;
    padding-right: 24px;
    border: none;
  }

  .dialog-modal__recommended-address-container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 1em;
    height: 98%;
  }

  .dialog-modal__address-issue-container {
    padding-left: 0;
    padding-right: 0;
    padding-top: 1em;
  }

  .dialog-modal__recommended-address-container .dialog-modal__message,
  .dialog-modal__address-issue-container .dialog-modal__message {
    box-shadow: 0 7px 10px -6px rgb(182 182 182/77%);
    margin-bottom: 0;
  }

  .address-validation__form-and-map {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    height: 100%;
  }

  .address-validation__form-and-map > form {
    width: 35%;
    margin-top: 1em;
  }

  .address-validation__form-and-map > .confirm-address__google-maps-container-dialog-modal {
    width: 65%;
    margin: 0;
    height: 100%;
    max-height: unset;
  }

  .dialog-modal__recommended-address-container .dialog-modal__position_absolute {
    justify-content: center;
    margin-top: 0;
    bottom: 24px;
  }

  .address-validation__address-issue-container {
    margin-top: 1em;
  }

  .address-validation__desktop-button-container {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .address-confirmation__missing-component h2 {
    padding-left: 0;
    padding-right: 0;
  }

  .dialog-modal__missing-component-container p {
    font-size: 16px;
  }

  .expired-session_popup .dialog-modal__button {
    flex: 1;
    flex-grow: 0.4;
    width: auto;
    max-width: unset;
    min-height: 44px;
    padding: 0 12px;
    letter-spacing: 0.2px;
    white-space: normal;
  }

  .expired-session_popup h2 {
    margin-top: 0;
    font-size: 24px;
  }
  .expired-session_popup p {
    line-height: 1.5;
    font-size: 16px;
  }

  .xp-subscription-container > h2 {
    font-size: 24px;
  }

  .xp-subscription-container > p {
    line-height: 1.5;
    font-size: 16px;
  }
}

@media screen and (min-width: 660px) and (max-width: 1024px) {
  .dialog-modal-container {
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dialog-modal {
    position: relative;
    padding: 3em 2em 2em 2em;
    border-radius: 8px;
  }

  .dialog-modal__buttons {
    margin-top: 2em;
    justify-content: flex-end;
  }

  .dialog-modal__buttons-two {
    justify-content: center;
  }
}

/** End Of Desktop Layout **/

@media screen and (max-width: 660px) {
 .fullScreenMobileModal .dialog-modal{
  height: 100%;
 }
 .fullScreenMobileModal .dialog-modal>div:first-child{
  height: 90.5%;
 }
}